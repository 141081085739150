import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import logoSmall from './logo-small.svg'

const Disclaimer = ({ disclaimer }) => (
  <div className="message message--flash">
    <img className="message__avatar avatar avatar--raised" src={logoSmall} alt="Careteam" />

    <div className="message__body">
      <header className="message__header">{t('header', { scope: 'inbox.conversation.disclaimer' })}</header>

      <p className="message__text">{t(disclaimer, { scope: 'inbox.conversation.disclaimer' })}</p>
    </div>
  </div>
)

Disclaimer.propTypes = {
  disclaimer: PropTypes.string.isRequired,
}

export default Disclaimer
