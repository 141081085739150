import acceptInvitationApi from 'Apiv2/acceptInvitationApi';
import declineInvitationApi from 'Apiv2/declineInvitationApi';
import setupSignupsFetcher from 'Components/CarePlanPlaceInvitationsV2/SignupsContainer/setupSignupsFetcher';
import { filterSignups } from 'Components/CarePlanPlaceInvitationsV2/SignupsContainer/SignupsFilters/filterActions';

export const getAllSignups = async (
  setIsTargetSignupModalOpen,
  fetchSignups,
  filterSignups,
  setFilteredSignups,
  setCurrentPageNumber,
  search,
  filterStatus,
  tab
) => {
  setIsTargetSignupModalOpen(false);
  const signups = await fetchSignups();
  filterSignups(setFilteredSignups, setCurrentPageNumber, signups, search, filterStatus, tab);
};

// this function will call API twice
// first time to update target signup and then close the modal
// second time to refetch all signups
// and update CarePlanPlaceInvitations parent component state
export const setupUpdateSignupWrapper = (
  acceptInvitationApiProvider = acceptInvitationApi,
  declineInvitationApiProvider = declineInvitationApi,
  setupSignupsFetcherProvider = setupSignupsFetcher,
  filterSignupsProvider = filterSignups,
  getAllSignupsProvider = getAllSignups
) => {
  return (
    setIsTargetSignupModalOpen,
    setTargetSignupModalStatus,
    setTargetSignupModalApiError,
    setSignups,
    setPageState,
    setFilteredSignups,
    setCurrentPageNumber,
    search,
    filterStatus
  ) => {
    return async (signupData) => {
      setTargetSignupModalApiError(null);
      setTargetSignupModalStatus('loading');

      try {
        let response;

        if (signupData.status === 'accepted') {
          response = await acceptInvitationApiProvider(signupData);
        } else {
          response = await declineInvitationApiProvider(signupData);
        }

        if (response.status === 200) {
          // should be (response.status === 200 && response.data.message) after api update
          setTargetSignupModalStatus('apiSuccess');

          const fetchSignups = setupSignupsFetcherProvider(setSignups, setPageState);

          setTimeout(() => {
            getAllSignupsProvider(
              setIsTargetSignupModalOpen,
              fetchSignups,
              filterSignupsProvider,
              setFilteredSignups,
              setCurrentPageNumber,
              search,
              filterStatus,
              signupData.entityType
            );
          }, 750);
        } else {
          throw 'Something went wrong.';
        }
      } catch (error) {
        setTargetSignupModalStatus('apiError');

        if (typeof error === 'string') {
          setTargetSignupModalApiError(error);
        } else if (
          typeof error === 'object' &&
          error !== null && // null is an object in JS
          typeof error.error === 'string'
        ) {
          setTargetSignupModalApiError(error.error);
        }
      }
    };
  };
};

export default setupUpdateSignupWrapper(
  acceptInvitationApi,
  declineInvitationApi,
  setupSignupsFetcher,
  filterSignups,
  getAllSignups
);
