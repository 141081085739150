import React from 'react';

import Templates from '../FormFields/Templates';
import Description from '../FormFields/Description';
import WhenShouldBeCompleted from '../FormFields/When/WhenShouldBeCompleted';
import Where from '../FormFields/Where';
import Who from '../FormFields/Who/Who';
import Resources from '../FormFields/Resources';
import Error from '../Error';

export const Appointment = () => {
  return (
    <div className="activity_modal">
      <Templates />
      <Description />
      <WhenShouldBeCompleted />
      <Where />
      <Resources />
      <Who /> {/* NOTE: Keep Who as the last element of ActivityModal */}
      <Error />
    </div>
  );
};

export default Appointment;
