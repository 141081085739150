import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'Components/Base/Icon'
import { t } from 'i18n'

const i18nOpts = { scope: 'care_plans.generic_order_section.generic_order' }

const RequestedHelp = ({ helpRequested, action, frequencyType }) => {
  return helpRequested ? (
    <div className="help-request-alert">
      <Icon name="warning" />
      {frequencyType === 'recurring' ? (
        <span className="help-request-alert__details">{t('no_help_request', i18nOpts)}</span>
      ) : (
        <>
          <span className="help-request-alert__details">{t('help_requested', i18nOpts)}</span>
          {action && (
            <span
              className="help-request-alert__details help-request-alert__details--action"
              onClick={action}
            >
              {t('view_details', i18nOpts)}
            </span>
          )}
        </>
      )}
    </div>
  ) : null
}

RequestedHelp.propTypes = {
  action: PropTypes.func,
  frequencyType: PropTypes.string,
  helpRequested: PropTypes.bool.isRequired,
}

RequestedHelp.defaultProps = {
  action: null,
  frequencyType: 'once',
}

export default RequestedHelp
