export const activityModalState = {
  DEFAULT: 'DEFAULT',
  LOADING: 'LOADING', // action button had been pressed, api interaction is happening
  ERROR: 'ERROR',
};

export const mode = {
  VIEW: 'VIEW',
  CREATE: 'CREATE',
  EDIT: 'EDIT',
};

export const activityType = {
  APPOINTMENT: 'APPOINTMENT',
  CHECK_IN: 'CHECK_IN',
  FORM: 'FORM',
  TASK: 'TASK',
};

export const activityKind = {
  GENERIC: 'GENERIC',
  MEDICAL: 'MEDICAL',
  PRESCRIPTION: 'PRESCRIPTION',
  GENERIC_HEALTH: 'GENERIC_HEALTH',
  PRESET: 'PRESET',
  REFERRAL: 'REFERRAL',
}

export const deleteButtonState = {
  DELETE: 'DELETE',
  CONFIRM: 'CONFIRM',
};