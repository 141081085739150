import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import { setActivityModalActivityProperties, setActivityModalShowRepeatsFields } from 'Actions/activityModal';

import InputGroup from 'Components/Forms/InputGroup';
import Icon from 'Components/Base/Icon';
import RadioOption from 'Components/Base/RadioOption';
import Checkbox from 'Components/Forms/Checkbox';

const i18nOpts = { scope: 'activity_modal.form_fields.when' };

const frequencies = [
  { id: 'daily', name: t('days', i18nOpts) },
  { id: 'weekly', name: t('weeks', i18nOpts) },
  { id: 'monthly', name: t('months', i18nOpts) },
];

export const Repeats = ({
  timeZone,
  activityProperties,
  formErrors,
  setActivityModalActivityProperties,
  setActivityModalShowRepeatsFields,
}) => {
  const { count, interval, frequency, endRepeat, until } = activityProperties;

  return (
    <>
      <div className="repeats_controller_container">
        <Checkbox
          checked={true}
          name="repeatsCheckbox"
          text={t('repeats_every', i18nOpts)}
          onChange={() => {
            // clear all Repeats fields
            setActivityModalActivityProperties({
              ...activityProperties,
              count: null,
              endRepeat: null,
              frequency: null,
              frequencyType: 'scheduled',
              interval: null,
              until: null,
            });
            setActivityModalShowRepeatsFields(false);
          }}
        />

        <InputGroup
          component="input"
          className="input repeats_controller_container__interval"
          name="interval"
          onChange={({ target: { value } }) => {
            setActivityModalActivityProperties({
              ...activityProperties,
              interval: value,
            });
          }}
          value={String(interval)}
          errors={formErrors}
          type="number"
          min="1"
          max="999"
        />

        <InputGroup
          component="select"
          className="input repeats_controller_container__frequency"
          name="frequency"
          onChange={({ target: { value } }) => {
            setActivityModalActivityProperties({
              ...activityProperties,
              frequency: value,
            });
          }}
          value={frequency}
          errors={formErrors}
        >
          {frequencies.map((option, idx) => {
            return (
              <option key={idx} value={option.id}>
                {option.name}
              </option>
            );
          })}
        </InputGroup>
      </div>

      <div className="repeats_controller_container__ends_on">
        <div className="repeats_controller_container__ends_on__description">{t('ends', i18nOpts)}</div>
        <div className="repeats_controller_container__ends_on__selector">
          <RadioOption
            isSelected={endRepeat === 'never'}
            onClick={() => {
              setActivityModalActivityProperties({
                ...activityProperties,
                endRepeat: 'never',
              });
            }}
          >
            {t('never', i18nOpts)}
          </RadioOption>
          <RadioOption
            isSelected={endRepeat === 'on_a_day'}
            onClick={() => {
              setActivityModalActivityProperties({
                ...activityProperties,
                endRepeat: 'on_a_day',
              });
            }}
          >
            <div className="radio_option__description__on_container">
              <span className="radio_option__description__on_container__copy">{t('on', i18nOpts)}</span>
              <InputGroup
                className="input radio_option__description__on_container__selector"
                name="until"
                onChange={({ target: { value } }) => {
                  setActivityModalActivityProperties({
                    ...activityProperties,
                    endRepeat: 'on_a_day',
                    until: value,
                  });
                }}
                value={until}
                timeZone={timeZone}
                component="datetime"
                errors={formErrors}
                withTime={false}
              />
            </div>
          </RadioOption>
          <RadioOption
            isSelected={endRepeat === 'times'}
            onClick={() => {
              setActivityModalActivityProperties({
                ...activityProperties,
                endRepeat: 'times',
              });
            }}
          >
            <div className="radio_option__description__on_container">
              <span className="radio_option__description__on_container__copy">{t('after', i18nOpts)}</span>
              <InputGroup
                component="input"
                className="input interval radio_option__description__on_container__selector"
                name="count"
                onChange={({ target: { value } }) => {
                  setActivityModalActivityProperties({
                    ...activityProperties,
                    count: value,
                  });
                }}
                value={String(count)}
                errors={formErrors}
                type="number"
                min="1"
                max="999"
                required
              />
              <span className="radio_option__description__on_container__copy">{t('times', i18nOpts)}</span>
            </div>
          </RadioOption>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ activityModal }) => {
  const { timeZone, activityProperties, formErrors } = activityModal;

  return { timeZone, activityProperties, formErrors };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalActivityProperties = (dispatch) => {
    return (activityProperties) => {
      dispatch(setActivityModalActivityProperties(activityProperties));
    };
  };

  const dispatchSetActivityModalShowRepeatsFields = (dispatch) => {
    return (showRepeatsFields) => {
      dispatch(setActivityModalShowRepeatsFields(showRepeatsFields));
    };
  };

  return {
    setActivityModalActivityProperties: dispatchSetActivityModalActivityProperties(dispatch),
    setActivityModalShowRepeatsFields: dispatchSetActivityModalShowRepeatsFields(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Repeats);
