import { Resources } from '../Api'

class CarePlanTemplate extends Resources {
  constructor() {
    super()
  }

  get url() {
    return '/care_plan_templates'
  }
}

export default CarePlanTemplate
