import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import Label from 'Components/Forms/Label';
import { t } from 'i18n';
import Loader from 'Components/Base/Loader';
import MultiSelectStyle from 'styles/MultiSelect';

const Select = React.lazy(() => import('react-select'));

const i18nOpts = { scope: 'care_plan_creators.resources.search' };

class TagSearchInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      input: '',
      selectedTags: [],
      suggestions: this.props.allTags,
    };

    this.handleOptionSelected = this.handleOptionSelected.bind(this);
  }

  handleInputChange = (input, { action }) => {
    if (action === 'input-change') {
      this.setState({ input });
      this.props.handleFilterModalResources(input, this.state.selectedTags);
    }
  };

  handleOptionSelected = (option, { action }) => {
    const input = action === 'select-option' ? '' : this.state.input; // clear input if option was selected
    this.setState({ input, selectedTags: option });
    this.props.handleFilterModalResources(input, option);
  };

  render() {
    return (
      <div className="form__row form__row--underline">
        <div className="input">
          <Label inputName={'tag'} text={t('label', i18nOpts)} />
          <Suspense fallback={<Loader />}>
            <Select
              name="tag"
              noOptionsMessage={() => null}
              styles={MultiSelectStyle}
              className="multi-select"
              inputValue={this.state.input}
              placeholder={t('search_placeholder', i18nOpts)}
              options={this.state.suggestions}
              isClearable={true}
              isSearchable={true}
              onInputChange={this.handleInputChange}
              onChange={this.handleOptionSelected}
              maxMenuHeight={160}
              isMulti
            />
          </Suspense>
        </div>
      </div>
    );
  }
}

TagSearchInput.propTypes = {
  allTags: PropTypes.array,
  handleFilterModalResources: PropTypes.func.isRequired,
  maxSuggestions: PropTypes.number,
};

TagSearchInput.defaultProps = {
  allTags: [],
  maxSuggestions: 2,
};

export default TagSearchInput;
