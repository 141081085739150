export const getAddressFields = (addressFields) => {
  if (!addressFields) {
    return null;
  }

  const { address, extendedAddress, city, region, postalCode, countryCode } = addressFields;

  return {
    address,
    city,
    region,
    extended_address: extendedAddress,
    postal_code: postalCode,
    country_code: countryCode,
  };
};

export const parseAssignments = (assignments) => {
  if (!assignments || assignments.length <= 0) {
    return null;
  }

  return assignments.map(({ value }) => {
    return { id: value };
  });
};

export const getActivityKind = (type) => {
  switch (type) {
    case 'TASK':
      // !! can also be 'generic' if created by patient
      // we need logic to determine the author role and pass it here
      return 'generic_health';
    case 'APPOINTMENT':
      return 'medical';
    case 'CHECK_IN':
      return null;
    case 'FORM':
      return null;
    default:
      return null;
  }
};