import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Datetime from 'react-datetime'
import moment from 'ct-moment'

class DateInput extends Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.renderInput = this.renderInput.bind(this)

    this.state = {
      value: this.props.value,
    }
  }

  handleChange(evt) {
    const value = evt.format(moment.HTML5_FMT.DATE)
    this.setState({ value, open: false })
    this.props.onChange({ target: { name: this.props.name, value } })
  }

  renderInput(props, openCalendar) {
    return (
      <input
        readOnly
        value={this.state.value}
        style={{ background: 'white' }}
        type="text"
        placeholder={this.props.placeholder}
        onFocus={openCalendar}
      />
    )
  }

  render() {
    return (
      <div>
        <Datetime
          closeOnSelect
          onChange={this.handleChange}
          dateFormat="YYYY-MM-DD"
          timeFormat={false}
          defaultValue={moment(this.state.value)}
          renderInput={this.renderInput}
          inputProps={{ name: this.props.name }}
        />

        {this.props.audit && <span className="audited">{this.props.audit}</span>}
      </div>
    )
  }
}

DateInput.propTypes = {
  audit: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
}

DateInput.defaultProps = {
  label: '',
  value: '',
  audit: '',
}

export default DateInput
