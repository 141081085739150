import CarePlanApi from 'Api/CarePlan'
import CarePlanTemplateApi from 'Api/TemplateCreator/CarePlanTemplate'

class CarePlanDispatcher {
  constructor(parentId, isTemplate) {
    this.id = parentId
    this.isTemplate = isTemplate
  }

  update(data) {
    return this.api.update(data)
  }

  updateDetails(data) {
    return this.api.updateDetails(data)
  }

  updateInstructions(data) {
    return this.api.updateInstructions(data)
  }

  get api() {
    if (this.isTemplate) {
      return new CarePlanTemplateApi(this.id)
    } else {
      return new CarePlanApi(this.id)
    }
  }
}

export default CarePlanDispatcher
