import Button from 'Components/Base/Button';
import { t } from 'i18n';
import React from 'react';
import withTaskActions from './withTaskActions';

const i18nOpts = { scope: 'tasks.actions_container' };

const TaskToggle = ({ handleClick, messages: { action } }) => {

  let buttonText;
  switch (action) {
    case 'Done!':
    case 'Filled':
      buttonText = t('mark_complete', i18nOpts);
      break;
    case 'Revert':
      buttonText = t('revert', i18nOpts);
      break;
    default:
      buttonText = action;
      break;
  }

  return (
    <Button className="buttonV2 task-actions__btns__btn" onClick={handleClick}>
      {buttonText}
    </Button>
  );
};

export default withTaskActions(TaskToggle, true);
