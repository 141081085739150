import React, { useState } from 'react';
import Icon from 'Components/Base/Icon';
import PropTypes from 'prop-types';
import { t } from 'i18n';

const i18nOptsClipboardCopy = { scope: 'dashboard.clipboard_copy' };

const CopyButton = ({ onCopyButtonClick }) => {
  const [copyButtonText, setCopyButtonText] = useState(t('copy_as_text', i18nOptsClipboardCopy));

  const handleClick = (e) => {
    e.preventDefault();
    setCopyButtonText(t('copied', i18nOptsClipboardCopy));
    onCopyButtonClick();
    setTimeout(() => {
      setCopyButtonText(t('copy_as_text', i18nOptsClipboardCopy));
    }, 3000);
  };

  return (
    <button className="insulin-dosing__copy-button" onClick={handleClick}>
      <Icon name="copy" className="insulin-dosing__copy-icon" />
      <span className="insulin-dosing__copy-text">{copyButtonText}</span>
    </button>
  );
};

CopyButton.propTypes = {
  onCopyButtonClick: PropTypes.func,
};

CopyButton.defaultProps = {};

export default CopyButton;
