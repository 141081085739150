import ContactsApi from 'Api/Inbox/Contact';
import ChannelPageApi from 'Api/Inbox/ChannelPage';
import ChannelSortApi from 'Api/Inbox/ChannelSort';

export const REQUEST_CONTACTS = 'REQUEST_CONTACTS';
export const REQUEST_CHANNEL_PAGE = 'REQUEST_CHANNEL_PAGE';
export const MARK_CONVERSATION_AS_READ = 'MARK_CONVERSATION_AS_READ';
export const MARK_CONVERSATION_AS_UNREAD = 'MARK_CONVERSATION_AS_UNREAD';
export const RECEIVE_CONTACTS = 'RECEIVE_CONTACTS';
export const RECEIVE_CHANNEL_CONTACTS = 'RECEIVE_CHANNEL_CONTACTS';
export const RECEIVE_CHANNEL_PAGE = 'RECEIVE_CHANNEL_PAGE';
export const SHOW_CONTACTS = 'SHOW_CONTACTS';
export const ADD_CONTACT = 'ADD_CONTACT';
export const ADD_CHANNEL_CONTACT = 'ADD_CHANNEL_CONTACT';
export const UPDATE_HAS_MORE_CHANNEL_PAGE = 'UPDATE_HAS_MORE_CHANNEL_PAGE';
export const RECEIVE_CHANNEL_SEARCH = 'RECEIVE_CHANNEL_SEARCH';
export const CLEAR_CHANNEL_SEARCH = 'CLEAR_CHANNEL_SEARCH';

export const fetchContacts = (includeChannels = false) => {
  return (dispatch) => {
    dispatch({ type: REQUEST_CONTACTS });

    return new ContactsApi()
      .index()
      .then((res) => res.json())
      .then(({ contacts }) => {
        dispatch(receiveContacts(contacts));
        if (includeChannels) {
          dispatch(receiveChannelContacts(contacts));
        }
      });
  };
};

export const fetchNextChannelContactsPage = ({ sortBy = null, lastChannelContact = null }) => {
  return (dispatch) => {
    dispatch({ type: REQUEST_CHANNEL_PAGE });

    const lastConversationId = lastChannelContact.conversation.id || lastChannelContact.id;

    return new ChannelPageApi({ sortBy: sortBy, lastConversationId: lastConversationId })
      .getPage()
      .then((res) => res.json())
      .then(({ channelContacts, hasMoreChannelPage }) => {
        dispatch({ type: RECEIVE_CHANNEL_PAGE, channelContacts });
        dispatch({ type: UPDATE_HAS_MORE_CHANNEL_PAGE, hasMoreChannelPage });
      });
  };
};

export const fetchSortedChannelContacts = (sortBy) => {
  return (dispatch) => {
    dispatch({ type: REQUEST_CHANNEL_PAGE });

    return new ChannelSortApi({ sortBy: sortBy })
      .sort()
      .then((res) => res.json())
      .then(({ channelContacts, hasMoreChannelPage }) => {
        dispatch({ type: RECEIVE_CHANNEL_CONTACTS, channelContacts }); // replace all contacts with new sorted list
        dispatch({ type: UPDATE_HAS_MORE_CHANNEL_PAGE, hasMoreChannelPage });
      });
  };
};

export const markConversationAsRead = (id) => ({ type: MARK_CONVERSATION_AS_READ, id });

export const markConversationAsUnread = (id) => ({ type: MARK_CONVERSATION_AS_UNREAD, id });

export const receiveContacts = (contacts) => ({ type: RECEIVE_CONTACTS, contacts });

export const receiveChannelContacts = (contacts) => {
  return (dispatch) => {
    const channelContacts = contacts?.filter((contact) => contact.type === 'Channel');
    dispatch({ type: RECEIVE_CHANNEL_CONTACTS, channelContacts });
  };
};

export const showContacts = () => ({ type: SHOW_CONTACTS });

export const addContact = (contact) => ({ type: ADD_CONTACT, contact });

export const addChannelContact = (contact) => ({ type: ADD_CHANNEL_CONTACT, contact });

export const updateHasMoreChannelPage = (hasMoreChannelPage) => {
  return (dispatch) => {
    if (hasMoreChannelPage === null || hasMoreChannelPage === undefined) {
      const hasMoreChannelPage = false;
      dispatch({ type: UPDATE_HAS_MORE_CHANNEL_PAGE, hasMoreChannelPage });
    } else {
      dispatch({ type: UPDATE_HAS_MORE_CHANNEL_PAGE, hasMoreChannelPage });
    }
  };
};
