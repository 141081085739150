import React from 'react'
import PropTypes from 'prop-types'

import Avatar from 'PulseBackports/Avatar'

const AsideHeader = ({ name, photo }) => (
  <div className="overview-aside-header">
    <Avatar
      src={photo}
      fullName={name}
      className="overview-aside-header__image"
      size={2.5}
      border={true}
    />
    <span>{name}</span>
  </div>
)

AsideHeader.propTypes = {
  name: PropTypes.string,
  photo: PropTypes.string,
}

AsideHeader.defaultProps = {
  name: '',
  photo: '',
}

export default AsideHeader
