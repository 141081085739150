import React from 'react'
import PropTypes from 'prop-types'
import moment from 'ct-moment'

import Activity from '../Activity'

import { ascBy } from 'Utilities/sort'

const eventKey = ({ type, id, dueAt }) => `${type}:${id}:${dueAt}`

const DailyEventList = ({ day, events }) => {
  if (!events || !events.length) {
    return null
  }

  return (
    <section>
      <div className="header">
        <span className="chip chip--blue-grey-light">
          {`${moment(day).format('dddd')}, ${moment(day).format('MMMM Do')}`}
        </span>
      </div>

      {events.sort(ascBy('dueAt')).map((event) => (
        <Activity key={eventKey(event)} {...event} />
      ))}
    </section>
  )
}

DailyEventList.propTypes = {
  day: PropTypes.string.isRequired,
  events: PropTypes.array,
}

DailyEventList.defaultProps = {
  events: [],
}

export default DailyEventList
