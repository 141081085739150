import calculateFill from './calculateFill';

const getRenderData = (data) =>
  data.map((day) => {
    const responseValue = day.value;
    return {
      ...day,
      displayValue: [null, ''].includes(responseValue) ? 0 : 9,
      fill: calculateFill(responseValue),
    };
  });
export default getRenderData;
