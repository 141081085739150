import React from 'react'
import PropTypes from 'prop-types'
import Button from 'Components/Base/Button'
import { t } from 'i18n'

const RequestButton = ({ helpRequested, action }) => {
  const i18nOpts = {
    scope: 'care_plans.generic_order_section.generic_order_form',
  }

  return (
    <Button
      type="button"
      className="btn btn--secondary"
      onClick={action}
      text={helpRequested ? t('edit_request', i18nOpts) : t('add_request', i18nOpts)}
    />
  )
}

RequestButton.propTypes = { action: PropTypes.func, helpRequested: PropTypes.bool }
RequestButton.defaultProps = { action: null, helpRequested: false }

export default RequestButton
