import React, { Fragment } from 'react'
import { t } from 'i18n'
import PropTypes from 'prop-types'
import InputGroup from 'Components/Forms/InputGroup'
import Icon from 'Components/Base/Icon'
import InputList from 'Components/CarePlanCreators/InputList'

const i18nOpts = { scope: 'care_plan_creators.details.details_form' }

const DiagnosesList = ({ onChange, diagnoses, addDiagnosis }) => (
  <Fragment>
    <hr />

    <label className="careplan__list-label">{t('list_label', i18nOpts)}</label>

    <InputList
      list={diagnoses}
      onChange={onChange}
      name="carePlan.diagnoses"
      emptyMessage={t('empty_list_none_added', i18nOpts)}
      render={({ conditionCode }, name) => (
        <InputGroup
          component="input"
          className="careplan-wizard__diagnosis"
          placeholder={t('diagnosis_placeholder', i18nOpts)}
          name={`${name}.conditionCode`}
          value={conditionCode}
          onChange={onChange}
          type="text"
        />
      )}
    />

    <button className="btn btn--link careplan__list-add-button" onClick={addDiagnosis}>
      <Icon name="plus_square" className="careplan-wizard__icon_add" />
      {t('add_diagnosis', i18nOpts)}
    </button>
  </Fragment>
)

DiagnosesList.propTypes = {
  addDiagnosis: PropTypes.func.isRequired,
  diagnoses: PropTypes.array,
  onChange: PropTypes.func.isRequired,
}

DiagnosesList.defaultProps = {
  diagnoses: [],
}

export default DiagnosesList
