import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { t } from 'i18n';

const i18nOpts = { scope: 'pagination' };
const entryI18nOpts = { scope: 'care_plan_place_invitations.index' };

const DisplayText = ({ currentPageNumber, maxItemCountPerPage, totalItemCount }) => (
  <span>
    {totalItemCount > maxItemCountPerPage
      ? t('more_pages.display_entries', {
          ...i18nOpts,
          entry_name: t('invitations', entryI18nOpts),
          first: (currentPageNumber - 1) * maxItemCountPerPage + 1,
          last:
            currentPageNumber * maxItemCountPerPage < totalItemCount
              ? currentPageNumber * maxItemCountPerPage
              : totalItemCount,
          total: totalItemCount,
        })
      : t('one_page.display_entries.other', {
          ...i18nOpts,
          entry_name: t('invitations', entryI18nOpts),
          count: totalItemCount,
        })}
  </span>
);

const mapStateToProps = ({ carePlanPlaceInvitationsPage }) => {
  const {
    filteredSignups,
    pagination: { currentPageNumber, maxItemCountPerPage },
  } = carePlanPlaceInvitationsPage;

  return {
    currentPageNumber,
    maxItemCountPerPage,
    totalItemCount: filteredSignups.length,
  };
};

DisplayText.propTypes = {
  currentPageNumber: PropTypes.number,
  maxItemCountPerPage: PropTypes.number,
  totalItemCount: PropTypes.number,
};

export default connect(mapStateToProps, null)(DisplayText);
