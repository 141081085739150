import { request } from '../handleRequest';

export const buildRequestURL = (carePlanId, type, recurring) => {
  let url = `/care_plan_templates/${carePlanId}/`;

  switch (type) {
    case 'Task':
      url += recurring ? 'repeated_task_templates' : 'task_templates';
      break;
    case 'Appointment':
      url += recurring ? 'repeated_appointment_templates' : 'task_templates'; // task_template path also handles singular appointments
      break;
    default:
      url = '/404';
  }

  return url;
};

export const createActivityTemplateApiWrapper = (
  requestProvider = request,
  buildRequestURLProvider = buildRequestURL
) => {
  return async (carePlanId, activity, type, recurring) => {
    try {
      const {
        address_attributes,
        assignments,
        completed_at,
        count,
        days_offset,
        due_at,
        end_repeat,
        frequency,
        frequency_type,
        id,
        interval,
        kind,
        practitioner,
        resources,
        selectedActivityTemplateId,
        text,
        time_offset,
        until,
        url,
      } = activity;

      const body = {
        type,
        address_attributes,
        assignments,
        completed_at,
        count,
        days_offset,
        due_at,
        end_repeat,
        frequency,
        frequency_type,
        id,
        interval,
        kind,
        practitioner,
        resources,
        selectedActivityTemplateId,
        text,
        time_offset,
        until,
        url,
      };

      const endpointUrl = buildRequestURLProvider(carePlanId, type, recurring);
      const response = await requestProvider(endpointUrl, 'POST', body);

      // notes for backend
      // activities and repeated activities should both return 201 on successful creation
      // they need to return "activity", not "order"
      // can be done in the later ticket when "handleSuccess" in orderCreator is rewritten

      const status = response.status;
      const responseBody = await response.json();
      const { order, repeatedTask, repeatedAppointment } = responseBody;

      // this should return { status, activity } no matter the case
      return { status, order, repeatedTask, repeatedAppointment };
    } catch (error) {
      return { status: 422, error };
    }
  };
};

export default createActivityTemplateApiWrapper(request, buildRequestURL);
