import React from 'react';
import PropTypes from 'prop-types';

const Textarea = ({
  name,
  value,
  placeholder,
  required,
  readonly,
  onChange,
  onKeyPress,
  autocomplete,
  autofocus,
  onBlur,
  onFocus,
}) => {
  return (
    <textarea
      className={required ? 'required' : 'optional'}
      placeholder={placeholder ? placeholder : ''}
      name={name}
      required={required ? 'required' : false}
      aria-required={required ? true : false}
      id={name}
      onChange={onChange}
      onKeyPress={onKeyPress}
      rows={1}
      value={value}
      readOnly={readonly}
      autoComplete={autocomplete}
      autoFocus={autofocus}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
};

Textarea.propTypes = {
  // By default autocomplete should be off to align with our caching policy
  // In some circumstances, it may be reasonable to use autocomplete but be
  // very cautious before doing this.
  autocomplete: PropTypes.string,
  autofocus: PropTypes.bool,
  handleChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  readonly: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

Textarea.defaultProps = {
  autocomplete: 'off',
  autofocus: false,
  type: 'text',
  readonly: false,
};

export default Textarea;
