import React, { Component } from 'react'
import PropTypes from 'prop-types'

class EmojiInput extends Component {
  constructor(props) {
    super(props)

    this.setToggleRef = (element) => {
      this.toggleRef = element
    }
    this.setEmojiPickerMenuRef = (element) => {
      this.emojiPickerMenuRef = element
    }
    this.state = { showEmojiPicker: false }
    this.handleEmoji = this.handleEmoji.bind(this)
    this.closePicker = this.closePicker.bind(this)
    this.openPicker = this.openPicker.bind(this)

    this.state = {
      showEmojiPicker: false,
    }
  }

  componentDidUpdate() {
    if (this.state.showEmojiPicker) {
      document.body.addEventListener('click', this.closePicker)
    } else {
      document.body.removeEventListener('click', this.closePicker)
    }
  }

  handleEmoji(code, { name }) {
    this.setState({ showEmojiPicker: false })
    this.props.onChange({ target: { name: this.props.name, value: `${this.props.value}:${name}:` } })
  }

  closePicker(evt) {
    if (this.toggleRef.contains(evt.target)) {
      evt.stopPropagation()
    }
    if (!this.emojiPickerMenuRef.contains(evt.target)) {
      this.setState({ showEmojiPicker: false })
    }
  }

  openPicker() {
    this.setState({ showEmojiPicker: true })
  }

  render() {
    const { required, onChange, placeholder, name, value, component: Input } = this.props

    return (
      <Input
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        value={value}
        onEmojiClick={this.handleEmoji}
        openPicker={this.openPicker}
        showEmojiPicker={this.state.showEmojiPicker}
        setToggleRef={this.setToggleRef}
        setEmojiPickerMenuRef={this.setEmojiPickerMenuRef}
      />
    )
  }
}

EmojiInput.propTypes = {
  component: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
}

EmojiInput.defaultProps = {
  placeholder: '',
  required: false,
  value: '',
}

export default EmojiInput
