import React from 'react';
import { t } from 'i18n';
import { Icon } from 'Components/Base';

const i18nOpts = { scope: 'care_plan_action_plan_page.activities_container.forms_card.form' };

const Form = ({ name, formattedDueAt, isComplete }) => (
  <li className="order">
    <div className="order__section text-truncate">
      <div>
        <strong>{name || 'unnamed'}</strong>
      </div>
      {formattedDueAt && <span>{formattedDueAt}</span>}
    </div>
    <div className="order__section">
      {isComplete ? (
        <span className="order--complete">
          <Icon name="check_alt3" className="order__section--check-icon" />
          {t('completed', i18nOpts)}
        </span>
      ) : (
        <span className="order--due">{t('not_completed', i18nOpts)}</span>
      )}
    </div>
  </li>
);

export default Form;
