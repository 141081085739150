import { actionTypes } from 'Actions/notificationTray'

const initialState = {
  isOpen: false,
  isFetching: false,
  lastFetch: null,
  notifications: [],
}

const notificationTray = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_TRAY:
      return {
        ...state,
        isOpen: true,
      }
    case actionTypes.CLOSE_TRAY:
      return {
        ...state,
        isOpen: false,
      }
    case actionTypes.FETCH_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case actionTypes.FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.payload,
      }
    case actionTypes.MARK_AS_READ_REQUEST:
      return {
        ...state,
      }
    case actionTypes.MARK_AS_READ_SUCCESS:
      return {
        ...state,
        notifications: state.notifications.filter((notification) => notification.id !== action.payload.id),
      }
    default:
      return state
  }
}

export default notificationTray
