import React from 'react'
import PropTypes from 'prop-types'

const ActivityBody = ({ text, children }) => {
  return (
    <div className="row__col row__col--truncate">
      <div className="text-truncate">
        <span className="text-medium h4">{text}</span>
      </div>

      {children}
    </div>
  )
}

ActivityBody.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string,
}

ActivityBody.defaultProps = {
  children: null,
  text: '',
}

export default ActivityBody
