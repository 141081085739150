import calculateFill from './calculateFill';

const getRenderData = (data) =>
  data.reduce((acc, question) => {
    const dataWithFill = question.data.map((response) => {
      const responseValue = response.value;

      return {
        ...response,
        // need to hardcode this value because recharts cannot create heatmap charts,
        // we still need the response's value to calculate the fill color
        displayValue: responseValue !== '' ? 100 : 0,
        fill: calculateFill(responseValue),
      };
    });
    return [...acc, { ...question, data: dataWithFill }];
  }, []);

export default getRenderData;
