import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import SearchInput from 'Components/Forms/SearchInput';
import InputGroup from 'Components/Forms/InputGroup';
import Button from 'Components/Base/Button';

import { t } from 'i18n';

const i18nOpts = { scope: 'care_plan_creators.resources' };

class ResourceNavigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewportWidth: window.innerWidth,
      isDisplayingTabs:
        this.props.tabs.length <= this.props.maxTabs && window.innerWidth >= this.props.breakpoint,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      const isDisplayingTabs =
        this.props.tabs.length <= this.props.maxTabs && window.innerWidth >= this.props.breakpoint;
      this.setState({ isDisplayingTabs });
    });
  }

  render() {
    const {
      tabs,
      input,
      selectedTab,
      handleInputChange,
      handleSelectTab,
      handleOpenAddResourceModal,
    } = this.props;

    if (!this.props.isNavigationDisplayed) return null;

    return (
      <Fragment>
        <div className="resource__navigation">
          <SearchInput
            className="item-pill__item-resource-list-search"
            name="resourceFilter"
            placeholder={t('search_placeholder', i18nOpts)}
            onChange={handleInputChange}
            value={input}
          />

          {!this.state.isDisplayingTabs && (
            <InputGroup
              component="select"
              className="input"
              name="resources-select"
              onChange={(evt) => {
                handleSelectTab(evt.target.value);
              }}
              value={selectedTab}
            >
              {tabs.map((tab) => (
                <option key={tab} value={tab}>
                  {tab}
                </option>
              ))}
            </InputGroup>
          )}
          <Button text={t('add_resource', i18nOpts)} onClick={handleOpenAddResourceModal} />
        </div>

        {this.state.isDisplayingTabs && (
          <ul className="tabs__content tabs__content--navigation-header">
            {tabs.map((tagText) => (
              <li
                key={tagText}
                className={
                  selectedTab === tagText
                    ? 'tab tab--selected tab--navigation-header'
                    : 'tab tab--navigation-header'
                }
                onClick={() => {
                  handleSelectTab(tagText);
                }}
              >
                {tagText}
              </li>
            ))}
          </ul>
        )}
      </Fragment>
    );
  }
}

ResourceNavigation.propTypes = {
  breakpoint: PropTypes.number,
  handleInputChange: PropTypes.func.isRequired,
  handleOpenAddResourceModal: PropTypes.func.isRequired,
  handleSelectTab: PropTypes.func.isRequired,
  input: PropTypes.string.isRequired,
  isNavigationDisplayed: PropTypes.bool,
  maxTabs: PropTypes.number,
  selectedTab: PropTypes.string.isRequired,
  tabs: PropTypes.array.isRequired,
};

ResourceNavigation.defaultProps = {
  breakpoint: 1200,
  isNavigationDisplayed: true,
  maxTabs: 6,
};

export default ResourceNavigation;
