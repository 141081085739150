import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Button, ModalFooter } from 'Components/Base'
import { Modal } from 'Components/Base/Modal'
import Submit from 'Components/Forms/Submit'
import InputGroup from 'Components/Forms/InputGroup'

const i18nOpts = { scope: 'care_plans.checkin_section.modal' }

const frequencyTypes = [
  { id: '', name: t('blank', i18nOpts) },
  { id: 'once', name: t('once', i18nOpts) },
  { id: 'scheduled', name: t('scheduled', i18nOpts) },
  { id: 'recurring', name: t('recurring', i18nOpts) },
]
const frequencies = [
  { id: '', name: t('blank', i18nOpts) },
  { id: 'daily', name: t('daily', i18nOpts) },
  { id: 'weekly', name: t('weekly', i18nOpts) },
  { id: 'monthly', name: t('monthly', i18nOpts) },
]

const endRepeats = [
  { id: 'never', name: t('end_never', i18nOpts) },
  { id: 'times', name: t('after', i18nOpts) },
  { id: 'on_a_day', name: t('end_on_a_day', i18nOpts) },
]

const CheckInModalForm = ({
  checkInTemplateId,
  checkInTemplates,
  count,
  dueAt,
  endRepeat,
  errors,
  frequencyType,
  frequency,
  interval,
  isOpen,
  onActionClick,
  onChange,
  onSubmit,
  timeZone,
  until,
}) => (
  <Modal title={t('header', i18nOpts)} isOpen={isOpen} closeModal={onActionClick}>
    <form onSubmit={onSubmit} noValidate>
      <InputGroup
        component="select"
        name="checkInTemplateId"
        label={t('check_in_type', i18nOpts)}
        onChange={onChange}
        value={checkInTemplateId}
        required
      >
        {checkInTemplates.map((option) => {
          return (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          )
        })}
      </InputGroup>

      <InputGroup
        component="select"
        className="input input--half"
        name="frequencyType"
        label={t('recurring', i18nOpts)}
        onChange={onChange}
        value={frequencyType}
        errors={errors}
        required
      >
        {frequencyTypes.map((option, idx) => {
          return (
            <option key={idx} value={option.id}>
              {option.name}
            </option>
          )
        })}
      </InputGroup>

      {frequencyType && frequencyType !== 'once' ? (
        <InputGroup
          component="datetime"
          className="input input--half"
          label={t('due_at', i18nOpts)}
          value={dueAt}
          name="dueAt"
          onChange={onChange}
          errors={errors}
          timeZone={timeZone}
          ensureVisibility
          required
        />
      ) : null}

      {frequencyType === 'recurring' ? (
        <div>
          <div className="form__row row-justify-start">
            <InputGroup
              component="select"
              className="input input--half"
              name="frequency"
              onChange={onChange}
              value={frequency}
              errors={errors}
              label={t('repeats', i18nOpts)}
            >
              {frequencies.map((option, idx) => {
                return (
                  <option key={idx} value={option.id}>
                    {option.name}
                  </option>
                )
              })}
            </InputGroup>

            {frequency && (
              <div>
                <InputGroup
                  component="input"
                  className="input input--one-third interval"
                  name="interval"
                  label={t('every', i18nOpts)}
                  onChange={onChange}
                  value={interval}
                  errors={errors}
                  type="number"
                  min="1"
                  max="999"
                  required
                />
                <div className="trail-text">
                  {t(frequency, { scope: 'tasks.form.interval', count: parseInt(interval) })}
                </div>
              </div>
            )}
          </div>

          <div className="form__row row-justify-start">
            <InputGroup
              component="select"
              className="input input--half"
              name="endRepeat"
              onChange={onChange}
              errors={errors}
              value={endRepeat}
              label={t('end_repeat', i18nOpts)}
            >
              {endRepeats.map((option, idx) => {
                return (
                  <option key={idx} value={option.id}>
                    {option.name}
                  </option>
                )
              })}
            </InputGroup>

            {endRepeat === 'times' ? (
              <div>
                <InputGroup
                  component="input"
                  className="input input--one-third count"
                  name="count"
                  label={t('after', i18nOpts)}
                  onChange={onChange}
                  errors={errors}
                  value={count}
                  type="number"
                  min="1"
                  max="999"
                  required
                />
                <div className="trail-text">{t('count.other', i18nOpts)}</div>
              </div>
            ) : null}
            {endRepeat === 'on_a_day' ? (
              <InputGroup
                className="input input--one-third until"
                label={t('end_date', i18nOpts)}
                name="until"
                onChange={onChange}
                value={until}
                timeZone={timeZone}
                component="datetime"
                errors={errors}
                required
                ensureVisibility
                withTime={false}
              />
            ) : null}
          </div>
        </div>
      ) : null}

      <ModalFooter
        renderRight={() => (
          <Fragment>
            <Button text={t('cancel', i18nOpts)} className="btn btn--secondary" onClick={onActionClick} />
            <Submit value={t('submit', i18nOpts)} />
          </Fragment>
        )}
      />
    </form>
  </Modal>
)

CheckInModalForm.propTypes = {
  checkInTemplateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checkInTemplates: PropTypes.array,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dueAt: PropTypes.string,
  endRepeat: PropTypes.string,
  errors: PropTypes.object,
  frequency: PropTypes.string,
  frequencyType: PropTypes.string,
  interval: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isOpen: PropTypes.bool,
  onActionClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  timeZone: PropTypes.string,
  until: PropTypes.string,
}

CheckInModalForm.defaultProps = {
  checkInTemplateId: '',
  checkInTemplates: [],
  count: '',
  dueAt: '',
  endRepeat: '',
  errors: {},
  frequency: '',
  frequencyType: 'once',
  interval: '',
  isOpen: false,
  timeZone: '',
  until: '',
}

export default CheckInModalForm
