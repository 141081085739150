import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'i18n';

import useComponentDidMount from 'Hooks/useComponentDidMount';
import InputGroup from 'Components/Forms/InputGroup';
import ActionButton from 'Components/Base/ActionButton';
import ApiErrorMessage from '../ApiErrorMessage';
import { Icon } from 'Components/Base';

import getFormHeaderClass from '../getFormHeaderClass';

import {
  setTargetUserInfo,
  setCurrentStep,
  goToNextStep,
  setApiErrorMessage,
  setIsLoading,
} from 'Actions/signupPage';

import setupUserValidator from '../../setupUserValidator';
import { setupFirstNameValidator, setupLastNameValidator } from './validateFormFields';

const i18nOpts = { scope: 'registration.patient' };

const PatientForm = ({
  currentStep,
  index,
  setCurrentStep,
  setTargetUserInfo,
  goToNextStep,
  user,
  setApiErrorMessage,
  setIsLoading,
  validatePatientUrl,
  apiErrorMessage,
  isLoading,
}) => {
  const isComponentMounted = useComponentDidMount();

  const completed = currentStep > index;
  const isOpen = currentStep === index;

  const { patientFirstName, patientLastName } = user;
  const disableNext = [patientFirstName, patientLastName].some((value) => ['', false].includes(value));

  const validateUser = setupUserValidator(setApiErrorMessage, setIsLoading, goToNextStep);
  const [firstNameFocused, setFirstNameFocused] = useState(false);
  const [firstNameDirty, setFirstNameDirty] = useState(false);
  const [lastNameFocused, setLastNameFocused] = useState(false);
  const [lastNameDirty, setLastNameDirty] = useState(false);
  const [formErrors, setFormErrors] = useState({
    patientFirstName: '',
    patientLastName: '',
  });

  const handleFirstNameValidation = setupFirstNameValidator(formErrors, setFormErrors, t, i18nOpts);
  const handleLastNameValidation = setupLastNameValidator(formErrors, setFormErrors, t, i18nOpts);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    handleFirstNameValidation(patientFirstName);
  }, [firstNameFocused]);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    handleLastNameValidation(patientLastName);
  }, [lastNameFocused]);

  return (
    <>
      <div
        className={getFormHeaderClass(completed, isOpen)}
        onClick={() => completed && setCurrentStep(index)}
      >
        <div className="header-title">{t('who', i18nOpts)}</div>
        {completed && !isOpen && (
          <Icon className="registrationV2__form__header__success_icon icon icon--bg" name="check" />
        )}
        {isOpen && <div className="header-subtitle">{t('subtitle', i18nOpts)}</div>}
      </div>

      {isOpen && (
        <>
          <div className="form__body">
            <div className="form__row">
              <InputGroup
                component="input"
                name="patientFirstName"
                label={t('first_name', i18nOpts)}
                value={patientFirstName}
                onBlur={() => {
                  setFirstNameDirty(true);
                  setFirstNameFocused(!firstNameFocused);
                }}
                onChange={({ target }) => {
                  if (firstNameDirty) {
                    handleFirstNameValidation(target.value);
                  }
                  setTargetUserInfo(target);
                }}
                errors={formErrors}
              />

              <InputGroup
                component="input"
                name="patientLastName"
                label={t('last_name', i18nOpts)}
                value={patientLastName}
                onBlur={() => {
                  setLastNameDirty(true);
                  setLastNameFocused(!firstNameFocused);
                }}
                onChange={({ target }) => {
                  if (lastNameDirty) {
                    handleLastNameValidation(target.value);
                  }
                  setTargetUserInfo(target);
                }}
                errors={formErrors}
              />
            </div>

            <ApiErrorMessage apiErrorMessage={apiErrorMessage} />
          </div>

          <div className="form__footer">
            <ActionButton
              className="form__footer__submit"
              isLoading={isLoading}
              disabled={isLoading || disableNext}
              onClick={() => {
                validateUser(user, validatePatientUrl);
              }}
              content={t('next', i18nOpts)}
            />
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ signupPage: { isLoading, apiErrorMessage, user, currentStep } }) => {
  return {
    currentStep,
    isLoading,
    apiErrorMessage,
    user,
  };
};

const mapDispatchToProps = {
  setCurrentStep: (index) => setCurrentStep(index),
  setTargetUserInfo: (targetInfo) => setTargetUserInfo(targetInfo),
  goToNextStep,
  setIsLoading: (isLoading) => setIsLoading(isLoading),
  setApiErrorMessage: (apiErrorMessage) => setApiErrorMessage(apiErrorMessage),
};

PatientForm.propTypes = {
  currentStep: PropTypes.number.isRequired,
  goToNextStep: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  setApiErrorMessage: PropTypes.func.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setTargetUserInfo: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  validatePatientUrl: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientForm);
