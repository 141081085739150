import { useState } from 'react'

const useToggleState = (initialState = false) => {
  const [state, setState] = useState(initialState)

  const toggleState = () => setState((state) => !state)

  return [state, toggleState, setState]
}

export default useToggleState
