import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import Prescription from './Prescription'
import Form from './PrescriptionForm'
import Submit from 'Components/Forms/Submit'
import { Card } from 'Components/Base/Card/'

import orderCreator from '../orderCreator'

const i18nOpts = { scope: 'care_plans.prescription_section.index' }

class PrescriptionSection extends Component {
  get cardProps() {
    return {
      title: t('header', i18nOpts),
      collapsable: true,
    }
  }

  render() {
    const { showForm, orders } = this.props
    return (
      <Card {...this.cardProps}>
        {showForm && (
          <Form
            className="form form--background"
            {...this.props}
            renderSubmit={() => (
              <div className="form__submit">
                <Submit value={t('submit', i18nOpts)} />
              </div>
            )}
          />
        )}

        <ul>
          {orders.length ? (
            orders.map((order) => <Prescription key={order.id} {...order} />)
          ) : (
            <li className="item-pill-section--item-list-empty">{t('empty', i18nOpts)}</li>
          )}
        </ul>
      </Card>
    )
  }
}

PrescriptionSection.propTypes = {
  orders: PropTypes.array,
  showForm: PropTypes.bool.isRequired,
  timeZone: PropTypes.string,
}

PrescriptionSection.defaultProps = {
  orders: [],
}

export default orderCreator(PrescriptionSection)
