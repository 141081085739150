import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Icon } from 'Components/Base'
import { Modal } from 'Components/Base/Modal'
import { FormList } from './'
import SectionFooter from '../SectionFooter'
import { FormForm } from 'Components/CarePlans/FormSection'

import FormContainer from 'Components/CarePlans/FormSection/FormContainer'

const i18nOpts = { scope: 'care_plan_creators.forms.index' }

class FormSectionContainer extends Component {
  constructor(props) {
    super(props)

    this.handleToggleForm = this.handleToggleForm.bind(this)
    this.handleSuccess = this.handleSuccess.bind(this)
    this.toggleDropbox = this.toggleDropbox.bind(this)
    this.editForm = this.editForm.bind(this)

    this.state = {
      showDropbox: false,
      showForm: false,
    }
  }

  toggleDropbox() {
    this.setState((prevState) => ({ showDropbox: !prevState.showDropbox }))
  }

  navigateToNextSection() {
    window.location.hash = '#resources'
  }

  handleSuccess() {
    this.setState({ showForm: false })
  }

  handleToggleForm(callback, form) {
    this.setState((prevState) => ({ showForm: !prevState.showForm }), callback.bind(null, form))
  }

  editForm() {
    this.setState({ showForm: true, showDropbox: false })
  }

  render() {
    return (
      <FormContainer
        {...this.props}
        onSuccess={this.handleSuccess}
        render={({ forms, resetForm, onDelete, onEdit, ...rest }) => (
          <Fragment>
            <FormList
              deleteForm={onDelete}
              editForm={this.handleToggleForm.bind(null, onEdit)}
              forms={forms}
            />

            <button
              className="btn btn--link careplan__list-add-button"
              onClick={this.handleToggleForm.bind(null, resetForm)}
            >
              <Icon name="plus_square" className="careplan-wizard__icon_add" />
              {t('add_form', i18nOpts)}
            </button>

            <Modal
              title={t('header', { scope: 'care_plans.form_section.modal' })}
              isOpen={this.state.showForm}
              closeModal={this.handleToggleForm.bind(null, resetForm)}
            >
              <FormForm {...rest} onClose={this.handleToggleForm.bind(null, resetForm)} />
            </Modal>

            <SectionFooter onNext={this.navigateToNextSection} />
          </Fragment>
        )}
      />
    )
  }
}

FormSectionContainer.propTypes = {
  formTemplates: PropTypes.array,
  forms: PropTypes.array,
  isTemplate: PropTypes.bool,
  parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

FormSectionContainer.defaultProps = {
  formTemplates: [],
  forms: [],
  isTemplate: false,
  parentId: null,
}

export default FormSectionContainer
