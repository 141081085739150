import fetchCarePlanBlocksApi from 'Apiv2/fetchCarePlanBlocksApi';

export const setupCarePlanBlocksWrapper = (getCarePlanBlocks = fetchCarePlanBlocksApi) => {
  return async (carePlanUuid, setBlocks, setPageState) => {
    setPageState('loading');

    try {
      const { status, data } = await getCarePlanBlocks(carePlanUuid);
      if (status === 200 && data) {
        setBlocks(data.blocks);
        setPageState('success');
      } else {
        throw 'Missing data';
      }
    } catch (error) {
      return setPageState('error');
    }
  };
};

export default setupCarePlanBlocksWrapper(fetchCarePlanBlocksApi);
