import { request } from './handleRequest';

const acceptInvitationApi = async (signup) => {
  try {
    const { id, entityType, carePlanUuid } = signup;
    const body = {
      signup: {
        entity_id: id,
        entity_type: entityType,
        care_plan_id: carePlanUuid,
      },
    };
    const response = await request('/care_plan_place_invitations/accept', 'POST', body);
    const status = response.status;
    return { status };
  } catch (error) {
    return { status: 500, error };
  }
};

export default acceptInvitationApi;
