import React from 'react'
import PropTypes from 'prop-types'

import ActivityHeader from './ActivityHeader'
import Comments from './Comments'
import LinkDecorator from 'Components/Base/LinkDecorator'

const Status = ({ id, createdAt, author, comments, text, type, carePlanUuid }) => (
  <div className="activity">
    <ActivityHeader createdAt={createdAt} type={type} {...author} />

    <div className="activity__body">
      <p>
        <LinkDecorator>{text}</LinkDecorator>
      </p>
    </div>

    <hr />

    <Comments comments={comments} parentId={id} carePlanUuid={carePlanUuid} />
  </div>
)

Status.propTypes = {
  author: PropTypes.object.isRequired,
  carePlanUuid: PropTypes.string.isRequired,
  comments: PropTypes.array,
  createdAt: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

Status.defaultProps = {
  comments: [],
}

export default Status
