import React from 'react';
import PropTypes from 'prop-types';
import Row from './Row';
import Icon from './../Icon';
import { t } from 'i18n';

const i18nOpts = { scope: 'dashboard.chart' };

const TooltipLegend = ({ active, payload, hasTarget, isIconsDisplayed }) => {
  if (!active || !payload || !payload.length) return null;

  const data = payload[0].payload;

  return (
    <div className="blood-glucose-legend blood-glucose-legend--tooltip">
      <div className="blood-glucose-legend__text">
        {Object.keys(data).map((dataKey) => (
          <Row
            dataKey={dataKey}
            dataValue={data[dataKey]}
            key={`legend_text_row_${dataKey}`}
            isIconsDisplayed={isIconsDisplayed}
          />
        ))}
        {hasTarget && (
          <div className="blood-glucose-legend__row">
            <Icon type={'target'} /> {t('target_range', i18nOpts)}
          </div>
        )}
      </div>
    </div>
  );
};

TooltipLegend.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
};

TooltipLegend.defaultProps = {
  active: false,
  payload: [],
};

export default TooltipLegend;
