import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import Icon from '../Base/Icon'
import Avatar from 'PulseBackports/Avatar'

const i18nOpts = { scope: 'activity_feed.activity_header' }

const iconMap = {
  appointment: 'calendar',
  medicaldocument: 'documents_alt',
  prescription: 'task',
  task: 'task',
  status: 'quotations_alt2',
}

const ActivityHeader = ({ photo, name, type, relationship, link, createdAt }) => (
  <header className="activity__header">
    <Avatar
      src={photo}
      fullName={name}
      size={2.5}
      className="activity__avatar"
    />

    <div className="activity__header-text">
      <h4 className="activity__username">
        {name}

        {relationship && <span className="activity__relationship">&nbsp;({relationship})</span>}
      </h4>

      {link ? (
        <div className="activity__type">
          <Icon name={iconMap[type]} />
          <a href={link}>{t(type, i18nOpts)}</a>
        </div>
      ) : (
        <div className="activity__type">
          <Icon name={iconMap[type]} />
          {t(type, i18nOpts)}
        </div>
      )}

      <span className="activity__created">{createdAt}</span>
    </div>
  </header>
)

ActivityHeader.propTypes = {
  createdAt: PropTypes.string.isRequired,
  link: PropTypes.string,
  name: PropTypes.string.isRequired,
  photo: PropTypes.string,
  relationship: PropTypes.string,
  type: PropTypes.string.isRequired,
}

ActivityHeader.defaultProps = {
  link: '',
  relationship: '',
}

export default ActivityHeader
