import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import {
  setActivityModalActivityProperties,
  setActivityModalResourcesDropdownIsOpen,
  setActivityModalResourcesDropdownState,
  setActivityModalResourcesDropdownSearchInput,
  setActivityModalResourcesDropdownResources,
  setActivityModalResourcesDropdownResourcesToDisplay,
} from 'Actions/activityModal';
import handleResourcesSetup from './handleResourcesSetup';

import { Button, Icon } from 'Components/Base';

const i18nOpts = { scope: 'activity_modal.form_fields.resources' };

export const OpenButton = ({
  carePlanId,
  activityType,
  resourcesDropdown,
  setActivityModalActivityProperties,
  setActivityModalResourcesDropdownIsOpen,
  setActivityModalResourcesDropdownState,
  setActivityModalResourcesDropdownSearchInput,
  setActivityModalResourcesDropdownResources,
  setActivityModalResourcesDropdownResourcesToDisplay,
}) => {
  const { isOpen } = resourcesDropdown;

  return (
    <Button
      className="btn--pill activity_modal__resources__resource_item"
      onClick={() => {
        if (isOpen) {
          setActivityModalResourcesDropdownIsOpen(false);
          setActivityModalResourcesDropdownSearchInput('');
        } else {
          setActivityModalResourcesDropdownIsOpen(true);
          setActivityModalResourcesDropdownSearchInput('');
          handleResourcesSetup(
            setActivityModalResourcesDropdownState,
            setActivityModalResourcesDropdownResources,
            setActivityModalResourcesDropdownResourcesToDisplay,
            carePlanId,
            activityType
          );
        }
      }}
    >
      <Icon name="plus_1" className="activity_modal__resources__resource_item__button_icon" />
      <span>{t('add_resources', i18nOpts)}</span>
    </Button>
  );
};

const mapStateToProps = ({ activityModal }) => {
  const { carePlanId, activityType, resourcesDropdown } = activityModal;

  return { carePlanId, activityType, resourcesDropdown };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalActivityProperties = (dispatch) => {
    return (activityProperties) => {
      dispatch(setActivityModalActivityProperties(activityProperties));
    };
  };

  const dispatchSetActivityModalResourcesDropdownIsOpen = (dispatch) => {
    return (isOpen) => {
      dispatch(setActivityModalResourcesDropdownIsOpen(isOpen));
    };
  };

  const dispatchSetActivityModalResourcesDropdownState = (dispatch) => {
    return (resourcesDropdownState) => {
      dispatch(setActivityModalResourcesDropdownState(resourcesDropdownState));
    };
  };

  const dispatchSetActivityModalResourcesDropdownSearchInput = (dispatch) => {
    return (searchInput) => {
      dispatch(setActivityModalResourcesDropdownSearchInput(searchInput));
    };
  };

  const dispatchSetActivityModalResourcesDropdownResources = (dispatch) => {
    return (resources) => {
      dispatch(setActivityModalResourcesDropdownResources(resources));
    };
  };

  const dispatchSetActivityModalResourcesDropdownResourcesToDisplay = (dispatch) => {
    return (resourcesToDisplay) => {
      dispatch(setActivityModalResourcesDropdownResourcesToDisplay(resourcesToDisplay));
    };
  };

  return {
    setActivityModalActivityProperties: dispatchSetActivityModalActivityProperties(dispatch),
    setActivityModalResourcesDropdownIsOpen: dispatchSetActivityModalResourcesDropdownIsOpen(dispatch),
    setActivityModalResourcesDropdownState: dispatchSetActivityModalResourcesDropdownState(dispatch),
    setActivityModalResourcesDropdownSearchInput:
      dispatchSetActivityModalResourcesDropdownSearchInput(dispatch),
    setActivityModalResourcesDropdownResources: dispatchSetActivityModalResourcesDropdownResources(dispatch),
    setActivityModalResourcesDropdownResourcesToDisplay:
      dispatchSetActivityModalResourcesDropdownResourcesToDisplay(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenButton);
