import Api from './Api'

class Goal extends Api {
  constructor(carePlanUuid, id) {
    super()
    this.carePlanUuid = carePlanUuid
    this.id = id

    this.complete = this.complete.bind(this)
    this.incomplete = this.incomplete.bind(this)
  }

  complete() {
    return this.request(`${this.url}/${this.id}/complete`, 'PATCH')
  }

  incomplete() {
    return this.request(`${this.url}/${this.id}/incomplete`, 'PATCH')
  }

  get url() {
    return `/care_plans/${this.carePlanUuid}/goals`
  }
}

export default Goal
