import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import SearchInput from 'Components/Forms/SearchInput'

const i18nOpts = { scope: 'inbox.side_nav.contacts.filters' }

const Filter = ({ search, sort }) => (
  <nav className="inbox-menu__filters">
    <SearchInput onChange={search} placeholder={t('search', i18nOpts)} />

    <select onChange={sort}>
      {['newest', 'oldest', 'nameAsc', 'nameDesc'].map((opt) => {
        return (
          <option key={opt} value={opt}>
            {t(opt, i18nOpts)}
          </option>
        )
      })}
    </select>
  </nav>
)

Filter.propTypes = {
  search: PropTypes.func.isRequired,
  sort: PropTypes.func.isRequired,
}

export default Filter
