import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import { setActivityModalResourcesDropdownIsOpen } from 'Actions/activityModal';

import { Button } from 'Components/Base';
import DotsLoader from 'Components/Base/DotsLoader';
import Backdrop from 'Components/Base/Backdrop';
import OpenButton from './OpenButton';
import FormField from '../FormField';
import ResourcesMenu from './ResourcesMenu';
import ResourcesList from './ResourcesList';

const i18nOpts = { scope: 'activity_modal.form_fields.resources' };

export const Resources = ({
  resourcesDropdown,
  setActivityModalResourcesDropdownIsOpen,
  canEditResources,
}) => {
  const { isOpen, resourcesDropdownState } = resourcesDropdown;

  let content;
  switch (resourcesDropdownState) {
    case 'LOADING':
      content = (
        <div className="resources_dropdown">
          <DotsLoader />
        </div>
      );
      break;
    case 'ERROR':
      content = (
        <div className="resources_dropdown resources_dropdown--error">{t('api_error', i18nOpts)}</div>
      );
      break;
    case 'DEFAULT':
      content = <ResourcesMenu />;
      break;
    default:
      content = (
        <div className="resources_dropdown resources_dropdown--error">{t('api_error', i18nOpts)}</div>
      );
      break;
  }

  return (
    <>
      <div className="activity_modal__input_field activity_modal__input_field--resources">
        <div className="activity_modal__input_field__label activity_modal__input_field__label--resources">
          {t('title', i18nOpts)}
        </div>
        <div className="activity_modal__input_field__container">
          <div className="activity_modal__resources">
            <ResourcesList />
            {canEditResources && <OpenButton />}
          </div>
          {isOpen && (
            <div className="activity_modal__resources__menu_container">
              {content}
              <Backdrop
                transparent
                onClick={() => {
                  setActivityModalResourcesDropdownIsOpen(false);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ activityModal }) => {
  const { resourcesDropdown, canEditFields: { canEditResources } } = activityModal;

  return { resourcesDropdown, canEditResources };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalResourcesDropdownIsOpen = (dispatch) => {
    return (isOpen) => {
      dispatch(setActivityModalResourcesDropdownIsOpen(isOpen));
    };
  };

  return {
    setActivityModalResourcesDropdownIsOpen: dispatchSetActivityModalResourcesDropdownIsOpen(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Resources);
