import React from 'react'
import { t } from 'i18n'

import Icon from 'Components/Base/Icon'
import Avatar from 'PulseBackports/Avatar'

const MobileHeader = ({ photo, name, back, onOpenAside, isCurrentUser }) => (
  <div className="hidden--lg page-header">
    <a href={back} className="page-header__action">
      <Icon name="arrow-left" />
    </a>

    <div className="page-header__middle">
      <Avatar
        src={photo}
        fullName={name}
        className="page-header__image"
        size={2}
        border={true}
      />

      <h3 className="page-header__title">{
        isCurrentUser
          ? t('my_activities', { scope: 'tasks.index.sidebar' })
          : t('mobile_header', { scope: 'tasks.index.sidebar', patient: name })
      }</h3>
    </div>

    <div className="page-header__action" onClick={onOpenAside}>
      <Icon name="care-plan-right" className="icon icon_care-plan-right" />
    </div>
  </div>
)

MobileHeader.defaultProps = {
  back: '',
  isCurrentUser: false,
}

export default MobileHeader
