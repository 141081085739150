import { TOGGLE_TASK } from 'Actions/editButton'

const initialState = {
  completed: true,
}

const editButton = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_TASK:
      return {
        ...state,
        completed: !state.completed,
      }
    default:
      return state
  }
}

export default editButton
