import {
  SET_DOCUMENTS,
  REQUEST_LIBRARY_DOCUMENTS,
  RECEIVE_LIBRARY_DOCUMENTS,
  ADD_DOCUMENT,
  REPLACE_DOCUMENT,
  DELETE_DOCUMENT,
  SEARCH,
  TOGGLE_EDITING,
  TOGGLE_LIBRARY_DOCUMENT_SELECTION,
  UPDATE_LIBRARY_DOCUMENT_COPIES,
} from 'Actions/documents'

import { getDroppedLibraryDocumentCopies, getPendingLibraryDocumentCopies } from 'Selectors/documents'

const initialState = {
  documents: [],
  libraryDocuments: [],
  libraryDocumentsReceived: false,
  isEditing: false,
  isFetching: false,
  documentSelectionStates: {},
  searchQuery: '',
  uploadModalIsOpen: false,
}

const documents = (state = initialState, action) => {
  switch (action.type) {
    case SET_DOCUMENTS:
      return {
        ...state,
        documents: action.documents,
      }

    case REQUEST_LIBRARY_DOCUMENTS:
      return {
        ...state,
        isFetching: true,
      }

    case RECEIVE_LIBRARY_DOCUMENTS: {
      const documentSelectionStates = {}
      action.libraryDocuments.map((document) => {
        documentSelectionStates[document.id] = false
      })
      const documentCopies = state.documents.filter((document) => document.libraryDocumentId !== null)
      documentCopies.map((document) => {
        documentSelectionStates[document.libraryDocumentId] = true
      })

      return {
        ...state,
        isFetching: false,
        libraryDocumentsReceived: true,
        libraryDocuments: action.libraryDocuments,
        documentSelectionStates,
      }
    }

    case ADD_DOCUMENT:
      return {
        ...state,
        documents: [...state.documents, action.document],
      }

    case REPLACE_DOCUMENT: {
      const updatedDocuments = state.documents.map((document) => {
        return document.id === action.currentDocumentId ? action.newDocument : document
      })

      return {
        ...state,
        documents: updatedDocuments,
      }
    }

    case DELETE_DOCUMENT:
      return {
        ...state,
        documents: state.documents.filter((document) => document.id !== action.documentId),
      }

    case SEARCH:
      return {
        ...state,
        searchQuery: action.searchQuery,
      }

    case TOGGLE_EDITING:
      return {
        ...state,
        isEditing: !state.isEditing,
      }

    case TOGGLE_LIBRARY_DOCUMENT_SELECTION: {
      const selectionStatus = !state.documentSelectionStates[action.libraryDocumentId]

      return {
        ...state,
        documentSelectionStates: {
          ...state.documentSelectionStates,
          [action.libraryDocumentId]: selectionStatus,
        },
      }
    }

    case UPDATE_LIBRARY_DOCUMENT_COPIES: {
      const droppedCopies = getDroppedLibraryDocumentCopies({ documents: state })
      const pendingCopies = getPendingLibraryDocumentCopies({ documents: state })
      // library documents are both copied and removed from the care plan on the server
      // asynchronously; however, we want to immediately update our components to reflect the
      // eventual state of the care plan, so we copy over the pending library documents to
      // act as temporary members of state.documents, and similarly delete dropped documents from state.

      return {
        ...state,
        documents: [
          ...state.documents.filter((document) => !droppedCopies.includes(document)),
          ...pendingCopies,
        ],
        isEditing: false,
      }
    }

    default:
      return state
  }
}

export default documents
