import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import moment from 'ct-moment'
import RepeatedTaskOccurrenceApi from 'Api/RepeatedTaskOccurrence'
import TaskFormBaseContainer from 'Components/Tasks/TaskFormContainer'
import UpdateModal from './UpdateModal'

class TaskFormContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isSubmitting: false,
      modalIsOpen: false,
      originalSchedule: {
        count: this.props.count,
        frequency: this.props.frequency,
        interval: this.props.interval,
        until: this.formatUntil(this.props.until),
      },
      repeats: true,
      errors: {},
      repeatedTaskParams: {},
    }

    this.handleCancel = this.handleCancel.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleApiCall = this.handleApiCall.bind(this)

    this.closeModal = this.closeModal.bind(this)
    this.formatUntil = this.formatUntil.bind(this)
    this.redirectToOccurrence = this.redirectToOccurrence.bind(this)

    this.updateSeries = this.updateSeries.bind(this)
    this.updateOccurrence = this.updateOccurrence.bind(this)
  }

  get api() {
    return new RepeatedTaskOccurrenceApi(
      this.props.carePlanId,
      this.props.repeatedTaskId,
      this.props.occurrence
    )
  }

  formatUntil(until) {
    return until ? moment.parseZone(until).format('YYYY-MM-DD') : until
  }

  closeModal() {
    this.setState({ modalIsOpen: false })
  }

  handleCancel() {
    this.setState({ isSubmitting: true }, () => {
      this.redirectToOccurrence()
    })
  }

  handleApiCall(promise) {
    this.setState({ modalIsOpen: false, isSubmitting: true })

    return promise.then((res) => {
      if (res.ok) {
        window.location.assign(res.headers.get('location'))
      } else if (res.status === 422) {
        return res.json().then(({ errors }) => {
          this.setState({ errors, isSubmitting: false })
        })
      }
    })
  }

  updateSeries() {
    return this.handleApiCall(
      this.api.update({ ...this.state.repeatedTaskParams, update_occurrence_only: false })
    )
  }

  updateOccurrence() {
    return this.handleApiCall(
      this.api.update({ ...this.state.repeatedTaskParams, update_occurrence_only: true })
    )
  }

  redirectToOccurrence() {
    window.location.assign(this.props.occurrencePath)
  }

  handleSubmit(repeatedTaskParams, repeats) {
    this.setState({ repeatedTaskParams, repeats, modalIsOpen: true })
  }

  render() {
    return (
      <Fragment>
        <TaskFormBaseContainer
          allowRepeat={this.props.allowRepeat}
          assignmentOptions={this.props.assignmentOptions}
          assignments={this.props.assignments}
          count={this.props.count}
          currentTime={this.props.currentTime}
          occurrence={this.props.occurrence}
          dueAt={this.props.occurrence}
          errors={this.state.errors}
          frequency={this.props.frequency}
          interval={this.props.interval}
          isSubmitting={this.state.isSubmitting}
          kind={this.props.kind}
          onCancel={this.handleCancel}
          onTaskSubmit={(params) => this.handleSubmit({ repeated_task: { ...params.task } }, false)}
          onRepeatedTaskSubmit={(params) => this.handleSubmit(params, true)}
          text={this.props.text}
          timeZone={this.props.timeZone}
          until={this.formatUntil(this.props.until)}
        />

        <UpdateModal
          modalIsOpen={this.state.modalIsOpen}
          closeModal={this.closeModal}
          updateOccurrence={this.updateOccurrence}
          updateSeries={this.updateSeries}
        />
      </Fragment>
    )
  }
}

TaskFormContainer.propTypes = {
  allowRepeat: PropTypes.bool,
  assignmentOptions: PropTypes.array,
  assignments: PropTypes.array,
  carePlanId: PropTypes.string,
  count: PropTypes.string,
  currentTime: PropTypes.object,
  dueAt: PropTypes.string,
  frequency: PropTypes.string,
  interval: PropTypes.string,
  kind: PropTypes.string,
  occurrence: PropTypes.string.isRequired,
  occurrencePath: PropTypes.string.isRequired,
  repeatedTaskId: PropTypes.number.isRequired,
  text: PropTypes.string,
  timeZone: PropTypes.string.isRequired,
  until: PropTypes.string,
}

export default TaskFormContainer
