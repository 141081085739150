import React, { useContext } from 'react';
import { t } from 'i18n';
import ActionButton from 'Components/Base/ActionButton';
import Icon from 'Components/Base/Icon';

const i18nOpts = { scope: 'dashboard.device' };

const ConnectionFailure = () => {
  const redirectToConsentRequest = () => window.location.assign('/oauth/identos/devices_request');

  return (
    <div className="highlight__error">
      <Icon name="alert" className="highlight__error--alert" />
      <div className="highlight__error--details">
        <div className="highlight__error--header">{t('connection_failed', i18nOpts)}</div>
        <div className="highlight__error--cta">
          <ActionButton
            content="Check devices"
            onClick={redirectToConsentRequest}
          />
        </div>
      </div>
    </div>
  );
};

export default ConnectionFailure;
