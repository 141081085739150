import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'i18n';

import Tab from './Tab';

import {
  setCurrentTab,
  setFilteredSignups,
  setCurrentPageNumber,
} from 'Actions/carePlanPlaceInvitationsPage';
import tabHasNewSignupsOfType from './tabHasNewSignupsOfType';
import { filterByTab, filterSignupsAction } from '../SignupsFilters/filterActions';

const CARE_PLAN_PLACE_INVITATION = 'CarePlanPlaceInvitation';
const PATIENT = 'Patient';
const ACCESS_REQUEST = 'AccessRequest';

const SignupsTabs = ({
  currentTab,
  setCurrentTab,
  signups,
  setFilteredSignups,
  setCurrentPageNumber,
  currentSearchInput,
  status,
}) => {
  const updateSignups = filterByTab(
    setFilteredSignups,
    setCurrentTab,
    signups,
    setCurrentPageNumber,
    currentSearchInput,
    status
  );

  return (
    <div className="signups-tabs">
      <Tab
        onClick={() => filterSignupsAction(updateSignups, CARE_PLAN_PLACE_INVITATION)}
        hasNewSignups={tabHasNewSignupsOfType(CARE_PLAN_PLACE_INVITATION, signups)}
        tabIsSelected={currentTab === CARE_PLAN_PLACE_INVITATION}
        descriptor={` ${t('external_team_invitations', { scope: 'care_plan_place_invitations.index' })}`}
      />
      <Tab
        onClick={() => filterSignupsAction(updateSignups, PATIENT)}
        hasNewSignups={tabHasNewSignupsOfType(PATIENT, signups)}
        tabIsSelected={currentTab === PATIENT}
        descriptor={` ${t('patient_signups', { scope: 'care_plan_place_invitations.index' })}`}
      />
      <Tab
        onClick={() => filterSignupsAction(updateSignups, ACCESS_REQUEST)}
        hasNewSignups={tabHasNewSignupsOfType(ACCESS_REQUEST, signups)}
        tabIsSelected={currentTab === ACCESS_REQUEST}
        descriptor={` ${t('support_team_contact_signups', { scope: 'care_plan_place_invitations.index' })}`}
      />
    </div>
  );
};

const mapStateToProps = ({ carePlanPlaceInvitationsPage }) => {
  const {
    currentTab,
    signups,
    currentSearchInput,
    filterByStatusDropdown: { input },
  } = carePlanPlaceInvitationsPage;

  return {
    currentTab,
    signups,
    currentSearchInput,
    status: input,
  };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetCurrentTab = (dispatch) => {
    return (currentTab) => {
      dispatch(setCurrentTab(currentTab));
    };
  };

  const dispatchSetFilteredSignups = (dispatch) => {
    return (signups) => {
      dispatch(setFilteredSignups(signups));
    };
  };

  const dispatchSetCurrentPageNumber = (dispatch) => {
    return (pageNumber) => {
      dispatch(setCurrentPageNumber(pageNumber));
    };
  };

  return {
    setCurrentTab: dispatchSetCurrentTab(dispatch),
    setFilteredSignups: dispatchSetFilteredSignups(dispatch),
    setCurrentPageNumber: dispatchSetCurrentPageNumber(dispatch),
  };
};

SignupsTabs.propTypes = {
  currentTab: PropTypes.string,
  currentSearchInput: PropTypes.string,
  setCurrentTab: PropTypes.func,
  setCurrentPageNumber: PropTypes.func,
  setFilteredSignups: PropTypes.func,
  signups: PropTypes.array,
  status: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupsTabs);
