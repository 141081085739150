import React from 'react';
import { connect } from 'react-redux';

import Appointment from './Appointment';
import CheckIn from './CheckIn';
import Task from './Task';
import Form from './Form';
import { activityType as activityTypeDefinitions } from '../modalContentStateDefinitions';

export const Create = ({ activityType }) => {
  const { APPOINTMENT, CHECK_IN, FORM, TASK } = activityTypeDefinitions;

  let mainContent;

  switch (activityType) {
    case APPOINTMENT:
      mainContent = <Appointment />;
      break;

    case CHECK_IN:
      mainContent = <CheckIn />;
      break;

    case TASK:
      mainContent = <Task />;
      break;

    case FORM:
      mainContent = <Form />;
      break;

    default:
      mainContent = <Appointment />;
      break;
  }

  return mainContent;
};

const mapStateToProps = ({ activityModal }) => {
  const { activityType } = activityModal;

  return { activityType };
};

export default connect(mapStateToProps, null)(Create);
