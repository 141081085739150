import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { ascBy } from 'Utilities/sort';
import { t } from 'i18n';

import Submit from 'Components/Forms/Submit';
import CheckInQuestionOption from './CheckInQuestionOption';
import moment from 'ct-moment';

const i18nOpts = { scope: 'tasks.index.check_in' };

class CheckInFormContainer extends Component {
  renderQuestionOptions() {
    const { checkInQuestion, onOptionSelect, modalSelectedOptionValue, userInput, errors } = this.props;
    return checkInQuestion.checkInQuestionOptions.sort(ascBy('order')).map((obj) => {
      return (
        <CheckInQuestionOption
          key={obj.id}
          modalSelectedOptionValue={modalSelectedOptionValue}
          errors={errors}
          optionData={obj}
          onClick={onOptionSelect}
          type={checkInQuestion.questionType}
          userInput={userInput}
        />
      );
    });
  }

  render() {
    const {
      checkInQuestion,
      patientName,
      modalSelectedOptionValue,
      onResponseSubmit,
      userInput,
      checkInComplete,
      onQuestionSkip,
      pastDueAt,
      dueAt,
    } = this.props;

    return (
      <Fragment>
        <div className="ReactModal__CheckIn--Text">
          <div>
            <strong>{t('thanks', { ...i18nOpts, name: patientName })}</strong>{' '}
          </div>
          <div>{checkInQuestion.text}</div>
          {pastDueAt && (
            <div className="modal--status-message--checkin">
              {t('past_due_at_warning', {
                ...i18nOpts,
                dueAt: moment(dueAt).format('LLL'),
              })}
            </div>
          )}
        </div>
        <form className="form centered" onSubmit={onResponseSubmit} noValidate>
          {this.renderQuestionOptions()}
          <Submit
            disabled={!(modalSelectedOptionValue || userInput.length)}
            className="ReactModal__CheckIn--Submit"
            value={t(checkInComplete ? 'submit' : 'next', i18nOpts)}
          />
          <div className="ReactModal__CheckIn--Skip" onClick={onQuestionSkip}>
            {t('skip', i18nOpts)}
          </div>
        </form>
      </Fragment>
    );
  }
}

CheckInFormContainer.propTypes = {
  checkInComplete: PropTypes.bool.isRequired,
  checkInQuestion: PropTypes.object.isRequired,
  errors: PropTypes.object,
  modalSelectedOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onOptionSelect: PropTypes.func.isRequired,
  onResponseSubmit: PropTypes.func.isRequired,
  onQuestionSkip: PropTypes.func.isRequired,
  patientName: PropTypes.string,
  userInput: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CheckInFormContainer.defaultProps = {
  errors: {},
  modalSelectedOptionValue: '',
  patientName: '',
  timeZone: 'Etc/UTC',
  type: 'CheckIn',
  userInput: '',
};

export default CheckInFormContainer;
