import { dotNotationToBracket } from './object-notation-conversion'

export const rekeyFormDataToBracketNotation = (formData) => {
  const dotNotationKeys = Array.from(formData.keys())

  dotNotationKeys.map((key) => {
    const convertedKey = dotNotationToBracket(key)

    if (convertedKey !== key) {
      formData.append(convertedKey, formData.get(key))
      formData.delete(key)
    }
  })

  return formData
}
