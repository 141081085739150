import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import NavigationArrow from './NavigationArrow';
import ResetButton from './ResetButton';

import navigateOneWeek from './navigateOneWeek';
import calculateDateFields from './calculateDateFields';

import { setRangeEndDate } from 'Actions/dashboardComparisonChart';

const i18nOpts = { scope: 'dashboard.chart' };

const WeekSelector = ({ chartMaximumDate, rangeEndDate, setRangeEndDate }) => {
  const calculatedDateFields = useMemo(
    () => calculateDateFields(rangeEndDate, chartMaximumDate),
    [rangeEndDate, chartMaximumDate]
  );

  const { chartMinimumDate, rangeStartDate, displayBeginDate, displayEndDate } = calculatedDateFields;

  const handleNavigate = (direction) => {
    navigateOneWeek(rangeEndDate, direction, setRangeEndDate);
  };

  return (
    <div className="comparison-chart__week-selector">
      <NavigationArrow
        type="prev"
        onClick={(type) => handleNavigate(type)}
        isDisabled={rangeStartDate <= chartMinimumDate}
      />
      <p className="comparison-chart__week-selector__date-range">
        {t('comparison_date_range', { ...i18nOpts, displayBeginDate, displayEndDate })}
      </p>
      <NavigationArrow
        type="next"
        onClick={(type) => handleNavigate(type)}
        isDisabled={rangeEndDate >= chartMaximumDate}
      />
      <ResetButton />
    </div>
  );
};

const mapStateToProps = ({ dashboardComparisonChart: { rangeEndDate, chartMaximumDate } }) => {
  return { rangeEndDate, chartMaximumDate };
};

const mapDispatchToProps = (dispatch) => ({
  setRangeEndDate: (rangeEndDate) => dispatch(setRangeEndDate(rangeEndDate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WeekSelector);
