import React, { useState } from 'react';
import { t } from 'i18n';
import ActionButton from 'Components/Base/ActionButton';
import Dialog from 'Components/Base/Dialog';
import Content from './Content';
import handleSubmitAgreement from './handleSubmitAgreement';

const i18nOpts = { scope: 'terms_of_service' };

const TermsOfServiceDialog = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [hasAgreed, setHasAgreed] = useState(false);

  return (
    <Dialog
      isOpen={isDialogOpen}
      content={
        <Content
          hasAgreed={hasAgreed}
          setHasAgreed={setHasAgreed}
          isProcessing={isProcessing}
          error={error}
        />
      }
      footer={
        <ActionButton
          onClick={() => handleSubmitAgreement(hasAgreed, setIsDialogOpen, setError, setIsProcessing)}
          className="dialog__action-button dialog__action-button--full-width"
          isLoading={isProcessing}
          content={t('continue', i18nOpts)}
        />
      }
    />
  );
};

export default TermsOfServiceDialog;
