import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import Button from '../Base/Button'
import Icon from '../Base/Icon'

import banner from 'tasks_banner.svg'

const i18nOpts = { scope: 'welcome_banner.tasks_banner' }

const TasksBanner = ({ close, selfSignup }) => (
  <div className="banner">
    <img src={banner} className="banner__image" />

    <div className="banner__text">
      <div className="banner__header">
        <h1>{t('header', i18nOpts)}</h1>
        <div onClick={close}>
          <Icon name="close" />
        </div>
      </div>

      <p className="banner__details">
        {selfSignup ? t('self_signup_details', i18nOpts) : t('basic_details', i18nOpts)}
      </p>
      <Button text={t('button', i18nOpts)} onClick={close} />
    </div>
  </div>
)

TasksBanner.propTypes = {
  close: PropTypes.func.isRequired,
  selfSignup: PropTypes.bool.isRequired,
}

export default TasksBanner
