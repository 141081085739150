import React from 'react'
import PropTypes from 'prop-types'

import moment from 'ct-moment'

const EventListRange = ({ firstDay, lastDay }) => {
  const formattedText = () => {
    if (!firstDay || !lastDay) {
      return ''
    }

    const first = moment(firstDay)
    const second = moment(lastDay)

    if (first.month() === second.month()) {
      return `${first.format('MMMM D')} — ${second.format('D, YYYY')}`
    } else if (first.year() === second.year()) {
      return `${first.format('MMMM D')} — ${second.format('MMMM D, YYYY')}`
    } else {
      return `${first.format('MMMM D, YYYY')} — ${second.format('MMMM D, YYYY')}`
    }
  }

  return <small className="text-medium text-grey-dark appointments-range">{formattedText()}</small>
}

EventListRange.propTypes = {
  firstDay: PropTypes.string,
  lastDay: PropTypes.string,
}

EventListRange.defaultProps = {
  firstDay: null,
  lastDay: null,
}

export default EventListRange
