import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';

const i18nOpts = {
  scope: 'care_plan_place_invitations.signups_container.target_signup_modal.personal_details',
};

const getTitle = (type) => {
  let title = 'title';
  switch (type) {
    case 'AccessRequest':
      title += '_caregiver';
      break;
    case 'CarePlanPlaceInvitation':
      title += '_hcp';
      break;
    default:
      break;
  }
  return title;
};

const PersonalDetails = ({
  caregiverName,
  caregiverEmail,
  patientName,
  carePlanTitle,
  submitted,
  entityType,
  invitedBy,
}) => {
  return (
    <div className="signup_modal__main_content__personal_details">
      {entityType !== 'Patient' && (
        <div className="signup_modal__main_content__personal_details__detail">
          <div className="signup_modal__main_content__personal_details__detail__title">
            {t(getTitle(entityType), i18nOpts)}:
          </div>
          <div className="signup_modal__main_content__personal_details__detail__info">
            <div>{caregiverName || invitedBy}</div>
            <div className="signup_modal__main_content__personal_details__detail__info__caregiver_email">
              {entityType === 'AccessRequest' && caregiverEmail}
            </div>
          </div>
        </div>
      )}
      <div className="signup_modal__main_content__personal_details__detail">
        <div className="signup_modal__main_content__personal_details__detail__title">
          {t('person', i18nOpts)}:
        </div>
        <div className="signup_modal__main_content__personal_details__detail__info">{patientName}</div>
      </div>
      <div className="signup_modal__main_content__personal_details__detail">
        <div className="signup_modal__main_content__personal_details__detail__title">
          {t('action_plan', i18nOpts)}:
        </div>
        <div className="signup_modal__main_content__personal_details__detail__info">{carePlanTitle}</div>
      </div>
      <div className="signup_modal__main_content__personal_details__detail">
        <div className="signup_modal__main_content__personal_details__detail__title">
          {t('request_date', i18nOpts)}:
        </div>
        <div className="signup_modal__main_content__personal_details__detail__info">{submitted}</div>
      </div>
    </div>
  );
};

PersonalDetails.propTypes = {
  carePlanTitle: PropTypes.string,
  caregiverEmail: PropTypes.string,
  caregiverName: PropTypes.string,
  entityType: PropTypes.string,
  invitedBy: PropTypes.string,
  patientName: PropTypes.string,
  submitted: PropTypes.string,
};

PersonalDetails.defaultProps = {
  carePlanTitle: '',
  caregiverEmail: '',
  caregiverName: '',
  entityType: 'CarePlanPlaceInvitation',
  invitedBy: '',
  patientName: '',
  submitted: '',
};

export default PersonalDetails;
