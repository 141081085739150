import React, { useState } from 'react';
import { t } from 'i18n';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import ActionButton from 'Components/Base/ActionButton';
import handleRemoveRecommendation from './handleRemoveRecommendation';

import {
  setRecommendations,
  setRemovingRecommendationIndex,
  setIsProcessing,
} from 'Actions/recommendationsBlock';

const i18nOpts = { scope: 'blocks.recommendations' };

const RemoveModal = ({
  index,
  recommendation,
  setRecommendations,
  removingRecommendationIndex,
  setRemovingRecommendationIndex,
  carePlanUuid,
  isProcessing,
  setIsProcessing,
}) => {
  const isModalOpen = index === removingRecommendationIndex;

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isModalOpen}
      onRequestClose={() => setRemovingRecommendationIndex(null)}
      shouldCloseOnOverlayClick={true}
      className={'recommendation__remove-modal'}
    >
      <div className="recommendation__remove-modal__container">
        <div className="recommendation__remove-modal__text-content">
          {t('confirm_remove_prompt', i18nOpts)}
        </div>
        <div className="recommendation__remove-modal__footer">
          <ActionButton
            className="button-subtle recommendation__remove-modal__action-button"
            content={t('cancel_remove', i18nOpts)}
            disabled={isProcessing}
            onClick={() => setRemovingRecommendationIndex(null)}
          />

          <ActionButton
            className="recommendation__remove-modal__action-button"
            content={t('confirm_remove', i18nOpts)}
            isLoading={isProcessing}
            onClick={() => {
              handleRemoveRecommendation(
                carePlanUuid,
                recommendation.id,
                setRecommendations,
                setRemovingRecommendationIndex,
                setIsProcessing
              );
            }}
          />
        </div>
      </div>
    </ReactModal>
  );
};

const mapStateToProps = ({
  recommendationsBlock: {
    removingRecommendationIndex,
    isProcessing,
    userDetails: { carePlanUuid },
  },
}) => {
  return { removingRecommendationIndex, carePlanUuid, isProcessing };
};
const mapDispatchToProps = (dispatch) => ({
  setRecommendations: (recommendations) => dispatch(setRecommendations(recommendations)),
  setRemovingRecommendationIndex: (index) => dispatch(setRemovingRecommendationIndex(index)),
  setIsProcessing: (isProcessing) => dispatch(setIsProcessing(isProcessing)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RemoveModal);
