import React from 'react'
import PropTypes from 'prop-types'

import Icon from './Icon'

const AttributeWithIcon = ({ children, iconName }) => (
  <div className="attribute-with-icon text-truncate">
    <span className="attribute-with-icon__icon">
      <Icon name={iconName} />
    </span>

    {children}
  </div>
)

AttributeWithIcon.propTypes = {
  children: PropTypes.node.isRequired,
  iconName: PropTypes.string.isRequired,
}

export default AttributeWithIcon
