import React from 'react'
import PropTypes from 'prop-types';
import { t } from 'i18n'

import InputGroup from 'Components/Forms/InputGroup'
import FormField from 'Components/Forms/FormField';
import Checkbox from 'Components/Forms/Checkbox'
import { Modal } from 'Components/Base/Modal'
import Label from 'Components/Forms/Label'
import AppointmentFields from './AppointmentFields'
import ResourceListItem from 'Components/Resources/ResourceListItem'
import { RequestForm, RequestedHelp } from 'Components/HelpRequest'

const i18nOpts = {
  scope: 'care_plans.medical_order_section.medical_order_form',
}

const MedicalOrderForm = (props) => {
  const {
    className,
    handleSubmit,
    handleChange,
    errors,
    practitioner,
    text,
    type,
    timeZone,
    locale,
    isEdit,
    isTemplate,
    frequencyType,
    appointmentTemplates,
    renderSubmit,
    onCloseModal,
    onOpenModal,
    onSubmitHelpRequest,
    helpRequest,
    modalIsOpen,
    selectedResources,
    onRemoveResource,
    onSelectActivityTemplate,
    selectedActivityTemplateId,
  } = props;
  return (
    <>
      <form className={className} onSubmit={handleSubmit} noValidate>
        {!isEdit && (
          <div className="input activity-template-group">
            <InputGroup
              component="select"
              className="input"
              name="appointmentTemplates"
              label={t('choose_appointment_template', i18nOpts)}
              onChange={onSelectActivityTemplate}
              value={selectedActivityTemplateId}
            >
              <option value={-1}>{t('select_appointment_template', i18nOpts)}</option>
              {appointmentTemplates.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </InputGroup>

            <hr />
            <div className="activity-template-group__hint">{t('start_new_appointment', i18nOpts)}</div>
          </div>
        )}
        <FormField
          label={t('practitioner', i18nOpts)}
          formErrors={errors}
          value={practitioner}
          name="practitioner"
          onChange={handleChange}
        />

        <InputGroup
          component="textarea"
          label={t('text', i18nOpts)}
          errors={errors}
          value={text}
          name="text"
          onChange={handleChange}
          required
          type="textarea"
        />

        {(!isTemplate || !isEdit) && (
          <Checkbox
            name="type"
            text={t('completed_at', i18nOpts)}
            checked={frequencyType === 'once'}
            onChange={() => {
              handleChange({
                target: {
                  name: 'type',
                  value: type === 'Task' ? 'Appointment' : 'Task',
                },
              })
              handleChange({
                target: { name: 'frequencyType', value: type === 'Appointment' ? 'once' : 'scheduled' },
              })
            }}
          />
        )}

        {type === 'Appointment' && (
          <div>
            <AppointmentFields {...props} />
            <RequestedHelp {...props} action={onOpenModal} />
          </div>
        )}

        {selectedResources.length ? (
          <div className="item-pill-section__item-list">
            <Label text={t('resources', i18nOpts)} inputName={t('resources', i18nOpts)} />
            {selectedResources.map((resource) => (
              <ResourceListItem
                key={resource.id}
                id={resource.id}
                name={resource.name}
                kind={resource.kind}
                deleteResource={onRemoveResource}
                isLinking
              />
            ))}
          </div>
        ) : null}

        {renderSubmit()}
      </form>

      <Modal
        className="ReactModal__Content"
        isOpen={modalIsOpen}
        closeModal={onCloseModal}
        title={t('request_volunteer', i18nOpts)}
      >
        <RequestForm
          helpRequest={helpRequest}
          onClose={onCloseModal}
          onSubmit={onSubmitHelpRequest}
          timeZone={timeZone}
        />
      </Modal>
    </>
  )
};

MedicalOrderForm.propTypes = {
  appointmentTemplates: PropTypes.array,
  className: PropTypes.string,
  errors: PropTypes.object.isRequired,
  frequencyType: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  helpRequest: PropTypes.object,
  helpRequested: PropTypes.bool,
  isEdit: PropTypes.bool,
  isTemplate: PropTypes.bool,
  modalIsOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,
  onOpenModal: PropTypes.func,
  onRemoveResource: PropTypes.func.isRequired,
  onSelectActivityTemplate: PropTypes.func.isRequired,
  onSubmitHelpRequest: PropTypes.func,
  practitioner: PropTypes.string,
  renderSubmit: PropTypes.func.isRequired,
  selectedActivityTemplateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectedResources: PropTypes.array,
  text: PropTypes.string.isRequired,
  timeZone: PropTypes.string,
  type: PropTypes.string.isRequired,
  url: PropTypes.string,
  volunteersEnabled: PropTypes.bool,
}

MedicalOrderForm.defaultProps = {
  appointmentTemplates: [],
  className: 'form',
  dueAt: '',
  frequencyType: 'once',
  handleSubmit: null,
  helpRequest: {},
  helpRequested: false,
  isEdit: false,
  isTemplate: false,
  modalIsOpen: false,
  onCloseModal: null,
  onOpenModal: null,
  onSubmitHelpRequest: null,
  practitioner: '',
  selectedActivityTemplateId: '',
  selectedResources: [],
  timeZone: '',
  url: '',
  volunteersEnabled: false,
}

export default MedicalOrderForm;
