import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import CheckIn from './CheckIn'

const i18nOpts = { scope: 'care_plan_creators.check_ins.index' }

const CheckInList = ({ checkIns, deleteCheckIn, editCheckIn }) =>
  !checkIns.length || !checkIns.filter((checkIn) => !checkIn._destroy).length ? (
    <div className="careplan__empty-list" dangerouslySetInnerHTML={{ __html: t('empty_html', i18nOpts) }} />
  ) : (
    <div className="careplan-wizard__order-list">
      {checkIns.map((checkIn, index) => {
        return (
          <CheckIn
            key={`checkIn-${checkIn.id}-${index}`}
            deleteCheckIn={deleteCheckIn.bind(null, checkIn.id, checkIn.frequencyType)}
            editCheckIn={editCheckIn.bind(null, checkIn)}
            checkIn={checkIn}
          />
        )
      })}
    </div>
  )

CheckInList.propTypes = {
  checkIns: PropTypes.array.isRequired,
  deleteCheckIn: PropTypes.func.isRequired,
  editCheckIn: PropTypes.func.isRequired,
}

CheckInList.defaultProps = {}

export default CheckInList
