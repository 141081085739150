import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import {
  setActivityModalActivityProperties,
  setActivityModalDefaultVisibleToAll,
  setActivityModalLibraryDropdownIsOpen,
  setActivityModalLibraryDropdownSearchInput,
} from 'Actions/activityModal';

const i18nOpts = { scope: 'activity_modal.form_fields.select_from_library' };

export const populateActivityFieldsFromTemplate = (
  currentActivityProperties,
  activityType,
  setActivityModalActivityProperties,
  setActivityModalDefaultVisibleToAll,
  templateProperties
) => {
  const { id, name, visible_to_all } = templateProperties;

  const visibleToAll = !!visible_to_all;
  const propertiesToUpdate = { name, visibleToAll };

  switch (activityType) {
    case 'CHECK_IN':
      propertiesToUpdate.checkInTemplateId = id;
      break;
    case 'FORM':
      propertiesToUpdate.formTemplateId = id;
      break;
    default:
      break;
  }

  setActivityModalDefaultVisibleToAll(visibleToAll);
  setActivityModalActivityProperties({
    ...currentActivityProperties,
    ...propertiesToUpdate,
  });
};

const TemplateItem = ({
  activityProperties,
  activityType,
  template,
  setActivityModalActivityProperties,
  setActivityModalDefaultVisibleToAll,
  setActivityModalLibraryDropdownIsOpen,
  setActivityModalLibraryDropdownSearchInput,
}) => {
  let unnamedTemplate;
  switch (activityType) {
    case 'CHECK_IN':
      unnamedTemplate = t('unnamed_check_in', i18nOpts);
      break;
    case 'FORM':
      unnamedTemplate = t('unnamed_form', i18nOpts);
      break;
    default:
      unnamedTemplate = t('unnamed_check_in', i18nOpts);
      break;
  }

  const onClick = () => {
    populateActivityFieldsFromTemplate(
      activityProperties,
      activityType,
      setActivityModalActivityProperties,
      setActivityModalDefaultVisibleToAll,
      template
    );
    setActivityModalLibraryDropdownIsOpen(false);
    setActivityModalLibraryDropdownSearchInput('');
  };

  const handleKeyDown = (evt) => {
    if (evt.key === 'Enter' || evt.key === ' ') {
      evt.preventDefault();
      onClick();
    }
    return;
  };

  return (
    <li
      className="library_dropdown__templates_list__item"
      onClick={onClick}
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      {template.name || unnamedTemplate}
    </li>
  );
};

const mapStateToProps = ({ activityModal }) => {
  const { activityProperties, activityType } = activityModal;

  return { activityProperties, activityType };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalActivityProperties = (dispatch) => {
    return (activityProperties) => {
      dispatch(setActivityModalActivityProperties(activityProperties));
    };
  };

  const dispatchSetActivityModalDefaultVisibleToAll = (dispatch) => {
    return (defaultVisibleToAll) => {
      dispatch(setActivityModalDefaultVisibleToAll(defaultVisibleToAll));
    };
  };

  const dispatchSetActivityModalLibraryDropdownIsOpen = (dispatch) => {
    return (isOpen) => {
      dispatch(setActivityModalLibraryDropdownIsOpen(isOpen));
    };
  };

  const dispatchSetActivityModalLibraryDropdownSearchInput = (dispatch) => {
    return (searchInput) => {
      dispatch(setActivityModalLibraryDropdownSearchInput(searchInput));
    };
  };

  return {
    setActivityModalActivityProperties: dispatchSetActivityModalActivityProperties(dispatch),
    setActivityModalDefaultVisibleToAll: dispatchSetActivityModalDefaultVisibleToAll(dispatch),
    setActivityModalLibraryDropdownIsOpen: dispatchSetActivityModalLibraryDropdownIsOpen(dispatch),
    setActivityModalLibraryDropdownSearchInput: dispatchSetActivityModalLibraryDropdownSearchInput(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateItem);
