import React from 'react'
import PropTypes from 'prop-types'

const Chip = ({ text, color }) => <span className={`chip chip--${color}`}>{text}</span>

Chip.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string.isRequired,
}

Chip.defaultProps = {
  color: '',
}

export default Chip
