import React from 'react'
import PropTypes from 'prop-types'
import Linkify from 'linkifyjs/react'

const LinkDecorator = ({ children }) => <Linkify>{children}</Linkify>

LinkDecorator.propTypes = {
  children: PropTypes.any.isRequired,
}

LinkDecorator.defaultProps = {}

export default LinkDecorator
