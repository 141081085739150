import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import Icon from 'Components/Base/Icon'
import Notification from './Notification'

const i18nOpts = { scope: 'notifications' }

class NotificationList extends PureComponent {
  get groupLabels() {
    return [...new Set(this.props.notifications.map(({ groupLabel }) => groupLabel))]
  }

  notificationsByGroupLabel(groupLabel) {
    return this.props.notifications.filter((notification) => notification.groupLabel === groupLabel)
  }

  renderNotification({ id, link, body, timestamp }) {
    return <Notification key={id} id={id} link={link} body={body} timestamp={timestamp} />
  }

  renderList() {
    return this.groupLabels.map((groupLabel) => (
      <Fragment key={groupLabel}>
        <div className="notification-tray__group-title">{groupLabel}</div>

        <ul className="notification-tray__list">
          {this.notificationsByGroupLabel(groupLabel).map(this.renderNotification)}
        </ul>
      </Fragment>
    ))
  }

  renderEmpty() {
    return (
      <div className="notification-tray__empty">
        <div className="notification-tray__empty-icon">
          <Icon name="no-notifications" />
        </div>
        <div className="notification-tray__empty-title">{t('empty.title', i18nOpts)}</div>
        <p>{t('empty.message', i18nOpts)}</p>
      </div>
    )
  }

  render() {
    return (
      <div className="notification-tray">
        {this.props.notifications.length ? this.renderList() : this.renderEmpty()}
      </div>
    )
  }
}

const notificationShape = PropTypes.shape({
  body: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired,
  groupLabel: PropTypes.string.isRequired,
})

NotificationList.propTypes = {
  notifications: PropTypes.arrayOf(notificationShape).isRequired,
}

export default NotificationList
