import React from 'react'
import PropTypes from 'prop-types'

import { Tooltip, Icon } from 'Components/Base'

import { t } from 'i18n'

const i18nOpts = { scope: 'tasks.index.agenda' }

const EventListNavigation = ({ current, isLoading, next, previous, children }) => (
  <div className="action-header action-header--bordered">
    <h2 className="text-medium">
      {t('heading', i18nOpts)}
      <span className="text-grey hidden--sm text-normal"> | </span>
      {!isLoading && children}
    </h2>

    <div className="action-header__button-section action-header__button-section--inline">
      <Tooltip text={t('previous', i18nOpts)}>
        <button
          className="btn--responsive"
          aria-label={t('previous', i18nOpts)}
          disabled={isLoading}
          onClick={previous}
        >
          <Icon className="icon--17px" name="chevron-left" />
        </button>
      </Tooltip>

      <Tooltip text={t('next', i18nOpts)}>
        <button
          className="btn--responsive"
          aria-label={t('next', i18nOpts)}
          disabled={isLoading}
          onClick={next}
        >
          <Icon className="icon--17px" name="chevron-right" />
        </button>
      </Tooltip>

      <button
        className="btn btn--secondary btn--extra-small hidden--med hidden--lg"
        disabled={isLoading}
        onClick={current}
      >
        {t('current', i18nOpts)}
      </button>

      <button className="btn btn--secondary btn--medium hidden--sm" disabled={isLoading} onClick={current}>
        {t('current', i18nOpts)}
      </button>
    </div>
  </div>
)

EventListNavigation.propTypes = {
  children: PropTypes.node,
  current: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  next: PropTypes.func.isRequired,
  previous: PropTypes.func.isRequired,
}

EventListNavigation.defaultProps = {
  children: null,
  isLoading: false,
}

export default EventListNavigation
