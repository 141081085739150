import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import RenderLegend from '../RenderLegend';
import Icon from 'Components/Base/Icon';

const allCategories = ['family', 'friends', 'strangers', 'school', 'sports', 'health'];

const Chart = ({ data }) => (
  <div className="chart worried-about">
    {data.map(({ day, categories }, dataIndex) => {
      return (
        <div key={dataIndex} className="day-wrapper">
          {categories.no_response ? (
            <div className="day day--no-response">
              <Icon name="close" className="cell no-data" />
            </div>
          ) : (
            <div className={`day ${categories.is_future && 'day--future'}`}>
              {allCategories.map((type, dayIndex) => (
                <div key={dayIndex} className={`chart cell ${categories[type] && 'cell--occurred'}`} />
              ))}
            </div>
          )}
          <span className="xAxis text text-subtle">{t(day, { scope: 'blocks.days' })}</span>
        </div>
      );
    })}
    <RenderLegend legend={t('legend', { scope: 'blocks.worried_about' })} />
  </div>
);

Chart.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Chart;
