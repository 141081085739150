import React from 'react';

const NewSignupsBadge = () => {
  return (
    <span className="signups-tabs__tab__badge-container">
      &nbsp;
      <span className="signups-tabs__tab__badge-container__new-signups-badge" />
    </span>
  );
}

export default NewSignupsBadge;
