import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import AvatarPreview from 'Components/Forms/ProfilePictureInput/AvatarPreview';
import Button from 'Components/Base/Button';
import Icon from 'Components/Base/Icon';
import defaultImage from 'profile-pic-upload.svg';

import { t } from 'i18n';
const i18nOpts = { scope: 'registration.avatar.form' };

const AvatarUpload = ({ file, onSetAvatar, onRemoveAvatar }) => {
  const inputRef = useRef(null);

  return (
    <div className="form__body__avatar-upload">
      <input
        ref={inputRef}
        type="file"
        name="avatar"
        accept="image/*"
        onChange={(e) => onSetAvatar(e.target.files[0])}
        className="profile-pic-upload__input"
      />

      <div className="avatar-upload__action">
        <p className="avatar-upload__action description">{t('photo', i18nOpts)}</p>
        <Button
          className={`avatar-upload__action-btn btn btn--secondary btn--extra-small`}
          onClick={file ? onRemoveAvatar : () => inputRef.current.click()}
        >
          <Icon name={file ? 'trash' : 'cloud-upload_alt'} />
          <span>{file ? t('remove', i18nOpts) : t('upload', i18nOpts)}</span>
        </Button>
      </div>

      <AvatarPreview
        alt={file ? file.name : t('default_alt', i18nOpts)}
        previewImageSource={file ? URL.createObjectURL(file) : null}
        defaultImage={defaultImage}
      />
    </div>
  );
};

AvatarUpload.propTypes = {
  file: PropTypes.object,
  onRemoveAvatar: PropTypes.func.isRequired,
  onSetAvatar: PropTypes.func.isRequired,
};

AvatarUpload.defaultProps = { file: null };

export default AvatarUpload;
