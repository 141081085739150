import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';

import { Icon, Tooltip } from 'Components/Base';

const i18nOpts = { scope: 'care_plans.prescription_section.prescription' };

const Prescription = ({ text, completedAt, link }) => (
  <li className={`order ${completedAt ? 'order--completed' : 'order--not-completed'}`}>
    <div className="order__section text-truncate">
      <b>{text}</b>
    </div>

    <div className="order__section">
      <span className="order__status">
        {completedAt ? (
          <>
            <Icon name="check_alt3" className="order__section--check-icon" /> 
            {t('filled', i18nOpts)}
          </>
        ) : (
          t('not_filled', i18nOpts)
        )}
      </span>
      <Tooltip text={t('edit', i18nOpts)} className="order__section--tooltip">
        <a href={link}>
          <Icon name="pencil-edit" />
        </a>
      </Tooltip>
    </div>
  </li>
);

Prescription.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

Prescription.defaultProps = {};

export default Prescription;
