import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { t } from 'i18n'

import { ItemPill, ItemPillActions } from 'Components/Base/ItemPill'
import { Chip, DeferredImage, Icon } from 'Components/Base'

import avatar from 'avatar.png'

class HealthTeamContactInput extends PureComponent {
  constructor(props) {
    super(props)
  }

  get checkboxProps() {
    const iconName = this.props.selected ? 'checkbox-selected' : 'checkbox-unselected'
    const className = classNames('icon icon--medium', {
      'icon--green': this.props.selected,
    })
    return { name: iconName, className }
  }

  render() {
    const { name, imageEntitlementToken, title, isCarePlanCreator, onToggle, selected } = this.props

    return (
      <ItemPill selected={selected}>
        <DeferredImage
          imageEntitlementToken={imageEntitlementToken}
          alt={name}
          className="new-contact__avatar avatar"
          fallbackImage={avatar}
        />

        <div className="item-pill__text">
          <div className="item-pill__name">
            {name} {isCarePlanCreator && <Chip color="light-grey" text={t('team.health_team.author')} />}
          </div>

          <small>{title}</small>
        </div>

        <ItemPillActions>
          <div
            className="item-pill__action item-pill__action--medium"
            onClick={onToggle}
            data-test="checkbox-icon"
          >
            <Icon {...this.checkboxProps} />
          </div>
        </ItemPillActions>
      </ItemPill>
    )
  }
}

HealthTeamContactInput.propTypes = {
  imageEntitlementToken: PropTypes.string,
  isCarePlanCreator: PropTypes.bool,
  name: PropTypes.string,
  onToggle: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  title: PropTypes.string,
}

HealthTeamContactInput.defaultProps = {
  imageEntitlementToken: '',
  isCarePlanCreator: false,
  name: '',
  selected: false,
  title: '',
}

export default HealthTeamContactInput
