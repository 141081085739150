import { t } from 'i18n';

const i18nOpts = { scope: 'dashboard.chart' };

const translateDataKeys = (key, isAbbreviated = false) => {
  switch (key) {
    case 'inner50pct':
      return isAbbreviated ? t('25_75_IQR_abbreviated', i18nOpts) : t('25_75_IQR', i18nOpts);
    case 'inner80pct':
      return isAbbreviated ? t('10_90_IDR_abbreviated', i18nOpts) : t('10_90_IDR', i18nOpts);
    case 'medianGlucose':
      return isAbbreviated ? t('50_median_abbreviated', i18nOpts) : t('50_median', i18nOpts);
    case 'time':
      return isAbbreviated ? t('time_of_day_abbreviated', i18nOpts) : t('time_of_day', i18nOpts);
    case 'glucoseValue':
      return t('glucose', i18nOpts);
    default:
      return '';
  }
};

const iconClassNames = (type) => {
  switch (type) {
    case 'time':
      return {
        icon: 'blood-glucose-legend__icon blood-glucose-legend__icon--hidden',
        coloredArea: 'blood-glucose-legend__icon__half',
      };
    case 'inner50pct':
      return {
        icon: 'blood-glucose-legend__icon',
        coloredArea: 'blood-glucose-legend__icon__half blood-glucose-legend__icon__half--IQR',
      };
    case 'inner80pct':
      return {
        icon: 'blood-glucose-legend__icon',
        coloredArea: 'blood-glucose-legend__icon__half blood-glucose-legend__icon__half--IDR',
      };
    case 'target':
      return {
        icon: 'blood-glucose-legend__icon',
        coloredArea: 'blood-glucose-legend__icon__half blood-glucose-legend__icon__half--target',
      };
    case 'glucoseValue': // same design as median
    case 'medianGlucose':
      return {
        icon: 'blood-glucose-legend__icon',
        coloredArea: 'blood-glucose-legend__icon__half blood-glucose-legend__icon__half--median',
      };
    default:
      return { icon: 'blood-glucose-legend__icon', coloredArea: 'blood-glucose-legend__icon__half' };
  }
};

export { translateDataKeys, iconClassNames };
