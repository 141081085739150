import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'i18n';

import useComponentDidMount from 'Hooks/useComponentDidMount';
import InputGroup from 'Components/Forms/InputGroup';
import PasswordInput from 'Components/Forms/PasswordInput';
import Checkbox from 'Components/Forms/Checkbox';
import ActionButton from 'Components/Base/ActionButton';
import ApiErrorMessage from '../ApiErrorMessage';
import Icon from 'Components/Base/Icon';

import getFormHeaderClass from '../getFormHeaderClass';

import {
  setTargetUserInfo,
  setCurrentStep,
  setIsLoading,
  setApiErrorMessage,
  goToNextStep,
} from 'Actions/signupPage';

import setupUserValidator from '../../setupUserValidator';
import {
  setupFirstNameValidator,
  setupLastNameValidator,
  setupEmailValidator,
  setupPasswordValidator,
} from './validateFormFields';

const i18nOpts = { scope: 'registration.user' };

const UserForm = ({
  setCurrentStep,
  setTargetUserInfo,
  setIsLoading,
  setApiErrorMessage,
  goToNextStep,
  isLoading,
  apiErrorMessage,
  user,
  index,
  validateUserUrl,
  currentStep,
  placeName,
}) => {
  const isComponentMounted = useComponentDidMount();

  const { firstName, lastName, email, password, termsOfService, consentToDisclosure } = user;

  const [firstNameFocused, setFirstNameFocused] = useState(false);
  const [firstNameDirty, setFirstNameDirty] = useState(false);
  const [lastNameFocused, setLastNameFocused] = useState(false);
  const [lastNameDirty, setLastNameDirty] = useState(false);
  const [emailFocused, setEmailFocused] = useState(false);
  const [emailDirty, setEmailDirty] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [passwordDirty, setPasswordDirty] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formErrors, setFormErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  });

  const validateUser = setupUserValidator(setApiErrorMessage, setIsLoading, goToNextStep);

  const completed = currentStep > index;
  const isOpen = currentStep === index;

  const handleFirstNameValidation = setupFirstNameValidator(formErrors, setFormErrors, t, i18nOpts);
  const handleLastNameValidation = setupLastNameValidator(formErrors, setFormErrors, t, i18nOpts);
  const handleEmailValidation = setupEmailValidator(formErrors, setFormErrors, t, i18nOpts);
  const handlePasswordValidation = setupPasswordValidator(formErrors, setFormErrors, t, i18nOpts);

  const disableNext =
    [firstName, lastName, email, password, termsOfService, consentToDisclosure].some((value) =>
      ['', false].includes(value)
    ) ||
    formErrors.password.length ||
    formErrors.email.length;

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }
    handleFirstNameValidation(firstName);
  }, [firstNameFocused]);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }
    handleLastNameValidation(lastName);
  }, [lastNameFocused]);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }
    handleEmailValidation(email);
  }, [emailFocused]);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }
    handlePasswordValidation(password);
  }, [passwordFocused]);

  return (
    <>
      <div
        className={getFormHeaderClass(completed, isOpen)}
        onClick={() => completed && setCurrentStep(index)}
      >
        <div className="header-title">{t('create_account', i18nOpts)}</div>
        {completed && !isOpen && (
          <Icon className="registrationV2__form__header__success_icon icon icon--bg" name="check" />
        )}
      </div>

      {isOpen && (
        <>
          <div className="form__body">
            <div className="form__row">
              <InputGroup
                component="input"
                name="firstName"
                label={t('first_name', i18nOpts)}
                value={firstName}
                onBlur={() => {
                  setFirstNameDirty(true);
                  setFirstNameFocused(!firstNameFocused);
                }}
                onChange={({ target }) => {
                  if (firstNameDirty) {
                    handleFirstNameValidation(target.value);
                  }
                  setTargetUserInfo(target);
                }}
                errors={formErrors}
              />

              <InputGroup
                component="input"
                name="lastName"
                label={t('last_name', i18nOpts)}
                value={lastName}
                onBlur={() => {
                  setLastNameDirty(true);
                  setLastNameFocused(!lastNameFocused);
                }}
                onChange={({ target }) => {
                  if (lastNameDirty) {
                    handleLastNameValidation(target.value);
                  }
                  setTargetUserInfo(target);
                }}
                errors={formErrors}
              />
            </div>

            <InputGroup
              component="input"
              name="email"
              label={t('email', i18nOpts)}
              value={email}
              onChange={({ target }) => {
                if (emailDirty) {
                  handleEmailValidation(target.value);
                }
                setTargetUserInfo(target);
              }}
              onBlur={() => {
                setEmailDirty(true);
                setEmailFocused(!emailFocused);
              }}
              errors={formErrors}
            />

            <PasswordInput
              label={t('password', i18nOpts)}
              name="password"
              value={password}
              onChange={({ target }) => {
                if (passwordDirty) {
                  handlePasswordValidation(target.value);
                }
                setTargetUserInfo(target);
              }}
              hint={t('password_hint', i18nOpts)}
              onBlur={() => {
                setPasswordDirty(true);
                setPasswordFocused(!passwordFocused);
              }}
              errors={formErrors}
            />

            <div className="form__body form__body__disclosure">
              <Checkbox
                checked={termsOfService}
                name="termsOfService"
                text={t('terms_of_service', i18nOpts)}
                onChange={() => {
                  setTargetUserInfo({
                    name: 'termsOfService',
                    value: !termsOfService,
                  });
                }}
              />

              <Checkbox
                checked={consentToDisclosure}
                name="consentToDisclosure"
                text={t('consent', { ...i18nOpts, place: placeName })}
                onChange={() => {
                  setTargetUserInfo({
                    name: 'consentToDisclosure',
                    value: !consentToDisclosure,
                  });
                }}
              />
            </div>

            <ApiErrorMessage apiErrorMessage={apiErrorMessage} />
          </div>

          <div className="form__footer">
            <ActionButton
              className="form__footer__submit"
              isLoading={isLoading}
              disabled={isLoading || disableNext}
              onClick={() => {
                validateUser(user, validateUserUrl);
              }}
              content={t('next', i18nOpts)}
            />
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ signupPage: { currentStep, isLoading, apiErrorMessage, user } }) => {
  return {
    currentStep,
    isLoading,
    apiErrorMessage,
    user,
  };
};

const mapDispatchToProps = {
  setCurrentStep: (index) => setCurrentStep(index),
  setTargetUserInfo: (targetInfo) => setTargetUserInfo(targetInfo),
  setIsLoading: (isLoading) => setIsLoading(isLoading),
  setApiErrorMessage: (apiErrorMessage) => setApiErrorMessage(apiErrorMessage),
  goToNextStep,
};

UserForm.propTypes = {
  currentStep: PropTypes.number.isRequired,
  goToNextStep: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  setApiErrorMessage: PropTypes.func.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setTargetUserInfo: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  validateUserUrl: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
