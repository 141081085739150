import React from 'react';
import PropTypes from 'prop-types';

const ListItem = ({ data: { property, subproperty, value } }) => (
  <div className="stats-list">
    <div className="stats-list__property">{property}</div>
    {subproperty && <div className="stats-list__subproperty">{subproperty}</div>}
    {value && <div className="stats-list__value">{value}</div>}
    {!subproperty && !value && <div className="stats-list__value">-</div>}
  </div>
);

ListItem.propTypes = {
  data: PropTypes.shape({
    property: PropTypes.string,
    subproperty: PropTypes.string,
    value: PropTypes.string,
  }),
};

ListItem.defaultProps = {
  data: {
    property: '',
    subproperty: '',
    value: '',
  },
};

export default ListItem;
