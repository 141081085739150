import React from 'react';
import PropTypes from 'prop-types';
import XAxisTickFormatter from '../XAxisTickFormatter';
import Icon from 'Components/Base/Icon';

import { BarChart, Bar, ResponsiveContainer, XAxis } from 'recharts';

const renderLabel = ({ x, index, data, width }) => {
  if (data[index].value === '') {
    return (
      <g>
        <foreignObject x={x + width / 2.5} y={65} className="icon close">
          <Icon className="no-data" name="close" />
        </foreignObject>
      </g>
    );
  }

  if (data[index].avoidance) {
    return (
      <g>
        <foreignObject x={x + width / 3} y={0} className="icon avoidance">
          <Icon name="avoidance" />
        </foreignObject>
      </g>
    );
  }

  return null;
};

const Chart = ({ data }) => {
  return (
    <div className="chart worry-intensity">
      <ResponsiveContainer width="100%" height={150}>
        <BarChart height={40} data={data} barCategoryGap="12%">
          <Bar dataKey="displayValue" fill="fill" label={(args) => renderLabel({ ...args, data })} />
          <XAxis
            dataKey="day"
            type="category"
            allowDuplicatedCategory={false}
            axisLine={false}
            tickLine={false}
            tick={<XAxisTickFormatter />}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
Chart.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Chart;
