const LEGEND_COLORS_MAP = {
  veryLow: '#DDF0F7',
  low: '#007FAE40',
  medium: '#007FAE80',
  high: '#036294',
  veryHigh: '#003B69',
  didNotOccur: '#DDDDDD',
  noData: '#FFF',
};

const calculateFill = (value) => {
  if (value === null) {
    return LEGEND_COLORS_MAP.noData;
  } else if (value <= 2) {
    return LEGEND_COLORS_MAP.veryLow;
  } else if (value > 2 && value <= 4) {
    return LEGEND_COLORS_MAP.low;
  } else if (value > 4 && value <= 6) {
    return LEGEND_COLORS_MAP.medium;
  } else if (value > 6 && value <= 8) {
    return LEGEND_COLORS_MAP.high;
  } else if (value > 8) {
    return LEGEND_COLORS_MAP.veryHigh;
  } else {
    return LEGEND_COLORS_MAP.noData;
  }
};

export { calculateFill as default, LEGEND_COLORS_MAP as legendMap };
