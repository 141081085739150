import React from 'react';
import ProviderWrapper from 'Components/Base/ProviderWrapper';
import RecommendationsCard from './RecommendationsCard';

const Recommendations = () => (
  <ProviderWrapper>
    <RecommendationsCard />
  </ProviderWrapper>
);

export default Recommendations;
