import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';

import { Icon, Tooltip } from 'Components/Base';
import { RequestedHelp } from 'Components/HelpRequest';

const i18nOpts = { scope: 'care_plans.generic_order_section.generic_order' };

const GenericOrder = ({ text, completedAt, link, helpRequest }) => (
  <li className={`order ${completedAt ? 'order--completed' : 'order--not-completed'}`}>
    <div className="order__section text-truncate">
      <b>{text}</b>
      <RequestedHelp helpRequested={!!helpRequest} />
    </div>

    <div className="order__section">
      {completedAt ? (
        <span className="order__status">
          <Icon name="check_alt3" className="order__section--check-icon" />
          {t('complete', i18nOpts)}
        </span>
      ) : (
        <span className="order__status">{t('incomplete', i18nOpts)}</span>
      )}

      <Tooltip text={t('edit', i18nOpts)} className="order__section--tooltip">
        <a href={link}>
          <Icon name="pencil-edit" />
        </a>
      </Tooltip>
    </div>
  </li>
);

GenericOrder.propTypes = {
  completedAt: PropTypes.string,
  helpRequest: PropTypes.object,
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

GenericOrder.defaultProps = {
  completedAt: null,
  helpRequest: null,
};

export default GenericOrder;
