import { request } from '../handleRequest';

export const buildRequestURL = (carePlanId, id, type, recurring) => {
  let url = `/care_plan_templates/${carePlanId}/`;

  switch (type) {
    case 'Task':
      url += recurring ? `repeated_task_templates/${id}` : `task_templates/${id}`;
      break;
    case 'Appointment':
      url += recurring ? `repeated_appointment_templates/${id}` : `task_templates/${id}`;
      break;
    default:
      url = '/404';
  }

  return url;
};

export const deleteActivityTemplateApiWrapper = (
  requestProvider = request,
  buildRequestURLProvider = buildRequestURL
) => {
  return async (carePlanId, activityId, type, recurring) => {
    try {
      const url = buildRequestURLProvider(carePlanId, activityId, type, recurring);
      const response = await requestProvider(url, 'DELETE');

      const status = response.status;

      return { status };
    } catch (error) {
      return { status: 422, error };
    }
  };
};

export default deleteActivityTemplateApiWrapper(request, buildRequestURL);
