import React from 'react'
import PropTypes from 'prop-types'

const MenuItem = ({ mobile, onClick, text }) => (
  <span onClick={onClick} className={`dropdown__item ${mobile && 'dropdown__item--mobile'}`}>
    {text}
  </span>
)

MenuItem.propTypes = {
  mobile: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
}

MenuItem.defaultProps = {
  mobile: false,
}

export default MenuItem
