import { createSelector } from 'reselect'

export const getPrimaryCaregivers = (state) => state.supportTeam.primaryCaregivers.members
export const getCaregivers = (state) => state.supportTeam.caregivers.members
export const getSupport = (state) => state.supportTeam.support.members
export const getPatient = (state) => state.supportTeam.patient.members
export const getUnaffiliatedMembers = (state) => state.supportTeam.unaffiliatedHealthCare.members
export const getPrimaryCaregiversErrors = (state) => state.supportTeam.primaryCaregivers.errors
export const getCaregiversErrors = (state) => state.supportTeam.caregivers.errors
export const getSupportErrors = (state) => state.supportTeam.support.errors
export const getAllMembers = createSelector(
  [getPrimaryCaregivers, getCaregivers, getSupport, getPatient, getUnaffiliatedMembers],
  (primaryCaregivers, caregivers, support, patient, unaffiliatedHealthCare) => [
    ...support,
    ...caregivers,
    ...patient,
    ...primaryCaregivers,
    ...unaffiliatedHealthCare,
  ]
)
