import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import PatientForm from './PatientForm';
import CareteamApi from 'Api/CarePlanCreator/Careteam';
import SectionFooter from '../SectionFooter';

import PrimaryCaregiverForm from './PrimaryCaregiverForm';
import { t } from 'i18n';

const i18nOpts = { scope: 'care_plan_creators.careteam.index' };

class Careteam extends Component {
  constructor(props) {
    super(props);

    const {
      carePlan: { patient, primaryCaregivers },
    } = this.props;

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeAndSubmit = this.handleChangeAndSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleErrors = this.handleErrors.bind(this);
    this.navigateToNextSection = this.navigateToNextSection.bind(this);

    this.state = {
      errors: {},
      isSubmitting: false,
      patient,
      primaryCaregivers,
    };
  }

  navigateToNextSection() {
    window.location.hash = '#finish';
  }

  handleChange({ target: { name, value } }) {
    this.setState((prevState) => _.set(prevState, name, value));
  }

  handleSuccess(teamMemberships, navigate = true) {
    this.setState({ errors: {}, ...teamMemberships });
    if (navigate) this.navigateToNextSection();
  }

  handleErrors(errors) {
    this.setState({ errors });
  }

  handleChangeAndSubmit({ target: { name, value } }) {
    const navigate = false;
    this.setState(
      (prevState) => _.set(prevState, name, value),
      () => this.handleSubmit(navigate)
    );
  }

  handleSubmit(navigate = true) {
    this.setState({ isSubmitting: true });

    return new CareteamApi(this.props.carePlan.uuid)
      .update({
        patient: this.state.patient,
        primaryCaregivers: this.state.primaryCaregivers,
      })
      .then((res) => {
        this.setState({ isSubmitting: false });

        if (res.status === 200) {
          res.json().then((teamMemberships) => this.handleSuccess(teamMemberships, navigate));
        } else if (res.status === 422) {
          res.json().then(this.handleErrors);
        }
      });
  }

  render() {
    const {
      carePlan: { uuid },
    } = this.props;

    const {
      patient,
      patient: { name },
      primaryCaregivers,
      errors,
      isSubmitting,
    } = this.state;

    return (
      <div>
        <div>
          <p className="text-primary-lighter careplan-wizard__section-sublabel">
            {t('subtitle', { ...i18nOpts, name: name })}
          </p>
        </div>
        <hr />
        <hr />
        <PatientForm onChange={this.handleChange} errors={errors} patient={patient} />

        <PrimaryCaregiverForm
          onChange={this.handleChange}
          errors={errors}
          patientName={name}
          primaryCaregivers={primaryCaregivers}
          carePlanUuid={uuid}
          changeAndSubmit={this.handleChangeAndSubmit}
        />

        <SectionFooter
          onSkip={this.navigateToNextSection}
          onNext={this.handleSubmit}
          isSubmitting={isSubmitting}
        />
      </div>
    );
  }
}

Careteam.propTypes = {
  carePlan: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    patient: PropTypes.shape({
      invitation: PropTypes.shape({
        email: PropTypes.string,
      }),
      name: PropTypes.string.isRequired,
    }),
    primaryCaregivers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        permission: PropTypes.bool,
        relationship: PropTypes.string,
        invitation: PropTypes.shape({
          email: PropTypes.string,
          firstName: PropTypes.string,
          lastName: PropTypes.string,
          phone: PropTypes.string,
        }),
      })
    ),
  }),
};

Careteam.defaultProps = {
  carePlan: {
    patient: { invitation: { email: '' }, name: '' },
    primaryCaregivers: [],
  },
};

export default Careteam;
