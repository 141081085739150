import React, { Component } from 'react'
import PropTypes from 'prop-types'

import RepeatedTaskOccurrenceApi from 'Api/RepeatedTaskOccurrence'
import RepeatedAppointmentOccurrenceApi from 'Api/RepeatedAppointmentOccurrence'
import Assignments from 'Components/Activities/Assignments'

class AssignmentsContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      assignments: props.assignments,
    }

    this.claim = this.claim.bind(this)
    this.updateStatus = this.updateStatus.bind(this)
    this.dismissAndClaim = this.dismissAndClaim.bind(this)
  }

  get api() {
    return this.props.type === 'RepeatedTask'
      ? new RepeatedTaskOccurrenceApi(this.props.carePlanId, this.props.id, this.props.occurrence)
      : new RepeatedAppointmentOccurrenceApi(this.props.carePlanId, this.props.id, this.props.occurrence)
  }

  claim() {
    return this.api.create_assignment({ teamMembershipId: this.props.currentUser.id }).then((res) => {
      if (res.ok) {
        const location = res.headers.get('location')
        window.location.assign(location)
      } else if (res.status === 422) {
        return res.json().then(console.error)
      }
    })
  }

  updateStatus(id, teamMembershipId, status) {
    return this.api.update_assignment({ teamMembershipId, status }).then((res) => {
      if (res.ok) {
        const location = res.headers.get('location')
        window.location.assign(location)
      } else if (res.status === 422) {
        return res.json().then(console.error)
      }
    })
  }

  /* eslint-disable no-unused-vars */
  dismissAndClaim(_id, _teamMembershipId) {
    /* eslint-enable no-unused-vars */
    // #dismissAndClaim is a function prop expected by the Activities/Assignments component;
    // in the case of non-repeating activities like tasks, #dismissAndClaim makes two API calls;
    // one to dismiss an assignment, and the other to claim - e.g.:
    //    this.updateStatus(id, teamMembershipId, 'dismissed')
    //    this.claim()

    // Currently, #dismissAndClaim applies to a state that is inaccessible to RepeatedTask Occurrences

    throw 'dismissAndClaim is not implemented for the RepeatedTasks/Show/AssignmentsContainer'
  }

  render() {
    return (
      <Assignments
        currentTeamMembershipId={this.props.currentTeamMembershipId}
        assignments={this.props.assignments}
        currentUser={this.props.currentUser}
        carePlanId={this.props.carePlanId}
        canClaim={this.props.canClaim}
        path={this.props.path}
        claim={this.claim}
        updateStatus={this.updateStatus}
        dismissAndClaim={this.dismissAndClaim}
      />
    )
  }
}

AssignmentsContainer.propTypes = {
  assignments: PropTypes.array,
  canClaim: PropTypes.bool.isRequired,
  carePlanId: PropTypes.string.isRequired,
  currentTeamMembershipId: PropTypes.number.isRequired,
  currentUser: PropTypes.object,
  id: PropTypes.number.isRequired,
  occurrence: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

AssignmentsContainer.defaultProps = {
  assignments: [],
  currentUser: {},
}

export default AssignmentsContainer
