import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import AssignmentApi from 'Api/Assignment'
import Assignments from 'Components/Activities/Assignments'
import { RequestedHelp } from 'Components/HelpRequest'

class AssignmentsContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      assignments: props.assignments,
    }

    this.api = this.api.bind(this)
    this.claim = this.claim.bind(this)
    this.updateStatus = this.updateStatus.bind(this)
    this.dismissAndClaim = this.dismissAndClaim.bind(this)
    this.updateAssignment = this.updateAssignment.bind(this)
  }

  api(assignmentId) {
    return new AssignmentApi(this.props.carePlanId, this.props.id, assignmentId)
  }

  claim() {
    return this.api()
      .create({ teamMembershipId: this.props.currentTeamMembershipId })
      .then((res) => {
        if (res.ok) {
          return res.json().then(({ assignment }) => {
            const assignments = [...this.state.assignments, assignment]
            this.setState({ assignments })
          })
        } else if (res.status === 422) {
          return res.json().then(console.error)
        }
      })
  }

  updateStatus(id, teamMembershipId, status) {
    return this.api(id)
      .update({ teamMembershipId, status })
      .then((res) => {
        if (res.ok) {
          return res.json().then(this.updateAssignment)
        } else if (res.status === 422) {
          return res.json().then(console.error)
        }
      })
  }

  updateAssignment({ assignment }) {
    const assignments = this.state.assignments.map((a) => (a.id === assignment.id ? assignment : a))

    this.setState({ assignments })
  }

  dismissAndClaim(id, teamMembershipId) {
    this.updateStatus(id, teamMembershipId, 'dismissed')
    this.claim()
  }

  render() {
    return (
      <Fragment>
        <Assignments
          id={this.props.id}
          assignments={this.state.assignments}
          carePlanId={this.props.carePlanId}
          canClaim={this.props.canClaim}
          path={this.props.path}
          claim={this.claim}
          updateStatus={this.updateStatus}
          dismissAndClaim={this.dismissAndClaim}
          currentTeamMembershipId={this.props.currentTeamMembershipId}
        />
        <RequestedHelp helpRequested={!!this.props.helpRequest} />
      </Fragment>
    )
  }
}

AssignmentsContainer.propTypes = {
  assignments: PropTypes.array,
  canClaim: PropTypes.bool.isRequired,
  carePlanId: PropTypes.string.isRequired,
  currentTeamMembershipId: PropTypes.number.isRequired,
  helpRequest: PropTypes.object,
  id: PropTypes.number.isRequired,
  path: PropTypes.string.isRequired,
}

AssignmentsContainer.defaultProps = {
  assignments: [],
  helpRequest: {},
}

export default AssignmentsContainer
