import React from 'react'
import PropTypes from 'prop-types'

import InputItemPill from 'Components/Forms/InputItemPill'
import Avatar from 'PulseBackports/Avatar'

const Contact = ({ name, title, photo, onClick, isSelected, radio, titleProminence = false }) => (
  <div className="new-contact">
    <InputItemPill
      name={name}
      radio={radio}
      selected={isSelected}
      stretch
      title={title}
      titleProminence={titleProminence}
      toggleSelection={onClick}
      renderImage={() => <Avatar src={photo} fullName={name} size={2} />}
    />
  </div>
)

Contact.propTypes = {
  isSelected: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  photo: PropTypes.string,
  radio: PropTypes.bool,
  title: PropTypes.string,
  titleProminence: PropTypes.bool,
}

Contact.defaultProps = {
  isSelected: false,
  radio: false,
  title: '',
}

export default Contact
