import React, { useState, useEffect } from 'react';
import PasswordInput from 'Components/Forms/PasswordInput';
import { t } from 'i18n';

const i18nOpts = { scope: 'registration.user' };

const PasswordField = ({
  id = 'user_password',
  name = 'user[password]',
  passwordError,
  containerClassName,
}) => {
  const [password, setPassword] = useState('');
  const [ruleMet, setRuleMet] = useState([false, false, false, false]);
  const [formErrors, setFormErrors] = useState(passwordError ? passwordError.split(',') : []);

  const handlePasswordChange = ({ target }) => {
    const val = target.value;
    setPassword(val);

    const rulesMet = [val.length >= 8, /[A-Z]/.test(val), /[a-z]/.test(val), /[0-9!@#$%^&*()]/.test(val)];
    setRuleMet(rulesMet);

    setFormErrors([]);
  };

  return (
    <>
      <PasswordInput
        id={id}
        name={name}
        value={password}
        onChange={handlePasswordChange}
        containerClassName={containerClassName}
        inputContainerClassName={formErrors.length ? 'error-field' : ''}
      />
      {formErrors.length > 1 ? (
        <ul className="error">
          {formErrors.map((error, idx) => (
            <li key={idx}>{error}</li>
          ))}
        </ul>
      ) : (
        <span className="error">{formErrors}</span>
      )}
      {id === 'user_password' && password && (
        <div className="hint">
          {t('must_have', i18nOpts)}
          <ul>
            <li className={ruleMet[0] ? 'checkmark' : 'disc'}>{t('eight_chars', i18nOpts)}</li>
            <li className={ruleMet[1] ? 'checkmark' : 'disc'}>{t('upper_case', i18nOpts)}</li>
            <li className={ruleMet[2] ? 'checkmark' : 'disc'}>{t('lower_case', i18nOpts)}</li>
            <li className={ruleMet[3] ? 'checkmark' : 'disc'}>{t('special_char', i18nOpts)}</li>
          </ul>
        </div>
      )}
    </>
  );
};

export default PasswordField;
