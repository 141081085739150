import deleteActivityApi from './deleteActivityApi';

export const handleDeleteActivityWrapper = (deleteActivityApiProvider = deleteActivityApi) => {
  return async ({
    setActivityModalState,
    carePlanId,
    activityId,
    type,
    dueAt,
    onDelete,
  }) => {
    setActivityModalState('LOADING');

    try {
      const { responseType } = await deleteActivityApiProvider({
        carePlanId,
        type,
        activityId,
        dueAt,
      });

      if (responseType === 'DATA') {
        onDelete();
      } else {
        throw 'Missing data';
      }
    } catch (error) {
      setActivityModalState('ERROR');
      console.error(error);
    }
  };
};

export default handleDeleteActivityWrapper(deleteActivityApi);
