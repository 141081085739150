import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'Components/Base/Modal';
import { t } from 'i18n';
import clinician_form from 'clinician_form.svg';

const i18nOptsForm = { scope: 'tasks.form' };

const FormSuccessModal = ({ carePlanId }) => {
  const [isOpen, setIsOpen] = useState(true);

  const close = () => {
    setIsOpen(false);
    let url = window.location.href;

    if (url) {
      url = url.replace('?status=success', '');
    }
    window.history.replaceState(null, '', url);
  };

  const handleCallbackSuccessClick = (event) => {
    event.preventDefault();
    close();
    window.location.href = `${window.location.origin}/care_plans/${carePlanId}/patient#form_results`;
  };

  const renderFormSuccess = () => {
    return (
      <div className="callback">
        <div className="callback__image">
          <img src={clinician_form} />
        </div>
        <div className="callback__content">
          <div className="callback__content__wrapper">
            <h1>{t('callback_header', i18nOptsForm)}</h1>
            <p>
              {t('callback_body.0', i18nOptsForm)}
              <a className="callback__content__wrapper--link" href="#" onClick={handleCallbackSuccessClick}>
                {t('callback_body.1', i18nOptsForm)}
              </a>
              {t('callback_body.2', i18nOptsForm)}
            </p>
            <div className="callback__content__wrapper--button">
              <button onClick={close}>{t('callback_button', i18nOptsForm)}</button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      className="ReactModal__Content ReactModal__Content--forms"
      bodyChildren="ReactModal__Content-Body-Form"
      isOpen={isOpen}
      closeModal={close}
      title={t('complete_form', i18nOptsForm)}
    >
      {renderFormSuccess()}
    </Modal>
  );
};

FormSuccessModal.propTypes = {
  carePlanId: PropTypes.string,
};

FormSuccessModal.defaultProps = {
  carePlanId: '',
};

export default FormSuccessModal;
