import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import {
  setActivityModalIsOpen,
  setActivityModalCarePlanId,
  setActivityModalMode,
  setActivityModalActivityType,
  setActivityModalTimeZone,
} from 'Actions/activityModal';

import { Card, AddActionHeader } from 'Components/Base/Card/';
import Loader from 'Components/Base/Loader';
import CheckIn from './CheckIn';
import RepeatedCheckIn from './RepeatedCheckIn';

const i18nOpts = { scope: 'care_plan_action_plan_page.activities_container.check_ins_card' };

const renderHeaderAction = (onClick) => {
  return (actionWrapper) => {
    return (
      <AddActionHeader
        onClick={() => {
          actionWrapper(onClick);
        }}
      />
    );
  };
};

const renderCheckInsList = (checkIns, repeatedCheckIns) => {
  if (checkIns?.length === 0 && repeatedCheckIns?.length === 0) {
    return <div className="item-pill-section--item-list-empty">{t('empty', i18nOpts)}</div>;
  }

  return (
    <ul>
      {checkIns.map((checkIn) => {
        return (
          <CheckIn
            key={`check-in-${checkIn.id}`}
            name={checkIn.name}
            completed={checkIn.completed}
            formattedDueAt={checkIn.formattedDueAt}
          />
        );
      })}

      {repeatedCheckIns.map((repeatedCheckIn) => {
        return (
          <RepeatedCheckIn
            key={`repeated-check-in-${repeatedCheckIn.id}`}
            name={repeatedCheckIn.name}
            localizedFrequency={repeatedCheckIn.localizedFrequency}
            until={repeatedCheckIn.until}
            formattedTime={repeatedCheckIn.formattedTime}
          />
        );
      })}
    </ul>
  );
};

const CheckInsCard = ({
  carePlanId,
  sessionInfo,
  checkInsCard,
  setActivityModalIsOpen,
  setActivityModalCarePlanId,
  setActivityModalMode,
  setActivityModalActivityType,
  setActivityModalTimeZone,
}) => {
  const { checkInsCardState, checkIns, repeatedCheckIns } = checkInsCard;

  let content;
  switch (checkInsCardState) {
    case 'LOADING':
      content = <Loader />;
      break;
    case 'ERROR':
      content = <div className="activities-container__error">{t('api_error', i18nOpts)}</div>;
      break;
    case 'MAIN_CONTENT':
      content = renderCheckInsList(checkIns, repeatedCheckIns);
      break;
    default:
      content = <div className="activities-container__error">{t('unexpected_error', i18nOpts)}</div>;
      break;
  }

  return (
    <Card
      id='check_ins'
      title={t('title', i18nOpts)}
      cardContainerClass='activities-card-container'
      collapsable={true}
      renderHeaderAction={
        sessionInfo.canAdd &&
        renderHeaderAction(() => {
          setActivityModalMode('CREATE');
          setActivityModalActivityType('CHECK_IN');
          setActivityModalCarePlanId(carePlanId);
          setActivityModalTimeZone(sessionInfo.timeZone);
          setActivityModalIsOpen(true);
        })
      }
    >
      {content}
    </Card>
  );
};

const mapStateToProps = ({ carePlanActionPlanViewPage }) => {
  const { carePlanId, sessionInfo, checkInsCard } = carePlanActionPlanViewPage;

  return {
    carePlanId,
    sessionInfo,
    checkInsCard,
  };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalIsOpen = (dispatch) => {
    return (isOpen) => {
      dispatch(setActivityModalIsOpen(isOpen));
    };
  };

  const dispatchSetActivityModalMode = (dispatch) => {
    return (mode) => {
      dispatch(setActivityModalMode(mode));
    };
  };

  const dispatchSetActivityModalActivityType = (dispatch) => {
    return (activityType) => {
      dispatch(setActivityModalActivityType(activityType));
    };
  };

  const dispatchSetActivityModalCarePlanId = (dispatch) => {
    return (carePlanId) => {
      dispatch(setActivityModalCarePlanId(carePlanId));
    };
  };

  const dispatchSetActivityModalTimeZone = (dispatch) => {
    return (timeZone) => {
      dispatch(setActivityModalTimeZone(timeZone));
    };
  };

  return {
    setActivityModalIsOpen: dispatchSetActivityModalIsOpen(dispatch),
    setActivityModalCarePlanId: dispatchSetActivityModalCarePlanId(dispatch),
    setActivityModalMode: dispatchSetActivityModalMode(dispatch),
    setActivityModalActivityType: dispatchSetActivityModalActivityType(dispatch),
    setActivityModalTimeZone: dispatchSetActivityModalTimeZone(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckInsCard);
