import React, { Component } from 'react'
import { t } from 'i18n'
import PropTypes from 'prop-types'
import InputGroup from 'Components/Forms/InputGroup'
import ScoreIcon from 'Components/Base/ScoreIcon'

const i18nOpts = { scope: 'care_plan_creators.check_in_templates.questions' }

class GenericScaleQuestionOption extends Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.buildInitialState = this.buildInitialState.bind(this)

    this.state = this.buildInitialState(props)
  }

  buildInitialState() {
    const { questionOption } = this.props

    return {
      id: questionOption.id,
      text: questionOption.data.text,
      description: questionOption.data.description || '',
      response: questionOption.data.response || '',
      index: questionOption.id || questionOption.data.index,
      order: questionOption.data.order,
      score: questionOption.data.score,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState(this.buildInitialState())
    }
  }

  handleChange({ target: { name, value } }) {
    const currentState = this.state
    currentState[name] = value
    this.setState(currentState, function () {
      this.props.updateOptions(this.state)
    })
  }

  render() {
    const { text, description, score, response } = this.state
    const { isActive, maxDesc, maxTitle, renderConditionalResponse } = this.props

    return (
      <li>
        <div className="option-input-wrapper">
          <div className="option-index">{score}</div>

          <div className="option-inputs">
            <div>
              <InputGroup
                component="input"
                name="text"
                placeholder={t('placeholders.generic_scale_text', i18nOpts)}
                value={text}
                onChange={this.handleChange}
                required
                maxLength={maxTitle}
                className="option-text"
                type="text"
                readonly={isActive}
              />
              <span className="character-count">{maxTitle - text.length}</span>
            </div>
            <div>
              <InputGroup
                component="input"
                name="description"
                placeholder={t('placeholders.generic_scale_description', i18nOpts)}
                value={description}
                onChange={this.handleChange}
                maxLength={maxDesc}
                className="option-description"
                type="text"
                readonly={isActive}
              />
              <span className="character-count">{maxDesc - (description || '').length}</span>
            </div>
            {renderConditionalResponse(response, this.handleChange)}
          </div>

          <div className="option-icon">
            <ScoreIcon score={score} />
          </div>
        </div>
      </li>
    )
  }
}

GenericScaleQuestionOption.propTypes = {
  isActive: PropTypes.bool,
  maxDesc: PropTypes.number,
  maxTitle: PropTypes.number,
  questionOption: PropTypes.object,
  renderConditionalResponse: PropTypes.func.isRequired,
  updateOptions: PropTypes.func,
}

GenericScaleQuestionOption.defaultProps = {
  isActive: false,
  maxDesc: 60,
  maxTitle: 25,
  questionOption: {},
  updateOptions: null,
}

export default GenericScaleQuestionOption
