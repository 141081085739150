import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'Components/Base/Icon'
import { connect } from 'react-redux'

import { t } from 'i18n'

const ButtonLink = ({ link, displayButton, modifiable }) =>
  displayButton && modifiable ? (
    <a
      href={link}
      data-test="edit"
      className="card-image-header__btn btn btn--circle"
      data-balloon={t('edit', { scope: 'care_plans.generic_order_section.generic_order' })}
      data-balloon-pos="up"
    >
      <Icon name="pencil-edit" />
    </a>
  ) : null

ButtonLink.propTypes = {
  displayButton: PropTypes.bool.isRequired,
  link: PropTypes.string.isRequired,
  modifiable: PropTypes.bool.isRequired,
}

const mapStateToProps = (state, ownProps) => ({
  displayButton: !state.editButton.completed,
  ...ownProps,
})

export default connect(mapStateToProps, null)(ButtonLink)
