import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';

import { Card, AddActionHeader } from 'Components/Base/Card/';
import { IFrame } from 'Components/Base';
import ReferralConfirmation from './ReferralConfirmation';
import { Modal } from 'Components/Base/Modal';
import { ReferralTile } from './';

import Referral from 'Api/Referral';

const i18nOpts = { scope: 'care_plans.referral_section.index' };

class ReferralSectionContainer extends Component {
  constructor(props) {
    super(props);

    this.closeModal = this.closeModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReferralRequest = this.handleReferralRequest.bind(this);
    this.handleReferralConfirmation = this.handleReferralConfirmation.bind(this);

    this.state = {
      link: '',
      confirmationModalIsOpen: false,
      modalIsOpen: false,
      currentServiceId: '',
      referrals: props.referrals || [],
    };
  }

  get api() {
    const { carePlanId } = this.props;
    return new Referral(carePlanId, this.state.currentServiceId);
  }

  get cardProps() {
    let renderHeaderAction = null;

    if (this.props.canAdd) {
      renderHeaderAction = (actionWrapper) => (
        <AddActionHeader onClick={() => actionWrapper(this.handleReferralConfirmation)} />
      );
    }

    return {
      title: t('header', i18nOpts),
      collapsable: true,
      renderHeaderAction,
    };
  }

  componentDidMount() {
    window.addEventListener('message', this.handleSubmit, false);
  }

  handleSubmit({ data }) {
    if (data.message === 'close') {
      if (!data.referral_data) {
        this.closeModal();
      } else {
        return this.api.show().then((res) => {
          if (res.ok) {
            res.json().then((response) => {
              this.setState((prevState) => ({
                link: '',
                referrals: [...prevState.referrals, response.referral],
                currentServiceId: '',
                modalIsOpen: false,
              }));
            });
          } else {
            this.handleError();
          }
        });
      }
    }
  }

  handleReferralConfirmation() {
    this.setState((prevState) => ({
      confirmationModalIsOpen: !prevState.confirmationModalIsOpen,
    }));
  }

  handleReferralRequest(isSSO, menuTop, menuSub, address) {
    return this.api.create({ isSSO }).then((res) => {
      if (res.ok) {
        res.json().then(({ id, launch_string }) => {
          // `launch_string` is the raw one-time generated url provided by caredove
          // here we concat searchParams to the url for it to
          // preselect options when the url opens
          // menuTop: correponds to `services.referral_category`
          // menuSub: corresponds to `services.referral_subcategory`
          const link = new URL(launch_string);

          link.searchParams.set('searchurl', 'true');
          if (menuTop) link.searchParams.set('menutop', menuTop);
          if (menuSub) link.searchParams.set('menusub', menuSub);
          if (address) link.searchParams.set('address', address);
          link.searchParams.set('lan', this.props.locale || 'en');

          this.setState(
            {
              currentServiceId: id,
              modalIsOpen: true,
              confirmationModalIsOpen: false,
              link: link.href
            }
          )
        });
      } else {
        this.handleError();
      }
    });
  }

  handleError() {
    window.flash_messages.addMessage(t('error_message', i18nOpts));
    this.closeModal();
  }

  closeModal() {
    this.setState({ link: '', modalIsOpen: false, currentServiceId: '' });
  }

  render() {
    const { referrals, modalIsOpen, link, confirmationModalIsOpen } = this.state;
    const { address, locale, carePlanId, signedInWithSso, onBehalf, isPlaceUser } = this.props;

    return (
      <>
        <Card {...this.cardProps}>
          <ul>
            {referrals.length ? (
              referrals.map((referral) => <ReferralTile key={referral.id} {...referral} />)
            ) : (
              <li className="item-pill-section--item-list-empty">{t('empty', i18nOpts)}</li>
            )}
          </ul>
        </Card>
        <Modal
          isOpen={confirmationModalIsOpen}
          closeModal={this.handleReferralConfirmation}
          title={t('header', i18nOpts)}
        >
          <ReferralConfirmation
            address={address}
            locale={locale}
            link={link}
            isSSO={signedInWithSso}
            closeModal={this.handleReferralConfirmation}
            isPlaceUser={isPlaceUser}
            onBehalf={onBehalf}
            onClick={this.handleReferralRequest}
            carePlanId={carePlanId}
          />
        </Modal>
        <Modal
          className="ReactModal__Content ReactModal__Content--referrals"
          isOpen={modalIsOpen}
          closeModal={this.closeModal}
          title={t('add_referral', i18nOpts)}
        >
          <IFrame link={link} className="ReactModal__Content--referrals--iframe" />
        </Modal>
      </>
    );
  }
}

ReferralSectionContainer.propTypes = {
  address: PropTypes.string,
  canAdd: PropTypes.bool.isRequired,
  carePlanId: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  referrals: PropTypes.array,
  signedInWithSso: PropTypes.bool,
  onBehalf: PropTypes.bool,
  isPlaceUser: PropTypes.bool,
};

ReferralSectionContainer.defaultProps = {
  referrals: [],
  signedInWithSso: false,
  onBehalf: false,
  isPlaceUser: false
};

export default ReferralSectionContainer;
