import React from 'react'
import PropTypes from 'prop-types'

import Icon from 'Components/Base/Icon'

const ServiceLink = ({ service, link }) => (
  <div className="task__resource">
    <Icon name="file" />
    <a className="task__resource-link text-medium" href={link}>
      <span className="task__resource-name text-truncate">{service.name}</span>
      <Icon className="task__resource-icon" name="carrot-right" />
    </a>
  </div>
)

ServiceLink.propTypes = {
  link: PropTypes.string,
  service: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
}

ServiceLink.defaultProps = {
  link: '',
}

export default ServiceLink
