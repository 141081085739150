import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import { Icon } from 'Components/Base';

const i18nOpts = { scope: 'care_plans.form_section.form_order' };

const orderCompleted = () => {
  return (
    <a
      className="order--complete order--complete--underlined"
      href={window.location.href.replace('/dashboard', '') + '/patient'}
    >
      {t('completed', i18nOpts)}
    </a>
  );
};

const orderDue = (userRole) => {
  let orderDueElem = (
    <a
      className="order--due order--due--underlined"
      href={window.location.href.replace('/dashboard', '') + '/tasks/weekly'}
    >
      {t('not_completed', i18nOpts)}
    </a>
  );

  if (
    userRole === 'health_care_assistant' ||
    userRole === 'health_care_professional' ||
    userRole === 'health_team_contact'
  ) {
    orderDueElem = t('not_completed', i18nOpts);
  }
  return orderDueElem;
};

const Form = ({ userRole, name, isComplete, formattedDueAt }) => (
  <li className="order">
    <div className="order__section text-truncate">
      <div>
        <strong>{name}</strong>
      </div>
      <span>{formattedDueAt && t('due_at_statement', { ...i18nOpts, dueDate: formattedDueAt })}</span>
    </div>
    <div className="order__section">
      {isComplete ? (
        <span className="order--complete">
          <Icon name="check_alt3" className="order__section--check-icon" />
          {orderCompleted()}
        </span>
      ) : (
        <span className="order--due">{orderDue(userRole)}</span>
      )}
    </div>
  </li>
);

Form.propTypes = {
  formattedDueAt: PropTypes.string,
  isComplete: PropTypes.bool,
  name: PropTypes.string.isRequired,
  userRole: PropTypes.string,
};

Form.defaultProps = {
  formattedDueAt: '',
  isComplete: false,
};

export default Form;
