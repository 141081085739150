import React, { useState } from 'react';
import { t } from 'i18n'
import Icon from 'Components/Base/Icon';
import ActionButton from 'Components/Base/ActionButton';
import handleResendInvitation from './handleResendInvitation';

const i18nOpts = { scope: 'patient.patient' };

const ResendInvitation = ({ member }) => {
  const [pageState, setPageState] = useState('READY');
  const patientId = member.id;

  const buttonCopy = pageState === 'INVITATION_SENT' ? (
    t('invitation_sent', i18nOpts)
  ) : (
    t('resend_invitation', i18nOpts)
  )

  return (
    <>
      <ActionButton
        className="button-subtle button-small resend-invitation-btn"
        onClick={() => {
          handleResendInvitation(patientId, setPageState)
        }}
        content={
          <>
            <Icon name="revert" className="resend-invitation-btn--icon" />
            {buttonCopy}
          </>
        }
        isLoading={pageState === 'LOADING'}
        disabled={pageState === 'INVITATION_SENT'}
      />
    </>
  );
}

export default ResendInvitation;
