import React from 'react'
import PropTypes from 'prop-types'

import {
  ItemPill,
  ItemPillIcon,
  ItemPillActions,
  ItemPillActionIcon,
  ItemPillText,
} from 'Components/Base/ItemPill'

const Order = ({ order: { title, loading, frequency }, deleteOrder, editOrder }) => (
  <ItemPill selected>
    <ItemPillIcon iconName={frequency ? 'refresh' : 'task'} />

    <ItemPillText text={title} data-test="display-name" />

    <ItemPillActions isLoading={loading}>
      {editOrder && <ItemPillActionIcon iconName="file-edit" onClick={editOrder} data-test="edit-icon" />}
      {deleteOrder && (
        <ItemPillActionIcon iconName="minus-circle" onClick={deleteOrder} data-test="delete-icon" />
      )}
    </ItemPillActions>
  </ItemPill>
)

Order.propTypes = {
  deleteOrder: PropTypes.func,
  editOrder: PropTypes.func,
  order: PropTypes.object.isRequired,
}

Order.defaultProps = {
  deleteOrder: null,
  editOrder: null,
}

export default Order
