import React from 'react';

const RadioOption = ({ className, isSelected, onClick, children }) => {
  const classes = className ? `radio_option ${className}` : 'radio_option';
  let iconClass = 'radio_option__icon';
  iconClass += isSelected ? ' radio_option__icon--selected' : ' radio_option__icon--unselected';

  const handleClick = (e) => { onClick && onClick(e); }

  return (
    <div
      className={classes}
      onClick={handleClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleClick(e);
        }
      }}
      tabIndex="0"
    >
      <div className={iconClass}>
        {isSelected && <div className="radio_option__icon__colour" />}
      </div>
      <div className="radio_option__description">{children}</div>
    </div>
  );
};

export default RadioOption;
