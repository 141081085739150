import getCheckInsApi from './getCheckInsApi';

export const handleCheckInsSetupWrapper = (getCheckInsApiProvider = getCheckInsApi) => {
  return async (setCheckInsCardState, setCheckInsCardCheckInsList, carePlanId) => {
    setCheckInsCardState('LOADING');

    try {
      const { responseType, checkIns, repeatedCheckIns } = await getCheckInsApiProvider(carePlanId);

      if (responseType === 'DATA') {
        setCheckInsCardCheckInsList({ checkIns, repeatedCheckIns });
        setCheckInsCardState('MAIN_CONTENT');
      } else {
        throw 'Could not get check-ins.';
      }
    } catch (error) {
      setCheckInsCardState('ERROR');
      console.error(error);
    }
  };
};

export default handleCheckInsSetupWrapper(getCheckInsApi);
