import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Button, Icon } from 'Components/Base/'

const i18nOpts = { scope: 'base.action_headers' }

const EditActionHeader = ({ onClick }) => (
  <Button type="button" className="btn--link card__header-link" onClick={onClick}>
    <Icon name="pencil-edit_alt" className="card__header-link-icon" />
    <span>{t('edit', i18nOpts)}</span>
  </Button>
)

EditActionHeader.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default EditActionHeader
