import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import RelationshipInput from '../../Forms/RelationshipInput'
import Submit from '../../Forms/Submit'

const i18nOpts = { scope: 'team.relationship.relationship_form' }

const RelationshipForm = ({ relationship, onChange, onSubmit, name, cancel }) => (
  <form id="" className="form form--background user-profile__relationship-form" onSubmit={onSubmit}>
    <RelationshipInput
      errors={{}}
      name="newRelationship"
      value={relationship}
      label={t('label', { ...i18nOpts, name })}
      onChange={onChange}
    />
    <div className="form__submit">
      <Submit />
      <a className="form__secondary-submit btn btn--secondary" onClick={cancel}>
        {t('cancel', i18nOpts)}
      </a>
    </div>
  </form>
)

RelationshipForm.propTypes = {
  cancel: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

RelationshipForm.defaultProps = {}

export default RelationshipForm
