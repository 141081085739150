const paginateSignups = (currentPageNumber, maxInvs, invs) => {
  const beginSlice = (currentPageNumber - 1) * maxInvs;
  const endSlice = currentPageNumber * maxInvs;

  return invs.slice(beginSlice, endSlice);
};

const searchFilter = (input, data) =>
  data.filter(
    (inv) =>
      inv.caregiverName.toLowerCase().includes(input.toLowerCase()) ||
      inv.patientName.toLowerCase().includes(input.toLowerCase())
  );

const statusFilter = (input, data) => (input === 'all' ? data : data.filter((inv) => inv.status === input));

const tabFilter = (tab, data) => data.filter(({ entityType }) => entityType === tab);

const signupsFilter = (search, status, tab = '', data) => {
  const filteredTabs = tab ? tabFilter(tab, data) : data;
  const filteredStatus = statusFilter(status, filteredTabs);

  return searchFilter(search, filteredStatus);
};

export { paginateSignups, searchFilter, statusFilter, tabFilter, signupsFilter };
