import React from 'react';
import { t } from 'i18n';

import InputGroup from 'Components/Forms/InputGroup';
import Checkbox from 'Components/Forms/Checkbox';
import { Icon } from 'Components/Base';
import { firstName } from 'Utilities/patientHelper';

// "name" prop represents the target name of the field, e.g. for the
// second PCG added name will be "primaryCaregiver[1]" ...

const i18nOpts = { scope: 'care_plan_creators.careteam.primary_caregiver_form.sdm_type' }
const i18nConfirmationOpts = { scope: 'care_plan_creators.careteam.primary_caregiver_form.sdm_confirmation' }

const SubstituteDecisionMakerInput = ({
  name,
  patientName,
  onChange,
  sdmInfo,
  errors
}) => {
  const SDM_TYPES = [ 'substitute_decision_maker', 'poa_personal_care', 'guardian_trustee'];

  const { isSdm, isConfirmed, sdmType } = sdmInfo;

  const patientFirstName = firstName(patientName);

  const setIsSdm = (value) => {
    onChange({ target: { name: `${name}.sdmInfo.isSdm`, value } });
  };

  const toggleIsConfirmed = () => {
    onChange({ target: { name: `${name}.sdmInfo.isConfirmed`, value: !isConfirmed } });
  }

  const unassignSdm = () => {
    onChange({ target: { name: `${name}.sdmInfo.sdmType`, value: 'none' } });
    onChange({ target: { name: `${name}.sdmInfo.isConfirmed`, value: false } });
    onChange({ target: { name: `${name}.sdmInfo.isSdm`, value: false } });
  }

  if (!isSdm) return (
    <div className="assign-sdm">
      <a
        className="assign-sdm__button"
        tabIndex="0"
        onClick={() => setIsSdm(true)}
        onKeyDown={(evt) => {
          if (evt.key === ' ' || evt.key === 'Enter') {
            setIsSdm(true);
          }
        }}
      >
        {`＋ ${t('assign_button', {...i18nOpts, patient_first_name: patientFirstName })}`}
      </a>
    </div>
  );

  const hasSdmTypeSelected = sdmType && sdmType !== 'none';

  return (
    <div className="assign-sdm">
      <InputGroup
        component="select"
        name={`${name}.sdmInfo.sdmType`}
        onChange={onChange}
        value={sdmType}
        label={t('label', i18nOpts)}
        errors={errors}
      >
        <option value="none">{t('placeholder', i18nOpts)}</option>
        { SDM_TYPES.map((option) => <option key={option} value={option}>{t(option, i18nOpts)}</option>) }
      </InputGroup>

      <div className="assign-sdm__remove-container">
        <button
          className="btn--link btn--icon assign-sdm__remove-button"
          onClick={unassignSdm}
        >
          <Icon className="icon--medium icon__trash" name="trash" />
        </button>
      </div>

      {
        hasSdmTypeSelected && (
          <div className="assign-sdm__confirmation-container">
            <Checkbox
              name={`${name}.sdmInfo.isConfirmed`}
              text={t(sdmType, i18nConfirmationOpts)}
              checked={isConfirmed}
              onChange={toggleIsConfirmed}
              errors={errors}
            />
          </div>
        )
      }
    </div>
  )
}

export default SubstituteDecisionMakerInput;
