import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const NextButtons = ({
  currentPageNumber,
  onPageChange,
  totalPageCount
}) => {

  return totalPageCount > currentPageNumber
    ? <Fragment>
        <span className='next' onClick={() => { onPageChange(currentPageNumber + 1) }}><a>{ '›' }</a></span>
        <span className='last' onClick={() => { onPageChange(totalPageCount) }}><a>{ '»' }</a></span>
      </Fragment>
    : <Fragment>
        <span className='next disabled'>{ '›' }</span>
        <span className='last disabled'>{ '»' }</span>
      </Fragment>
}

NextButtons.propTypes = {
  currentPageNumber: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  totalPageCount: PropTypes.number.isRequired
}

export default NextButtons
