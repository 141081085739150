import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { t } from 'i18n'

import DocumentForm from 'Components/Forms/DocumentForm'
import DocumentList from './DocumentList'
import ItemListHeader from 'Components/Forms/ItemListHeader'
import MedicalDocumentApi from 'Api/CarePlan/MedicalDocument'
import { Modal } from 'Components/Base/Modal'

import { addDocument, deleteDocument, replaceDocument } from 'Actions/documents'

const i18nOpts = { scope: 'care_plans.document_section.edit.uploaded_documents' }

class UploadedDocuments extends Component {
  constructor(props) {
    super(props)

    const recordsProcessing = props.documents.reduce((result, document) => {
      result[document.id] = false
      return result
    }, {})

    this.state = {
      activeDocument: {},
      documents: props.documents,
      errors: {},
      editing: false,
      fileName: null,
      listIsOpen: true,
      isSubmitting: false,
      recordsProcessing,
    }

    this.closeModal = this.closeModal.bind(this)
    this.createDocument = this.createDocument.bind(this)
    this.deleteDocument = this.deleteDocument.bind(this)
    this.editDocument = this.editDocument.bind(this)
    this.toggleListVisibility = this.toggleListVisibility.bind(this)
    this.updateDocument = this.updateDocument.bind(this)
  }

  closeModal() {
    this.setState({ activeDocument: {}, editing: false, errors: {}, isSubmitting: false })
    this.props.closeModal()
  }

  createDocument(formData) {
    this.setState({ isSubmitting: true, errors: {} })

    return new MedicalDocumentApi(this.props.carePlanUuid).create(formData).then((res) => {
      if (res.ok) {
        return res.json().then(({ medicalDocument }) => {
          this.props.addDocument(medicalDocument)
          this.closeModal()
        })
      } else if (res.status === 422) {
        return res.json().then((errors) => this.setState({ errors, isSubmitting: false }))
      }
    })
  }

  deleteDocument(id) {
    this.setState((prevState) => ({ recordsProcessing: { ...prevState.recordsProcessing, [id]: true } }))

    return new MedicalDocumentApi(this.props.carePlanUuid, id).destroy().then((res) => {
      if (res.ok) {
        const documents = this.state.documents.filter((document) => document.id !== id)
        const recordsProcessing = { ...this.state.recordsProcessing }
        delete recordsProcessing[id]

        this.setState({ documents, recordsProcessing })

        this.props.deleteDocument(id)
      } else if (res.status === 422) {
        return res.json().then(console.error)
      }
    })
  }

  editDocument(document) {
    this.setState({ activeDocument: document, editing: true })
    this.props.openModal()
  }

  toggleListVisibility() {
    this.setState((prevState) => {
      return { listIsOpen: !prevState.listIsOpen }
    })
  }

  updateDocument(formData, id) {
    this.setState({ isSubmitting: true, errors: {} })

    return new MedicalDocumentApi(this.props.carePlanUuid, id).update(formData).then((res) => {
      if (res.ok) {
        return res.json().then(({ medicalDocument }) => {
          this.props.replaceDocument(id, medicalDocument)
          this.closeModal()
        })
      } else if (res.status === 422) {
        return res.json().then((errors) => this.setState({ errors, isSubmitting: false }))
      }
    })
  }

  render() {
    return (
      <div className="item-pill-section__item-list">
        <Modal
          isOpen={this.props.modalIsOpen}
          closeModal={this.closeModal}
          title={this.state.editing ? t('edit_document', i18nOpts) : t('upload_document', i18nOpts)}
        >
          <DocumentForm
            activeDocument={this.state.activeDocument}
            carePlanUuid={this.props.carePlanUuid}
            createDocument={this.createDocument}
            editing={this.state.editing}
            errors={this.state.errors}
            isSubmitting={this.state.isSubmitting}
            onCancel={this.closeModal}
            parentId={this.props.parentId}
            updateDocument={this.updateDocument}
          />
        </Modal>

        <h4 className="careplan__subheader">{t('subheader', i18nOpts)}</h4>

        <ItemListHeader
          headerText={t('list_header', i18nOpts)}
          iconName="attached"
          itemsSelectedCount={this.props.documents.length}
          toggleListVisibility={this.toggleListVisibility}
          listIsOpen={this.state.listIsOpen}
        />

        {this.state.listIsOpen && (
          <DocumentList
            documents={this.props.documents}
            editDocument={this.editDocument}
            deleteDocument={this.deleteDocument}
            recordsProcessing={this.state.recordsProcessing}
          />
        )}
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  addDocument(document) {
    dispatch(addDocument(document))
  },
  replaceDocument(currentDocumentId, newDocument) {
    dispatch(replaceDocument(currentDocumentId, newDocument))
  },
  deleteDocument(id) {
    dispatch(deleteDocument(id))
  },
})

const documentPropType = PropTypes.shape({
  author: PropTypes.object,
  canModify: PropTypes.bool,
  createdAt: PropTypes.string,
  documentFileName: PropTypes.string,
  downloadUrl: PropTypes.string,
  id: PropTypes.number,
  libraryDocumentId: PropTypes.number,
  name: PropTypes.string,
  url: PropTypes.string,
})

UploadedDocuments.propTypes = {
  // dispatch
  addDocument: PropTypes.func.isRequired,
  carePlanUuid: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  // props
  deleteDocument: PropTypes.func.isRequired,
  documents: PropTypes.arrayOf(documentPropType),
  modalIsOpen: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  parentId: PropTypes.any.isRequired,
  replaceDocument: PropTypes.func.isRequired,
}

UploadedDocuments.defaultProps = {
  documents: [],
}

export default connect(null, mapDispatchToProps)(UploadedDocuments)
