import { request } from 'Apiv2/handleRequest';

export const getFormsApiWrapper = (requestProvider = request) => {
  return async (carePlanId) => {
    try {
      const response = await requestProvider(
        `/care_plans/${carePlanId}/care_plan_action_plan_view_page/forms`,
        'GET'
      );

      const status = response.status;
      const { forms, repeated_forms } = await response.json();

      if (status === 200) {
        return {
          responseType: 'DATA',
          forms: forms,
          repeatedForms: repeated_forms,
        };
      } else {
        throw 'Missing data';
      }
    } catch (error) {
      console.error(error);
      return { responseType: 'API_ERROR' };
    }
  };
};

export default getFormsApiWrapper(request);
