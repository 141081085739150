import moment from 'ct-moment'

export const ascByTimeOfDate = (prop) => (a, b) => {
  const propA = moment(a[prop])
  const propB = moment(b[prop])

  const minutesA = propA.hours() * 60 + propA.minutes()
  const minutesB = propB.hours() * 60 + propB.minutes()

  return asc(minutesA, minutesB)
}

export const ascBy = (prop) => (a, b) => asc(a[prop], b[prop])
export const descBy = (prop) => (a, b) => desc(a[prop], b[prop])

export const asc = (a, b) => (a < b) ? -1 : (a > b) ? 1 : 0
export const desc = (a, b) => (a > b) ? -1 : (a < b) ? 1 : 0
