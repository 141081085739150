import { request } from 'Apiv2/handleRequest';

export const resendInvitationApiWrapper = (requestProvider = request) => {
  return async (id) => {
    try {
      const requestUrl = `/team/${id}/invitation/send_invitation`;

      const response = await requestProvider(requestUrl, 'POST');

      if (response.status !== 200) {
        throw new Error;
      }

      return { responseType: 'SUCCESS' };
    } catch (error) {
      return { status: 422, message: error.message, responseType: 'ERROR' };
    }
  }
}

export default resendInvitationApiWrapper(request);
