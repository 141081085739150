import React from 'react'
import PropTypes from 'prop-types'

const Backdrop = ({ onClick, transparent }) => (
  <div className={`backdrop backdrop--show ${transparent && 'backdrop--transparent'}`} onClick={onClick} />
)

Backdrop.propTypes = {
  onClick: PropTypes.func.isRequired,
  transparent: PropTypes.bool,
}

Backdrop.defaultProps = {
  transparent: false,
}

export default Backdrop
