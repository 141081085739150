import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n'

const i18nOpts = { scope: 'activity_modal.errors' };

export const Error = ({ activityModalState, activityType }) => {

  if (activityModalState !== 'ERROR') {
    return null;
  }

  return <div className="action_modal__body__error">{t('api_error', i18nOpts)}</div>;
};

const mapStateToProps = ({ activityModal }) => {
  const { activityModalState, activityType } = activityModal;

  return { activityModalState, activityType };
};

export default connect(mapStateToProps, null)(Error);
