import { SAVE_DRAFT, CLEAR_DRAFT } from 'Actions/inbox/messageDraft'

const messageDraft = (state = { drafts: [] }, action) => {
  switch (action.type) {
    case CLEAR_DRAFT:
      return {
        ...state,
        drafts: state.drafts.filter((draft) => draft.id !== action.id),
      }
    case SAVE_DRAFT: {
      const { id, body } = action
      const filtered = state.drafts.filter((data) => data.id === id)
      return {
        ...state,
        drafts:
          filtered.length > 0 ? updateDraft(state.drafts, { id, body }) : [...state.drafts, { id, body }],
      }
    }
    default:
      return state
  }
}

export default messageDraft

const updateDraft = (drafts, obj) => {
  return drafts.map((draft) => {
    if (draft.id !== obj.id) {
      return draft
    }
    return {
      ...draft,
      ...obj,
    }
  })
}
