import React from 'react';
import PropTypes from 'prop-types';

import TextGroup from './TextGroup';
import Status from './Status';
import Details from './Details';

import Avatar from 'PulseBackports/Avatar';

const Signup = ({
  patientPhoto,
  patientName,
  carePlanTitle,
  placeName,
  status,
  caregiverName,
  caregiverEmail,
  submitted,
  resolvedAt,
  entityType,
  onClick,
}) => {
  let photo;
  let name;
  let subtext;

  if (entityType === 'AccessRequest') {
    photo = null;
    name = caregiverName;
    subtext = caregiverEmail;
  } else {
    photo = patientPhoto;
    name = patientName;
    subtext = carePlanTitle;
  }

  return (
    <a onClick={onClick} className="item-pill item-pill--stretch item-pill--flat invitation">
      <div className="invitation__profile">
        <div className="invitation__photo">
          <Avatar src={photo} fullName={name} size={2.5} />
        </div>
        <TextGroup text={name} subtext={subtext} />
      </div>

      <Details
        carePlanTitle={carePlanTitle}
        patientName={patientName}
        placeName={placeName}
        entityType={entityType}
        caregiverName={caregiverName}
        caregiverEmail={caregiverEmail}
        submitted={submitted}
        resolvedAt={resolvedAt}
      />
      <Status status={status} />
    </a>
  );
};

Signup.propTypes = {
  carePlanTitle: PropTypes.string,
  caregiverEmail: PropTypes.string,
  caregiverName: PropTypes.string,
  entityType: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  patientName: PropTypes.string.isRequired,
  patientPhoto: PropTypes.string,
  placeName: PropTypes.string.isRequired,
  resolvedAt: PropTypes.string,
  status: PropTypes.string.isRequired,
  submitted: PropTypes.string,
};

Signup.defaultProps = {
  carePlanTitle: '',
  caregiverEmail: '',
  caregiverName: '',
  resolvedAt: '',
  submitted: '',
};

export default Signup;
