import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import FormSuccessModal from './FormSuccessModal';

import TaskList from './TaskList';
import EventList from './EventList';

const WeeklyList = ({ carePlanId }) => {
  const [callbackStatus, setCallbackStatus] = useState(null);

  useEffect(() => {
    const queryParams = window.location.search;
    const params = new URLSearchParams(queryParams);
    if (params) {
      setCallbackStatus(params.get('status'));
    }
  }, []);

  return (
    <div>
      <TaskList carePlanId={carePlanId} />
      <EventList carePlanId={carePlanId} />

      {callbackStatus && callbackStatus === 'success' && <FormSuccessModal carePlanId={carePlanId} />}
    </div>
  );
};

WeeklyList.propTypes = {
  assignmentOptions: PropTypes.array,
  assignments: PropTypes.array,
  canEditResources: PropTypes.bool,
  carePlanId: PropTypes.string.isRequired,
};

WeeklyList.defaultProps = {
  assignmentOptions: [],
  assignments: [],
  canEditResources: false,
};

export default WeeklyList;
