import calculateFill from './calculateFill';

const CURRENT_DAY_OF_WEEK = new Date().getDay();
const dayOfWeekMap = {
  Sun: 0,
  Mon: 1,
  Tue: 2,
  Wed: 3,
  Thu: 4,
  Fri: 5,
  Sat: 6,
};

const getRenderData = (forms) =>
  forms.reduce((acc, form) => {
    const { name, data } = form;

    const timeOfDays = ['morning', 'afternoon', 'evening'];
    const filteredResponses = data.reduce(
      (acc, { day, is_future: isFuture, morning, afternoon, evening }) => {
        if (!isFuture || CURRENT_DAY_OF_WEEK <= dayOfWeekMap[day]) {
          if (morning !== null) {
            acc = [...acc, morning];
          }
          if (afternoon !== null) {
            acc = [...acc, afternoon];
          }
          if (evening !== null) {
            acc = [...acc, evening];
          }
        }

        return acc;
      },
      []
    );

    const dataLength = data.length - 1;
    const transformedResponses = data.reduce((memo, resp, dataIndex) => {
      timeOfDays.forEach((tod) => {
        let displayDot = false;
        const validResponse = resp[tod] !== null;

        if (validResponse && dataIndex === 0) {
          if (
            (tod === 'morning' && resp['afternoon'] === null) ||
            (tod === 'afternoon' && resp['morning'] === null && resp['evening'] === null) ||
            (tod === 'evening' && resp['afternoon'] === null && data[dataIndex + 1]['morning'] === null)
          )
            displayDot = true;
        } else if (dataIndex > 0) {
          if (
            validResponse &&
            tod === 'morning' &&
            data[dataIndex - 1]['evening'] === null &&
            data[dataIndex]['afternoon'] === null
          ) {
            displayDot = true;
          } else if (
            validResponse &&
            tod === 'afternoon' &&
            data[dataIndex]['morning'] === null &&
            data[dataIndex]['evening'] === null
          ) {
            displayDot = true;
          } else if (validResponse && tod === 'evening' && data[dataIndex]['afternoon'] === null) {
            if (dataIndex === dataLength && data[dataIndex]['afternoon'] === null) {
              displayDot = true;
            } else if (data[dataIndex + 1]['morning'] === null) {
              displayDot = true;
            }
          }
        }

        memo = [
          ...memo,
          {
            day: `${resp.day}-${tod}`,
            value: resp[tod],
            isFuture: resp.is_future,
            displayDot,
          },
        ];
      });

      return memo;
    }, []);

    return [
      ...acc,
      {
        ...form,
        data: transformedResponses,
        overall: filteredResponses.length
          ? Math.round(
              filteredResponses.reduce((prev, curr) => prev + (curr || 0), 0) / filteredResponses.length
            )
          : 0,
        maxValue: filteredResponses.length ? Math.max(...filteredResponses) : 0,
        overtimeFill: calculateFill(name, 'overtime'),
        overallFill: calculateFill(name, 'overall'),
      },
    ];
  }, []);

export default getRenderData;
