import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import Modal from 'Components/Base/Modal'

import { AccessRequestRoleSelect, AccessRequestConsent } from './'

const i18nOpts = { scope: 'team.acceptance_modal' }

class AcceptanceModal extends PureComponent {
  constructor(props) {
    super(props)

    this.handleApprove = this.handleApprove.bind(this)
    this.handleDecline = this.handleDecline.bind(this)
    this.handleNext = this.handleNext.bind(this)
    this.handleBack = this.handleBack.bind(this)
    this.renderConsent = this.renderConsent.bind(this)
    this.renderRoleSelect = this.renderRoleSelect.bind(this)
  }

  handleApprove() {
    this.props.onApprove(this.props.accessRequest.id, this.props.role, this.props.consent)
  }

  handleDecline() {
    this.props.onDecline(this.props.accessRequest.id)
  }

  handleNext() {
    if (this.props.consent) {
      this.props.onToggleConsent()
    }

    this.props.setModalView('pcg_consent')
  }

  handleBack() {
    if (!this.props.consent) {
      this.props.onToggleConsent()
    }

    this.props.setModalView('role_select')
  }

  get viewProps() {
    return {
      accessRequest: this.props.accessRequest,
      isSubmitting: this.props.isSubmitting,
      onApprove: this.handleApprove,
      onClose: this.props.onClose,
      onDecline: this.handleDecline,
      role: this.props.role,
    }
  }

  renderRoleSelect() {
    return (
      <AccessRequestRoleSelect
        {...this.viewProps}
        isPlaceUser={this.props.isPlaceUser}
        onChange={this.props.onChange}
        onNext={this.handleNext}
      />
    )
  }

  renderConsent() {
    return (
      <AccessRequestConsent
        {...this.viewProps}
        consent={this.props.consent}
        onBack={this.handleBack}
        onToggleConsent={this.props.onToggleConsent}
      />
    )
  }

  render() {
    return (
      <Modal title={t('title', i18nOpts)} trigger="accessRequests">
        {this.props.view === 'role_select' ? this.renderRoleSelect() : this.renderConsent()}
      </Modal>
    )
  }
}

AcceptanceModal.propTypes = {
  accessRequest: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    photo: PropTypes.string,
    preferredPhoneNumber: PropTypes.shape({
      number: PropTypes.string.isRequired,
    }),
    relationship: PropTypes.string.isRequired,
  }),
  consent: PropTypes.bool,
  isPlaceUser: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onApprove: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  onToggleConsent: PropTypes.func.isRequired,
  role: PropTypes.string,
  setModalView: PropTypes.func.isRequired,
  view: PropTypes.string,
}

AcceptanceModal.defaultProps = {
  accessRequest: null,
  consent: false,
  isPlaceUser: false,
  isSubmitting: false,
  role: '',
  view: 'role_select',
}

export default AcceptanceModal
