import React, { useState } from 'react';
import { t } from 'i18n';
import CheckInScoreSection from '../CheckInScoreSection';
import _ from 'lodash';

const i18nOpts = { scope: 'patient.check_in_result' };

const CheckInResponseHistory = ({ checkIns, question, lastCheckin, skipped, dataIndex }) => {
  const INCREMENT_STEP = 10;
  const [renderLimit, setRenderLimit] = useState(0);
  const incrementRenderLimit = () => setRenderLimit(limit => limit + INCREMENT_STEP);

  const hasMoreToRender = checkIns.length - 1 > renderLimit;

  return (
    <section className="checkin-results__details">
      <p className='checkin-results__label'>{t('last_checkin', i18nOpts)}</p>
      <section className='checkin-results__score-wrapper'>
        <div className='checkin-results__score-container'>
          <p className="checkin-results__question text-bold text-grey-darkest">{question.text}</p>
          <CheckInScoreSection
            type={question.questionType}
            submittedData={lastCheckin.submittedData[dataIndex]}
            skipped={skipped}
          />
        </div>
        <p className="checkin-results__score__label">
          {lastCheckin.submittedData[dataIndex].date}
          {t('submittedBy', { ...i18nOpts, name: lastCheckin.submittedData[dataIndex].by })}
        </p>
      </section>

      { renderLimit > 0 &&
        <>
          <p className='checkin-results__label'>{t('previous_checkins', i18nOpts)}</p>

          { _.reverse(checkIns.slice(0, -1))
              .slice(0, renderLimit)
              .map((checkIn) => (
              <section className='checkin-results__score-wrapper' key={`past-checkIn-${checkIn.id}`}>
                <div className='checkin-results__score-container'>
                  <CheckInScoreSection
                    type={question.questionType}
                    submittedData={checkIn.submittedData[dataIndex]}
                    skipped={!checkIn.submittedData[dataIndex]?.checkInQuestionOptionId}
                  />
                </div>
                <p className="checkin-results__score__label">
                  {checkIn.submittedData[dataIndex].date}
                  {t('submittedBy', { ...i18nOpts, name: checkIn.submittedData[dataIndex].by })}
                </p>
              </section>
            ))
          }
        </>
      }


      { hasMoreToRender &&
        <a
          onClick={() => incrementRenderLimit()}
          onKeyDown={(evt) => {
            if (evt.key === 'Enter' || evt.key === ' ') incrementRenderLimit();
          }}
          className='checkin-results__show-more-link'
          tabIndex='0'
        >
          {t('show_more_submissions', i18nOpts)}
        </a>
      }
    </section>
  )
};

export default CheckInResponseHistory;
