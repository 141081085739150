import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { Button, Icon, ModalFooter } from 'Components/Base';
import Checkbox from 'Components/Forms/Checkbox';
import Submit from 'Components/Forms/Submit';
import InputGroup from 'Components/Forms/InputGroup';
import Option from 'Components/Forms/Option';

const i18nOpts = { scope: 'team.modal.email' };
const i18nOptsModal = { scope: 'team.modal' };
const i18nOptsErrors = { scope: 'errors.email' };

const validationSchema = (persistedMembers, members, currentId) => {
  const persistedEmails = persistedMembers
    .filter((member) => !!member.pendingOwner || !!member.owner)
    .map((member) => (member.pendingOwner || member.owner).email);

  const pendingEmails = members.map((member) => member.id !== currentId && member.email);
  const allEmails = [...pendingEmails, ...persistedEmails];

  return Yup.object({
    email: Yup.string()
      .email(t('invalid', i18nOptsErrors))
      .required(t('blank', i18nOptsErrors))
      .notOneOf(allEmails, t('already_exists', i18nOptsErrors)),
  });
};

const EmailModal = ({
  teamType,
  email,
  relationship,
  onSubmit,
  persistedMembers,
  members,
  currentId,
  relationshipOptions,
  permission,
  onInviteTypeSet,
  onCancel,
  onPermission,
  name,
  isPatient,
}) => (
  <Fragment>
    <h2 className="invite-code-modal__title">
      {t(`${teamType}_team_invite_title`, { ...i18nOptsModal, inviteType: 'email' })}
    </h2>

    {teamType === 'support' && (
      <h3 className="invite-code-modal__header-subtitle">
        {t('support_team_invite_subtitle', i18nOptsModal)}
      </h3>
    )}

    <Formik
      initialValues={{ email, relationship }}
      onSubmit={onSubmit}
      validationSchema={validationSchema(persistedMembers, members, currentId)}
    >
      {({ values, isSubmitting, touched, errors, getFieldProps }) => (
        <Form>
          <InputGroup
            label={t('email_label', i18nOpts)}
            name="email"
            required
            component="input"
            errors={touched.email && errors}
            className="input team-invitations-modal__input input--fixed-hint"
            placeholder={t('email_placeholder', i18nOpts)}
            hint={t('email_hint', i18nOpts)}
            {...getFieldProps('email')}
          />

          {teamType === 'support' && (
            <InputGroup
              label={
                isPatient
                  ? t('relationship_patient_label', i18nOpts)
                  : t('relationship_label', { ...i18nOpts, name })
              }
              className="input team-invitations-modal__input"
              name="relationship"
              component="select"
              {...getFieldProps('relationship')}
            >
              <option key="blank" value="">
                {t('empty_relationship', i18nOpts)}
              </option>
              {relationshipOptions.map((option) => (
                <Option key={option} option={option} scope="relationship" />
              ))}
            </InputGroup>
          )}

          {teamType === 'health' && (
            <Checkbox
              checked={permission}
              name={'permission'}
              text={t('health_team_declaration', { ...i18nOptsModal, name })}
              errors={errors}
              onChange={onPermission}
            />
          )}

          <div className="invite-code-modal__button-footer">
            <Icon name="or" className="icon_or invite-code-modal__or-icon" />
          </div>

          <div className="invite-code-modal__invite-type">
            <Button
              data-test="add"
              className="btn btn--link invite-code-modal__invite-type-change-button"
              onClick={onInviteTypeSet}
            >
              {t('invite_by_code', { ...i18nOptsModal })}
            </Button>
          </div>

          <ModalFooter
            renderRight={() => (
              <Fragment>
                <Button
                  data-test="cancel"
                  text={t('cancel', i18nOpts)}
                  onClick={onCancel}
                  className="btn btn--secondary"
                />
                <Submit
                  value={t('next', i18nOpts)}
                  disabled={
                    !values.email.length || isSubmitting || (teamType === 'health' ? !permission : false)
                  }
                />
              </Fragment>
            )}
          />
        </Form>
      )}
    </Formik>
  </Fragment>
);

EmailModal.propTypes = {
  currentId: PropTypes.string,
  email: PropTypes.string,
  isPatient: PropTypes.bool,
  members: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onInviteTypeSet: PropTypes.func.isRequired,
  onPermission: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  permission: PropTypes.bool.isRequired,
  persistedMembers: PropTypes.array.isRequired,
  relationship: PropTypes.string,
  relationshipOptions: PropTypes.array.isRequired,
  teamType: PropTypes.string,
};

EmailModal.defaultProps = {
  currentId: null,
  email: '',
  isPatient: false,
  relationship: '',
  teamType: '',
};

export default EmailModal;
