import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Activity = ({ onClick, children, footer }) => {
  const classes = classNames('card', 'card--flat', { 'card--clickable': onClick })
  return (
    <div onClick={onClick} onKeyDown={onClick} tabIndex={onClick ? '0' : null} className={classes}>
      <div className="grid">
        <div className="row row--top-aligned">{children}</div>
        {footer}
      </div>
    </div>
  )
}

Activity.propTypes = {
  children: PropTypes.node,
  footer: PropTypes.node,
  onClick: PropTypes.func,
}

Activity.defaultProps = {
  children: null,
  footer: null,
  onClick: null,
}

export default Activity
