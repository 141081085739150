import { t } from 'i18n'
const i18nOpts = { scope: 'care_plan_creators.repeated_options' }
export const frequencyTypes = [
  { id: '', name: t('blank', i18nOpts) },
  { id: 'once', name: t('once', i18nOpts) },
  { id: 'scheduled', name: t('scheduled', i18nOpts) },
  { id: 'recurring', name: t('recurring', i18nOpts) },
]
export const frequencies = [
  { id: 'daily', name: t('daily', i18nOpts) },
  { id: 'weekly', name: t('weekly', i18nOpts) },
  { id: 'monthly', name: t('monthly', i18nOpts) },
]

export const endRepeats = [
  { id: 'never', name: t('end_never', i18nOpts) },
  { id: 'times', name: t('after', i18nOpts) },
  { id: 'on_a_day', name: t('end_on_a_day', i18nOpts) },
]

export const endRepeatsForTemplate = [
  { id: 'never', name: t('end_never', i18nOpts) },
  { id: 'times', name: t('after', i18nOpts) },
]

export const frequencyTypesAppt = [
  { id: 'scheduled', name: t('scheduled', i18nOpts) },
  { id: 'recurring', name: t('recurring', i18nOpts) },
]
