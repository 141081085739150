import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { ItemPill, ItemPillText } from 'Components/Base/ItemPill'
import { Button, DeferredImage, IconButton, ModalFooter } from 'Components/Base'
import Checkbox from 'Components/Forms/Checkbox'
import Submit from 'Components/Forms/Submit'

import banner from 'invite_health_teams_confirmation.svg'
import location from 'location.png'

const i18nOpts = { scope: 'care_plans.health_team_banner.consent_form' }

const ConsentForm = ({ permission, places, errors, onBack, onChange, onClose, onSubmit }) => (
  <Fragment>
    <div className="ReactModal__ContentBanner ReactModal__ContentBanner--simple">
      <div className="ReactModal__ContentBanner-text">{t('header', i18nOpts)}</div>

      <img src={banner} className="ReactModal__ContentBanner-image" />
    </div>

    <div>
      {places.map(({ id, imageEntitlementToken, name, address, phoneNumber }) => (
        <ItemPill key={id} stretch flat>
          <DeferredImage
            alt={name}
            className="new-contact__avatar avatar"
            fallbackImage={location}
            imageEntitlementToken={imageEntitlementToken}
          />

          <ItemPillText text={name} subtext={address} />
          <div>{phoneNumber}</div>
        </ItemPill>
      ))}
    </div>

    <br />

    <Checkbox
      checked={permission}
      name="permission"
      onChange={onChange}
      text={t('permission', i18nOpts)}
      errors={errors}
      large
    />

    <form noValidate onSubmit={onSubmit}>
      <ModalFooter
        renderRight={() => (
          <Fragment>
            <Button className="btn btn--secondary" text={t('cancel', i18nOpts)} onClick={onClose} />
            <Submit value={t('invite', i18nOpts)} disabled={!permission} />
          </Fragment>
        )}
        renderLeft={() => (
          <IconButton text={t('back', i18nOpts)} onClick={onBack} iconName="chevron-left" isSecondary />
        )}
      />
    </form>
  </Fragment>
)

ConsentForm.propTypes = {
  errors: PropTypes.object,
  onBack: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  permission: PropTypes.bool,
  places: PropTypes.array,
}

ConsentForm.defaultProps = {
  errors: {},
  permission: false,
  places: [],
}

export default ConsentForm
