import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import Card from 'Components/Base/Card';
import DataVisualization from './DataVisualization';
import CGMPeriodTabs from './CGMPeriodTabs';
import fetchAllGlucoseData from './fetchAllGlucoseData';
import { CarePlanContext } from 'Components/Dashboard/Dashboard';
import CopyButton from '../Buttons/CopyButton';
import buildTextFromBlocks from '../buildTextFromBlocks';

const i18nOpts = { scope: 'dashboard' };
const i18nGlucoseAverageOpts = { scope: 'dashboard.glucose_average' };
const i18nGlucoseRangeOpts = { scope: 'dashboard.glucose_range' };

const BloodGlucoseCard = ({
  statisticsData,
  statisticsStatus,
  initialMonitorData,
  initialMonitorStatus,
  error,
  monitorType,
}) => {
  const { carePlanUuid, userDetails } = useContext(CarePlanContext);

  const [selectedTab, setSelectedTab] = useState('1day');
  const [bloodGlucoseMonitorStatus, setBloodGlucoseMonitorStatus] = useState(initialMonitorStatus);
  const [bloodGlucoseMonitorData, setBloodGlucoseMonitorData] = useState(initialMonitorData);
  const [isChartDataLoaded, setIsChartDataLoaded] = useState(false);

  const handleButtonClick = () => {
    const text = buildTextFromBlocks('GLUCOSE_LEVELS', {
      statsData: statisticsData,
      tab: selectedTab,
      userDetails,
    });
    // To test this in local, check readme
    navigator.clipboard?.writeText(text);
  };

  useEffect(() => {
    if (!error) {
      fetchAllGlucoseData(
        carePlanUuid,
        setBloodGlucoseMonitorData,
        setBloodGlucoseMonitorStatus,
        setIsChartDataLoaded
      )(); // note immediate invocation of returned function
    }
  }, []);

  const isGlucometer = monitorType === 'mdi_glucometer' || monitorType === 'blood_glucose_meter';

  return (
    <Card title={t('glucose_levels', i18nOpts)} id="glucose_levels" cardHeaderClass="card__header__dashboard">
      {!isGlucometer && (
        <div className="insulin-dosing__copy-dashboard">
          <CopyButton onCopyButtonClick={handleButtonClick} />
        </div>
      )}

      <CGMPeriodTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      <DataVisualization
        bloodGlucoseMonitorStatus={bloodGlucoseMonitorStatus}
        bloodGlucoseMonitorData={bloodGlucoseMonitorData}
        statisticsData={statisticsData}
        statisticsStatus={statisticsStatus}
        isChartDataLoaded={isChartDataLoaded}
        timeRange={selectedTab}
        monitorType={monitorType}
        error={error}
      />
    </Card>
  );
};

BloodGlucoseCard.propTypes = {
  initialMonitorData: PropTypes.object,
  initialMonitorStatus: PropTypes.string,
  statisticsData: PropTypes.shape({
    cgmActivePercentage: PropTypes.object,
    glucoseAverages: PropTypes.array,
    glucoseRange: PropTypes.array,
  }),
  statisticsStatus: PropTypes.string,
  error: PropTypes.string,
};

const defaultStatisticsData = {
  cgmActivePercentage: {
    status: 'ERROR',
    number: 0,
    days_active: 0,
  },
  glucoseAverages: [
    {
      header: t('average_glucose', i18nGlucoseAverageOpts),
    },
    {
      header: t('gmi', i18nGlucoseAverageOpts),
      tooltip: t('gmi_tooltip', i18nGlucoseAverageOpts),
    },
    {
      header: t('cv', i18nGlucoseAverageOpts),
      tooltip: t('cv_tooltip', i18nGlucoseAverageOpts),
    },
    {
      header: t('sd', i18nGlucoseAverageOpts),
    },
  ],
  glucoseRange: [
    {
      header: t('low', i18nGlucoseRangeOpts),
    },
    {
      header: t('in_range', i18nGlucoseRangeOpts),
    },
    {
      header: t('high', i18nGlucoseRangeOpts),
    },
  ],
};

BloodGlucoseCard.defaultProps = {
  initialMonitorData: {
    '1day': [],
    '7days': [],
    '14days': [],
    '30days': [],
    '90days': [],
  },
  initialMonitorStatus: 'FAILURE',
  statisticsData: {
    '1day': defaultStatisticsData,
    '7days': defaultStatisticsData,
    '14days': defaultStatisticsData,
    '30days': defaultStatisticsData,
    '90days': defaultStatisticsData,
  },
  statisticsStatus: 'FAILURE',
  error: '',
};

export default BloodGlucoseCard;
