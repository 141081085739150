import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from 'Components/Base'

const ItemPillLargeAction = ({ iconName, label, onClick }) => (
  <div className="item-pill__action item-pill__action--large" onClick={onClick} tabIndex="0">
    <div className="btn btn--action">
      <Icon name={iconName} />
    </div>
    <small className="text-brand">{label}</small>
  </div>
)

ItemPillLargeAction.propTypes = {
  iconName: PropTypes.string.isRequired,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

ItemPillLargeAction.defaultProps = {
  label: '',
}

export default ItemPillLargeAction
