import React, { Component } from 'react'
import PropTypes from 'prop-types'
import 'moment-timezone'
import moment from 'ct-moment'
import _ from 'lodash'

import TaskForm from './TaskForm'
import { roundTimeUp } from 'Utilities/time'

class TaskFormContainer extends Component {
  constructor(props) {
    super(props)

    let end
    // until supersedes count
    if (props.count) {
      end = 'count'
    }
    if (props.until) {
      end = 'date'
    }

    const { countryCode, region, city, postalCode, address, extendedAddress } = props.address;

    this.state = {
      assignments: props.assignments,
      dueAt: props.dueAt,
      text: props.text,
      selectedResources: props.taskSelectedResources,
      frequency: props.frequency,
      interval: props.interval,
      count: props.count,
      until: props.until,
      modalResourcesIsOpen: false,
      modalVolunteerIsOpen: false,
      end,
      helpRequest: this.helpRequestState,
      helpRequested: this.isEditHelpRequest,
      countryCode,
      region,
      city,
      postalCode,
      address,
      extendedAddress,
    }

    this.filterResources = this.filterResources.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.showDateTime = this.showDateTime.bind(this)
    this.hideDateTime = this.hideDateTime.bind(this)
    this.handleDeleteResource = this.handleDeleteResource.bind(this)
    this.handleChangeResources = this.handleChangeResources.bind(this)
    this.handleOpenResourcesModal = this.handleOpenResourcesModal.bind(this)
    this.handleOpenVolunteerModal = this.handleOpenVolunteerModal.bind(this)
    this.handleCloseResourcesModal = this.handleCloseResourcesModal.bind(this)
    this.handleCloseVolunteerModal = this.handleCloseVolunteerModal.bind(this)
    this.handleHelpRequestSubmit = this.handleHelpRequestSubmit.bind(this)
  }

  get helpRequestState() {
    const { patientInfo, currentTime, timeZone, helpRequest } = this.props
    const emptyAddress = {
      address: '',
      city: '',
      region: '',
      postalCode: '',
      countryCode: '',
    }

    if (helpRequest && !helpRequest.addressFrom) {
      helpRequest.addressFrom = emptyAddress
    }
    return (
      helpRequest || {
        stringNameTo: '',
        addressFrom: emptyAddress,
        stringNameFrom: '',
        addressTo: (patientInfo && patientInfo.addressTo) || emptyAddress,
        phoneNumberRecipient: (patientInfo && patientInfo.phoneNumber) || { number: '' },
        datetimeDueAt: roundTimeUp(5, currentTime.tz(timeZone)).format(),
        stringMoreInfo: '',
      }
    )
  }

  get isEditHelpRequest() {
    return !!(this.props.helpRequest || false)
  }

  handleChange({ target: { name, value } }) {
    this.setState({ [name]: value })
  }

  handleSubmit(event) {
    event.preventDefault()

    switch (this.state.frequency) {
      case 'daily':
      case 'weekly':
      case 'monthly':
      case 'yearly':
        return this.props.onRepeatedTaskSubmit(this.repeatedTaskParams)
      default:
        return this.props.onTaskSubmit(this.taskParams)
    }
  }

  get availableResources() {
    const resources = _.flatten(Object.values(this.props.resources))
    const allResources = _.flattenDeep([
      this.props.taskSelectedResources,
      this.state.selectedResources,
      this.props.adHocResources,
      resources,
    ])
    return _.uniqBy(allResources, (allResources) => {
      return allResources.id.valueOf()
    })
  }

  filterResources() {
    return this.availableResources.map((r) => {
      const selectedItem = this.state.selectedResources.find((item) => item.id === r.id)
      return { ...r, selected: selectedItem ? true : false }
    })
  }

  get taskParams() {
    const { text, dueAt, assignments, countryCode, region, city, postalCode, address, extendedAddress } = this.state

    const task = {
      text,
      assignments: assignments ? assignments.map((a) => ({ id: a.value })) : [],
      resources: this.filterResources(),
      address_attributes: {
        countryCode,
        region,
        city,
        postalCode,
        address,
        extendedAddress,
      },
    }

    if (this.props.kind === 'generic') {
      task.due_at = dueAt
    }

    if (this.state.helpRequested) {
      task.helpRequest = this.state.helpRequest
    }

    return { task }
  }

  get repeatedTaskParams() {
    const { text, dueAt, assignments, frequency, interval, end, until, count } = this.state

    const repeatedTask = {
      assignments: assignments ? assignments.map((a) => ({ id: a.value })) : [],
      resources: this.filterResources(),
      due_at: dueAt,
      text,
      frequency,
      interval,
    }

    if (end === 'date') {
      repeatedTask.until = until
    } else if (end === 'count') {
      repeatedTask.count = count
    }

    return { repeated_task: repeatedTask }
  }

  showDateTime() {
    const currentTime = this.props.currentTime.tz(this.props.timeZone)
    this.setState({ dueAt: roundTimeUp(5, currentTime).format() })
  }

  hideDateTime() {
    this.setState(() => ({
      dueAt: null,
      frequency: 'none',
      interval: '1',
      end: 'never',
      count: '1',
      until: '',
    }))
  }

  handleOpenResourcesModal() {
    this.setState({ modalResourcesIsOpen: true })
  }

  handleOpenVolunteerModal() {
    this.setState({ modalVolunteerIsOpen: true })
  }

  handleCloseResourcesModal() {
    this.setState({ modalResourcesIsOpen: false })
  }

  handleCloseVolunteerModal() {
    this.setState({ modalVolunteerIsOpen: false })
  }

  handleHelpRequestSubmit(params) {
    this.setState({
      helpRequested: true,
      modalVolunteerIsOpen: false,
      helpRequest: {
        ...params,
        phoneNumberRecipient: { number: params.number },
      },
    })
  }

  handleDeleteResource(id) {
    this.setState((state) => ({
      selectedResources: _.filter(state.selectedResources, (resource) => resource.id !== id),
    }))
  }

  handleChangeResources(selectedStates) {
    const selectedResources = _.filter(this.availableResources, (resource) => selectedStates[resource.id])

    this.setState({ selectedResources, modalResourcesIsOpen: false })
  }

  render() {
    const wrappedProps = _.assign({}, this.props, this.state)
    const currentTime = this.props.currentTime.tz(this.props.timeZone)

    return (
      <TaskForm
        currentTime={roundTimeUp(5, currentTime).format()}
        onCloseResourcesModal={this.handleCloseResourcesModal}
        onCloseVolunteerModal={this.handleCloseVolunteerModal}
        onOpenResourcesModal={this.handleOpenResourcesModal}
        onOpenVolunteerModal={this.handleOpenVolunteerModal}
        onHelpRequestSubmit={this.handleHelpRequestSubmit}
        hideDateTime={this.hideDateTime}
        onCancel={this.props.onCancel}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
        showDateTime={this.showDateTime}
        onDeleteResource={(this.props.canDeleteResources && this.handleDeleteResource) || null}
        onSelectResource={this.handleChangeResources}
        {...wrappedProps}
      />
    )
  }
}

const resourceGroupShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  kind: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
})

TaskFormContainer.propTypes = {
  adHocResources: PropTypes.arrayOf(resourceGroupShape),
  allResources: PropTypes.array,
  allowRepeat: PropTypes.bool,
  assignmentOptions: PropTypes.array,
  assignments: PropTypes.array,
  canDeleteResources: PropTypes.bool,
  count: PropTypes.string,
  currentTime: PropTypes.object,
  dueAt: PropTypes.string,
  ensureDatePickerVisibility: PropTypes.bool,
  errors: PropTypes.object,
  frequency: PropTypes.string,
  helpRequest: PropTypes.object,
  interval: PropTypes.string,
  isSubmitting: PropTypes.bool,
  kind: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  patientInfo: PropTypes.shape({
    addressTo: PropTypes.shape({
      address: PropTypes.string,
      city: PropTypes.string,
      region: PropTypes.string,
      postalCode: PropTypes.string,
      countryCode: PropTypes.string,
    }),
    phoneNumber: PropTypes.shape({ number: PropTypes.string }),
  }),
  resources: PropTypes.shape({
    information: PropTypes.arrayOf(resourceGroupShape),
    service: PropTypes.arrayOf(resourceGroupShape),
    practitioner: PropTypes.arrayOf(resourceGroupShape),
    people: PropTypes.arrayOf(resourceGroupShape),
    other: PropTypes.arrayOf(resourceGroupShape),
  }),
  taskSelectedResources: PropTypes.arrayOf(resourceGroupShape),
  text: PropTypes.string,
  textIsReadonly: PropTypes.bool,
  timeZone: PropTypes.string.isRequired,
  until: PropTypes.string,
  volunteersEnabled: PropTypes.bool,
}

TaskFormContainer.defaultProps = {
  adHocResources: [],
  allResources: [],
  allowRepeat: false,
  assignmentOptions: [],
  assignments: [],
  canDeleteResources: false,
  count: '',
  currentTime: moment(),
  dueAt: '',
  ensureDatePickerVisibility: false,
  errors: {},
  frequency: 'none',
  helpRequest: null,
  interval: '',
  isSubmitting: false,
  kind: '',
  patientInfo: {
    addressTo: {
      address: '',
      city: '',
      region: '',
      postalCode: '',
      countryCode: '',
    },
    phoneNumber: { number: '' },
  },
  resources: {
    information: [],
    service: [],
    practitioner: [],
    people: [],
    other: [],
  },
  taskSelectedResources: [],
  text: '',
  textIsReadonly: false,
  until: '',
  volunteersEnabled: false,
  address: {},
}

export default TaskFormContainer
