import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import Button from '../Base/Button'
import Icon from '../Base/Icon'

import banner from 'health_banner.svg'

const i18nOpts = { scope: 'welcome_banner.patient_banner' }

const PatientBanner = ({ close }) => (
  <div className="banner">
    <img src={banner} className="banner__image" />

    <div className="banner__text">
      <div className="banner__header">
        <h1>{t('header', i18nOpts)}</h1>
        <div onClick={close}>
          <Icon name="close" />
        </div>
      </div>

      <p className="banner__details">{t('details', i18nOpts)}</p>
      <Button text={t('button', i18nOpts)} onClick={close} />
    </div>
  </div>
)

PatientBanner.propTypes = {
  close: PropTypes.func.isRequired,
}

export default PatientBanner
