import Api from './Api'

class Comment extends Api {
  constructor(carePlanUuid, parentId) {
    super()

    this.carePlanUuid = carePlanUuid
    this.parentId = parentId
  }

  create(text) {
    return super.create({ comment: { text } })
  }

  get url() {
    return `/care_plans/${this.carePlanUuid}/activities/${this.parentId}/comment`
  }
}

export default Comment
