import { REQUEST_ALL_CONTACTS, RECEIVE_ALL_CONTACTS } from 'Actions/inbox/sideNav/newContacts'

import {
  REQUEST_CHANNEL_CONTACTS,
  TOGGLE_CHANNEL_MEMBER,
  TOGGLE_CHANNEL_MEMBERS,
} from 'Actions/inbox/sideNav/channel'

const initialState = { contacts: [], isFetching: false }

const toggleMembers = (id, contact) =>
  contact.id === id ? { ...contact, isSelected: !contact.isSelected } : contact

const allNewMembers = (members, selected) => {
  return members.map((member) => {
    member.isSelected = !selected
    return member
  })
}

const newContacts = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ALL_CONTACTS:
    case REQUEST_CHANNEL_CONTACTS:
      return {
        ...state,
        isFetching: true,
      }
    case RECEIVE_ALL_CONTACTS:
      return {
        ...state,
        contacts: action.contacts,
        isFetching: false,
      }
    case TOGGLE_CHANNEL_MEMBER:
      return {
        ...state,
        contacts: state.contacts.map(toggleMembers.bind(null, action.member.id)),
      }
    case TOGGLE_CHANNEL_MEMBERS:
      return {
        ...state,
        contacts: allNewMembers(state.contacts, action.selected),
      }
    default:
      return state
  }
}

export default newContacts
