import CarePlansApi from 'Api/Inbox/CarePlan';

import { REQUEST_ALL_CONTACTS, RECEIVE_ALL_CONTACTS } from './newContacts';

export const REQUEST_CARE_PLANS = 'REQUEST_CARE_PLANS';
export const RECEIVE_CARE_PLANS = 'RECEIVE_CARE_PLANS';
export const SHOW_CARE_PLANS = 'SHOW_CARE_PLANS';

export const fetchCarePlans = () => {
  return (dispatch) => {
    dispatch({ type: REQUEST_CARE_PLANS });

    return new CarePlansApi()
      .index()
      .then((res) => res.json())
      .then(({ care_plans }) => {
        const carePlans = care_plans.map(
          ({ id, title, patient_photo: patientPhoto, patient_name: patientName }) => ({
            id,
            title,
            patientPhoto,
            patientName,
          })
        );
        dispatch({
          type: RECEIVE_CARE_PLANS,
          carePlans,
        });
      });
  };
};

export const fetchCarePlanContacts = (id) => {
  return (dispatch) => {
    dispatch({ type: REQUEST_ALL_CONTACTS });

    return new CarePlansApi(id)
      .carePlanContacts()
      .then((res) => res.json())
      .then(({ contacts }) =>
        dispatch({
          type: RECEIVE_ALL_CONTACTS,
          contacts,
        })
      );
  };
};

export const showCarePlans = () => ({ type: SHOW_CARE_PLANS });
