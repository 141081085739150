import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import ActivityHeader from './ActivityHeader'
import Assignments from './Assignments'
import Comments from './Comments'
import LinkDecorator from 'Components/Base/LinkDecorator'

const i18nOpts = { scope: 'activity_feed.appointment' }

const Appointment = ({
  id,
  createdAt,
  link,
  author,
  text,
  type,
  comments,
  practitioner,
  dueAt,
  assignments,
  carePlanUuid,
  address: { address, city },
}) => (
  <div className="activity">
    <ActivityHeader createdAt={createdAt} type={type} link={link} {...author} />

    <div className="activity__body">
      <dd>
        {t('when', i18nOpts)}
        <b>{dueAt}</b>
      </dd>

      {practitioner && (
        <dd>
          {t('with', i18nOpts)}
          <b>{practitioner}</b>
        </dd>
      )}

      {address && (
        <dd>
          {t('where', i18nOpts)}
          <b>{[address, city].filter((p) => p).join(', ')}</b>
        </dd>
      )}
      <p>
        <LinkDecorator>{text}</LinkDecorator>
      </p>

      <Assignments assignments={assignments} />
    </div>

    <hr />

    <Comments comments={comments} parentId={id} carePlanUuid={carePlanUuid} />
  </div>
)

Appointment.propTypes = {
  address: PropTypes.shape({
    address: PropTypes.string,
    city: PropTypes.string,
  }),
  assignments: PropTypes.array,
  author: PropTypes.object.isRequired,
  carePlanUuid: PropTypes.string.isRequired,
  comments: PropTypes.array,
  createdAt: PropTypes.string.isRequired,
  dueAt: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired,
  practitioner: PropTypes.string,
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

Appointment.defaultProps = {
  practitioner: '',
  comments: [],
  assignments: [],
  address: {
    address: '',
    city: '',
  },
}

export default Appointment
