import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { t } from 'i18n'

import { Icon } from 'Components/Base'
import { fetchChannels } from 'Actions/inbox/sideNav/channel';
import CreateButton from './CreateButton';

const i18nOpts = { scope: 'inbox.side_nav.contacts.header' }

const ChannelHeader = ({ fetchChannels }) => {
  useEffect(() => {
    fetchChannels()
  }, [])

  return (
    <div className="inbox-menu__header">
      <Icon name="users" className={`icon icon--channels`} />
      <span className="inbox-menu__header-text text-medium">
        {t("users", i18nOpts)}
        <div className="inbox-menu__header-subtext text-normal">{t(`channels_subtext`, i18nOpts)}</div>
      </span>

      <div className="inbox-menu__header-link">
        <CreateButton />
      </div>
    </div>
  )
}


const mapDispatchToProps = (dispatch) => ({
  fetchChannels: () => dispatch(fetchChannels())
});

export default connect(null, mapDispatchToProps)(ChannelHeader);
