import React, { Component } from 'react'
import { t } from 'i18n'

import Icon from '../../Base/Icon'
import { connect } from 'react-redux'
import { toggleTask } from 'Actions/editButton'

const i18nOpts = { scope: 'goals.show.status' }

class Status extends Component {
  constructor(props) {
    super(props)

    const { completedAt } = props

    this.state = {
      completedAt,
    }
  }

  componentDidMount() {
    if (!this.state.completedAt) {
      this.props.toggle()
    }
  }

  get status() {
    return this.state.completedAt ? 'complete' : 'incomplete'
  }

  render() {
    return (
      <section className="goal-show__body-section">
        <div className="icon icon--large">
          <Icon name="check_alt" />
        </div>

        <div className="goal-show__body-section__content">
          <h3 className="goal-show__body-section__header">{t('header', i18nOpts)}</h3>
          <p className={`goal-show__body-section__status goal-show__body-section__status--${this.status}`}>
            {this.status === 'complete' ? <Icon name="check_alt3" className="goal__section--check-icon" /> : <></>}
            {t(`status.${this.status}`, i18nOpts)}
          </p>
        </div>
      </section>
    )
  }
}

Status.defaultProps = {
  completedAt: '',
}

const mapDispatchToProps = (dispatch) => ({
  toggle: () => dispatch(toggleTask()),
})

export default connect(null, mapDispatchToProps)(Status)
