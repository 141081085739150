import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';

import { Icon, Spinner, Tooltip } from 'Components/Base';
import { Activity, ActivityBody, AssignmentsWithDueAt, CarePlanBadge, TaskDetails } from '../';

import FormResponseApi from 'Api/FormResponse';
import RepeatedFormResponseApi from 'Api/RepeatedFormResponse';

const i18nOpts = { scope: 'tasks.index' };

class FormContainer extends Component {
  constructor(props) {
    super(props);

    this.renderCheckBox = this.renderCheckBox.bind(this);
    this.handleClick = this.handleClick.bind(this);

    this.state = {
      isComplete: this.props.isComplete,
      isCompleting: false,
      link: this.props.link,
      isPending: this.props.isPending,
    };
  }

  get api() {
    const { carePlanUuid, id, isRepeated } = this.props;

    if (isRepeated) {
      return new RepeatedFormResponseApi(carePlanUuid, id, null, window.location.href);
    } else {
      return new FormResponseApi(carePlanUuid, id, null, window.location.href);
    }
  }

  handleClick(evt) {
    if (this.state.isPending) {
      return;
    }

    if (!this.props.canComplete || this.state.link) {
      window.location = this.state.link;
      return;
    }

    if (evt.key && evt.key !== 'Enter') {
      return;
    }

    this.setState({ isCompleting: true });

    this.api.create({ timestamp: this.props.dueAt }).then((res) => {
      if (res.ok) {
        res.json().then(({ url }) => {
          window.location = url;
        });
      }

      this.setState({ isCompleting: false, isComplete: true });
    });
  }

  renderCheckBox() {
    return this.state.isCompleting ? (
      <div className="row__col row__col--fixed">
        <Spinner />
      </div>
    ) : (
      <div
        className="row__col row__col--fixed"
        data-test={this.state.isComplete || this.state.isPending ? 'checkbox-checked' : 'checkbox-unchecked'}
      >
        <Tooltip text={t('not_available', i18nOpts)} tabIndex="0">
          <Icon
            className="icon--medium"
            name={this.state.isComplete || this.state.isPending ? 'box-checked' : 'box-empty'}
          />
        </Tooltip>
      </div>
    );
  }

  render() {
    const { canComplete, carePlan, isHealthCareView, text, patientName, dueAt, isRepeated, id } = this.props;

    return (
      <>
        <Activity onClick={this.handleClick}>
          {canComplete ? this.renderCheckBox() : <div className="row__spacer" />}

          {carePlan.place && !isHealthCareView && <CarePlanBadge place={carePlan.place} />}

          <ActivityBody text={text}>
            <TaskDetails title={carePlan.title} patientName={patientName} />
          </ActivityBody>
          <AssignmentsWithDueAt dueAt={dueAt} repeated={isRepeated} />
        </Activity>
      </>
    );
  }
}

FormContainer.propTypes = {
  canComplete: PropTypes.bool,
  carePlan: PropTypes.shape({
    title: PropTypes.string,
    place: PropTypes.shape({
      name: PropTypes.string,
      logo: PropTypes.string,
    }),
  }),
  carePlanUuid: PropTypes.string,
  dueAt: PropTypes.string,
  id: PropTypes.number.isRequired,
  isComplete: PropTypes.bool,
  isPending: PropTypes.bool,
  isHealthCareView: PropTypes.bool,
  isRepeated: PropTypes.bool,
  link: PropTypes.string,
  patientName: PropTypes.string,
  text: PropTypes.string,
};

FormContainer.defaultProps = {
  canComplete: false,
  carePlan: {},
  carePlanUuid: '',
  dueAt: '',
  isComplete: false,
  isHealthCareView: false,
  isRepeated: false,
  link: '',
  patientName: null,
  text: '',
};

export default FormContainer;
