import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Button } from 'Components/Base/'

const i18nOpts = { scope: 'base.action_headers' }

const CancelActionHeader = ({ onClick }) => (
  <Button className="btn--link card__header-link" onClick={onClick}>
    {t('cancel', i18nOpts)}
  </Button>
)

CancelActionHeader.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default CancelActionHeader
