import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Signup from './Signup';
import PaginationButtons from 'Components/CarePlanPlaceInvitationsV2/PaginationButtons/';
import { paginateSignups } from '../SignupsFilters/signupFilters';

import {
  setTargetSignupModalTarget,
  setIsTargetSignupModalOpen,
  setTargetSignupModalStatus,
} from 'Actions/carePlanPlaceInvitationsPage';

const SignupsList = ({
  currentPageNumber,
  filteredSignups,
  maxItemCountPerPage,
  setTargetSignupModalTarget,
  setIsTargetSignupModalOpen,
  setTargetSignupModalStatus,
}) => {
  const signupsInCurrentPage = paginateSignups(currentPageNumber, maxItemCountPerPage, filteredSignups);

  return (
    <>
      {signupsInCurrentPage.map((signup) => {
        const openTargetSignupModal = () => {
          setTargetSignupModalTarget(signup);
          setIsTargetSignupModalOpen(true);
          setTargetSignupModalStatus('displayingTargetSignup');
        };

        return (
          <Signup
            key={`${signup.id}-${signup.entityType}`}
            carePlanTitle={signup.carePlanTitle}
            caregiverEmail={signup.caregiverEmail}
            caregiverName={signup.caregiverName}
            patientName={signup.patientName}
            patientPhoto={signup.patientPhoto}
            placeName={signup.placeName}
            entityType={signup.entityType}
            resolvedAt={signup.resolvedAt}
            status={signup.status}
            submitted={signup.submitted}
            onClick={openTargetSignupModal}
          />
        );
      })}
      <PaginationButtons />
    </>
  );
};

const mapStateToProps = ({ carePlanPlaceInvitationsPage }) => {
  const {
    filteredSignups,
    pagination: { currentPageNumber, maxItemCountPerPage },
  } = carePlanPlaceInvitationsPage;

  return {
    currentPageNumber,
    filteredSignups,
    maxItemCountPerPage,
  };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetTargetSignupModalTarget = (dispatch) => {
    return (target) => {
      dispatch(setTargetSignupModalTarget(target));
    };
  };

  const dispatchSetIsTargetSignupModalOpen = (dispatch) => {
    return (isOpen) => {
      dispatch(setIsTargetSignupModalOpen(isOpen));
    };
  };

  const dispatchSetTargetSignupModalStatus = (dispatch) => {
    return (status) => {
      dispatch(setTargetSignupModalStatus(status));
    };
  };

  return {
    setTargetSignupModalTarget: dispatchSetTargetSignupModalTarget(dispatch),
    setIsTargetSignupModalOpen: dispatchSetIsTargetSignupModalOpen(dispatch),
    setTargetSignupModalStatus: dispatchSetTargetSignupModalStatus(dispatch),
  };
};

SignupsList.propTypes = {
  currentPageNumber: PropTypes.number,
  filteredSignups: PropTypes.array,
  maxItemCountPerPage: PropTypes.number,
  setTargetSignupModalTarget: PropTypes.func,
  setIsTargetSignupModalOpen: PropTypes.func,
  setTargetSignupModalStatus: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupsList);
