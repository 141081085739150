import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import 'regenerator-runtime/runtime';
import { TooltipLegend } from './../Legend';
import GraphErrorMessage from './../GraphErrorMessage';
import formatUnixTime from './../formatUnixTime';

import {
  ResponsiveContainer,
  ReferenceLine,
  ReferenceArea,
  Area,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Label,
  ComposedChart,
} from 'recharts';

import {
  CHART_DOMAIN,
  CHART_TICKS,
  CHART_HEIGHT,
  CHART_LABEL_INNER_HEIGHT,
  Y_AXIS_TICK_COUNT,
  Y_AXIS_DOMAIN,
  TARGET_RANGE_UPPER_BOUND,
  TARGET_RANGE_LOWER_BOUND,
  CARTESIAN_GRID_COLOR,
  INNER_80_PCT_STROKE_COLOR,
  INNER_50_PCT_STROKE_COLOR,
  AVG_GLUCOSE_STROKE_COLOR,
  REFERENCE_LINE_COLOR,
} from './../constants';

const i18nOpts = { scope: 'dashboard.chart' };

const RiverChart = ({ data, error }) => (
  <div className="text-normal">
    <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
      <ComposedChart data={data} margin={{ left: -12, bottom: 12 }}>
        <CartesianGrid stroke={CARTESIAN_GRID_COLOR} />
        <XAxis
          dataKey={'time'}
          tickFormatter={formatUnixTime}
          type="number"
          height={CHART_LABEL_INNER_HEIGHT}
          domain={CHART_DOMAIN}
          ticks={CHART_TICKS}
        >
          <Label position="insideBottom" offset={0}>
            {t('time_of_day', i18nOpts)}
          </Label>
        </XAxis>

        <YAxis tickCount={Y_AXIS_TICK_COUNT} domain={Y_AXIS_DOMAIN} type="number">
          <Label angle={270} position="insideLeft" style={{ textAnchor: 'middle' }} offset={18}>
            {t('glucose', i18nOpts)}
          </Label>
        </YAxis>

        {/* Do not update ReferenceArea and ReferenceLine into a single fragment
            until recharts library has been updated to a React Fragment supporting version */}
        {data?.length && (
          <ReferenceArea
            y1={TARGET_RANGE_LOWER_BOUND}
            y2={TARGET_RANGE_UPPER_BOUND}
            fill={REFERENCE_LINE_COLOR}
            fillOpacity={0.1}
          />
        )}
        {data?.length && <ReferenceLine y={TARGET_RANGE_UPPER_BOUND} stroke={REFERENCE_LINE_COLOR} />}
        {data?.length && <ReferenceLine y={TARGET_RANGE_LOWER_BOUND} stroke={REFERENCE_LINE_COLOR} />}

        <Area
          dataKey={'inner50pct'}
          stroke={INNER_50_PCT_STROKE_COLOR}
          fill={INNER_50_PCT_STROKE_COLOR}
          strokeWidth={2}
          fillOpacity={0.25}
          type="linear"
        />

        <Area
          dataKey={'inner80pct'}
          stroke={INNER_80_PCT_STROKE_COLOR}
          fill={INNER_80_PCT_STROKE_COLOR}
          fillOpacity={0}
          strokeWidth={2}
          strokeDasharray="12 8" // 12 pixel lines, 8 pixel gaps
          type="linear"
        />

        <Line
          dot={false}
          dataKey={'medianGlucose'}
          stroke={AVG_GLUCOSE_STROKE_COLOR}
          strokeWidth={3}
          type="linear"
        />

        <Line
          dot={false}
          dataKey={'glucoseValue'}
          stroke={AVG_GLUCOSE_STROKE_COLOR}
          strokeWidth={3}
          type="linear"
        />

        {data.length && <Tooltip content={<TooltipLegend hasTarget={true} isIconsDisplayed={true} />} />}
      </ComposedChart>
    </ResponsiveContainer>

    {error && <GraphErrorMessage status={error} />}
  </div>
);

RiverChart.propTypes = {
  data: PropTypes.array,
  error: PropTypes.string,
  isChartDataLoaded: PropTypes.bool,
  timeRange: PropTypes.string.isRequired,
};

RiverChart.defaultProps = {
  data: [],
  error: '',
  isChartDataLoaded: false,
};

export default RiverChart;
