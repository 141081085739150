import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Button, ModalFooter } from 'Components/Base'
import Resource from './Resource'
import ResourceList from './ResourceList'
import TagSearchInput from './TagSearchInput'

const i18nOpts = { scope: 'care_plan_creators.resources' }

const ExistingResourceForm = ({
  allTags,
  dispatchRemoveResource,
  filteredModalResources,
  onCloseAddResourceModal,
  onFilterModalResources,
  onOpenModal,
  onSubmit,
  resourceAttributes,
  toggleResourceSelected,
}) => {
  return (
    <>
      <TagSearchInput handleFilterModalResources={onFilterModalResources} allTags={allTags} />
      <ResourceList selectedTab="All Resources">
        {filteredModalResources.map((res) => {
          const resAttributes = resourceAttributes(res)
          return (
            <Resource
              key={res.id}
              resource={res}
              checkboxProps={resAttributes.checkboxProps}
              isAddResForm
              toggleResourceSelected={toggleResourceSelected}
              onRemoveResource={dispatchRemoveResource}
              onOpenModal={onOpenModal}
              isResourceSelected={resAttributes.isResourceSelected}
              tagString={resAttributes.tagString}
            />
          )
        })}
      </ResourceList>
      <ModalFooter
        renderRight={() => (
          <>
            <Button
              className="btn btn--secondary"
              onClick={onCloseAddResourceModal}
              text={t('cancel', i18nOpts)}
            />
            <Button className="btn" onClick={onSubmit} text={t('done', i18nOpts)} />
          </>
        )}
      />
    </>
  )
}

ExistingResourceForm.propTypes = {
  allTags: PropTypes.array,
  dispatchRemoveResource: PropTypes.func,
  filteredModalResources: PropTypes.array,
  onCloseAddResourceModal: PropTypes.func.isRequired,
  onFilterModalResources: PropTypes.func.isRequired,
  onOpenModal: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  resourceAttributes: PropTypes.func.isRequired,
  toggleResourceSelected: PropTypes.func.isRequired,
}

ExistingResourceForm.defaultProps = {
  allTags: [],
  dispatchRemoveResource: null,
  filteredModalResources: [],
  onOpenModal: null,
}

export default ExistingResourceForm
