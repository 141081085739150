import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setCurrentPageNumber } from 'Actions/carePlanPlaceInvitationsPage';
import { getTotalPageCount } from './pageNumberButtonHelper';

const NextButtons = ({ currentPageNumber, setCurrentPageNumber, totalItemCount, maxItemCountPerPage }) => {
  const totalPageCount = getTotalPageCount(totalItemCount, maxItemCountPerPage);
  return totalPageCount > currentPageNumber ? (
    <>
      <span
        className="next"
        onClick={() => {
          setCurrentPageNumber(currentPageNumber + 1);
        }}
      >
        <a>{'›'}</a>
      </span>
      <span
        className="last"
        onClick={() => {
          setCurrentPageNumber(totalPageCount);
        }}
      >
        <a>{'»'}</a>
      </span>
    </>
  ) : (
    <>
      <span className="next--disabled">{'›'}</span>
      <span className="last--disabled">{'»'}</span>
    </>
  );
};

const mapStateToProps = ({ carePlanPlaceInvitationsPage }) => {
  const {
    filteredSignups,
    pagination: { currentPageNumber, maxItemCountPerPage },
  } = carePlanPlaceInvitationsPage;

  return {
    currentPageNumber,
    maxItemCountPerPage,
    totalItemCount: filteredSignups.length,
  };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetCurrentPageNumber = (dispatch) => {
    return (page) => {
      dispatch(setCurrentPageNumber(page));
    };
  };

  return {
    setCurrentPageNumber: dispatchSetCurrentPageNumber(dispatch),
  };
};

NextButtons.propTypes = {
  currentPageNumber: PropTypes.number,
  maxItemCountPerPage: PropTypes.number,
  setCurrentPageNumber: PropTypes.func,
  totalItemCount: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(NextButtons);
