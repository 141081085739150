import React, {forwardRef} from 'react';
import { QRCode as QRCodeLib } from 'react-qrcode-logo';

const QRCode = forwardRef(({ id, url, fgColor = '#003B69', logo, logoWidth = 40, logoHeight = 40 }, ref) => {
  return (
    <QRCodeLib
      id={id}
      ref={ref}
      value={url}
      quietZone={5}
      fgColor={fgColor}
      ecLevel="H"
      qrStyle="dots"
      enableCORS={true}
      removeQrCodeBehindLogo={true}
      logoImage={logo}
      logoWidth={logoWidth}
      logoHeight={logoHeight}
      logoPadding={1}
      logoPaddingStyle="circle"
    />
  );
});

export default QRCode;
