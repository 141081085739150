import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import { connect } from 'react-redux';

import Loader from 'Components/Base/Loader';
import SignupsTabs from './SignupsTabs';
import TargetSignupModal from './TargetSignupModal';
import SignupsFilterByStatus from './SignupsFilters/SignupsFilterByStatus';
import SignupsSearchFilter from './SignupsFilters/SignupsSearchFilter';
import SignupsList from './SignupsList';
import SignupsDescription from './SignupsDescription';
import ApiError from './ApiError';

import setupSignupsFetcher from './setupSignupsFetcher';

import {
  setSignups,
  setPageState,
  setApiError,
  setFilteredSignups,
} from 'Actions/carePlanPlaceInvitationsPage';

import invitationsHeaderIcon from 'invitations_header-illustration.svg';

const i18nOpts = { scope: 'care_plan_place_invitations.index' };

export const renderPageState = (pageState) => {
  switch (pageState) {
    case 'loading':
      return <Loader />;
    case 'apiError':
      return <ApiError />;
    case 'success':
      return (
        <>
          <div className="signups-filters">
            <SignupsSearchFilter />
            <SignupsFilterByStatus />
          </div>
          <SignupsDescription />
          <SignupsList />
        </>
      );
    default:
      return <ApiError />;
  }
};

const SignupsContainer = ({
  pageState,
  currentTab,
  setSignups,
  setPageState,
  setApiError,
  setFilteredSignups,
}) => {
  const getSignups = setupSignupsFetcher(setSignups, setPageState, setApiError);

  useEffect(() => {
    (async () => {
      const signups = await getSignups();
      // bug - this should apply all filters
      const filteredSignups = signups.filter(({ entityType }) => entityType === currentTab);

      setFilteredSignups(filteredSignups);
    })();
  }, []);

  return (
    <section>
      <div className="card">
        <div className="header invitations-header">
          <div className="invitations-header__container">
            <h2 className="text-medium">{t('pending_header', i18nOpts)}</h2>
            <SignupsTabs />
          </div>
          <img className="invitations-header__icon" src={invitationsHeaderIcon}/>
        </div>
        {renderPageState(pageState)}
        <TargetSignupModal />
      </div>
    </section>
  );
};

const mapStateToProps = ({ carePlanPlaceInvitationsPage }) => {
  const { pageState, signups, currentTab } = carePlanPlaceInvitationsPage;

  return {
    pageState,
    signups,
    currentTab,
  };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetSignups = (dispatch) => {
    return (signups) => {
      dispatch(setSignups(signups));
    };
  };

  const dispatchSetPageState = (dispatch) => {
    return (pageState) => {
      dispatch(setPageState(pageState));
    };
  };

  const dispatchSetApiError = (dispatch) => {
    return (apiError) => {
      dispatch(setApiError(apiError));
    };
  };

  const dispatchSetFilteredSignups = (dispatch) => {
    return (signups) => {
      dispatch(setFilteredSignups(signups));
    };
  };

  return {
    setSignups: dispatchSetSignups(dispatch),
    setPageState: dispatchSetPageState(dispatch),
    setApiError: dispatchSetApiError(dispatch),
    setFilteredSignups: dispatchSetFilteredSignups(dispatch),
  };
};

SignupsContainer.propTypes = {
  currentTab: PropTypes.string,
  pageState: PropTypes.string,
  setApiError: PropTypes.func,
  setFilteredSignups: PropTypes.func,
  setSignups: PropTypes.func,
  setPageState: PropTypes.func,
  signups: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupsContainer);
