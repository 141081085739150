import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'Components/Base/Icon';
import { t } from 'i18n';

const RenderLegend = ({ legend }) => (
  <div className="block-visual block-visual__legend-wrapper">
    <div className="block-visual__legend-padding" />
    <div className="block-visual block-visual__legend">
      {legend.map((description, index) => (
        <div key={index} className="block-visual__legend__symbol block-visual__categories__symbol">
          {description === t('no_data', { scope: 'blocks' }) ? (
            <Icon key={index} name="close" className={`item item--${index} no-data`} />
          ) : (
            <div className={`item item--${index}`} />
          )}
          <span className="description">{description}</span>
        </div>
      ))}
    </div>
  </div>
);

RenderLegend.propTypes = { legend: PropTypes.array.isRequired };

export default RenderLegend;
