import React from 'react'
import ProviderWrapper from 'Components/Base/ProviderWrapper'

import NotificationTray from './NotificationTray'

const NotificationTrayContainer = () => (
  <ProviderWrapper>
    <NotificationTray />
  </ProviderWrapper>
)

export default NotificationTrayContainer
