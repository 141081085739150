import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import {
  setActivityModalActivityProperties,
} from 'Actions/activityModal';

import RadioOption from 'Components/Base/RadioOption';

const i18nOpts = { scope: 'activity_modal.form_fields.visibility' };

export const Visibility = ({
  activityProperties,
  defaultVisibleToAll,
  setActivityModalActivityProperties
}) => {
  const visibleToAll = activityProperties?.visibleToAll;

  const DefaultTag = <div className="default_tag">{t('default', i18nOpts)}</div>;

  return (
    <>
      <div className="activity_modal__input_field__label">{t('who_should_see', i18nOpts)}</div>

      <div className="visibility_controller_container">
        <RadioOption
          isSelected={visibleToAll}
          onClick={() => {
            setActivityModalActivityProperties({
              ...activityProperties,
              visibleToAll: true
            });
          }}
          className="left_option"
        >
          {t('everyone', i18nOpts)}
          {defaultVisibleToAll && DefaultTag}
        </RadioOption>
        <RadioOption
          isSelected={!visibleToAll}
          onClick={() => {
            setActivityModalActivityProperties({
              ...activityProperties,
              visibleToAll: false
            });
          }}
        >
          {t('hcp_only', i18nOpts)}
          {!defaultVisibleToAll && DefaultTag}
        </RadioOption>
      </div>
    </>
  );
};

const mapStateToProps = ({ activityModal }) => {
  const { activityProperties, defaultVisibleToAll } = activityModal;

  return { activityProperties, defaultVisibleToAll };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalActivityProperties = (dispatch) => {
    return (activityProperties) => {
      dispatch(setActivityModalActivityProperties(activityProperties));
    };
  };

  return {
    setActivityModalActivityProperties: dispatchSetActivityModalActivityProperties(dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Visibility);
