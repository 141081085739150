import NotificationApi from 'Api/Notification'
import moment from 'ct-moment'

export const actionTypes = {
  OPEN_TRAY: 'notificationTray/OPEN_TRAY',
  CLOSE_TRAY: 'notificationTray/CLOSE_TRAY',
  FETCH_NOTIFICATIONS_REQUEST: 'notificationTray/FETCH_NOTIFICATIONS_REQUEST',
  FETCH_NOTIFICATIONS_SUCCESS: 'notificationTray/FETCH_NOTIFICATIONS_SUCCESS',
  MARK_AS_READ_REQUEST: 'notificationTray/MARK_AS_READ_REQUEST',
  MARK_AS_READ_SUCCESS: 'notificationTray/MARK_AS_READ_SUCCESS',
}

const toggleTray = () => {
  return (dispatch, getState) => {
    const { isOpen } = getState().notificationTray

    dispatch(isOpen ? closeTray() : openTray())
  }
}

const openTray = () => {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.OPEN_TRAY })

    // To prevent multiple costly notification requests, only fetch new requests
    // from the server if the cache has expired (> 2 minutes since last request)
    const { lastFetch } = getState().notificationTray
    const timeElapsed = moment.duration(new moment().diff(lastFetch)).as('minutes')

    if (!lastFetch || timeElapsed > 2) {
      dispatch(fetchNotifications())
    }
  }
}

const closeTray = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.CLOSE_TRAY })
  }
}

const fetchNotifications = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_NOTIFICATIONS_REQUEST })

    return new NotificationApi()
      .index()
      .then((res) => res.json())
      .then((notifications) => {
        dispatch({
          type: actionTypes.FETCH_NOTIFICATIONS_SUCCESS,
          payload: {
            lastFetch: new moment(),
            notifications,
          },
        })
      })
  }
}

const markAsRead = (id) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.MARK_AS_READ_REQUEST })

    return new NotificationApi(id).markAsRead().then((res) => {
      if (res.ok) {
        dispatch({
          type: actionTypes.MARK_AS_READ_SUCCESS,
          payload: { id },
        })
      }
    })
  }
}

export const actions = {
  openTray,
  closeTray,
  toggleTray,
  fetchNotifications,
  markAsRead,
}
