import React from 'react'
import PropTypes from 'prop-types'

import Icon from 'Components/Base/Icon'

const ItemListHeader = ({ headerText, iconName, itemsSelectedCount, listIsOpen, toggleListVisibility }) => (
  <h3 className="item-pill-section__header">
    {iconName && <Icon name={iconName} className="item-pill-section__header-icon" />}

    {headerText}

    {itemsSelectedCount > 0 && (
      <span className="item-pill-section__header-parenthetical">({itemsSelectedCount})</span>
    )}

    {toggleListVisibility && (
      <div className="icon icon-container" onClick={toggleListVisibility}>
        <Icon name={listIsOpen ? 'carrot-down' : 'carrot-up'} className="item-pill-section__header-icon" />
      </div>
    )}
  </h3>
)

ItemListHeader.propTypes = {
  headerText: PropTypes.string.isRequired,
  iconName: PropTypes.string,
  itemsSelectedCount: PropTypes.number,
  listIsOpen: PropTypes.bool.isRequired,
  toggleListVisibility: PropTypes.func,
}

ItemListHeader.defaultProps = {
  iconName: '',
  itemsSelectedCount: null,
  toggleListVisibility: null,
}

export default ItemListHeader
