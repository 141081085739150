import TaskApi from 'Api/Task'
import IntegratedTaskApi from 'Api/Integrated/Task'
import PlaceUserTaskApi from 'Api/PlaceUser/Task'

export const RECEIVE_AGENDA = 'RECEIVE_AGENDA'
export const REQUEST_AGENDA = 'REQUEST_AGENDA'
export const ADD_EVENT_TO_AGENDA = 'ADD_EVENT_TO_AGENDA'
export const REMOVE_EVENT_FROM_AGENDA = 'REMOVE_EVENT_FROM_AGENDA'
export const ADD_REPEATED_ACTIVITY_TO_AGENDA = 'ADD_REPEATED_ACTIVITY_TO_AGENDA'
export const SET_HEALTH_CARE_VIEW = 'SET_HEALTH_CARE_VIEW'

const setHealthCareView = () => ({
  type: SET_HEALTH_CARE_VIEW,
})

const fetchAgenda = (carePlanId, year = null, week = null) => {
  return (dispatch) => {
    dispatch(requestAgenda())

    return new TaskApi(carePlanId)
      .weekly(year, week)
      .then((res) => res.json())
      .then(({ agenda }) => dispatch(receiveAgenda(agenda)))
  }
}

const fetchIntegratedAgenda = (patientUuid, year = null, week = null) => {
  return (dispatch) => {
    dispatch(requestAgenda())

    return new IntegratedTaskApi(patientUuid)
      .weekly(year, week)
      .then((res) => res.json())
      .then(({ agenda }) => dispatch(receiveAgenda(agenda)))
  }
}

const fetchHealthCareAgenda = (placeUserId, year = null, week = null) => {
  return (dispatch) => {
    dispatch(requestAgenda())

    return new PlaceUserTaskApi(placeUserId)
      .weekly(year, week)
      .then((res) => res.json())
      .then(({ agenda }) => dispatch(receiveAgenda(agenda)))
  }
}

const requestAgenda = () => ({
  type: REQUEST_AGENDA,
})

const receiveAgenda = (agenda) => ({
  type: RECEIVE_AGENDA,
  agenda,
})

const addEventToAgenda = (event) => ({
  type: ADD_EVENT_TO_AGENDA,
  event,
})

const removeEventFromAgenda = (event) => ({
  type: REMOVE_EVENT_FROM_AGENDA,
  event,
})

const addRepeatedActivityToAgenda = (repeatedActivity) => ({
  type: ADD_REPEATED_ACTIVITY_TO_AGENDA,
  repeatedActivity,
})

export {
  fetchAgenda,
  fetchHealthCareAgenda,
  requestAgenda,
  receiveAgenda,
  addEventToAgenda,
  setHealthCareView,
  removeEventFromAgenda,
  addRepeatedActivityToAgenda,
  fetchIntegratedAgenda,
}
