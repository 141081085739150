import React from 'react'
import PropTypes from 'prop-types'

const TaskDetails = ({ title, patientName }) => (
  <div className="text-truncate">
    <span className="text-grey-darkest text-normal hidden--lg hidden--med">
      {patientName}
      {patientName && title && <br />}
      {title}
    </span>

    <span className="text-grey-darkest text-normal hidden--sm">
      {[patientName && patientName, title && title].filter(Boolean).join(' | ')}
    </span>
  </div>
)

TaskDetails.propTypes = {
  patientName: PropTypes.string,
  title: PropTypes.string,
}

TaskDetails.defaultProps = {
  patientName: null,
  title: null,
}

export default TaskDetails
