import React from 'react';
import PropTypes from 'prop-types';

import GenericOrderForm from 'Components/CarePlans/GenericOrderSection/GenericOrderForm';
import PrescriptionOrderForm from 'Components/CarePlans/PrescriptionSection/PrescriptionForm';
import MedicalOrderForm from 'Components/CarePlans/MedicalOrderSection/MedicalOrderForm';
import Footer from './Footer';

const Form = ({ kind, handleActionClick, ...rest }) => {
  switch (kind) {
    case 'generic':
    case 'generic_health':
      return (
        <GenericOrderForm
          {...rest}
          renderSubmit={() => <Footer {...rest} toggleModal={handleActionClick} displayLinkResourcesButton />}
        />
      );
    case 'medical':
      return (
        <MedicalOrderForm
          {...rest}
          renderSubmit={() => <Footer {...rest} toggleModal={handleActionClick} displayLinkResourcesButton />}
        />
      );
    case 'prescription':
      return (
        <PrescriptionOrderForm {...rest} renderSubmit={() => <Footer toggleModal={handleActionClick} />} />
      );
    default:
      return null;
  }
};

Form.propTypes = {
  handleActionClick: PropTypes.func.isRequired,
  kind: PropTypes.string.isRequired,
};

Form.defaultProps = {};

export default Form;
