import React, { useState, useEffect, useRef, Fragment } from 'react';
import Quill from 'quill';
import { t } from 'i18n';
import _ from 'lodash';

// This uses the QuillJS rich text editor
// https://quilljs.com/
// There are 2 components, one for the Editor (this file) and another for the viewer (QuillViewer)
//
// * @param {Delta} contentFormatted Quill's Delta data structure that represents the formatted content
// * @param {() => void} setContentFormmated updates the contentFormatted
// * @param {() => void} setContentText (optional) updates the contentText (raw text, unformatted)
//
// To easily view the output of the QuillEditor, you will need to save the formatted content
// which is in the form of Quill's custom `Delta` data structure: https://quilljs.com/docs/delta/
// The `setContentFormatted` function is used for this.
// Once you have the `contentFormatted`, you can pass it into `QuillViewer`.
//
// The styling comes from QuillJS's `snow` css theme. The css is stored locally in `_quill.scss`
// with minor modifications to fit our design better.
//
// In some cases, QuillJS may be undefined when QuillEditor/QuilllViewer is imported.
// If that happens, lazy load QuillEditor/QuillViewer:
// ```
// const QuillViewer = lazy(() => import('Components/Base/Quilljs/QuillViewer'));
// ...
//
// <Suspense fallback={t('loading', i18nOpts)}>
//   <QuillViewer contentFormatted={emergencyInstruction} content={defaultEmergencyInstruction} />
// </Suspense>
// ```
const i18nOpts = { scope: 'editor' };

const localizeTooltip = (quill) => {
  const input = quill.theme.tooltip.root.querySelector('input[data-link]');
  input.dataset.link = 'http://www.example.com'; // placeholder
  document.querySelector('.ql-snow .ql-tooltip a.ql-action').dataset.edit = t('edit', i18nOpts); // Edit button
  document.querySelector('.ql-snow .ql-tooltip a.ql-action').dataset.save = t('save', i18nOpts); // Save button
  document.querySelector('.ql-snow .ql-tooltip a.ql-remove').dataset.remove = t('remove', i18nOpts); // Remove button
  document.querySelector('.ql-snow .ql-tooltip').dataset.visit = t('visit', i18nOpts); // Visit url text
  document.querySelector('.ql-snow .ql-tooltip').dataset.enter = t('enter_link', i18nOpts); // Enter url text
};

const QuillEditor = ({ contentFormatted, setContentFormatted, setContentText }) => {
  const editorContainerRef = useRef(null);
  const editorRef = useRef(null);

  const [quill, setQuill] = useState(null);

  const options = {
    theme: 'snow',
    modules: {
      toolbar: {
        container: [['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }], ['link']],
      },
    },
  };

  useEffect(() => {
    const editorEl = editorRef.current;
    const quillLocal = new Quill(editorEl, options);
    setQuill(quillLocal);

    quillLocal.setContents(contentFormatted);

    // (optional) Do an update the very first time to initialize the state for contentText
    if (setContentText) setContentText(quillLocal.getText()?.trimEnd());

    localizeTooltip(quillLocal); // HACK: localize the link tooltip

    const handler = (delta, oldDelta, source) => {
      setContentFormatted(quillLocal.getContents().ops);
      if (setContentText) setContentText(quillLocal.getText()?.trimEnd());
    };

    quillLocal.on('text-change', handler);

    return () => {
      quillLocal.off('text-change', handler);
      // editorContainerRef needs to be removed manually, otherwise it will stay there even if the React component is gone
      editorContainerRef.current.remove();
    };
  }, []);

  useEffect(() => {
    // This guard statement prevents a potential infinite re-render loop
    const isUnchanged = _.isEqual(contentFormatted, quill?.getContents().ops);
    if (isUnchanged) return;

    quill?.setContents(contentFormatted);
  }, [contentFormatted]);

  return (
    <Fragment>
      <div id="quill-editor-container" ref={editorContainerRef}>
        <div id="quill-editor" ref={editorRef}></div>
      </div>
    </Fragment>
  );
};

export default QuillEditor;
