import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

const i18nOpts = { scope: 'forms.submit' }

const Submit = ({ value, className = 'btn', disabled }) => (
  <input className={className} type="submit" name="commit" value={value} disabled={disabled} />
)

Submit.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
}

Submit.defaultProps = {
  value: t('save', i18nOpts),
  className: 'btn',
  disabled: false,
}

export default Submit
