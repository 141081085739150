export const SET_PAGE_STATE = 'SET_PAGE_STATE';
export const SET_PATIENTS = 'SET_PATIENTS';
export const SET_SORT_BY = 'SET_SORT_BY';
export const SET_SEARCH_INPUT = 'SET_SEARCH_INPUT';
export const SET_IS_SHOWING_MY_PATIENTS = 'SET_IS_SHOWING_MY_PATIENTS';
export const SET_IS_SHOWING_ARCHIVED_PATIENTS = 'SET_IS_SHOWING_ARCHIVED_PATIENTS';
export const SET_MY_PATIENT_IDS = 'SET_MY_PATIENT_IDS';
export const SET_ARCHIVED_PATIENTS_AND_IDS = 'SET_ARCHIVED_PATIENTS_AND_IDS';

const setPageState = (pageState) => ({
  type: SET_PAGE_STATE,
  pageState,
});

const setPatients = (patients) => ({
  type: SET_PATIENTS,
  patients,
});

const setSortBy = (sortBy) => ({
  type: SET_SORT_BY,
  sortBy,
});

const setSearchInput = (searchInput) => ({
  type: SET_SEARCH_INPUT,
  searchInput,
});

const setIsShowingMyPatients = (isShowingMyPatients) => ({
  type: SET_IS_SHOWING_MY_PATIENTS,
  isShowingMyPatients,
});

const setIsShowingArchivedPatients = (isShowingArchivedPatients) => ({
  type: SET_IS_SHOWING_ARCHIVED_PATIENTS,
  isShowingArchivedPatients,
});

const setMyPatientIds = (myPatientIds) => ({
  type: SET_MY_PATIENT_IDS,
  myPatientIds,
});

const setArchivedPatientsAndIds = (archivedPatientIds) => ({
  type: SET_ARCHIVED_PATIENTS_AND_IDS,
  archivedPatientIds,
});


export { setArchivedPatientsAndIds, setIsShowingArchivedPatients, setIsShowingMyPatients, setMyPatientIds, setPageState, setPatients, setSortBy, setSearchInput};