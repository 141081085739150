import React from 'react';
import { t } from 'i18n';

import FormField from '../FormField'

const i18nOpts = { scope: "care_plan_creators.library_appointment_templates.form" };

const ExtendedAddressByCountry = ({ address, onChange, formErrors }) => {
  let regionLocale, postalCodeLocale;

  switch (address.countryCode) {
    case 'US':
      regionLocale = t('state', i18nOpts);
      postalCodeLocale = t('zip_code', i18nOpts);
      break;
    case 'GB':
      postalCodeLocale = t('postcode', i18nOpts);
      break;
    case 'CA':
    default:
      regionLocale = t('province', i18nOpts);
      postalCodeLocale = t('postal_code', i18nOpts);
      break;
  }

  return (
    <div className="patient_address_form__extended_address__input_field__container">
      <FormField
        className="patient_address_form__extended_address__input_field"
        label={t('city', i18nOpts)}
        placeholder={t('city', i18nOpts)}
        value={address.city}
        name="city"
        onChange={onChange}
        formErrors={formErrors}
      />
      {address.countryCode != 'GB' &&
        <FormField
          className="patient_address_form__extended_address__input_field"
          label={regionLocale}
          placeholder={regionLocale}
          value={address.region}
          name="region"
          onChange={onChange}
          formErrors={formErrors}
        />
      }
      <FormField
        label={postalCodeLocale}
        placeholder={postalCodeLocale}
        className="patient_address_form__extended_address__input_field"
        value={address.postalCode}
        name="postalCode"
        onChange={onChange}
        formErrors={formErrors}
      />
    </div>
  )
};

export default ExtendedAddressByCountry;
