import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import { setActivityModalActivityProperties, setActivityModalShowRepeatsFields } from 'Actions/activityModal';

import Checkbox from 'Components/Forms/Checkbox';
import DueAt from './DueAt';
import Repeats from './Repeats';

const i18nOpts = { scope: 'activity_modal.form_fields.when' };

export const Scheduled = ({
  activityProperties,
  showRepeatsFields,
  setActivityModalActivityProperties,
  setActivityModalShowRepeatsFields,
}) => {
  return (
    <>
      <DueAt />
      {!showRepeatsFields && (
        <Checkbox
          checked={false}
          name="repeatsCheckbox"
          text={t('repeats', i18nOpts)}
          onChange={() => {
            // set defaults
            setActivityModalActivityProperties({
              ...activityProperties,
              frequency: 'daily',
              frequencyType: 'recurring',
              interval: 1,
              endRepeat: 'never',
            });
            setActivityModalShowRepeatsFields(true);
          }}
        />
      )}

      {showRepeatsFields && <Repeats />}
    </>
  );
};

const mapStateToProps = ({ activityModal }) => {
  const { activityProperties, showRepeatsFields } = activityModal;

  return {
    activityProperties,
    showRepeatsFields,
  };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalActivityProperties = (dispatch) => {
    return (activityProperties) => {
      dispatch(setActivityModalActivityProperties(activityProperties));
    };
  };

  const dispatchSetActivityModalShowRepeatsFields = (dispatch) => {
    return (showRepeatsFields) => {
      dispatch(setActivityModalShowRepeatsFields(showRepeatsFields));
    };
  };

  return {
    setActivityModalActivityProperties: dispatchSetActivityModalActivityProperties(dispatch),
    setActivityModalShowRepeatsFields: dispatchSetActivityModalShowRepeatsFields(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Scheduled);
