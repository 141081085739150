import moment from 'ct-moment';

export const calculateDateFieldsWrapper = (timeProvider = moment) => {
  return (rangeEndDate, chartMaximumDate) => {
    return {
      chartMinimumDate: timeProvider(chartMaximumDate).subtract('days', 90).format('YYYY-MM-DD'),
      rangeStartDate: timeProvider(rangeEndDate).subtract('days', 6).format('YYYY-MM-DD'),
      displayBeginDate: timeProvider(rangeEndDate).subtract('days', 6).format('MMM D'),
      displayEndDate: timeProvider(rangeEndDate).format('MMM D'),
    };
  };
};

export default calculateDateFieldsWrapper(moment);
