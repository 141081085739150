import { REQUEST_TASKS, RECEIVE_TASKS, ADD_TASK } from '../actions/tasks'

const initialState = {
  isFetching: true, // For nice transition from server-side rendering
  items: [],
}

const tasks = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_TASKS:
      return {
        ...state,
        isFetching: true,
      }
    case RECEIVE_TASKS:
      return {
        ...state,
        items: action.tasks,
        isFetching: false,
        completed: action.completed,
      }
    case ADD_TASK:
      if (state.completed) {
        return state
      } else {
        return {
          ...state,
          items: [...state.items, action.task],
        }
      }
    default:
      return state
  }
}

export default tasks
