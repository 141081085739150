import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';

import { Icon, Tooltip } from 'Components/Base';

const i18nOpts = { scope: 'care_plan_action_plan_page.activities_container.appointments_card.appointment' };

const Appointment = ({ practitioner, link, text, url, dueAtFormatted, completedAt, helpRequest }) => {
  return (
    <li className="order">
      <div className="order__section text-truncate">
        <p>
          <strong>{practitioner}</strong>
        </p>
        <strong>{text || t('unnamed_appointment', i18nOpts)}</strong>
        {url && (
          <p>
            <a href={url} target="_blank">
              {url}
            </a>
          </p>
        )}
        {!!helpRequest && (
          <div className="help-request-alert">
            <Icon name="warning" />
            <span className="help-request-alert__details">{t('help_requested', i18nOpts)}</span>
          </div>
        )}
      </div>

      <div className="order__section">
        <div className="order__section--status">
          {dueAtFormatted ? (
            dueAtFormatted
          ) : completedAt ? (
            <span className="order--complete">
              <Icon name="check_alt3" className="order__section--check-icon" />
              {t('booked', i18nOpts)}
            </span>
          ) : (
            <span className="order--due">{t('not_booked', i18nOpts)}</span>
          )}
        </div>
        <Tooltip text={t('edit', i18nOpts)} className="order__section--tooltip">
          <a href={link}>
            <Icon name="pencil-edit" />
          </a>
        </Tooltip>
      </div>
    </li>
  );
};

Appointment.propTypes = {
  completedAt: PropTypes.string,
  dueAtFormatted: PropTypes.string,
  helpRequest: PropTypes.object,
  link: PropTypes.string.isRequired,
  practitioner: PropTypes.string,
  text: PropTypes.string.isRequired,
  url: PropTypes.string,
};

Appointment.defaultProps = {
  completedAt: '',
  dueAtFormatted: '',
  helpRequest: {},
  practitioner: '',
  url: '',
};

export default Appointment;
