import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setCachedImageUrl } from 'Actions/deferredImages'
import { getDeferredImageUrl } from 'Selectors/deferredImages'
import missing from 'missing.png'

import { CSSTransition, TransitionGroup } from 'react-transition-group'

import DeferredImageApi from 'Api/DeferredImage'

export class DeferredImage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      imageUrl: props.cachedImageUrl || props.fallbackImage || missing,
    }

    this.handleImageError = this.handleImageError.bind(this)
  }

  handleImageError() {
    // https://stackoverflow.com/a/51401156
    this.setState({ imageUrl: this.props.fallbackImage || missing })
  }

  componentDidMount() {
    if (!this.props.cachedImageUrl && this.props.imageEntitlementToken) {
      const api = new DeferredImageApi()
      return api.retrieveImageUrl(this.props.imageEntitlementToken).then((res) => {
        if (res.ok) {
          return res.json().then(({ imageUrl }) => {
            this.setState(
              { imageUrl },
              this.props.setCachedImageUrl.bind(null, this.props.imageEntitlementToken, imageUrl)
            )
          })
        } else if (res.status === 422) {
          return res.json().then(console.error)
        }
      })
    }
  }

  render() {
    return (
      <TransitionGroup className={this.props.className} style={{ position: 'relative' }}>
        <CSSTransition key={this.state.imageUrl} classNames="fade" timeout={250}>
          <img
            src={this.state.imageUrl}
            alt={this.props.alt}
            className={this.props.className}
            onError={this.handleImageError}
          />
        </CSSTransition>
      </TransitionGroup>
    )
  }
}

DeferredImage.propTypes = {
  alt: PropTypes.string,
  cachedImageUrl: PropTypes.string,
  className: PropTypes.string,
  fallbackImage: PropTypes.string,
  imageEntitlementToken: PropTypes.string.isRequired,
  setCachedImageUrl: PropTypes.func.isRequired,
}

DeferredImage.defaultProps = {
  alt: '',
  cachedImageUrl: '',
  className: '',
  fallbackImage: '',
}

const mapStateToProps = (state, ownProps) => ({
  cachedImageUrl: getDeferredImageUrl(state, ownProps.imageEntitlementToken),
})

const mapDispatchToProps = (dispatch) => ({
  setCachedImageUrl: (imageEntitlementToken, imageUrl) =>
    dispatch(setCachedImageUrl(imageEntitlementToken, imageUrl)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DeferredImage)
