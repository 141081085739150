import validateUserApi from './SignupContainer/UserForm/validateUserApi';

export const setupUserValidatorWrapper = (validateUserApiProvider = validateUserApi) => {
  return (setApiErrorMessage, setIsLoading, goToNextStep, executeAnalytics = null) => {
    return async (user, validationUrl) => {
      setApiErrorMessage('');
      setIsLoading(true);

      try {
        const { status, error, data } = await validateUserApiProvider(user, validationUrl);
        if (status === 200) {
          executeAnalytics && executeAnalytics();

          if (data?.redirectUrl) {
            return window.location.assign(data.redirectUrl);
          }

          goToNextStep();
        } else if (status === 422 && error) {
          throw error;
        } else {
          throw 'Something went wrong';
        }
      } catch (error) {
        if (typeof error === 'string') {
          setApiErrorMessage(error);
        } else if (typeof error === 'object' && error !== null) {
          const errorKeys = Object.keys(error);

          const output = errorKeys.reduce((acc, key) => {
            acc = [...acc, `${key}: ${error[key]}`];
            return acc;
          }, []);

          setApiErrorMessage(output.join('\n'));
        }
      }

      setIsLoading(false);
    };
  };
};

export default setupUserValidatorWrapper(validateUserApi);
