import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Icon } from 'Components/Base'
import Chip from 'Components/Base/Chip'

const i18nOpts = { scope: 'team.select' }

class RoleOption extends PureComponent {
  constructor(props) {
    super(props)
  }

  get chipColor() {
    return {
      primary_caregiver: 'brand',
      caregiver: 'blue-grey',
      support: 'light-grey',
      unaffiliated_health_care: 'lighter-grey',
    }[this.props.role]
  }

  render() {
    const { role, collapsed, selected, onSelect, onExpand } = this.props

    return (
      <Fragment>
        <div className="accordion-option__radio" onClick={onSelect}>
          <Icon name={selected ? 'circle-selected' : 'circle-unselected'} />
        </div>

        <div className="accordion-option__section">
          <div className="accordion-option__header" onClick={collapsed ? onExpand : null}>
            <h3 className="accordion-option__header-text text-medium">{t(`${role}.header`, i18nOpts)}</h3>

            <Chip text={t(`${role}.chip`, i18nOpts)} color={this.chipColor} />

            <span className="accordion-option__header-section">
              <div className="icon icon--large" onClick={onExpand}>
                <Icon name={collapsed ? 'carrot-down' : 'carrot-up'} />
              </div>
            </span>
          </div>

          <div className="accordion-option__body">
            <p>{t(`${role}.description`, i18nOpts)}</p>

            <h3 className="text-medium">{t(`${role}.access`, i18nOpts)}</h3>

            <ul className="arrow-list">
              {t(`${role}.list`, i18nOpts).map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      </Fragment>
    )
  }
}

RoleOption.propTypes = {
  collapsed: PropTypes.bool,
  onExpand: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  role: PropTypes.oneOf(['caregiver', 'primary_caregiver', 'support', 'unaffiliated_health_care']).isRequired,
  selected: PropTypes.bool,
}

RoleOption.defaultProps = {
  collapsed: false,
  selected: false,
}

export default RoleOption
