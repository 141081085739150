import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MAX_FILE_SIZE } from 'Utilities/constants'
import AvatarPreview from './AvatarPreview'
import Errors from './Errors'
import defaultImage from 'profile-pic-upload.svg'

import { t } from 'i18n'
const i18nOpts = { scope: 'forms.profile_picture_upload' }

class ProfilePictureInput extends Component {
  constructor(props) {
    super(props)

    this.fileUploadRef = null
    this.setFileUploadRef = (element) => {
      this.fileUploadRef = element
    }

    this.state = {
      errors: props.errors,
      fileName: null,
      fileSizeExceeded: false,
      fileFormatError: null,
      previewImageSource: props.photo,
    }

    this.handleFileChange = this.handleFileChange.bind(this)
    this.handleUploadFile = this.handleUploadFile.bind(this)
    this.handleRemove = this.handleRemove.bind(this)
  }

  get currentFile() {
    return this.fileUploadRef.files[0]
  }

  get currentFileIsPresent() {
    return this.fileUploadRef && this.fileUploadRef.files[0]
  }

  get previewImageSource() {
    if (this.currentFileIsPresent) {
      return URL.createObjectURL(this.currentFile)
    } else {
      return null
    }
  }

  handleRemove() {
    if (this.currentFileIsPresent) {
      // https://stackoverflow.com/a/3162319
      this.fileUploadRef.value = ''
      this.handleFileChange()
    }
  }

  handleFileChange() {
    const { name = null, size = null } = this.currentFile || {}
    const fileSizeExceeded = size > MAX_FILE_SIZE
    const errors = fileSizeExceeded ? t('file_size_exceeded', i18nOpts) : ''

    this.setState({
      errors,
      fileName: name,
      size,
      fileSizeExceeded,
      previewImageSource: this.previewImageSource,
    })
  }

  handleUploadFile(event) {
    if (this.fileUploadRef) {
      this.fileUploadRef.click()

      if (event) {
        // stop event propagation; otherwise, the form will lose focus
        event.stopPropagation()
        event.preventDefault()
      }
    }
  }

  render() {
    return (
      <div className="profile-pic-upload">
        <input
          ref={this.setFileUploadRef}
          onChange={this.handleFileChange}
          className="profile-pic-upload__input"
          type="file"
          accept="image/*"
          name={this.props.inputName}
          id={this.props.inputId}
        />

        <AvatarPreview
          alt={this.state.fileName || t('default_alt', i18nOpts)}
          onUpload={this.handleUploadFile}
          onRemove={this.handleRemove}
          previewImageSource={this.state.previewImageSource}
          defaultImage={defaultImage}
        />

        <div className="profile-upload__text">
          <a className="profile-pic-upload__text-link text-medium" onClick={this.handleUploadFile}>
            {this.currentFileIsPresent ? t('update', i18nOpts) : t('upload', i18nOpts)}
          </a>
          <span className="text-grey">&nbsp;{t('optional', i18nOpts)}</span>
          <div>{t('explanation', i18nOpts)}</div>

          {!!this.state.errors && <Errors errors={this.state.errors} />}
        </div>
      </div>
    )
  }
}

ProfilePictureInput.propTypes = {
  errors: PropTypes.string,
  inputId: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
  photo: PropTypes.string,
}

ProfilePictureInput.defaultProps = {
  errors: '',
  photo: '',
}

export default ProfilePictureInput
