import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'

import Label from 'Components/Forms/Label'
import Input from 'Components/Forms/Input'
import InputGroup from 'Components/Forms/InputGroup'
import DateTimeInput from 'Components/Forms/DateTimeInput'

import Icon from 'Components/Base/Icon'

import { t } from 'i18n'

class RepeatableFormFields extends PureComponent {
  constructor(props) {
    super(props)
  }

  renderAddDateLink() {
    return (
      <div className="icon-container icon-container--link header">
        <Icon name="clock_alt" />
        <a href="#" onClick={this.props.showDateTime}>
          {t('add_time', { scope: 'tasks.form' })}
        </a>
      </div>
    )
  }

  renderDatefields() {
    const {
      allowRepeat,
      dueAt,
      ensureDatePickerVisibility,
      errors,
      hideDateTime,
      onChange,
      timeZone,
    } = this.props
    return (
      <Fragment>
        <div className="input">
          <Label inputName="dueAt" text={t('due_at', { scope: 'tasks.form' })} />
          <div className="date-input">
            <div className="date-input__input">
              <DateTimeInput
                ensureVisibility={ensureDatePickerVisibility}
                id="dueAt"
                name="dueAt"
                onChange={onChange}
                value={dueAt}
                timeZone={timeZone}
                errors={errors}
              />
            </div>
            <a className="date-input__close" onClick={hideDateTime}>
              <Icon name="close" />
            </a>
          </div>
        </div>

        {allowRepeat && this.renderRepeatableFields()}
      </Fragment>
    )
  }

  renderRepeatableFields() {
    const { count, end, errors, frequency, interval, isSubmitting, onChange, timeZone, until } = this.props
    return (
      <Fragment>
        <div className="form__row">
          <InputGroup
            className="input input--half"
            name="frequency"
            label={t('repeat', { scope: 'tasks.form' })}
            component="select"
            onChange={onChange}
            value={frequency}
            disabled={isSubmitting}
            errors={errors}
          >
            <option value="none">{t('none', { scope: 'tasks.form.frequency' })}</option>
            <option value="daily">{t('daily', { scope: 'tasks.form.frequency' })}</option>
            <option value="weekly">{t('weekly', { scope: 'tasks.form.frequency' })}</option>
            <option value="monthly">{t('monthly', { scope: 'tasks.form.frequency' })}</option>
            <option value="yearly">{t('yearly', { scope: 'tasks.form.frequency' })}</option>
          </InputGroup>

          {frequency !== 'none' && (
            <div className="input input--half input--inline">
              <Label inputName="interval" text={t('every', { scope: 'tasks.form' })} />
              <Input
                className="interval"
                id="interval"
                name="interval"
                type="number"
                min="1"
                max="999"
                onChange={onChange}
                value={interval}
              />
              <Label
                inputName="interval"
                text={t(frequency, { scope: 'tasks.form.interval', count: parseInt(interval) })}
              />
            </div>
          )}
        </div>

        {frequency !== 'none' && (
          <div className="form__row">
            <InputGroup
              className="input input--half"
              name="end"
              label={t('end_repeat', { scope: 'tasks.form' })}
              component="select"
              onChange={onChange}
              value={end}
            >
              <option value="never">{t('never', { scope: 'tasks.form.end' })}</option>
              <option value="date">{t('date', { scope: 'tasks.form.end' })}</option>
              <option value="count">{t('count', { scope: 'tasks.form.end' })}</option>
            </InputGroup>

            {end === 'date' && (
              <div className="input input--half input--inline">
                <Label inputName="until" text={t('until', { scope: 'tasks.form' })} />
                <div className="date-input">
                  <div className="date-input__input">
                    <DateTimeInput
                      className="input input--half input--unlabelled"
                      id="until"
                      name="until"
                      onChange={onChange}
                      timeZone={timeZone}
                      value={until}
                      errors={errors}
                      ensureVisibility
                      withTime={false}
                    />
                  </div>
                </div>
              </div>
            )}

            {end === 'count' && (
              <div className="input input--half input--inline">
                <Input
                  id="count"
                  name="count"
                  type="number"
                  min="1"
                  max="9999"
                  onChange={onChange}
                  value={count}
                />

                <Label inputName="count" text={t('count', { scope: 'tasks.form', count: parseInt(count) })} />
              </div>
            )}
          </div>
        )}
      </Fragment>
    )
  }

  render() {
    return !this.props.dueAt ? this.renderAddDateLink() : this.renderDatefields()
  }
}

RepeatableFormFields.propTypes = {
  allowRepeat: PropTypes.bool,
  count: PropTypes.string,
  dueAt: PropTypes.string,
  end: PropTypes.string,
  ensureDatePickerVisibility: PropTypes.bool,
  errors: PropTypes.object,
  frequency: PropTypes.string,
  hideDateTime: PropTypes.func,
  interval: PropTypes.string,
  isSubmitting: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  showDateTime: PropTypes.func,
  timeZone: PropTypes.string,
  until: PropTypes.string,
}

RepeatableFormFields.defaultProps = {
  allowRepeat: true,
  count: '1',
  dueAt: null,
  end: 'never',
  ensureDatePickerVisibility: false,
  errors: {},
  frequency: 'none',
  hideDateTime: null,
  interval: '1',
  isSubmitting: false,
  showDateTime: null,
  timeZone: 'Etc/UTC',
  until: '',
}

export default RepeatableFormFields
