export const SAVE_DRAFT = 'SAVE_DRAFT'
export const CLEAR_DRAFT = 'CLEAR_DRAFT'

const saveDraft = ({ id, body }) => {
  return {
    type: SAVE_DRAFT,
    id,
    body,
  }
}

const clearDraft = (id) => {
  return {
    type: CLEAR_DRAFT,
    id,
  }
}

export { saveDraft, clearDraft }
