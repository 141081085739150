import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { updateQueryString } from 'Utilities/window';

import LocaleSelect from 'Components/Forms/LocaleSelect';

class LocaleChanger extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { locale: props.locale };

    this.handleLocaleChange = this.handleLocaleChange.bind(this);
  }

  handleLocaleChange(locale) {
    window.location.href = updateQueryString('locale', locale);
  }

  render() {
    return (
      <LocaleSelect
        availableLocales={this.props.availableLocales}
        displayBorder={false}
        name="locale-select"
        onChange={this.handleLocaleChange}
        value={this.state.locale}
      />
    );
  }
}

LocaleChanger.propTypes = {
  availableLocales: PropTypes.array.isRequired,
  locale: PropTypes.string.isRequired,
};

export default LocaleChanger;
