import React from 'react';
import { connect } from 'react-redux';
import handleAddRecommendation from './handleAddRecommendation';
import RecommendationInput from './../RecommendationInput';
import {
  setRecommendations,
  setEditingRecommendationIndex,
  setIsProcessing,
} from 'Actions/recommendationsBlock';

const AddRecommendationDropdown = ({
  setRecommendations,
  setEditingRecommendationIndex,
  editingRecommendationIndex,
  carePlanUuid,
  setIsProcessing,
}) => {
  // editingRecommendationIndex of -1 represents this input text box being open
  if (editingRecommendationIndex !== -1) return null;

  return (
    <RecommendationInput
      handleCancel={() => setEditingRecommendationIndex(null)}
      handleSave={(textContent, setErrors) =>
        handleAddRecommendation(
          carePlanUuid,
          textContent,
          setRecommendations,
          setEditingRecommendationIndex,
          setErrors,
          setIsProcessing
        )
      }
    />
  );
};

const mapStateToProps = ({
  recommendationsBlock: {
    editingRecommendationIndex,
    userDetails: { carePlanUuid },
  },
}) => {
  return { editingRecommendationIndex, carePlanUuid };
};

const mapDispatchToProps = (dispatch) => ({
  setRecommendations: (recommendations) => dispatch(setRecommendations(recommendations)),
  setEditingRecommendationIndex: (index) => dispatch(setEditingRecommendationIndex(index)),
  setIsProcessing: (isProcessing) => dispatch(setIsProcessing(isProcessing)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddRecommendationDropdown);
