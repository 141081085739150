import Api from './Api';

class FormResponse extends Api {
  constructor(carePlanId, formId, id, currentUrl) {
    super();

    this.carePlanId = carePlanId;
    this.formId = formId;
    this.id = id;
    this.currentUrl = encodeURI(currentUrl);
  }

  get url() {
    return `/care_plans/${this.carePlanId}/forms/${this.formId}/form_responses?redirect_url=${this.currentUrl}?status=success`;
  }
}

export default FormResponse;
