import {
  SET_IS_TOGGLING_DAYS,
  SET_RANGE_END_DATE,
  SET_RENDERED_DAYS,
  SET_WEEK_ORDER,
  SET_CHART_MAXIMUM_DATE,
  SET_CHART_DATA,
  SET_CHART_ERROR,
  SET_MONITOR_TYPE,
  SET_PAGE_STATE,
} from 'Actions/dashboardComparisonChart';

const initialState = {
  pageState: 'loading',
  isTogglingDays: false,
  rangeEndDate: null,
  weekOrder: [],
  chartData: {},
  chartError: '',
  chartMaximumDate: null,
  monitorType: null,
  renderedDays: {
    mon: true,
    tue: true,
    wed: true,
    thu: true,
    fri: true,
    sat: true,
    sun: true,
  },
};

const dashboardComparisonChart = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_TOGGLING_DAYS:
      return {
        ...state,
        isTogglingDays: action.isTogglingDays,
      };
    case SET_RANGE_END_DATE:
      return {
        ...state,
        rangeEndDate: action.rangeEndDate,
      };
    case SET_RENDERED_DAYS:
      return {
        ...state,
        renderedDays: action.renderedDays,
      };
    case SET_WEEK_ORDER:
      return {
        ...state,
        weekOrder: action.weekOrder,
      };
    case SET_CHART_MAXIMUM_DATE:
      return {
        ...state,
        chartMaximumDate: action.chartMaximumDate,
      };
    case SET_CHART_DATA:
      return {
        ...state,
        chartData: action.chartData,
      };
    case SET_CHART_ERROR:
      return {
        ...state,
        chartError: action.chartError,
      };
    case SET_MONITOR_TYPE:
      return {
        ...state,
        monitorType: action.monitorType,
      };
    case SET_PAGE_STATE:
      return {
        ...state,
        pageState: action.pageState,
      };
    default:
      return state;
  }
};

export default dashboardComparisonChart;
