const LEGEND_COLORS_MAP = {
  veryLow: '#feb8b4',
  low: '#fe8882',
  medium: '#ed4c42',
  high: '#bf3e36',
  veryHigh: '#7b423f',
  didNotOccur: '#DDDDDD',
  noData: '#FFF',
};

const calculateFill = (value) => {
  if (value === null) {
    return LEGEND_COLORS_MAP.didNotOccur;
  } else if (value === 0) {
    return LEGEND_COLORS_MAP.noData;
  } else if (value < 3) {
    return LEGEND_COLORS_MAP.veryLow;
  } else if (value < 5) {
    return LEGEND_COLORS_MAP.low;
  } else if (value < 7) {
    return LEGEND_COLORS_MAP.medium;
  } else if (value < 9) {
    return LEGEND_COLORS_MAP.high;
  } else if (value >= 9) {
    return LEGEND_COLORS_MAP.veryHigh;
  }

  return '';
};

export { calculateFill as default, LEGEND_COLORS_MAP as legendMap };
