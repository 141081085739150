import React from 'react';
import { connect } from 'react-redux';

import {
  setActivityModalActivityProperties,
  setActivityModalResourcesDropdownIsOpen,
  setActivityModalResourcesDropdownSearchInput,
} from 'Actions/activityModal';

export const handleResourceSelection = (
  resource,
  activityProperties,
  setActivityModalActivityProperties,
  setActivityModalResourcesDropdownIsOpen,
  setActivityModalResourcesDropdownSearchInput
) => {
  const resources = activityProperties.resources ? activityProperties.resources : [];
  resources.push({ ...resource, selected: true });

  setActivityModalActivityProperties({ ...activityProperties, resources });
  setActivityModalResourcesDropdownIsOpen(false);
  setActivityModalResourcesDropdownSearchInput('');
};

export const getAddressFields = (addressFields) => {
  const { address, city, country_code, postal_code, region } = addressFields;

  if (!address && !city && !country_code && !postal_code && !region) {
    return null;
  }

  const allFields = [address, city, country_code, postal_code, region];
  let addressString = '';
  allFields.forEach((field) => {
    addressString = field ? `${addressString}, ${field}` : addressString;
  });

  // remove ', ' from the first part of the string
  return addressString.substring(2);
};

const getTags = (tagsList) => {
  if (!tagsList || tagsList.length <= 0) {
    return null;
  }

  const tags = tagsList.map(({ label, value }) => {
    return (
      <div
        key={`resource-tag-${value}`}
        className="resources_dropdown__resources_list__item__tags_container__tag"
      >
        {label}
      </div>
    );
  });

  return <div className="resources_dropdown__resources_list__item__tags_container">{tags}</div>;
};

const ResourceItem = ({
  resource,
  activityProperties,
  setActivityModalActivityProperties,
  setActivityModalResourcesDropdownIsOpen,
  setActivityModalResourcesDropdownSearchInput,
}) => {
  const { address, id, name, preferredPhoneNumber, referralLink, referralLinkLabel, tags, url } = resource;

  let phone;
  if (preferredPhoneNumber) {
    phone = preferredPhoneNumber.phone;
  }

  const addressFields = getAddressFields(address);

  const onClick = () => {
    handleResourceSelection(
      resource,
      activityProperties,
      setActivityModalActivityProperties,
      setActivityModalResourcesDropdownIsOpen,
      setActivityModalResourcesDropdownSearchInput
    );
  };

  const handleKeyDown = (evt) => {
    if (evt.key === ' ') {
      evt.preventDefault();
      onClick();
    }
    return;
  };

  return (
    <li
      key={`resource-${id}`}
      className="resources_dropdown__resources_list__item"
      onClick={onClick}
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      {getTags(tags)}
      <div className="resources_dropdown__resources_list__item__name">{name}</div>
      {addressFields && (
        <div className="resources_dropdown__resources_list__item__address">{addressFields}</div>
      )}
      {phone && <div className="resources_dropdown__resources_list__item__phone">{phone}</div>}
      {url && <a className="resources_dropdown__resources_list__item__url">{url}</a>}
      {referralLink && (
        <a className="resources_dropdown__resources_list__item__url">
          {referralLinkLabel ? referralLink : referralLinkLabel}
        </a>
      )}
    </li>
  );
};

const mapStateToProps = ({ activityModal }) => {
  const { activityProperties } = activityModal;

  return { activityProperties };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalActivityProperties = (dispatch) => {
    return (activityProperties) => {
      dispatch(setActivityModalActivityProperties(activityProperties));
    };
  };

  const dispatchSetActivityModalResourcesDropdownIsOpen = (dispatch) => {
    return (isOpen) => {
      dispatch(setActivityModalResourcesDropdownIsOpen(isOpen));
    };
  };

  const dispatchSetActivityModalResourcesDropdownSearchInput = (dispatch) => {
    return (searchInput) => {
      dispatch(setActivityModalResourcesDropdownSearchInput(searchInput));
    };
  };

  return {
    setActivityModalActivityProperties: dispatchSetActivityModalActivityProperties(dispatch),
    setActivityModalResourcesDropdownIsOpen: dispatchSetActivityModalResourcesDropdownIsOpen(dispatch),
    setActivityModalResourcesDropdownSearchInput:
      dispatchSetActivityModalResourcesDropdownSearchInput(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResourceItem);
