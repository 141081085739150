import React from 'react';
import { connect } from 'react-redux';
import Icon from 'Components/Base/Icon';
import { t } from 'i18n';

import { setIsListTruncated } from 'Actions/recommendationsBlock';

const i18nOpts = { scope: 'blocks.recommendations' };

const TruncationToggler = ({ isListTruncated, setIsListTruncated, recommendations, truncatedCount }) => {
  if (truncatedCount >= recommendations.length) return null;

  return (
    <div className="recommendation__truncate-toggler__container">
      <div className="recommendation__truncate-toggler__button">
        <span
          onClick={() => setIsListTruncated(!isListTruncated)}
          className="recommendation__truncate-toggler__text"
        >
          {isListTruncated ? (
            <>
              <Icon name="chevron-right_alt" />
              {t('show_older', i18nOpts)}
            </>
          ) : (
            <>
              <Icon name="chevron-up" />
              {t('hide_older', i18nOpts)}
            </>
          )}
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = ({ recommendationsBlock: { recommendations, isListTruncated, truncatedCount } }) => {
  return { recommendations, isListTruncated, truncatedCount };
};

const mapDispatchToProps = (dispatch) => ({
  setIsListTruncated: (isListTruncated) => dispatch(setIsListTruncated(isListTruncated)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TruncationToggler);
