import React from 'react';
import PropTypes from 'prop-types';

const SwitchToggle = ({ className, onChange, leftOptionName, rightOptionName, toggledRight }) => {
  const classes = className ? `switch_toggle ${className}` : 'switch_toggle';

  return (
    <label
      tabIndex={0}
      className={classes}
      onKeyPress={(e) => {
        // trigger action when component in focus
        onChange(e);
      }}
    >
      {leftOptionName && <div className="switch_toggle__left_option">{leftOptionName}</div>}
      <div className="switch_toggle__positioning_container">
        <input
          className="switch_toggle__positioning_container__controller"
          type="checkbox"
          onChange={onChange}
          checked={toggledRight}
        />
        <span className="switch_toggle__positioning_container__slider" />
      </div>
      {rightOptionName && <div className="switch_toggle__right_option">{rightOptionName}</div>}
    </label>
  );
};

SwitchToggle.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  leftOptionName: PropTypes.string,
  rightOptionName: PropTypes.string,
  toggledRight: PropTypes.bool,
};

export default SwitchToggle;
