import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';
import AddRecommendationDropdown from './AddRecommendationDropdown';
import DotsLoader from 'Components/Base/DotsLoader';
import fetchDataAndUserDetails from './fetchDataAndUserDetails';
import RecommendationsList from './RecommendationsList';
import { setRecommendations, setUserDetails } from 'Actions/recommendationsBlock';

const i18nOpts = { scope: 'blocks.recommendations' };

export const getCarePlanIdFromPath = (path) => {
  // NOTE: this limits it to the dashboard
  return path.split('/care_plans/').pop().split('/dashboard').shift();
};

const RecommendationsContainer = ({ setRecommendations, setUserDetails }) => {
  const [pageState, setPageState] = useState('loading');

  useEffect(() => {
    const carePlanUuid = getCarePlanIdFromPath(window.location.pathname);
    fetchDataAndUserDetails(carePlanUuid, setRecommendations, setUserDetails, setPageState);
  }, []);

  switch (pageState) {
    case 'success':
      return (
        <>
          <AddRecommendationDropdown />
          <RecommendationsList />
        </>
      );
    case 'loading':
      return <DotsLoader />;
    default:
      return <div className="recommendation__api-error-text">{t('fetch_error', i18nOpts)}</div>;
  }
};

const mapDispatchToProps = (dispatch) => ({
  setRecommendations: (recommendations) => dispatch(setRecommendations(recommendations)),
  setUserDetails: (userDetails) => dispatch(setUserDetails(userDetails)),
});

export default connect(null, mapDispatchToProps)(RecommendationsContainer);
