import dataApiRequestHandler from 'Components/Recommendations/api/dataApiRequestHandler';
import { t } from 'i18n';

const i18nOpts = { scope: 'blocks.recommendations' };

export const handleAddRecommendation = (
  makeApiRequest = dataApiRequestHandler,
  translate = t,
  translationOptions = i18nOpts
) => {
  return async (
    carePlanUuid,
    textContent,
    setRecommendations,
    setEditingRecommendationIndex,
    setErrors,
    setIsProcessing
  ) => {
    setIsProcessing(true);

    // Validate text field not empty
    if (textContent.length <= 0) {
      setErrors({ recommendationInput: translate('required', translationOptions) });
      setIsProcessing(false);
      return;
    }

    const response = await makeApiRequest(carePlanUuid, 'POST', { textContent });

    setIsProcessing(false);

    if (response.content_type === 'ERROR') {
      setErrors({ recommendationInput: translate('save_error', translationOptions) });
      return;
    }

    setEditingRecommendationIndex(null);
    setRecommendations(response.data.recommendations);
  };
};

export default handleAddRecommendation(dataApiRequestHandler, t, i18nOpts);
