import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import Address from './Address'

const Addresses = ({ addresses, edit }) => (
  <div className="patient-details__section">
    <hr />
    <h4 className="header text-medium">{t('header', { scope: 'patient.show.addresses' })}</h4>
    <div className="patient-details__additional-details">
      {addresses.map((a) => (
        <Address key={a.id} edit={edit.bind(null, a.id)} editable {...a} />
      ))}
    </div>
  </div>
)

Addresses.propTypes = {
  addresses: PropTypes.array,
  edit: PropTypes.func.isRequired,
}

Addresses.defaultProps = {
  addresses: [],
}

export default Addresses
