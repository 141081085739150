import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Button, ModalFooter } from 'Components/Base'
import InputGroup from 'Components/Forms/InputGroup'

const i18nOpts = { scope: 'team.new_invitation' }

const NewInvitation = ({ email, isSubmitting, errors, onChange, onSubmit, onClose }) => (
  <Fragment>
    <div className="ReactModal__ContentBody-text">
      <div className="form--inline">
        <InputGroup
          component="input"
          className="input input--flex input--fixed-hint"
          label={t('label', i18nOpts)}
          hint={t('hint', i18nOpts)}
          errors={errors}
          name="invitation.email"
          onChange={onChange}
          value={email}
          readonly={isSubmitting}
          required
          type="email"
        />
      </div>
    </div>

    <ModalFooter
      renderRight={() => (
        <Fragment>
          <Button
            text={t('cancel', i18nOpts)}
            onClick={onClose}
            className="btn btn--secondary"
            disabled={isSubmitting}
          />
          <Button
            text={t('send_invitation', i18nOpts)}
            disabled={isSubmitting || !email}
            onClick={onSubmit}
          />
        </Fragment>
      )}
    />
  </Fragment>
)

NewInvitation.propTypes = {
  email: PropTypes.string.isRequired,
  errors: PropTypes.object,
  isSubmitting: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

NewInvitation.defaultProps = {
  errors: {},
  isSubmitting: false,
}

export default NewInvitation
