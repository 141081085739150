import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'i18n';

import { Modal } from 'Components/Base/Modal';
import ApiSuccessMessage from './ApiSuccessMessage';
import ApiErrorMessage from './ApiErrorMessage';
import ActionButtonsSection from './ActionButtonsSection';
import LegalCopy from './LegalCopy';
import PersonalDetails from './PersonalDetails';
import StatusWarning from './StatusWarning';

import { setIsTargetSignupModalOpen } from 'Actions/carePlanPlaceInvitationsPage';

const i18nOpts = {
  scope: 'care_plan_place_invitations.signups_container.target_signup_modal',
};

const getModalTitle = (type) => {
  let title = 'title';

  switch (type) {
    case 'CarePlanPlaceInvitation':
      title += '_hcp';
      break;

    case 'Patient':
      title += '_patient';
      break;

    case 'AccessRequest':
      title += '_caregiver';
      break;

    default:
      title += '_caregiver';
      break;
  }

  return title;
};

export const getModalHeader = ({ entityType, placeName, caregiverName, patientName }) => {
  let header = 'header';
  let title = '';
  switch (entityType) {
    case 'AccessRequest':
      header += '_caregiver';
      title = t(header, { ...i18nOpts, caregiverName, patientName });
      break;
    case 'Patient':
      header += '_patient';
      title = t(header, { ...i18nOpts, patientName });
      break;
    default:
      //CarePlanPlaceInvitation
      header += '_hcp';
      title = t(header, { ...i18nOpts, placeName });
  }

  return title;
};

const TargetSignupModal = ({ targetSignupModal, setIsTargetSignupModalOpen }) => {
  const { apiError, isOpen, target } = targetSignupModal;

  const modalStatus = targetSignupModal.status;

  const {
    carePlanTitle,
    caregiverEmail,
    caregiverName,
    patientName,
    placeName,
    resolvedAt,
    resolvedBy,
    status,
    submitted,
    entityType,
    invitedBy,
  } = target;
  return (
    <>
      <Modal
        isOpen={isOpen}
        closeModal={() => {
          setIsTargetSignupModalOpen(false);
        }}
        onClose={() => {
          setIsTargetSignupModalOpen(false);
        }}
        title={t(getModalTitle(entityType), i18nOpts)}
      >
        <h3 className="signup_modal__main_content__title">
          {getModalHeader({ entityType, placeName, caregiverName, patientName })}
        </h3>
        <PersonalDetails
          caregiverName={caregiverName}
          caregiverEmail={caregiverEmail}
          patientName={patientName}
          carePlanTitle={carePlanTitle}
          submitted={submitted}
          entityType={entityType}
          invitedBy={invitedBy}
        />
        <StatusWarning status={status} resolvedAt={resolvedAt} resolvedBy={resolvedBy} />
        <LegalCopy
          caregiverName={caregiverName}
          patientName={patientName}
          placeName={placeName}
          status={status}
          entityType={entityType}
          invitedBy={invitedBy}
        />
        <ApiSuccessMessage modalStatus={modalStatus} />
        <ApiErrorMessage modalStatus={modalStatus} apiError={apiError} />
        <ActionButtonsSection />
      </Modal>
    </>
  );
};

const mapStateToProps = ({ carePlanPlaceInvitationsPage }) => {
  const { targetSignupModal } = carePlanPlaceInvitationsPage;

  return {
    targetSignupModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetIsTargetSignupModalOpen = (dispatch) => {
    return (isOpen) => {
      dispatch(setIsTargetSignupModalOpen(isOpen));
    };
  };

  return {
    setIsTargetSignupModalOpen: dispatchSetIsTargetSignupModalOpen(dispatch),
  };
};

TargetSignupModal.propTypes = {
  setIsTargetSignupModalOpen: PropTypes.func,
  targetSignupModal: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(TargetSignupModal);
