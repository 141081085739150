import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Button, ModalFooter, DeferredImage } from 'Components/Base'
import { ItemPill, ItemPillText, ItemPillActions, ItemPillLargeAction } from 'Components/Base/ItemPill'

import banner from 'modify_invitation_resend.svg'
import location from 'location.png'

const i18nOpts = { scope: 'team.manage_invitation' }

const ActionSelectPlace = ({
  member: { name, address, imageEntitlementToken },
  onClose,
  onRemoveInvite,
  onResend,
}) => {
  return (
    <Fragment>
      <div className="ReactModal__ContentBanner ReactModal__ContentBanner--simple">
        <div className="ReactModal__ContentBanner-text">{t('team.manage_invitation.place.header')}</div>

        <img src={banner} className="ReactModal__ContentBanner-image" />
      </div>

      <div
        className="ReactModal__ContentBody-text"
        dangerouslySetInnerHTML={{ __html: t('team.manage_invitation.place.body') }}
      />

      <ItemPill flat large stretch multiline isolated>
        <div className="place-invitation__details">
          <DeferredImage
            alt={name}
            className="new-contact__avatar avatar"
            imageEntitlementToken={imageEntitlementToken}
            fallbackImage={location}
          />
          <ItemPillText text={name} subtext={address} truncate={false} />
        </div>
        <ItemPillActions>
          <ItemPillLargeAction iconName="resend" label={t('resend', i18nOpts)} onClick={onResend} />
          <ItemPillLargeAction
            iconName="minus-circle"
            label={t('remove', i18nOpts)}
            onClick={onRemoveInvite}
          />
        </ItemPillActions>
      </ItemPill>

      <ModalFooter
        renderRight={() => (
          <Button text={t('close', i18nOpts)} className="btn btn--secondary" onClick={onClose} />
        )}
      />
    </Fragment>
  )
}

ActionSelectPlace.propTypes = {
  member: PropTypes.shape({
    id: PropTypes.number,
    address: PropTypes.string,
    name: PropTypes.string,
    imageEntitlementToken: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onRemoveInvite: PropTypes.func.isRequired,
  onResend: PropTypes.func.isRequired,
}

export default ActionSelectPlace
