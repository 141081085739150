import React from 'react'
import { t } from 'i18n'

import InputGroup from 'Components/Forms/InputGroup'
import DateTimeInput from 'Components/Forms/DateTimeInput'
import Label from 'Components/Forms/Label'
import Submit from 'Components/Forms/Submit'
import { endRepeats, endRepeatsForTemplate, frequencyTypes, frequencies } from 'Utilities/repeated_options'

const i18nOpts = { scope: 'care_plan_creators.check_ins.form' }

const CheckInForm = ({
  checkInTemplates,
  daysOffset,
  errors,
  dueAt,
  until,
  count,
  editing,
  timeZone,
  frequencyType,
  frequency,
  endRepeat,
  interval,
  onChange,
  onSubmit,
  isTemplate,
  selectedTemplateId,
  timeOffset,
  visibleToAll
}) => (
  <form className="form" onSubmit={onSubmit} noValidate>
    <InputGroup
      component="select"
      className="input input--one-third"
      name="selectedTemplateId"
      label={t('check_in_type', i18nOpts)}
      onChange={onChange}
      value={selectedTemplateId}
      disabled={editing}
      required
    >
      {checkInTemplates.map((option) => {
        return (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        )
      })}
    </InputGroup>

    <InputGroup
      component="select"
      className="input input--one-third"
      name="visibleToAll"
      label={t('visibility', i18nOpts)}
      onChange={onChange}
      value={visibleToAll}
      disabled={editing}
      required
    >
      <option key="visibleToEveryone" value={true}>
        {t('everyone', i18nOpts)}
      </option>
      <option key="healthTeamOnly" value={false}>
        {t('health_team_only', i18nOpts)}
      </option>
    </InputGroup>

    <div className="form__row row-justify-start">
      <InputGroup
        component="select"
        className="input input--one-third"
        name="frequencyType"
        label={t('recurring', i18nOpts)}
        onChange={onChange}
        value={frequencyType}
        errors={{ frequencyType: errors['frequency_type'] }}
        disabled={editing}
        required
      >
        {frequencyTypes.map((option, idx) => {
          return (
            <option key={idx} value={option.id}>
              {option.name}
            </option>
          )
        })}
      </InputGroup>

      {isTemplate && frequencyType && frequencyType !== 'once' ? (
        <div>
          <div className="input days-offset">
            <InputGroup
              component="input"
              className="input input--one-third"
              name="daysOffset"
              label={t('days_offset', i18nOpts)}
              onChange={onChange}
              value={String(daysOffset)}
              errors={{ daysOffset: errors['days_offset'] }}
              type="number"
              min="1"
              max="999"
              required
            />
            <div className="trail-text">
              {t('daily', { scope: 'tasks.form.interval', count: parseInt(daysOffset) })}{' '}
              {t('days_offset_text', i18nOpts)}
            </div>
          </div>

          <div className="input due-at">
            <div className="date-input">
              <div className="date-input__input">
                <InputGroup
                  component="datetime"
                  id="timeOffset"
                  name="timeOffset"
                  label={t('due_at', i18nOpts)}
                  onChange={onChange}
                  value={timeOffset}
                  timeZone={timeZone}
                  withDate={false}
                  errors={{ timeOffset: errors['time_offset'] }}
                  required
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {!isTemplate && frequencyType && frequencyType !== 'once' ? (
        <div className="input due-at">
          <Label inputName="dueAt" text={t('due_at', i18nOpts)} />
          <div className="date-input">
            <div className="date-input__input">
              <DateTimeInput id="dueAt" name="dueAt" onChange={onChange} value={dueAt} timeZone={timeZone} />
            </div>
          </div>
        </div>
      ) : null}
    </div>

    {frequencyType === 'recurring' ? (
      <div>
        <div className="form__row row-justify-start">
          <InputGroup
            component="select"
            className="input input--one-third"
            name="frequency"
            onChange={onChange}
            value={frequency}
            errors={errors}
            label={t('repeats', i18nOpts)}
          >
            {frequencies.map((option, idx) => {
              return (
                <option key={idx} value={option.id}>
                  {option.name}
                </option>
              )
            })}
          </InputGroup>

          {frequency && (
            <div>
              <InputGroup
                component="input"
                className="input input--one-third interval"
                name="interval"
                label={t('every', i18nOpts)}
                onChange={onChange}
                value={String(interval)}
                errors={errors}
                type="number"
                min="1"
                max="999"
                required
              />
              <div className="trail-text">
                {t(frequency, { scope: 'tasks.form.interval', count: parseInt(interval) })}
              </div>
            </div>
          )}
        </div>

        <div className="form__row row-justify-start">
          <InputGroup
            component="select"
            className="input input--one-third"
            name="endRepeat"
            onChange={onChange}
            errors={errors}
            value={endRepeat}
            label={t('end_repeat', i18nOpts)}
          >
            {(isTemplate ? endRepeatsForTemplate : endRepeats).map((option, idx) => {
              return (
                <option key={idx} value={option.id}>
                  {option.name}
                </option>
              )
            })}
          </InputGroup>

          {endRepeat === 'times' ? (
            <div>
              <InputGroup
                component="input"
                className="input input--one-third count"
                name="count"
                label={t('after', i18nOpts)}
                onChange={onChange}
                errors={errors}
                value={count}
                type="number"
                min="1"
                max="999"
                required
              />
              <div className="trail-text">{t('count.other', i18nOpts)}</div>
            </div>
          ) : null}
          {endRepeat === 'on_a_day' ? (
            <InputGroup
              className="input input--one-third until"
              label={t('end_date', i18nOpts)}
              name="until"
              onChange={onChange}
              value={until}
              timeZone={timeZone}
              component="date"
              errors={errors}
              required
            />
          ) : null}
        </div>
      </div>
    ) : null}

    <div className="submit-check-in">
      <Submit value={t('submit', i18nOpts)} />
    </div>
  </form>
)

CheckInForm.defaultProps = {
  checkInTemplates: [],
  count: '',
  daysOffset: '',
  dueAt: '',
  editing: false,
  endRepeat: '',
  errors: {},
  frequency: '',
  frequencyType: 'once',
  interval: '',
  selectedTemplateId: '',
  timeOffset: '',
  timeZone: '',
  until: '',
}

export default CheckInForm
