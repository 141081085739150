import React from 'react'
import PropTypes from 'prop-types'

import RadioButtonListItem from 'Components/Base/RadioButtonListItem'

const RadioButtonList = ({ header, onClick, options, selectedOptionId }) => (
  <div className="radio-button-list">
    <div className="radio-button-list__header">{header}</div>

    <div className="radio-button-list__body">
      {options.map((option) => (
        <RadioButtonListItem
          key={option.id}
          isSelected={selectedOptionId === option.id}
          onClick={onClick.bind(null, option.id)}
          name={option.name}
        />
      ))}
    </div>
  </div>
)

RadioButtonList.propTypes = {
  header: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  selectedOptionId: PropTypes.number,
}

RadioButtonList.defaultProps = {
  options: [],
  selectedOptionId: null,
}

export default RadioButtonList
