import Api from './Api'

class PrintLog extends Api {
  constructor() {
    super()
  }

  create(url) {
    return super.create({ url })
  }

  get url() {
    return `/print_logs`
  }
}

export default PrintLog
