const sliceButtons = (buttonArray, currentPageNumber, totalPageCount, buttonCount) => {
  let beginSlice = currentPageNumber - Math.floor(buttonCount / 2) - 1;
  let endSlice = currentPageNumber + Math.floor(buttonCount / 2);
  let amountToShift = 0 - beginSlice;

  if (beginSlice < 0) {
    beginSlice += amountToShift;
    endSlice += amountToShift;
  } else if (endSlice > totalPageCount) {
    amountToShift = endSlice - totalPageCount;
    beginSlice -= amountToShift;
    endSlice -= amountToShift;
  }

  return [...buttonArray].slice(beginSlice, endSlice);
};

const pageNumberButtonArray = (totalPageCount, buttonCount, currentPageNumber) => {
  let pageNumberButtonArray = [];
  for (let pageNumber = 1; pageNumber < totalPageCount + 1; pageNumber++) {
    pageNumberButtonArray.push(pageNumber);
  }

  if (totalPageCount > buttonCount) {
    pageNumberButtonArray = sliceButtons(
      pageNumberButtonArray,
      currentPageNumber,
      totalPageCount,
      buttonCount
    );
  }
  return pageNumberButtonArray;
};

const getTotalPageCount = (totalItemCount, maxItemCountPerPage) =>
  Math.ceil(totalItemCount / maxItemCountPerPage);

export { sliceButtons, pageNumberButtonArray, getTotalPageCount };
