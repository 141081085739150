import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import InputGroup from 'Components/Forms/InputGroup'
import { Icon } from 'Components/Base'

const i18nOpts = { scope: 'care_plan_creators.check_in_templates.questions' }

class PainScaleQuestionOption extends Component {
  constructor(props) {
    super(props)

    const { questionOption } = props

    this.handleChange = this.handleChange.bind(this)

    this.state = {
      id: questionOption.id,
      text: questionOption.data.text,
      description: questionOption.data.description || '',
      response: questionOption.data.response || '',
      index: questionOption.id || questionOption.data.index,
      order: questionOption.data.order,
      score: questionOption.data.score,
      iconName: questionOption.data.iconName,
      colorCode: questionOption.data.colorCode,
    }
  }

  handleChange({ target: { name, value } }) {
    const currentState = this.state
    currentState[name] = value
    this.setState(currentState, () => this.props.updateOptions(this.state))
  }

  render() {
    const { text, description, response, score, iconName, colorCode } = this.state
    const { isActive, maxDesc, maxTitle, renderConditionalResponse } = this.props
    return (
      <li>
        <div className="option-input-wrapper">
          <div className="option-index">{score}</div>

          <div className="option-inputs">
            <div>
              <InputGroup
                component="input"
                name="text"
                placeholder={t('placeholders.pain_scale_text', i18nOpts)}
                value={text}
                onChange={this.handleChange}
                required
                maxLength={maxTitle}
                className="option-text"
                type="text"
                readonly={isActive}
              />
              <span className="character-count">{maxTitle - text.length}</span>
            </div>
            <div>
              <InputGroup
                component="input"
                name="description"
                placeholder={t('placeholders.pain_scale_description', i18nOpts)}
                value={description}
                onChange={this.handleChange}
                maxLength={maxDesc}
                className="option-description"
                type="text"
                readonly={isActive}
              />
              <span className="character-count">{maxDesc - (description || '').length}</span>
            </div>
            {renderConditionalResponse(response, this.handleChange)}
          </div>

          <div className="option-icon" style={{ color: colorCode }}>
            <Icon name={iconName} className="icon icon--large" />
          </div>
        </div>
      </li>
    )
  }
}

PainScaleQuestionOption.propTypes = {
  isActive: PropTypes.bool,
  maxDesc: PropTypes.number,
  maxTitle: PropTypes.number,
  questionOption: PropTypes.object,
  renderConditionalResponse: PropTypes.func.isRequired,
  updateOptions: PropTypes.func,
}

PainScaleQuestionOption.defaultProps = {
  isActive: false,
  maxDesc: 60,
  maxTitle: 25,
  questionOption: {},
  updateOptions: null,
}

export default PainScaleQuestionOption
