import React, { useState, useEffect } from 'react';

import useComponentDidMount from 'Hooks/useComponentDidMount';

const FormField = ({
  value,
  label,
  formErrors,
  name,
  onChange,
  handleValidation,
  className,
  readOnly = false,
}) => {
  const isComponentMounted = useComponentDidMount();

  const [fieldFocused, setFieldFocused] = useState(false);
  const [fieldDirty, setFieldDirty] = useState(false);

  useEffect(() => {
    if (!isComponentMounted || !fieldDirty) {
      return;
    }
    handleValidation && handleValidation(value);
  }, [fieldFocused]);

  const error = formErrors?.[name];

  return (
    <div
      className={`${className} ${error && 'input--errors'}`}
    >
      <label htmlFor={name}>
        {label}
      </label>
      <input
        onChange={({ target: {value} })  => {
          if (fieldDirty) {
            handleValidation && handleValidation(value);
          }
          onChange(value);
        }}
        name={name}
        value={value}
        id={name}
        onBlur={() => {
          setFieldDirty(true);
          setFieldFocused(false);
        }}
        onFocus={() => {
          setFieldFocused(true);
        }}
        readOnly={readOnly}
      />
      {error && <div className="error">{error}</div>}
    </div>
  );
};

export default FormField;
