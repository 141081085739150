import React from 'react';

const DotsLoader = () => {
  return (
    <div className="dots_loader">
      <div className="dots_loader__elements" />
    </div>
  );
};

export default DotsLoader;
