import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';

const Label = ({ text, inputName, required, handleClick, optional }) => {
  const i18nOpts = { scope: 'forms.label' };
  return (
    <label className={required ? 'required' : ''} htmlFor={inputName} onClick={handleClick}>
      {required ? <abbr title="required">*</abbr> : ''} <span dangerouslySetInnerHTML={{ __html: text }} />
      {optional && <span className="optional text-primary-lighter"> {t('optional', i18nOpts)}</span>}
    </label>
  );
};

Label.propTypes = {
  handleClick: PropTypes.func,
  inputName: PropTypes.string.isRequired,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  optional: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default Label;
