import React from 'react'
import PropTypes from 'prop-types'

import Icon from 'Components/Base/Icon'
import { ItemPill, ItemPillIcon, ItemPillText, ItemPillActions } from 'Components/Base/ItemPill'

const CarePlan = ({ title }) => (
  <div className="inbox-menu__careplan">
    <ItemPill selected stretch>
      <ItemPillIcon iconName="care-plan-outline" />
      <ItemPillText text={title} />
      <ItemPillActions>
        <div className="item-pill__action" data-test="checkbox-icon">
          <Icon name="circle-selected" className="icon icon--medium icon--green" />
        </div>
      </ItemPillActions>
    </ItemPill>
  </div>
)

CarePlan.propTypes = {
  title: PropTypes.string.isRequired,
}

export default CarePlan
