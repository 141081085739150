import React from 'react';
import { t } from 'i18n';
import { Icon } from 'Components/Base';

const i18nOpts = { scope: 'care_plan_action_plan_page.activities_container.check_ins_card.check_in' };

const CheckIn = ({ name, completed, formattedDueAt }) => (
  <li className="order">
    <div className="order__section text-truncate">
      <div>
        <strong>{name || t('unnamed_check_in', i18nOpts)}</strong>
      </div>
      <span>{formattedDueAt && t('due_at_statement', { ...i18nOpts, dueDate: formattedDueAt })}</span>
    </div>
    <div className="order__section">
      {completed ? (
        <span className="order--complete">
          <Icon name="check_alt3" className="order__section--check-icon" />
          {t('completed', i18nOpts)}
        </span>
      ) : (
        <span className="order--due">{t('not_completed', i18nOpts)}</span>
      )}
    </div>
  </li>
);

export default CheckIn;
