import formatUnixTime from './../formatUnixTime';

export const parseDataValues = (parseTime) => (value, key) => {
  if (key === 'time') {
    return parseTime(value);
  }

  // for array of [min, max] data
  if (typeof value === 'object') {
    if (value[0] && value[1]) {
      const rangeStart = (Math.round(value[0] * 10) / 10).toFixed(1);
      const rangeEnd = (Math.round(value[1] * 10) / 10).toFixed(1);

      return `${rangeStart} ~ ${rangeEnd}`;
    } else {
      return null;
    }
  }

  // for strings and numbers
  const number = Number(value);

  if (isNaN(number)) {
    return value;
  }

  const roundedNumber = (Math.round(number * 10) / 10).toFixed(1);

  return roundedNumber;
};

export default parseDataValues(formatUnixTime);
