import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { PlaceSelectionForm, ConsentForm } from './'
import { Modal } from 'Components/Base/Modal'

import CarePlanPlaceInvitation from 'Api/CarePlanPlaceInvitation'

const i18nOpts = { scope: 'team.health_team.invitation_modal' }

class InvitationModalContainer extends Component {
  constructor(props) {
    super(props)

    this.handleClose = this.handleClose.bind(this)
    this.handleSelectPlaceId = this.handleSelectPlaceId.bind(this)
    this.handlePlaceSelectionSubmit = this.handlePlaceSelectionSubmit.bind(this)
    this.handleBack = this.handleBack.bind(this)
    this.handlePermissionChange = this.handlePermissionChange.bind(this)
    this.handleConsentSubmit = this.handleConsentSubmit.bind(this)

    this.state = {
      selectedPlaceIds: [],
      formStep: 'placeSelection',
      permission: false,
      errors: {},
    }
  }

  get params() {
    return {
      carePlanUuid: this.props.carePlanUuid,
      placeIds: this.state.selectedPlaceIds,
      permission: this.state.permission,
    }
  }

  handleClose() {
    this.setState(
      {
        permission: false,
        formStep: 'placeSelection',
        selectedPlaceIds: [],
        errors: {},
      },
      this.props.onClose
    )
  }

  handleSelectPlaceId(placeId) {
    this.setState(({ selectedPlaceIds }) => ({
      selectedPlaceIds: selectedPlaceIds.includes(placeId)
        ? selectedPlaceIds.filter((selected) => selected !== placeId)
        : [placeId, ...selectedPlaceIds],
    }))
  }

  handlePlaceSelectionSubmit(evt) {
    evt.preventDefault()

    this.setState({ formStep: 'consent' })
  }

  handleBack() {
    this.setState({ formStep: 'placeSelection', permission: false })
  }

  handlePermissionChange() {
    this.setState((prevState) => ({ permission: !prevState.permission }))
  }

  handleConsentSubmit(evt) {
    evt.preventDefault()

    return new CarePlanPlaceInvitation().batchCreate(this.params).then((res) => {
      if (res.ok) {
        res.json().then(({ carePlanPlaceInvitations }) => this.props.onSuccess(carePlanPlaceInvitations))

        this.setState({
          selectedPlaceIds: [],
          formStep: 'placeSelection',
          permission: false,
          errors: {},
        })

        window.flash_messages.addMessage(t('success', i18nOpts))
      } else if (res.status === 422) {
        return res.json().then(({ errors }) => this.setState({ errors }))
      }
    })
  }

  renderPlaceSelectionForm() {
    return (
      <PlaceSelectionForm
        places={this.props.places}
        selectedPlaceIds={this.state.selectedPlaceIds}
        onClose={this.handleClose}
        onSelectPlace={this.handleSelectPlaceId}
        onSubmit={this.handlePlaceSelectionSubmit}
      />
    )
  }

  renderConsentForm() {
    return (
      <ConsentForm
        places={this.props.places.filter((place) => this.state.selectedPlaceIds.includes(place.id))}
        permission={this.state.permission}
        errors={this.state.errors}
        onBack={this.handleBack}
        onChange={this.handlePermissionChange}
        onClose={this.handleClose}
        onSubmit={this.handleConsentSubmit}
      />
    )
  }

  render() {
    return (
      <Modal isOpen={this.props.modalIsOpen} title={t('header', i18nOpts)} closeModal={this.handleClose}>
        {this.state.formStep === 'placeSelection'
          ? this.renderPlaceSelectionForm()
          : this.renderConsentForm()}
      </Modal>
    )
  }
}

InvitationModalContainer.propTypes = {
  carePlanUuid: PropTypes.string.isRequired,
  modalIsOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  places: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      imageEntitlementToken: PropTypes.string,
      name: PropTypes.string,
      address: PropTypes.string,
      phoneNumber: PropTypes.string,
    })
  ),
}

InvitationModalContainer.defaultProps = {
  modalIsOpen: false,
  places: [],
}

export default InvitationModalContainer
