import { Resource } from '../Api'

class LibraryDocumentCopies extends Resource {
  constructor(carePlanId) {
    super()

    this.carePlanId = carePlanId
  }

  update(selectedLibraryDocuments) {
    return super.update({ selected_library_documents: selectedLibraryDocuments })
  }

  get url() {
    return `/intake/${this.carePlanId}/library_document_copies`
  }
}

export default LibraryDocumentCopies
