import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import Team from './Team'
import UserForm from './UserForm'

import { fetchSupportTeam, createSupportTeamEntry } from 'Actions/supportTeam'
import {
  getPrimaryCaregivers,
  getCaregivers,
  getSupport,
  getPrimaryCaregiversErrors,
  getCaregiversErrors,
  getSupportErrors,
  getPatient,
} from 'Selectors/supportTeam'

const i18nOpts = { scope: 'team.active_team' }

class SupportTeamContainer extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.fetchSupportTeam(this.props.carePlanId)
  }

  render() {
    const { carePlanId } = this.props

    const patientName = this.props.patient.length ? this.props.patient[0].firstName : null
    const fullName = this.props.patient.length ? this.props.patient[0].name : null

    return (
      <Fragment>
        <Team
          title={t('primary_caregiver', i18nOpts)}
          members={this.props.primaryCaregivers}
          name={fullName}
          id="support_team_contacts"
          useReduxState
          carePlanId={carePlanId}
          form={
            <UserForm
              carePlanId={carePlanId}
              patientName={patientName}
              errors={this.props.primaryCaregiversErrors}
              createSupportTeamEntry={this.props.createSupportTeamEntry}
              needsPatientPermission={this.props.needsPatientPermission}
              role={'primary_caregiver'}
            />
          }
          role="primary_caregiver"
        />

        <Team
          title={t('caregiver', i18nOpts)}
          members={this.props.caregivers}
          name={fullName}
          id="support_team_members"
          useReduxState
          carePlanId={carePlanId}
          form={
            <UserForm
              carePlanId={carePlanId}
              errors={this.props.caregiversErrors}
              patientName={patientName}
              createSupportTeamEntry={this.props.createSupportTeamEntry}
              needsPatientPermission={false}
              role={'caregiver'}
            />
          }
          role="caregiver"
        />

        <Team
          title={t('support', i18nOpts)}
          members={this.props.support}
          name={fullName}
          id="extended_support_team"
          useReduxState
          carePlanId={carePlanId}
          form={
            <UserForm
              carePlanId={carePlanId}
              errors={this.props.supportErrors}
              patientName={patientName}
              createSupportTeamEntry={this.props.createSupportTeamEntry}
              needsPatientPermission={false}
              role={'support'}
            />
          }
          role="support"
        />
      </Fragment>
    )
  }
}

SupportTeamContainer.propTypes = {
  carePlanId: PropTypes.string.isRequired,
  caregivers: PropTypes.array,
  caregiversErrors: PropTypes.object,
  createSupportTeamEntry: PropTypes.func.isRequired,
  fetchSupportTeam: PropTypes.func.isRequired,
  needsPatientPermission: PropTypes.bool,
  patient: PropTypes.array,
  primaryCaregivers: PropTypes.array,
  primaryCaregiversErrors: PropTypes.object,
  support: PropTypes.array,
  supportErrors: PropTypes.object,
}

SupportTeamContainer.defaultProps = {
  caregivers: [],
  caregiversErrors: {},
  needsPatientPermission: false,
  patient: [],
  primaryCaregivers: [],
  primaryCaregiversErrors: {},
  support: [],
  supportErrors: {},
}

const mapStateToProps = (state) => {
  return {
    primaryCaregivers: getPrimaryCaregivers(state),
    caregivers: getCaregivers(state),
    support: getSupport(state),
    patient: getPatient(state),
    primaryCaregiversErrors: getPrimaryCaregiversErrors(state),
    caregiversErrors: getCaregiversErrors(state),
    supportErrors: getSupportErrors(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSupportTeam: (carePlanId) => dispatch(fetchSupportTeam(carePlanId)),
    createSupportTeamEntry: (carePlanId, email, relationship, role, permission) =>
      dispatch(createSupportTeamEntry(carePlanId, email, relationship, role, permission)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SupportTeamContainer)
