import getAssignmentsApi from './getAssignmentsApi';

export const handleAssignmentsSetupWrapper = (getAssignmentsApiProvider = getAssignmentsApi) => {
  return async (
    setActivityModalAssignmentsDropdownState,
    setActivityModalAssignmentsDropdownAssignments,
    setActivityModalAssignmentsDropdownAssignmentsToDisplay,
    carePlanId
  ) => {
    setActivityModalAssignmentsDropdownState('LOADING');

    try {
      const { responseType, assignments } = await getAssignmentsApiProvider(carePlanId);

      if (responseType === 'DATA') {
        setActivityModalAssignmentsDropdownAssignments(assignments);
        setActivityModalAssignmentsDropdownAssignmentsToDisplay(assignments);
        setActivityModalAssignmentsDropdownState('DEFAULT');
      } else {
        throw 'Missing data';
      }
    } catch (error) {
      setActivityModalAssignmentsDropdownState('ERROR');
      console.error(error);
    }
  };
};

export default handleAssignmentsSetupWrapper(getAssignmentsApi);
