import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

const i18nOpts = { scope: 'team.unaffiliated_contact_team' }

import Avatar from 'PulseBackports/Avatar';
import MostResponsibleProviderChip from '../MostResponsibleProviderChip';

class UnaffiliatedContact extends PureComponent {
  constructor(props) {
    super(props)
  }

  get owner() {
    return this.props.member.owner || this.props.member.pendingOwner
  }

  render() {
    const { path, relationship, status, mostResponsibleProvider } = this.props.member

    return (
      <li className="support-team-member support-team-member--unaffiliated-team-contact">
        <a href={path} className="support-team-member__avatar">
          <Avatar
            src={this.owner.photo}
            fullName={this.owner.name}
            size={2.5}
          />
        </a>

        <div className="support-team-member__details">
          <div className="support-team-member__section support-team-member__section--name">
            <h3 className="support-team-member__name">
              <a href={path}>{status === 'accepted' ? this.owner.name : t('pending', i18nOpts)}</a>
            </h3>

            {mostResponsibleProvider && <MostResponsibleProviderChip />}

            <span className="support-team-member__role">
              {t(status !== 'accepted' ? 'other' : relationship, i18nOpts)}
            </span>
          </div>
        </div>
      </li>
    )
  }
}

UnaffiliatedContact.propTypes = {
  member: PropTypes.shape({
    invitation: PropTypes.object,
    path: PropTypes.string.isRequired,
    photo: PropTypes.string,
    relationship: PropTypes.string,
    status: PropTypes.string.isRequired,
    owner: PropTypes.object,
    pendingOwner: PropTypes.object,
  }).isRequired,
}

UnaffiliatedContact.defaultProps = {}

export default UnaffiliatedContact
