import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import DisplayText from './DisplayText';
import NextButtons from './NextButtons';
import PageNumberButtons from './PageNumberButtons';
import PreviousButtons from './PreviousButtons';

const PaginationButtons = ({
  currentPageNumber,
  itemName,
  maxItemCountPerPage,
  onPageChange,
  totalItemCount
}) => {
  const totalPageCount = Math.ceil(totalItemCount / maxItemCountPerPage);
  const hasMoreThanOnePage = totalItemCount > maxItemCountPerPage;

  return (
    <Fragment>
      {( totalItemCount > 0 && hasMoreThanOnePage ) &&
        <nav className='pagination'>
          <PreviousButtons
            onPageChange={onPageChange}
            currentPageNumber={currentPageNumber}
          />

          <PageNumberButtons
            onPageChange={onPageChange}
            currentPageNumber={currentPageNumber}
            totalPageCount={totalPageCount}
          />

          <NextButtons
            onPageChange={onPageChange}
            currentPageNumber={currentPageNumber}
            totalPageCount={totalPageCount}
          />
        </nav>
      }

      <DisplayText
        hasMoreThanOnePage={hasMoreThanOnePage}
        itemName={itemName}
        currentPageNumber={currentPageNumber}
        maxItemCountPerPage={maxItemCountPerPage}
        totalItemCount={totalItemCount}
      />
    </Fragment>
  );
};

PaginationButtons.propTypes = {
  currentPageNumber: PropTypes.number.isRequired,
  itemName: PropTypes.string.isRequired,
  maxItemCountPerPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  totalItemCount: PropTypes.number.isRequired,
};

export default PaginationButtons;
