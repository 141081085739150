import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Button, ModalFooter } from 'Components/Base'
import Submit from 'Components/Forms/Submit'
import LinkResourceButton from 'Components/Tasks/Index/LinkResourceButton'

const i18nOpts = { scope: 'care_plan_creators.tasks.index' }

const Footer = ({ toggleModal, displayLinkResourcesButton, ...rest }) => (
  <ModalFooter
    renderLeft={() =>
      displayLinkResourcesButton ? <LinkResourceButton action={rest.onToggleLinkResources} /> : null
    }
    renderRight={() => (
      <Fragment>
        <Button text={t('cancel', i18nOpts)} className="btn btn--secondary" onClick={toggleModal} />
        <Submit value={t('submit', i18nOpts)} />
      </Fragment>
    )}
  />
)

Footer.propTypes = {
  displayLinkResourcesButton: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
}

Footer.defaultProps = { displayLinkResourcesButton: false }

export default Footer
