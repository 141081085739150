import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import TextareaAutosize from 'react-autosize-textarea'
import { Icon, Tooltip } from 'Components/Base'

import { t } from 'i18n'

class MessageInput extends PureComponent {
  constructor(props) {
    super(props)

    this.handleResize = this.handleResize.bind(this)
    this.handleKeypad = this.handleKeypad.bind(this)
    this.handleMessageScroll = this.handleMessageScroll.bind(this)
  }

  componentDidMount() {
    const initialHeight = 20
    this.resize(initialHeight)
    document.addEventListener('touchstart', this.handleKeypad)
  }

  componentWillUnmount() {
    document.removeEventListener('touchstart', this.handleKeypad)
  }

  handleResize({ target }) {
    const { height: formHeight } = target.getBoundingClientRect()
    this.resize(formHeight)
  }

  resize(formHeight) {
    const formMargin = 28
    const messagesContainer = document.querySelector('.messages__container')
    const scrollContainer = document.querySelector('.messages__scroll')

    if (messagesContainer && scrollContainer) {
      const containerStyles = window.getComputedStyle(messagesContainer, null)
      const messagesHeight = containerStyles.getPropertyValue('content').replace(/"/g, '')

      const newHeight = messagesHeight.replace(')', ` - ${formHeight + formMargin}px)`)

      messagesContainer.style.height = newHeight
      scrollContainer.style['min-height'] = newHeight
      this.handleMessageScroll()
    }
  }

  handleKeypad({ target }) {
    const form = document.querySelector('.message-form')
    if (form && !form.contains(target)) {
      document.activeElement.blur()
    }
  }

  handleMessageScroll() {
    const messagesContainer = document.querySelector('.messages__container')
    const scrollContainer = document.querySelector('.messages__scroll')

    messagesContainer.scroll({ top: scrollContainer.scrollHeight, behavior: 'smooth' })
  }

  render() {
    const {
      name,
      onChange,
      onEmojiClick,
      openPicker,
      placeholder,
      setEmojiPickerMenuRef,
      setToggleRef,
      showEmojiPicker,
      value,
    } = this.props

    return (
      <div className="message-input">
        <Tooltip text={t('emoji', { scope: 'inbox.conversation.messages' })} position="right" tabIndex="0">
          <div ref={setToggleRef} className="message-input__emoji" onClick={openPicker}>
            <Icon name="emoji-alt" />
          </div>
        </Tooltip>

        <TextareaAutosize
          className="message-input__textarea"
          name={name}
          onChange={onChange}
          onResize={this.handleResize}
          placeholder={placeholder}
          tabIndex="0"
          value={value}
          onClick={this.handleMessageScroll}
        />

        {showEmojiPicker && (
          <span className="emoji-picker-menu" ref={setEmojiPickerMenuRef}>
            <EmojiPicker disableDiversityPicker onEmojiClick={onEmojiClick} />
          </span>
        )}
      </div>
    )
  }
}

MessageInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onEmojiClick: PropTypes.func.isRequired,
  openPicker: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  setEmojiPickerMenuRef: PropTypes.func.isRequired,
  setToggleRef: PropTypes.func.isRequired,
  showEmojiPicker: PropTypes.bool.isRequired,
  value: PropTypes.string,
}

MessageInput.defaultProps = {
  placeholder: '',
  value: '',
}

export default MessageInput
