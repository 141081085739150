import React from 'react';
import Icon from 'Components/Base/Icon';

const RenderNoDataPoint = ({ x, y, index, data, width }) => {
  if (data[index].value !== '') {
    return null;
  }

  return (
    <g>
      <foreignObject x={x + width / 2.5} y={y} height={25} width={25} className="icon close">
        <Icon className="no-data" name="close" />
      </foreignObject>
    </g>
  );
};

export default RenderNoDataPoint;
