import React from 'react';
import ProviderWrapper from '../Base/ProviderWrapper';
import EditButtonModal from '../Activities/EditButtonModal';
import {
  activityType as activityTypeDefinitions,
  activityKind as activityKindDefinitions,
} from 'Components/Activities/ActivityModal/modalContentStateDefinitions';
import ActivityModal from 'Components/Activities/ActivityModal';

export const EditModalContainer = ({
  carePlanId,
  task,
  address,
  resources,
  assignments,
  assignmentOptions,
  timeZone,
  locale,
  canDeleteActivity,
  canEditDescription,
  canEditResources,
  canDeleteResources,
  editBtnStyle,
  editBtnText
}) => {
  const { MEDICAL, GENERIC_HEALTH } = activityKindDefinitions;

  const activityProperties = {
    description: task.text,
    dueAt: task.dueAt,
    addressFields: address,
    resources,
    assignments,
    virtualVisitLink: task.url,
  };

  return (
    <ProviderWrapper>
      <>
        <EditButtonModal
          carePlanId={carePlanId}
          activityId={task.id}
          activityType={activityTypeDefinitions.TASK}
          activityProperties={activityProperties}
          activityKind={task.kind === 'medical' ? MEDICAL : GENERIC_HEALTH}
          assignmentOptions={assignmentOptions}
          timeZone={timeZone}
          locale={locale}
          canDeleteActivity={canDeleteActivity}
          canEditFields={{
            canEditDescription,
            canEditResources,
            canDeleteResources,
          }}
          editBtnStyle={editBtnStyle}
          editBtnText={editBtnText}
        />
        <ActivityModal
          onUpdate={() => {
            window.location.reload();
          }}
        />
      </>
    </ProviderWrapper>
  );
};

export default EditModalContainer;
