import React, { Component } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

import NumericInputQuestionOption from './NumericInputQuestionOption'

class NumericInputQuestion extends Component {
  constructor(props) {
    super(props)
    this.renderOption = this.renderOption.bind(this)
    this.buildQuestionOptions = this.buildQuestionOptions.bind(this)
    this.updateOptions = this.updateOptions.bind(this)

    let questionOptions = props.checkInQuestionOptions || []

    if (_.isEmpty(questionOptions)) {
      questionOptions = this.buildQuestionOptions()
      this.props.onChange({ target: { name: 'checkInQuestionOptions', value: questionOptions } })
    }

    this.state = { questionOptions }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ questionOptions: nextProps.checkInQuestionOptions })
  }

  buildQuestionOptions() {
    return [
      {
        id: '',
        type: 'NumericBasedOption',
        data: {
          index: 1,
          order: 1,
          units: '',
          upperLimit: undefined,
          lowerLimit: undefined,
        },
      },
    ]
  }

  updateOptions(optionValues) {
    const { questionOptions } = this.state
    const questionOption = questionOptions[0]

    Object.assign(questionOption.data, optionValues)
    this.setState({ questionOptions })
    this.props.onChange({ target: { name: 'checkInQuestionOptions', value: questionOptions } })
  }

  renderOption(questionOption, index) {
    return (
      <NumericInputQuestionOption
        isActive={this.props.isActive}
        key={index}
        questionOption={questionOption}
        updateOptions={this.updateOptions}
        errors={this.props.errors}
      />
    )
  }

  render() {
    const { questionOptions } = this.state
    return questionOptions.map(this.renderOption)
  }
}

NumericInputQuestion.propTypes = {
  checkInQuestionOptions: PropTypes.array,
  errors: PropTypes.object,
  isActive: PropTypes.bool,
  onChange: PropTypes.func,
}

NumericInputQuestion.defaultProps = {
  checkInQuestionOptions: [],
  errors: {},
  isActive: false,
  onChange: null,
}

export default NumericInputQuestion
