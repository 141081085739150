import moment from 'ct-moment';

// Takes the time coordinate and creates a datetime on 1970-01-01 with that timestamp.
// This is to allow the chart to plot the data point using time as the X variable.

// See following for issue comments on plotting time series in recharts:
// https://github.com/recharts/recharts/issues/1028#issuecomment-350578263
// https://github.com/recharts/recharts/issues/956#issuecomment-339279600

// 1970-01-01 is an arbitrary date with no relevance to this component. It was chosen
// because it is the first date of unix time.

export const transformData = (timeProvider) => (data, monitorType, timeRange) => {
  if (!data.length) return [];

  const transformedData = data.map((dataPoint) => ({
    ...dataPoint,
    time: timeProvider(`1970-01-01 ${dataPoint.time}:00`).unix(),
  }));

  // If continuous_glucose_monitor, there is no corresponding glucose value for the 24:00 segment
  // all of the data points around 24:00 get put into the 00:00 time block. This code duplicates
  // the Y values at 00:00 into the 24:00 segment (because 00:00 and 24:00 are the same thing) so
  // that the 23:00 segment has something to draw a line to.

  if (monitorType === 'continuous_glucose_monitor' && timeRange !== '1day') {
    const BEGIN_TIMESTAMP = 28800; // 1970-01-01 at 00:00:00
    const END_TIMESTAMP = 115200; // 1970-01-02 at 00:00:00

    const first_data_point = transformedData.find((dataPoint) => dataPoint['time'] === BEGIN_TIMESTAMP);
    transformedData.push({ ...first_data_point, time: END_TIMESTAMP });
  }

  return transformedData;
};

export default transformData(moment);
