import getTasksApi from './getTasksApi';

export const handleTasksSetupWrapper = (getTasksApiProvider = getTasksApi) => {
  return async (setTasksCardState, setTasksCardTasksList, carePlanId) => {
    setTasksCardState('LOADING');

    try {
      const { responseType, tasks, repeatedTasks } = await getTasksApiProvider(carePlanId);

      if (responseType === 'DATA') {
        setTasksCardTasksList({ tasks, repeatedTasks });
        setTasksCardState('MAIN_CONTENT');
      } else {
        throw 'Could not get tasks.';
      }
    } catch (error) {
      setTasksCardState('ERROR');
      console.error(error);
    }
  };
};

export default handleTasksSetupWrapper(getTasksApi);
