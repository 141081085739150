import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import Card from 'Components/Base/Card';
import Highlight from 'Components/Base/Highlight';

const i18nOpts = { scope: 'dashboard.device' };

const ErrorDisplay = ({ title, status }) => {
  let message;
  switch (status) {
    case 'ERROR_UNAUTHORIZED':
      message = t('unauthorized', i18nOpts);
      break;
    case 'ERROR_CONNECTION_LOST':
      message = t('connection_lost', i18nOpts);
      break;
    case 'ERROR_PUMP_SETTINGS_EMPTY':
      message = t('pump_settings_empty', i18nOpts);
      break;
    default:
      message = t('data_not_available', i18nOpts);
      break;
  }
  return (
    <Card title={title}>
      <Highlight type="warning" message={message} />
    </Card>
  );
};

ErrorDisplay.propTypes = {
  status: PropTypes.string,
  title: PropTypes.string.isRequired,
};

ErrorDisplay.defaultProps = {
  status: 'ERROR',
};

export default ErrorDisplay;
