import { t } from 'i18n';
import React, { useEffect, useRef, useState } from 'react';

import ActionButton from 'Components/Base/ActionButton';
import Icon from 'Components/Base/Icon';
import QRCode from 'Components/Base/QRCode';
import logo from 'logos/logo-small.svg';

const i18nOpts = { scope: 'base.invite_qr_code' };

const InviteQRCode = ({ code, path }) => {
  const qrCodeRef = useRef(null);
  const groupRef = useRef(null);

  const [templateCode, setTemplateCode] = useState('');
  const [copyButtonText, setCopyButtonText] = useState(t('copy', i18nOpts));
  const [imageCopySupported, setImageCopySupported] = useState(false);

  useEffect(() => {
    if (window?.ClipboardItem) {
      setImageCopySupported(true);
    }

    const templateInput = groupRef.current
      .closest('.invitation-modal')
      .querySelector('#place_users_invite_form_care_plan_template_code');

    if (templateInput) {
      setTemplateCode(templateInput.value);
      const handleChange = (evt) => setTemplateCode(evt.target.value);
      templateInput.addEventListener('change', handleChange);
      return () => templateInput.removeEventListener('change', handleChange);
    }
  }, []);

  const url = `${path}?code=${[code, templateCode].filter((p) => p).join('-')}`;

  const handleDownload = () => {
    try {
      const canvas = qrCodeRef?.current?.canvas?.current;
      if (canvas) {
        const img = canvas.toDataURL('image/png');
        const downloadLink = document.createElement('a');

        downloadLink.href = img;
        downloadLink.download = 'signup-careteam.png';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    } catch {
      console.error('Failed to download image!');
    }
  };

  const handleCopyToClipboard = () => {
    try {
      const canvas = qrCodeRef?.current?.canvas?.current;
      if (canvas) {
        const img = canvas.toDataURL('image/png');

        const imgBlob = async () => {
          const data = await fetch(img);
          return data.blob();
        };

        navigator.clipboard?.write([new ClipboardItem({ 'image/png': imgBlob() })]);

        setCopyButtonText(t('copied', i18nOpts));
        setTimeout(() => {
          setCopyButtonText(t('copy', i18nOpts));
        }, 3000);
      }
    } catch {
      console.error('Failed to copy image!');
    }
  };

  return (
    <div ref={groupRef} className="invite-qr-code-container">
      <QRCode id="invite-qr-code" url={url} logo={logo} ref={qrCodeRef} />
      <div className="invite-qr-code-container__btns">
        <ActionButton
          className="invite-qr-code-container__btn flat"
          onClick={handleDownload}
          content={
            <>
              <Icon name="download_2" />
              {t('download', i18nOpts)}
            </>
          }
        />
        {imageCopySupported && (
          <ActionButton
            className="invite-qr-code-container__btn flat"
            onClick={handleCopyToClipboard}
            content={
              <>
                <Icon name="copy" />
                {copyButtonText}
              </>
            }
          />
        )}
      </div>
    </div>
  );
};

export default InviteQRCode;
