import withAssignments from 'Components/Tasks/withAssignments'

import Appointment from './Appointment'
import CheckIn from './CheckIn'
import Form from './Form'
import Referral from './Referral'
import RepeatedAppointment from './RepeatedAppointment'
import RepeatedTask from './RepeatedTask'
import Task from './Task'

import Activity from './Activity'
import ActivityBody from './ActivityBody'
import ActivityContainer from './ActivityContainer'
import AppointmentDetails from './AppointmentDetails'
import AssignmentActions from './AssignmentActions'
import AssignmentsWithDueAt from './AssignmentsWithDueAt'
import CarePlanBadge from './CarePlanBadge'
import ServiceLink from './ServiceLink'
import TaskAppointmentForm from './TaskAppointmentForm'
import TaskCheckBox from './TaskCheckBox'
import TaskDetails from './TaskDetails'

export {
  Activity,
  ActivityBody,
  ActivityContainer,
  Appointment,
  AppointmentDetails,
  AssignmentActions,
  AssignmentsWithDueAt,
  CarePlanBadge,
  CheckIn,
  Form,
  ServiceLink,
  Referral,
  RepeatedAppointment,
  RepeatedTask,
  Task,
  TaskCheckBox,
  TaskDetails,
  TaskAppointmentForm,
}

export default withAssignments(ActivityContainer)
