const sortPatients = (patients, sortMethod) => {
  switch (sortMethod) {
    case 'createdAtDesc':
      return patients.sort((a, b) => (a.created_at < b.created_at ? 1 : -1));
    case 'createdAtAsc':
      return patients.sort((a, b) => (a.created_at > b.created_at ? 1 : -1));
    case 'nameAsc':
      return patients.sort((a, b) => a.last_name.localeCompare(b.last_name));
    case 'nameDesc':
      return patients.sort((a, b) => a.last_name.localeCompare(b.last_name) * -1);
    default:
      return patients;
  }
};

export default sortPatients;
