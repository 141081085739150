import { t } from 'i18n';
const i18nOpts = { scope: 'care_plan_place_invitations.index.status_input' };

const buttonText = (currentStatusInput) => {
  let buttonText;
  switch (currentStatusInput) {
    case 'all':
      buttonText = t('all', i18nOpts);
      break;
    case 'pending':
      buttonText = t('pending', i18nOpts);
      break;
    case 'accepted':
      buttonText = t('accepted', i18nOpts);
      break;
    case 'declined':
      buttonText = t('declined', i18nOpts);
      break;
    default:
      buttonText = t('all', i18nOpts);
  }

  return buttonText;
};

export default buttonText;
