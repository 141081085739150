const WORRIED_SCARED_MAP = {
  overtime: '#3399BE',
  overall: '#5CADCB',
};

const HAPPY_MAP = {
  overtime: '#ED9F0E',
  overall: '#F1B23E',
};

const MISERABLE_MAP = {
  overtime: '#2F9110',
  overall: '#59A740',
};

const ANGRY_MAP = {
  overtime: '#ED4C41',
  overall: '#F17067',
};

const calculateFill = (title, type) => {
  switch (title) {
    case 'worried_scared': {
      return WORRIED_SCARED_MAP[type];
    }

    case 'happy': {
      return HAPPY_MAP[type];
    }

    case 'miserable': {
      return MISERABLE_MAP[type];
    }

    case 'angry': {
      return ANGRY_MAP[type];
    }
    default:
      return '#FFF';
  }
};

export {
  calculateFill as default,
  WORRIED_SCARED_MAP as worriedScaredMap,
  HAPPY_MAP as happyMap,
  MISERABLE_MAP as miserableMap,
  ANGRY_MAP as angryMap,
};
