import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import InsulinPumpCard from './InsulinPumpCard';
import InsulinDosingCard from './InsulinDosingCard';
import { CarePlanContext } from 'Components/Dashboard/Dashboard';
import ProviderWrapper from 'Components/Base/ProviderWrapper';

const InsulinDataCard = ({ data, error }) => {
  const { dashboardType } = useContext(CarePlanContext);

  let dashboardComponent = null;

  if (!dashboardType) return null;

  if (error) {
    return (
      <ProviderWrapper>
        <InsulinPumpCard status={error} />
      </ProviderWrapper>
    );
  }

  const { insulinPump, pumpStatistics } = data;

  dashboardComponent =
    dashboardType === 'pump_glucometer' || dashboardType === 'pump_sensor' ? (
      <InsulinPumpCard data={insulinPump.data} pumpStatistics={pumpStatistics} status={insulinPump.status} />
    ) : (
      <InsulinDosingCard />
    );

  return <ProviderWrapper>{dashboardComponent}</ProviderWrapper>;
};

InsulinDataCard.propTypes = {
  data: PropTypes.shape({
    insulinPump: PropTypes.shape({
      data: PropTypes.object,
      status: PropTypes.string,
    }),
  }),
  error: PropTypes.string,
};

InsulinDataCard.defaultProps = {
  data: {
    insulinPump: {
      data: {},
      status: 'ERROR',
    },
    pumpStatistics: {
      data: {},
      status: 'ERROR',
    },
  },
  error: '',
};

export default InsulinDataCard;
