import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import { setActivityModalActivityProperties } from 'Actions/activityModal';

import Icon from 'Components/Base/Icon';

const i18nOpts = { scope: 'activity_modal.form_fields.resources_list' };

const ResourcesList = ({ activityProperties, setActivityModalActivityProperties, canDeleteResources }) => {
  const { resources } = activityProperties;

  if (!resources || resources.length <= 0) {
    return null;
  }

  // sort alphabetically and put "unnamed resource" at the end
  resources.sort((a, b) => {
    if (!a.name) {
      return 1;
    }
    return a.name.localeCompare(b.name);
  });

  return resources.map(resource => {
    const key = `resource-${resource.id}`;
    const recourceDescription = resource.name || t('unnamed_resource', i18nOpts);
    return canDeleteResources
      ? <button
        key={key}
        className='activity_modal__resources__resource_item btn--pill btn--pill--secondary'
        onClick={() => {
          // remove target resource from the selected list
          setActivityModalActivityProperties({
            ...activityProperties,
            resources: resources.filter((resourceToCheck) => {
              return resourceToCheck.id !== resource.id;
            }),
          });
        }}
      >
        <span className="resource_description">{recourceDescription}</span>
        <Icon className="resource_close_icon" name="close_v2" />
      </button>
      : <button
        key={key}
        className='activity_modal__resources__resource_item btn--pill btn--pill--disabled'
      >
        <span className="resource_description">{recourceDescription}</span>
      </button>;
  });
};

const mapStateToProps = ({ activityModal }) => {
  const { activityProperties, canEditFields: { canDeleteResources } } = activityModal;

  return { activityProperties, canDeleteResources };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalActivityProperties = (dispatch) => {
    return (activityProperties) => {
      dispatch(setActivityModalActivityProperties(activityProperties));
    };
  };

  return {
    setActivityModalActivityProperties: dispatchSetActivityModalActivityProperties(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResourcesList);
