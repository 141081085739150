import React from 'react';

const ActionButton = ({ className, onClick, isLoading, disabled, content }) => {
  let buttonClassName = 'buttonV2';
  if (className) {
    buttonClassName += ` ${className}`;
  }

  if (isLoading) {
    return (
      <button className={`button_loaderV2 ${buttonClassName}`}>
        <div className="button_loader__elementsV2" />
      </button>
    );
  } else {
    if (disabled) {
      buttonClassName += ' disabled';
    }

    return (
      <button className={buttonClassName} onClick={!disabled && onClick}>
        {content}
      </button>
    );
  }
};

export default ActionButton;
