import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Button, Loader, RadioButtonList, ModalFooter } from 'Components/Base'
import { Modal } from 'Components/Base/Modal'
import Submit from 'Components/Forms/Submit'

import CarePlanTemplateApi from 'Api/Place/CarePlanTemplate'

import banner from 'care_plan_template.svg'

const i18nOpts = { scope: 'patient.index.care_plan_templates_modal' }

class CarePlanTemplatesModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      carePlanTemplates: [],
      isLoading: false,
      isDisabled: true,
      selectedTemplateId: 0,
    }

    this.selectTemplate = this.selectTemplate.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    this.setState({ isLoading: true })
    return new CarePlanTemplateApi().index().then((res) => {
      if (res.ok) {
        return res.json().then(({ carePlanTemplates }) => {
          this.setState({ carePlanTemplates, isLoading: false })
        })
      } else if (res.status === 422) {
        window.flash_messages.addMessage(t('modal_failure', i18nOpts))
        this.props.closeModal()
        return res.json().then(console.error)
      }
    })
  }

  handleSubmit() {
    this.setState({ isDisabled: true })
  }

  selectTemplate(selectedTemplateId) {
    if (!this.state.selectedTemplateId || !this.state.isDisabled) {
      this.setState({ selectedTemplateId, isDisabled: false })
    }
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} closeModal={this.props.closeModal} title={t('title', i18nOpts)}>
        <div className="ReactModal__ContentBanner">
          <div className="ReactModal__ContentBanner-text">{t('content_banner', i18nOpts)}</div>

          <img src={banner} className="ReactModal__ContentBanner-image ReactModal__ContentBanner-image-hidden-sm" />
        </div>

        {this.state.isLoading ? (
          <Loader />
        ) : (
          <RadioButtonList
            header={t('list_header', i18nOpts)}
            onClick={this.selectTemplate}
            options={this.state.carePlanTemplates}
            selectedOptionId={this.state.selectedTemplateId}
          />
        )}

        <form
          className="form"
          method="get"
          onSubmit={this.handleSubmit}
          action={this.props.newCarePlanPath}
          noValidate
        >
          <input
            type="hidden"
            name="care_plan[care_plan_template_id]"
            value={this.state.selectedTemplateId}
          />

          <ModalFooter
            renderRight={() => (
              <Fragment>
                <Button
                  className="btn btn--secondary"
                  text={t('cancel', i18nOpts)}
                  onClick={this.props.closeModal}
                />
                <Submit value={t('next', i18nOpts)} name="submit" disabled={this.state.isDisabled} />
              </Fragment>
            )}
          />
        </form>
      </Modal>
    )
  }
}

CarePlanTemplatesModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  newCarePlanPath: PropTypes.string.isRequired,
}

export default CarePlanTemplatesModal
