import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import InputGroup from 'Components/Forms/InputGroup'
import { endRepeats, frequencyTypes, frequencies, frequencyTypesAppt } from 'Utilities/repeated_options'

const i18nOpts = {
  scope: 'care_plans.recurrence_input',
}

class RecurrenceInput extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      count,
      dueAt,
      endRepeat,
      errors,
      frequency,
      frequencyType,
      handleChange,
      isEdit,
      interval,
      timeZone,
      type,
      until,
    } = this.props

    return (
      <div>
        <div className="form_row">
          <InputGroup
            component="select"
            className="input input--one-third"
            name="frequencyType"
            label={t('recurring', i18nOpts)}
            onChange={handleChange}
            value={frequencyType}
            errors={errors}
            required
          >
            {(type === 'Appointment' ? frequencyTypesAppt : frequencyTypes).map((option, idx) => {
              return (
                <option key={idx} value={option.id}>
                  {option.name}
                </option>
              )
            })}
          </InputGroup>

          {frequencyType && frequencyType !== 'once' && (
            <InputGroup
              component="datetime"
              className="input input-half"
              label={
                type === 'Appointment'
                  ? isEdit
                    ? t('time', i18nOpts)
                    : t('due_at_appt', i18nOpts)
                  : t('due_at', i18nOpts)
              }
              value={dueAt}
              name="dueAt"
              onChange={handleChange}
              errors={errors}
              timeZone={timeZone}
              required
            />
          )}
        </div>

        {frequencyType === 'recurring' && (
          <div>
            <div className="form__row row-justify-start">
              <InputGroup
                component="select"
                className="input input--one-third"
                name="frequency"
                onChange={handleChange}
                value={frequency}
                errors={errors}
                label={t('repeats', i18nOpts)}
                required
              >
                {frequencies.map((option, idx) => {
                  return (
                    <option key={idx} value={option.id}>
                      {option.name}
                    </option>
                  )
                })}
              </InputGroup>

              {frequency && (
                <div>
                  <InputGroup
                    component="input"
                    className="input input--one-third interval"
                    name="interval"
                    label={t('every', i18nOpts)}
                    onChange={handleChange}
                    value={String(interval)}
                    errors={errors}
                    type="number"
                    min="1"
                    max="999"
                    required
                  />
                  <div className="trail-text">
                    {t(frequency, { scope: 'tasks.form.interval', count: parseInt(interval) })}
                  </div>
                </div>
              )}
            </div>

            <div className="form__row row-justify-start">
              <InputGroup
                component="select"
                className="input input--one-third"
                name="endRepeat"
                onChange={handleChange}
                errors={errors}
                value={endRepeat}
                label={t('end_repeat', i18nOpts)}
                required
              >
                {endRepeats.map((option, idx) => {
                  return (
                    <option key={idx} value={option.id}>
                      {option.name}
                    </option>
                  )
                })}
              </InputGroup>

              {endRepeat === 'times' && (
                <div>
                  <InputGroup
                    component="input"
                    className="input input--one-third count"
                    name="count"
                    label={t('after', i18nOpts)}
                    onChange={handleChange}
                    errors={errors}
                    value={count}
                    type="number"
                    min="1"
                    max="999"
                    required
                  />
                  <div className="trail-text">{t('count.other', i18nOpts)}</div>
                </div>
              )}
              {endRepeat === 'on_a_day' && (
                <InputGroup
                  className="input input--one-third until"
                  label={t('end_date', i18nOpts)}
                  name="until"
                  onChange={handleChange}
                  value={until}
                  timeZone={timeZone}
                  component="datetime"
                  errors={errors}
                  ensureVisibility
                  renderAbove
                  withTime={false}
                  required
                />
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
}

RecurrenceInput.propTypes = {
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dueAt: PropTypes.string,
  endRepeat: PropTypes.string,
  errors: PropTypes.object,
  frequency: PropTypes.string,
  frequencyType: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  interval: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isEdit: PropTypes.bool,
  timeZone: PropTypes.string,
  type: PropTypes.string,
  until: PropTypes.string,
}

RecurrenceInput.defaultProps = {
  count: '',
  dueAt: '',
  endRepeat: '',
  errors: {},
  frequency: '',
  frequencyType: '',
  interval: '',
  isEdit: false,
  timeZone: '',
  type: '',
  until: '',
}

export default RecurrenceInput
