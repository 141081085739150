import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'i18n';

const i18nOpts = { scope: 'care_plan_place_invitations.signups_container.target_signup_modal.row_headers' };

const SignupsDescription = ({ pageState, currentTab }) => {
  if (pageState === 'success') {
    switch (currentTab) {
      case 'CarePlanPlaceInvitation':
        return (
          <div className="signups-description signups-description--hcp">
            <div className="signups-description__profile">{t('patient', i18nOpts)}</div>
            <div className="signups-description__hospital-details">{t('health_care_provider', i18nOpts)}</div>
            <div className="signups-description__chip">{t('status', i18nOpts)}</div>
          </div>
        );
      case 'AccessRequest':
        return (
          <div className="signups-description signups-description--caregiver">
            <div className="signups-description__profile">{t('support_team_contact', i18nOpts)}</div>
            <div className="signups-description__caregiver-details">
              <div className="signups-description__caregiver-details__patient">
                {t('patient', i18nOpts)}
              </div>
              <div className="signups-description__caregiver-details__submitted">
                {t('submitted', i18nOpts)}
              </div>
              <div className="signups-description__caregiver-details__resolved">
                {t('resolved', i18nOpts)}
              </div>
            </div>
            <div className="signups-description__chip">{t('status', i18nOpts)}</div>
          </div>
        );
      case 'Patient':
        return (
          <div className="signups-description signups-description--patient">
            <div className="signups-description__profile">{t('patient', i18nOpts)}</div>
            <div className="signups-description__caregiver-details">
              <div className="signups-description__caregiver-details__submitted">
                {t('submitted', i18nOpts)}
              </div>
              <div className="signups-description__caregiver-details__resolved">
                {t('resolved', i18nOpts)}
              </div>
            </div>
            <div className="signups-description__chip">{t('status', i18nOpts)}</div>
          </div>
        );
      default:
        return null;
    }
  } else {
    return null;
  }
};

const mapStateToProps = ({ carePlanPlaceInvitationsPage }) => {
  const { pageState, currentTab } = carePlanPlaceInvitationsPage;

  return {
    pageState,
    currentTab,
  };
};

SignupsDescription.propTypes = {
  currentTab: PropTypes.string,
  pageState: PropTypes.string,
};

export default connect(mapStateToProps, null)(SignupsDescription);
