const getFormHeaderClass = (completed, isOpen) => {
  let formHeaderClass = 'form__header';

  if (completed) {
    formHeaderClass += ' form__header--can-select';
  }

  if (isOpen) {
    formHeaderClass += ' form__header--open';
  }

  return formHeaderClass;
};

export default getFormHeaderClass;
