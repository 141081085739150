import createActivityApi from './createActivityApi';

export const handleCreateActivityWrapper = (createActivityApiProvider = createActivityApi) => {
  return async (
    setActivityModalState,
    handleCloseModal,
    carePlanId,
    activityType,
    activityProperties,
    onCreate
  ) => {
    setActivityModalState('LOADING');

    try {
      const { responseType } = await createActivityApiProvider(carePlanId, activityProperties, activityType);

      if (responseType === 'DATA') {
        handleCloseModal();
        onCreate();
      } else {
        throw 'Missing data';
      }
    } catch (error) {
      setActivityModalState('ERROR');
      console.error(error);
    }
  };
};

export default handleCreateActivityWrapper(createActivityApi);
