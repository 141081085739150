import React, { useEffect, useRef, useState } from 'react';
import { t } from 'i18n';

import Patient from '../Patient';
import BlankSlate from 'Components/Base/BlankSlate';


const i18nOpts = { scope: 'patient.index' };

const BlankPatientsList = () => (
  <section className="patients">
    <BlankSlate iconName="search" body={t('empty', i18nOpts)} />
  </section>
);

const UhcpPatientList = ({ patients }) => {

  const ELEMENT_RENDER_LIMIT = 20;
  const [offset, setOffset] = useState(ELEMENT_RENDER_LIMIT)

  const lastPatientRef = useRef(null);

  const observer = useRef(
    new IntersectionObserver((entries) => {
      if(entries[0].isIntersecting) {
        setOffset((currentLimit) => currentLimit + ELEMENT_RENDER_LIMIT);
      }
    })
  );

  const renderedPatients = patients.slice(0, offset)

  // once the next page of patients has loaded, reconnect to the last element of the next page
  useEffect(() => {
    if (observer.current) {
      observer.current.disconnect();
    }

    const hasMorePatientsToRender = patients.length > offset; // condition necessary to prevent infinite loop

    if (lastPatientRef.current && hasMorePatientsToRender) {
      observer.current.observe(lastPatientRef.current)
    }
  }, [renderedPatients])

  if (!patients.length) return <BlankPatientsList />;

  return (
    <section className="patients">
      { renderedPatients.map((patient, renderIndex) => {
        const {
          id,
          name,
          photo,
          birthday,
          last_sign_in_at,
          invitations_pending,
          new_patient,
          unconfirmed_self_registrations_pending,
          medical_record_number,
          thrive_id,
          path
        } = patient;

        const isLastPatient = renderIndex === renderedPatients.length - 1;

        return (
          <div key={id} ref={isLastPatient ? lastPatientRef : null}>
            <Patient
              name={name}
              path={path}
              photo={photo}
              birthday={birthday}
              lastSignInAt={last_sign_in_at}
              invitationsPending={invitations_pending}
              newPatient={new_patient}
              selfRegCarePlan={unconfirmed_self_registrations_pending}
              medicalRecordNumber={medical_record_number}
              thriveId={thrive_id}
            />
          </div>
        );
      })
    }
    </section>
  );
};

export default UhcpPatientList;
