import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import ActionButton from 'Components/Base/ActionButton';
import Icon from 'Components/Base/Icon';
import handleDeleteActivity from './handleDeleteActivity';
import {
  setActivityModalState,
} from 'Actions/activityModal';
import {
  activityModalState as activityModalStateDefinitions,
  deleteButtonState as deleteButtonStateDefinitions,
} from '../../modalContentStateDefinitions';
import Backdrop from 'Components/Base/Backdrop';

const i18nOpts = { scope: 'activity_modal.footer.delete_button' };

const DeleteButton = ({
  setActivityModalState,
  carePlanId,
  activityId,
  activityType,
  activityModalState,
  dueAt,
}) => {

  const { DELETE, CONFIRM } = deleteButtonStateDefinitions;
  const { LOADING } = activityModalStateDefinitions;

  const [buttonState, setButtonState] = useState(DELETE);

  const handleDeleteClick = () => {
    if (buttonState === CONFIRM) {
      handleDeleteActivity({
        setActivityModalState,
        carePlanId,
        activityId,
        type: activityType,
        dueAt,
        onDelete: () => {
          window.location.href = `/care_plans/${carePlanId}`;
        },
      });
    } else {
      setButtonState(CONFIRM);
    }
  };

  const deleteButtonContent = buttonState === CONFIRM
    ? <span>{t('confirm', i18nOpts)}</span>
    : <>
      <Icon name="trash" />
      <span>{t('delete', i18nOpts)}</span>
    </>;

  const btnStateClassName = buttonState === CONFIRM ? 'action_modal__footer__delete_button--confirm' : '';

  return <>
    {buttonState === CONFIRM && <Backdrop
      transparent
      onClick={() => {
        setButtonState(DELETE);
      }}
    />}
    <ActionButton
      className={`button-subtle action_modal__footer__delete_button ${btnStateClassName}`}
      isLoading={activityModalState === LOADING}
      disabled={activityModalState === LOADING}
      onClick={handleDeleteClick}
      content={deleteButtonContent}
    />
  </>
};

const mapStateToProps = ({ activityModal }) => {
  const {
    carePlanId,
    activityId,
    activityType,
    activityModalState,
    activityProperties: { dueAt },
  } = activityModal;

  return {
    carePlanId,
    activityId,
    activityType,
    activityModalState,
    dueAt,
  };
};

const mapDispatchToProps = (dispatch) => {

  const dispatchSetActivityModalState = (dispatch) => {
    return (activityModalState) => {
      dispatch(setActivityModalState(activityModalState));
    };
  };

  return {
    setActivityModalState: dispatchSetActivityModalState(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteButton);