import TeamMembershipApi from 'Api/TeamMembership'
import { fetchSupportTeam } from './supportTeam'
import { getMembers } from 'Selectors/teamInvitationsModal'

export const ADD_MEMBER = 'ADD_MEMBER'
export const DELETE_MEMBER = 'DELETE_MEMBER'
export const RESET_TEAM_INVITATIONS_MODAL_STATE = 'RESET_TEAM_INVITATIONS_MODAL_STATE'
export const SET_TEAM_INVITATIONS_MODAL_ERROR = 'SET_TEAM_INVITATIONS_MODAL_ERROR'

const addMember = (member) => ({
  type: ADD_MEMBER,
  member,
})

const deleteMember = (id) => ({
  type: DELETE_MEMBER,
  id,
})

const resetTeamInvitationsModalState = () => ({
  type: RESET_TEAM_INVITATIONS_MODAL_STATE,
})

const setTeamInvitationsModalError = () => ({
  type: SET_TEAM_INVITATIONS_MODAL_ERROR,
})

const sendInvitations = (carePlanUuid) => {
  return (dispatch, getState) => {
    const teamMembershipApi = new TeamMembershipApi(carePlanUuid)
    const invitations = getMembers(getState()).map((member) => ({
      permission: true,
      role: member.role,
      relationship: member.relationship,
      invitation_attributes: { email: member.email },
    }))

    return teamMembershipApi.batchCreate(invitations).then((res) => {
      if (res.ok) {
        return res.json().then(() => {
          return dispatch(fetchSupportTeam(carePlanUuid))
        })
      } else if (res.status === 422) {
        dispatch(setTeamInvitationsModalError())
      }
    })
  }
}

export {
  addMember,
  deleteMember,
  sendInvitations,
  resetTeamInvitationsModalState,
  setTeamInvitationsModalError,
}
