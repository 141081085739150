import Api from './Api'

class CheckInResponse extends Api {
  constructor(carePlanId, checkInId) {
    super()

    this.carePlanId = carePlanId
    this.checkInId = checkInId
  }

  get url() {
    return `/care_plans/${this.carePlanId}/check_ins/${this.checkInId}/check_in_responses`
  }
}

export default CheckInResponse
