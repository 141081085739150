import React from 'react';
import PropTypes from 'prop-types';
import Table from './Table';

const BolusSettings = ({ insulinCarbRatios, insulinSensitivityFactors, bloodGlucoseTargets }) => {
  return (
    <div className="pump-settings__bolus-container">
      <div className="pump-settings__settings-group__container">
        <div className="pump-settings__settings-group__title">{insulinCarbRatios.title}</div>
        <Table data={insulinCarbRatios} />
      </div>
      <div className="pump-settings__settings-group__container">
        <div className="pump-settings__settings-group__title">{insulinSensitivityFactors.title}</div>
        <Table data={insulinSensitivityFactors} />
      </div>
      <div className="pump-settings__settings-group__container">
        <div className="pump-settings__settings-group__title">{bloodGlucoseTargets.title}</div>
        <Table data={bloodGlucoseTargets} />
      </div>
    </div>
  );
};

BolusSettings.propTypes = {
  bloodGlucoseTargets: PropTypes.shape({
    columns: PropTypes.array,
    rows: PropTypes.array,
    title: PropTypes.string,
  }),
  insulinCarbRatios: PropTypes.shape({
    columns: PropTypes.array,
    rows: PropTypes.array,
    title: PropTypes.string,
  }),
  insulinSensitivityFactors: PropTypes.shape({
    columns: PropTypes.array,
    rows: PropTypes.array,
    title: PropTypes.string,
  }),
};

BolusSettings.defaultProps = {
  bloodGlucoseTargets: {
    columns: [],
    rows: [],
    title: '',
  },
  insulinCarbRatios: {
    columns: [],
    rows: [],
    title: '',
  },
  insulinSensitivityFactors: {
    columns: [],
    rows: [],
    title: '',
  },
};

export default BolusSettings;
