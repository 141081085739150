import { Resource } from '../Api'

class PatientHealthInformation extends Resource {
  constructor(carePlanUuid) {
    super()

    this.carePlanUuid = carePlanUuid
  }

  update({ current_medications, past_medications, immunizations, allergies, family_history, notes }) {
    return super.update({
      patient: {
        patient_health_information: {
          current_medications,
          past_medications,
          immunizations,
          allergies,
          family_history,
          notes,
        },
      },
    })
  }

  get url() {
    return `/care_plans/${this.carePlanUuid}/patient`
  }
}

export default PatientHealthInformation
