import React from 'react'
import PropTypes from 'prop-types'

import store from '../../../store'

import { setCurrentUser } from 'Actions/current-user'
import { setEventFilter } from 'Actions/filter'

import { Provider } from 'react-redux'
import WeeklyList from './WeeklyList'

class WeeklyListModule extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    store.dispatch(setCurrentUser(this.props.currentUser))
    store.dispatch(setEventFilter('all'))

    return (
      <Provider store={store}>
        <WeeklyList {...this.props} />
      </Provider>
    )
  }
}

WeeklyListModule.propTypes = {
  assignmentOptions: PropTypes.array,
  assignments: PropTypes.array,
  canEditResources: PropTypes.bool,
  carePlanId: PropTypes.string,
  currentUser: PropTypes.shape({
    uuid: PropTypes.string,
    timeZone: PropTypes.string,
  }).isRequired,
}

WeeklyListModule.defaultProps = {
  assignmentOptions: [],
  assignments: [],
  canEditResources: false,
  carePlanId: '',
}

export default WeeklyListModule
