import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import IconButton from 'Components/Base/IconButton'
import NewCarePlanMenu from 'Components/Patient/Index/NewCarePlanMenu'
import CarePlanTemplatesModal from 'Components/Patient/Index/CarePlanTemplatesModal'

const i18nOpts = { scope: 'patient.index' }

class CarePlanButtons extends Component {
  constructor(props) {
    super(props)

    this.state = {
      menuIsOpen: false,
      modalIsOpen: false,
    }

    this.openMenu = this.openMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openMenu() {
    this.setState({ menuIsOpen: true })
  }

  closeMenu() {
    this.setState({ menuIsOpen: false })
  }

  openModal() {
    this.setState({ modalIsOpen: true })
  }

  closeModal() {
    this.setState({ modalIsOpen: false, menuIsOpen: false })
  }

  renderNewCarePlanButton() {
    return this.props.templatesAreAvailable ? (
      <IconButton
        iconName="arrow-triangle-down"
        id="new-care-plan"
        isReversed
        onClick={this.openMenu}
        text={t('new_care_plan', i18nOpts)}
      />
    ) : (
      <a className="btn" id="new-care-plan" href={this.props.newCarePlanPath}>
        {t('new_care_plan', i18nOpts)}
      </a>
    )
  }

  render() {
    const { registrationIsOpen } = this.props

    return (
      <div
        className={`patients__index-buttons && ${
          registrationIsOpen && 'patients__index-buttons--registration'
        }`}
      >
        <div className="action-header__button-section">
          {registrationIsOpen && (
            <a className="btn" id="invite-patient" href="/">
              {t('self_signups', i18nOpts)}
            </a>
          )}

          {this.renderNewCarePlanButton()}
        </div>

        {this.state.menuIsOpen && (
          <NewCarePlanMenu
            closeMenu={this.closeMenu}
            newCarePlanPath={this.props.newCarePlanPath}
            onUseTemplateClick={this.openModal}
          />
        )}

        {this.state.modalIsOpen && (
          <CarePlanTemplatesModal
            closeModal={this.closeModal}
            isOpen={this.state.modalIsOpen}
            newCarePlanPath={this.props.newCarePlanPath}
          />
        )}
      </div>
    )
  }
}

CarePlanButtons.propTypes = {
  newCarePlanPath: PropTypes.string.isRequired,
  registrationIsOpen: PropTypes.bool,
  templatesAreAvailable: PropTypes.bool,
}

CarePlanButtons.defaultProps = {
  registrationIsOpen: false,
  templatesAreAvailable: false,
}

export default CarePlanButtons
