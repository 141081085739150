const addArchiveFieldToPatient = (patients, archivedPatientIds) => {
    if (!patients?.length) return patients;

    const archivedPatientIdSet = new Set(archivedPatientIds);

    return patients.map((patient) => {
        return {...patient, archived: archivedPatientIdSet.has(patient.id)};
    });
  }

export default addArchiveFieldToPatient;