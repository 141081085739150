import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';

import banner from 'mailer_full_team.png';
import { Button } from 'Components/Base';

import EmailModal from './EmailModal';
import InviteCodeModal from './InviteCodeModal';

const i18nOptsModal = { scope: 'team.modal' };

const EmailStep = (props) => {
  const { teamType, onTeamTypeSet, inviteType } = props;

  return (
    <Fragment>
      <header className="wave-header">
        <h2 className="wave-header__title">{t('build', i18nOptsModal)}</h2>
        <img src={banner} className="wave-header__banner wave-header__banner--full-team" />
      </header>

      <div className="invite-code-modal__team-type">
        <div className="invite-code-modal__team-type--description">
          <Button
            data-test="add"
            className={`${
              teamType === 'support' ? 'btn btn--keep-focus' : 'btn--secondary'
            } invite-code-modal__team-type-change-button`}
            onClick={onTeamTypeSet.bind(null, 'support')}
            text={t(`support_add_team_button`, i18nOptsModal)}
          />
          {!teamType && (
            <div className="invite-code-modal__header-subtitle invite-code-modal__header-subtitle--description">
              {t(`support_team_explanation`, i18nOptsModal)}
            </div>
          )}
        </div>

        <div className="invite-code-modal__team-type--description">
          <Button
            data-test="add"
            className={`${
              teamType === 'health' ? 'btn btn--keep-focus' : 'btn--secondary'
            } invite-code-modal__team-type-change-button`}
            onClick={onTeamTypeSet.bind(null, 'health')}
            text={t(`health_add_team_button`, i18nOptsModal)}
          />
          {!teamType && (
            <div className="invite-code-modal__header-subtitle invite-code-modal__header-subtitle--description">
              {t(`health_team_explanation`, i18nOptsModal)}
            </div>
          )}
        </div>
      </div>

      {teamType && (
        <Fragment>
          <div className="card__button-footer" />
          {inviteType === 'email' ? <EmailModal {...props} /> : <InviteCodeModal {...props} />}
        </Fragment>
      )}
    </Fragment>
  );
};

EmailStep.propTypes = {
  copied: PropTypes.bool.isRequired,
  currentId: PropTypes.string,
  email: PropTypes.string,
  invitationLink: PropTypes.string.isRequired,
  inviteType: PropTypes.string,
  members: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  onInviteTypeSet: PropTypes.func.isRequired,
  onPermission: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onTeamTypeSet: PropTypes.func.isRequired,
  permission: PropTypes.bool.isRequired,
  persistedMembers: PropTypes.array.isRequired,
  relationship: PropTypes.string,
  relationshipOptions: PropTypes.array.isRequired,
  requestAccessToken: PropTypes.string,
  startUrl: PropTypes.string.isRequired,
  teamType: PropTypes.string,
  uhcpAccessToken: PropTypes.string,
};

EmailStep.defaultProps = {
  currentId: null,
  email: '',
  inviteType: '',
  relationship: '',
  requestAccessToken: null,
  teamType: '',
  uhcpAccessToken: null,
};

export default EmailStep;
