import React from 'react';
import PropTypes from 'prop-types';

const Input = ({
  name,
  type,
  value,
  placeholder,
  required,
  onChange,
  min,
  max,
  maxLength,
  readonly,
  autocomplete,
  accept,
  onBlur,
  onFocus,
  step,
}) => {
  return (
    <input
      className={required ? 'required' : 'optional'}
      onChange={onChange}
      placeholder={placeholder ? placeholder : ''}
      type={type}
      name={name}
      value={value}
      min={min}
      max={max}
      maxLength={maxLength}
      required={required ? 'required' : false}
      aria-required={required ? true : false}
      id={name}
      accept={accept}
      readOnly={readonly}
      autoComplete={autocomplete}
      onBlur={onBlur}
      onFocus={onFocus}
      step={step}
    />
  );
};

Input.propTypes = {
  // By default autocomplete should be off to align with our caching policy
  // In some circumstances, it may be reasonable to use autocomplete but be
  // very cautious before doing this.
  accept: PropTypes.string,
  autocomplete: PropTypes.string,
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.number,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  readonly: PropTypes.bool,
  required: PropTypes.bool,
  step: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
};

Input.defaultProps = {
  accept: '',
  autocomplete: 'off',
  max: '',
  min: '',
  value: '',
  required: false,
  readonly: false,
  step: '',
};

export default Input;
