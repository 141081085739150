import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import PhoneNumber from './PhoneNumber'

const PhoneNumbers = ({ phoneNumbers, edit }) => (
  <div className="patient-details__section">
    <hr />
    <h4 className="header text-medium">{t('header', { scope: 'patient.show.phone_numbers' })}</h4>
    <div className="patient-details__additional-details">
      {phoneNumbers.map((p) => {
        return <PhoneNumber key={p.id} edit={edit.bind(null, p.id)} editable {...p} />
      })}
    </div>
  </div>
)

PhoneNumbers.propTypes = {
  edit: PropTypes.func.isRequired,
  phoneNumbers: PropTypes.array,
}

PhoneNumbers.defaultProps = {
  phoneNumbers: [],
}

export default PhoneNumbers
