import { request } from './handleRequest';

const fetchSignupsApi = async () => {
  try {
    const response = await request('/care_plan_place_invitations/signups.json');
    const status = response.status;
    const data = await response.json();
    return { status, data };
  } catch (error) {
    return { status: 500, error };
  }
};

export default fetchSignupsApi;
