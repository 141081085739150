import buildBloodGlucoseTextFromBlock from './buildBloodGlucoseTextFromBlock';
import buildInsulinPumpTextFromBlock from './buildInsulinPumpTextFromBlock';
import buildMDITextFromBlock from './buildMDITextFromBlock';

const buildTextFromBlocks = (blockType, data) => {
  switch (blockType) {
    case 'GLUCOSE_LEVELS':
      return buildBloodGlucoseTextFromBlock(data);

    case 'INSULIN_PUMP':
      return buildInsulinPumpTextFromBlock(data);

    case 'MDI':
      return buildMDITextFromBlock(data);

    default:
      return '';
  }
};

export default buildTextFromBlocks;
