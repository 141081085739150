import Api from 'Api/Api'

class LibraryActivityTemplate extends Api {
  constructor(libraryActivityTemplateId, templateType) {
    super()

    this.id = libraryActivityTemplateId
    this.templateType = templateType
  }

  get url() {
    return this.templateType === 'Task' ? `/library_task_templates` : `/library_appointment_templates`
  }
}

export default LibraryActivityTemplate
