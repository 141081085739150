import Api from './../Api'

class MedicalDocument extends Api {
  constructor(carePlanId, id = null) {
    super()

    this.carePlanId = carePlanId
    this.id = id
  }

  create(medicalDocumentFormData) {
    return super.create(medicalDocumentFormData)
  }

  update(medicalDocumentFormData) {
    return super.update(medicalDocumentFormData)
  }

  destroy() {
    return super.destroy({})
  }

  get url() {
    return `/care_plans/${this.carePlanId}/medical_documents`
  }
}

export default MedicalDocument
