import React from 'react'
import PropTypes from 'prop-types'

import Icon from 'Components/Base/Icon'

const SectionFooterCheckbox = ({ checked, text, onChange }) => (
  <div className="card__button-footer-checkbox" onClick={onChange}>
    <Icon name={checked ? 'checkbox-selected' : 'checkbox-unselected'} className="icon icon--medium" />

    <label className="card__button-footer-checkbox-label">{text}</label>
  </div>
)

SectionFooterCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
}

export default SectionFooterCheckbox
