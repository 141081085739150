import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import Icon from '../Base/Icon'

import banner from 'cpc_finish.svg'
import logo from 'logos/logo.png'

const i18nOpts = { scope: 'welcome_banner.uhcp_action_plan_index_banner' }

const UhcpActionPlanIndexBanner = ({ close }) => (
  <div className="banner banner--uhcp">
    <div className="card card--wave-header">
      <header className="card__image-header">
        <div className="card__image-header-content">
          <img src={logo} />
          <h3 className="text-medium">{t('invited', i18nOpts)}</h3>
          <h3 className="text-medium">{t('email', i18nOpts)}</h3>
        </div>

        <div className="card__image-header-image-center__hidden-med">
          <img src={banner} />
        </div>

        <div onClick={close} className="card__image-header-close">
          <Icon name="close" />
        </div>
      </header>
    </div>
  </div>
)

UhcpActionPlanIndexBanner.propTypes = {
  close: PropTypes.func.isRequired,
}

export default UhcpActionPlanIndexBanner
