import moment from 'ct-moment';

export const navigateOneWeekWrapper = (timeProvider = moment) => {
  return (currentDate, direction, setDate) => {
    let newDate;

    if (direction === 'next') {
      newDate = timeProvider(currentDate).add('days', 7).format('YYYY-MM-DD');
    } else {
      newDate = timeProvider(currentDate).subtract('days', 7).format('YYYY-MM-DD');
    }

    setDate(newDate);
  };
};

export default navigateOneWeekWrapper(moment);
