import React from 'react';

import heartbeat from 'heartbeat.svg';

const Loader = () => (
  <div className="loader">
    <object type="image/svg+xml" data={heartbeat} className="loader__img" />
  </div>
);

export default Loader;

const Spinner = () => (
  <div className="circle-loader">
    <div className="circle-loader__circle circle-loader__circle--circle1"></div>
    <div className="circle-loader__circle circle-loader__circle--circle2"></div>
    <div className="circle-loader__circle circle-loader__circle--circle3"></div>
    <div className="circle-loader__circle circle-loader__circle--circle4"></div>
    <div className="circle-loader__circle circle-loader__circle--circle5"></div>
    <div className="circle-loader__circle circle-loader__circle--circle6"></div>
    <div className="circle-loader__circle circle-loader__circle--circle7"></div>
    <div className="circle-loader__circle circle-loader__circle--circle8"></div>
    <div className="circle-loader__circle circle-loader__circle--circle9"></div>
    <div className="circle-loader__circle circle-loader__circle--circle10"></div>
    <div className="circle-loader__circle circle-loader__circle--circle11"></div>
    <div className="circle-loader__circle circle-loader__circle--circle12"></div>
  </div>
);

export { Spinner };
