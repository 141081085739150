import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import classNames from 'classnames';
import ExistingResourceForm from '../CarePlans/ResourceSection/ExistingResourceForm';

const i18nOpts = { scope: 'tasks.index.resources' };
const i18nOptsTags = { scope: 'tags' };

class LinkResources extends Component {
  constructor(props) {
    super(props);

    this.state = {
      resources: [],
      filteredModalResources: [],
      resourceSelectionStates: {},

      ...this.setInitialState(),
    };
  }

  get initialState() {
    return {
      ...this.setInitialState(),
    };
  }

  setInitialState = () => {
    const { placeResources, adHocResources } = this.props.resources;

    const adHoc = adHocResources ? adHocResources.map((res) => ({ ...res, isAdHoc: true })) : [];
    const nonAdHoc = placeResources.map((res) => ({ ...res, isAdHoc: false }));

    const resourceSelectionStates = {};
    nonAdHoc.forEach((res) => {
      resourceSelectionStates[res.id] = false;
    });
    adHoc.forEach((res) => {
      resourceSelectionStates[res.id] = false;
    });
    this.props.selectedResources.forEach((res) => {
      resourceSelectionStates[res.id] = true;
    });

    const filteredModalResources = [...adHoc, ...nonAdHoc];

    return {
      resources: filteredModalResources,
      resourceSelectionStates,
      filteredModalResources,
    };
  };

  resourceAttributes = (res) => {
    const isResourceSelected = this.state.resourceSelectionStates[res.id];

    const checkboxProps = {
      name: isResourceSelected ? 'checkbox-selected' : 'checkbox-unselected',
      className: classNames({
        icon: true,
        'icon--medium': true,
        'icon--green': isResourceSelected,
      }),
    };

    const tagString = res.tags
      .map((tag) => tag.label)
      .filter((tag) => tag !== t('other', i18nOptsTags))
      .sort((a, b) => a.localeCompare(b))
      .join(', ');

    return {
      isRemovable: false,
      isModifiable: false,
      isResourceSelected,
      checkboxProps,
      tagString,
    };
  };

  handleSubmit = () => {
    this.props.onSelectResources(this.state.resourceSelectionStates, () => {
      this.props.onBack();
    });
  };

  handleFilterModalResources = (input, tags = []) => {
    const isStringIncluded = (parent, child) => parent.toLowerCase().includes(child.toLowerCase());

    const filteredModalResources = this.state.resources.filter(
      (res) =>
        isStringIncluded(res.name, input) &&
        tags.map((tag) => tag.label).every((label) => res.tags.map((tag) => tag.label).includes(label))
    );

    this.setState({ filteredModalResources });
  };

  toggleResourceSelected = (resource) => {
    const newSelectionState = !this.state.resourceSelectionStates[resource.id];
    this.setState((prevState) => ({
      resourceSelectionStates: {
        ...prevState.resourceSelectionStates,
        [resource.id]: newSelectionState,
      },
    }));
  };

  render() {
    return (
      <ExistingResourceForm
        allTags={this.props.allTags}
        resourceAttributes={this.resourceAttributes}
        toggleResourceSelected={this.toggleResourceSelected}
        filteredModalResources={this.state.filteredModalResources}
        onCloseAddResourceModal={this.props.onBack}
        onFilterModalResources={this.handleFilterModalResources}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

LinkResources.propTypes = {
  onBack: PropTypes.func.isRequired,
  onSelectResources: PropTypes.func.isRequired,
  resources: PropTypes.shape({
    placeResources: PropTypes.array,
    adHocResources: PropTypes.array,
  }),
  selectedResources: PropTypes.array,
};

LinkResources.defaultProps = {
  resources: {
    placeResources: [],
    adHocResources: [],
  },
  selectedResources: [],
};

export default LinkResources;
