const tabClassName = (isSelected, isFocused, isDisabled) => {
  let className = 'tabs-V2__tab';
  if (isSelected) className += ' tabs-V2__tab--selected';
  if (isFocused) className += ' tabs-V2__tab--focused';
  if (isDisabled) className += ' tabs-V2__tab--disabled';

  return className;
};

export default tabClassName;
