import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import CarePlanPlaceInvitationApi from 'Api/CarePlanPlaceInvitation'
import { invitationWrapper, ModifyPlaceInvitationModal } from 'Components/Team/ModifyInvitation'

class ModifyPlaceInvitationContainer extends PureComponent {
  constructor(props) {
    super(props)

    this.handleResend = this.handleResend.bind(this)
    this.handleRemove = this.handleRemove.bind(this)
  }

  handleResend() {
    const {
      member: { id },
      onClose,
    } = this.props

    return new CarePlanPlaceInvitationApi(id).sendInvitation().then((res) => {
      if (res.ok) {
        onClose()
        window.flash_messages.addMessage(t('sent', { scope: 'team.manage_invitation' }))
      }
    })
  }

  handleRemove() {
    const {
      member: { id },
    } = this.props

    return new CarePlanPlaceInvitationApi(id).destroy().then((res) => {
      if (res.ok) {
        window.location.reload()
      }
    })
  }

  render() {
    const { member, isOpen, formStep, onBack, onClose, onRemoveInvite, renderProp, onOpen } = this.props
    return (
      <div className="item-pill__action">
        {renderProp(onOpen)}

        <ModifyPlaceInvitationModal
          member={member}
          isOpen={isOpen}
          formStep={formStep}
          onBack={onBack}
          onClose={onClose}
          onRemoveInvite={onRemoveInvite}
          onRemove={this.handleRemove}
          onResend={this.handleResend}
        />
      </div>
    )
  }
}

ModifyPlaceInvitationContainer.propTypes = {
  formStep: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  member: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.string,
    imageEntitlementToken: PropTypes.string.isRequired,
  }).isRequired,
  onBack: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onRemoveInvite: PropTypes.func.isRequired,
  renderProp: PropTypes.func.isRequired,
}

export default invitationWrapper(ModifyPlaceInvitationContainer)
