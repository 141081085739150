import Api from './Api'

class CheckIn extends Api {
  constructor(carePlanId, id) {
    super()

    this.carePlanId = carePlanId
    this.id = id
  }

  get url() {
    return `/care_plans/${this.carePlanId}/check_ins`
  }
}

export default CheckIn
