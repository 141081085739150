import Api from 'Api/Api'

class Service extends Api {
  constructor(serviceId) {
    super()

    this.id = serviceId
  }

  get url() {
    return '/services'
  }
}

export default Service
