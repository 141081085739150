import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import OutboundUrlLink from 'Components/Base/OutboundUrlLink'

const AppointmentDetails = ({ practitioner, url }) => (
  <Fragment>
    <div className="text-truncate">{practitioner}</div>
    {url && (
      <div>
        <OutboundUrlLink url={url} />
      </div>
    )}
  </Fragment>
)

AppointmentDetails.propTypes = {
  practitioner: PropTypes.string,
  url: PropTypes.string,
}

AppointmentDetails.defaultProps = {
  practitioner: null,
  url: null,
}

export default AppointmentDetails
