import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { t } from 'i18n'

import { actions } from 'Actions/notificationTray'
import { getUnreadNotificationCount, getNotificationTrayOpenState } from 'Selectors/notificationTray'

import Icon from 'Components/Base/Icon'

const NotificationHeaderIcon = ({ unreadCounter, isOpen, toggleTray }) => {
  const classes = classNames('header-nav__menu-item', 'header-nav__menu-item--icon')
  const iconClasses = classNames({ badge: unreadCounter }, 'icon', 'icon-container', 'icon_notification')

  return (
    <a
      className={classes}
      onClick={toggleTray.bind(null, isOpen)}
      onKeyDown={evt => {
        const { key } = evt;
        if (key === 'Enter' || key === ' ') {
          toggleTray.bind(null, isOpen)(evt)
        }
      }}
      tabIndex="0"
      role="button"
      aria-pressed="false"
      aria-label={t('header.notifications')}
      title={t('header.notifications')}
    >
      <div className={iconClasses} data-badge={unreadCounter}>
        <Icon name="notification" className="icon_notification" />
      </div>
    </a>
  )
}

NotificationHeaderIcon.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleTray: PropTypes.func.isRequired,
  unreadCounter: PropTypes.number,
}

NotificationHeaderIcon.defaultProps = {
  unreadCounter: 0,
}

const mapStateToProps = (state, ownProps) => ({
  unreadCounter: getUnreadNotificationCount(state, ownProps),
  isOpen: getNotificationTrayOpenState(state),
})

const mapDispatchToProps = {
  toggleTray: actions.toggleTray,
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationHeaderIcon)
