import getAppointmentsApi from './getAppointmentsApi';

export const handleAppointmentsSetupWrapper = (getAppointmentsApiProvider = getAppointmentsApi) => {
  return async (setAppointmentsCardState, setAppointmentsCardAppointmentsList, carePlanId) => {
    setAppointmentsCardState('LOADING');

    try {
      const { responseType, appointments, repeatedAppointments } = await getAppointmentsApiProvider(
        carePlanId
      );

      if (responseType === 'DATA') {
        setAppointmentsCardAppointmentsList({ appointments, repeatedAppointments });
        setAppointmentsCardState('MAIN_CONTENT');
      } else {
        throw 'Could not get appointments.';
      }
    } catch (error) {
      setAppointmentsCardState('ERROR');
      console.error(error);
    }
  };
};

export default handleAppointmentsSetupWrapper(getAppointmentsApi);
