import React from 'react';
import PropTypes from 'prop-types';
import ListItem from './ListItem';

const StatsList = ({ label, list }) => {
  return (
    <div className="stats-list__container">
      {label && <div className="stats-list__label">{label}</div>}
      {list.map((data) => (
        <ListItem data={data} key={data.property} />
      ))}
    </div>
  );
};

StatsList.propTypes = {
  label: PropTypes.string,
  list: PropTypes.array,
};

StatsList.defaultProps = {
  label: '',
  list: [],
};

export default StatsList;
