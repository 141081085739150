import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';

import Icon from '../Base/Icon';

import banner from 'cpc_finish.svg';
import logo from 'logos/logo.png';

const i18nOpts = { scope: 'welcome_banner.uacg_care_plan_banner' };

const UacgCarePlanBanner = ({ close, placeName, patientName }) => (
  <div className="card card--wave-header">
    <header className="card__image-header">
      <div className="card__image-header-content">
        <h2 className="text-bold">{t('banner_header', { ...i18nOpts, patient_name: patientName })}</h2>
        <h3 className="text-medium">{t('banner_subheader', { ...i18nOpts, place: placeName })}</h3>
      </div>

      <div className="card__image-header-image-center">
        <img src={banner} />
      </div>

      <div onClick={close} className="card__image-header-close">
        <Icon name="close" />
      </div>
    </header>
  </div>
);

UacgCarePlanBanner.propTypes = {
  close: PropTypes.func.isRequired,
  placeName: PropTypes.String,
  patientName: PropTypes.String,
};

export default UacgCarePlanBanner;
