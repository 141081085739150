import {
  SET_ACTIVITY_MODAL_CAREPLAN_ID,
  SET_ACTIVITY_MODAL_LOCALE,
  SET_ACTIVITY_MODAL_STATE,
  SET_ACTIVITY_MODAL_MODE,
  SET_ACTIVITY_MODAL_ACTIVITY_TYPE,
  SET_ACTIVITY_MODAL_IS_OPEN,
  SET_ACTIVITY_MODAL_TO_DEFAULT,
  HANDLE_CLOSE_ACTIVITY_MODAL,
  SET_ACTIVITY_MODAL_ACTIVITY_PROPERTIES,
  SET_ACTIVITY_MODAL_ACTIVITY_ID,
  SET_ACTIVITY_MODAL_FORM_ERRORS,
  SET_ACTIVITY_MODAL_SHOW_SCHEDULED_FIELDS,
  SET_ACTIVITY_MODAL_SHOW_REPEATS_FIELDS,
  SET_ACTIVITY_MODAL_SHOW_ADDRESS,
  SET_ACTIVITY_MODAL_SHOW_ADDRESS_FIELDS,
  SET_ACTIVITY_MODAL_TEMPLATES_DROPDOWN_IS_OPEN,
  SET_ACTIVITY_MODAL_TEMPLATES_DROPDOWN_STATE,
  SET_ACTIVITY_MODAL_TEMPLATES_DROPDOWN_SEARCH_INPUT,
  SET_ACTIVITY_MODAL_TEMPLATES_DROPDOWN_TEMPLATES,
  SET_ACTIVITY_MODAL_TEMPLATES_DROPDOWN_TEMPLATES_TO_DISPLAY,
  SET_ACTIVITY_MODAL_ASSIGNMENTS_DROPDOWN_IS_OPEN,
  SET_ACTIVITY_MODAL_ASSIGNMENTS_DROPDOWN_STATE,
  SET_ACTIVITY_MODAL_ASSIGNMENTS_DROPDOWN_SEARCH_INPUT,
  SET_ACTIVITY_MODAL_ASSIGNMENTS_DROPDOWN_ASSIGNMENTS,
  SET_ACTIVITY_MODAL_ASSIGNMENTS_DROPDOWN_ASSIGNMENTS_TO_DISPLAY,
  SET_ACTIVITY_MODAL_RESOURCES_DROPDOWN_IS_OPEN,
  SET_ACTIVITY_MODAL_RESOURCES_DROPDOWN_STATE,
  SET_ACTIVITY_MODAL_RESOURCES_DROPDOWN_SEARCH_INPUT,
  SET_ACTIVITY_MODAL_RESOURCES_DROPDOWN_RESOURCES,
  SET_ACTIVITY_MODAL_RESOURCES_DROPDOWN_RESOURCES_TO_DISPLAY,
  SET_ACTIVITY_MODAL_LIBRARY_DROPDOWN_IS_OPEN,
  SET_ACTIVITY_MODAL_LIBRARY_DROPDOWN_STATE,
  SET_ACTIVITY_MODAL_LIBRARY_DROPDOWN_SEARCH_INPUT,
  SET_ACTIVITY_MODAL_LIBRARY_DROPDOWN_TEMPLATES,
  SET_ACTIVITY_MODAL_LIBRARY_DROPDOWN_TEMPLATES_TO_DISPLAY,
  SET_ACTIVITY_MODAL_TIME_ZONE,
  SET_ACTIVITY_MODAL_IF_CAN_DELETE_ACTIVITY,
  SET_ACTIVITY_MODAL_IF_CAN_EDIT_FIELDS,
  SET_ACTIVITY_MODAL_DEFAULT_VISIBLE_TO_ALL,
} from 'Actions/activityModal';

const getDefaultActivityProperties = () => {
  return {
    description: '', // APPOINTMENT, TASK, FORM
    virtualVisitLink: '', // APPOINTMENT
    addressFields: {
      address: '',
      extendedAddress: '',
      city: '',
      region: '',
      postalCode: '',
      countryCode: '',
    }, // APPOINTMENT
    frequencyType: 'once', // APPOINTMENT, CHECK_IN, TASK, FORM
    dueAt: null, // APPOINTMENT, CHECK_IN, TASK, FORM
    frequency: '', // APPOINTMENT, CHECK_IN, TASK, FORM
    count: null, // APPOINTMENT, CHECK_IN, TASK, FORM
    endRepeat: null, // APPOINTMENT, CHECK_IN, TASK, FORM
    until: null, // APPOINTMENT, CHECK_IN, TASK, FORM
    interval: null, // APPOINTMENT, CHECK_IN, TASK, FORM
    assignments: [], // APPOINTMENT, TASK
    resources: [], // APPOINTMENT, TASK
    checkInTemplateId: null, // CHECK_IN
    name: '', // CHECK_IN, FORM
    formTemplateId: null, // FORM
    visibleToAll: true, // CHECK_IN
  };
};

const getDefaultCanEditFields = () => ({
  canEditDescription: true,
  canEditUrl: true,
  canEditResources: true,
  canDeleteResources: true,
});

const initialState = {
  carePlanId: '',
  timeZone: '',
  locale: '',
  activityModalState: 'DEFAULT',
  mode: 'CREATE',
  activityType: 'APPOINTMENT',
  isOpen: false,
  setActivityModalActivityId: null,
  activityProperties: getDefaultActivityProperties(),
  formErrors: {},
  showScheduledFields: false,
  showRepeatsFields: false,
  showAddress: true,
  showAddressFields: false,
  defaultVisibleToAll: true,
  templatesDropdown: {
    isOpen: false,
    templatesDropdownState: 'LOADING',
    searchInput: '',
    templates: null,
    templatesToDisplay: null,
  },
  assignmentsDropdown: {
    isOpen: false,
    assignmentsDropdownState: 'LOADING',
    searchInput: '',
    assignments: null,
    assignmentsToDisplay: null,
  },
  resourcesDropdown: {
    isOpen: false,
    resourcesDropdownState: 'LOADING',
    searchInput: '',
    resources: null,
    resourcesToDisplay: null,
  },
  libraryDropdown: {
    isOpen: false,
    libraryDropdownState: 'LOADING',
    searchInput: '',
    templates: null,
    templatesToDisplay: null,
  },
  canDeleteActivity: false,
  canEditFields: getDefaultCanEditFields(),
};

const activityModal = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVITY_MODAL_CAREPLAN_ID:
      return {
        ...state,
        carePlanId: action.carePlanId,
      };

    case SET_ACTIVITY_MODAL_LOCALE:
      return {
        ...state,
        locale: action.locale,
      };

    case SET_ACTIVITY_MODAL_STATE:
      return {
        ...state,
        activityModalState: action.activityModalState,
      };

    case SET_ACTIVITY_MODAL_MODE:
      return {
        ...state,
        mode: action.mode,
      };

    case SET_ACTIVITY_MODAL_ACTIVITY_TYPE:
      return {
        ...state,
        activityType: action.activityType,
      };

    case SET_ACTIVITY_MODAL_IS_OPEN:
      return {
        ...state,
        isOpen: action.isOpen,
      };

    case SET_ACTIVITY_MODAL_TO_DEFAULT:
      return {
        ...state,
        activityModalState: 'DEFAULT',
        activityProperties: getDefaultActivityProperties(),
        formErrors: {},
        showScheduledFields: false,
        showRepeatsFields: false,
        showAddressFields: false,
        canEditFields: getDefaultCanEditFields(),
        defaultVisibleToAll: true,
      };

    case SET_ACTIVITY_MODAL_ACTIVITY_PROPERTIES:
      return {
        ...state,
        activityProperties: action.activityProperties,
      };

    case SET_ACTIVITY_MODAL_ACTIVITY_ID:
      return {
        ...state,
        activityId: action.activityId,
      };

    case SET_ACTIVITY_MODAL_FORM_ERRORS:
      return {
        ...state,
        formErrors: action.formErrors,
      };

    case SET_ACTIVITY_MODAL_SHOW_SCHEDULED_FIELDS:
      return {
        ...state,
        showScheduledFields: action.showScheduledFields,
      };

    case SET_ACTIVITY_MODAL_SHOW_REPEATS_FIELDS:
      return {
        ...state,
        showRepeatsFields: action.showRepeatsFields,
      };

    case SET_ACTIVITY_MODAL_SHOW_ADDRESS:
      return {
        ...state,
        showAddress: action.showAddress,
      };

    case SET_ACTIVITY_MODAL_SHOW_ADDRESS_FIELDS:
      return {
        ...state,
        showAddressFields: action.showAddressFields,
      };

    case SET_ACTIVITY_MODAL_TEMPLATES_DROPDOWN_IS_OPEN:
      return {
        ...state,
        templatesDropdown: {
          ...state.templatesDropdown,
          isOpen: action.isOpen,
        },
      };

    case SET_ACTIVITY_MODAL_TEMPLATES_DROPDOWN_STATE:
      return {
        ...state,
        templatesDropdown: {
          ...state.templatesDropdown,
          templatesDropdownState: action.templatesDropdownState,
        },
      };

    case SET_ACTIVITY_MODAL_TEMPLATES_DROPDOWN_SEARCH_INPUT:
      return {
        ...state,
        templatesDropdown: {
          ...state.templatesDropdown,
          searchInput: action.searchInput,
        },
      };

    case SET_ACTIVITY_MODAL_TEMPLATES_DROPDOWN_TEMPLATES:
      return {
        ...state,
        templatesDropdown: {
          ...state.templatesDropdown,
          templates: action.templates,
        },
      };

    case SET_ACTIVITY_MODAL_TEMPLATES_DROPDOWN_TEMPLATES_TO_DISPLAY:
      return {
        ...state,
        templatesDropdown: {
          ...state.templatesDropdown,
          templatesToDisplay: action.templatesToDisplay,
        },
      };

    case SET_ACTIVITY_MODAL_ASSIGNMENTS_DROPDOWN_IS_OPEN:
      return {
        ...state,
        assignmentsDropdown: {
          ...state.assignmentsDropdown,
          isOpen: action.isOpen,
        },
      };

    case SET_ACTIVITY_MODAL_ASSIGNMENTS_DROPDOWN_STATE:
      return {
        ...state,
        assignmentsDropdown: {
          ...state.assignmentsDropdown,
          assignmentsDropdownState: action.assignmentsDropdownState,
        },
      };

    case SET_ACTIVITY_MODAL_ASSIGNMENTS_DROPDOWN_SEARCH_INPUT:
      return {
        ...state,
        assignmentsDropdown: {
          ...state.assignmentsDropdown,
          searchInput: action.searchInput,
        },
      };

    case SET_ACTIVITY_MODAL_ASSIGNMENTS_DROPDOWN_ASSIGNMENTS:
      return {
        ...state,
        assignmentsDropdown: {
          ...state.assignmentsDropdown,
          assignments: action.assignments,
        },
      };

    case SET_ACTIVITY_MODAL_ASSIGNMENTS_DROPDOWN_ASSIGNMENTS_TO_DISPLAY:
      return {
        ...state,
        assignmentsDropdown: {
          ...state.assignmentsDropdown,
          assignmentsToDisplay: action.assignmentsToDisplay,
        },
      };

    case SET_ACTIVITY_MODAL_RESOURCES_DROPDOWN_IS_OPEN:
      return {
        ...state,
        resourcesDropdown: {
          ...state.resourcesDropdown,
          isOpen: action.isOpen,
        },
      };

    case SET_ACTIVITY_MODAL_RESOURCES_DROPDOWN_STATE:
      return {
        ...state,
        resourcesDropdown: {
          ...state.resourcesDropdown,
          resourcesDropdownState: action.resourcesDropdownState,
        },
      };

    case SET_ACTIVITY_MODAL_RESOURCES_DROPDOWN_SEARCH_INPUT:
      return {
        ...state,
        resourcesDropdown: {
          ...state.resourcesDropdown,
          searchInput: action.searchInput,
        },
      };

    case SET_ACTIVITY_MODAL_RESOURCES_DROPDOWN_RESOURCES:
      return {
        ...state,
        resourcesDropdown: {
          ...state.resourcesDropdown,
          resources: action.resources,
        },
      };

    case SET_ACTIVITY_MODAL_RESOURCES_DROPDOWN_RESOURCES_TO_DISPLAY:
      return {
        ...state,
        resourcesDropdown: {
          ...state.resourcesDropdown,
          resourcesToDisplay: action.resourcesToDisplay,
        },
      };

    case SET_ACTIVITY_MODAL_LIBRARY_DROPDOWN_IS_OPEN:
      return {
        ...state,
        libraryDropdown: {
          ...state.libraryDropdown,
          isOpen: action.isOpen,
        },
      };

    case SET_ACTIVITY_MODAL_LIBRARY_DROPDOWN_STATE:
      return {
        ...state,
        libraryDropdown: {
          ...state.libraryDropdown,
          libraryDropdownState: action.libraryDropdownState,
        },
      };

    case SET_ACTIVITY_MODAL_LIBRARY_DROPDOWN_SEARCH_INPUT:
      return {
        ...state,
        libraryDropdown: {
          ...state.libraryDropdown,
          searchInput: action.searchInput,
        },
      };

    case SET_ACTIVITY_MODAL_LIBRARY_DROPDOWN_TEMPLATES:
      return {
        ...state,
        libraryDropdown: {
          ...state.libraryDropdown,
          templates: action.templates,
        },
      };

    case SET_ACTIVITY_MODAL_LIBRARY_DROPDOWN_TEMPLATES_TO_DISPLAY:
      return {
        ...state,
        libraryDropdown: {
          ...state.libraryDropdown,
          templatesToDisplay: action.templatesToDisplay,
        },
      };

    case SET_ACTIVITY_MODAL_TIME_ZONE:
      return {
        ...state,
        timeZone: action.timeZone,
      };

    case SET_ACTIVITY_MODAL_IF_CAN_DELETE_ACTIVITY:
      return {
        ...state,
        canDeleteActivity: action.canDeleteActivity,
      }

    case SET_ACTIVITY_MODAL_IF_CAN_EDIT_FIELDS:
      return {
        ...state,
        canEditFields: {
          ...state.canEditFields,
          ...action.canEditFields,
        }
      }

    case SET_ACTIVITY_MODAL_DEFAULT_VISIBLE_TO_ALL:
      return {
        ...state,
        defaultVisibleToAll: action.defaultVisibleToAll,
      };

    default:
      return state;
  }
};

export default activityModal;
