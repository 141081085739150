import { t } from 'i18n';

const i18nOpts = { scope: 'blocks.my_sleep' };

const getYAxisTickValue = (value) => {
  if (value === 0 || value === 24) {
    return t('noon', i18nOpts);
  } else if (value === 12) {
    return t('midnight', i18nOpts);
  } else {
    if (value < 12) {
      const amTimeOffsetMap = {
        10: 2,
        8: 4,
        6: 6,
        4: 8,
        2: 10,
      };

      return `${amTimeOffsetMap[value]} ${t('am', i18nOpts)}`;
    } else {
      const pmTimeOffsetMap = {
        22: 2,
        20: 4,
        18: 6,
        16: 8,
        14: 10,
      };

      return `${pmTimeOffsetMap[value]} ${t('pm', i18nOpts)}`;
    }
  }
};

export default getYAxisTickValue;
