import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';
import moment from 'ct-moment';

import { roundTimeUp } from 'Utilities/time';

import {
  setActivityModalActivityProperties,
  setActivityModalShowScheduledFields,
  setActivityModalShowRepeatsFields,
} from 'Actions/activityModal';
import { activityType as activityTypeDefinitions } from '../../modalContentStateDefinitions';

import RadioOption from 'Components/Base/RadioOption';
import Scheduled from './Scheduled';
import FieldLabel from './FieldLabel';

const i18nOpts = { scope: 'activity_modal.form_fields.when_should_be_completed' };

export const WhenShouldBeCompleted = ({
  activityProperties,
  showScheduledFields,
  activityType,
  setActivityModalActivityProperties,
  setActivityModalShowScheduledFields,
  setActivityModalShowRepeatsFields,
  timeZone
}) => {
  const { APPOINTMENT } = activityTypeDefinitions;

  const isAppointment = activityType === APPOINTMENT;

  return (
    <>
      <FieldLabel />
      <div className="activity_modal__input_field__container">
        <div className="when_radio_option_controller_container">
          <RadioOption
            isSelected={!showScheduledFields}
            onClick={() => {
              // clear and hide all Repeats fields if we are hiding Scheduled fields
              setActivityModalShowRepeatsFields(false);
              setActivityModalActivityProperties({
                ...activityProperties,
                count: null,
                dueAt: null,
                endRepeat: null,
                frequency: null,
                frequencyType: 'once',
                interval: null,
                until: null,
                formattedTime: null,
                localizedFrequency: null,
              });
              setActivityModalShowScheduledFields(false);
            }}
            className="left_option"
          >
            {isAppointment ? t('to_be_booked', i18nOpts): t('any_time', i18nOpts)}
          </RadioOption>
          <RadioOption
            isSelected={showScheduledFields}
            onClick={() => {
              setActivityModalActivityProperties({
                ...activityProperties,
                frequencyType: 'scheduled',
                dueAt: roundTimeUp(5, moment()).tz(timeZone).format(),
              });
              setActivityModalShowScheduledFields(true);
            }}
          >
            {t('scheduled', i18nOpts)}
          </RadioOption>
        </div>

        {showScheduledFields && <Scheduled />}
      </div>
    </>
  );
};

const mapStateToProps = ({ activityModal }) => {
  const { activityProperties, showScheduledFields, activityType, timeZone } = activityModal;

  return { activityProperties, showScheduledFields, activityType, timeZone };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalActivityProperties = (dispatch) => {
    return (activityProperties) => {
      dispatch(setActivityModalActivityProperties(activityProperties));
    };
  };

  const dispatchSetActivityModalShowScheduledFields = (dispatch) => {
    return (showScheduledFields) => {
      dispatch(setActivityModalShowScheduledFields(showScheduledFields));
    };
  };

  const dispatchSetActivityModalShowRepeatsFields = (dispatch) => {
    return (showRepeatsFields) => {
      dispatch(setActivityModalShowRepeatsFields(showRepeatsFields));
    };
  };

  return {
    setActivityModalActivityProperties: dispatchSetActivityModalActivityProperties(dispatch),
    setActivityModalShowScheduledFields: dispatchSetActivityModalShowScheduledFields(dispatch),
    setActivityModalShowRepeatsFields: dispatchSetActivityModalShowRepeatsFields(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WhenShouldBeCompleted);
