import MedicalDocumentApi from 'Api/CarePlan/MedicalDocument'
import MedicalDocumentTemplateApi from 'Api/TemplateCreator/MedicalDocumentTemplate'

class MedicalDocumentDispatcher {
  constructor(parentId, isTemplate, id = null) {
    this.parentId = parentId
    this.isTemplate = isTemplate
    this.id = id
  }

  create(data) {
    return this.api.create(data)
  }

  update(data) {
    return this.api.update(data)
  }

  destroy() {
    return this.api.destroy()
  }

  get api() {
    if (this.isTemplate) {
      return new MedicalDocumentTemplateApi(this.parentId, this.id)
    } else {
      return new MedicalDocumentApi(this.parentId, this.id)
    }
  }
}

export default MedicalDocumentDispatcher
