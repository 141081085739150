import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Button, CheckboxList, ModalFooter } from 'Components/Base'
import Submit from 'Components/Forms/Submit'

import banner from 'invite_health_teams.svg'

const i18nOpts = { scope: 'care_plans.health_team_banner.place_selection_form' }

const PlaceSelectionForm = ({ places, selectedPlaceIds, onClose, onSelectPlace, onSubmit }) => (
  <Fragment>
    <div className="ReactModal__ContentBanner ReactModal__ContentBanner--simple">
      <div className="ReactModal__ContentBanner-text">{t('header', i18nOpts)}</div>

      <img src={banner} className="ReactModal__ContentBanner-image" />
    </div>

    <CheckboxList
      header={t('list_header', i18nOpts)}
      onClick={onSelectPlace}
      options={places}
      selectedOptionIds={selectedPlaceIds}
    />

    <form className="form" noValidate onSubmit={onSubmit}>
      <ModalFooter
        renderRight={() => (
          <Fragment>
            <Button className="btn btn--secondary" text={t('cancel', i18nOpts)} onClick={onClose} />
            <Submit value={t('next', i18nOpts)} disabled={!selectedPlaceIds.length} />
          </Fragment>
        )}
      />
    </form>
  </Fragment>
)

PlaceSelectionForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSelectPlace: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  places: PropTypes.array,
  selectedPlaceIds: PropTypes.array,
}

PlaceSelectionForm.defaultProps = {
  places: [],
  selectedPlaceIds: [],
}

export default PlaceSelectionForm
