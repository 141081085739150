import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Footer, Card, EditActionHeader } from 'Components/Base/Card'
import { IconButton } from 'Components/Base'
import { HealthTeamContact, PendingPlaceInvitation, PendingPlaceInvitations } from './'

const i18nOpts = { scope: 'team.health_team' }

class HealthTeamContacts extends PureComponent {
  constructor(props) {
    super(props)

    this.renderMembers = this.renderMembers.bind(this)
    this.renderPendingInvitations = this.renderPendingInvitations.bind(this)
  }

  renderMembers() {
    return this.props.members.length ? (
      <ul className="item-pill-section__item-list">
        {this.props.members.map((member) => (
          <HealthTeamContact key={member.id} {...member} />
        ))}
      </ul>
    ) : (
      <p className="item-pill-section--item-list-empty">{t('no_members', i18nOpts)}</p>
    )
  }

  renderPendingInvitations() {
    return (
      !!this.props.pendingPlaceInvitations.length && (
        <PendingPlaceInvitations
          pendingPlaceInvitations={this.props.pendingPlaceInvitations}
          render={({ id, name, address, imageEntitlementToken }) => (
            <PendingPlaceInvitation
              key={id}
              id={id}
              imageEntitlementToken={imageEntitlementToken}
              name={name}
              address={address}
            />
          )}
        />
      )
    )
  }

  render() {
    return (
      <Card
        title={t('title', i18nOpts)}
        collapsable
        renderHeaderAction={
          this.props.canUpdateHealthContacts ? () => <EditActionHeader onClick={this.props.onEdit} /> : null
        }
      >
        {this.renderMembers()}
        {this.renderPendingInvitations()}

        {this.props.canInviteHealthTeam && (
          <Footer
            left={
              <IconButton
                text={t('invite', i18nOpts)}
                iconName="plus_square"
                onClick={this.props.onModalOpen}
              />
            }
          />
        )}
      </Card>
    )
  }
}

HealthTeamContacts.propTypes = {
  canInviteHealthTeam: PropTypes.bool,
  canUpdateHealthContacts: PropTypes.bool,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      isCarePlanCreator: PropTypes.bool.isRequired,
      location: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      phoneNumber: PropTypes.string,
      photo: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
  onEdit: PropTypes.func.isRequired,
  onModalOpen: PropTypes.func.isRequired,
  pendingPlaceInvitations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      address: PropTypes.string,
      name: PropTypes.string,
      imageEntitlementToken: PropTypes.string,
    })
  ),
}

HealthTeamContacts.defaultProps = {
  canInviteHealthTeam: false,
  canUpdateHealthContacts: false,
  members: [],
  pendingPlaceInvitations: [],
}

export default HealthTeamContacts
