import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import { Icon } from 'Components/Base';

const i18nOpts = { scope: 'care_plans.referral_section.referral.status' };

const ReferralTile = ({ dueAtFormatted, status, serviceName, provider }) => (
  <li className="order">
    <div className="order__section text-truncate">
      <div>
        <strong>
          {t('service_name', { scope: 'care_plans.referral_section.referral', serviceName, provider })}
        </strong>
      </div>
      <span>{dueAtFormatted}</span>
    </div>
    <div className="order__section">
      <span className={`${status === 'completed' || status === 'booked' ? 'order--complete' : 'order--due'}`}>
        <Icon name="check_alt3" className="goal__section--check-icon" />
        {t(status, i18nOpts)}
      </span>
    </div>
  </li>
);

ReferralTile.propTypes = {
  dueAtFormatted: PropTypes.string,
  provider: PropTypes.string.isRequired,
  serviceName: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

ReferralTile.defaultProps = {
  dueAtFormatted: '',
};

export default ReferralTile;
