import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'

import store from '../../../store'
import { setCurrentUser } from 'Actions/current-user'
import { setEventFilter } from 'Actions/filter'

import WeeklyList from './WeeklyList'

class WeeklyListModule extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    store.dispatch(setCurrentUser(this.props.currentUser))
    if (this.props.myTasks) {
      store.dispatch(setEventFilter('mine'))
    } else {
      store.dispatch(setEventFilter('all'))
    }

    return (
      <Provider store={store}>
        <WeeklyList {...this.props} />
      </Provider>
    )
  }
}

WeeklyListModule.propTypes = {
  currentUser: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    timeZone: PropTypes.string.isRequired,
  }).isRequired,
  myTasks: PropTypes.bool,
  patientUuid: PropTypes.string.isRequired,
}

WeeklyListModule.defaultProps = {
  myTasks: false,
}

export default WeeklyListModule
