import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';

const i18nOpts = {
  scope: 'care_plan_place_invitations.signups_container.target_signup_modal'
};

const ApiSuccessMessage = ({ modalStatus }) => {
  if (modalStatus === 'apiSuccess') {
    return (
      <div className="signup_modal__main_content__api_success">
        {t('api_success', i18nOpts)}.
      </div>
    );
  } else {
    return null;
  }
}

ApiSuccessMessage.propTypes = {
  modalStatus: PropTypes.string,
}

export default ApiSuccessMessage;
