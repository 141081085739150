import { push } from 'connected-react-router';

import { t } from 'i18n';
import ConversationApi from 'Api/Inbox/Conversation';

import { SORT_BY_NEWEST, closeSidebar } from 'Actions/inbox/sideNav/general';
import {
  showContacts,
  addContact,
  addChannelContact,
  fetchContacts,
  fetchSortedChannelContacts,
} from 'Actions/inbox/sideNav/contacts';

export const REQUEST_CONVERSATION = 'REQUEST_CONVERSATION';
export const RECEIVE_CONVERSATION = 'RECEIVE_CONVERSATION';
export const RECEIVE_CONVERSATION_MESSAGE = 'RECEIVE_CONVERSATION_MESSAGE';
export const UPDATE_EMERGENCY_INSTRUCTION = 'UPDATE_EMERGENCY_INSTRUCTION';

const fetchConversation = (id) => {
  return (dispatch) => {
    dispatch({ type: REQUEST_CONVERSATION });
    dispatch(closeSidebar());
    dispatch(push(`/inbox/conversations/${id}`));

    return new ConversationApi(id)
      .show()
      .then((res) => res.json())
      .then((conversation) => {
        dispatch(receiveConversation(conversation));
        dispatch(updateEmergencyInstruction(conversation['emergencyInstruction']));
      });
  };
};

const openConversation = (id) => {
  return (dispatch) => {
    dispatch(fetchContacts());

    return dispatch(fetchConversation(id));
  };
};

const startNewDeskConversation = (conversation) => {
  return (dispatch) => {
    dispatch({ type: REQUEST_CONVERSATION });
    dispatch(closeSidebar());
    dispatch(showContacts());
    dispatch(push('/inbox/conversations/new'));

    return dispatch(receiveConversation({ receipts: [], ...conversation }));
  };
};

const generateTimeStamp = () => Math.round(new Date().getTime() / 1000);

const startNewConversation = ({ id, conversation, contact }) => {
  const lastMessageAt = generateTimeStamp();
  const conversationId = `__${id}__`;
  const newConversation = { ...conversation, id: conversationId, lastMessageAt };
  const newContact = { ...contact, id: conversationId, conversation: newConversation };

  return (dispatch) => {
    dispatch({ type: REQUEST_CONVERSATION });
    dispatch(closeSidebar());
    dispatch(addContact(newContact));
    dispatch(showContacts());
    dispatch(push('/inbox/conversations/new'));

    return dispatch(receiveConversation({ ...newConversation, receipts: [] }));
  };
};

const receiveNewConversation = (conversation) => {
  return (dispatch) => {
    dispatch({ type: REQUEST_CONVERSATION });
    dispatch(closeSidebar());
    dispatch(push('/inbox/conversations/new'));

    return dispatch(receiveConversation({ ...conversation, receipts: [] }));
  };
};

const createConversationSubscription = (conversationId, dispatch, getState) => {
  App.cable.subscriptions.create(
    { channel: 'ConversationChannel', conversation_id: conversationId },
    {
      received: (data) => {
        if (conversationId === getState().conversation.id) {
          return dispatch(receiveConversationMessage(conversationId, data.receipt));
        }
      },
      disconnected: () => {
        window.flash_messages.addMessage(t('disconnected', { scope: 'inbox' }));
      },
    }
  );
};

const receiveConversation = (conversation) => {
  return (dispatch, getState) => {
    const subscription = App.cable.subscriptions.subscriptions.find(
      (subscription) => JSON.parse(subscription.identifier).conversation_id === conversation.id
    );

    if (!subscription && conversation.id && typeof conversation.id == 'number') {
      createConversationSubscription(conversation.id, dispatch, getState);
    }

    return dispatch({
      type: RECEIVE_CONVERSATION,
      conversation,
    });
  };
};

const receiveConversationMessage = (id, receipt) => ({
  type: RECEIVE_CONVERSATION_MESSAGE,
  id,
  receipt,
});

const redirectToConversation = (conversation, isPlaceUser) => {
  return (dispatch) => {
    dispatch(push(`/inbox/conversations/${conversation.id}`));

    const includeChannels = true;

    if (isPlaceUser) {
      // PlaceUsers have the paginator method so whenever a new conversation is created,
      // have to refresh the contacts list and sorted by newest
      dispatch(fetchContacts(includeChannels));
      dispatch({ type: SORT_BY_NEWEST });
    } else {
      dispatch(fetchContacts(includeChannels));
    }

    return dispatch(receiveConversation(conversation));
  };
};

const startChannel = ({ channelName, carePlanId, participants, isPlaceUser }) => {
  const lastMessageAt = generateTimeStamp();
  const conversationId = `__${lastMessageAt}__`;
  const conversation = {
    id: conversationId,
    subject: channelName,
    carePlanId,
    subheader: channelName,
    type: 'NEW_CHANNEL',
    disclaimer: isPlaceUser ? 'health_care_channel' : null,
    details: `/care_plans/${carePlanId}`,
    lastMessageAt,
    recipients: participants.map(({ id, type }) => ({ id, type })),
  };

  const contact = { id: channelName, title: channelName, participants, conversation, type: 'Channel' };

  return (dispatch) => {
    dispatch({ type: REQUEST_CONVERSATION });
    dispatch(closeSidebar());
    dispatch(addChannelContact(contact));
    dispatch(showContacts());
    dispatch(push('/inbox/conversations/new'));

    return dispatch(receiveConversation({ ...conversation, receipts: [] }));
  };
};

const updateEmergencyInstruction = (emergencyInstruction) => ({
  type: UPDATE_EMERGENCY_INSTRUCTION,
  emergencyInstruction,
});

export {
  fetchConversation,
  startNewDeskConversation,
  startNewConversation,
  openConversation,
  receiveNewConversation,
  redirectToConversation,
  receiveConversation,
  receiveConversationMessage,
  startChannel,
  updateEmergencyInstruction,
};
