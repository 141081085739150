const filterArchivedPatients = (patients, archivedPatientIds, isShowingArchivedPatients) => {
  const archivedPatientIdSet = new Set(archivedPatientIds);  
  
  return patients.filter((patient) => {
    return isShowingArchivedPatients
    ? archivedPatientIdSet.has(patient.id)
    : !archivedPatientIdSet.has(patient.id);
  });
};
  
export default filterArchivedPatients;