import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import XAxisTickFormatter from '../XAxisTickFormatter';
import RenderLegend from '../RenderLegend';
import RenderNoDataPoint from '../RenderNoDataPoint';
import useWindowDimensions from 'Hooks/useWindowDimensions';

import {
  ComposedChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Text,
  Rectangle,
} from 'recharts';

const i18nOpts = { scope: 'blocks.expected_vs_reality' };
const FORM_MAX_VALUE = 10;

const Chart = ({ data: { results, maxValue } }) => {
  const { width } = useWindowDimensions();

  return (
    <div className="chart expected-vs-reality">
      <ResponsiveContainer width="100%" height={150}>
        <ComposedChart height={40} data={results} barCategoryGap="15%" margin={{ left: 25, top: 5 }}>
          <CartesianGrid vertical={false} />
          {results.map(({ name, data }, index) => {
            return (
              <Bar
                key={index}
                data={data}
                dataKey="value"
                name="name"
                fill="fill"
                label={(args) => <RenderNoDataPoint {...args} data={data} y={50} />}
                shape={({ fill, x, y, ...props }) => {
                  if (name === 'morning') {
                    const getTranslatedX = (xPos) => {
                      // 768 is $med-max. This cannot be done with css because
                      // xPos is dynamic. The value added to xPos is an arbitrary value, it just
                      // happens to work on devices with resolution larger than $med-max
                      return width >= 768 ? xPos + 5.5 : xPos;
                    };

                    return (
                      <g transform={`translate(${getTranslatedX(x)}, ${y + 4})`}>
                        <circle r="1.5%" fill={fill} />
                      </g>
                    );
                  }

                  return <Rectangle x={x} y={y} fill={fill} {...props} />;
                }}
              />
            );
          })}
          <XAxis
            dataKey="day"
            type="category"
            allowDuplicatedCategory={false}
            tickLine={false}
            interval={0}
            tick={<XAxisTickFormatter />}
          />
          <YAxis
            tickMargin={25}
            axisLine={false}
            tickLine={false}
            domain={[0, FORM_MAX_VALUE]}
            tickCount={maxValue > 0 ? 5 : 2}
            tick={({ x, y, className, payload: { value } }) => {
              const getYAxisTickValue = (val) => {
                if (val === 0) return t('not_at_all', i18nOpts);
                if (val >= FORM_MAX_VALUE) return t('extremely', i18nOpts);
                if (val > 0 && val <= FORM_MAX_VALUE) return '';
              };

              return (
                <g transform={`translate(${x - 55}, ${y})`}>
                  <Text className={`${className} text text-subtle`}>{getYAxisTickValue(value)}</Text>
                </g>
              );
            }}
          />
        </ComposedChart>
      </ResponsiveContainer>
      <RenderLegend legend={t('legend', i18nOpts)} />
    </div>
  );
};

Chart.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Chart;
