import { t } from 'i18n';

const i18nOpts = {
  scope: 'care_plan_place_invitations.signups_container.target_signup_modal.action_buttons'
};

export const leftButtonContent = (target) => {
  if (target.status === 'accepted') {
    return t('view_action_plan', i18nOpts);
  } else {
    return t('decline', i18nOpts);
  }
}

export const rightButtonContent = (target) => {
  if (target.status === 'accepted') {
    return t('done', i18nOpts);
  } else {
    return t('accept', i18nOpts);
  }
}
