import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'ct-moment';
import 'moment-timezone';

import { roundTimeUp } from 'Utilities/time';

import FormApi from 'Api/Form';
import RepeatedFormApi from 'Api/RepeatedForm';
import CarePlanTemplateFormTemplate from 'Api/TemplateCreator/CarePlanTemplateFormTemplate';

class FormContainer extends Component {
  constructor(props) {
    super(props);

    this.resetForm = this.resetForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDeleteForm = this.handleDeleteForm.bind(this);
    this.handleEditForm = this.handleEditForm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      ...this.initialState,
      forms: props.forms,
    };
  }

  get initialState() {
    const currentTime = moment().tz(this.props.timeZone);

    return {
      id: undefined,
      name: '',
      frequencyType: 'once',
      frequency: '',
      dueAt: roundTimeUp(5, currentTime).format(),
      daysOffset: undefined,
      timeOffset: undefined,
      until: undefined,
      count: undefined,
      formTemplateId: undefined,
      endRepeat: 'never',
      interval: 1,
      errors: {},
    };
  }

  get api() {
    if (this.props.isTemplate) {
      return new CarePlanTemplateFormTemplate(this.props.parentId, this.state.id);
    } else if (this.state.frequencyType === 'recurring') {
      return new RepeatedFormApi(this.props.parentId, this.state.id);
    } else {
      return new FormApi(this.props.parentId, this.state.id);
    }
  }

  get editing() {
    return !!this.state.id;
  }

  get formParams() {
    const {
      id,
      frequencyType,
      count,
      endRepeat,
      dueAt,
      formTemplateId,
      daysOffset,
      timeOffset,
      frequency,
      name,
      interval,
      until,
    } = this.state;

    const paramsDueAt = frequencyType === 'once' ? '' : dueAt;
    const paramsCount = endRepeat === 'times' ? count : '';
    const paramsUntil = endRepeat === 'on_a_day' ? until : '';

    return {
      id,
      frequencyType,
      daysOffset,
      timeOffset,
      frequency,
      endRepeat,
      interval,
      dueAt: paramsDueAt,
      until: paramsUntil,
      count: paramsCount,
      formTemplateId,
      name,
    };
  }

  resetForm() {
    this.setState({ ...this.initialState });
  }

  handleChange({ target: { name, value } }) {
    this.setState((prevState) => {
      if (name === 'frequencyType' && value === 'recurring' && this.state.frequency === '') {
        return {
          ...prevState,
          frequencyType: value,
          frequency: 'daily',
        };
      } else if (name === 'formTemplateId') {
        const form =
          value === '' ? value : this.props.formTemplates.find((template) => template.id === Number(value));

        return { ...prevState, formTemplateId: form.id, name: form.name };
      } else {
        return {
          ...prevState,
          [name]: value,
        };
      }
    });
  }

  handleDeleteForm(id, frequencyType) {
    this.setState(
      (prevState) => {
        const forms = prevState.forms.map((form) => (form.id === id ? { ...form, loading: true } : form));
        return { forms, id, frequencyType };
      },
      () =>
        this.api.destroy().then((res) => {
          if (res.ok) {
            this.setState((prevState) => ({
              ...this.initialState,
              forms: prevState.forms.filter((form) => form.id !== id),
            }));
          }
        })
    );
  }

  handleEditForm(form) {
    this.setState({
      id: form.id,
      frequencyType: form.frequencyType,
      frequency: form.frequency,
      daysOffset: form.daysOffset,
      timeOffset: form.timeOffset,
      interval: form.interval,
      endRepeat: form.endRepeat,
      count: form.count,
      until: form.until,
      dueAt: form.dueAt,
      formTemplateId: form.formTemplateId,
      name: form.name,
      errors: {},
    });
  }

  handleSubmit(evt) {
    evt.preventDefault();

    const action = this.editing ? 'update' : 'create';

    this.api[action](this.formParams).then((res) => {
      if (res.ok) {
        res.json().then(({ form }) => {
          this.setState((prevState) => {
            const forms = this.editing
              ? prevState.forms.map((existingForm) => (existingForm.id === form.id ? form : existingForm))
              : [form, ...prevState.forms];
            return { forms, ...this.initialState };
          }, this.props.onSuccess);
        });
      } else if (res.status === 422) {
        res.json().then((errors) => this.setState({ errors }));
      }
    });
  }

  render() {
    return this.props.render({
      ...this.state,
      editing: this.editing,
      formTemplates: this.props.formTemplates,
      isTemplate: this.props.isTemplate,
      onChange: this.handleChange,
      onDelete: this.handleDeleteForm,
      onEdit: this.handleEditForm,
      onSubmit: this.handleSubmit,
      resetForm: this.resetForm,
      timeZone: this.props.timeZone,
    });
  }
}

FormContainer.propTypes = {
  formTemplates: PropTypes.array,
  forms: PropTypes.array,
  isTemplate: PropTypes.bool,
  onSuccess: PropTypes.func,
  parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  render: PropTypes.func.isRequired,
  timeZone: PropTypes.string.isRequired,
};

FormContainer.defaultProps = {
  formTemplates: [],
  forms: [],
  isTemplate: false,
  onSuccess: null,
  parentId: '',
};

export default FormContainer;
