import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import {
  setActivityModalTemplatesDropdownSearchInput,
  setActivityModalTemplatesDropdownTemplatesToDisplay,
} from 'Actions/activityModal';

import sortByKey from '../../sortByKey';

import TemplateItem from './TemplateItem';
import FormField from '../../FormField';

const i18nOpts = { scope: 'activity_modal.form_fields.templates' };

const renderMainContent = (templatesToDisplay) => {
  if (templatesToDisplay?.length > 0) {
    const sortedTemplates = sortByKey(templatesToDisplay, 'name');
    const templates = sortedTemplates.map((template, id) => {
      return <TemplateItem key={`template-${id}`} template={template} />;
    });
    return <ul className="templates_dropdown__templates_list">{templates}</ul>;
  } else {
    return <div className="templates_dropdown__no_templates">{t('no_templates', i18nOpts)}</div>;
  }
};

export const handleTemplatesSearch = (
  setActivityModalTemplatesDropdownSearchInput,
  setActivityModalTemplatesDropdownTemplatesToDisplay,
  templates,
  templatesToDisplay,
  inputValue
) => {
  setActivityModalTemplatesDropdownSearchInput(inputValue);

  if (templates?.length > 0) {
    const filteredTemplates = templates.filter(({ name }) => {
      return name.toLowerCase().includes(inputValue.toLowerCase());
    });
    setActivityModalTemplatesDropdownTemplatesToDisplay(filteredTemplates);
  }
};

export const TemplatesMenu = ({
  templatesDropdown,
  setActivityModalTemplatesDropdownSearchInput,
  setActivityModalTemplatesDropdownTemplatesToDisplay,
}) => {
  const { searchInput, templates, templatesToDisplay } = templatesDropdown;

  return (
    <div className="templates_dropdown">
      <FormField
        className="templates_dropdown__search_input"
        value={searchInput}
        name="searchInput"
        onChange={(value) => {
          handleTemplatesSearch(
            setActivityModalTemplatesDropdownSearchInput,
            setActivityModalTemplatesDropdownTemplatesToDisplay,
            templates,
            templatesToDisplay,
            value
          );
        }}
      />

      {renderMainContent(templatesToDisplay)}
    </div>
  );
};

const mapStateToProps = ({ activityModal }) => {
  const { templatesDropdown } = activityModal;

  return { templatesDropdown };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalTemplatesDropdownSearchInput = (dispatch) => {
    return (searchInput) => {
      dispatch(setActivityModalTemplatesDropdownSearchInput(searchInput));
    };
  };

  const dispatchSetActivityModalTemplatesDropdownTemplatesToDisplay = (dispatch) => {
    return (templatesToDisplay) => {
      dispatch(setActivityModalTemplatesDropdownTemplatesToDisplay(templatesToDisplay));
    };
  };

  return {
    setActivityModalTemplatesDropdownSearchInput:
      dispatchSetActivityModalTemplatesDropdownSearchInput(dispatch),
    setActivityModalTemplatesDropdownTemplatesToDisplay:
      dispatchSetActivityModalTemplatesDropdownTemplatesToDisplay(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplatesMenu);
