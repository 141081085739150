import React from 'react'
import PropTypes from 'prop-types'

import ProviderWrapper from 'Components/Base/ProviderWrapper'
import NotificationHeaderIcon from './NotificationHeaderIcon'

const HeaderIcon = ({ notificationCount }) => (
  <ProviderWrapper>
    <NotificationHeaderIcon notificationCount={notificationCount} />
  </ProviderWrapper>
)

HeaderIcon.propTypes = {
  notificationCount: PropTypes.number,
}

HeaderIcon.defaultProps = {
  notificationCount: 0,
}

export default HeaderIcon
