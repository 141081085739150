import React, { Fragment, Component } from 'react'
import { t } from 'i18n'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import PropTypes from 'prop-types'

import { Modal } from 'Components/Base/Modal'
import { Button, ModalFooter } from 'Components/Base'
import IconButton from 'Components/Base/IconButton'
import Icon from 'Components/Base/Icon'
import CarePlanApi from 'Api/CarePlan'
const i18nOpts = { scope: 'team.invite_code_modal' }

class InviteCodeModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      copied: false,
      modalIsOpen: false,
      requestAccessToken: props.requestAccessToken,
    }

    this.handleToggleModal = this.handleToggleModal.bind(this)
    this.createToken = this.createToken.bind(this)
  }

  get invitationLink() {
    return `${this.props.startUrl}?request_token=${this.state.requestAccessToken}`
  }

  handleToggleModal() {
    if (!this.state.requestAccessToken) {
      this.createToken()
    }

    this.setState((prevState) => ({ modalIsOpen: !prevState.modalIsOpen, copied: false }))
  }

  createToken() {
    const carePlanApi = new CarePlanApi(this.props.carePlanUuid)

    return carePlanApi.readOrUpdateToken().then((res) => {
      if (res.ok) {
        return res
          .json()
          .then(({ request_access_token }) => this.setState({ requestAccessToken: request_access_token }))
      } else if (res.status === 422) {
        return window.flash_messages.addMessage(t('team.access_requests.failure'))
      }
    })
  }

  render() {
    return (
      <Fragment>
        <IconButton
          isSecondary
          iconName="link_right"
          onClick={this.handleToggleModal}
          text={t('get_code', i18nOpts)}
        />

        <Modal
          title={t('header', i18nOpts)}
          isOpen={this.state.modalIsOpen}
          closeModal={this.handleToggleModal}
        >
          <header className="wave-header">
            <div className="wave-header__header-title text-medium">{t('title', i18nOpts)}</div>

            <div className="wave-header__header-subtitle">{t('subtitle', i18nOpts)}</div>
          </header>

          <div className="wave-header__header-title text-medium">{t('first_option', i18nOpts)}</div>

          <div
            className="wave-header__header-subtitle"
            dangerouslySetInnerHTML={{
              __html: t('first_option_subtitle', {
                ...i18nOpts,
                link: this.props.startUrl,
              }),
            }}
          />

          <div className="invite-code-modal__code text-medium">{this.state.requestAccessToken}</div>

          <div className="card__button-footer">
            <Icon name="or" className="icon_or invite-code-modal__or-icon" />
          </div>

          <div className="wave-header__header-title text-medium">{t('second_option', i18nOpts)}</div>

          <div className="wave-header__header-subtitle">{t('second_option_subtitle', i18nOpts)}</div>

          <div className="invite-code-modal__copy-row">
            <input
              readOnly
              type="text"
              value={this.invitationLink}
              className="invite-code-modal__code-input"
            />

            <CopyToClipboard text={this.invitationLink} onCopy={() => this.setState({ copied: true })}>
              <IconButton
                iconName="link_copy"
                className="invite-code-modal__code-button"
                text={t('get_link', i18nOpts)}
              />
            </CopyToClipboard>
          </div>

          <ModalFooter
            className="invite-code-modal__footer"
            renderRight={() => (
              <Button
                text={t('done', i18nOpts)}
                className="btn btn--secondary"
                onClick={this.handleToggleModal}
              />
            )}
            renderLeft={() =>
              this.state.copied && (
                <div className="invite-code-modal__copied">
                  <span>
                    <Icon name="check" className="icon_or invite-code-modal__check-icon" />
                    <span className="invite-code-modal__copied-title">{t('link_copied', i18nOpts)}</span>
                  </span>

                  <div className="invite-code-modal__copied-link">{this.invitationLink}</div>
                </div>
              )
            }
          />
        </Modal>
      </Fragment>
    )
  }
}

InviteCodeModal.propTypes = {
  carePlanUuid: PropTypes.string.isRequired,
  requestAccessToken: PropTypes.string,
  startUrl: PropTypes.string.isRequired,
}

InviteCodeModal.defaultProps = {
  requestAccessToken: null,
}

export default InviteCodeModal
