import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import Button from '../../Base/Button'

const i18nOpts = { scope: 'activities.assignments.claim_task' }

const ClaimTask = ({ claim }) => (
  <li className="assignment assignment--claim">
    <span className="assignment__status">{t('claim_task', i18nOpts)}</span>

    <div className="assignment-actions">
      <Button className="assignment-actions__action" onClick={claim}>
        {t('claim', i18nOpts)}
      </Button>
    </div>
  </li>
)

ClaimTask.propTypes = {
  claim: PropTypes.func.isRequired,
}

ClaimTask.defaultProps = {}

export default ClaimTask
