import { request } from 'Apiv2/handleRequest';

const saveDataApiRequest = async (carePlanUuid, inputData) => {
  const endpointUrl = `/care_plans/${carePlanUuid}/dashboard/insulin_dosing_data`;

  try {
    const responseObject = await request(endpointUrl, 'POST', inputData);
    const { data, errors } = await responseObject.json();

    if (errors) {
      throw errors;
    }

    if (responseObject.status === 200) {
      return { content_type: 'INSULIN_DATA', data };
    } else {
      return { content_type: 'ERROR', errors: ['UNKNOWN_STATUS'] };
    }
  } catch (error) {
    return { content_type: 'ERROR', error };
  }
};

export default saveDataApiRequest;
