import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Card, AddActionHeader, CancelActionHeader } from 'Components/Base/Card/'
import Goal from './Goal'
import GoalForm from './GoalForm'

const i18nOpts = { scope: 'care_plans.goal_section.index' }

class GoalSection extends Component {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
    this.handleSuccess = this.handleSuccess.bind(this)
    this.toggle = this.toggle.bind(this)
    this.renderIncompleteGoals = this.renderIncompleteGoals.bind(this)
    this.renderCompletedGoals = this.renderCompletedGoals.bind(this)

    this.state = {
      showForm: false,
      goals: props.goals,
      showCompleted: true,
    }
  }

  handleSuccess({ goal }) {
    this.setState({
      showForm: false,
      goals: [goal, ...this.state.goals],
    })
  }

  handleClick() {
    this.setState({ showForm: !this.state.showForm })
  }

  toggle(evt) {
    evt.preventDefault()
    this.setState((prevState) => ({ showCompleted: !prevState.showCompleted }))
  }

  renderIncompleteGoals() {
    const { goals } = this.state
    return (
      <ul>
        {goals.length ? (
          goals.filter((goal) => !goal.completedAt).map((goal) => <Goal key={goal.id} {...goal} />)
        ) : (
          <li className="item-pill-section--item-list-empty">
            {t(this.props.kind === 'patient' ? 'empty' : 'empty_hca', i18nOpts)}
          </li>
        )}
      </ul>
    )
  }

  renderCompletedGoals() {
    const { showCompleted } = this.state
    const goals = this.state.goals.filter((goal) => goal.completedAt)

    if (goals.length) {
      return (
        <div>
          {showCompleted && (
            <div>
              <hr className="primary" />
              <ul>
                {goals.map((goal) => (
                  <Goal key={goal.id} {...goal} />
                ))}
              </ul>
            </div>
          )}

          <div className="card__footer">
            <a className="h4" onClick={this.toggle}>
              {showCompleted ? t('hide_completed', i18nOpts) : t('show_all', i18nOpts)}
            </a>
          </div>
        </div>
      )
    }
  }

  get cardProps() {
    let renderHeaderAction = null

    if (this.props.canAdd) {
      const onActionClick = this.handleClick
      const RenderHeaderComponent = this.state.showForm ? CancelActionHeader : AddActionHeader
      renderHeaderAction = (actionWrapper) => (
        <RenderHeaderComponent onClick={() => actionWrapper(onActionClick)} />
      )
    }

    return {
      title: t('header', i18nOpts),
      collapsable: true,
      renderHeaderAction,
    }
  }

  render() {
    const { showForm } = this.state

    return (
      <Card {...this.cardProps}>
        {showForm && (
          <GoalForm
            onSuccess={this.handleSuccess}
            kind={this.props.kind}
            carePlanId={this.props.carePlanId}
          />
        )}

        {this.renderIncompleteGoals()}

        {this.renderCompletedGoals()}
      </Card>
    )
  }
}

GoalSection.propTypes = {
  canAdd: PropTypes.bool,
  carePlanId: PropTypes.string.isRequired,
  goals: PropTypes.array,
  id: PropTypes.string,
  kind: PropTypes.string,
}

GoalSection.defaultProps = {
  canAdd: false,
  goals: [],
  id: 'goals',
  kind: 'patient',
}

export default GoalSection
