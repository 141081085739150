import {
  REQUEST_AGENDA,
  RECEIVE_AGENDA,
  ADD_EVENT_TO_AGENDA,
  REMOVE_EVENT_FROM_AGENDA,
  SET_HEALTH_CARE_VIEW,
  ADD_REPEATED_ACTIVITY_TO_AGENDA,
} from 'Actions/agenda'

import { parseZoneDate } from 'Utilities/time'

const initialState = {
  events: {},
  previousWeek: null,
  healthCareView: false,
  week: null,
  nextWeek: null,
  previousYear: null,
  year: null,
  nextYear: null,
  isFetching: true, // For nice transition from server-side rendering
}

const agenda = (state = initialState, action) => {
  switch (action.type) {
    case SET_HEALTH_CARE_VIEW:
      return { ...state, healthCareView: true }

    case REQUEST_AGENDA:
      return { ...state, isFetching: true }

    case RECEIVE_AGENDA:
      return {
        ...state,
        events: compileEvents(action.agenda),
        previousWeek: action.agenda.previousWeek,
        week: action.agenda.week,
        nextWeek: action.agenda.nextWeek,
        previousYear: action.agenda.previousYear,
        year: action.agenda.year,
        nextYear: action.agenda.nextYear,
        isFetching: false,
        firstDay: action.agenda.firstDay,
        lastDay: action.agenda.lastDay,
      }

    case ADD_EVENT_TO_AGENDA: {
      const day = parseZoneDate(action.event.dueAt)

      if (day >= state.firstDay && day <= state.lastDay) {
        return {
          ...state,
          events: {
            ...state.events,
            [day]: [...(state.events[day] || []), action.event],
          },
        }
      }

      return state
    }

    case REMOVE_EVENT_FROM_AGENDA: {
      const day = parseZoneDate(action.event.dueAt)

      return {
        ...state,
        events: {
          ...state.events,
          [day]: state.events[day].filter(
            (event) => event.id !== action.event.id || event.type !== action.event.type
          ),
        },
      }
    }

    case ADD_REPEATED_ACTIVITY_TO_AGENDA: {
      return {
        ...state,
        events: action.repeatedActivity.occurrences.reduce((days, occurrence) => {
          const day = parseZoneDate(occurrence.dueAt)
          return {
            ...days,
            [day]: [...(state.events[day] || []), occurrence],
          }
        }, state.events),
      }
    }

    default:
      return state
  }
}

function compileEvents(agenda) {
  const {
    appointments = [],
    tasks = [],
    checkIns = [],
    forms = [],
    repeatedTaskOccurrences = [],
    repeatedAppointmentOccurrences = [],
    repeatedCheckInOccurrences = [],
    repeatedFormOccurrences = [],
  } = agenda

  const unsortedEvents = [
    ...appointments,
    ...tasks,
    ...checkIns,
    ...forms,
    ...repeatedTaskOccurrences,
    ...repeatedAppointmentOccurrences,
    ...repeatedCheckInOccurrences,
    ...repeatedFormOccurrences,
  ]
  const events = {}

  unsortedEvents.forEach((event) => {
    const day = parseZoneDate(event.dueAt)

    if (events[day] === undefined) {
      events[day] = []
    }

    events[day].push(event)
  })
  return events
}

export default agenda
