import TaskApi from 'Api/Task'
import IntegratedTaskApi from 'Api/Integrated/Task'
import PlaceUserTaskApi from 'Api/PlaceUser/Task'

export const RECEIVE_TASKS = 'RECEIVE_TASKS'
export const REQUEST_TASKS = 'REQUEST_TASKS'

export const ADD_TASK = 'ADD_TASK'

const fetchTasks = (carePlanId, completed = false) => {
  return (dispatch) => {
    dispatch(requestTasks())

    const task = new TaskApi(carePlanId)
    const response = completed ? task.completed() : task.index()

    return response.then((res) => res.json()).then(({ tasks }) => dispatch(receiveTasks(tasks, completed)))
  }
}

const fetchIntegratedTasks = (patientUuid, completed = false) => {
  return (dispatch) => {
    dispatch(requestTasks())

    const task = new IntegratedTaskApi(patientUuid)
    const response = completed ? task.completed() : task.index()

    return response.then((res) => res.json()).then(({ tasks }) => dispatch(receiveTasks(tasks, completed)))
  }
}

const fetchHealthCareTasks = (placeUserId, completed = false) => {
  return (dispatch) => {
    dispatch(requestTasks())

    const task = new PlaceUserTaskApi(placeUserId)
    const response = completed ? task.completed() : task.index()

    return response.then((res) => res.json()).then(({ tasks }) => dispatch(receiveTasks(tasks, completed)))
  }
}

const requestTasks = () => ({
  type: REQUEST_TASKS,
})

const receiveTasks = (tasks, completed) => ({
  type: RECEIVE_TASKS,
  tasks,
  completed,
})

const addTask = (task) => ({
  type: ADD_TASK,
  task,
})

export { fetchTasks, requestTasks, receiveTasks, addTask, fetchIntegratedTasks, fetchHealthCareTasks }
