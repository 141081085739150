import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'i18n';

import { setActivityModalActivityProperties, setActivityModalFormErrors } from 'Actions/activityModal';
import { setupVirtualVisitLinkValidator } from '../formFieldsValidators';

import FormField from '../FormField';

const i18nOpts = { scope: 'activity_modal.form_fields.where' };

export const VirtualVisitLink = ({
  activityProperties,
  formErrors,
  setActivityModalActivityProperties,
  setActivityModalFormErrors,
}) => {
  const handleVirtualVisitLinkValidation = setupVirtualVisitLinkValidator(
    formErrors,
    setActivityModalFormErrors,
    t,
    i18nOpts
  );

  return (
    <FormField
      className="activity_modal__virtual_visit__input_field__container"
      label={t('virtual_visit_link_description', i18nOpts)}
      formErrors={formErrors}
      value={activityProperties.virtualVisitLink}
      name="virtualVisitLink"
      onChange={(value) => {
        setActivityModalActivityProperties({
          ...activityProperties,
          virtualVisitLink: value,
        });
      }}
      handleValidation={handleVirtualVisitLinkValidation}
    />
  );
};

const mapStateToProps = ({ activityModal }) => {
  const { activityProperties, formErrors } = activityModal;

  return { activityProperties, formErrors };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalActivityProperties = (dispatch) => {
    return (activityProperties) => {
      dispatch(setActivityModalActivityProperties(activityProperties));
    };
  };

  const dispatchSetActivityModalFormErrors = (dispatch) => {
    return (formErrors) => {
      dispatch(setActivityModalFormErrors(formErrors));
    };
  };

  return {
    setActivityModalActivityProperties: dispatchSetActivityModalActivityProperties(dispatch),
    setActivityModalFormErrors: dispatchSetActivityModalFormErrors(dispatch),
  };
};

VirtualVisitLink.propTypes = {
  activityProperties: PropTypes.object,
  formErrors: PropTypes.object,
  setActivityModalActivityProperties: PropTypes.func,
  setActivityModalFormErrors: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(VirtualVisitLink);
