import { combineReducers } from 'redux'

import general from './general'
import contacts from './contacts'
import newContacts from './newContacts'
import carePlans from './carePlans'
import channel from './channel'

export default combineReducers({
  general,
  contacts,
  newContacts,
  carePlans,
  channel,
})
