import SearchIndex from './search_index'

class DocumentSearchIndex extends SearchIndex {
  constructor(documents) {
    super(documents)
  }

  get fuseOptions() {
    return {
      threshold: 0.3,
      location: 0,
      distance: 60,
      maxPatternLength: 32,
      minMatchCharLength: 2,
      keys: ['name', 'documentFileName'],
    }
  }
}

export default DocumentSearchIndex
