import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Button, IconButton, ModalFooter } from 'Components/Base'
import InputGroup from 'Components/Forms/InputGroup'

import banner from 'modify_invitation_edit.svg'

const i18nOpts = { scope: 'team.edit_invitation' }

const EditInvitation = ({ email, emailChanged, isSubmitting, errors, onChange, onSubmit, onBack }) => (
  <Fragment>
    <div className="ReactModal__ContentBanner ReactModal__ContentBanner--simple">
      <div className="ReactModal__ContentBanner-text">{t('header', i18nOpts)}</div>

      <img src={banner} className="ReactModal__ContentBanner-image" />
    </div>

    <div className="ReactModal__ContentBody-text">
      <InputGroup
        component="input"
        className="input input--fixed-hint"
        label={t('label', i18nOpts)}
        hint={t('hint', i18nOpts)}
        errors={errors}
        name="invitation.email"
        onChange={onChange}
        value={email}
        readonly={isSubmitting}
        required
        type="text"
      />
    </div>

    <ModalFooter
      renderRight={() => (
        <Button
          text={t('send_invitation', i18nOpts)}
          disabled={isSubmitting || !emailChanged}
          onClick={onSubmit}
        />
      )}
      renderLeft={() => (
        <IconButton
          text={t('back', i18nOpts)}
          disabled={isSubmitting}
          isSecondary
          onClick={onBack}
          iconName="chevron-left"
        />
      )}
    />
  </Fragment>
)

EditInvitation.propTypes = {
  email: PropTypes.string.isRequired,
  emailChanged: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  isSubmitting: PropTypes.bool,
  onBack: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

EditInvitation.defaultProps = {
  emailChanged: false,
  errors: {},
  isSubmitting: false,
}

export default EditInvitation
