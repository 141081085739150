import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Modal } from 'Components/Base/Modal'
import Button from 'Components/Base/Button'
import IconButton from 'Components/Base/IconButton'

const UpdateModal = ({ modalIsOpen, closeModal, updateOccurrence, updateSeries }) => (
  <Modal isOpen={modalIsOpen} closeModal={closeModal} onRequestClose={closeModal}>
    <p>{t('change_series', { scope: 'repeated_tasks.occurrences.edit' })}</p>

    <div className="form__submit">
      <IconButton
        iconName="refresh"
        id="update-series"
        onClick={updateSeries}
        text={t('this_series', { scope: 'repeated_tasks.occurrences.edit' })}
      />

      <IconButton
        iconName="task"
        id="update-occurrence"
        onClick={updateOccurrence}
        text={t('this_event', { scope: 'repeated_tasks.occurrences.edit' })}
      />

      <Button
        className="btn btn--secondary"
        id="cancel-update"
        onClick={closeModal}
        text={t('cancel', { scope: 'repeated_tasks.occurrences.edit' })}
      />
    </div>
  </Modal>
)

UpdateModal.propTypes = {
  closeModal: PropTypes.func,
  modalIsOpen: PropTypes.bool,
  updateOccurrence: PropTypes.func,
  updateSeries: PropTypes.func,
}

export default UpdateModal
