import React from 'react'

import { Spinner } from 'Components/Base/Loader'

const ItemPillLoader = () => (
  <div className="icon item-pill__action">
    <Spinner />
  </div>
)

ItemPillLoader.propTypes = {}
ItemPillLoader.defaultProps = {}

export default ItemPillLoader
