import Api from './Api'

class Assignment extends Api {
  constructor(carePlanId, activityId, id = null) {
    super()

    this.carePlanId = carePlanId
    this.activityId = activityId
    this.id = id

    this.create = this.create.bind(this)
    this.update = this.update.bind(this)
  }

  // POST care_plan_activity_assignments_path
  create({ teamMembershipId }) {
    return super.create({
      assignment: {
        team_membership_id: teamMembershipId,
        status: 'accepted',
      },
    })
  }

  // PUT care_plan_activity_assignment_path
  update({ status }) {
    return super.update({
      assignment: { status },
    })
  }

  get url() {
    return `/care_plans/${this.carePlanId}/activities/${this.activityId}/assignments`
  }
}

export default Assignment
