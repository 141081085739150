const formatNumber = (number, type, isAvailable, decimalPlaces = 1) => {
  if (!isAvailable || number === null) return '*';

  const factor = 10 ** decimalPlaces;
  const roundedString = (Math.round(number * factor) / factor).toFixed(decimalPlaces);

  return type === 'percentage' ? `${roundedString}%` : String(roundedString);
};

export default formatNumber;
