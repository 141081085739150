import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import banner from 'circle-of-care-teams.svg'

import { Button, IconButton, ModalFooter } from 'Components/Base'
import AccordionSelect from 'Components/Forms/AccordionSelect'
import RoleOption from './RoleOption'
import Checkbox from 'Components/Forms/Checkbox'

const i18nOpts = { scope: 'team.modal.role' }

const RoleStep = ({ onClick, onCancel, role, onBack, onNext, onPermission, permission, name }) => (
  <Fragment>
    <header className="wave-header">
      <h2 className="wave-header__title">{t('header_title', i18nOpts)}</h2>
      <img src={banner} className="wave-header__banner wave-header__banner--circle" />
    </header>

    <h2 className="team-invitations-modal__header header hidden--sm hidden--vert-sm">
      {t('header_title', i18nOpts)}
    </h2>

    <p className="team-invitations-modal__description">{t('description', i18nOpts)}</p>

    <AccordionSelect
      onChange={onClick}
      options={['primary_caregiver', 'caregiver', 'support']}
      selected={role}
      renderOption={(role, collapsed, selected, onExpand, onSelect) => (
        <RoleOption
          role={role}
          collapsed={collapsed}
          selected={selected}
          onExpand={onExpand}
          onSelect={onSelect}
        />
      )}
    />

    <Checkbox
      checked={permission}
      name={'permission'}
      text={t('support_team_declaration', { scope: 'team.modal', name })}
      onChange={onPermission}
    />

    <ModalFooter
      renderRight={() => (
        <Fragment>
          <Button text={t('cancel', i18nOpts)} onClick={onCancel} className="btn btn--secondary" />
          <Button
            text={t('next', i18nOpts)}
            className="btn"
            onClick={onNext}
            disabled={!role || !permission}
          />
        </Fragment>
      )}
      renderLeft={() => (
        <IconButton iconName="chevron-left" isSecondary onClick={onBack} text={t('back', i18nOpts)} />
      )}
    />
  </Fragment>
)

RoleStep.propTypes = {
  name: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onPermission: PropTypes.func.isRequired,
  permission: PropTypes.bool.isRequired,
  role: PropTypes.string,
}

RoleStep.defaultProps = {
  role: '',
}

export default RoleStep
