import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import _ from 'lodash';

import { Modal } from 'Components/Base/Modal';
import IconButton from 'Components/Base/IconButton';
import EmailStep from './EmailStep';
import RoleStep from './RoleStep';
import ReviewStep from './ReviewStep';
import FinalStep from './FinalStep';
import { fetchSupportTeam } from 'Actions/supportTeam';
import {
  addMember,
  deleteMember,
  sendInvitations,
  resetTeamInvitationsModalState,
} from 'Actions/teamInvitationsModal';

import CarePlanApi from 'Api/CarePlan';

import { getAllMembers } from 'Selectors/supportTeam';
import { getMembers, getError } from 'Selectors/teamInvitationsModal';

const i18nOpts = { scope: 'team.modal' };

class TeamInvitationsModalContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      copied: false,
      modalIsOpen: props.showModal,
      email: '',
      inviteType: '',
      relationship: '',
      role: '',
      currentId: null,
      currentStep: 'email',
      teamType: '',
      permission: false,
      requestAccessToken: props.requestAccessToken,
      uhcpAccessToken: props.uhcpAccessToken,
    };

    this.createToken = this.createToken.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
    this.handleToggleModal = this.handleToggleModal.bind(this);
    this.handleRoleSelect = this.handleRoleSelect.bind(this);
    this.handleEmailSubmit = this.handleEmailSubmit.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleRoleNext = this.handleRoleNext.bind(this);
    this.handleInvite = this.handleInvite.bind(this);
    this.handleInviteMore = this.handleInviteMore.bind(this);
    this.handleEditMember = this.handleEditMember.bind(this);
    this.handleDeleteMember = this.handleDeleteMember.bind(this);
    this.handlePermission = this.handlePermission.bind(this);
    this.resetLocalState = this.resetLocalState.bind(this);
    this.handleTeamType = this.handleTeamType.bind(this);
    this.handleInviteType = this.handleInviteType.bind(this);
  }

  componentDidMount() {
    this.props.fetchSupportTeam(this.props.carePlanUuid);
  }

  get invitationLink() {
    const token =
      this.state.teamType === 'health' ? this.state.uhcpAccessToken : this.state.requestAccessToken;
    return `${this.props.startUrl}?request_token=${token}`;
  }

  createToken() {
    const carePlanApi = new CarePlanApi(this.props.carePlanUuid);

    return carePlanApi.readOrUpdateToken().then((res) => {
      if (res.ok) {
        return res
          .json()
          .then(({ request_access_token, uhcp_access_token }) =>
            this.setState({ requestAccessToken: request_access_token, uhcpAccessToken: uhcp_access_token })
          );
      } else if (res.status === 422) {
        return window.flash_messages.addMessage(t('team.access_requests.failure'));
      }
    });
  }

  handleCopy() {
    this.setState({ copied: true });
  }

  handlePermission() {
    this.setState((prevState) => ({ permission: !prevState.permission }));
  }

  handleToggleModal() {
    if (!this.state.requestAccessToken || !this.state.uhcpAccessToken) {
      this.createToken();
    }

    if (this.state.currentStep !== 'final' && this.props.members.length > 0) {
      this.setState({ currentStep: 'review' });
    } else {
      this.props.resetTeamInvitationsModalState();
      this.resetLocalState();
    }

    this.setState((prevState) => ({ modalIsOpen: !prevState.modalIsOpen }));
  }

  handleRoleSelect(role) {
    this.setState({ role });
  }

  handleEmailSubmit({ email, relationship }, { setSubmitting }) {
    if (this.state.teamType === 'health') {
      this.setState(
        { email, relationship: 'unaffiliated_health_care', role: 'unaffiliated_health_care' },
        this.handleRoleNext
      );
    } else {
      relationship = relationship || 'other';
      setSubmitting(false);
      this.setState({ currentStep: 'role', email, relationship });
    }
  }

  handleBack() {
    this.setState({ currentStep: 'email', permission: false });
  }

  handleRoleNext() {
    this.props.addMember({
      id: this.state.currentId,
      email: this.state.email,
      relationship: this.state.relationship,
      role: this.state.role,
    });
    this.setState({ currentStep: 'review', permission: false });
  }

  handleInvite() {
    return this.props.sendInvitations(this.props.carePlanUuid).then(() => {
      if (!this.props.error) {
        this.setState({ currentStep: 'final' });
      }
    });
  }

  handleInviteMore() {
    this.props.resetTeamInvitationsModalState();
    this.resetLocalState();
  }

  handleEditMember(id) {
    const selectedMember = this.props.members.find((member) => member.id === id);

    this.setState({
      currentId: id,
      currentStep: 'email',
      email: selectedMember.email,
      relationship: selectedMember.relationship,
      role: selectedMember.role,
      permission: false,
    });
  }

  handleDeleteMember(id) {
    if (this.props.members.length === 1) {
      this.resetLocalState();
    }

    this.props.deleteMember(id);
  }

  handleTeamType(teamType) {
    this.setState({
      teamType,
      email: '',
      permission: false,
      inviteType: teamType === 'health' ? 'code' : 'email',
    });
  }

  handleInviteType() {
    this.setState({ inviteType: this.state.inviteType === 'code' ? 'email' : 'code' });
  }

  resetLocalState() {
    this.setState({
      copied: false,
      currentId: null,
      currentStep: 'email',
      email: '',
      inviteType: '',
      relationship: '',
      role: '',
      teamType: '',
      permission: false,
    });
  }

  get membersByRole() {
    return _.groupBy(this.props.members, (member) => member.role);
  }

  get currentComponent() {
    switch (this.state.currentStep) {
      case 'email':
        return (
          <EmailStep
            relationshipOptions={this.props.relationshipOptions}
            persistedMembers={this.props.persistedMembers}
            members={this.props.members}
            onCancel={this.handleToggleModal}
            email={this.state.email}
            relationship={this.state.relationship}
            onSubmit={this.handleEmailSubmit}
            currentId={this.state.currentId}
            teamType={this.state.teamType}
            inviteType={this.state.inviteType}
            onInviteTypeSet={this.handleInviteType}
            onTeamTypeSet={this.handleTeamType}
            onPermission={this.handlePermission}
            permission={this.state.permission}
            requestAccessToken={this.props.requestAccessToken}
            invitationLink={this.invitationLink}
            copied={this.state.copied}
            onCopy={this.handleCopy}
            uhcpAccessToken={this.props.uhcpAccessToken}
            startUrl={this.props.startUrl}
            name={this.props.name}
            isPatient={this.props.isPatient}
          />
        );
      case 'role':
        return (
          <RoleStep
            onCancel={this.handleToggleModal}
            onClick={this.handleRoleSelect}
            role={this.state.role}
            onBack={this.handleBack}
            onNext={this.handleRoleNext}
            onPermission={this.handlePermission}
            permission={this.state.permission}
            name={this.props.name}
          />
        );
      case 'review':
        return (
          <ReviewStep
            onCancel={this.handleToggleModal}
            error={this.props.error}
            membersByRole={this.membersByRole}
            onEdit={this.handleEditMember}
            onDelete={this.handleDeleteMember}
            onAdd={this.resetLocalState}
            onInvite={this.handleInvite}
          />
        );
      case 'final':
        return (
          <FinalStep
            onDone={this.handleToggleModal}
            members={this.props.members}
            onInvite={this.handleInviteMore}
          />
        );
    }
  }

  render() {
    return (
      <Fragment>
        {this.props.showButton && (
          <IconButton
            iconName="add-member"
            className="full-wave-header__add-members"
            onClick={this.handleToggleModal}
            text={t('add_members', i18nOpts)}
          />
        )}
        <Modal
          title={this.props.firstVisit ? t('welcome', i18nOpts) : t('add_team_members', i18nOpts)}
          isOpen={this.state.modalIsOpen}
          closeModal={this.handleToggleModal}
        >
          {this.currentComponent}
        </Modal>
      </Fragment>
    );
  }
}

TeamInvitationsModalContainer.propTypes = {
  addMember: PropTypes.func.isRequired,
  carePlanUuid: PropTypes.string.isRequired,
  deleteMember: PropTypes.func.isRequired,
  error: PropTypes.bool,
  fetchSupportTeam: PropTypes.func.isRequired,
  firstVisit: PropTypes.bool,
  members: PropTypes.array,
  name: PropTypes.string.isRequired,
  persistedMembers: PropTypes.array,
  relationshipOptions: PropTypes.array.isRequired,
  requestAccessToken: PropTypes.string,
  resetTeamInvitationsModalState: PropTypes.func.isRequired,
  sendInvitations: PropTypes.func.isRequired,
  showButton: PropTypes.bool,
  showModal: PropTypes.bool,
  startUrl: PropTypes.string.isRequired,
  uhcpAccessToken: PropTypes.string,
};

TeamInvitationsModalContainer.defaultProps = {
  error: false,
  firstVisit: false,
  members: [],
  persistedMembers: [],
  requestAccessToken: null,
  showButton: true,
  showModal: false,
  uhcpAccessToken: null,
};

const mapStateToProps = (state) => {
  return {
    members: getMembers(state),
    error: getError(state),
    persistedMembers: getAllMembers(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addMember: (member) => dispatch(addMember(member)),
    resetTeamInvitationsModalState: () => dispatch(resetTeamInvitationsModalState()),
    sendInvitations: (carePlanUuid) => dispatch(sendInvitations(carePlanUuid)),
    deleteMember: (id) => dispatch(deleteMember(id)),
    fetchSupportTeam: (carePlanUuid) => dispatch(fetchSupportTeam(carePlanUuid)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamInvitationsModalContainer);
