import { createBrowserHistory, createMemoryHistory } from 'history'
import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { routerMiddleware } from 'connected-react-router'

import inboxReducer from 'Reducers/inbox/index'

export const isServer = !(typeof window !== 'undefined' && window.document && window.document.createElement)

export default function configureStore(preloadedState, url) {
  const history = isServer ? createMemoryHistory({ initialEntries: [url] }) : createBrowserHistory()

  const store = createStore(
    inboxReducer(history),
    preloadedState,
    composeWithDevTools(applyMiddleware(routerMiddleware(history), thunkMiddleware))
  )

  return {
    store,
    history,
  }
}
