import { createSelector } from 'reselect';

import { asc, desc } from 'Utilities/sort';
import ContactSearchIndex from 'Indexes/contact_search_index';
import CarePlanSearchIndex from 'Indexes/care_plan_search_index';

// Conversation
export const getConversation = (state) => state.conversation;

// ContactList
export const getSideNavOpenState = (state) => state.sideNav.general.isOpen;
export const getSideNavMode = (state) => state.sideNav.general.mode;
export const getSideNavFetchingState = (state) => state.sideNav.contacts.isFetching;
export const getSideNavFetchingChannelPageState = (state) => state.sideNav.contacts.isFetchingChannelPage;
export const getNewContactsFetchingState = (state) => state.sideNav.newContacts.isFetching;
export const getCarePlanFetchingState = (state) => state.sideNav.carePlans.isFetching;
export const getNewContacts = (state) => state.sideNav.newContacts.contacts;
export const getChannel = (state) => state.sideNav.channel;

export const getHasMoreChannelPage = (state) => state.sideNav.contacts.hasMoreChannelPage;
export const getIsSearchingForChannel = (state) =>
  state.sideNav.general.channelSearchQuery.isSearchingForChannel;
export const checkMissingSearchResult = (state) =>
  state.sideNav.general.channelSearchQuery.missingSearchResult;

export const getChannelSortBy = (state) => state.sideNav.general.channelSortBy.sortBy;

// MessageDrafts
export const getMessageDraft = (state) => state.messageDraft.drafts;

const getCarePlanList = (state) => state.sideNav.carePlans.carePlans;
const getContactList = (state) => state.sideNav.contacts.contacts;
const getChannelContactList = (state) => state.sideNav.contacts.channelContacts;
const getSortby = (state) => state.sideNav.general.sortBy;
const getSearchQuery = (state) => state.sideNav.general.searchQuery;

const sortFns = {
  newest: (a, b) => desc(a.conversation.lastMessageAt, b.conversation.lastMessageAt),
  oldest: (a, b) => asc(a.conversation.lastMessageAt, b.conversation.lastMessageAt),
  nameAsc: (a, b) => asc(a.name || a.title, b.name || b.title),
  nameDesc: (a, b) => desc(a.name || a.title, b.name || b.title),
};

export const getChannelSearchContacts = (state) => state.sideNav.contacts.channelSearchContacts;

export const getEmergencyInstruction = (state) => state.emergencyInstruction;

// MEMOIZED SELECTORS
// -- Contacts
const getContacts = createSelector([getContactList], (contacts) => new ContactSearchIndex(contacts));

export const getMemberContacts = createSelector(
  [getContacts, getSortby, getSearchQuery],
  (contacts, sortBy, searchQuery) => {
    return contacts
      .search(searchQuery)
      .filter((contact) => contact.type === 'Member')
      .sort(sortFns[sortBy]);
  }
);

export const getDeskContacts = createSelector(
  [getContacts, getSortby, getSearchQuery],
  (contacts, sortBy, searchQuery) => {
    return contacts
      .search(searchQuery)
      .filter((contact) => contact.type === 'Desk')
      .sort(sortFns[sortBy]);
  }
);

export const getChannelContacts = createSelector([getChannelContactList], (contacts) =>
  contacts.filter((contact) => contact.type === 'Channel')
);

export const getDirectContacts = createSelector(
  [getContacts, getSortby, getSearchQuery],
  (contacts, sortBy, searchQuery) => {
    return contacts
      .search(searchQuery)
      .filter((contact) => contact.type === 'Member')
      .sort((a, b) => sortFns.nameAsc(a, b))
      .sort((a, b) =>
        a.conversation.type !== 'NEW_DIRECT_MESSAGE'
          ? -1
          : b.conversation.type !== 'NEW_DIRECT_MESSAGE'
          ? 1
          : 0
      )
      .sort(sortFns[sortBy]);
  }
);

export const getChannelNames = createSelector([getChannelContacts], (channels) =>
  channels.map((channel) => channel.title)
);

// -- CarePlans
const getCarePlansIndex = createSelector(
  [getCarePlanList],
  (carePlans) => new CarePlanSearchIndex(carePlans)
);

export const getCarePlans = createSelector([getCarePlansIndex, getSearchQuery], (carePlans, searchQuery) =>
  carePlans.search(searchQuery)
);

// -- Channel Contacts
export const getSelectedChannelContacts = createSelector([getNewContacts], (contacts) =>
  contacts.filter((contact) => contact.isSelected)
);

export const getAllSelected = createSelector(
  [getNewContacts],
  (contacts) => contacts.filter((contact) => contact.isSelected).length === contacts.length
);

// -- Message Drafts
export const getMessageDraftById = createSelector(
  [getMessageDraft, getConversation],
  (drafts, conversation) => {
    const draft = drafts.find((draft) => draft.id === conversation.id);
    return draft ? draft.body : '';
  }
);
