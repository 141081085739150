import React, { useContext } from 'react';
import { Tooltip } from 'Components/Base';
import { t } from 'i18n';
import questionMarkIcon from 'question_mark.svg';
import { CarePlanContext } from 'Components/Dashboard/Dashboard';

import formatNumber from './formatNumber';

const i18nOpts = { scope: 'dashboard.device' };

const RenderNumber = ({ value, status }) => {
  if (status === 'FAILURE_EFFECTIVE_TIME_MEASURED_BELOW_12_DAYS') {
    return (
      <div className="stats-highlight__number stats-highlight__number--error-message">
        {t('time_range_below_12_days', i18nOpts)}
      </div>
    );
  }

  return <div className="stats-highlight__number stats-highlight__number--large">{value}</div>;
};

const RenderError = ({ error, firstName }) => {
  switch (error) {
    case 'FAILURE_MISSING_FHIR_TOKEN':
      return (
        <div className="stats-highlight__empty-data-text">{`* ${t('missing_fhir_token', {
          ...i18nOpts,
          firstName,
        })}`}</div>
      );
    case 'EMPTY_STATISTICS_DATA':
      return (
        <div className="stats-highlight__empty-data-text">{`* ${t(
          'no_data_present_timeframe',
          i18nOpts
        )}`}</div>
      );
    default:
      return null;
  }
};

const LowerHighlight = ({ data, timeRange, status, error }) => {
  if (status !== 'SUCCESS') return null;

  const dataForTimeRange = data[timeRange];
  const hasDataForTimeRange = dataForTimeRange.cgmActivePercentage.number > 0;

  let errorMessage;
  if (error !== 'FAILURE_MISSING_FHIR_TOKEN' && !hasDataForTimeRange) {
    errorMessage = 'EMPTY_STATISTICS_DATA';
  } else {
    errorMessage = error;
  }

  const {
    userDetails: { patientFirstName },
  } = useContext(CarePlanContext);

  return (
    <div className="stats-highlight__lower-container">
      {dataForTimeRange.glucoseAverages.map((datum) => (
        <div className="stats-highlight__unit" key={datum.header}>
          <RenderNumber
            status={datum.status}
            value={formatNumber(datum.number, datum.type, hasDataForTimeRange)}
          />
          <div className="stats-highlight__header">
            {datum.header}
            {datum?.tooltip && (
              <Tooltip
                className="stats-highlight__tooltip"
                text={datum.tooltip}
                tabIndex="0"
                position="up"
                length="large"
              >
                <span className="stats-highlight__tooltip__icon">
                  <img src={questionMarkIcon} alt={`${datum.header} tooltip`} />
                </span>
              </Tooltip>
            )}
          </div>
        </div>
      ))}
      <RenderError error={errorMessage} firstName={patientFirstName} />
    </div>
  );
};

export default LowerHighlight;
