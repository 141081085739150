import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Flash from './Flash'

class FlashMessages extends Component {
  constructor(props) {
    super(props)

    this.removeMessage = this.removeMessage.bind(this)
    this.nextMessage = this.nextMessage.bind(this)

    this.state = {
      index: props.messages.length,
      messages: props.messages,
      currentMessage: props.messages[0],
    }
  }

  componentDidMount() {
    window.flash_messages = this
  }

  // Allows adding a new Flash Message programatically
  // window.flash_messages.addMessage("Your Sweet Message")
  addMessage(text) {
    const newMessage = { id: this.state.index++, text }
    const currentMessage = this.state.currentMessage || newMessage

    this.setState({
      currentMessage,
      index: this.state.index++,
      messages: [...this.state.messages, newMessage],
    })
  }

  removeMessage() {
    const messages = this.state.messages.filter((msg) => msg.id !== this.state.currentMessage.id)

    this.setState({ messages, currentMessage: null })
  }

  nextMessage() {
    this.setState({ currentMessage: this.state.messages[0] })
  }

  render() {
    const { currentMessage } = this.state
    return currentMessage ? (
      <Flash text={currentMessage.text} onFadeOut={this.removeMessage} onUnmount={this.nextMessage} />
    ) : null
  }
}

FlashMessages.propTypes = {
  messages: PropTypes.array.isRequired,
}

export default FlashMessages
