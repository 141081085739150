import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'i18n';

import { Icon } from 'Components/Base';
import Button from 'Components/Base/Button';

import getFormHeaderClass from '../getFormHeaderClass';

import { setTargetUserInfo, setCurrentStep, goToNextStep } from 'Actions/signupPage';
import { trackCaregiverSelected, trackPatientSelected } from 'Mixpanel';

const i18nOpts = { scope: 'registration.representative' };

const RepresentativeForm = ({
  index,
  currentStep,
  placeName,
  setCurrentStep,
  setTargetUserInfo,
  goToNextStep,
  user,
}) => {
  const isPatient = user.role === 'patient';
  const completed = currentStep > index;
  const isOpen = currentStep === index;

  const handleSelectRole = (role) => {
    return setTargetUserInfo({
      name: 'role',
      value: role,
    });
  };

  const handleNextStep = async () => {
    await goToNextStep();
    isPatient ? trackPatientSelected() : trackCaregiverSelected();
  };

  return (
    <>
      <div
        className={getFormHeaderClass(completed, isOpen)}
        onClick={() => completed && setCurrentStep(index)}
      >
        <div className="header-title">{t('role', i18nOpts)}</div>
        {completed && !isOpen && (
          <Icon className="registrationV2__form__header__success_icon icon icon--bg" name="check" />
        )}
      </div>

      {isOpen && (
        <>
          <div className="form__body">
            <div className="form__body__role-option" onClick={() => handleSelectRole('patient')}>
              <div className="form__body__role-option__header">
                <Icon name={isPatient ? 'circle-selected' : 'circle-unselected'} />
                <div className="form__body__role-option__header__title">{t('myself', i18nOpts)}</div>
              </div>
              <div className="form__body__role-option__header__description">
                {t('patient_role', i18nOpts)}
              </div>
            </div>

            <div className="form__body__role-option" onClick={() => handleSelectRole('caregiver')}>
              <div className="form__body__role-option__header">
                <Icon name={!isPatient ? 'circle-selected' : 'circle-unselected'} />
                <div className="form__body__role-option__header__title">{t('someone_else', i18nOpts)}</div>
              </div>
              <p className="form__body__role-option__header__description">
                {t('caregiver_role', { ...i18nOpts, place: placeName })}
              </p>
            </div>
          </div>

          <div className="form__footer">
            <Button className="form__footer__submit" onClick={handleNextStep}>
              {t('next', i18nOpts)}
            </Button>
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ signupPage: { currentStep, user } }) => ({
  currentStep,
  user,
});

const mapDispatchToProps = {
  setCurrentStep: (index) => setCurrentStep(index),
  setTargetUserInfo: (targetInfo) => setTargetUserInfo(targetInfo),
  goToNextStep,
};

RepresentativeForm.propTypes = {
  index: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  setTargetUserInfo: PropTypes.func.isRequired,
  goToNextStep: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(RepresentativeForm);
