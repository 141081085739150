import React from 'react'
import PropTypes from 'prop-types'

import {
  ItemPill,
  ItemPillIcon,
  ItemPillActions,
  ItemPillActionIcon,
  ItemPillText,
} from 'Components/Base/ItemPill'

const CheckIn = ({ checkIn: { title, loading }, deleteCheckIn, editCheckIn }) => (
  <ItemPill selected>
    <ItemPillIcon iconName="task" />

    <ItemPillText text={title} data-test="display-name" />

    <ItemPillActions isLoading={loading}>
      <ItemPillActionIcon iconName="file-edit" onClick={editCheckIn} data-test="edit-icon" />
      <ItemPillActionIcon iconName="minus-circle" onClick={deleteCheckIn} data-test="delete-icon" />
    </ItemPillActions>
  </ItemPill>
)

CheckIn.propTypes = {
  checkIn: PropTypes.object.isRequired,
  deleteCheckIn: PropTypes.func.isRequired,
  editCheckIn: PropTypes.func.isRequired,
}

CheckIn.defaultProps = {}

export default CheckIn
