import Api from '../Api'

class PhoneNumber extends Api {
  constructor(carePlanUuid, id = null) {
    super()

    this.carePlanUuid = carePlanUuid
    this.id = id
  }

  create({ label, number, preferredNumber: preferred_number }) {
    return super.create({
      phone_number: {
        label,
        number,
        preferred_number,
      },
    })
  }

  update({ label, number, preferredNumber: preferred_number }) {
    return super.update({
      phone_number: {
        label,
        number,
        preferred_number,
      },
    })
  }

  get url() {
    return `/care_plans/${this.carePlanUuid}/patient/phone_numbers`
  }
}

export default PhoneNumber
