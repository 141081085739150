import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

import Textarea from './Textarea';
import Input from './Input';
import Select from './Select';
import EmojiTextInput, { EmojiInput } from './EmojiTextInput';
import DateTimeInput from './DateTimeInput';
import DateInput from './DateInput';
import MultiSelectStyle from 'styles/MultiSelect';
import Loader from 'Components/Base/Loader';
import { t } from 'i18n';

const ReactSelect = React.lazy(() => import('react-select'));

const orderOptions = (values) => {
  if (values === null) {
    return values;
  }
  // read only options appear first
  return values.sort((a, b) => (a.readOnly < b.readOnly ? 1 : -1));
};

const Field = (props) => {
  switch (props.component) {
    case 'textarea':
      return <Textarea {...props} />;
    case 'datetime':
      return <DateTimeInput id={props.name} {...props} />;
    case 'date':
      return <DateInput id={props.name} {...props} />;
    case 'input':
      return <Input {...props} />;
    case 'multi-select':
      return (
        <Suspense fallback={<Loader />}>
          <ReactSelect
            id={props.name}
            {...props}
            styles={{ ...MultiSelectStyle, ...props.styles }}
            value={orderOptions(props.value)}
            className="multi-select"
            noOptionsMessage={() => t('forms.no_options')}
            isMulti
            isClearable={false}
            maxMenuHeight={160}
          />
        </Suspense>
      );
    case 'select':
      return <Select {...props} />;
    case 'emoji':
      return <EmojiInput {...props} component={EmojiTextInput} />;
    default:
      return null;
  }
};

Field.propTypes = {
  component: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default Field;
