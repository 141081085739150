import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import IconButton from 'Components/Base/IconButton'
import Icon from 'Components/Base/Icon'
import LibraryDocumentSelect from 'Components/Forms/LibraryDocumentSelect'
import UploadedDocumentList from './UploadedDocumentList'
import SectionFooter from '../SectionFooter'
import DocumentPreviewModal from './DocumentPreviewModal'

import LibraryDocumentCopyDispatcherApi from 'Api/Dispatchers/LibraryDocumentCopyDispatcher'
import LibraryDocumentDispatcherApi from 'Api/Dispatchers/LibraryDocumentDispatcher'

const i18nOpts = { scope: 'care_plan_creators.documents' }

class Documents extends Component {
  constructor(props) {
    super(props)

    const documentSelectionStates = {}
    props.libraryDocuments.map((document) => {
      documentSelectionStates[document.id] = false
    })
    props.selectedLibraryDocuments.map((id) => {
      documentSelectionStates[id] = true
    })

    this.state = {
      documentSelectionStates,
      isSubmitting: false,
      modalIsOpen: false,
      previewIsOpen: false,
      previewLink: '',
      previewFile: {},
    }
    this.closeModal = this.closeModal.bind(this)
    this.openModal = this.openModal.bind(this)
    this.openPreview = this.openPreview.bind(this)
    this.closePreview = this.closePreview.bind(this)
    this.navigateToNextSection = this.navigateToNextSection.bind(this)
    this.updateLibraryDocuments = this.updateLibraryDocuments.bind(this)
    this.toggleLibraryDocumentSelection = this.toggleLibraryDocumentSelection.bind(this)
    this.previewDocument = this.previewDocument.bind(this)
  }

  openModal() {
    this.setState({ modalIsOpen: true })
  }

  closeModal() {
    this.setState({ modalIsOpen: false })
  }

  openPreview() {
    this.setState({ previewIsOpen: true })
  }

  closePreview() {
    this.setState({ previewIsOpen: false })
  }

  previewDocument(isLibraryDocument, document) {
    const isTemplate = this.props.isTemplate && !isLibraryDocument

    return new LibraryDocumentDispatcherApi(this.props.parentId, document.id, isTemplate)
      .previewLink()
      .then((res) => {
        return res.json().then(({ url }) => {
          this.setState({ previewIsOpen: true, previewLink: url, previewFile: document })
        })
      })
  }

  navigateToNextSection() {
    window.location.hash = '#instructions'
  }

  toggleLibraryDocumentSelection(documentId) {
    this.setState((prevState) => ({
      documentSelectionStates: {
        ...prevState.documentSelectionStates,
        [documentId]: !prevState.documentSelectionStates[documentId],
      },
    }))
  }

  updateLibraryDocuments() {
    this.setState({ isSubmitting: true })
    const documentSelectionStates = this.state.documentSelectionStates

    const selectedLibraryDocuments = Object.keys(documentSelectionStates).reduce((selected, id) => {
      return documentSelectionStates[id] ? [...selected, id] : selected
    }, [])

    return new LibraryDocumentCopyDispatcherApi(this.props.parentId, this.props.isTemplate)
      .update(selectedLibraryDocuments)
      .then((res) => {
        if (res.ok) {
          this.setState({ isSubmitting: false })
          this.navigateToNextSection()
        } else if (res.status === 422) {
          return res.json().then(console.error)
        }
      })
  }

  render() {
    return (
      <div className="item-pill-section">
        <h3 className="item-pill-section__header">
          <Icon name="documents-lg" className="item-pill-section__header-icon" />
          {t('library_documents', i18nOpts)}
        </h3>

        <LibraryDocumentSelect
          libraryDocuments={this.props.libraryDocuments}
          documentSelectionStates={this.state.documentSelectionStates}
          toggleSelection={this.toggleLibraryDocumentSelection}
          previewDocument={this.previewDocument.bind(null, true)}
        />

        <DocumentPreviewModal
          previewIsOpen={this.state.previewIsOpen}
          previewFile={this.state.previewFile}
          previewLink={this.state.previewLink}
          closePreview={this.closePreview}
          timeZone={this.props.timeZone}
        />

        <h3 className="item-pill-section__header">
          <Icon name="attached" className="item-pill-section__header-icon" />
          {t('uploaded_documents', i18nOpts)}
        </h3>

        <UploadedDocumentList
          parentId={this.props.parentId}
          closeModal={this.closeModal}
          documents={this.props.uploadedDocuments}
          isTemplate={this.props.isTemplate}
          modalIsOpen={this.state.modalIsOpen}
          openModal={this.openModal}
          previewDocument={this.previewDocument.bind(null, false)}
        />

        <SectionFooter onNext={this.updateLibraryDocuments} isSubmitting={this.state.isSubmitting}>
          <IconButton
            iconName="file-upload"
            isSecondary
            onClick={this.openModal}
            text={t('upload_documents', i18nOpts)}
          />
        </SectionFooter>
      </div>
    )
  }
}

Documents.propTypes = {
  isTemplate: PropTypes.bool,
  libraryDocuments: PropTypes.array,
  selectedLibraryDocuments: PropTypes.array,
  timeZone: PropTypes.string,
  uploadedDocuments: PropTypes.array,
}

Documents.defaultProps = {
  uploadedDocuments: [],
  libraryDocuments: [],
  isTemplate: false,
  selectedLibraryDocuments: [],
  timeZone: 'Etc/UTC',
}

export default Documents
