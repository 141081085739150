import { createSelector } from 'reselect'

import { getFilter, getCarePlanFilter } from './filter'
import { getCurrentUser } from './currentUser'

// Non-memoized input-selectors
export const getEvents = (state) => state.agenda.events
export const getDays = (state) => Object.keys(state.agenda.events)
export const getPreviousYear = (state) => state.agenda.previousYear
export const getYear = (state) => state.agenda.year
export const getNextYear = (state) => state.agenda.nextYear
export const getPreviousWeek = (state) => state.agenda.previousWeek
export const getWeek = (state) => state.agenda.week
export const getNextWeek = (state) => state.agenda.nextWeek
export const isFetching = (state) => state.agenda.isFetching
export const getFirstDay = (state) => state.agenda.firstDay
export const getLastDay = (state) => state.agenda.lastDay
export const getHealthCareView = (state) => state.agenda.healthCareView

export const applyFilters = (events, filter, currentUser, excludedCarePlans) => {
  let filteredEvents
  switch (filter) {
    case 'all':
      filteredEvents = events
      break
    case 'assigned':
      filteredEvents = events.filter((e) => e.assignments && e.assignments.length)
      break
    case 'accepted':
      filteredEvents = events.filter(
        (e) =>
          e.assignments && e.assignments.length && e.assignments.filter((a) => a.status === 'accepted').length
      )
      break
    case 'pending':
      filteredEvents = events.filter(
        (e) =>
          e.assignments && e.assignments.length && e.assignments.filter((a) => a.status === 'pending').length
      )
      break
    case 'unassigned':
      filteredEvents = events.filter((e) => !e.assignments || !e.assignments.length)
      break
    case 'mine':
      filteredEvents = events.filter(
        (e) =>
          e.assignments &&
          e.assignments.length &&
          e.assignments.filter((a) => a.assignedUserId === currentUser.id).length
      )
      break
    default:
      throw 'filter does not exist'
  }
  return filteredEvents.filter((e) => !excludedCarePlans.includes(e.carePlanUuid))
}

// Memoized selectors
export const getVisibleEvents = createSelector(
  [getEvents, getDays, getFilter, getCurrentUser, getCarePlanFilter],
  (events, days, filter, currentUser, excludedCarePlans) => {
    const visibleEvents = {}

    days.forEach((day) => {
      visibleEvents[day] = applyFilters(events[day], filter, currentUser, excludedCarePlans)
    })

    return visibleEvents
  }
)

export const hasVisibleEvents = createSelector([getVisibleEvents], (visibleEvents) => {
  const allEvents = Object.keys(visibleEvents).map((day) => visibleEvents[day])

  for (let i = 0; i < allEvents.length; i++) {
    const dailyEvents = allEvents[i]

    if (dailyEvents.length > 0) {
      return true
    }
  }

  return false
})
