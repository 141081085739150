import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Button, ModalFooter } from 'Components/Base'
import banner from 'invited.svg'

const i18nOpts = { scope: 'team.modal.final' }

const FinalStep = ({ members, onInvite, onDone }) => {
  return (
    <Fragment>
      <header className="wave-header">
        <h2
          dangerouslySetInnerHTML={{ __html: t('header_title_html', { ...i18nOpts, size: members.length }) }}
          className="wave-header__title"
        />
        <img src={banner} className="wave-header__banner wave-header__banner--invite-confirm" />
      </header>

      <h2
        className="team-invitations-modal__header header hidden--sm hidden--vert-sm"
        dangerouslySetInnerHTML={{ __html: t('header_title_html', { ...i18nOpts, size: members.length }) }}
      />

      <p
        className="team-invitations-modal__description"
        dangerouslySetInnerHTML={{ __html: t('description_html', i18nOpts) }}
      />

      <div className="team-invitations-modal__members">
        {members.map((member) => (
          <div key={member.id} className="item-pill team-invitations-modal__member-pill item-pill--flat">
            <div className="item-pill__text">
              <div>
                <strong className="team-invitations-modal__pill-email">{member.email}</strong>
                <span>{t(member.relationship, { scope: 'relationship' })}</span>
                <span>{t(member.role, i18nOpts)}</span>
              </div>
            </div>
          </div>
        ))}
      </div>

      <ModalFooter
        renderRight={() => (
          <Fragment>
            <Button
              data-test="done"
              text={t('done', i18nOpts)}
              onClick={onDone}
              className="btn btn--secondary"
            />
            <Button data-test="invite" text={t('invite_more', i18nOpts)} className="btn" onClick={onInvite} />
          </Fragment>
        )}
      />
    </Fragment>
  )
}

FinalStep.propTypes = {
  members: PropTypes.array.isRequired,
  onDone: PropTypes.func.isRequired,
  onInvite: PropTypes.func.isRequired,
}

FinalStep.defaultProps = {}

export default FinalStep
