import React from 'react';
import { t } from 'i18n';
import moment from 'ct-moment';

import Datetime from 'react-datetime';
import InputGroup from 'Components/Forms/InputGroup';
import Icon from 'Components/Base/Icon'
import PropTypes from 'prop-types'

const i18nOpts = { scope: 'care_plan_creators.details.details_form' };

const DateModalContents = ({ date, setDate, setModalStatus, modalState, currentDate, originalDate, errors }) => (
  <>
    <div className="ReactModal__Calendar__ContentText">
      {t('date_update_label', i18nOpts)}
    </div>
    <div className="ReactModal__Calendar__Content--date-input">
      <InputGroup component="input" value={date} errors={errors} />
      {date !== currentDate &&
        <div className="ReactModal__Calendar__ContentImage" onClick={() => {
          setDate(currentDate);
          currentDate === originalDate ? setModalStatus('ready') : setModalStatus('selected');
        }}>
          <Icon name="revert" className="ReactModal__Calendar__ContentImage--icon" />
          {t('revert_to_default_label', { ...i18nOpts, date: currentDate })}
        </div>
      }
    </div>
    <Datetime
      className={"ReactModal__Calendar__ContentModal"}
      value={date}
      timeFormat={false}
      input={false}
      onChange={(e) => {
        const updatedDate = moment(e).format('MM/DD/YYYY');
        setDate(updatedDate);
        originalDate === updatedDate ? setModalStatus('ready') : setModalStatus('selected');
      }}
    />
    {modalState === 'apiError' && (
      <div className="ReactModal__Calendar__api-error">
        {errors}
      </div>
    )}
  </>
);

DateModalContents.propTypes = {
  currentDate: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  setDate: PropTypes.func.isRequired,
  setModalStatus: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
};

export default DateModalContents;
