import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import XAxisTickFormatter from '../XAxisTickFormatter';
import Icon from 'Components/Base/Icon';

import {
  ResponsiveContainer,
  ComposedChart,
  CartesianGrid,
  Line,
  Scatter,
  YAxis,
  XAxis,
  Text,
} from 'recharts';

const i18nOpts = { scope: 'blocks.my_emotions' };
const FORM_MAX_VALUE = 10;

const RenderMissingData = ({ x, index, value, data }) => {
  if (value === null && !data[index].isFuture) {
    return (
      <g>
        <foreignObject x={x} y={60} className="icon close">
          <Icon className="no-data" name="close" />
        </foreignObject>
      </g>
    );
  }

  return null;
};

const CustomizedDot = ({ fill, cx, cy, payload: { displayDot, value } }) => {
  return displayDot ? (
    <svg x={cx + 2.5} y={value === 0 ? cy - 4 : cy}>
      <g transform="translate(4 4)">
        <circle r={4} fill={fill} />
      </g>
    </svg>
  ) : null;
};

const Chart = ({ data }) => {
  const dataLength = data.length - 1;

  return (
    <div className="chart my-emotions">
      {data.map(({ data, name, overallFill, overtimeFill, overall, maxValue }, formIndex) => {
        return (
          <Fragment key={`${name}-${formIndex}`}>
            <div className="chart__title">{t(name, i18nOpts)}</div>
            <ResponsiveContainer width="100%" height={180}>
              <ComposedChart height={40} data={data} margin={{ right: 35, left: 25, top: 10, bottom: 5 }}>
                <CartesianGrid vertical={false} />

                <Line
                  dot={<CustomizedDot fill={overtimeFill} />}
                  key={formIndex}
                  data={data}
                  dataKey="value"
                  name={name}
                  strokeWidth={2}
                  stroke={overtimeFill}
                  label={(args) => <RenderMissingData {...args} data={data} />}
                />

                <Scatter
                  dataKey="overall"
                  fill="overallFill"
                  shape={({ x, y, day }) => {
                    if (day === 'Sun-afternoon') {
                      return (
                        <g transform={`translate(${x}, ${y + 25})`}>
                          <circle r={overall * 2} fill={overallFill} />
                        </g>
                      );
                    }
                  }}
                />

                <YAxis
                  tickMargin={25}
                  axisLine={false}
                  tickLine={false}
                  domain={[0, FORM_MAX_VALUE]}
                  tickCount={maxValue > 0 ? 5 : 2}
                  tick={({ x, y, className, payload: { value } }) => {
                    const getYAxisTickValue = (val) => {
                      if (val === 0) return t('not_at_all', i18nOpts);
                      if (val >= FORM_MAX_VALUE) return t('extremely', i18nOpts);
                      if (val > 0 && val < FORM_MAX_VALUE) return '';
                    };

                    return (
                      <g transform={`translate(${x - 55}, ${y + 5})`}>
                        <Text className={`${className} text text-subtle`}>{getYAxisTickValue(value)}</Text>
                      </g>
                    );
                  }}
                />

                <XAxis
                  interval={2}
                  dataKey="day"
                  type="category"
                  allowDuplicatedCategory={false}
                  tickLine={false}
                  tick={dataLength === formIndex && <XAxisTickFormatter />}
                />
              </ComposedChart>
            </ResponsiveContainer>
            <hr />
          </Fragment>
        );
      })}
    </div>
  );
};

Chart.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Chart;
