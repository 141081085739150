import Api from 'Api/Api'

class CheckInTemplate extends Api {
  constructor(id) {
    super()

    this.id = id
  }

  get url() {
    return '/check_in_templates'
  }
}

export default CheckInTemplate
