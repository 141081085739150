import React, { Component } from 'react'
import _ from 'lodash'
import { t } from 'i18n'
import PropTypes from 'prop-types'

import MoodScaleQuestionOption from './MoodScaleQuestionOption'

const i18nOpts = { scope: 'care_plan_creators.check_in_templates.questions' }

const colorCodes = {
  0: '#e30712',
  1: '#e30712',
  2: '#f25116',
  3: '#f25116',
  4: '#56a701',
  5: '#56a701',
  6: '#56a701',
  7: '#f25116',
  8: '#f25116',
  9: '#e30712',
  10: '#e30712',
}

class MoodScaleQuestion extends Component {
  constructor(props) {
    super(props)
    this.renderOption = this.renderOption.bind(this)
    this.buildQuestionOptions = this.buildQuestionOptions.bind(this)
    this.updateOptions = this.updateOptions.bind(this)

    let questionOptions = props.checkInQuestionOptions || []
    const count = this.props.optionCount

    if (_.isEmpty(questionOptions)) {
      questionOptions = this.buildQuestionOptions(count)
      this.props.onChange({ target: { name: 'checkInQuestionOptions', value: questionOptions } })
    }

    this.state = {
      questionOptions,
    }
  }

  buildQuestionOptions(maxValue) {
    const _this = this
    return _.map(_.range(1, maxValue + 1), (index) => {
      return _this.buildBlankOption(index - 1)
    })
  }

  buildBlankOption(index) {
    return {
      type: 'ScaleBasedOption',
      data: {
        text: '',
        description: '',
        response: t('default_response', i18nOpts),
        index,
        order: index,
        colorCode: colorCodes[10 - index],
        score: 10 - index,
      },
    }
  }

  updateOptions(optionValues) {
    const index = optionValues.index
    const { questionOptions } = this.state

    const questionOption = _.find(questionOptions, (q) => {
      return q.data.index === index
    })

    Object.assign(questionOption.data, optionValues)
    this.setState({ questionOptions })
    this.props.onChange({ target: { name: 'checkInQuestionOptions', value: questionOptions } })
  }

  renderOption(questionOption, index) {
    return (
      <MoodScaleQuestionOption
        isActive={this.props.isActive}
        renderConditionalResponse={this.props.renderConditionalResponse}
        key={index}
        questionOption={questionOption}
        updateOptions={this.updateOptions}
        errors={this.props.errors}
      />
    )
  }

  render() {
    const { questionOptions } = this.state
    return questionOptions.map(this.renderOption)
  }
}

MoodScaleQuestion.propTypes = {
  checkInQuestionOptions: PropTypes.array,
  errors: PropTypes.object,
  isActive: PropTypes.bool,
  onChange: PropTypes.func,
  optionCount: PropTypes.number.isRequired,
  renderConditionalResponse: PropTypes.func.isRequired,
}

MoodScaleQuestion.defaultProps = {
  checkInQuestionOptions: [],
  errors: {},
  isActive: false,
  onChange: null,
}

export default MoodScaleQuestion
