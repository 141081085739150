import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { AttributeWithIcon, Button } from 'Components/Base'
import Avatar from 'PulseBackports/Avatar'

const i18nOpts = { scope: 'team.access_requests' }

const AccessRequest = ({
  email,
  name,
  photo,
  preferredPhoneNumber,
  relationship,
  uhcpRequest,
  onClick,
  role,
}) => (
  <li className="support-team-member">
    <div className="support-team-member__avatar">
      <Avatar
        src={photo}
        fullName={name}
        size={2.5}
      />
    </div>

    <div className="support-team-member__details">
      <div className="support-team-member__section support-team-member__section--name">
        <h3 className="support-team-member__name">{name}</h3>

        <span className="support-team-member__relationship">
          {role
            ? t(role, { scope: 'team.unaffiliated_contact_team' })
            : t(relationship, { scope: 'relationship' })}
        </span>
      </div>

      <div className="support-team-member__contact-information text-truncate">
        <div className="support-team-member__section support-team-member__section--phone">
          {!uhcpRequest && (
            <AttributeWithIcon iconName="phone_alt">
              {preferredPhoneNumber ? (
                <a className="text-truncate" href={`tel:${preferredPhoneNumber.number}`}>
                  {preferredPhoneNumber.number}
                </a>
              ) : (
                <span className="text-grey-alt">{t('unknown', i18nOpts)}</span>
              )}
            </AttributeWithIcon>
          )}
        </div>

        <div className="support-team-member__section support-team-member__section--email">
          {!uhcpRequest && (
            <AttributeWithIcon iconName="mail_alt2">
              <a className="text-truncate" href={`mailto:${email}`}>
                {email}
              </a>
            </AttributeWithIcon>
          )}
        </div>
      </div>

      <div className="support-team-member__section support-team-member__section--resend">
        <Button className="btn btn--secondary btn--extra-small" onClick={onClick}>
          {t('approve', i18nOpts)}
        </Button>
      </div>
    </div>
  </li>
)

AccessRequest.propTypes = {
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  photo: PropTypes.string,
  preferredPhoneNumber: PropTypes.shape({
    number: PropTypes.string.isRequired,
  }),
  relationship: PropTypes.string.isRequired,
  role: PropTypes.string,
  uhcpRequest: PropTypes.bool,
}

AccessRequest.defaultProps = {
  preferredPhoneNumber: null,
  role: null,
  uhcpRequest: false,
}

export default AccessRequest
