const transformDataToCamelCase = (data) => ({
  longInsulinType: data.long_insulin_type,
  rapidInsulinType: data.rapid_insulin_type,
  breakfastLong: data.breakfast_long,
  breakfastICR: data.breakfast_icr,
  breakfastISF: data.breakfast_isf,
  lunchLong: data.lunch_long,
  lunchICR: data.lunch_icr,
  lunchISF: data.lunch_isf,
  dinnerLong: data.dinner_long,
  dinnerICR: data.dinner_icr,
  dinnerISF: data.dinner_isf,
  bedtimeLong: data.bedtime_long,
  bedtimeICR: data.bedtime_icr,
  bedtimeISF: data.bedtime_isf,
});

export default transformDataToCamelCase;
