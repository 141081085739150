import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'Components/Base';

const IconChip = ({ text, color, iconName }) => (
  <span className={`chip chip--${color}`}>
    <Icon name={iconName} /> {text}
  </span>
);

IconChip.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
};

IconChip.defaultProps = {
  color: '',
};

export default IconChip;
