import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';

import { Icon, Tooltip } from 'Components/Base';
import { RequestedHelp } from 'Components/HelpRequest';

const i18nOpts = { scope: 'care_plans.medical_order_section.medical_order' };

const MedicalOrder = ({ practitioner, link, text, url, dueAtFormatted, completedAt, helpRequest }) => (
  <li className="order">
    <div className="order__section text-truncate">
      <p>
        <strong>{practitioner}</strong>
      </p>
      <strong>{text}</strong>
      {url && (
        <p>
          <a href={url} target="_blank">
            {url}
          </a>
        </p>
      )}
      <RequestedHelp helpRequested={!!helpRequest} />
    </div>

    <div className="order__section">
      <div className="order__section--status">
        {dueAtFormatted ? (
          dueAtFormatted
        ) : completedAt ? (
          <span className="order--complete">{t('booked', i18nOpts)}</span>
        ) : (
          <span className="order--due">{t('not_booked', i18nOpts)}</span>
        )}
      </div>
      <Tooltip text={t('edit', i18nOpts)} className="order__section--tooltip">
        <a href={link}>
          <Icon name="pencil-edit" />
        </a>
      </Tooltip>
    </div>
  </li>
);

MedicalOrder.propTypes = {
  completedAt: PropTypes.string,
  dueAtFormatted: PropTypes.string,
  helpRequest: PropTypes.object,
  link: PropTypes.string.isRequired,
  practitioner: PropTypes.string,
  text: PropTypes.string.isRequired,
  url: PropTypes.string,
};

MedicalOrder.defaultProps = {
  completedAt: '',
  dueAtFormatted: '',
  helpRequest: {},
  practitioner: '',
  url: '',
};

export default MedicalOrder;
