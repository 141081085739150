import React from 'react'
import { connect } from 'react-redux';
import { t } from 'i18n'

const i18nOpts = { scope: 'inbox.side_nav.contacts.header' }
import Button from 'Components/Base/Button'

import { showChannelCarePlans } from 'Actions/inbox/sideNav/channel'
import { getCarePlans } from 'Selectors/inbox'

const CreateButton = ({ carePlans, openCarePlanDrawer }) => {
  if (!carePlans.length) return null;

  return (
    <Button onClick={openCarePlanDrawer} className="btn btn--extra-small">
      {t("users", i18nOpts)}
    </Button>
  );
}

const mapStateToProps = (state) => ({
  carePlans: getCarePlans(state)
})

const mapDispatchToProps = (dispatch) => ({
  openCarePlanDrawer: () => dispatch(showChannelCarePlans()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateButton)
