import { request } from './handleRequest';

const updateActionPlanStartAtApi = async (date, resourceId) => {
  try {
    const body = {
      date
    };
    const { status } = await request(`/intake/${resourceId}/update_start_at`, 'PATCH', body);
    return { status };
  } catch (error) {
    return { status: 500, error };
  }
};

export default updateActionPlanStartAtApi;
