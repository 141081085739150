import React from 'react'
import PropTypes from 'prop-types'

import Appointment from './Appointment'
import Prescription from './Prescription'
import Status from './Status'
import Task from './Task'

const Activity = (props) => {
  const { type } = props
  const components = {
    appointment: Appointment,
    prescription: Prescription,
    status: Status,
    task: Task,
  }

  const Component = components[type]
  if (!Component) return <span></span>
  return <Component {...props} />
}

Activity.propTypes = {
  type: PropTypes.string.isRequired,
}

export default Activity
