import React, { useState } from 'react';
import { t } from 'i18n';
import { connect } from 'react-redux';
import InputGroup from 'Components/Forms/InputGroup';
import ActionButton from 'Components/Base/ActionButton';

const i18nOpts = { scope: 'blocks.recommendations' };

import { setIsProcessing } from 'Actions/recommendationsBlock';

const RecommendationInput = ({
  content,
  handleCancel,
  handleSave,
  editingRecommendationIndex,
  isProcessing,
  setIsProcessing,
}) => {
  const [textContent, setTextContent] = useState(content || '');
  const [errors, setErrors] = useState({});

  // -1 represents adding a new recommendation; any other index represents editing an existing recommendation
  const isAddingNewRecommendation = editingRecommendationIndex == -1;

  return (
    <div className="recommendation__container">
      <InputGroup
        component="textarea"
        value={textContent}
        onChange={(evt) => setTextContent(evt.target.value)}
        errors={errors}
        name="recommendationInput"
        placeholder={t('placeholder', i18nOpts)}
        autofocus={true}
      />
      <div className="recommendation__action-button__container">
        <ActionButton
          className="recommendation__action-button"
          isLoading={isProcessing}
          onClick={() => {
            handleSave(textContent, setErrors, setIsProcessing);
          }}
          content={t('save', i18nOpts)}
        />
        <ActionButton
          className="button-subtle recommendation__action-button"
          disabled={isProcessing}
          onClick={handleCancel}
          content={isAddingNewRecommendation ? t('cancel', i18nOpts) : t('discard_changes', i18nOpts)}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ recommendationsBlock: { editingRecommendationIndex, isProcessing } }) => {
  return { editingRecommendationIndex, isProcessing };
};

const mapDispatchToProps = (dispatch) => ({
  setIsProcessing: (isProcessing) => dispatch(setIsProcessing(isProcessing)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecommendationInput);
