import React from 'react'
import PropTypes from 'prop-types'

import Avatar from 'PulseBackports/Avatar'

const Avatars = ({ participants }) => {
  const showExtraParticipants = participants.length > 3
  const extraParticipantCount = participants.length - 2

  return (
    <div className="inbox-menu__contact-avatars">
      {participants.slice(0, showExtraParticipants ? 2 : 3).map(({ name, photo }) => (
        <Avatar
          key={name}
          src={photo}
          fullName={name}
          size={2.625}
          border={true}
        />
      ))}

      {showExtraParticipants && (
        <div
          data-text={`+${extraParticipantCount}`}
          className="inbox-menu__contact-avatar avatar avatar--text avatar--raised"
        />
      )}
    </div>
  )
}

Avatars.propTypes = {
  participants: PropTypes.array.isRequired,
}

const Channel = ({ isUnread, isActive, onClick, participants, title }) => (
  <div onClick={onClick} className={`inbox-menu__contact ${isActive && 'inbox-menu__contact--current'}`}>
    <Avatars participants={participants} />

    <div className="inbox-menu__contact-details">
      <div className="inbox-menu__contact-name text-truncate">{title}</div>
      <div className="inbox-menu__contact-careplan">{participants.map(({ name }) => name).join(', ')}</div>
    </div>

    <div className="inbox-menu__contact-unread">
      {isUnread && <div className="inbox-menu__contact-unread-badge" />}
    </div>
  </div>
)

Channel.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isUnread: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  participants: PropTypes.array,
  title: PropTypes.string,
}

Channel.defaultProps = {
  isUnread: false,
  channel: [],
  title: '',
}

export default Channel
