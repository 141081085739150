import getTemplatesApi from './getTemplatesApi';

export const handleTemplatesSetupWrapper = (getTemplatesApiProvider = getTemplatesApi) => {
  return async (
    setActivityModalLibraryDropdownState,
    setActivityModalLibraryDropdownTemplates,
    setActivityModalLibraryDropdownTemplatesToDisplay,
    carePlanId,
    activityType
  ) => {
    setActivityModalLibraryDropdownState('LOADING');

    try {
      const { responseType, templates } = await getTemplatesApiProvider(carePlanId, activityType);

      if (responseType === 'DATA') {
        setActivityModalLibraryDropdownTemplates(templates);
        setActivityModalLibraryDropdownTemplatesToDisplay(templates);
        setActivityModalLibraryDropdownState('DEFAULT');
      } else {
        throw 'Missing data';
      }
    } catch (error) {
      setActivityModalLibraryDropdownState('ERROR');
      console.error(error);
    }
  };
};

export default handleTemplatesSetupWrapper(getTemplatesApi);
