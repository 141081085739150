// https://stackoverflow.com/a/11654596
function updateQueryString(queryParamKey, queryParamValue) {
  let hash
  let url = window.location.href;
  const regex = new RegExp("([?&])" + queryParamKey + "=.*?(&|#|$)(.*)", "gi")

  if (regex.test(url)) {
    if (typeof queryParamValue !== 'undefined' && queryParamValue !== null) {
      return url.replace(regex, '$1' + queryParamKey + "=" + queryParamValue + '$2$3')
    }
    else {
      hash = url.split('#')
      url = hash[0].replace(regex, '$1$3').replace(/(&|\?)$/, '');
      if (typeof hash[1] !== 'undefined' && hash[1] !== null) {
        url += '#' + hash[1]
      }
      return url
    }
  }
  else {
    if (typeof queryParamValue !== 'undefined' && queryParamValue !== null) {
      const separator = url.indexOf('?') !== -1 ? '&' : '?'
      hash = url.split('#')
      url = hash[0] + separator + queryParamKey + '=' + queryParamValue
      if (typeof hash[1] !== 'undefined' && hash[1] !== null) {
        url += '#' + hash[1]
      }
      return url
    }
    else {
      return url
    }
  }
}

export { updateQueryString }
