import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Flash extends Component {
  constructor(props) {
    super(props)

    this.fadeOut = this.fadeOut.bind(this)

    this.state = {
      className: 'flash',
      handleTransitionEnd: this.fadeOut,
    }
  }

  get startDelay() {
    return 1000
  }

  get displayTime() {
    return 7000
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ className: 'flash flash--show' })
    }, this.startDelay)
  }

  componentWillUnmount() {
    this.props.onUnmount()
  }

  // Run this function once the slide-up transition has ended
  fadeOut() {
    setTimeout(() => {
      this.setState({ className: 'flash', handleTransitionEnd: this.props.onFadeOut })
    }, this.displayTime)
  }

  render() {
    return (
      <div className={this.state.className} onTransitionEnd={this.state.handleTransitionEnd}>
        {this.props.text}
      </div>
    )
  }
}

Flash.propTypes = {
  onFadeOut: PropTypes.func,
  onUnmount: PropTypes.func,
  text: PropTypes.string.isRequired,
}

export default Flash
