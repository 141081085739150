export const SET_API_ERROR = 'SET_API_ERROR';
export const SET_CURRENT_PAGE_NUMBER = 'SET_CURRENT_PAGE_NUMBER';
export const SET_CURRENT_SEARCH_INPUT = 'SET_CURRENT_SEARCH_INPUT';
export const SET_CURRENT_TAB = 'SET_CURRENT_TAB';
export const SET_FILTERED_SIGNUPS = 'SET_FILTERED_SIGNUPS';
export const SET_FILTER_BY_STATUS_DROPDOWN_INPUT = 'SET_FILTER_BY_STATUS_DROPDOWN_INPUT';
export const SET_IS_FILTER_BY_STATUS_DROPDOWN_OPEN = 'SET_IS_FILTER_BY_STATUS_DROPDOWN_OPEN';
export const SET_IS_TARGET_SIGNUP_MODAL_OPEN = 'SET_IS_TARGET_SIGNUP_MODAL_OPEN';
export const SET_PAGE_STATE = 'SET_PAGE_STATE';
export const SET_SIGNUPS = 'SET_SIGNUPS';
export const SET_TARGET_SIGNUP_MODAL_API_ERROR = 'SET_TARGET_SIGNUP_MODAL_API_ERROR';
export const SET_TARGET_SIGNUP_MODAL_STATUS = 'SET_TARGET_SIGNUP_MODAL_STATUS';
export const SET_TARGET_SIGNUP_MODAL_TARGET = 'SET_TARGET_SIGNUP_MODAL_TARGET';

const setApiError = (apiError) => ({
  type: SET_API_ERROR,
  apiError,
});

const setCurrentPageNumber = (pageNumber) => ({
  type: SET_CURRENT_PAGE_NUMBER,
  pageNumber,
});

const setCurrentSearchInput = (input) => ({
  type: SET_CURRENT_SEARCH_INPUT,
  input,
});

const setCurrentTab = (currentTab) => ({
  type: SET_CURRENT_TAB,
  currentTab,
});

const setFilteredSignups = (signups) => ({
  type: SET_FILTERED_SIGNUPS,
  signups,
});

const setFilterByStatusDropdownInput = (input) => ({
  type: SET_FILTER_BY_STATUS_DROPDOWN_INPUT,
  input,
});

const setIsFilterByStatusDropdownOpen = (isDropdownOpen) => ({
  type: SET_IS_FILTER_BY_STATUS_DROPDOWN_OPEN,
  isDropdownOpen,
});

const setIsTargetSignupModalOpen = (isOpen) => ({
  type: SET_IS_TARGET_SIGNUP_MODAL_OPEN,
  isOpen,
});

const setPageState = (pageState) => ({
  type: SET_PAGE_STATE,
  pageState,
});

const setSignups = (signups) => ({
  type: SET_SIGNUPS,
  signups,
});

const setTargetSignupModalApiError = (apiError) => ({
  type: SET_TARGET_SIGNUP_MODAL_API_ERROR,
  apiError,
});

const setTargetSignupModalStatus = (status) => ({
  type: SET_TARGET_SIGNUP_MODAL_STATUS,
  status,
});

const setTargetSignupModalTarget = (target) => ({
  type: SET_TARGET_SIGNUP_MODAL_TARGET,
  target,
});

export {
  setApiError,
  setCurrentPageNumber,
  setCurrentSearchInput,
  setCurrentTab,
  setFilteredSignups,
  setFilterByStatusDropdownInput,
  setIsFilterByStatusDropdownOpen,
  setIsTargetSignupModalOpen,
  setPageState,
  setSignups,
  setTargetSignupModalApiError,
  setTargetSignupModalStatus,
  setTargetSignupModalTarget,
};
