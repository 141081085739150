import React from 'react';
import { ReferralForm } from 'Components/Resources/ReferralLink';
import { t } from 'i18n';

const i18nOpts = { scope: 'care_plans.referral_section.index' };

const ReferralConfirmation = ({ address, closeModal, isSSO, link, locale, onClick, isPlaceUser, onBehalf, carePlanId }) => (
  <>
    <header className="no-wave-header">
      <div className="no-wave-header__header-title text-brand text-bold">{t('title', i18nOpts)}</div>
    </header>
    <h4 className="text-grey-darkest header">{t('description', i18nOpts)}</h4>
    <ReferralForm
      address={address}
      locale={locale}
      link={link}
      isSSO={isSSO}
      onClick={onClick}
      closeModal={closeModal}
      onBehalf={onBehalf}
      isPlaceUser={isPlaceUser}
      carePlanId={carePlanId}
    />
  </>
);

export default ReferralConfirmation;
