import React from 'react';
import PropTypes from 'prop-types';

const TabContainer = ({ children }) => {
  return <div className="tabs-V2__container">{children}</div>;
};

TabContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

TabContainer.defaultProps = {
  children: [],
};

export default TabContainer;
