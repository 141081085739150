import { connect } from 'react-redux'

import { RepeatedTask } from './'

import { addEventToAgenda, removeEventFromAgenda } from 'Actions/agenda'

import {
  getHealthCareView
} from 'Selectors/agenda'

const mapStateToProps = (state) => ({
  healthCareView: getHealthCareView(state)
})

const mapDispatchToProps = (dispatch) => ({
  addTask(task) {
    dispatch(addEventToAgenda(task))
  },
  removeEventFromAgenda(task) {
    dispatch(removeEventFromAgenda(task))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(RepeatedTask)
