import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import TextGroup from './TextGroup'

import Icon from 'Components/Base/Icon'
const i18nOpts = { scope: 'care_plan_place_invitations.index' }

const Details = ({ placeName, requestType, caregiverName, caregiverEmail, submittedDate, resolvedDate }) => {
  switch (requestType) {
    case 'hcp':
      return (
        <div className="invitation__hospital-details">
          <Icon name="hospital" className="invitation__hospital-icon text-grey" />
          <div>
            <h3 className="invitation__title">{placeName}</h3>
            <div className="invitation__text text-normal">
              {t('sent_at', { ...i18nOpts, sent_at: submittedDate })}
            </div>
          </div>
        </div>
      )
    case 'caregiver':
      return (
        <div className="invitation__caregiver-details">
          <TextGroup text={caregiverName} subtext={caregiverEmail} />
          <TextGroup text={submittedDate} />
          <TextGroup text={resolvedDate} />
        </div>
      )
    case 'patient':
      return (
        <div className="invitation__patient-details">
          <TextGroup text={submittedDate} />
          <TextGroup text={resolvedDate} />
        </div>
      )
    default:
      return null
  }
}

Details.propTypes = {
  caregiverEmail: PropTypes.string,
  caregiverName: PropTypes.string,
  placeName: PropTypes.string,
  requestType: PropTypes.string,
  resolvedDate: PropTypes.string,
  submittedDate: PropTypes.string,
}

Details.defaultProps = {
  caregiverEmail: '',
  caregiverName: '',
  placeName: '',
  requestType: '',
  resolvedDate: '',
  submittedDate: '',
}

export default Details
