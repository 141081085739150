import MedicalDocumentTemplateApi from 'Api/TemplateCreator/MedicalDocumentTemplate'
import LibraryDocumentApi from 'Api/CarePlanCreator/LibraryDocument'

class LibraryDocumentDispatcher {
  constructor(parentId, id, isTemplate) {
    this.isTemplate = isTemplate
    this.id = id
    this.parentId = parentId
  }

  previewLink() {
    return this.api.previewLink()
  }

  get api() {
    if (this.isTemplate) {
      return new MedicalDocumentTemplateApi(this.parentId, this.id)
    } else {
      return new LibraryDocumentApi(this.id)
    }
  }
}

export default LibraryDocumentDispatcher
