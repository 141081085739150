import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import classNames from 'classnames';
import _ from 'lodash';

import { Icon, Tooltip } from 'Components/Base';
import { Modal } from 'Components/Base/Modal';
import { ReferralForm } from './';

import useToggleState from 'Hooks/useToggleState';

const i18nOpts = { scope: 'resources.confirmation' };

const ReferralConfirmation = ({
  address,
  label,
  link,
  isSSO,
  locale,
  singleView,
  title,
  onBehalf,
  isPlaceUser,
  carePlanId,
  menuTop,
  menuSub,
  unclickableLink,
}) => {
  const [modalIsOpen, handleClick] = useToggleState(false);
  if (unclickableLink) {
    // Link is only unclickable for admin resources library view
    // since it's not associated to a care plan so no need to launch referral there
    return (
      <Fragment>
        <p className={classNames({ 'contact__details-method': singleView })}>
          {_.truncate(label, { length: 50, separator: ' ' })}
        </p>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        {singleView && <Icon name="link_right" />}
        <Tooltip text={_.truncate(link, { length: 30, separator: ' ' })}>
          <a href="#" className={classNames({ contact__details: singleView })} onClick={handleClick}>
            <span className={classNames({ 'contact__details-method': singleView })}>
              {_.truncate(label, { length: 50, separator: ' ' })}
            </span>
            <Icon name="external-link" className="icon_external-link" />
          </a>
        </Tooltip>

        <Modal isOpen={modalIsOpen} closeModal={handleClick} title={t('header', i18nOpts)}>
          <header className="wave-header">
            <div className="wave-header__header-title text-brand text-bold">
              {t('title', { ...i18nOpts, title })}
            </div>
          </header>
          <h4 className="text-grey-darkest header">{t('description', i18nOpts)}</h4>
          <ReferralForm
            address={address}
            locale={locale}
            link={link}
            isSSO={isSSO}
            closeModal={handleClick}
            onBehalf={onBehalf}
            isPlaceUser={isPlaceUser}
            carePlanId={carePlanId}
            menuTop={menuTop}
            menuSub={menuSub}
          />
        </Modal>
      </Fragment>
    );
  }
};

ReferralConfirmation.propTypes = {
  address: PropTypes.string,
  label: PropTypes.string,
  link: PropTypes.string.isRequired,
  isSSO: PropTypes.bool,
  locale: PropTypes.string,
  onBehalf: PropTypes.bool,
  isPlaceUser: PropTypes.bool,
  singleView: PropTypes.bool,
  title: PropTypes.string,
};

ReferralConfirmation.defaultProps = {
  address: '',
  label: '',
  locale: 'en',
  isSSO: false,
  onBehalf: false,
  isPlaceUser: false,
  singleView: false,
  title: '',
  unclickableLink: false,
};

export default ReferralConfirmation;
