import store from '../store';

export const SET_ACTIVITY_MODAL_CAREPLAN_ID = 'SET_ACTIVITY_MODAL_CAREPLAN_ID';
export const SET_ACTIVITY_MODAL_LOCALE = 'SET_ACTIVITY_MODAL_LOCALE';
export const SET_ACTIVITY_MODAL_STATE = 'SET_ACTIVITY_MODAL_STATE';
export const SET_ACTIVITY_MODAL_MODE = 'SET_ACTIVITY_MODAL_MODE';
export const SET_ACTIVITY_MODAL_ACTIVITY_TYPE = 'SET_ACTIVITY_MODAL_ACTIVITY_TYPE';
export const SET_ACTIVITY_MODAL_IS_OPEN = 'SET_ACTIVITY_MODAL_IS_OPEN';
export const SET_ACTIVITY_MODAL_TO_DEFAULT = 'SET_ACTIVITY_MODAL_TO_DEFAULT';
export const HANDLE_CLOSE_ACTIVITY_MODAL = 'HANDLE_CLOSE_ACTIVITY_MODAL';
export const SET_ACTIVITY_MODAL_ACTIVITY_PROPERTIES = 'SET_ACTIVITY_MODAL_ACTIVITY_PROPERTIES';
export const SET_ACTIVITY_MODAL_ACTIVITY_ID = 'SET_ACTIVITY_MODAL_ACTIVITY_ID';
export const SET_ACTIVITY_MODAL_FORM_ERRORS = 'SET_ACTIVITY_MODAL_FORM_ERRORS';
export const SET_ACTIVITY_MODAL_SHOW_SCHEDULED_FIELDS = 'SET_ACTIVITY_MODAL_SHOW_SCHEDULED_FIELDS';
export const SET_ACTIVITY_MODAL_SHOW_REPEATS_FIELDS = 'SET_ACTIVITY_MODAL_SHOW_REPEATS_FIELDS';
export const SET_ACTIVITY_MODAL_SHOW_ADDRESS = 'SET_ACTIVITY_MODAL_SHOW_ADDRESS';
export const SET_ACTIVITY_MODAL_SHOW_ADDRESS_FIELDS = 'SET_ACTIVITY_MODAL_SHOW_ADDRESS_FIELDS';
export const SET_ACTIVITY_MODAL_TEMPLATES_DROPDOWN_IS_OPEN = 'SET_ACTIVITY_MODAL_TEMPLATES_DROPDOWN_IS_OPEN';
export const SET_ACTIVITY_MODAL_TEMPLATES_DROPDOWN_STATE = 'SET_ACTIVITY_MODAL_TEMPLATES_DROPDOWN_STATE';
export const SET_ACTIVITY_MODAL_TEMPLATES_DROPDOWN_SEARCH_INPUT =
  'SET_ACTIVITY_MODAL_TEMPLATES_DROPDOWN_SEARCH_INPUT';
export const SET_ACTIVITY_MODAL_TEMPLATES_DROPDOWN_TEMPLATES =
  'SET_ACTIVITY_MODAL_TEMPLATES_DROPDOWN_TEMPLATES';
export const SET_ACTIVITY_MODAL_TEMPLATES_DROPDOWN_TEMPLATES_TO_DISPLAY =
  'SET_ACTIVITY_MODAL_TEMPLATES_DROPDOWN_TEMPLATES_TO_DISPLAY';
export const SET_ACTIVITY_MODAL_ASSIGNMENTS_DROPDOWN_IS_OPEN =
  'SET_ACTIVITY_MODAL_ASSIGNMENTS_DROPDOWN_IS_OPEN';
export const SET_ACTIVITY_MODAL_ASSIGNMENTS_DROPDOWN_STATE = 'SET_ACTIVITY_MODAL_ASSIGNMENTS_DROPDOWN_STATE';
export const SET_ACTIVITY_MODAL_ASSIGNMENTS_DROPDOWN_SEARCH_INPUT =
  'SET_ACTIVITY_MODAL_ASSIGNMENTS_DROPDOWN_SEARCH_INPUT';
export const SET_ACTIVITY_MODAL_ASSIGNMENTS_DROPDOWN_ASSIGNMENTS =
  'SET_ACTIVITY_MODAL_ASSIGNMENTS_DROPDOWN_ASSIGNMENTS';
export const SET_ACTIVITY_MODAL_ASSIGNMENTS_DROPDOWN_ASSIGNMENTS_TO_DISPLAY =
  'SET_ACTIVITY_MODAL_ASSIGNMENTS_DROPDOWN_ASSIGNMENTS_TO_DISPLAY';
export const SET_ACTIVITY_MODAL_RESOURCES_DROPDOWN_IS_OPEN = 'SET_ACTIVITY_MODAL_RESOURCES_DROPDOWN_IS_OPEN';
export const SET_ACTIVITY_MODAL_RESOURCES_DROPDOWN_STATE = 'SET_ACTIVITY_MODAL_RESOURCES_DROPDOWN_STATE';
export const SET_ACTIVITY_MODAL_RESOURCES_DROPDOWN_SEARCH_INPUT =
  'SET_ACTIVITY_MODAL_RESOURCES_DROPDOWN_SEARCH_INPUT';
export const SET_ACTIVITY_MODAL_RESOURCES_DROPDOWN_RESOURCES =
  'SET_ACTIVITY_MODAL_RESOURCES_DROPDOWN_RESOURCES';
export const SET_ACTIVITY_MODAL_RESOURCES_DROPDOWN_RESOURCES_TO_DISPLAY =
  'SET_ACTIVITY_MODAL_RESOURCES_DROPDOWN_RESOURCES_TO_DISPLAY';
export const SET_ACTIVITY_MODAL_TIME_ZONE = 'SET_ACTIVITY_MODAL_TIME_ZONE';
export const SET_ACTIVITY_MODAL_LIBRARY_DROPDOWN_IS_OPEN = 'SET_ACTIVITY_MODAL_LIBRARY_DROPDOWN_IS_OPEN';
export const SET_ACTIVITY_MODAL_LIBRARY_DROPDOWN_STATE = 'SET_ACTIVITY_MODAL_LIBRARY_DROPDOWN_STATE';
export const SET_ACTIVITY_MODAL_LIBRARY_DROPDOWN_SEARCH_INPUT =
  'SET_ACTIVITY_MODAL_LIBRARY_DROPDOWN_SEARCH_INPUT';
export const SET_ACTIVITY_MODAL_LIBRARY_DROPDOWN_TEMPLATES = 'SET_ACTIVITY_MODAL_LIBRARY_DROPDOWN_TEMPLATES';
export const SET_ACTIVITY_MODAL_LIBRARY_DROPDOWN_TEMPLATES_TO_DISPLAY =
  'SET_ACTIVITY_MODAL_LIBRARY_DROPDOWN_TEMPLATES_TO_DISPLAY';
export const SET_ACTIVITY_MODAL_IF_CAN_DELETE_ACTIVITY = 'SET_ACTIVITY_MODAL_IF_CAN_DELETE_ACTIVITY';
export const SET_ACTIVITY_MODAL_IF_CAN_EDIT_FIELDS = 'SET_ACTIVITY_MODAL_IF_CAN_EDIT_FIELDS';
export const SET_ACTIVITY_MODAL_DEFAULT_VISIBLE_TO_ALL = 'SET_ACTIVITY_MODAL_DEFAULT_VISIBLE_TO_ALL';


const setActivityModalCarePlanId = (carePlanId) => ({
  type: SET_ACTIVITY_MODAL_CAREPLAN_ID,
  carePlanId,
});

const setActivityModalLocale = (locale) => ({
  type: SET_ACTIVITY_MODAL_LOCALE,
  locale,
})

const setActivityModalState = (activityModalState) => ({
  type: SET_ACTIVITY_MODAL_STATE,
  activityModalState,
});

const setActivityModalMode = (mode) => ({
  type: SET_ACTIVITY_MODAL_MODE,
  mode,
});

const setActivityModalActivityType = (activityType) => ({
  type: SET_ACTIVITY_MODAL_ACTIVITY_TYPE,
  activityType,
});

const setActivityModalIsOpen = (isOpen) => ({
  type: SET_ACTIVITY_MODAL_IS_OPEN,
  isOpen,
});

const setActivityModalToDefault = () => ({
  type: SET_ACTIVITY_MODAL_TO_DEFAULT
});

// exception to the rule
const handleCloseActivityModal = () => {
  // default state flashes before modal is closed if we don't do this
  setTimeout(() => {
    store.dispatch(setActivityModalToDefault());
  }, 200);

  return {
    type: SET_ACTIVITY_MODAL_IS_OPEN,
    isOpen: false,
  }
}

const setActivityModalActivityProperties = (activityProperties) => ({
  type: SET_ACTIVITY_MODAL_ACTIVITY_PROPERTIES,
  activityProperties,
});

const setActivityModalActivityId = (activityId) => ({
  type: SET_ACTIVITY_MODAL_ACTIVITY_ID,
  activityId,
});

const setActivityModalFormErrors = (formErrors) => ({
  type: SET_ACTIVITY_MODAL_FORM_ERRORS,
  formErrors,
});

const setActivityModalShowScheduledFields = (showScheduledFields) => ({
  type: SET_ACTIVITY_MODAL_SHOW_SCHEDULED_FIELDS,
  showScheduledFields,
});

const setActivityModalShowRepeatsFields = (showRepeatsFields) => ({
  type: SET_ACTIVITY_MODAL_SHOW_REPEATS_FIELDS,
  showRepeatsFields,
});

const setActivityModalShowAddress = (showAddress) => ({
  type: SET_ACTIVITY_MODAL_SHOW_ADDRESS,
  showAddress,
});

const setActivityModalShowAddressFields = (showAddressFields) => ({
  type: SET_ACTIVITY_MODAL_SHOW_ADDRESS_FIELDS,
  showAddressFields,
});

const setActivityModalTemplatesDropdownIsOpen = (isOpen) => ({
  type: SET_ACTIVITY_MODAL_TEMPLATES_DROPDOWN_IS_OPEN,
  isOpen,
});

const setActivityModalTemplatesDropdownState = (templatesDropdownState) => ({
  type: SET_ACTIVITY_MODAL_TEMPLATES_DROPDOWN_STATE,
  templatesDropdownState,
});

const setActivityModalTemplatesDropdownSearchInput = (searchInput) => ({
  type: SET_ACTIVITY_MODAL_TEMPLATES_DROPDOWN_SEARCH_INPUT,
  searchInput,
});

const setActivityModalTemplatesDropdownTemplates = (templates) => ({
  type: SET_ACTIVITY_MODAL_TEMPLATES_DROPDOWN_TEMPLATES,
  templates,
});

const setActivityModalTemplatesDropdownTemplatesToDisplay = (templatesToDisplay) => ({
  type: SET_ACTIVITY_MODAL_TEMPLATES_DROPDOWN_TEMPLATES_TO_DISPLAY,
  templatesToDisplay,
});

const setActivityModalAssignmentsDropdownIsOpen = (isOpen) => ({
  type: SET_ACTIVITY_MODAL_ASSIGNMENTS_DROPDOWN_IS_OPEN,
  isOpen,
});

const setActivityModalAssignmentsDropdownState = (assignmentsDropdownState) => ({
  type: SET_ACTIVITY_MODAL_ASSIGNMENTS_DROPDOWN_STATE,
  assignmentsDropdownState,
});

const setActivityModalAssignmentsDropdownSearchInput = (searchInput) => ({
  type: SET_ACTIVITY_MODAL_ASSIGNMENTS_DROPDOWN_SEARCH_INPUT,
  searchInput,
});

const setActivityModalAssignmentsDropdownAssignments = (assignments) => ({
  type: SET_ACTIVITY_MODAL_ASSIGNMENTS_DROPDOWN_ASSIGNMENTS,
  assignments,
});

const setActivityModalAssignmentsDropdownAssignmentsToDisplay = (assignmentsToDisplay) => ({
  type: SET_ACTIVITY_MODAL_ASSIGNMENTS_DROPDOWN_ASSIGNMENTS_TO_DISPLAY,
  assignmentsToDisplay,
});

const setActivityModalResourcesDropdownIsOpen = (isOpen) => ({
  type: SET_ACTIVITY_MODAL_RESOURCES_DROPDOWN_IS_OPEN,
  isOpen,
});

const setActivityModalResourcesDropdownState = (resourcesDropdownState) => ({
  type: SET_ACTIVITY_MODAL_RESOURCES_DROPDOWN_STATE,
  resourcesDropdownState,
});

const setActivityModalResourcesDropdownSearchInput = (searchInput) => ({
  type: SET_ACTIVITY_MODAL_RESOURCES_DROPDOWN_SEARCH_INPUT,
  searchInput,
});

const setActivityModalResourcesDropdownResources = (resources) => ({
  type: SET_ACTIVITY_MODAL_RESOURCES_DROPDOWN_RESOURCES,
  resources,
});

const setActivityModalLibraryDropdownIsOpen = (isOpen) => ({
  type: SET_ACTIVITY_MODAL_LIBRARY_DROPDOWN_IS_OPEN,
  isOpen,
});

const setActivityModalLibraryDropdownState = (libraryDropdownState) => ({
  type: SET_ACTIVITY_MODAL_LIBRARY_DROPDOWN_STATE,
  libraryDropdownState,
});

const setActivityModalLibraryDropdownSearchInput = (searchInput) => ({
  type: SET_ACTIVITY_MODAL_LIBRARY_DROPDOWN_SEARCH_INPUT,
  searchInput,
});

const setActivityModalLibraryDropdownTemplates = (templates) => ({
  type: SET_ACTIVITY_MODAL_LIBRARY_DROPDOWN_TEMPLATES,
  templates,
});

const setActivityModalLibraryDropdownTemplatesToDisplay = (templatesToDisplay) => ({
  type: SET_ACTIVITY_MODAL_LIBRARY_DROPDOWN_TEMPLATES_TO_DISPLAY,
  templatesToDisplay,
});

const setActivityModalResourcesDropdownResourcesToDisplay = (resourcesToDisplay) => ({
  type: SET_ACTIVITY_MODAL_RESOURCES_DROPDOWN_RESOURCES_TO_DISPLAY,
  resourcesToDisplay,
});

const setActivityModalTimeZone = (timeZone) => ({
  type: SET_ACTIVITY_MODAL_TIME_ZONE,
  timeZone,
});

const setActivityModalCanDeleteActivity = (canDeleteActivity) => ({
  type: SET_ACTIVITY_MODAL_IF_CAN_DELETE_ACTIVITY,
  canDeleteActivity,
});

const setActivityModalCanEditFields = (canEditFields) => ({
  type: SET_ACTIVITY_MODAL_IF_CAN_EDIT_FIELDS,
  canEditFields,
});

const setActivityModalDefaultVisibleToAll = (defaultVisibleToAll) => ({
  type: SET_ACTIVITY_MODAL_DEFAULT_VISIBLE_TO_ALL,
  defaultVisibleToAll,
});

export {
  setActivityModalCarePlanId,
  setActivityModalLocale,
  setActivityModalState,
  setActivityModalMode,
  setActivityModalActivityType,
  setActivityModalIsOpen,
  handleCloseActivityModal, // exception
  setActivityModalActivityProperties,
  setActivityModalActivityId,
  setActivityModalFormErrors,
  setActivityModalShowScheduledFields,
  setActivityModalShowRepeatsFields,
  setActivityModalShowAddress,
  setActivityModalShowAddressFields,
  setActivityModalTemplatesDropdownIsOpen,
  setActivityModalTemplatesDropdownState,
  setActivityModalTemplatesDropdownSearchInput,
  setActivityModalTemplatesDropdownTemplates,
  setActivityModalTemplatesDropdownTemplatesToDisplay,
  setActivityModalAssignmentsDropdownIsOpen,
  setActivityModalAssignmentsDropdownState,
  setActivityModalAssignmentsDropdownSearchInput,
  setActivityModalAssignmentsDropdownAssignments,
  setActivityModalAssignmentsDropdownAssignmentsToDisplay,
  setActivityModalResourcesDropdownIsOpen,
  setActivityModalResourcesDropdownState,
  setActivityModalResourcesDropdownSearchInput,
  setActivityModalResourcesDropdownResources,
  setActivityModalResourcesDropdownResourcesToDisplay,
  setActivityModalLibraryDropdownIsOpen,
  setActivityModalLibraryDropdownState,
  setActivityModalLibraryDropdownSearchInput,
  setActivityModalLibraryDropdownTemplates,
  setActivityModalLibraryDropdownTemplatesToDisplay,
  setActivityModalTimeZone,
  setActivityModalCanDeleteActivity,
  setActivityModalCanEditFields,
  setActivityModalDefaultVisibleToAll,
};
