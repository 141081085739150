import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';

import InputGroup from '../../Forms/InputGroup';

const i18nOpts = { scope: 'care_plan_creators.careteam.patient_form' };

const PatientForm = ({ onChange, errors, patient }) => {
  const { invitation, name } = patient;
  const email = invitation ? invitation.email : '';

  return (
    <div>
      <h4 className="careplan-wizard__section-subheader careplan-wizard__section-subheader--patient-subheader">
        {t('header', { ...i18nOpts, name: name })}
      </h4>
      <InputGroup
        name="patient.invitation.email"
        type="text"
        component="input"
        value={email}
        onChange={onChange}
        errors={errors}
        label={t('email', i18nOpts)}
        hint={t('hint', i18nOpts)}
      />
    </div>
  );
};

PatientForm.propTypes = {
  errors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  patient: PropTypes.shape({
    invitation: PropTypes.shape({
      email: PropTypes.string,
    }),
    name: PropTypes.string.isRequired,
  }).isRequired,
};

PatientForm.defaultProps = {
  errors: {},
};

export default PatientForm;
