import React from 'react'
import PropTypes from 'prop-types'

const InputError = ({ error }) => <div className="error">{error}</div>

InputError.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
}

export default InputError
