import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Loader from 'Components/Base/Loader';
import { t } from 'i18n';
import classNames from 'classnames';
import ErrorDisplay from 'Components/Base/Dashboard/ErrorDisplay';
import setupBlockFetcher from '../setupBlockFetcher';
import MySleepChart from './Chart';
import getRenderData from './getRenderData';
import RenderCategories from '../RenderCategories';

const i18nOpts = { scope: 'blocks.my_sleep' };

const MySleep = ({ carePlanUuid, blockName }) => {
  const [data, setData] = useState({});
  const [pageState, setPageState] = useState('loading');

  useEffect(() => {
    setupBlockFetcher(carePlanUuid, blockName, setData, setPageState);
  }, []);

  switch (pageState) {
    case 'success': {
      const renderData = getRenderData(data);
      const dailyHoursSlept = renderData.map(({ hoursSlept }) => hoursSlept);

      return (
        <div className="card block-visual my-sleep">
          <div className="block-visual block-visual__header">
            <h3 className="title">{t('title', i18nOpts)}</h3>
          </div>
          <div className="block-visual block-visual__chart-wrapper">
            <div className="hours-slept__wrapper">
              <RenderCategories categories={[t('hours', i18nOpts)]} />
              <div className="hours-slept__indicator">
                {dailyHoursSlept.map((day, index) => {
                  const classes = classNames({
                    slept: true,
                    'slept--no-data': !day,
                  });
                  const display = day == '' ? '-' : day;
                  return (
                    <span className={classes} key={index}>
                      {display}
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="category-padding" />
            <MySleepChart data={renderData} />
          </div>
        </div>
      );
    }
    case 'error':
      return <ErrorDisplay title={t('fetch_error', i18nOpts)} status="ERROR" />;
    case 'loading':
      return <Loader />;
    default:
      return <ErrorDisplay title={t('fetch_error', i18nOpts)} status="ERROR" />;
  }
};

MySleep.propTypes = {
  carePlanUuid: PropTypes.string.isRequired,
  blockName: PropTypes.string.isRequired,
};

export default MySleep;
