import React from 'react'
import PropTypes from 'prop-types'

const ScoreIcon = ({ color, score, background, className }) => (
  <div className={`score-icon ${className}`} style={{ background, color }}>{score}</div>
)

ScoreIcon.propTypes = {
  background: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  score: PropTypes.number,
}

ScoreIcon.defaultProps = {
  background: '#1085c6',
  className: '',
  color: '#fff',
  score: 0,
}

export default ScoreIcon
