import { signupsFilter } from './signupFilters';

export const filterSignups = (setFilteredSignups, setCurrentPageNumber, signups, search, status, tab) => {
  const filtered = signupsFilter(search, status, tab, signups);
  setFilteredSignups(filtered);
  setCurrentPageNumber(1);
};

export const filterByStatus = (
  setFilterByStatusDropdownInput,
  setIsFilterByStatusDropdownOpen,
  setFilteredSignups,
  setCurrentPageNumber,
  signups,
  search,
  tab
) => {
  return (input) => {
    setFilterByStatusDropdownInput(input);
    setIsFilterByStatusDropdownOpen(false);
    filterSignups(setFilteredSignups, setCurrentPageNumber, signups, search, input, tab);
  };
};

export const filterBySearch = (
  setCurrentSearchInput,
  setFilteredSignups,
  setCurrentPageNumber,
  signups,
  status,
  tab
) => {
  return (input) => {
    setCurrentSearchInput(input);
    filterSignups(setFilteredSignups, setCurrentPageNumber, signups, input, status, tab);
  };
};

export const filterByTabWrapper = (filterSignupsProvider = filterSignups) => {
  return (setFilteredSignups, setCurrentTab, signups, setCurrentPageNumber, currentSearchInput, status) => {
    return (invitationType) => {
      setCurrentTab(invitationType);

      filterSignupsProvider(
        setFilteredSignups,
        setCurrentPageNumber,
        signups,
        currentSearchInput,
        status,
        invitationType
      );
    };
  };
};

export const filterByTab = filterByTabWrapper(filterSignups);

export const handleSignups = (updateSignups, input) => {
  updateSignups(input);
};

export const filterSignupsWrapper = (filterAction = handleSignups) => {
  return (updateSignups, input) => {
    filterAction(updateSignups, input);
  };
};

export const filterSignupsAction = filterSignupsWrapper(handleSignups);
