import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import classNames from 'classnames';
import RenderLegend from '../RenderLegend';
import Icon from 'Components/Base/Icon';

const i18nOpts = { scope: 'blocks.friend_reaction' };

const allReactions = ['very_negative', 'negative', 'neutral', 'positive', 'very_positive', 'no_interactions'];

const getCellClassName = ({ occurred, timeOfDayIndex, noInteraction = false }) =>
  classNames({
    'chart cell': true,
    'cell--morning': !noInteraction && occurred && timeOfDayIndex === 0,
    'cell--afternoon': !noInteraction && occurred && timeOfDayIndex === 1,
    'cell--evening': !noInteraction && occurred && timeOfDayIndex === 2,
    'cell--no-interaction': noInteraction && occurred,
  });

const getDayClassName = (dayMissed) =>
  classNames({
    day: true,
    'day-missed': dayMissed,
  });

const Chart = ({ data }) => {
  return (
    <div className="chart friend-reaction">
      {data.map(({ day, reactions }, dataIndex) => {
        const dayMissed = !reactions.map(({ no_response }) => no_response).includes(false);

        return (
          <div key={dataIndex} className={getDayClassName(dayMissed)}>
            <div className={`time-of-day-wrapper ${dayMissed && 'day-missed'}`}>
              {reactions.map((reaction, timeOfDayIndex) => {
                if (reaction.no_response) {
                  return (
                    <div key={timeOfDayIndex} className="time-of-day time-of-day--no-response">
                      <Icon name="close" className="cell no-data" />
                    </div>
                  );
                }
                return (
                  <div
                    key={timeOfDayIndex}
                    className={`time-of-day ${reaction.is_future && 'time-of-day--future'}`}
                  >
                    {allReactions.map((type, reactionIndex) => {
                      const occurred = reaction[type];
                      const noInteraction = type === 'no_interactions';

                      return (
                        <div
                          key={reactionIndex}
                          className={getCellClassName({ occurred, timeOfDayIndex, noInteraction })}
                        />
                      );
                    })}
                  </div>
                );
              })}
              <span className="xAxis text text-subtle">{t(day, { scope: 'blocks.days' })}</span>
            </div>
          </div>
        );
      })}
      <RenderLegend legend={t('legend', i18nOpts)} />
    </div>
  );
};

Chart.propTypes = {
  data: PropTypes.array.isRequired,
};

export { Chart as default, getCellClassName };
