import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import InputGroup from 'Components/Forms/InputGroup'
import {
  endRepeatsForTemplate,
  frequencyTypes,
  frequencies,
  frequencyTypesAppt,
} from 'Utilities/repeated_options'

const i18nOpts = {
  scope: 'care_plans.recurrence_input',
}

class TemplateRecurrenceInput extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      count,
      daysOffset,
      endRepeat,
      errors,
      frequency,
      frequencyType,
      handleChange,
      interval,
      isEdit,
      timeOffset,
      timeZone,
      type,
      required,
    } = this.props
    return (
      <div>
        <div className="form__row row-justify-start">
          <InputGroup
            component="select"
            className="input input--one-third"
            name="frequencyType"
            label={t('recurring', i18nOpts)}
            onChange={handleChange}
            value={frequencyType}
            errors={{ frequencyType: errors['frequency_type'] }}
            required={required}
            disabled={isEdit}
          >
            {(type === 'Appointment' ? frequencyTypesAppt : frequencyTypes).map((option, idx) => {
              return (
                <option key={idx} value={option.id}>
                  {option.name}
                </option>
              )
            })}
          </InputGroup>
        </div>

        {frequencyType && frequencyType !== 'once' && (
          <div>
            <div className="input activity-days-offset">
              <InputGroup
                component="input"
                className="input input--one-third"
                name="daysOffset"
                label={t('days_offset', i18nOpts)}
                onChange={handleChange}
                value={String(daysOffset)}
                errors={{ daysOffset: errors['days_offset'] }}
                type="number"
                min="1"
                max="999"
                required
              />
              <div className="trail-text">
                {t('daily', { scope: 'tasks.form.interval', count: parseInt(daysOffset) })}{' '}
                {t('days_offset_text', i18nOpts)}
              </div>
            </div>
            <div className="input due-at">
              <div className="date-input date-input__input">
                <InputGroup
                  component="datetime"
                  id="timeOffset"
                  name="timeOffset"
                  label={t('due_at', i18nOpts)}
                  onChange={handleChange}
                  value={timeOffset}
                  timeZone={timeZone}
                  withDate={false}
                  errors={{ timeOffset: errors['time_offset'] }}
                  required
                />
              </div>
            </div>
          </div>
        )}

        {frequencyType === 'recurring' && (
          <div>
            <div className="form__row row-justify-start">
              <InputGroup
                component="select"
                className="input input--one-third"
                name="frequency"
                onChange={handleChange}
                value={frequency}
                errors={errors}
                label={t('repeats', i18nOpts)}
                required
              >
                {frequencies.map((option, idx) => {
                  return (
                    <option key={idx} value={option.id}>
                      {option.name}
                    </option>
                  )
                })}
              </InputGroup>

              <div>
                <InputGroup
                  component="input"
                  className="input input--one-third interval"
                  name="interval"
                  label={t('every', i18nOpts)}
                  onChange={handleChange}
                  value={String(interval)}
                  errors={errors}
                  type="number"
                  min="1"
                  max="999"
                  required
                />
                <div className="trail-text">
                  {t(frequency, { scope: 'tasks.form.interval', count: parseInt(interval) })}
                </div>
              </div>
            </div>

            <div className="form__row row-justify-start">
              <InputGroup
                component="select"
                className="input input--one-third"
                name="endRepeat"
                onChange={handleChange}
                errors={errors}
                value={endRepeat}
                label={t('end_repeat', i18nOpts)}
              >
                {endRepeatsForTemplate.map((option, idx) => {
                  return (
                    <option key={idx} value={option.id}>
                      {option.name}
                    </option>
                  )
                })}
              </InputGroup>
              {endRepeat === 'times' && (
                <div>
                  <InputGroup
                    component="input"
                    className="input input--one-third count"
                    name="count"
                    label={t('after', i18nOpts)}
                    onChange={handleChange}
                    errors={errors}
                    value={count}
                    type="number"
                    min="1"
                    max="999"
                    required
                  />
                  <div className="trail-text">{t('count.other', i18nOpts)}</div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
}

TemplateRecurrenceInput.propTypes = {
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  daysOffset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  endRepeat: PropTypes.string,
  errors: PropTypes.shape({
    days_offset: PropTypes.string,
    frequency_type: PropTypes.string,
    time_offset: PropTypes.string,
  }),
  frequency: PropTypes.string,
  frequencyType: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  interval: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isEdit: PropTypes.bool,
  required: PropTypes.bool,
  timeOffset: PropTypes.string,
  timeZone: PropTypes.string,
  type: PropTypes.string,
}

TemplateRecurrenceInput.defaultProps = {
  count: '',
  daysOffset: '',
  endRepeat: '',
  errors: {
    days_offset: '',
    frequency_type: '',
    time_offset: '',
  },
  frequency: '',
  frequencyType: '',
  interval: '',
  isEdit: false,
  required: true,
  timeOffset: '',
  timeZone: '',
  type: '',
}

export default TemplateRecurrenceInput
