import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import PrintLog from 'Api/PrintLog'
import IconButton from 'Components/Base/IconButton'

class PrintButton extends PureComponent {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    new PrintLog().create(window.location.href).then((res) => {
      if (res.ok) {
        window.print()
      }
    })
  }

  render() {
    return (
      <IconButton
        iconName="printer"
        isFlat
        onClick={this.handleClick}
        title={this.props.title}
        text={t('print', { scope: 'base.print_button' })}
      />
    )
  }
}

PrintButton.propTypes = {
  title: PropTypes.string,
}

PrintButton.defaultProps = {
  title: t('print', { scope: 'base.print_button' }),
}

export default PrintButton
