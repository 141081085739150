import React from 'react'
import PropTypes from 'prop-types'
import moment from 'ct-moment'
import { t } from 'i18n'

import { Icon, Tooltip } from 'Components/Base'
import Assignments from 'Components/ActivityFeed/Assignments'
import { ReferralProvider } from 'Components/Tasks/Index/Activity/Referral'

const AssignmentsWithDueAt = ({ assignments, dueAt, provider, repeated }) => (
  <div className="row__col row__col--fixed appointment-assignments">
    {dueAt ? (
      <div className="appointment-assignments__time">
        {repeated && (
          <Tooltip text={t('repeated', { scope: 'tasks.index' })}>
            <Icon className="icon--medium" name="refresh" />
          </Tooltip>
        )}
        <span className="h3">{moment.parseZone(dueAt).format('LT')}</span>
      </div>
    ) : null}
    {provider ? <ReferralProvider provider={provider} /> : null}
    <Assignments assignments={assignments} minimalistic />
  </div>
)

AssignmentsWithDueAt.propTypes = {
  assignments: PropTypes.array,
  dueAt: PropTypes.string,
  provider: PropTypes.string,
  repeated: PropTypes.bool,
}

AssignmentsWithDueAt.defaultProps = {
  assignments: [],
  dueAt: null,
  provider: null,
  repeated: false,
}

export default AssignmentsWithDueAt
