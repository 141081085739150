import React from 'react'
import PropTypes from 'prop-types'

const OutboundUrlLink = ({ url, children }) => (
  <a href={url} target="_blank" rel="noopener noreferrer nofollow">
    {children || url}
  </a>
)

OutboundUrlLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  url: PropTypes.string.isRequired,
}

OutboundUrlLink.defaultProps = {
  children: '',
}

export default OutboundUrlLink
