import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';

const i18nOpts = {
  scope: 'care_plan_place_invitations.signups_container.target_signup_modal.legal_warning',
};

const getLegalCopy = ({ entityType, placeName, patientName, caregiverName, invitedBy }) => {
  let type = 'warning';
  let copy = '';

  switch (entityType) {
    case 'AccessRequest':
      type += '_caregiver';
      copy = (
        <Fragment>
          <li>{t(`${type}_point_1`, { ...i18nOpts, patientName, caregiverName })}</li>
          <li>{t(`${type}_point_2`, { ...i18nOpts, patientName, caregiverName })}</li>
          <li>{t(`${type}_point_3`, { ...i18nOpts, placeName })}</li>
        </Fragment>
      );
      break;
    case 'Patient':
      type += '_patient';
      copy = (
        <Fragment>
          <li>{t(`${type}_point_1`, { ...i18nOpts, patientName })}</li>
          <li>{t(`${type}_point_2`, { ...i18nOpts, placeName })}</li>
        </Fragment>
      );
      break;
    default:
      // CarePlanPlaceInvitation
      type += '_hcp';
      copy = (
        <Fragment>
          <li>{t(`${type}_point_1`, { ...i18nOpts, invitedBy })}</li>
          <li>{t(`${type}_point_2`, { ...i18nOpts, placeName })}</li>
        </Fragment>
      );
  }
  return copy;
};

const LegalCopy = ({ caregiverName, patientName, placeName, status, entityType, invitedBy }) => {
  if (status === 'pending' || status === 'declined') {
    return (
      <>
        <div className="signup_modal__main_content__legal_warning_title">{t('title', i18nOpts)}:</div>
        <ul className="signup_modal__main_content__legal_warning_points">
          {getLegalCopy({ entityType, placeName, patientName, caregiverName, invitedBy })}
        </ul>
      </>
    );
  } else {
    return null;
  }
};

LegalCopy.propTypes = {
  caregiverName: PropTypes.string,
  patientName: PropTypes.string,
  placeName: PropTypes.string,
  status: PropTypes.string,
};

export default LegalCopy;
