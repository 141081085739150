import {
  REQUEST_CONVERSATION,
  RECEIVE_CONVERSATION,
  RECEIVE_CONVERSATION_MESSAGE,
  UPDATE_EMERGENCY_INSTRUCTION,
} from 'Actions/inbox/conversation';

const conversation = (state = { isFetching: true }, action) => {
  switch (action.type) {
    case REQUEST_CONVERSATION:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_CONVERSATION:
      return {
        ...state,
        ...action.conversation,
        isFetching: false,
      };
    case RECEIVE_CONVERSATION_MESSAGE:
      return {
        ...state,
        receipts: [action.receipt, ...state.receipts],
        isFetching: false,
      };
    case UPDATE_EMERGENCY_INSTRUCTION:
      return {
        ...state,
        emergencyInstruction: action.emergencyInstruction,
      };
    default:
      return state;
  }
};

export default conversation;
