import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Icon, Tooltip, Spinner } from 'Components/Base'

const i18nOpts = { scope: 'tasks.index' }

const TaskCheckBox = ({ canComplete, completedAt, isCompleting, onClick }) =>
  !canComplete ? (
    <div className="row__spacer" />
  ) : isCompleting ? (
    <div className="row__col row__col--fixed">
      <Spinner />
    </div>
  ) : (
    <div
      className="row__col row__col--fixed"
      data-test={completedAt ? 'checkbox-checked' : 'checkbox-unchecked'}
      onClick={onClick}
    >
      <Tooltip text={t(completedAt ? 'revert' : 'complete', i18nOpts)} tabIndex="0">
        <Icon className="icon--medium" name={completedAt ? 'box-checked' : 'box-empty'} />
      </Tooltip>
    </div>
  )

TaskCheckBox.propTypes = {
  canComplete: PropTypes.bool,
  completedAt: PropTypes.string,
  isCompleting: PropTypes.bool,
  onClick: PropTypes.func,
}

TaskCheckBox.defaultProps = {
  canComplete: false,
  completedAt: null,
  isCompleting: false,
  onClick: null,
}

export default TaskCheckBox
