import React from 'react';
import { t } from 'i18n';

import { ModalFooter } from 'Components/Base';
import PropTypes from 'prop-types'

const i18nOpts = { scope: 'care_plan_creators.details.details_form' };

const DateModalButtons = ({ onClose, updateDate, modalState }) => (
  <ModalFooter
    renderRight={() => {
      if (modalState === 'loading') {
        return (
          <a className={`btn--secondary button-loader btn`}>
            <div className="button-loader__elements" />
          </a>
        );
      } else {
        return (
          <a
            className={`btn ${modalState === 'selected' ? 'btn--primary' : 'btn--secondary'}`}
            onClick={modalState === 'apiError' ? onClose : updateDate}
          >
            {modalState === 'selected' ? t('apply', i18nOpts) : t('done', i18nOpts)}
          </a>
        );
      }
    }}
  />
);

DateModalButtons.propTypes = {
  updateDate: PropTypes.func.isRequired,
  modalState: PropTypes.string.isRequired
};

export default DateModalButtons;
