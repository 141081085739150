import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { t } from 'i18n';

import AdHocResourceDispatcherApi from 'Api/Dispatchers/AdHocResourceDispatcher';
import ServiceApi from 'Api/Admin/Service';

import { Button, ModalFooter } from 'Components/Base';
import AddressForm from 'Components/Forms/AddressForm';
import TagInput from 'Components/Forms/TagInput';
import Submit from 'Components/Forms/Submit';
import FormField from 'Components/Forms/FormField';
import { Icon, Tooltip } from 'Components/Base';

const i18nOpts = { scope: 'care_plan_creators.resources.form' };

class ResourceForm extends Component {
  constructor(props) {
    super(props);

    const service = { ...props.service };

    service.address = {
      address: service.address?.address,
      extendedAddress: service.address?.extended_address,
      city: service.address?.city,
      region: service.address?.region,
      postalCode: service.address?.postal_code,
      countryCode: service.address?.country_code,
    };

    service.preferredPhoneNumber = service.preferredPhoneNumber || {
      number: '',
    };

    const tags = service.tags || [];

    // Subset of allTags that is not already included in tags
    const suggestions = this.props.allTags.filter(
      (suggestionTag) =>
        !tags.some((serviceTag) => JSON.stringify(suggestionTag) === JSON.stringify(serviceTag))
    );

    const getLocale = () => (navigator.language.includes('fr') ? 'fr' : 'en');

    this.state = {
      service: {
        name: '',
        url: '',
        referralCategory: '',
        referralSubcategory: '',
        referralLinkLabel: service.referralLinkLabel,
        ...service,
      },
      tags,
      suggestions,
      errors: {},
      linkReferralEnabled: service.referralLinkLabel ? true : false,
      sampleReferralLink: 'https://www.caredove.com/champlaincss',
      locale: getLocale(),
    };

    this.handleErrors = this.handleErrors.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTagInputChange = this.handleTagInputChange.bind(this);
    this.toggleLinkReferral = this.toggleLinkReferral.bind(this);
    this.updateSampleReferralLink = this.updateSampleReferralLink.bind(this);
    this.handleReferralChange = this.handleReferralChange.bind(this);
  }

  toggleLinkReferral = () => {
    this.setState((prevState) => ({
      linkReferralEnabled: !prevState.linkReferralEnabled,
      service: {
        ...prevState.service,
        referralCategory: '',
        referralSubcategory: '',
        referralLinkLabel: '',
      },
    }));
  };

  handleChange({ target: { name, value } }) {
    this.setState({ service: _.set(this.state.service, name, value) });
  }

  handleReferralChange({ target: { name, value } }) {
    this.setState({ service: _.set(this.state.service, name, value) }, this.updateSampleReferralLink);
  }

  handleTagInputChange = (option) => {
    this.setState({ tags: option });
    this.setState({
      suggestions: this.props.allTags.filter(
        (suggestionTag) =>
          !option.some((serviceTag) => JSON.stringify(suggestionTag) === JSON.stringify(serviceTag))
      ),
    });
  };

  handleErrors(errors) {
    const parsedErrors = { ...errors };

    if (errors['address.country_code']) {
      parsedErrors['address.countryCode'] = errors['address.country_code'];
    }
    if (errors['address.postal_code']) {
      parsedErrors['address.postalCode'] = errors['address.postal_code'];
    }
    if (errors['preferred_phone_number.number']) {
      parsedErrors['preferredPhoneNumber.number'] = errors['preferred_phone_number.number'];
    }

    this.setState({ errors: parsedErrors });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { tags, suggestions, service } = this.state;
    const action = this.isUpdate ? 'update' : 'create';

    const selectedTags = tags.map((tag) => ({ ...tag, selected: true }));
    const unselectedTags = suggestions.map((tag) => ({ ...tag, selected: false }));

    return this.api[action]({
      service: { ...service, tags: [...selectedTags, ...unselectedTags] },
    }).then((res) => {
      if (res.status === 200) {
        return res.json().then((service) => {
          this.props.onSuccess(service);
        });
      } else if (res.status === 422) {
        return res.json().then(this.handleErrors);
      }
    });
  }

  // Has to be called as a callback to setState if your setState is changing service.referralCategory or service.referralSubcategory
  updateSampleReferralLink() {
    const menuTop = this.state.service.referralCategory;
    const menuSub = this.state.service.referralSubcategory;

    const link = new URL(this.state.sampleReferralLink);
    link.searchParams.set('searchurl', 'true');
    link.searchParams.set('lan', this.state.locale);
    menuTop ? link.searchParams.set('menutop', menuTop) : link.searchParams.delete('menutop');
    menuSub ? link.searchParams.set('menusub', menuSub) : link.searchParams.delete('menusub');

    this.setState({ sampleReferralLink: link.href });
  }

  get isUpdate() {
    return !!this.props.serviceId;
  }

  get api() {
    return this.props.isAdHoc
      ? new AdHocResourceDispatcherApi(this.props.parentId, this.props.isTemplate, this.props.serviceId)
      : new ServiceApi(this.props.serviceId);
  }

  renderNameField() {
    return (
      <FormField
        className="input"
        label={t('name', i18nOpts)}
        name="name"
        placeholder={t('placeholder.name', i18nOpts)}
        value={this.state.service.name}
        required
        onChange={this.handleChange}
        formErrors={this.state.errors}
      />
    );
  }

  renderDescriptionField() {
    return (
      <FormField
        className="input"
        label={t('description', i18nOpts)}
        name="description"
        placeholder={t('placeholder.description', i18nOpts)}
        value={this.state.service.description}
        onChange={this.handleChange}
        formErrors={this.state.errors}
      />
    );
  }

  renderAddressFields() {
    return (
      <>
        <section className="resource-form__address_form__header">
          <label>{t('address', i18nOpts)}</label>
        </section>
        <AddressForm
          className="activity_modal"
          locale={this.props.locale}
          address={this.state.service.address}
          name="address"
          onChange={this.handleChange}
          errors={this.state.errors}
        />
      </>
    );
  }

  renderTagField() {
    const props = {
      className: 'input',
      suggestions: this.state.suggestions,
      tags: this.state.tags,
      handleTagInputChange: this.handleTagInputChange,
    };
    return <TagInput {...props} />;
  }

  renderWebsiteFields() {
    return (
      <FormField
        className="input"
        label={t('url', i18nOpts)}
        name="url"
        placeholder={t('placeholder.url', i18nOpts)}
        value={this.state.service.url}
        onChange={this.handleChange}
        formErrors={this.state.errors}
      />
    );
  }

  renderNumberFields() {
    return (
      <>
        <label htmlFor='number'>{t('number', i18nOpts)}</label>
        <div className="form__row">
          <FormField
            className="input"
            name="preferredPhoneNumber.number"
            type="tel"
            placeholder={t('placeholder.number', i18nOpts)}
            value={this.state.service.preferredPhoneNumber.number}
            onChange={this.handleChange}
            formErrors={this.state.errors}
          />
        </div>
      </>
    );
  }

  renderLinkReferral() {
    const { linkReferralEnabled, sampleReferralLink, service, errors } = this.state;

    return (
      <div className="input">
        <Button
          className="btn btn--secondary input"
          onClick={this.toggleLinkReferral}
          text={linkReferralEnabled ? t('removeReferral', i18nOpts) : t('linkReferral', i18nOpts)}
        />
        {linkReferralEnabled && (
          <>
            <div className="input referral-text">
              <p>
                {t('referralDescription1a', i18nOpts)}&nbsp;
                <a
                  href={sampleReferralLink}
                  target="_blank"
                  className="resource-form referral-link"
                >
                  {t('referralDescription1b', i18nOpts)}
                </a>
                &nbsp;{t('referralDescription2', i18nOpts)}
              </p>
            </div>
            <div className="form__row">
              <FormField
                className="input"
                label={t('referralCategoryLabel', i18nOpts)}
                name="referralCategory"
                placeholder={t('placeholder.category', i18nOpts)}
                value={service.referralCategory}
                onChange={this.handleReferralChange}
                formErrors={errors}
              />

              <FormField
                className="input"
                label={t('referralSubcategoryLabel', i18nOpts)}
                name="referralSubcategory"
                placeholder={t('placeholder.subcategory', i18nOpts)}
                value={service.referralSubcategory}
                onChange={this.handleReferralChange}
                formErrors={errors}
              />

              <FormField
                className="input"
                label={t('referralLabel', i18nOpts)}
                name="referralLinkLabel"
                value={service.referralLinkLabel}
                onChange={this.handleChange}
                onBlur={this.updateSampleReferralLink}
                formErrors={errors}
              />
            </div>

            {service.referralCategory && (
              <div className="label-link input referral-text">
                <span>{t('linkedReferralDescription', i18nOpts)}&nbsp;</span>
                {service.referralLinkLabel ? (
                  <Tooltip text={sampleReferralLink}>
                    <a href={sampleReferralLink} target="_blank" className="contact__details">
                      <span className="contact__details-method contact__details-link">
                        {_.truncate(service.referralLinkLabel, { length: 50, separator: ' ' })}
                      </span>
                      <Icon name="external-link" className="icon_external-link" />
                    </a>
                  </Tooltip>
                ) : (
                  <>{t('missingReferralLabel', i18nOpts)}</>
                )}
              </div>
            )}
            {errors.referralError && (
              <div className="resource-form error">{errors.referralError}</div>
            )}
          </>
        )}
      </div>
    );
  }

  renderFooter() {
    return (
      <ModalFooter
        renderRight={() => (
          <>
            <Button
              className="btn btn--secondary"
              onClick={this.props.onCancel}
              text={t('cancel', i18nOpts)}
            />
            <Submit value={t(this.isUpdate ? 'update' : 'add', i18nOpts)} />
          </>
        )}
      />
    );
  }

  render() {
    return (
      <form className="form resource-form" onSubmit={this.handleSubmit} noValidate>
        <div className="form__row">{this.renderNameField()}</div>
        <div className="form__row">{this.renderDescriptionField()}</div>
        <div className="form__row">{this.renderTagField()}</div>
        <div className="form__row">{this.renderWebsiteFields()}</div>
        {this.renderNumberFields()}
        {this.renderAddressFields()}
        {this.props.caredoveEnabled && <div className="form__row">{this.renderLinkReferral()}</div>}
        {this.renderFooter()}
      </form>
    );
  }
};

const serviceProps = PropTypes.shape({
  name: PropTypes.string,
  url: PropTypes.string,
  address: PropTypes.shape({
    address: PropTypes.string,
    city: PropTypes.string,
    region: PropTypes.string,
    postal_code: PropTypes.string,
    country_code: PropTypes.string,
  }),
  preferredPhoneNumber: PropTypes.shape({
    number: PropTypes.string,
  }),
});

ResourceForm.propTypes = {
  allTags: PropTypes.array,
  isAdHoc: PropTypes.bool,
  isTemplate: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  service: serviceProps,
  serviceId: PropTypes.number,
};

ResourceForm.defaultProps = {
  allTags: [],
  isAdHoc: true, // CRITICAL; DO NOT DELETE
  isTemplate: false,
  parentId: '',
  serviceId: null,
  service: {},
};

export default ResourceForm;
