import CarePlansApi from 'Api/Inbox/CarePlan';

import { RECEIVE_CARE_PLANS } from './carePlans';
import { RECEIVE_ALL_CONTACTS } from './newContacts';

export const REQUEST_CHANNEL_CARE_PLANS = 'REQUEST_CHANNEL_CARE_PLANS';
export const REQUEST_CHANNEL_CONTACTS = 'REQUEST_CHANNEL_CONTACTS';
export const SHOW_CHANNEL_CARE_PLANS = 'SHOW_CHANNEL_CARE_PLANS';
export const SHOW_CHANNEL_CONTACTS = 'SHOW_CHANNEL_CONTACTS';
export const TOGGLE_CHANNEL_MEMBER = 'TOGGLE_CHANNEL_MEMBER';
export const TOGGLE_CHANNEL_MEMBERS = 'TOGGLE_CHANNEL_MEMBERS';
export const NAME_CHANNEL = 'NAME_CHANNEL';
export const SET_CHANNEL_CARE_PLAN_TITLE = 'SET_CHANNEL_CARE_PLAN_TITLE';

export const fetchChannels = () => {
  return (dispatch) => {
    dispatch({ type: REQUEST_CHANNEL_CARE_PLANS });

    return new CarePlansApi()
      .index()
      .then((res) => res.json())
      .then(({ care_plans }) => {
        const carePlans = care_plans.map(
          ({ id, title, patient_photo: patientPhoto, patient_name: patientName }) => ({
            id,
            title,
            patientPhoto,
            patientName,
          })
        );

        dispatch({
          type: RECEIVE_CARE_PLANS,
          carePlans,
        });
      });
  };
};

export const fetchChannelContacts = (id, title) => {
  return (dispatch) => {
    dispatch({ type: REQUEST_CHANNEL_CONTACTS, id });
    dispatch({ type: SET_CHANNEL_CARE_PLAN_TITLE, title });

    return new CarePlansApi(id)
      .channelContacts()
      .then((res) => res.json())
      .then(({ contacts }) => {
        dispatch({ type: RECEIVE_ALL_CONTACTS, contacts });

        if (contacts?.length === 1) {
          dispatch(toggleChannelMember(contacts[0]));
        }
      });
  };
};

export const showChannelCarePlans = () => ({ type: SHOW_CHANNEL_CARE_PLANS });
export const showChannelContacts = () => ({ type: SHOW_CHANNEL_CONTACTS });
export const toggleChannelMember = (member) => ({ type: TOGGLE_CHANNEL_MEMBER, member });
export const toggleChannelMembers = (selected) => ({ type: TOGGLE_CHANNEL_MEMBERS, selected });
export const nameChannel = (members) => ({ type: NAME_CHANNEL, members });
