import { request } from 'Apiv2/handleRequest';

export const buildRequestUrl = ({
  carePlanId,
  type,
  activityId,
  dueAt,
}) => {

  let requestUrl = `/care_plans/${carePlanId}/`;

  let activityType = type;
  if (activityType === 'APPOINTMENT' && !dueAt) {
    activityType = 'TASK';
  }

  switch (activityType) {
    case 'TASK':
      requestUrl += `tasks/${activityId}`;
      break;
    case 'APPOINTMENT':
      requestUrl += `appointments/${activityId}`;
      break;
    default:
      requestUrl = '/404';
      break;
  }

  return requestUrl;
};

export const deleteActivityApiWrapper = (
  requestProvider = request,
  buildRequestUrlProvider = buildRequestUrl,
) => {
  return async ({ carePlanId, type, activityId, dueAt }) => {
    try {
      const requestUrl = buildRequestUrlProvider({
        carePlanId,
        type,
        activityId,
        dueAt,
      });

      const response = await requestProvider(requestUrl, 'DELETE');

      if (response.status === 422) {
        throw new Error();
      }

      return { responseType: 'DATA' };
    } catch (error) {
      return { status: 422, error, responseType: 'ERROR' };
    }
  };
};

export default deleteActivityApiWrapper(request, buildRequestUrl);
