import React from 'react'

import ProviderWrapper from 'Components/Base/ProviderWrapper'
import TeamInvitationsModalContainer from './TeamInvitationsModalContainer'

const InvitationModal = (props) => {
  return (
    <ProviderWrapper>
      <TeamInvitationsModalContainer {...props} />
    </ProviderWrapper>
  )
}

export default InvitationModal
