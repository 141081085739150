import { request } from 'Apiv2/handleRequest';

const userDetailsApiRequestHandler = async (carePlanUuid) => {
  const endpointUrl = `/care_plans/${carePlanUuid}/recommendations/user_details`;

  try {
    const response = await request(endpointUrl);

    const { current_user_name, current_user_id, care_plan_uuid, can_modify, errors } = await response.json();

    if (errors) {
      throw errors;
    }

    if (response.status === 200) {
      return {
        content_type: 'USER_DETAILS',
        current_user_name,
        current_user_id,
        care_plan_uuid,
        can_modify,
      };
    } else {
      return { content_type: 'ERROR', errors: ['UNKNOWN_STATUS'] };
    }
  } catch (error) {
    return { content_type: 'ERROR', error };
  }
};

export default userDetailsApiRequestHandler;
