import Api from 'Api/Api'

class CarePlanTemplateCheckInTemplate extends Api {
  constructor(carePlanTemplateId, id) {
    super()

    this.id = id
    this.carePlanTemplateId = carePlanTemplateId
  }

  get url() {
    return `/care_plan_templates/${this.carePlanTemplateId}/care_plan_template_check_in_templates`
  }
}

export default CarePlanTemplateCheckInTemplate
