import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

const i18nOpts = { scope: 'pagination' }

const DisplayText = ({
  hasMoreThanOnePage,
  itemName,
  currentPageNumber,
  maxItemCountPerPage,
  totalItemCount
}) => (
  <span>
    { hasMoreThanOnePage
      ? t('more_pages.display_entries', {
          ...i18nOpts,
          entry_name: itemName,
          first: (currentPageNumber-1) * maxItemCountPerPage + 1,
          last: currentPageNumber * maxItemCountPerPage < totalItemCount ? currentPageNumber * maxItemCountPerPage : totalItemCount,
          total: totalItemCount
        })
      : t('one_page.display_entries.other', {
          ...i18nOpts,
          entry_name: itemName,
          count: totalItemCount
        })
    }
  </span>
)

DisplayText.propTypes = {
  currentPageNumber: PropTypes.number.isRequired,
  hasMoreThanOnePage: PropTypes.bool.isRequired,
  itemName: PropTypes.string.isRequired,
  maxItemCountPerPage: PropTypes.number.isRequired,
  totalItemCount: PropTypes.number.isRequired
}

export default DisplayText
