import React, { useEffect, useState } from 'react';
import { t } from 'i18n';

import filterByBlockName from './filterByBlockName';
import SymptomIntensity from './SymptomIntensity';
import SymptomOccurrence from './SymptomOccurrence';
import WorryIntensity from './WorryIntensity';
import WorriedAbout from './WorriedAbout';
import CertaintyExpectation from './CertaintyExpectation';
import ExpectedVsReality from './ExpectedVsReality';
import GoingToSchool from './GoingToSchool';
import FriendInteraction from './FriendInteraction';
import FriendReaction from './FriendReaction';
import MyEmotions from './MyEmotions';
import MySleep from './MySleep';
import getCarePlanIdFromPath from 'Components/Dashboard/getCarePlanIdFromPath';
import setupCarePlanBlockFetcher from 'Components/Dashboard/Blocks/setupCarePlanBlockFetcher';
import ErrorDisplay from 'Components/Base/Dashboard/ErrorDisplay';
import Loader from 'Components/Base/Loader';

const i18nOpts = { scope: 'blocks.index' };

const componentMap = {
  symptom_intensity: SymptomIntensity,
  symptom_occurrence: SymptomOccurrence,
  worry_intensity: WorryIntensity,
  worried_about: WorriedAbout,
  certainty_expectation: CertaintyExpectation,
  expected_vs_reality: ExpectedVsReality,
  going_to_school: GoingToSchool,
  friend_interactions: FriendInteraction,
  friend_reactions: FriendReaction,
  my_emotions: MyEmotions,
  my_sleep: MySleep,
};

const ListContainer = () => {
  const [blocks, setBlocks] = useState([]);
  const [pageState, setPageState] = useState('loading');
  const carePlanUuid = getCarePlanIdFromPath(window.location.pathname);

  useEffect(() => {
    setupCarePlanBlockFetcher(carePlanUuid, setBlocks, setPageState);
  }, []);

  switch (pageState) {
    case 'success': {
      const symptomIntensityBlocks = filterByBlockName(blocks, 'symptom_intensity');
      const symptomOccurrenceBlocks = filterByBlockName(blocks, 'symptom_occurrence');
      const worryIntensityBlocks = filterByBlockName(blocks, 'worry_intensity');
      const worriedAboutBlocks = filterByBlockName(blocks, 'worried_about');
      const certaintyExpectationBlocks = filterByBlockName(blocks, 'certainty_expectation');
      const expectedVsRealityBlocks = filterByBlockName(blocks, 'expected_vs_reality');
      const goingToSchoolBlocks = filterByBlockName(blocks, 'going_to_school');
      const friendInteractionBlocks = filterByBlockName(blocks, 'friend_interactions');
      const friendReactionBlocks = filterByBlockName(blocks, 'friend_reactions');
      const myEmotionsBlocks = filterByBlockName(blocks, 'my_emotions');
      const mySleepBlocks = filterByBlockName(blocks, 'my_sleep');

      return [
        ...mySleepBlocks,
        ...symptomIntensityBlocks,
        ...symptomOccurrenceBlocks,
        ...myEmotionsBlocks,
        ...worryIntensityBlocks,
        ...worriedAboutBlocks,
        ...certaintyExpectationBlocks,
        ...expectedVsRealityBlocks,
        ...goingToSchoolBlocks,
        ...friendInteractionBlocks,
        ...friendReactionBlocks,
      ].map(({ care_plan_block_id, block_name }) => {
        const Block = componentMap[block_name];

        return <Block key={care_plan_block_id} blockName={block_name} carePlanUuid={carePlanUuid} />;
      });
    }
    case 'error': {
      return <ErrorDisplay title={t('fetch_error', i18nOpts)} status="ERROR" />;
    }
    default:
      return <Loader />;
  }
};

export default ListContainer;
