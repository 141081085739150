import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Activity, ActivityBody, CarePlanBadge, TaskCheckBox, TaskDetails, AssignmentsWithDueAt } from '../'

import RepeatedTaskOccurrenceApi from 'Api/RepeatedTaskOccurrence'

import { redirectTo } from 'Utilities/linkHelpers'

class RepeatedTask extends Component {
  constructor(props) {
    super(props)

    this.handleCompleteOccurrence = this.handleCompleteOccurrence.bind(this)
    this.state = { isCompleting: false }
  }

  get isOverview() {
    return !!this.props.carePlan.place
  }

  handleCompleteOccurrence(evt) {
    evt.stopPropagation()
    evt.preventDefault()
    this.setState({ isCompleting: true })

    return new RepeatedTaskOccurrenceApi(this.props.carePlanUuid, this.props.id, this.props.dueAt)
      .complete({ isOverview: this.isOverview })
      .then((res) => {
        return res.json().then(({ task }) => {
          if (res.ok) {
            this.props.addTask(task)
            this.props.removeEventFromAgenda({ ...this.props })
          }
        })
      })
  }

  render() {
    const { link, carePlan, assignments, text, dueAt, healthCareView } = this.props
    return (
      <Activity onClick={redirectTo.bind(null, link)}>
        <TaskCheckBox
          canComplete={
            // Temporary: HCP cannot complete RepeatedTasks as functionality is not implemented yet.
            // Remove this prop when functionality is implemented
            !healthCareView
          }
          isCompleting={this.state.isCompleting}
          onClick={this.handleCompleteOccurrence}
        />

        {this.isOverview && <CarePlanBadge {...carePlan} />}

        <ActivityBody text={text}>
          <TaskDetails title={carePlan.title} />
        </ActivityBody>
        <AssignmentsWithDueAt assignments={assignments} dueAt={dueAt} repeated />
      </Activity>
    )
  }
}

RepeatedTask.propTypes = {
  addTask: PropTypes.func.isRequired,
  assignments: PropTypes.array,
  carePlan: PropTypes.shape({
    title: PropTypes.string,
    place: PropTypes.shape({
      name: PropTypes.string,
      logo: PropTypes.string,
    }),
  }),
  carePlanUuid: PropTypes.string,
  dueAt: PropTypes.string.isRequired,
  id: PropTypes.number,
  link: PropTypes.string,
  removeEventFromAgenda: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
}

RepeatedTask.defaultProps = {
  assignments: [],
  carePlan: {},
  carePlanUuid: '',
  id: null,
  link: null,
  type: 'repeated_task',
}

export default RepeatedTask
