import React from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'

import logo from 'logos/logo-small.svg'

import { Icon } from 'Components/Base'

export const CheckInModal = ({ isOpen, onClose, children }) => (
  <ReactModal
    ariaHideApp={false}
    closeTimeoutMS={150}
    isOpen={isOpen}
    onRequestClose={onClose}
    shouldCloseOnOverlayClick={false}
    shouldReturnFocusAfterClose={false}
    className="ReactModal__CheckIn--Content"
  >
    <div className="ReactModal__CheckIn--Header">
      <h2 className="centered">
        <img src={logo} />
        <a className="ReactModal__CheckIn--ModalClose" onClick={onClose}>
          <Icon name="close" />
        </a>
      </h2>
    </div>
    <div className="ReactModal__CheckIn--ContentBody">{children}</div>
  </ReactModal>
)

CheckInModal.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

CheckInModal.defaultProps = {
  children: null,
}

ReactModal.defaultStyles = {} // Clear default styles

export default CheckInModal
