import React from 'react'
import PropTypes from 'prop-types'

import { Icon, Tooltip } from 'Components/Base'

import { t } from 'i18n'

const i18nOpts = { scope: 'care_plan_creators.pill_actions' }

const ItemPillActionLink = ({ iconName, link, ...rest }) => (
  <a className="icon item-pill__action" href={link} {...rest}>
    <Tooltip text={t(iconName, i18nOpts)}>
      <Icon className="item-pill__action-icon" name={iconName} />
    </Tooltip>
  </a>
)

ItemPillActionLink.propTypes = {
  iconName: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}

ItemPillActionLink.defaultProps = {}

export default ItemPillActionLink
