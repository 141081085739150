import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { t } from 'i18n'

import Loader from 'Components/Base/Loader'
import SelectedDocuments from './SelectedDocuments'
import UnselectedDocuments from './UnselectedDocuments'

import { toggleLibraryDocumentSelection, search } from 'Actions/documents'
import { getIsFetchingDocuments, getSearchQuery } from 'Selectors/documents'

const i18nOpts = { scope: 'care_plans.document_section.edit.library_documents' }

class LibraryDocuments extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedListIsOpen: true,
    }

    this.toggleSelectedListVisibility = this.toggleSelectedListVisibility.bind(this)
  }

  toggleSelectedListVisibility() {
    this.setState((prevState) => ({ selectedListIsOpen: !prevState.selectedListIsOpen }))
  }

  render() {
    return (
      <Fragment>
        <h4 className="careplan__subheader">{t('subheader', i18nOpts)}</h4>

        {this.props.isFetching ? (
          <Loader />
        ) : (
          <Fragment>
            <SelectedDocuments
              libraryDocuments={this.props.selectedDocuments}
              listIsOpen={this.state.selectedListIsOpen}
              toggleListVisibility={this.toggleSelectedListVisibility}
              toggleSelection={this.props.toggleSelection}
            />

            <UnselectedDocuments
              libraryDocuments={this.props.unselectedDocuments}
              libraryIsEmpty={this.props.libraryIsEmpty}
              onSearchChange={this.props.search}
              searchQuery={this.props.searchQuery}
              toggleSelection={this.props.toggleSelection}
            />
          </Fragment>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  isFetching: getIsFetchingDocuments(state),
  searchQuery: getSearchQuery(state),
})

const mapDispatchToProps = (dispatch) => ({
  toggleSelection(libraryDocumentId) {
    dispatch(toggleLibraryDocumentSelection(libraryDocumentId))
  },

  search({ target: { value } }) {
    dispatch(search(value))
  },
})

const documentPropType = PropTypes.shape({
  author: PropTypes.object,
  canModify: PropTypes.bool,
  createdAt: PropTypes.string,
  documentFileName: PropTypes.string,
  downloadUrl: PropTypes.string,
  id: PropTypes.number,
  libraryDocumentId: PropTypes.number,
  name: PropTypes.string,
  url: PropTypes.string,
})

LibraryDocuments.propTypes = {
  // dispatch
  isFetching: PropTypes.bool,
  libraryIsEmpty: PropTypes.bool.isRequired,
  // state
  search: PropTypes.func,
  searchQuery: PropTypes.string,
  // props
  selectedDocuments: PropTypes.arrayOf(documentPropType),
  toggleSelection: PropTypes.func,
  unselectedDocuments: PropTypes.arrayOf(documentPropType),
}

LibraryDocuments.defaultProps = {
  selectedDocuments: [],
  unselectedDocuments: [],
}

export default connect(mapStateToProps, mapDispatchToProps)(LibraryDocuments)
