import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'
import { connect } from 'react-redux'

import Aside from '../NewContacts/Aside'
import Contact from '../NewContacts/Contact'
import Filters from './Filters'

import { showContacts } from 'Actions/inbox/sideNav/contacts'
import { fetchCarePlanContacts } from 'Actions/inbox/sideNav/carePlans'
import { fetchChannelContacts } from 'Actions/inbox/sideNav/channel'
import { search } from 'Actions/inbox/sideNav/general'

import { getSideNavOpenState, getCarePlanFetchingState, getCarePlans, getSideNavMode } from 'Selectors/inbox'

import { getCurrentUser } from 'Selectors/currentUser'

const i18nOpts = { scope: 'inbox.side_nav.care_plans' }

class CarePlansContainer extends Component {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
    this.renderCarePlans = this.renderCarePlans.bind(this)

    this.state = {
      mode: this.props.mode,
    }
  }

  get info() {
    const userType = this.props.isPlaceUser ? 'health_care' : 'non_health_care'
    return t(`${userType}.${this.state.mode}.info`, i18nOpts)
  }

  handleClick(id, title) {
    if (this.props.mode === 'channelCarePlans') {
      this.props.fetchChannelContacts(id, title)
    } else {
      this.props.fetchContacts(id)
    }
  }

  renderCarePlans() {
    const { carePlans, isPlaceUser, search } = this.props

    return (
      <Fragment>
        {isPlaceUser && <Filters search={search} />}

        <p className="inbox-menu__info">{this.info}</p>

        <section className="inbox-menu__contacts">
          <div className="inbox-menu__contacts-scroll">
            {carePlans.length ? (
              carePlans.map(({ id, patientName, patientPhoto, title }) => (
                <Contact
                  key={id}
                  name={patientName}
                  photo={patientPhoto}
                  title={title}
                  titleProminence={true}
                  onClick={this.handleClick.bind(null, id, title)}
                  radio
                />
              ))
            ) : (
              <div className="inbox-menu__contact inbox-menu__contact--empty">
                {t('no_results', i18nOpts)}
              </div>
            )}
          </div>
        </section>
      </Fragment>
    )
  }

  render() {
    const { isOpen, back, isFetching } = this.props

    return (
      <Aside
        isOpen={isOpen}
        isFetching={isFetching}
        onClick={back}
        header={t('header', i18nOpts)}
        render={this.renderCarePlans}
      />
    )
  }
}

CarePlansContainer.propTypes = {
  back: PropTypes.func.isRequired,
  carePlans: PropTypes.array,
  fetchChannelContacts: PropTypes.func.isRequired,
  fetchContacts: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isPlaceUser: PropTypes.bool,
  mode: PropTypes.string,
  search: PropTypes.func.isRequired,
}

CarePlansContainer.defaultProps = {
  carePlans: [],
  isPlaceUser: false,
  mode: 'carePlan',
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  carePlans: getCarePlans(state),
  isOpen: getSideNavOpenState(state),
  isFetching: getCarePlanFetchingState(state),
  mode: getSideNavMode(state),
  isPlaceUser: getCurrentUser(state).isPlaceUser,
})

const mapDispatchToProps = (dispatch) => ({
  back: () => dispatch(showContacts()),
  fetchChannelContacts: (id, title) => dispatch(fetchChannelContacts(id, title)),
  fetchContacts: (id) => dispatch(fetchCarePlanContacts(id)),
  search: ({ target: { value } }) => dispatch(search(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CarePlansContainer)
