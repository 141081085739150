import React from 'react'
import PropTypes from 'prop-types'

import CheckboxListItem from 'Components/Base/CheckboxListItem'

const CheckboxList = ({ header, onClick, options, selectedOptionIds }) => (
  <div className="radio-button-list">
    <div className="radio-button-list__header">{header}</div>

    <div className="radio-button-list__body">
      {options.map((option) => (
        <CheckboxListItem
          key={option.id}
          isSelected={selectedOptionIds.includes(option.id)}
          onClick={onClick.bind(null, option.id)}
          name={option.name}
        />
      ))}
    </div>
  </div>
)

CheckboxList.propTypes = {
  header: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  selectedOptionIds: PropTypes.array,
}

CheckboxList.defaultProps = {
  options: [],
  selectedOptionIds: [],
}

export default CheckboxList
