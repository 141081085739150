import React from 'react'
import PropTypes from 'prop-types'

import ProviderWrapper from 'Components/Base/ProviderWrapper'
import SideBarContainer from './SideBarContainer'

const SideBarModule = (props) => (
  <ProviderWrapper>
    <SideBarContainer {...props} />
  </ProviderWrapper>
)

SideBarModule.propTypes = {
  back: PropTypes.string,
  carePlans: PropTypes.array,
  name: PropTypes.string,
  photo: PropTypes.string,
}

SideBarModule.defaultProps = {
  back: '',
  carePlans: [],
  name: '',
  photo: '',
}

export default SideBarModule
