import { connect } from 'react-redux'

import { setEventFilter } from 'Actions/filter'
import { getFilter } from 'Selectors/filter'
import { getHealthCareView } from 'Selectors/agenda'

import Filters from './Filters'

const mapStateToProps = (state) => ({
  value: getFilter(state),
  healthCareView: getHealthCareView(state),
  isPlaceUser: state.currentUser.isPlaceUser,
})

const mapDispatchToProps = (dispatch) => ({
  onChange({ target: { value } }) {
    dispatch(setEventFilter(value))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Filters)
