export default {
  menu: (base) => ({ ...base, fontWeight: '400', color: '#333333', zIndex: 100 }),
  input: (base) => ({
    ...base,
    input: {
      borderRadius: 0,
    },
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? '#DDDDDD' : base.backgroundColor,
    borderBottom: '1px solid #CCCCCC',
    '&:last-child': {
      borderBottom: 0,
    },
  }),
  control: (base, state) => ({
    ...base,
    boxShadow: 'none',
    borderColor: state.isFocused ? '#007FAE' : base.borderColor,
    '&:hover': {
      borderColor: state.isFocused ? '#007FAE' : base.borderColor,
    },
  }),
  multiValue: (base, state) => {
    if (!state.data.label) {
      return { ...base, display: 'none' };
    }

    return state.data.readOnly
      ? { ...base, backgroundColor: '#DDDDDD', borderRadius: '8px' }
      : { ...base, backgroundColor: '#F0F2F3', color: '#333333', borderRadius: '8px' };
  },
  multiValueLabel: (base, state) => {
    return state.data.readOnly
      ? { ...base, fontWeight: '400', paddingRight: 6, color: '#444444' }
      : { ...base, fontWeight: '400', color: '#333333' };
  },
  multiValueRemove: (base, state) => {
    return state.data.readOnly
      ? { ...base, display: 'none' }
      : {
          ...base,
          '&:hover': {
            color: '#003B69',
            backgroundColor: '#F0F2F3',
            borderBottomRightRadius: '8px',
            borderTopRightRadius: '8px',
          },
        };
  },
};
