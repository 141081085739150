import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { MenuItemLink } from 'Components/Base/Menu'
import Icon from 'Components/Base/Icon'
import Backdrop from 'Components/Base/Backdrop'

const i18nOpts = { scope: 'header.menu.index' }

class Menu extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
    }

    this.open = this.open.bind(this)
    this.close = this.close.bind(this)
  }

  open() {
    this.setState({ isOpen: true })
  }

  close() {
    this.setState({ isOpen: false })
  }

  render() {
    return (
      <div className="dropdown">
        <div
          className="icon-container header-nav__hamburger"
          onClick={this.open}
          tabIndex="0"
          onKeyDown={({ key }) => (key === 'Enter' || key === ' ') &&
            this.setState(prevState => ({
              isOpen: !prevState.isOpen
            }))}
        >
          <Icon name="menu2" />
        </div>

        <span
          className="header-nav__my-profile"
          onClick={this.open}
          tabIndex="0"
          onKeyDown={({ key }) => (key === 'Enter' || key === ' ') &&
            this.setState(prevState => ({
              isOpen: !prevState.isOpen
            }))}
        >
          {t('my_profile', i18nOpts)}
          <Icon name="carrot-down" />
        </span>

        {this.state.isOpen && (
          <Fragment>
            <div className="dropdown__menu header-nav__dropdown">
              {this.props.carePlanLinks.map((link, idx) => (
                <MenuItemLink key={idx + 10} mobile {...link} />
              ))}
              {this.props.links.map((link, idx) => (
                <MenuItemLink key={idx} className={this.props.className} {...link} />
              ))}
            </div>

            <Backdrop transparent onClick={this.close} />
          </Fragment>
        )}
      </div>
    )
  }
}

Menu.propTypes = {
  carePlanLinks: PropTypes.array,
  className: PropTypes.string,
  links: PropTypes.array,
}

Menu.defaultProps = {
  carePlanLinks: [],
  className: '',
  links: [],
}

export default Menu
