import { request } from 'Apiv2/handleRequest';
import { getAddressFields, parseAssignments } from '../../ApiUtils';

export const buildRequestParams = (
  carePlanId,
  type,
  activityId,
  body,
  dueAt,
) => {
  let requestUrl = `/care_plans/${carePlanId}/`;
  let requestBody;

  let activityType = type;
  if (activityType === 'APPOINTMENT' && !dueAt) {
    activityType = 'TASK';
  }

  switch (activityType) {
    case 'TASK':
      requestUrl += 'tasks/';
      requestBody = { task: { ...body } };
      break;
    case 'APPOINTMENT':
      requestUrl += 'appointments/';
      requestBody = { appointment: { ...body } };
      break;
    default:
      requestUrl = '/404';
      break;
  }

  requestUrl += activityId;

  return { requestUrl, requestBody };
};

export const updateActivityApiWrapper = (
  requestProvider = request,
  buildRequestParamsProvider = buildRequestParams
) => {
  return async ({ carePlanId, activity, type, activityId, allResources }) => {
    try {
      const {
        description,
        virtualVisitLink,
        addressFields,
        dueAt,
        assignments,
        resources,
      } = activity;

      const body = {
        text: description,
        url: virtualVisitLink,
        address_attributes: getAddressFields(addressFields),
        due_at: dueAt,
        assignments: parseAssignments(assignments) || [],
        resources: allResources.map(r => (
          { ...r, selected: !!resources.find(sr => sr.id === r.id) }
        )),
      };

      const { requestUrl, requestBody } = buildRequestParamsProvider(
        carePlanId,
        type,
        activityId,
        body,
        dueAt,
      );

      const response = await requestProvider(requestUrl, 'PATCH', requestBody);

      const { ok, status, statusText } = response;

      if (!ok) {
        throw {
          status,
          statusText,
        };
      }

      return { responseType: 'DATA' };
    } catch (error) {
      return { status: error.status, error, responseType: 'ERROR' };
    }
  };
};

export default updateActivityApiWrapper(request, buildRequestParams);
