import Api from './Api';

class RepeatedFormResponse extends Api {
  constructor(carePlanId, repeatedFormId, id, currentUrl) {
    super();

    this.carePlanId = carePlanId;
    this.repeatedFormId = repeatedFormId;
    this.id = id;
    this.currentUrl = encodeURI(currentUrl);
  }

  get url() {
    return `/care_plans/${this.carePlanId}/repeated_forms/${this.repeatedFormId}/form_responses?redirect_url=${this.currentUrl}?status=success`;
  }
}

export default RepeatedFormResponse;
