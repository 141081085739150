import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import SearchInput from 'Components/Forms/SearchInput'

const i18nOpts = { scope: 'inbox.side_nav.care_plans' }

const Filters = ({ search }) => (
  <nav className="inbox-menu__filters inbox-menu__filters--careplans">
    <SearchInput onChange={search} placeholder={t('filter', i18nOpts)} />
  </nav>
)

Filters.propTypes = {
  search: PropTypes.func.isRequired,
}

export default Filters
