import React from 'react'
import PropTypes from 'prop-types'
import IconButton from 'Components/Base/IconButton'
import { t } from 'i18n'

const LinkResourceButton = ({ action }) => {
  return (
    <IconButton
      iconName="plus_square"
      isSecondary
      onClick={action}
      text={t('link_button', { scope: 'tasks.index.resources' })}
    />
  )
}

LinkResourceButton.propTypes = { action: PropTypes.func }
LinkResourceButton.defaultProps = { action: null }

export default LinkResourceButton
