import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Icon from 'Components/Base/Icon';
import Invitation from './Invitation';

const InvitationList = ({ collapsed, header, subheader, invitations }) => {
  const [isCollapsed, setCollapsed] = useState(collapsed);
  const toggleCollapsed = () => {
    setCollapsed((isCollapsed) => !isCollapsed);
  };

  return (
    <div className="card">
      <div className="header select-header">
        <h2 className="text-medium">{header}</h2>
        <span data-test="caret" onClick={toggleCollapsed}>
          <Icon className="icon--large" name={isCollapsed ? 'carrot-down' : 'carrot-up'} />
        </span>
      </div>
      {!isCollapsed && (
        <>
          {subheader && <div className="invitation__description text-medium">{subheader}</div>}
          {invitations.map((invitation) => (
            <Invitation key={invitation.id} {...invitation} />
          ))}
        </>
      )}
    </div>
  );
};

InvitationList.propTypes = {
  collapsed: PropTypes.bool,
  header: PropTypes.string.isRequired,
  invitations: PropTypes.array.isRequired,
  subheader: PropTypes.string,
};

InvitationList.defaultProps = {
  collapsed: false,
  subheader: null,
};

export default InvitationList;
