import Api from './Api'

class RepeatedTaskOccurrence extends Api {
  constructor(carePlanId, repeatedTaskId, occurrenceTimestamp) {
    super()

    this.carePlanId = carePlanId
    this.repeatedTaskId = repeatedTaskId
    this.occurrenceTimestamp = occurrenceTimestamp

    this.complete = this.complete.bind(this)
    this.update_assignment = this.update_assignment.bind(this)
    this.create_assignment = this.create_assignment.bind(this)
  }

  destroy(body) {
    return this.request(`${this.occurrenceUrl}`, 'DELETE', body)
  }

  update(body) {
    return this.request(`${this.occurrenceUrl}`, 'PATCH', body)
  }

  complete({ isOverview }) {
    return this.request(`${this.occurrenceUrl}/complete`, 'PATCH', { overview: isOverview })
  }

  update_assignment({ teamMembershipId, status }) {
    return this.request(`${this.occurrenceUrl}/update_assignment`, 'PATCH', {
      assignment: {
        team_membership_id: teamMembershipId,
        status,
      },
    })
  }

  create_assignment({ teamMembershipId }) {
    return this.request(`${this.occurrenceUrl}/create_assignment`, 'PATCH', {
      assignment: {
        team_membership_id: teamMembershipId,
        status: 'accepted',
      },
    })
  }

  get occurrenceUrl() {
    const encodedOccurrenceTimestamp = encodeURIComponent(this.occurrenceTimestamp).replace(/\./g, '%20')
    return `/care_plans/${this.carePlanId}/repeated_tasks/${this.repeatedTaskId}/occurrences/${encodedOccurrenceTimestamp}`
  }
}

export default RepeatedTaskOccurrence
