import { t } from 'i18n';

const i18nOpts = { scope: 'dashboard.insulin' };

export const validateDoseValues = (tProvider, tOpts) => (doseValues) => {
  const MAXIMUM_DOSE = 100;

  const NUMERICAL_FIELDS = [
    'breakfastLong',
    'breakfastICR',
    'breakfastISF',
    'lunchLong',
    'lunchICR',
    'lunchISF',
    'dinnerLong',
    'dinnerICR',
    'dinnerISF',
    'bedtimeLong',
    'bedtimeICR',
    'bedtimeISF',
  ];

  let inputErrors = {};

  // Loop through every numerical field and ensure it does not equal/exceed MAXIMUM_DOSE
  NUMERICAL_FIELDS.forEach((fieldName) => {
    const fieldValue = Number(doseValues[fieldName]);

    if (fieldValue >= MAXIMUM_DOSE) {
      inputErrors[fieldName] = tProvider('exceeds_maximum_dose', tOpts);
    }
  });

  if (Object.values(inputErrors).length > 0) {
    return {
      inputErrors,
      isValid: false,
    };
  }

  return { isValid: true };
};

export default validateDoseValues(t, i18nOpts);
