import {
  SET_PAGE_STATE,
  SET_PATIENTS,
  SET_SORT_BY,
  SET_SEARCH_INPUT,
  SET_IS_SHOWING_MY_PATIENTS,
  SET_IS_SHOWING_ARCHIVED_PATIENTS,
  SET_MY_PATIENT_IDS,
  SET_ARCHIVED_PATIENTS_AND_IDS
} from 'Actions/placeUsersPatientsIndexPage';

const initialState = {
  pageState: 'loading',
  patients: [],
  sortBy: 'createdAtDesc',
  searchInput: '',
  isShowingMyPatients: false,
  isShowingArchivedPatients: false,
  myPatientIds: [],
  archivedPatientIds: [],
};

const placeUsersPatientsIndexPage = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAGE_STATE:
      return {
        ...state,
        pageState: action.pageState,
      };
    case SET_PATIENTS:
      return {
        ...state,
        patients: action.patients,
      };
    case SET_SORT_BY:
      return {
        ...state,
        sortBy: action.sortBy,
      };
    case SET_SEARCH_INPUT:
      return {
        ...state,
        searchInput: action.searchInput,
      };
    case SET_IS_SHOWING_MY_PATIENTS:
      return {
        ...state,
        isShowingMyPatients: action.isShowingMyPatients,
      };
    case SET_IS_SHOWING_ARCHIVED_PATIENTS:
      return {
        ...state,
        isShowingArchivedPatients: action.isShowingArchivedPatients,
      };
    case SET_MY_PATIENT_IDS:
      return {
        ...state,
        myPatientIds: action.myPatientIds,
      };
    case SET_ARCHIVED_PATIENTS_AND_IDS:
      return {
        ...state,
        archivedPatientIds: action.archivedPatientIds,
      };
    default:
      return state;
  }
};

export default placeUsersPatientsIndexPage;
