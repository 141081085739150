export const CAREGIVER_SSU_USER_PHOTO_UPLOADED = 'self.signup.user.photo.uploaded';
export const CAREGIVER_SSU_USER_PHOTO_SKIPPED = 'self.signup.user.photo.skipped';
export const CAREGIVER_SSU_PATIENT_SELECTED = 'self.signup.patient.selected';
export const CAREGIVER_SSU_CAREGIVER_SELECTED = 'self.signup.caregiver.selected';

const trackUserPhotoUploaded = () => {
  track(CAREGIVER_SSU_USER_PHOTO_UPLOADED);
};

const trackUserPhotoSkipped = () => {
  track(CAREGIVER_SSU_USER_PHOTO_SKIPPED);
};

const trackPatientSelected = () => {
  track(CAREGIVER_SSU_PATIENT_SELECTED);
};

const trackCaregiverSelected = () => {
  track(CAREGIVER_SSU_CAREGIVER_SELECTED);
};

const track = (event) => {
  // This doesn't really match the Rails config, but it's a simpler method in the meantime to minimize changes
  if (process.env.NODE_ENV !== 'development') {
    mixpanel.track(event, mixpanelPayload);
  }
};

export { trackUserPhotoUploaded, trackUserPhotoSkipped, trackPatientSelected, trackCaregiverSelected };
