import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { t } from 'i18n';

import { MenuItem } from 'Components/Base/Menu';
import Backdrop from 'Components/Base/Backdrop';

import {
  setIsFilterByStatusDropdownOpen,
  setFilterByStatusDropdownInput,
  setFilteredSignups,
  setCurrentPageNumber,
} from 'Actions/carePlanPlaceInvitationsPage';
import { filterSignupsAction, filterByStatus } from '../filterActions';

const i18nOpts = { scope: 'care_plan_place_invitations.index.status' };

const Dropdown = ({
  search,
  signups,
  setIsFilterByStatusDropdownOpen,
  setFilterByStatusDropdownInput,
  setFilteredSignups,
  setCurrentPageNumber,
  currentTab,
}) => {
  const updateSignups = filterByStatus(
    setFilterByStatusDropdownInput,
    setIsFilterByStatusDropdownOpen,
    setFilteredSignups,
    setCurrentPageNumber,
    signups,
    search,
    currentTab
  );

  return (
    <div className="dropdown">
      <div className="dropdown__menu">
        <MenuItem
          onClick={() => {
            filterSignupsAction(updateSignups, 'all');
          }}
          text={t('all', i18nOpts)}
        />
        <MenuItem
          onClick={() => {
            filterSignupsAction(updateSignups, 'pending');
          }}
          text={t('pending', i18nOpts)}
        />
        <MenuItem
          onClick={() => {
            filterSignupsAction(updateSignups, 'accepted');
          }}
          text={t('accepted', i18nOpts)}
        />
        <MenuItem
          onClick={() => {
            filterSignupsAction(updateSignups, 'declined');
          }}
          text={t('declined', i18nOpts)}
        />
      </div>

      <Backdrop
        onClick={() => {
          setIsFilterByStatusDropdownOpen(false);
        }}
        transparent
      />
    </div>
  );
};

const mapStateToProps = ({ carePlanPlaceInvitationsPage }) => {
  const {
    currentSearchInput,
    filterByStatusDropdown: { input },
    signups,
    currentTab,
  } = carePlanPlaceInvitationsPage;

  return {
    search: currentSearchInput,
    signups,
    status: input,
    currentTab,
  };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetIsFilterByStatusDropdownOpen = (dispatch) => {
    return (isDropdownOpen) => {
      dispatch(setIsFilterByStatusDropdownOpen(isDropdownOpen));
    };
  };

  const dispatchSetCurrentPageNumber = (dispatch) => {
    return (pageNumber) => {
      dispatch(setCurrentPageNumber(pageNumber));
    };
  };

  const dispatchSetFilterByStatusDropdownInput = (dispatch) => {
    return (input) => {
      dispatch(setFilterByStatusDropdownInput(input));
    };
  };

  const dispatchFilteredSignups = (dispatch) => {
    return (signups) => {
      dispatch(setFilteredSignups(signups));
    };
  };

  return {
    setCurrentPageNumber: dispatchSetCurrentPageNumber(dispatch),
    setFilteredSignups: dispatchFilteredSignups(dispatch),
    setFilterByStatusDropdownInput: dispatchSetFilterByStatusDropdownInput(dispatch),
    setIsFilterByStatusDropdownOpen: dispatchSetIsFilterByStatusDropdownOpen(dispatch),
  };
};

Dropdown.propTypes = {
  setCurrentPageNumber: PropTypes.func.isRequired,
  setFilterByStatusDropdownInput: PropTypes.func.isRequired,
  setFilteredSignups: PropTypes.func.isRequired,
  setIsFilterByStatusDropdownOpen: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dropdown);
