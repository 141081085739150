import React from 'react';
import { connect } from 'react-redux';
import Icon from 'Components/Base/Icon';

import { setIsTogglingDays, setRenderedDays } from 'Actions/dashboardComparisonChart';

const ToggledIndicator = ({
  day, // one of mon, tue, wed, etc.
  isTogglingDays,
  renderedDays,
  setIsTogglingDays,
  setRenderedDays
}) => {
  if (!isTogglingDays) return <div className="comparison-chart__day-selector__toggle-indicator"></div>;

  const handleClickDay = (day) => {
    if (isTogglingDays) {
      setRenderedDays({
        ...renderedDays,
        [day]: !renderedDays[day],
      });
    } else {
      setIsTogglingDays(true);
      setRenderedDays({ [day]: true });
    }
  };
  const isChecked = renderedDays[day];

  return (
    <div className="comparison-chart__day-selector__toggle-indicator">
      <div
        onClick={() => handleClickDay(day)}
        className={`comparison-chart__day-toggle comparison-chart__day-toggle${isChecked ? '--checked' : '--unchecked'}`}
        tabIndex="0"
      >
        {isChecked && <Icon name="check_alt" />}
      </div>
    </div>
  )
};

const mapStateToProps = ({ dashboardComparisonChart: { renderedDays, isTogglingDays } }) => {
  return { renderedDays, isTogglingDays };
};

const mapDispatchToProps = (dispatch) => ({
  setIsTogglingDays: (isTogglingDays) => dispatch(setIsTogglingDays(isTogglingDays)),
  setRenderedDays: (renderedDays) => dispatch(setRenderedDays(renderedDays)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ToggledIndicator);
