import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import Button from 'Components/Base/Button'
import { Footer } from 'Components/Base/Card/'
import Icon from 'Components/Base/Icon'
import CarePlanButtons from 'Components/Patient/Index/CarePlanButtons'

import banner from 'health_team_invite_banner.svg'

const i18nOpts = { scope: 'welcome_banner.health_team_banner' }

class NewCarePlanBanner extends Component {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
    this.state = { visible: true }
  }

  handleClick() {
    this.setState({ visible: false })
  }

  render() {
    return this.state.visible ? (
      <div className="card card--wave-header ">
        <header className="card__image-header">
          <div className="card__image-header-text">
            {t('header', {
              ...i18nOpts,
              care_plan_title: this.props.carePlanTitle,
              patient_name: this.props.patientName,
            })}
          </div>

          <div className="card__image-header-image-center">
            <img src={banner} className="banner__image" />
          </div>

          <a onClick={this.handleClick} className="card__image-header-close" data-test="close">
            <Icon name="close" />
          </a>
        </header>

        <div className="card__body">
          <p className="h3 text-grey-darker">
            {t('details', {
              ...i18nOpts,
              patient_name: this.props.patientName,
              originating_place: this.props.originatingPlace,
            })}
          </p>
          <br />

          <Footer
            right={
              <>
                <Button text={t('button', i18nOpts)} onClick={this.handleClick} className="btn--secondary" />
                <CarePlanButtons
                  newCarePlanPath={this.props.newCarePlanPath}
                  registrationIsOpen={false}
                  templatesAreAvailable={this.props.templatesAreAvailable}
                />
              </>
            }
          />
        </div>
      </div>
    ) : null
  }
}

NewCarePlanBanner.propTypes = {
  carePlanTitle: PropTypes.string,
  newCarePlanPath: PropTypes.string.isRequired,
  originatingPlace: PropTypes.string.isRequired,
  patientName: PropTypes.string.isRequired,
  templatesAreAvailable: PropTypes.bool.isRequired,
}

NewCarePlanBanner.defaultProps = {
  carePlanTitle: '',
}

export default NewCarePlanBanner
