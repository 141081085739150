import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { t } from 'i18n'

import { Aside, Loader } from 'Components/Base'
import NotificationList from './NotificationList'

import { actions } from 'Actions/notificationTray'

const i18nOpts = { scope: 'notifications' }

const NotificationTray = ({ isFetching, isOpen, notifications, onClose }) => (
  <Aside header={t('header', i18nOpts)} isVisible={isOpen} headerColor="blue" onClose={onClose}>
    {isFetching ? <Loader /> : <NotificationList notifications={notifications} />}
  </Aside>
)

NotificationTray.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  notifications: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
}

const mapStateToProps = ({ notificationTray }) => ({
  isFetching: notificationTray.isFetching,
  isOpen: notificationTray.isOpen,
  notifications: notificationTray.notifications,
})

const mapDispatchToProps = {
  onClose: actions.closeTray,
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationTray)
