export const SET_RECOMMENDATIONS = 'SET_RECOMMENDATIONS';
export const SET_EDITING_RECOMMENDATION_INDEX = 'SET_EDITING_RECOMMENDATION_INDEX';
export const SET_IS_LIST_TRUNCATED = 'SET_IS_LIST_TRUNCATED';
export const SET_REMOVING_RECOMMENDATION_INDEX = 'SET_REMOVING_RECOMMENDATION_INDEX';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const SET_IS_PROCESSING = 'SET_IS_PROCESSING';

const setRecommendations = (recommendations) => ({
  type: SET_RECOMMENDATIONS,
  recommendations,
});

const setEditingRecommendationIndex = (editingRecommendationIndex) => ({
  type: SET_EDITING_RECOMMENDATION_INDEX,
  editingRecommendationIndex,
});

const setIsListTruncated = (isListTruncated) => ({
  type: SET_IS_LIST_TRUNCATED,
  isListTruncated,
});

const setRemovingRecommendationIndex = (removingRecommendationIndex) => ({
  type: SET_REMOVING_RECOMMENDATION_INDEX,
  removingRecommendationIndex,
});

const setUserDetails = (userDetails) => ({
  type: SET_USER_DETAILS,
  userDetails,
});

const setIsProcessing = (isProcessing) => ({
  type: SET_IS_PROCESSING,
  isProcessing,
});

export {
  setRecommendations,
  setEditingRecommendationIndex,
  setIsListTruncated,
  setRemovingRecommendationIndex,
  setUserDetails,
  setIsProcessing,
};
