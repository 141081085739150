import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setCurrentPageNumber } from 'Actions/carePlanPlaceInvitationsPage';
import { pageNumberButtonArray, getTotalPageCount } from './pageNumberButtonHelper';

const PageNumberButtons = ({
  buttonCount,
  currentPageNumber,
  maxItemCountPerPage,
  setCurrentPageNumber,
  totalItemCount,
}) => {
  const totalPageCount = getTotalPageCount(totalItemCount, maxItemCountPerPage);

  return pageNumberButtonArray(totalPageCount, buttonCount, currentPageNumber).map((number) =>
    number === currentPageNumber ? (
      <span className="page--current" key={number}>
        <a>{number}&#32;</a>
      </span>
    ) : (
      <span
        className="page"
        key={number}
        onClick={() => {
          setCurrentPageNumber(number);
        }}
      >
        <a>{number}&#32;</a>
      </span>
    )
  );
};

const mapStateToProps = ({ carePlanPlaceInvitationsPage }) => {
  const {
    filteredSignups,
    pagination: { currentPageNumber, buttonCount, maxItemCountPerPage },
  } = carePlanPlaceInvitationsPage;

  return {
    buttonCount,
    currentPageNumber,
    maxItemCountPerPage,
    totalItemCount: filteredSignups.length,
  };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetCurrentPageNumber = (dispatch) => {
    return (page) => {
      dispatch(setCurrentPageNumber(page));
    };
  };

  return {
    setCurrentPageNumber: dispatchSetCurrentPageNumber(dispatch),
  };
};

PageNumberButtons.propTypes = {
  buttonCount: PropTypes.number,
  currentPageNumber: PropTypes.number,
  maxItemCountPerPage: PropTypes.number,
  setCurrentPageNumber: PropTypes.func,
  totalItemCount: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageNumberButtons);
