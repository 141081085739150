import React from 'react';
import ProviderWrapper from 'Components/Base/ProviderWrapper';
import Container from './Container';

const ComparisonChartWrapper = ({ data, error, monitorType }) => (
  <ProviderWrapper>
    <Container data={data} error={error} monitor={monitorType} />
  </ProviderWrapper>
);

export default ComparisonChartWrapper;
