import React from 'react'
import PropTypes from 'prop-types'

import InputItemPill from 'Components/Forms/InputItemPill'
import { ItemPillIcon, ItemPillActionLink } from 'Components/Base/ItemPill'

const DocumentList = ({ emptyStateWarning, libraryDocuments, selected, toggleSelection }) => (
  <div className="item-pill-section__item-list">
    {libraryDocuments.length ? (
      libraryDocuments.map((document) => (
        <InputItemPill
          key={document.id}
          name={document.name}
          selected={selected}
          toggleSelection={toggleSelection.bind(null, document.id)}
          renderImage={() => <ItemPillIcon iconName="file" />}
          renderActions={() => (
            <ItemPillActionLink
              link={document.url}
              iconName="external-link"
              target="_blank"
              data-test="preview-url"
            />
          )}
        />
      ))
    ) : (
      <div className="item-pill-section--item-list-empty">
        <span>{emptyStateWarning}</span>
      </div>
    )}
  </div>
)

DocumentList.propTypes = {
  emptyStateWarning: PropTypes.string.isRequired,
  libraryDocuments: PropTypes.array,
  selected: PropTypes.bool.isRequired,
  toggleSelection: PropTypes.func.isRequired,
}

DocumentList.defaultProps = {
  libraryDocuments: [],
}

export default DocumentList
