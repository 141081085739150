import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SearchInput from 'Components/Forms/SearchInput';
import {
  setCurrentSearchInput,
  setFilteredSignups,
  setCurrentPageNumber,
} from 'Actions/carePlanPlaceInvitationsPage';
import { filterSignupsAction, filterBySearch } from './filterActions';

import { t } from 'i18n';
const i18nOpts = { scope: 'care_plan_place_invitations.index.search_input' };

const SignupsSearchFilter = ({
  currentSearchInput,
  signups,
  setCurrentSearchInput,
  setCurrentPageNumber,
  setFilteredSignups,
  status,
  tab,
}) => {
  const updateSignups = filterBySearch(
    setCurrentSearchInput,
    setFilteredSignups,
    setCurrentPageNumber,
    signups,
    status,
    tab
  );

  return (
    <SearchInput
      className="item-pill__item-resource-list-search"
      name="invitationFilter"
      placeholder={t('place_holder', i18nOpts)}
      onChange={(evt) => {
        filterSignupsAction(updateSignups, evt.target.value);
      }}
      value={currentSearchInput}
    />
  );
};

const mapStateToProps = ({ carePlanPlaceInvitationsPage }) => {
  const { currentSearchInput, filterByStatusDropdown, signups, currentTab } = carePlanPlaceInvitationsPage;

  return {
    currentSearchInput,
    signups,
    status: filterByStatusDropdown.input,
    tab: currentTab,
  };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchCurrentSearchInput = (dispatch) => {
    return (currentSearchInput) => {
      dispatch(setCurrentSearchInput(currentSearchInput));
    };
  };

  const dispatchFilteredSignups = (dispatch) => {
    return (signups) => {
      dispatch(setFilteredSignups(signups));
    };
  };

  const dispatchSetCurrentPageNumber = (dispatch) => {
    return (pageNumber) => {
      dispatch(setCurrentPageNumber(pageNumber));
    };
  };

  return {
    setCurrentPageNumber: dispatchSetCurrentPageNumber(dispatch),
    setCurrentSearchInput: dispatchCurrentSearchInput(dispatch),
    setFilteredSignups: dispatchFilteredSignups(dispatch),
  };
};

SignupsSearchFilter.propTypes = {
  currentSearchInput: PropTypes.string,
  setCurrentPageNumber: PropTypes.func,
  setCurrentSearchInput: PropTypes.func,
  setFilteredSignups: PropTypes.func,
  filteredSignups: PropTypes.array,
  status: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupsSearchFilter);
