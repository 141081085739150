import { request } from 'Apiv2/handleRequest';

const validateUserApi = async (user, validationUrl) => {
  try {
    const response = await request(validationUrl, 'POST', { user });
    const status = response.status;
    const { error, data } = await response.json();

    return { status, error, data };
  } catch (error) {
    return { status: 500, error };
  }
};

export default validateUserApi;
