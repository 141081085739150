import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import {
  activityModalState as activityModalStateDefinitions,
  activityType as activityModalTypeDefinitions,
} from './modalContentStateDefinitions';

const i18nOpts = { scope: 'activity_modal.errors' };

export const Error = ({ activityModalState, activityType }) => {
  const { ERROR } = activityModalStateDefinitions;
  const { FORM } = activityModalTypeDefinitions;

  if (activityModalState !== ERROR) {
    return null;
  }

  const errorMessage = activityType === FORM ? t('form_api_error', i18nOpts) : t('api_error', i18nOpts);

  return <div className="action_modal__body__error">{errorMessage}</div>;
};

const mapStateToProps = ({ activityModal }) => {
  const { activityModalState, activityType } = activityModal;

  return { activityModalState, activityType };
};

export default connect(mapStateToProps, null)(Error);
