const handleRequest = (performRequest = fetch) => {
  return async (url, method = 'GET', body = null) => {
    const isFormDataRequest = body instanceof FormData;
    const tokenElement = document.querySelector('meta[name="csrf-token"]');

    const headers = {
      Accept: 'application/json',
    };

    if (tokenElement && tokenElement.content) {
      headers['X-CSRF-Token'] = tokenElement.content;
    }

    // https://itnext.io/uploading-files-to-your-rails-api-6b293a4a5c90
    if (!isFormDataRequest) {
      headers['Content-Type'] = 'application/json';
    }

    const options = {
      credentials: 'include',
      headers,
      method,
    };

    if (body) {
      options['body'] = isFormDataRequest ? body : JSON.stringify(body);
    }
    return performRequest(url, options);
  };
};

const requestObject = handleRequest(fetch);
export { requestObject as request };
