import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import Icon from './Icon';

export const ModalV2 = ({ isOpen, onClose, className, title, children, footer }) => {
  let cssClass = className ? 'ModalV2 ' + className : 'ModalV2';
  let bodyClass = footer ? 'ModalV2__body ModalV2__body--with_footer' : 'ModalV2__body';

  return (
    <ReactModal
      ariaHideApp={false}
      closeTimeoutMS={150}
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={false}
      shouldReturnFocusAfterClose={false}
      className={cssClass}
      contentLabel={title}
    >
      <div className="ModalV2__header">
        <h2 className="ModalV2__header__title">{title}</h2>

        <a className="ModalV2__header__close_button" onClick={onClose} tabIndex={0}>
          <Icon name="close_v3" />
        </a>
      </div>

      <div className={bodyClass}>{children}</div>

      {footer && <div className="ModalV2__footer">{footer}</div>}
    </ReactModal>
  );
};

ModalV2.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  footer: PropTypes.node,
};

ReactModal.defaultStyles = {}; // Clear default styles

export default ModalV2;
