import SearchIndex from './search_index'

class ContactSearchIndex extends SearchIndex {
  constructor(contacts) {
    super(contacts)
  }

  get fuseOptions() {
    return {
      threshold: 0.4,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 2,
      keys: ['name', 'title', 'participants.name'],
    }
  }
}

export default ContactSearchIndex
