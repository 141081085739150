import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import {
  setActivityModalResourcesDropdownSearchInput,
  setActivityModalResourcesDropdownResourcesToDisplay,
} from 'Actions/activityModal';

import sortByKey from '../../sortByKey';

import ResourceItem from './ResourceItem';
import FormField from '../../FormField';

const i18nOpts = { scope: 'activity_modal.form_fields.resources' };

const renderMainContent = (resourcesToDisplay) => {
  if (resourcesToDisplay?.length > 0) {
    const sortedResources = sortByKey(resourcesToDisplay, 'name');
    const resources = sortedResources.map((resource, id) => {
      return <ResourceItem key={`resource-${id}`} resource={resource} />;
    });
    return <ul className="resources_dropdown__resources_list">{resources}</ul>;
  } else {
    return <div className="resources_dropdown__no_resources">{t('no_resources', i18nOpts)}</div>;
  }
};

export const handleResourcesSearch = (
  setActivityModalResourcesDropdownSearchInput,
  setActivityModalResourcesDropdownResourcesToDisplay,
  resources,
  resourcesToDisplay,
  inputValue
) => {
  setActivityModalResourcesDropdownSearchInput(inputValue);

  if (resources?.length > 0) {
    const filteredResources = resources.filter(({ name, tags }) => {
      const nameMatch = name.toLowerCase().includes(inputValue.toLowerCase());

      // search tags for matches
      let tagMatch = false;
      tags.forEach(({ label }) => {
        if (label.toLowerCase().includes(inputValue.toLowerCase())) {
          tagMatch = true;
        }
      });

      return nameMatch || tagMatch;
    });
    setActivityModalResourcesDropdownResourcesToDisplay(filteredResources);
  }
};

const removeSelectedResources = (resources, selectedResources) => {
  if (!resources || resources.length <= 0 || !selectedResources || selectedResources.length <= 0) {
    return resources;
  }

  let filteredResources = resources;

  selectedResources.forEach((selectedResource) => {
    filteredResources = filteredResources.filter((resource) => {
      return resource.id !== selectedResource.id;
    });
  });

  return filteredResources;
};

export const ResourcesMenu = ({
  resourcesDropdown,
  activityProperties,
  setActivityModalResourcesDropdownSearchInput,
  setActivityModalResourcesDropdownResourcesToDisplay,
}) => {
  const { searchInput, resources, resourcesToDisplay } = resourcesDropdown;

  // remove resources that were already selected
  const filteredResources = removeSelectedResources(resourcesToDisplay, activityProperties.resources);

  return (
    <div className="resources_dropdown">
      <FormField
        className="resources_dropdown__search_input"
        value={searchInput}
        name="searchInput"
        onChange={(value) => {
          handleResourcesSearch(
            setActivityModalResourcesDropdownSearchInput,
            setActivityModalResourcesDropdownResourcesToDisplay,
            resources,
            resourcesToDisplay,
            value
          );
        }}
      />

      {renderMainContent(filteredResources)}
    </div>
  );
};

const mapStateToProps = ({ activityModal }) => {
  const { resourcesDropdown, activityProperties } = activityModal;

  return { resourcesDropdown, activityProperties };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalResourcesDropdownSearchInput = (dispatch) => {
    return (searchInput) => {
      dispatch(setActivityModalResourcesDropdownSearchInput(searchInput));
    };
  };

  const dispatchSetActivityModalResourcesDropdownResourcesToDisplay = (dispatch) => {
    return (resourcesToDisplay) => {
      dispatch(setActivityModalResourcesDropdownResourcesToDisplay(resourcesToDisplay));
    };
  };

  return {
    setActivityModalResourcesDropdownSearchInput:
      dispatchSetActivityModalResourcesDropdownSearchInput(dispatch),
    setActivityModalResourcesDropdownResourcesToDisplay:
      dispatchSetActivityModalResourcesDropdownResourcesToDisplay(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResourcesMenu);
