import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import { setActivityModalActivityProperties, setActivityModalShowAddressFields } from 'Actions/activityModal';

import AddressForm from './AddressForm';

const i18nOpts = { scope: 'activity_modal.form_fields.where.address' };

export const Address = ({
  activityProperties,
  showAddressFields,
  setActivityModalActivityProperties,
  setActivityModalShowAddressFields
}) => (
  <>
    {!showAddressFields && (
      <button
        className="btn btn--secondary"
        onClick={() => {
          setActivityModalShowAddressFields(true);
          setActivityModalActivityProperties({
            ...activityProperties,
            addressFields: {
              address: '',
              city: '',
              region: '',
              extendedAddress: '',
              postalCode: '',
              countryCode: '',
            },
          });
        }}
      >
        {t('add_address', i18nOpts)}
      </button>
    )}

    {showAddressFields && (
      <>
        <div className="activity_modal__address_controller__input_field__container">
          <div className="activity_modal__address_controller__description__input_field__container">
            {t('description', i18nOpts)}
          </div>
          <button
            className="btn btn--secondary"
            onClick={() => {
              setActivityModalShowAddressFields(false);
              setActivityModalActivityProperties({
                ...activityProperties,
                addressFields: undefined,
              });
            }}
          >
            {t('remove_address', i18nOpts)}
          </button>
        </div>

        <AddressForm />
      </>
    )}
  </>
);

const mapStateToProps = ({ activityModal }) => {
  const { activityProperties, showAddressFields } = activityModal;

  return { activityProperties, showAddressFields };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalActivityProperties = (dispatch) => {
    return (activityProperties) => {
      dispatch(setActivityModalActivityProperties(activityProperties));
    };
  };

  const dispatchSetActivityModalShowAddressFields = (dispatch) => {
    return (showAddressFields) => {
      dispatch(setActivityModalShowAddressFields(showAddressFields));
    };
  };

  return {
    setActivityModalActivityProperties: dispatchSetActivityModalActivityProperties(dispatch),
    setActivityModalShowAddressFields: dispatchSetActivityModalShowAddressFields(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Address);
