import React from 'react';
import Icon from 'Components/Base/Icon';

const NavigationArrow = ({
  type, // 'prev' | 'next'
  onClick,
  isDisabled,
}) => {
  if (isDisabled) return null;

  let className = 'comparison-chart__week-selector__arrow';
  if (type === 'prev') {
    className += ' comparison-chart__week-selector__arrow--left';
  } else {
    className += ' comparison-chart__week-selector__arrow--right';
  }

  const iconName = type === 'prev' ? 'chevron-left_alt2' : 'chevron-right_alt2';

  return (
    <div onClick={() => onClick(type)} className={className}>
      <Icon name={iconName} />
    </div>
  );
};

export default NavigationArrow;
