import { Resource } from '../Api'

class Resources extends Resource {
  constructor(carePlanId) {
    super()

    this.carePlanId = carePlanId
  }

  update(services) {
    return super.update({ carePlan: { services } })
  }

  get url() {
    return `/care_plans/${this.carePlanId}/resources`
  }
}

export default Resources
