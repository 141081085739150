import fetchDeviceData from './fetchDeviceData';
import fetchUserDetails from './fetchUserDetails';
import moment from 'ct-moment';

export const fetchAndUpdateDeviceData = (
  getDeviceData = fetchDeviceData,
  getUserDetails = fetchUserDetails,
  timeProvider = moment
) => {
  return (carePlanUuid, setData, setUserDetails, setPageState) => {
    return async () => {
      try {
        const currentUserTime = timeProvider().format(); // iso8601 format
        const userDetailsResponse = await getUserDetails(carePlanUuid);
        const dataResponse = await getDeviceData(carePlanUuid, currentUserTime);

        if (dataResponse.status !== 'success') {
          setPageState('error');
          return;
        }

        setData(dataResponse.data);
        setUserDetails({
          isPlaceUser: userDetailsResponse.isPlaceUser,
          patientFirstName: userDetailsResponse.patientFirstName,
          patientFullName: userDetailsResponse.patientFullName,
          patientBirthday: userDetailsResponse.patientBirthday,
          carehubUrl: userDetailsResponse.carehubUrl,
        });

        if (dataResponse.status === 'success' && userDetailsResponse.status === 'success') {
          setPageState('success');
          return;
        }

        setPageState('error');
      } catch (error) {
        setPageState('error');
      }
    };
  };
};

export default fetchAndUpdateDeviceData(fetchDeviceData, fetchUserDetails, moment);
