import React from 'react';

import ProviderWrapper from 'Components/Base/ProviderWrapper';
import SignupContainer from './SignupContainer';

const RegistrationsV2 = (props) => {
  return (
    <ProviderWrapper>
      <SignupContainer {...props} />
    </ProviderWrapper>
  );
};

export default RegistrationsV2;
