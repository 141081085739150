import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'
import _ from 'lodash'

import { Card, EditActionHeader, EditActionHeaderWithCareHub, CancelActionHeader, CancelActionHeaderWithCareHub } from 'Components/Base/Card/'

import Show from './Show'
import Edit from './Edit'
import NewAddressForm from './New/AddressForm'
import EditAddressForm from './Edit/AddressForm'
import NewPhoneNumber from './New/PhoneNumber'
import EditPhoneNumber from './Edit/PhoneNumber'

import PatientApi from 'Api/Patient/User'

const i18nOpts = { scope: 'patient.patient' }

class Patient extends Component {
  constructor(props) {
    super(props)

    this.toggleEditing = this.toggleEditing.bind(this)
    this.newAddress = this.newAddress.bind(this)
    this.editAddress = this.editAddress.bind(this)
    this.newPhoneNumber = this.newPhoneNumber.bind(this)
    this.editPhoneNumber = this.editPhoneNumber.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSuccess = this.handleSuccess.bind(this)
    this.handleFormErrors = this.handleFormErrors.bind(this)
    this.handleSubmitError = this.handleSubmitError.bind(this)
    this.handleNewAddress = this.handleNewAddress.bind(this)
    this.handleAddress = this.handleAddress.bind(this)
    this.handleNewPhoneNumber = this.handleNewPhoneNumber.bind(this)
    this.handlePhoneNumber = this.handlePhoneNumber.bind(this)
    this.handleEmail = this.handleEmail.bind(this)
    this.renderContent = this.renderContent.bind(this)

    this.state = {
      patient: props.patient,
      updateCareHub: true,
      saved: _.cloneDeep(props.patient),
      display: 'show',
      invitationManagementIsOpen: false,
      audits: props.audits,
      formErrors: {},
      submitError: false,
      isProcessing: false
    }
  }

  get patientMember() {
    const { teamMembershipId: id, invitation = { email: null }, email = '' } = this.props.patient
    const patientEmail = invitation ? invitation.email : email

    return { id, email: patientEmail, role: 'patient' }
  }

  toggleEditing() {
    if (this.state.display === 'show') {
      this.setState({ display: 'edit' })
    } else {
      const patient = _.cloneDeep(this.state.saved)
      this.setState({ display: 'show', patient })
    }
  }

  newAddress() {
    this.setState({ display: 'newAddress' })
  }

  editAddress(id) {
    const editingAddress = this.state.saved.addresses.find((a) => a.id === id)
    this.setState({ display: 'editAddress', editingAddress })
  }

  newPhoneNumber() {
    this.setState({ display: 'newPhoneNumber' })
  }

  editPhoneNumber(id) {
    const editingPhoneNumber = this.state.saved.phoneNumbers.find((p) => p.id === id)
    this.setState({ display: 'editPhoneNumber', editingPhoneNumber })
  }

  handleChange({ target: { name, value } }) {
    if (name == 'patient.preferredAddress.countryCode' && value == 'GB') {
      this.setState((prevState) => ({
        ...prevState,
        patient: {
          ...prevState.patient,
          preferredAddress: {
            ...prevState.preferredAddress,
            region: '',
            countryCode: value
          }
        }
      }))
    } else if (name === 'patient.email') {
      this.setState((prevState) => {
        const newState = _.set(prevState, name, value);
        const clearedEmailError = { formErrors: { ...this.state.formErrors, "patient.email": ''} };

        return { ...newState, ...clearedEmailError };
      })
    } else {
      this.setState((prevState) => _.set(prevState, name, value))
    }
  }

  handleSubmit(evt) {
    evt.preventDefault()
    this.setState({ isProcessing: true })
    const { carePlanUuid } = this.props

    new PatientApi(carePlanUuid).update(this.state).then((res) => {
      if (res.ok) {
        res.json().then(this.handleSuccess);
      } else if (res.status === 400) {
        res.json().then(this.handleFormErrors);
        document.getElementById('patient_details')?.scrollIntoView({ behavior: "smooth" });
      } else if (res.status === 422) {
        this.handleSubmitError();
      }
    })
  }

  handleSuccess({ audits, patient }) {
    this.setState({
      audits,
      patient,
      saved: _.cloneDeep(patient),
      display: 'show',
      formErrors: {},
      submitError: false,
      isProcessing: false
    })

    window.location.reload(); // HACK to trigger the Rails flash message
  }

  handleFormErrors(formErrors) {
    const modifiedFormErrors = { ...formErrors };
    modifiedFormErrors["patient.email"] = formErrors["invitation.email"]?.at(0);

    this.setState({ formErrors: modifiedFormErrors, isProcessing: false })
  }

  handleSubmitError() {
    this.setState({ submitError: true, isProcessing: false })
  }

  handleNewAddress({ address }) {
    const addresses = this.state.saved.addresses
    this.setState((prevState) => {
      const newState = prevState
      _.set(newState, 'saved.addresses', [...addresses, address])
      _.set(newState, 'display', 'show')
      return newState
    })
  }

  handleAddress({ address }) {
    const addresses = this.state.saved.addresses.map((a) => (a.id === address.id ? address : a))
    this.setState((prevState) => {
      const newState = prevState
      _.set(newState, 'saved.addresses', [...addresses])
      _.set(newState, 'display', 'show')
      return newState
    })
  }

  handleNewPhoneNumber({ phoneNumber }) {
    const phoneNumbers = this.state.saved.phoneNumbers
    this.setState((prevState) => {
      const newState = prevState
      _.set(newState, 'saved.phoneNumbers', [...phoneNumbers, phoneNumber])
      _.set(newState, 'display', 'show')
      return newState
    })
  }

  handlePhoneNumber({ phoneNumber }) {
    const phoneNumbers = this.state.saved.phoneNumbers.map((p) => (p.id === phoneNumber.id ? phoneNumber : p))
    this.setState((prevState) => {
      const newState = prevState
      _.set(newState, 'saved.phoneNumbers', [...phoneNumbers])
      _.set(newState, 'display', 'show')
      return newState
    })
  }

  handleEmail(email = '') {
    const patient = this.state.patient
    patient.email = email
    this.setState({ patient, saved: _.cloneDeep(patient) })
  }

  renderContent() {
    const { carePlanUuid, canInvite, canManageInvitation, locale, isCareHubPatient } = this.props
    const { patient, saved, audits, formErrors, submitError, editingPhoneNumber, editingAddress, updateCareHub, isProcessing } = this.state

    switch (this.state.display) {
      case 'show':
        return (
          <Show
            patient={saved}
            patientMember={this.patientMember}
            canManageInvitation={canManageInvitation && !!this.state.saved.email}
            carePlanUuid={carePlanUuid}
            editAddress={this.editAddress}
            editPhoneNumber={this.editPhoneNumber}
            canInvite={canInvite && !this.state.saved.email}
            addEmail={this.handleEmail}
            isCareHubPatient={isCareHubPatient}
          />
        )
      case 'edit':
        return (
          <Edit
            patient={patient}
            canInvite={canInvite}
            canManageInvitation={canManageInvitation}
            updateCareHub={updateCareHub}
            isCareHubPatient={isCareHubPatient}
            audits={audits}
            locale={locale}
            formErrors={formErrors}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            submitError={submitError}
            isProcessing={isProcessing}
          />
        )
      case 'newAddress':
        return <NewAddressForm carePlanUuid={carePlanUuid} locale={locale} onSuccess={this.handleNewAddress} />
      case 'editAddress':
        return (
          <EditAddressForm address={editingAddress} locale={locale} carePlanUuid={carePlanUuid} onSuccess={this.handleAddress} />
        )
      case 'newPhoneNumber':
        return <NewPhoneNumber carePlanUuid={carePlanUuid} onSuccess={this.handleNewPhoneNumber} />
      case 'editPhoneNumber':
        return (
          <EditPhoneNumber
            phoneNumber={editingPhoneNumber}
            carePlanUuid={carePlanUuid}
            onSuccess={this.handlePhoneNumber}
          />
        )
    }
  }

  determineRenderHeaderAction(display) {
    const { isCareHubPatient, patient } = this.props
    const showEditWithCareHub = isCareHubPatient && !patient.email;
    if (display === 'show') {
      // return EditActionHeader;
      return showEditWithCareHub ? EditActionHeaderWithCareHub : EditActionHeader;
    } else {
      return isCareHubPatient ? CancelActionHeaderWithCareHub : CancelActionHeader;
    }
  }

  render() {
    const { display } = this.state
    let renderHeaderAction = null

    if (this.props.canUpdate) {
      const RenderHeaderComponent = this.determineRenderHeaderAction(display)
      const onActionClick = this.toggleEditing
      renderHeaderAction = (actionWrapper) => (
        <RenderHeaderComponent onClick={() => actionWrapper(onActionClick)} />
      )
    }

    return (
      <Card
        id="patient_details"
        title={t('header', { ...i18nOpts, name: this.props.patient.firstName })}
        renderHeaderAction={renderHeaderAction}
        collapsable
      >
        {this.renderContent()}
      </Card>
    )
  }
}

Patient.propTypes = {
  audits: PropTypes.shape({}),
  canInvite: PropTypes.bool,
  canManageInvitation: PropTypes.bool,
  canUpdate: PropTypes.bool.isRequired,
  carePlanUuid: PropTypes.string.isRequired,
  patient: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    teamMembershipId: PropTypes.number,
    invitation: PropTypes.shape({
      email: PropTypes.string,
    }),
  }),
}

Patient.defaultProps = {
  audits: {},
  canInvite: false,
  canManageInvitation: false,
  patient: {},
}

export default Patient
