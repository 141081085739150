import { connect } from 'react-redux'

import { getCarePlanFilter, getAsideState } from 'Selectors/filter'
import { toggleCarePlanFilter, openAside, closeAside } from 'Actions/filter'

import SideBar from './SideBar'

const mapStateToProps = (state, ownProps) => ({
  excludedCarePlans: getCarePlanFilter(state),
  isOpen: getAsideState(state),
  ...ownProps,
})

const mapDispatchToProps = (dispatch) => ({
  onChange: (uuid) => dispatch(toggleCarePlanFilter(uuid)),
  onOpenAside: dispatch.bind(null, openAside()),
  onCloseAside: dispatch.bind(null, closeAside()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SideBar)
