import React, { Component } from 'react'
import { t } from 'i18n'
import _ from 'lodash'
import PropTypes from 'prop-types'

import { Footer } from 'Components/Base/Card/'
import InputGroup from 'Components/Forms/InputGroup'
import Submit from 'Components/Forms/Submit'

import CarePlanTemplateApi from 'Api/TemplateCreator/CarePlanTemplate'

const i18nOpts = { scope: 'care_plan_creators.name' }

class NameSection extends Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleErrors = this.handleErrors.bind(this)
    this.navigateToNextSection = this.navigateToNextSection.bind(this)

    this.state = {
      carePlan: {
        name: this.props.name || '',
      },
      isSubmitting: false,
      errors: {},
    }
  }

  handleChange({ target: { name, value } }) {
    this.setState((prevState) => _.set(prevState, name, value))
  }

  handleErrors(errors) {
    this.setState({ errors })
  }

  handleSubmit(evt) {
    evt.preventDefault()
    this.setState({ isSubmitting: true })

    return new CarePlanTemplateApi(this.props.resourceId).updateName(this.state).then((res) => {
      if (res.ok) {
        return res.json().then(() => {
          this.setState((prevState) => ({
            isSubmitting: false,
            errors: {},
            carePlan: { ...prevState.carePlan },
          }))

          this.navigateToNextSection()
        })
      } else if (res.status === 422) {
        this.setState({ isSubmitting: false })
        return res.json().then(this.handleErrors)
      }
    })
  }

  navigateToNextSection() {
    window.location.hash = '#careplan'
  }

  render() {
    const { carePlan, isSubmitting, errors } = this.state

    return (
      <form onSubmit={this.handleSubmit} noValidate>
        <div className="input string required care_plan_title">
          <label className="string required careplan-wizard__details-title" htmlFor="carePlan.name">
            <abbr title="required">*</abbr> {t('title_label', i18nOpts)}
            <p className="text-primary-lighter careplan-wizard__details-subtitle">
              {t('title_sublabel', i18nOpts)}
            </p>
          </label>
        </div>

        <InputGroup
          component="input"
          placeholder={t('title_placeholder', i18nOpts)}
          name="carePlan.name"
          value={carePlan.name}
          errors={errors}
          onChange={this.handleChange}
          required
          type="text"
        />

        <Footer
          right={<Submit value={t('next', { scope: 'care_plan_creators.name' })} disabled={isSubmitting} />}
        />
      </form>
    )
  }
}

NameSection.propTypes = {
  name: PropTypes.string,
  resourceId: PropTypes.number.isRequired,
}

NameSection.defaultProps = {
  name: '',
}

export default NameSection
