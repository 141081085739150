import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import { setActivityModalActivityProperties, setActivityModalFormErrors } from 'Actions/activityModal';
import { setupDescriptionValidator } from './formFieldsValidators';

import FormField from './FormField';

const i18nOpts = { scope: 'activity_modal.form_fields.what' };

export const Description = ({
  activityProperties,
  activityType,
  formErrors,
  setActivityModalActivityProperties,
  setActivityModalFormErrors,
  canEditDescription,
}) => {
  const handleDescriptionValidation = setupDescriptionValidator(
    formErrors,
    setActivityModalFormErrors,
    t,
    i18nOpts
  );

  let labelDescription;
  switch (activityType) {
    case 'FORM':
      labelDescription = t('form_description', i18nOpts);
      break;
    case 'TASK':
      labelDescription = t('task_description', i18nOpts);
      break;
    case 'APPOINTMENT':
      labelDescription = t('appointment_description', i18nOpts);
      break;
    default:
      labelDescription = t('task_description', i18nOpts);
      break;
  }

  return (
    <div className="activity_modal__input_field--description">
      <div className="activity_modal__input_field__label activity_modal__input_field__label--description">
        {labelDescription}
      </div>
      <div className="activity_modal__input_field__container">
        <FormField
          formErrors={formErrors}
          value={activityProperties.description}
          name="description"
          onChange={(value) => {
            setActivityModalActivityProperties({
              ...activityProperties,
              description: value,
            });
          }}
          readOnly={!canEditDescription}
          handleValidation={handleDescriptionValidation}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ activityModal }) => {
  const { activityProperties, activityType, formErrors, canEditFields: { canEditDescription } } = activityModal;

  return { activityProperties, activityType, formErrors, canEditDescription };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalActivityProperties = (dispatch) => {
    return (activityProperties) => {
      dispatch(setActivityModalActivityProperties(activityProperties));
    };
  };

  const dispatchSetActivityModalFormErrors = (dispatch) => {
    return (formErrors) => {
      dispatch(setActivityModalFormErrors(formErrors));
    };
  };

  return {
    setActivityModalActivityProperties: dispatchSetActivityModalActivityProperties(dispatch),
    setActivityModalFormErrors: dispatchSetActivityModalFormErrors(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Description);
