import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import 'url-polyfill';

import Locale from 'Components/Base/Locale';
import Backdrop from 'Components/Base/Backdrop';
import Icon from 'Components/Base/Icon';

class LocaleSelector extends PureComponent {
  constructor(props) {
    super(props);

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.renderOption = this.renderOption.bind(this);

    this.state = {
      isOpen: false,
    };
  }

  open() {
    this.setState({ isOpen: true });
  }

  close() {
    this.setState({ isOpen: false });
  }

  handleClick(locale, evt) {
    evt.preventDefault();

    const url = new URL(window.location.href);
    url.searchParams.set('locale', locale);
    window.location.assign(url);
  }

  renderOption(locale) {
    return (
      <div key={locale} className="dropdown__item" onClick={this.handleClick.bind(null, locale)}>
        <Locale locale={locale} />
      </div>
    );
  }

  render() {
    return (
      <div className="dropdown dropdown--locale">
        <div onClick={this.open}>
          <Locale locale={this.props.locale} tabIndex="0" />
          <Icon name="carrot-down" />
        </div>

        {this.state.isOpen && (
          <Fragment>
            <div className="dropdown__menu header-nav__dropdown">
              {this.props.availableLocales.map(this.renderOption)}
            </div>

            <Backdrop transparent onClick={this.close} />
          </Fragment>
        )}
      </div>
    );
  }
}

LocaleSelector.propTypes = {
  availableLocales: PropTypes.array,
  locale: PropTypes.string.isRequired,
};

LocaleSelector.defaultProps = {
  availableLocales: [],
};

export default LocaleSelector;
