import React from 'react';
import PropTypes from 'prop-types';
import AttributeWithIcon from 'Components/Base/AttributeWithIcon';
import { Chip, IconChip } from 'Components/Base';
import { t } from 'i18n';
import SubstituteDecisionMakerChip from './SubstituteDecisionMakerChip';
import Avatar from 'PulseBackports/Avatar';

const SupportTeamMemberRow = ({
  id,
  path,
  relationship,
  status,
  owner,
  showPending,
  substituteDecisionMaker
}) => {
  const i18nOpts = { scope: 'team.support_team_member' };

  const renderStcPhoneNumber = (status) => {
    let snippet;

    if (status === 'pending' && owner.phone) {
      snippet = (
        <a className="text-truncate" href={`tel:${owner.phone}`} onClick={callPhone}>
          {owner.phone}
        </a>
      );
    } else if (status === 'accepted' && owner.preferredPhoneNumber) {
      snippet = (
        <a className="text-truncate" href={`tel:${owner.preferredPhoneNumber.number}`} onClick={callPhone}>
          {owner.preferredPhoneNumber.number}
        </a>
      );
    } else {
      snippet = <span className="text-grey-alt">{t('unknown', i18nOpts)}</span>;
    }
    return snippet;
  };

  const sendEmail = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.location.href = `mailto:${owner.email}`;
  };

  const handlePath = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.location.href = path;
  };

  const callPhone = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (owner.phone) {
      window.location.href = `tel:${owner.phone}`;
    } else if (owner.preferredPhoneNumber) {
      window.location.href = `tel:${owner.preferredPhoneNumber.number}`;
    }
  };

  const renderPendingPill = (showPending, owner) => {
    let snippet = '';
    if (showPending) {
      if (owner.invalidEmail) {
        snippet = (
          <div className="invitation__chip">
            <IconChip color="warning-yellow" text={t('email_invalid', i18nOpts)} iconName="alert" />
          </div>
        );
      } else {
        snippet = (
          <div className="invitation__chip">
            <Chip color="blue-foam" text={t('pending', i18nOpts)} />
          </div>
        );
      }
    }
    return snippet;
  };

  return (
    <li key={id} className="support-team-member">
      <a href={path} className="support-team-member__avatar" onClick={handlePath}>
        <Avatar
          src={owner.photo}
          fullName={owner.name}
          pending={showPending}
          size="2.5"
        />
      </a>

      <div className="support-team-member__detailsV2">
        <div className="support-team-member__section support-team-member__section--name">
          <h3 className="support-team-member__name">
            <a href={path} onClick={handlePath}>
              {owner.name}
            </a>
          </h3>

          {substituteDecisionMaker && <SubstituteDecisionMakerChip />}

          <span className="support-team-member__relationship">
            {t(relationship, { scope: 'relationship' })}
          </span>
        </div>

        <div className="support-team-member__contact-information text-truncate hidden--sm">
          <div className="support-team-member__section support-team-member__section--phone text-truncate">
            <AttributeWithIcon iconName="phone_alt">{renderStcPhoneNumber(status)}</AttributeWithIcon>
          </div>

          <div className="support-team-member__section support-team-member__section--email text-truncate">
            <AttributeWithIcon iconName="mail_alt2">
              <a className="text-truncate" href={`mailto:${owner.email}`} onClick={sendEmail}>
                {owner.email}
              </a>
            </AttributeWithIcon>
          </div>
        </div>
        <div className="support-team-member__section support-team-member__section--resend">
          {renderPendingPill(showPending, owner)}
        </div>
      </div>
    </li>
  );
};

SupportTeamMemberRow.propTypes = {
  id: PropTypes.number.isRequired,
  path: PropTypes.string.isRequired,
  relationship: PropTypes.string,
  status: PropTypes.string.isRequired,
  owner: PropTypes.object,
  showPending: PropTypes.bool,
};

SupportTeamMemberRow.defaultProps = {
  showPending: false,
};

export default SupportTeamMemberRow;
