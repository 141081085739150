import React from 'react';
import PropTypes from 'prop-types';
import Row from './Row';

const StatsTable = ({ data: { title, columns, rows } }) => (
  <div className="stats-table">
    <div className="stats-table__label">{title}</div>
    <table>
      <tbody>
        <Row columnCount={columns.length} row={columns} />
        {rows.map((row, idx) => (
          <Row columnCount={columns.length} row={row} key={idx} />
        ))}
      </tbody>
    </table>
  </div>
);

StatsTable.propTypes = {
  data: PropTypes.shape({
    columns: PropTypes.array,
    rows: PropTypes.array,
    title: PropTypes.string,
  }),
};

StatsTable.defaultProps = {
  data: {
    columns: [],
    rows: [],
    title: '',
  },
};

export default StatsTable;
