import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import TaskList from 'Components/Tasks/Index/TaskList'
import EventList from 'Components/Tasks/Index/EventList'

const WeeklyList = ({ patientUuid }) => (
  <Fragment>
    <TaskList overview patientUuid={patientUuid} />
    <EventList overview patientUuid={patientUuid} />
  </Fragment>
)

WeeklyList.propTypes = {
  patientUuid: PropTypes.string,
}

WeeklyList.defaultProps = {
  patientUuid: '',
}

export default WeeklyList
