import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';

import Form from './Form';
import OrderList from './OrderList';
import OrderDropbox from './OrderDropbox';
import { Modal } from 'Components/Base/Modal';
import IFrame from 'Components/Base/IFrame';
import Icon from 'Components/Base/Icon';

import LinkResources from 'Components/Resources/LinkResources';
import ReferralConfirmation from './ReferralConfirmation'

import Referral from 'Api/Referral';

import SectionFooter from '../SectionFooter';
import orderCreator from '../../CarePlans/orderCreator';

const i18nOpts = { scope: 'care_plan_creators.tasks.index' };
const referralOpts = { scope: 'care_plans.referral_section.index' };

class TaskSection extends Component {
  constructor(props) {
    super(props);

    this.toggleDropbox = this.toggleDropbox.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.renderActivityForm = this.renderActivityForm.bind(this);
    this.handleReferralRequest = this.handleReferralRequest.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleError = this.handleError.bind(this);

    this.state = {
      showDropbox: false,
      isSubmitting: false,
      currentServiceId: '',
      referrals: props.referrals || [],
      link: '',
    };
  }

  get referralApi() {
    const { parentId } = this.props;
    return new Referral(parentId, this.state.currentServiceId);
  }

  get referralsEnabled() {
    return this.props.referralsEnabled && !this.props.isTemplate;
  }

  componentDidMount() {
    window.addEventListener('message', this.handleSubmit, false);
  }

  closeModal() {
    this.setState({ link: '', currentServiceId: '' }, this.props.handleActionClick);
  }

  openModal(kind) {
    this.props.resetFormState();
    this.props.handleChange({ target: { name: 'kind', value: kind } });
    this.toggleDropbox();
    this.closeModal();
  }

  toggleDropbox() {
    this.setState((prevState) => ({ showDropbox: !prevState.showDropbox }));
  }

  navigateToNextSection() {
    window.location.hash = '#check_ins';
  }

  handleSubmit({ data }) {
    if (data.message === 'close') {
      if (!data.referral_data) {
        this.closeModal();
      } else {
        return this.referralApi.show().then((res) => {
          if (res.ok) {
            return res.json().then((response) => {
              this.setState(
                (prevState) => ({
                  referrals: [...prevState.referrals, response.referral],
                }),
                this.closeModal()
              );
            });
          } else {
            this.handleError();
          }
        });
      }
    }
  }

  handleReferralRequest(isSSO, menuTop, menuSub, address) {
    return this.referralApi.create({ isSSO }).then((res) => {
      if (res.ok) {
        res.json().then(({ id, launch_string }) => {
          // `launch_string` is the raw one-time generated url provided by caredove
          // here we concat searchParams to the url for it to
          // preselect options when the url opens
          // menuTop: correponds to `services.referral_category`
          // menuSub: corresponds to `services.referral_subcategory`
          const link = new URL(launch_string);

          link.searchParams.set('searchurl', 'true');
          if (menuTop) link.searchParams.set('menutop', menuTop);
          if (menuSub) link.searchParams.set('menusub', menuSub);
          if (address) link.searchParams.set('address', address);
          link.searchParams.set('lan', this.props.locale || 'en');

          this.setState(
            { currentServiceId: id, link: link.href }
          )
        });
      } else {
        this.handleError();
      }
    });
  }

  handleError() {
    window.flash_messages.addMessage(t('error_message', referralOpts));
    if (this.props.showForm) {
      this.closeModal();
    }
  }

  renderActivityForm() {
    const { showForm, showLinkResource, kind, allTags } = this.props;

    if (kind === 'Referral') {
      return this.renderReferralModal();
    }

    return (
      <Modal
        className={"ReactModal__Content"}
        title={t('add_activity', i18nOpts)}
        isOpen={showForm && !showLinkResource}
        closeModal={this.closeModal}
      >
        <Form
          allTags={allTags}
          isTemplate
          kind={kind}
          handleActionClick={this.closeModal}
          {...this.props}
        />
      </Modal>
    );
  }

  renderReferralModal() {
    const { carePlanId, referralAddress, locale, hasCaredoveSso, showForm } = this.props;
    const { link } = this.state;

    return (
      <Modal
        className={`ReactModal__Content ${link ? 'ReactModal__Content--referrals' : ''}`}
        isOpen={showForm}
        closeModal={this.closeModal}
        title={t('referral_header', i18nOpts)}
      >
        {link ?
          <IFrame link={link} className="ReactModal__Content--referrals--iframe" />
          :
          <ReferralConfirmation
            address={referralAddress}
            locale={locale}
            isSSO={hasCaredoveSso}
            onClick={this.handleReferralRequest}
            closeModal={this.closeModal}
            onBehalf={true}
            isPlaceUser={true}
            carePlanId={carePlanId}
          />
        }
      </Modal>
    )
  }

  render() {
    const {
      allTags,
      orders,
      repeatedTasks,
      repeatedAppointments,
      deleteOrder,
      deleteRepeatedTask,
      deleteRepeatedAppointment,
      editOrder,
      onToggleLinkResources,
      onSelectResources,
      showLinkResource,
      showForm,
      resources,
      adHocResources,
      selectedResources,
    } = this.props;

    const { referrals, showDropbox } = this.state;

    return (
      <Fragment>
        <OrderList
          deleteOrder={deleteOrder}
          deleteRepeatedTask={deleteRepeatedTask}
          deleteRepeatedAppointment={deleteRepeatedAppointment}
          editOrder={editOrder}
          repeatedTasks={repeatedTasks}
          repeatedAppointments={repeatedAppointments}
          orders={[...orders, ...referrals]}
        />

        <button className="btn btn--link careplan__list-add-button" onClick={this.toggleDropbox}>
          <Icon name="plus_square" className="careplan-wizard__icon_add" />
          {t('add_activity', i18nOpts)}
          <Icon name="carrot-down" className="icon--medium" />
        </button>

        {showDropbox && (
          <OrderDropbox
            referralsEnabled={this.referralsEnabled}
            openModal={this.openModal}
            toggle={this.toggleDropbox}
          />
        )}

        {showForm && this.renderActivityForm()}

        <Modal
          isOpen={showLinkResource}
          closeModal={onToggleLinkResources}
          title={t('link_resources', i18nOpts)}
        >
          <LinkResources
            allTags={allTags}
            adHocResources={adHocResources}
            resources={resources}
            selectedResources={selectedResources}
            onBack={onToggleLinkResources}
            isLinking
            onSelectResources={onSelectResources}
          />
        </Modal>

        <SectionFooter isSubmitting={this.state.isSubmitting} onNext={this.navigateToNextSection} />
      </Fragment>
    );
  }
}

const resourceGroupShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  kind: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

TaskSection.propTypes = {
  address: PropTypes.object,
  referralAddress: PropTypes.string,
  adHocResources: PropTypes.array,
  allTags: PropTypes.array,
  carePlanId: PropTypes.string.isRequired,
  deleteOrder: PropTypes.func.isRequired,
  deleteRepeatedAppointment: PropTypes.func.isRequired,
  deleteRepeatedTask: PropTypes.func.isRequired,
  editOrder: PropTypes.func.isRequired,
  handleActionClick: PropTypes.func.isRequired,
  handleChange: PropTypes.func,
  hasCaredoveSso: PropTypes.bool,
  isTemplate: PropTypes.bool,
  kind: PropTypes.string.isRequired,
  onRemoveResource: PropTypes.func.isRequired,
  onSelectResources: PropTypes.func.isRequired,
  onToggleLinkResources: PropTypes.func.isRequired,
  orders: PropTypes.array,
  parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  patientFirstName: PropTypes.string,
  referrals: PropTypes.array,
  referralsEnabled: PropTypes.bool,
  repeatedAppointments: PropTypes.array,
  repeatedTasks: PropTypes.array,
  resetFormState: PropTypes.func,
  resources: PropTypes.object,
  selectedResources: PropTypes.arrayOf(resourceGroupShape),
  showForm: PropTypes.bool,
  showLinkResource: PropTypes.bool,
  timeZone: PropTypes.string,
  type: PropTypes.string,
};

TaskSection.defaultProps = {
  address: null,
  referralAddress: '',
  adHocResources: [],
  allTags: [],
  handleChange: null,
  hasCaredoveSso: false,
  isTemplate: false,
  locale: 'en',
  orders: [],
  patientFirstName: '',
  referrals: [],
  referralsEnabled: false,
  repeatedAppointments: [],
  repeatedTasks: [],
  resetFormState: null,
  resources: {},
  selectedResources: [],
  showForm: false,
  showLinkResource: false,
  timeZone: '',
  type: '',
};

export default orderCreator(TaskSection);
