/*
  Receipt requires the emoji_support.js pack to be loaded on the page before
  the emojiConverter can be used. Ensure you include the following on any page
  this component is used:
    <%= javascript_pack_tag 'emoji_support', defer: true %>
*/

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { LinkDecorator } from 'Components/Base'

import Avatar from 'PulseBackports/Avatar'

class Receipt extends Component {
  constructor(props) {
    super(props)

    this.state = {
      body: props.message.body,
    }
  }

  componentDidMount() {
    this.setState({ body: window.emojiConverter.replace_colons(this.props.message.body) })
  }

  render() {
    const {
      message: {
        createdAt,
        isMe,
        sender: { photo, name },
      },
      isEarliestUnreadReceipt,
    } = this.props
    return (
      <div className={`message ${isMe ? 'message--outgoing' : ""}`}>
        <Avatar
          src={photo}
          fullName={name}
          size={2.625}
          border={true}
          className="message__avatar"
        />

        <div className={`message__body ${isEarliestUnreadReceipt ? 'message__animate' : "" }`}>
          <header className="message__header">
            <span>{name}</span>

            <small className="message__timestamp">{createdAt}</small>
          </header>

          <p className="message__text">
            <LinkDecorator>{this.state.body}</LinkDecorator>
          </p>
        </div>
      </div>
    )
  }
}

Receipt.propTypes = {
  message: PropTypes.shape({
    body: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    isMe: PropTypes.bool.isRequired,
    sender: PropTypes.shape({
      photo: PropTypes.string,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  isEarliestUnreadReceipt: PropTypes.bool,
}

export default Receipt
