import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import Document from 'Components/Documents/Show/Document'

const i18nOpts = { scope: 'care_plans.document_section.show' }

const ShowDocuments = ({ documents }) => {
  return documents.length ? (
    documents.map((document) => <Document document={document} key={document.id} />)
  ) : (
    <div className="item-pill-section--item-list-empty">{t('no_documents', i18nOpts)}</div>
  )
}

ShowDocuments.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.object,
      canModify: PropTypes.bool,
      createdAt: PropTypes.string,
      documentFileName: PropTypes.string,
      downloadUrl: PropTypes.string,
      id: PropTypes.number,
      libraryDocumentId: PropTypes.number,
      name: PropTypes.string,
      url: PropTypes.string,
    })
  ),
}

ShowDocuments.defaultProps = {
  documents: [],
}

export default ShowDocuments
