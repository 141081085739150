import React from 'react';
import { t } from 'i18n';

const i18nOpts = { scope: 'patient.edit.address' };

const AddressTypeSelector = ({ label, name, onChange }) => (
  <div className="patient__address_form__input_field__type_selector">
    <label>
      {t('label', i18nOpts)}
    </label>
    <select name={name} value={label} onChange={onChange}>
      {['home', 'work', 'temporary', 'mailing_address'].map((opt) => (
        <option value={opt}>{t(opt, i18nOpts)}</option>
      ))}
    </select>
  </div>
)

export default AddressTypeSelector;
