import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Icon, Tooltip, Button, ModalFooter } from 'Components/Base'
import { Modal } from 'Components/Base/Modal'
import useToggleState from 'Hooks/useToggleState'

const i18nOpts = { scope: 'care_plans.export_modal' }

const ExportButton = ({ exportPath }) => {
  const [modalIsOpen, handleClick] = useToggleState(false)

  return (
    <Fragment>
      <Tooltip text={t('header', i18nOpts)} position="left">
        <Button onClick={handleClick} className="btn btn--action">
          <Icon name="file-download" className="icon_file-download" />
        </Button>
      </Tooltip>

      <Modal title={t('header', i18nOpts)} isOpen={modalIsOpen} closeModal={handleClick}>
        <div className="ReactModal__ContentBody-text">{t('body', i18nOpts)}</div>

        <ModalFooter
          renderRight={() => (
            <Fragment>
              <Button text={t('cancel', i18nOpts)} className="btn btn--secondary" onClick={handleClick} />
              <a className="btn" href={exportPath} onClick={handleClick}>
                {t('export', i18nOpts)}
              </a>
            </Fragment>
          )}
        />
      </Modal>
    </Fragment>
  )
}

ExportButton.propTypes = {
  exportPath: PropTypes.string.isRequired,
}

ExportButton.defaultProps = {}

export default ExportButton
