import { OPEN_MODAL, CLOSE_MODAL } from '../actions/modal'

const initialState = { id: null }

const modal = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return { ...state, id: action.id }
    case CLOSE_MODAL:
      return { ...state, id: null }
    default:
      return state
  }
}

export default modal
