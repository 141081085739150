import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'
import InputGroup from './InputGroup'
import Submit from './Submit'

const i18nOpts = { scope: 'forms.phone_number_form' }

const PhoneNumberForm = ({ phoneNumber, onChange, onSubmit, errors }) => (
  <form className="form form--background" onSubmit={onSubmit}>
    <InputGroup
      name="phoneNumber.label"
      component="select"
      value={phoneNumber.label}
      onChange={onChange}
      label="Label"
      errors={errors}
      children={[
        <option key="mobile" value="mobile">
          {t('mobile', i18nOpts)}
        </option>,
        <option key="primary_residence" value="primary_residence">
          {t('primary_residence', i18nOpts)}
        </option>,
        <option key="secondary_residence" value="secondary_residence">
          {t('secondary_residence', i18nOpts)}
        </option>,
        <option key="work" value="work">
          {t('work', i18nOpts)}
        </option>,
      ]}
    />

    <InputGroup
      name="phoneNumber.number"
      component="input"
      type="tel"
      label="Number"
      value={phoneNumber.number}
      errors={errors}
      onChange={onChange}
    />

    <div className="form__submit">
      <Submit className="btn" />
    </div>
  </form>
)

PhoneNumberForm.propTypes = {
  errors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  phoneNumber: PropTypes.object.isRequired,
}

PhoneNumberForm.defaultProps = {
  errors: {},
}

export default PhoneNumberForm
