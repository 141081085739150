import { Resources } from '../Api';

class AdHocResources extends Resources {
  constructor(carePlanId, id = null) {
    super();

    this.carePlanId = carePlanId;
    this.id = id;
  }

  get url() {
    return `/care_plans/${this.carePlanId}/ad_hoc_resources`;
  }
}

export default AdHocResources;
