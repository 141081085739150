import React from 'react';
import Card from 'Components/Base/Card';
import ResourceSection from './../ResourceSection';
import { t } from 'i18n';

const i18nOpts = { scope: 'care_plan_creators.resources' };

const ResourceSectionContainer = (props) => {
  return (
    <Card collapsable={false} id="resources" title={t('resources', i18nOpts)}>
      <ResourceSection {...props} />
    </Card>
  );
};

export default ResourceSectionContainer;
