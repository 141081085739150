import React from 'react';
import { AddActionHeader } from 'Components/Base/Card';

const AddButton = (canModify, editingRecommendationIndex, setEditingRecommendationIndex) => {
  const isAddingRecommendation = editingRecommendationIndex === -1;
  if (!canModify || isAddingRecommendation) return null;

  return (actionWrapper) => (
    <AddActionHeader onClick={() => actionWrapper(() => setEditingRecommendationIndex(-1))} />
  );
};

export default AddButton;
