import Api from './Api'

class Notification extends Api {
  constructor(id) {
    super()

    this.id = id

    this.markAsRead = this.markAsRead.bind(this)
    this.destroy = this.destroy.bind(this)
  }

  markAsRead() {
    return this.request(`${this.url}/${this.id}/mark_as_read`, 'PATCH')
  }

  get url() {
    return '/notifications'
  }
}

export default Notification
