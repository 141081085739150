import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import Contact from './Contact'

import emptyTeamIcon from 'build-your-team_empty.svg'

const i18nOpts = { scope: 'inbox.side_nav.contacts.contact_list.no_contacts' }

const ContactList = ({ contacts, conversationId, openConversation, type }) =>
  contacts.length ? (
    contacts.map(({ id, name, title, photo, conversation }) => (
      <Contact
        key={id}
        name={name}
        title={title}
        photo={photo}
        onClick={openConversation.bind(null, conversation)}
        isActive={conversationId === conversation.id}
        isUnread={conversation.isUnread}
      />
    ))
  ) : type === 'direct' ? (
    <div className="empty-contacts">
      <img src={emptyTeamIcon} className="empty-contacts__image" />
      <div className="empty-contacts__header">{t('header', i18nOpts)}</div>
      <div className="empty-contacts__info">{t('info', i18nOpts)}</div>
    </div>
  ) : (
    <div className="inbox-menu__contact inbox-menu__contact--empty">{t('no_contacts', i18nOpts)}</div>
  )

ContactList.propTypes = {
  contacts: PropTypes.array.isRequired,
  conversationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  openConversation: PropTypes.func.isRequired,
  type: PropTypes.string,
}

export default ContactList
