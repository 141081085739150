import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';

import FormContainer from './FormContainer';
import { Form, FormForm } from './';
import RepeatedActivity from '../RepeatedActivity';
import { Card, AddActionHeader } from 'Components/Base/Card/';
import { Modal } from 'Components/Base/Modal';

const i18nOpts = { scope: 'care_plans.form_section.index' };

class FormSectionContainer extends Component {
  constructor(props) {
    super(props);

    this.handleToggleForm = this.handleToggleForm.bind(this);
    this.renderForm = this.renderForm.bind(this);

    this.state = {
      showForm: false,
    };
  }

  get cardProps() {
    let renderHeaderAction = null;

    if (this.props.canAdd) {
      renderHeaderAction = (actionWrapper) => (
        <AddActionHeader onClick={() => actionWrapper(this.handleToggleForm)} />
      );
    }

    return {
      title: t('header', i18nOpts),
      collapsable: true,
      renderHeaderAction,
    };
  }

  handleToggleForm(callback) {
    this.setState((prevState) => ({ showForm: !prevState.showForm }), callback);
  }

  renderForm(form) {
    if (form.frequencyType === 'recurring') {
      return <RepeatedActivity key={`repeatedForm:${form.id}`} {...form} />;
    } else {
      return <Form key={form.id} userRole={this.props.userRole} {...form} />;
    }
  }

  render() {
    return (
      <Card {...this.cardProps}>
        <FormContainer
          {...this.props}
          onSuccess={this.handleToggleForm}
          render={({ forms, resetForm, ...rest }) => (
            <Fragment>
              <ul>
                {forms.length ? (
                  forms.map(this.renderForm)
                ) : (
                  <li className="item-pill-section--item-list-empty">{t('empty', i18nOpts)}</li>
                )}
              </ul>
              <Modal
                title={t('header', { scope: 'care_plans.form_section.modal' })}
                isOpen={this.state.showForm}
                closeModal={this.handleToggleForm.bind(null, resetForm)}
              >
                <FormForm
                  {...rest}
                  onClose={this.handleToggleForm.bind(null, resetForm)}
                  timeZone={this.props.timeZone}
                />
              </Modal>
            </Fragment>
          )}
        />
      </Card>
    );
  }
}

FormSectionContainer.propTypes = {
  canAdd: PropTypes.bool,
  formTemplates: PropTypes.array,
  forms: PropTypes.array,
  parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  userRole: PropTypes.string,
  timeZone: PropTypes.string,
};

FormSectionContainer.defaultProps = {
  canAdd: false,
  formTemplates: [],
  forms: [],
  parentId: '',
  timeZone: 'Etc/UTC',
};

export default FormSectionContainer;
