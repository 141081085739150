import React, { Component } from 'react'
import PropTypes from 'prop-types'

import CommentsHeader from './CommentsHeader'
import Comment from './Comment'
import Form from './Form'

import CommentApi from '../../../api/Comment'

class Comments extends Component {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleErrors = this.handleErrors.bind(this)

    this.state = {
      comments: props.comments,
      text: '',
      errors: {},
      isCollapsed: true,
      loading: false,
    }
  }

  handleClick() {
    this.setState((prevState) => ({
      isCollapsed: !prevState.isCollapsed,
    }))
  }

  handleChange({ target: { value } }) {
    this.setState({ text: value })
  }

  handleSubmit(evt) {
    evt.preventDefault()
    this.setState({ loading: true })

    const { carePlanUuid, parentId } = this.props
    new CommentApi(carePlanUuid, parentId).create(this.state.text).then((res) => {
      if (res.ok) {
        res.json().then(({ comment }) => {
          this.setState((prevState) => {
            return { comments: [...prevState.comments, comment], text: '', loading: false }
          })
        })
      } else if (res.status === 422) {
        res.json().then(this.handleErrors)
      }
    })
  }

  handleErrors(errors) {
    this.setState({ errors, loading: false })
  }

  renderLatestComment() {
    if (this.state.comments.length) {
      const comment = this.state.comments[this.state.comments.length - 1]
      return <Comment {...comment} />
    }
    return null
  }

  render() {
    const { comments, isCollapsed, text, loading, errors } = this.state

    return (
      <div className={`comments ${isCollapsed && 'comments--collapsed'}`}>
        <CommentsHeader commentCount={comments.length} onClick={this.handleClick} isCollapsed={isCollapsed} />

        {isCollapsed ? this.renderLatestComment() : comments.map((c) => <Comment key={c.id} {...c} />)}

        <Form
          text={text}
          errors={errors}
          loading={loading}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
        />
      </div>
    )
  }
}

Comments.propTypes = {
  carePlanUuid: PropTypes.string.isRequired,
  comments: PropTypes.array,
  parentId: PropTypes.number.isRequired,
}

Comments.defaultProps = {
  comments: [],
}

export default Comments
