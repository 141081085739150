import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Button, Icon } from 'Components/Base/'

const i18nOpts = { scope: 'base.action_headers' }

const AddActionHeader = ({ onClick }) => (
  <Button className="btn--link card__header-link" onClick={onClick}>
    <Icon name="plus_square" className="card__header-link-icon card__header-link-icon--plus" />
    <span>{t('add', i18nOpts)}</span>
  </Button>
)

AddActionHeader.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default AddActionHeader
