import { connect } from 'react-redux';

import { setSelectedRequest } from 'Actions/accessRequests';
import { getAccessRequests } from 'Selectors/accessRequests';
import { getUnaffiliatedMembers } from 'Selectors/supportTeam';

import { AccessRequests } from './';

const filterAccessRequests = (state) => {
  const ids = getUnaffiliatedMembers(state).map((member) => member?.owner?.id);
  return getAccessRequests(state).filter((req) => !ids.includes(req.requesterId));
};
const mapStateToProps = (state) => ({
  accessRequests: filterAccessRequests(state),
});

const mapDispatchToProps = {
  onClick: setSelectedRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessRequests);
