import 'Utilities/intersection-observer-polyfill';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SkeletonChannel from './SkeletonChannel';

class ChannelPaginator extends Component {
  constructor(props) {
    super(props);
    this.paginatorRef = null;

    this.setPaginatorRef = (element) => {
      this.paginatorRef = element;
    };

    this.createPaginatorObserver = this.createPaginatorObserver.bind(this);
    this.handleIntersect = this.handleIntersect.bind(this);
  }

  handleIntersect(entries, observer) {
    const paginatorDiv = entries[0];
    if (
      paginatorDiv.isIntersecting &&
      this.props.hasMoreChannelPage &&
      !this.props.isFetchingChannelPage &&
      !this.props.isSearchingForChannel &&
      this.props.lastChannelContact
    ) {
      this.props.fetchNextChannelContactsPage({
        sortBy: this.props.channelSortBy,
        lastChannelContact: this.props.lastChannelContact,
      });
    }
  }

  createPaginatorObserver(targetEl) {
    let observer;

    const options = {
      root: null, // watch for intersection of paginatorRef element relative to viewport
      rootMargin: '0px',
      threshold: 0,
    };

    observer = new IntersectionObserver(this.handleIntersect, options);
    observer.observe(targetEl);
  }

  componentDidMount() {
    this.createPaginatorObserver(this.paginatorRef);
  }

  render() {
    const isFetchingChannelPage = this.props.isFetchingChannelPage;
    return (
      <div>
        {isFetchingChannelPage && (
          <div>
            <SkeletonChannel />
            <SkeletonChannel />
            <SkeletonChannel />
          </div>
        )}
        <div ref={this.setPaginatorRef} style={{ height: '1px' }}></div>
      </div>
    );
  }
}

ChannelPaginator.propTypes = {
  channelSortBy: PropTypes.string.isRequired,
  isFetchingChannelPage: PropTypes.bool.isRequired,
  isSearchingForChannel: PropTypes.bool.isRequired,
  fetchNextChannelContactsPage: PropTypes.func.isRequired,
  hasMoreChannelPage: PropTypes.bool.isRequired,
  lastChannelContact: PropTypes.object,
};

export default ChannelPaginator;
