import saveDataApiRequest from './saveDataApiRequest';
import validateDoseValues from './validateDoseValues';

export const handleValidateAndSubmit = (
  handleSubmitApiProvider = saveDataApiRequest,
  validationProvider = validateDoseValues
) => {
  return async (setIsEditing, setIsLoading, setDisabled, setHasSubmissionError, doseValues, carePlanUuid) => {
    try {
      const { isValid } = validationProvider(doseValues);

      if (!isValid) {
        throw new Error();
      }

      const response = await handleSubmitApiProvider(carePlanUuid, { doseValues });

      if (response.content_type === 'INSULIN_DATA') {
        setIsEditing(false);
      } else {
        throw new Error();
      }
    } catch (_) {
      setIsLoading(false);
      setDisabled(false);
      setHasSubmissionError(true);
    }
  };
};

export default handleValidateAndSubmit(saveDataApiRequest, validateDoseValues);
