import { request } from 'Apiv2/handleRequest';

export const getResourcesApiWrapper = (requestProvider = request) => {
  return async (carePlanId) => {
    try {
      const response = await requestProvider(`/care_plans/${carePlanId}/activity_modal/resources`, 'GET');
      const status = response.status;

      if (status === 200) {
        const {
          resources: { adHocResources, placeResources },
        } = await response.json();
        return {
          responseType: 'DATA',
          resources: [...adHocResources, ...placeResources],
        };
      } else {
        throw 'Missing data';
      }
    } catch (error) {
      console.error(error);
      return { responseType: 'ERROR' };
    }
  };
};

export default getResourcesApiWrapper(request);
