import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import AddressApi from 'Api/Address'
import { ModalFooter } from 'Components/Base'
import InputGroup from 'Components/Forms/InputGroup'
// import AddressForm from 'Components/Forms/AddressForm'
import { Button } from 'Components/Base'
import Submit from 'Components/Forms/Submit'

const i18nOpts = { scope: 'forms.help_request' }
const phoneRegExp = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
const datetimeDueAtRegExp = /^2\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[1|2][0-9]|3[0|1])T((0[8|9]|1[0-9]|20):[0-5][0-9]:[0-5][0-9]|21:00:00)/

function validateAddress(address) {
  if (!address.address) {
    return true
  }

  return new AddressApi().validate(address).then((res) => {
    return res.ok
      ? true
      : this.createError({
          path: 'addressTo.address',
        })
  })
}

const validationSchema = Yup.object({
  number: Yup.string().matches(phoneRegExp, t('invalid_phone', i18nOpts)).required(t('blank', i18nOpts)),
  addressTo: Yup.object({
    address: Yup.string().required(t('blank', i18nOpts)),
    city: Yup.string().required(t('blank', i18nOpts)),
    region: Yup.string().required(t('blank', i18nOpts)),
    postalCode: Yup.string().required(t('blank', i18nOpts)),
    countryCode: Yup.string().required(t('blank', i18nOpts)),
  }).test('address-test', t('invalid_address', i18nOpts), validateAddress),
  datetimeDueAt: Yup.string().matches(datetimeDueAtRegExp, t('outside_hours', i18nOpts)),
})

const RequestForm = ({
  helpRequest: {
    stringNameTo,
    addressFrom,
    stringNameFrom,
    addressTo,
    datetimeDueAt,
    stringMoreInfo,
    phoneNumberRecipient: { number },
  },
  onClose,
  timeZone,
  onSubmit,
}) => {
  const renderRequestType = () => {
    const transportation = t('transportation', i18nOpts)
    return (
      <InputGroup
        name="type"
        component="select"
        value={transportation}
        label={t('task_type', i18nOpts)}
        required
        disabled
      >
        <option key={transportation} value={transportation}>
          {transportation}
        </option>
      </InputGroup>
    )
  }

  const renderPickupLocationName = ({ values, getFieldProps }) => (
    <Fragment>
      <InputGroup
        className="input"
        component="input"
        name="stringNameFrom"
        label={t('pickup_name', i18nOpts)}
        placeholder={t('placeholder.pickup', i18nOpts)}
        value={values.stringNameFrom}
        {...getFieldProps('stringNameFrom')}
      />
    </Fragment>
  )

  renderPickupLocationName.propTypes = {
    getFieldProps: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
  }

  const renderPickupLocationAddress = ({ values, getFieldProps }) => (
    <Fragment>
      <label>{t('pickup_address', i18nOpts)}</label>
      {/* <AddressForm
        address={values.addressFrom}
        name="addressFrom"
        collapsible={false}
        errors={{}}
        {...getFieldProps('addressFrom')}
      /> */}
    </Fragment>
  )

  renderPickupLocationAddress.propTypes = {
    getFieldProps: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
  }

  const renderDeliveryLocation = ({ values, getFieldProps }) => (
    <Fragment>
      <InputGroup
        className="input"
        label={t('delivery_name', i18nOpts)}
        name="stringNameTo"
        component="input"
        placeholder={t('placeholder.delivery', i18nOpts)}
        value={values.stringNameTo}
        {...getFieldProps('stringNameTo')}
      />
    </Fragment>
  )

  renderDeliveryLocation.propTypes = {
    getFieldProps: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
  }

  const renderDeliveryAddress = ({ touched, errors, getFieldProps, values }) => (
    <div className="input">
      <label className="required" htmlFor="addressTo">
        <abbr title="required">*</abbr> {t('delivery_address', i18nOpts)}
      </label>
      {/* <AddressForm
        address={values.addressTo}
        name="addressTo"
        collapsible={false}
        errors={(touched.addressTo && errors) || {}}
        {...getFieldProps('addressTo')}
      /> */}
    </div>
  )

  renderDeliveryAddress.propTypes = {
    errors: PropTypes.object.isRequired,
    getFieldProps: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
  }

  const renderRecipientPhoneNumber = ({ touched, errors, getFieldProps, values }) => (
    <Fragment>
      <InputGroup
        className="input"
        label={t('recipient_phone', i18nOpts)}
        name="number"
        component="input"
        placeholder={t('placeholder.recipient_phone', i18nOpts)}
        errors={touched.number && errors}
        required
        values={values.number}
        {...getFieldProps('number')}
      />
    </Fragment>
  )

  renderRecipientPhoneNumber.propTypes = {
    errors: PropTypes.object.isRequired,
    getFieldProps: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
  }

  const renderPickUpTime = ({ errors, touched, values, getFieldProps }) => (
    <InputGroup
      className="input schedule-input__input input--fixed-hint"
      name="datetimeDueAt"
      component="datetime"
      label={t('due_at', i18nOpts)}
      required
      timeZone={timeZone}
      ensureVisibility
      value={values.datetimeDueAt}
      hint={t('placeholder.due_at', i18nOpts)}
      errors={touched.datetimeDueAt && errors}
      {...getFieldProps('datetimeDueAt')}
    />
  )

  renderPickUpTime.propTypes = {
    errors: PropTypes.object.isRequired,
    getFieldProps: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
  }

  const renderNotes = ({ values, getFieldProps }) => (
    <Fragment>
      <InputGroup
        className="input"
        label={t('notes', i18nOpts)}
        name="stringMoreInfo"
        component="textarea"
        value={values.stringMoreInfo}
        {...getFieldProps('stringMoreInfo')}
      />
    </Fragment>
  )

  renderNotes.propTypes = {
    getFieldProps: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
  }

  return (
    <Fragment>
      <header className="wave-header">
        <div className="wave-header__header-title text-medium">{t('placeholder.header', i18nOpts)}</div>
      </header>
      <Formik
        initialValues={{
          stringNameTo,
          addressFrom,
          addressTo,
          stringNameFrom,
          number,
          datetimeDueAt,
          stringMoreInfo,
        }}
        onSubmit={onSubmit}
        validateOnChange={false}
        validationSchema={validationSchema}
      >
        {({ errors, getFieldProps, touched, values }) => {
          return (
            <Form noValidate className="form">
              {renderRequestType()}
              {renderPickupLocationName({ values, getFieldProps })}
              {renderPickupLocationAddress({ values, getFieldProps })}
              {renderDeliveryLocation({ values, getFieldProps })}
              {renderDeliveryAddress({ errors, touched, getFieldProps, values })}
              {renderRecipientPhoneNumber({ errors, touched, getFieldProps, values })}
              {renderPickUpTime({ errors, touched, values, getFieldProps })}
              {renderNotes({ values, getFieldProps })}

              <ModalFooter
                renderRight={() => (
                  <Fragment>
                    <Button onClick={onClose} className="btn btn--secondary" text={t('cancel', i18nOpts)} />

                    <Submit value={t('submit', i18nOpts)} />
                  </Fragment>
                )}
              />
            </Form>
          )
        }}
      </Formik>
    </Fragment>
  )
}

RequestForm.propTypes = {
  helpRequest: PropTypes.shape({
    id: PropTypes.number,
    stringNameTo: PropTypes.string,
    addressFrom: PropTypes.shape({
      address: PropTypes.string,
      city: PropTypes.string,
      region: PropTypes.string,
      postalCode: PropTypes.string,
      countryCode: PropTypes.string,
    }),
    stringNameFrom: PropTypes.string,
    addressTo: PropTypes.shape({
      address: PropTypes.string,
      city: PropTypes.string,
      region: PropTypes.string,
      postalCode: PropTypes.string,
      countryCode: PropTypes.string,
    }),
    datetimeDueAt: PropTypes.string,
    stringMoreInfo: PropTypes.string,
    phoneNumberRecipient: PropTypes.shape({ number: PropTypes.string }),
  }),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  timeZone: PropTypes.string.isRequired,
}

RequestForm.defaultProps = {
  helpRequest: {
    id: null,
    stringNameTo: '',
    addressFrom: {
      address: '',
      city: '',
      region: '',
      postalCode: '',
      countryCode: '',
    },
    stringNameFrom: '',
    addressTo: {
      address: '',
      city: '',
      region: '',
      postalCode: '',
      countryCode: '',
    },
    datetimeDueAt: '',
    stringMoreInfo: '',
    phoneNumberRecipient: { number: '' },
  },
}

export default RequestForm
