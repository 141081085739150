export const leftButtonAction = (updateSignup, target) => {
  if (target.status === 'accepted') {
    // "View Action Plan" option
    return () => {
      window.location.replace(target.url);
    };
  } else {
    // "Decline" option
    return () => {
      updateSignup({ ...target, status: 'declined' });
    };
  }
};

export const rightButtonAction = (setIsTargetSignupModalOpen, updateSignup, target) => {
  if (target.status === 'accepted') {
    // "Done" option
    return () => {
      setIsTargetSignupModalOpen(false);
    };
  } else {
    // "Accept" option
    return () => {
      updateSignup({ ...target, status: 'accepted' });
    };
  }
};
