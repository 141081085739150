import { Resources } from 'Api/Api'

class AdHocResourceTemplates extends Resources {
  constructor(carePlanTemplateId, id = null) {
    super()

    this.carePlanTemplateId = carePlanTemplateId
    this.id = id
  }

  get url() {
    return `/care_plan_templates/${this.carePlanTemplateId}/ad_hoc_resource_templates`
  }
}

export default AdHocResourceTemplates
