import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import InputGroup from '../../Forms/InputGroup'
import Submit from '../../Forms/Submit'

const PHISection = ({ value, audit, onChange, onSubmit, errors }) => (
  <form className="form form--background" onSubmit={onSubmit}>
    <InputGroup
      component="textarea"
      name="phi"
      value={value}
      audit={audit}
      errors={errors}
      onChange={onChange}
    />

    <div className="form__submit">
      <Submit value={t('submit', { scope: 'patient.edit.phi_section' })} className="btn" />
    </div>
  </form>
)

PHISection.propTypes = {
  audit: PropTypes.string,
  errors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.string,
}

PHISection.defaultProps = {
  value: '',
  audit: '',
  errors: {},
}

export default PHISection
