export const SET_IS_TOGGLING_DAYS = 'SET_IS_TOGGLING_DAYS';
export const SET_RANGE_END_DATE = 'SET_RANGE_END_DATE';
export const SET_RENDERED_DAYS = 'SET_RENDERED_DAYS';
export const SET_WEEK_ORDER = 'SET_WEEK_ORDER';
export const SET_CHART_MAXIMUM_DATE = 'SET_CHART_MAXIMUM_DATE';
export const SET_CHART_DATA = 'SET_CHART_DATA';
export const SET_CHART_ERROR = 'SET_CHART_ERROR';
export const SET_MONITOR_TYPE = 'SET_MONITOR_TYPE';
export const SET_PAGE_STATE = 'SET_PAGE_STATE';

const setIsTogglingDays = (isTogglingDays) => ({
  type: SET_IS_TOGGLING_DAYS,
  isTogglingDays,
});

const setRangeEndDate = (rangeEndDate) => ({
  type: SET_RANGE_END_DATE,
  rangeEndDate,
});

const setRenderedDays = (renderedDays) => ({
  type: SET_RENDERED_DAYS,
  renderedDays,
});

const setWeekOrder = (weekOrder) => ({
  type: SET_WEEK_ORDER,
  weekOrder,
});

const setChartMaximumDate = (chartMaximumDate) => ({
  type: SET_CHART_MAXIMUM_DATE,
  chartMaximumDate,
});

const setChartData = (chartData) => ({
  type: SET_CHART_DATA,
  chartData,
});

const setChartError = (chartError) => ({
  type: SET_CHART_ERROR,
  chartError,
});

const setMonitorType = (monitorType) => ({
  type: SET_MONITOR_TYPE,
  monitorType,
});

const setPageState = (pageState) => ({
  type: SET_PAGE_STATE,
  pageState,
});

export {
  setIsTogglingDays,
  setRangeEndDate,
  setRenderedDays,
  setWeekOrder,
  setChartMaximumDate,
  setChartData,
  setChartError,
  setMonitorType,
  setPageState,
};
