import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import Button from '../Base/Button'
import Icon from '../Base/Icon'
import { Footer } from 'Components/Base/Card/'

import banner from 'health_team_invite_banner.svg'

const i18nOpts = { scope: 'welcome_banner.care_plan_banner' }

const handleClick = () => window.location.assign(`${window.location.href}/tasks/weekly`)

const CarePlanBanner = ({ close, selfSignup, placeName }) => (
  <div className="card card--wave-header">
    <header className="card__image-header">
      <div className="card__image-header-text">
        {selfSignup ? t('self_signup_header', i18nOpts) : t('basic_header', { ...i18nOpts, placeName })}
      </div>

      <div className="card__image-header-image-center">
        <img src={banner} />
      </div>

      <div onClick={close} className="card__image-header-close">
        <Icon name="close" />
      </div>
    </header>

    <div className="card__body">
      <p className="h3 text-grey-darker">
        {selfSignup ? t('self_signup_details', i18nOpts) : t('basic_details', i18nOpts)}
        &nbsp;
        <a onClick={handleClick}>{t('link_text', i18nOpts)}</a>
      </p>
      <br />

      <Footer right={<Button text={t('button', i18nOpts)} onClick={close} />} />
    </div>
  </div>
)

CarePlanBanner.propTypes = {
  close: PropTypes.func.isRequired,
  placeName: PropTypes.string.isRequired,
  selfSignup: PropTypes.bool.isRequired,
}

export default CarePlanBanner
