import React from 'react';
import ProviderWrapper from 'Components/Base/ProviderWrapper';

import AddActivityHeaderContents from './AddActivityHeaderContents';

const AddActivityHeader = () => {
  return (
    <ProviderWrapper>
      <AddActivityHeaderContents />
    </ProviderWrapper>
  );
}

export default AddActivityHeader;
