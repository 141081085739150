import { request } from 'Apiv2/handleRequest';

export const buildRequestParams = (carePlanId, activityType, dueAt, frequencyType, body) => {
  let requestUrl = `/care_plans/${carePlanId}/`;
  let requestBody;

  const recurring = frequencyType === 'recurring';

  switch (activityType) {
    case 'TASK':
      if (recurring) {
        requestUrl += 'repeated_tasks/weekly';
        requestBody = { ...body };
      } else {
        requestUrl += 'tasks';
        requestBody = { task: { ...body } };
      }
      break;
    case 'APPOINTMENT':
      if (recurring) {
        requestUrl += 'repeated_appointments';
        requestBody = { ...body };
      } else {
        requestUrl += 'appointments';
        requestBody = { appointment: { ...body } };
      }
      break;
    default:
      requestUrl = '/404';
      break;
  }

  return { requestUrl, requestBody };
};

export const getAddressFields = (addressFields) => {
  if (!addressFields) {
    return null;
  }

  const { address, city, region, extendedAddress, postalCode, countryCode } = addressFields;

  return {
    address,
    city,
    region,
    extended_address: extendedAddress,
    postal_code: postalCode,
    country_code: countryCode,
  };
};

export const parseAssignments = (assignments) => {
  if (!assignments || assignments.length <= 0) {
    return null;
  }

  return assignments.map(({ value }) => {
    return { id: value };
  });
};

export const createActivityApiWrapper = (
  requestProvider = request,
  buildRequestParamsProvider = buildRequestParams
) => {
  return async (carePlanId, activity, type) => {
    try {
      const {
        description,
        virtualVisitLink,
        addressFields,
        frequencyType,
        dueAt,
        frequency,
        count,
        endRepeat,
        until,
        interval,
        assignments,
        resources,
        name,
      } = activity;

      const body = {
        type,
        kind: 'generic', // for patient-created tasks and appointments
        text: description,
        url: virtualVisitLink,
        address_attributes: getAddressFields(addressFields),
        frequency_type: frequencyType, // 'scheduled', 'recurring', 'once'
        due_at: dueAt,
        frequency, // 'daily', 'weekly', 'monthly', 'yearly'
        count,
        end_repeat: endRepeat,
        until,
        interval,
        assignments: parseAssignments(assignments),
        resources,
        name,
      };

      const { requestUrl, requestBody } = buildRequestParamsProvider(
        carePlanId,
        type,
        dueAt,
        frequencyType,
        body
      );
      const response = await requestProvider(requestUrl, 'POST', requestBody);

      if (response.status === 422) {
        throw new Error();
      }

      return { responseType: 'DATA' };
    } catch (error) {
      return { status: 422, error, responseType: 'ERROR' };
    }
  };
};

export default createActivityApiWrapper(request, buildRequestParams);
