import { Resource } from './Api'

class Invitation extends Resource {
  constructor(teamMembershipId) {
    super()

    this.teamMembershipId = teamMembershipId
  }

  update({ email, sendInvitation = null }) {
    return super.update({
      team_membership: {
        invitation_attributes: {
          email,
        },
      },
      send_invitation: sendInvitation,
    })
  }

  sendInvitation() {
    return this.request(`/team/${this.teamMembershipId}/invitation/send_invitation`, 'POST')
  }

  get url() {
    return `/team/${this.teamMembershipId}/invitation`
  }
}

export default Invitation
