import { Resource } from '../Api'

class Contact extends Resource {
  constructor() {
    super()
  }

  all() {
    return super.request(`${this.url}/all`)
  }

  get url() {
    return '/inbox/contacts'
  }
}

export default Contact
