import React from 'react';
import { t } from 'i18n';
import PropTypes from 'prop-types';
import { TabContainer, Tab } from 'Components/Base/Tabs';

const i18nOpts = { scope: 'dashboard' };

const CGMPeriodTabs = ({ selectedTab, setSelectedTab }) => {
  // Returns two tab components - one for Mobile and one for Desktop
  return (
    <>
      <TabContainer>
        <Tab
          text={
            <>
              <span className="tabs-V2__tab__text--mobile-only">{t('time_range.1d', i18nOpts)}</span>
              <span className="tabs-V2__tab__text--desktop-only">{t('time_range.1day', i18nOpts)}</span>
            </>
          }
          isSelected={selectedTab === '1day'}
          onClick={() => {
            setSelectedTab('1day');
          }}
        />
        <Tab
          text={
            <>
              <span className="tabs-V2__tab__text--mobile-only">{t('time_range.7d', i18nOpts)}</span>
              <span className="tabs-V2__tab__text--desktop-only">{t('time_range.7days', i18nOpts)}</span>
            </>
          }
          isSelected={selectedTab === '7days'}
          onClick={() => {
            setSelectedTab('7days');
          }}
        />
        <Tab
          text={
            <>
              <span className="tabs-V2__tab__text--mobile-only">{t('time_range.14d', i18nOpts)}</span>
              <span className="tabs-V2__tab__text--desktop-only">{t('time_range.14days', i18nOpts)}</span>
            </>
          }
          isSelected={selectedTab === '14days'}
          onClick={() => {
            setSelectedTab('14days');
          }}
        />
        <Tab
          text={
            <>
              <span className="tabs-V2__tab__text--mobile-only">{t('time_range.30d', i18nOpts)}</span>
              <span className="tabs-V2__tab__text--desktop-only">{t('time_range.30days', i18nOpts)}</span>
            </>
          }
          isSelected={selectedTab === '30days'}
          onClick={() => {
            setSelectedTab('30days');
          }}
        />
        <Tab
          text={
            <>
              <span className="tabs-V2__tab__text--mobile-only">{t('time_range.90d', i18nOpts)}</span>
              <span className="tabs-V2__tab__text--desktop-only">{t('time_range.90days', i18nOpts)}</span>
            </>
          }
          isSelected={selectedTab === '90days'}
          onClick={() => {
            setSelectedTab('90days');
          }}
        />
        <Tab
          text={t('time_range.compare', i18nOpts)}
          isSelected={selectedTab === 'compare'}
          onClick={() => {
            setSelectedTab('compare');
          }}
        />
      </TabContainer>
    </>
  );
};

CGMPeriodTabs.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
};

export default CGMPeriodTabs;
