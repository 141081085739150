const LEGEND_COLORS_MAP = {
  poor: '#BAEBAB',
  average: '#74C75A',
  good: '#2F9110',
  great: '#11551D',
  noData: '#FFF',
};

const calculateFill = (quality) => (quality ? LEGEND_COLORS_MAP[quality] : LEGEND_COLORS_MAP.noData);

export { calculateFill as default, LEGEND_COLORS_MAP as legendMap };
