import ResourcesApi from 'Api/CarePlan/Resources'
import ServiceTemplatesApi from 'Api/TemplateCreator/ServiceTemplate'

class ServiceDispatcher {
  constructor(resourceId, isTemplate) {
    this.id = resourceId
    this.isTemplate = isTemplate
  }

  update(data) {
    return this.api.update(data)
  }

  get api() {
    if (this.isTemplate) {
      return new ServiceTemplatesApi(this.id)
    } else {
      return new ResourcesApi(this.id)
    }
  }
}

export default ServiceDispatcher
