import React, { Component, Fragment } from 'react'
import { t } from 'i18n'
import PropTypes from 'prop-types'

import Button from 'Components/Base/Button'
import InputGroup from 'Components/Forms/InputGroup'
import ScoreIcon from 'Components/Base/ScoreIcon'
import { ModalFooter } from 'Components/Base'
import { Modal } from 'Components/Base/Modal'

const i18nOpts = { scope: 'care_plan_creators.check_in_templates.questions' }

class MultipleChoiceQuestionOption extends Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleConfirmationModal = this.handleConfirmationModal.bind(this)

    this.state = {
      ...this.initialState,
    }
  }

  get initialState() {
    const { questionOption } = this.props

    return {
      id: questionOption.id,
      text: questionOption.data.text,
      description: questionOption.data.description || '',
      response: questionOption.data.response || '',
      index: questionOption.id || questionOption.data.index,
      order: questionOption.data.order,
      score: questionOption.data.score,
      confirmationModalIsOpen: false,
    }
  }

  handleChange({ target: { name, value } }) {
    this.setState({ [name]: value }, () => {
      this.props.updateOptions(this.state)
    })
  }

  componentWillReceiveProps() {
    this.setState(this.initialState)
  }

  handleConfirmationModal() {
    this.setState((prevState) => ({ confirmationModalIsOpen: !prevState.confirmationModalIsOpen }))
  }

  handleDelete(score) {
    this.handleConfirmationModal()
    this.props.removeOption(score)
  }

  renderConfirmationModal() {
    const { confirmationModalIsOpen, score } = this.state

    return (
      <Fragment>
        <Modal isOpen={confirmationModalIsOpen} closeModal={this.handleConfirmationModal}>
          <h4 className="text-grey-darkest header">{t('confirmation', i18nOpts)}</h4>
          <ModalFooter
            renderRight={() => (
              <Fragment>
                <Button
                  text={t('cancel', i18nOpts)}
                  className="btn btn--secondary"
                  onClick={this.handleConfirmationModal}
                />
                <Button text={t('delete', i18nOpts)} onClick={this.handleDelete.bind(this, score)} />
              </Fragment>
            )}
          />
        </Modal>
      </Fragment>
    )
  }

  render() {
    const { text, description, response, score, confirmationModalIsOpen } = this.state
    const {
      isActive,
      editing,
      errors,
      maxDesc,
      maxTitle,
      minResponses,
      renderConditionalResponse,
    } = this.props
    const error = text.length ? null : errors['check_in_question_options.text']

    return (
      <li>
        <div className="option-input-wrapper">
          <div className="option-index">{score}</div>
          <div className="option-inputs">
            <div>
              <InputGroup
                component="input"
                name="text"
                placeholder={t('placeholders.multiple_choice_text', i18nOpts)}
                value={text}
                onChange={this.handleChange}
                required
                maxLength={maxTitle}
                className="option-text"
                type="text"
                errors={{ text: error }}
                readonly={isActive}
              />
              <span className="character-count">{maxTitle - text.length}</span>
              {score > minResponses && !editing && (
                <Button
                  onClick={this.handleConfirmationModal}
                  className="btn btn--link"
                  text={t('delete_response', i18nOpts)}
                />
              )}
            </div>
            <div>
              <InputGroup
                component="input"
                name="description"
                placeholder={t('placeholders.multiple_choice_description', i18nOpts)}
                value={description}
                onChange={this.handleChange}
                maxLength={maxDesc}
                className="option-description"
                type="text"
                readonly={isActive}
              />
              <span className="character-count">{maxDesc - (description || '').length}</span>
            </div>
            {renderConditionalResponse(response, this.handleChange)}
          </div>
          <div className="option-icon">
            <ScoreIcon score={score} />
          </div>
        </div>
        {confirmationModalIsOpen && this.renderConfirmationModal()}
      </li>
    )
  }
}

MultipleChoiceQuestionOption.propTypes = {
  editing: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  isActive: PropTypes.bool,
  maxDesc: PropTypes.number,
  maxTitle: PropTypes.number,
  minResponses: PropTypes.number,
  questionOption: PropTypes.object,
  removeOption: PropTypes.func.isRequired,
  renderConditionalResponse: PropTypes.func.isRequired,
  updateOptions: PropTypes.func,
}

MultipleChoiceQuestionOption.defaultProps = {
  errors: {},
  isActive: false,
  maxDesc: 60,
  maxTitle: 25,
  minResponses: 2,
  questionOption: {},
  updateOptions: null,
}

export default MultipleChoiceQuestionOption
