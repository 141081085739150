import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import Icon from '../../Base/Icon'
import Button from '../../Base/Button'
import Avatar from 'PulseBackports/Avatar'

const i18nOpts = { scope: 'activities.assignments.assignment' }

const Assignment = ({ claim, claimable, dismiss, currentTeamMembershipId, assignment, activityPath }) => {
  const {
    status,
    statusText,
    assigner,
    teamMembership,
    teamMembership: { path, canMessage, messagePath },
  } = assignment

  const owner = teamMembership.owner || teamMembership.pendingOwner || teamMembership.declinedOwner

  return (
    <li className="assignment">
      <Avatar
        src={owner.photo}
        fullName={owner.name}
        className="assignment__avatar avatar avatar--thumb"
      />

      <ul className="assignment__status">
        <li>
          <h3 className="assignment__name">
            <a href={path}>{owner.name}</a>
            {status === 'accepted' && <Icon name="check_alt2" className="icon--green" />}
            {status === 'declined' && <Icon name="blocked" className="icon--red" />}
          </h3>
        </li>
        <li>
          <b>{statusText}</b>
        </li>
      </ul>

      {assigner && assigner.id === currentTeamMembershipId && status === 'declined' ? (
        <div className="assignment-actions assignment-actions--declined">
          <div className="assignment-actions__label">{t('what_would_you_do', i18nOpts)}</div>

          <a href={activityPath} className="btn assignment-actions__action">
            {t('assign_someone_else', i18nOpts)}
          </a>

          <Button onClick={dismiss} className="btn assignment-actions__action">
            {t('let_anyone_claim', i18nOpts)}
          </Button>

          {claimable && (
            <Button onClick={claim} className="btn assignment-actions__action">
              {t('claim', i18nOpts)}
            </Button>
          )}
        </div>
      ) : (
        canMessage && (
          <div className="assignment-actions">
            <a href={messagePath} className="btn assignment-actions__action">
              {t('message', i18nOpts)}
            </a>
          </div>
        )
      )}
    </li>
  )
}

Assignment.propTypes = {
  activityPath: PropTypes.string.isRequired,
  assignment: PropTypes.object.isRequired,
  currentTeamMembershipId: PropTypes.number.isRequired,
}

Assignment.defaultProps = {}

export default Assignment
