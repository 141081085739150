import React from 'react';
import { t } from 'i18n';

import * as i18nCountries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import frLocale from "i18n-iso-countries/langs/fr.json";

const i18nOpts = { scope: 'country_codes' }

const CountrySelector = ({ activityProperties, label, name, value, onChange, locale }) => {
  i18nCountries.registerLocale(enLocale);
  i18nCountries.registerLocale(frLocale);

  const FOCUSED_COUNTRIES = ['CA', 'US', 'GB'];
  const countryDefinitions = i18nCountries.getNames(locale, { select: 'official' });

  const sortedDefs = Object.entries(countryDefinitions).sort((a, b) => ( a[1].localeCompare(b[1]) ));
  const sortedCountryDefinitions = sortedDefs.filter(([code]) => !FOCUSED_COUNTRIES.includes(code));

  return (
    <div className="appointment__address__input_field__country_selector">
      <label htmlFor={name}>{label}</label>
      <select
        value={value}
        name={name}
        onChange={(e) => {
          let region = activityProperties?.addressFields?.region;
          if (e.target.value == 'GB') {
            region = '';
          }
          onChange(e)
        }}
      >
        <option key="blank" value="">
          {t('blank', i18nOpts)}
        </option>
        {
          FOCUSED_COUNTRIES.map(code => (
            <option key={`focused-country-${code}`} value={code}>
              { i18nCountries.getName(code, locale, { select: 'official' }) }
            </option>
          ))
        }
        {
          sortedCountryDefinitions.map(([code, displayName]) => (
            <option key={`sorted-country-${code}`} value={code}>{ displayName }</option>
          ))
        }
      </select>
    </div>
  )
};

export default CountrySelector;
