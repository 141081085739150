import Api from '../Api'

class Task extends Api {
  constructor(placeUserId) {
    super()

    this.placeUserId = placeUserId
  }

  weekly(year, week) {
    const path = year && week ? `weekly/${year}/${week}` : 'weekly'

    return this.request(`${this.url}/${path}`, 'GET')
  }

  completed() {
    return this.request(`${this.url}/completed`)
  }

  get url() {
    return `/place_users/${this.placeUserId}/tasks`
  }
}

export default Task
