import Api from '../Api';

class ChannelPage extends Api {
  constructor({ sortBy = null, lastConversationId = null }) {
    super();

    this.sortBy = sortBy || '';
    this.lastConversationId = lastConversationId || '';
  }

  getPage() {
    return super.request(
      `${this.url}?sortBy=${this.sortBy}&lastConversationId=${this.lastConversationId}`,
      'GET',
      null,
      false
    );
  }

  get url() {
    return '/inbox/page';
  }
}

export default ChannelPage;
