import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'Components/Base/Icon';
import { t } from 'i18n';

import {
  ItemPill,
  ItemPillActions,
  ItemPillActionIcon,
  ItemPillText
} from 'Components/Base/ItemPill'

const i18nOpts = { scope: 'care_plan_creators.resources.form' };

const Resource = ({
  resource,
  isAddResForm,
  isProcessing,
  isRemovable,
  isModifiable,
  checkboxProps,
  tagString,
  onOpenModal,
  toggleResourceSelected,
  onRemoveResource,
}) => {
  return (
    <div className="form__row form__row--resource">
      {isAddResForm && (
        <div
          className="item-pill__action item-pill__action--medium"
          onClick={() => {
            toggleResourceSelected(resource);
          }}
          data-test="checkbox-icon"
        >
          <Icon {...checkboxProps} />
        </div>
      )}
      <ItemPill selected flat stretch multiline>
        <span className="item-pill__text">
          {isAddResForm && <ItemPillText text="" subtext={tagString} />}

          <a href={resource.link}>
            <ItemPillText text={resource.name} />
          </a>

          {resource.address.address && (
            <ItemPillText text="" subtext={`${resource.address.address}, ${resource.address.city}`} />
          )}

          {resource.preferredPhoneNumber.number && <ItemPillText text="" subtext={resource.preferredPhoneNumber.number} />}

          {resource.url && (
            <a href={resource.url}>
              <small>{t('url', i18nOpts)}</small>
            </a>
          )}
        </span>

        <ItemPillActions isLoading={isProcessing}>
          {!isAddResForm && <ItemPillText text="" subtext={tagString} />}

          <div className="item-pill__action-items">
            {isModifiable && (
              <ItemPillActionIcon
                iconName="file-edit-blue"
                data-test="edit-icon"
                onClick={() => {
                  onOpenModal(resource.id);
                }}
              />
            )}

            {isRemovable && (
              <ItemPillActionIcon
                iconName="minus-circle-blue"
                data-test="delete-icon"
                onClick={() => {
                  onRemoveResource(resource);
                }}
              />
            )}
          </div>
        </ItemPillActions>
      </ItemPill>
    </div>
  );
};

Resource.propTypes = {
  checkboxProps: PropTypes.object.isRequired,
  isAddResForm: PropTypes.bool,
  isModifiable: PropTypes.bool,
  isProcessing: PropTypes.bool,
  isRemovable: PropTypes.bool,
  isResourceSelected: PropTypes.bool,
  onOpenModal: PropTypes.func.isRequired,
  onRemoveResource: PropTypes.func.isRequired,
  resource: PropTypes.shape({
    address: PropTypes.shape({
      address: PropTypes.string,
      city: PropTypes.string,
      postal_code: PropTypes.string,
      region: PropTypes.string,
    }),
    authorType: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    isAdHoc: PropTypes.bool.isRequired,
    link: PropTypes.string,
    name: PropTypes.string.isRequired,
    preferredPhoneNumber: PropTypes.shape({
      number: PropTypes.string,
    }),
    referralLink: PropTypes.string,
    tags: PropTypes.array,
    url: PropTypes.string,
  }),
  tagString: PropTypes.string,
  toggleResourceSelected: PropTypes.func.isRequired,
};

Resource.defaultProps = {
  isAddResForm: false,
  isModifiable: false,
  isProcessing: false,
  isRemovable: false,
  isResourceSelected: false,
  resource: {
    address: {
      address: '',
      city: '',
      region: '',
      postal_code: '',
    },
    link: '',
    preferredPhoneNumber: {
      number: '',
    },
    referralLink: '',
    tags: [],
    url: '',
  },
  tagString: '',
};

export default Resource;
