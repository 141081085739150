import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import { setActivityModalActivityProperties, setActivityModalFormErrors } from 'Actions/activityModal';
import { setupVirtualVisitLinkValidator } from '../formFieldsValidators';

import FormField from '../FormField';

const i18nOpts = { scope: 'activity_modal.form_fields.where' };

export const VirtualVisitLink = ({
  activityProperties,
  formErrors,
  setActivityModalActivityProperties,
  setActivityModalFormErrors,
  canEditUrl,
}) => {
  const handleVirtualVisitLinkValidation = setupVirtualVisitLinkValidator(
    formErrors,
    setActivityModalFormErrors,
    t,
    i18nOpts
  );

  return (
    <FormField
      className="activity_modal__virtual_visit__input_field__container"
      label={t('virtual_visit_link_description', i18nOpts)}
      formErrors={formErrors}
      value={activityProperties.virtualVisitLink}
      name="virtualVisitLink"
      onChange={(value) => {
        setActivityModalActivityProperties({
          ...activityProperties,
          virtualVisitLink: value,
        });
      }}
      readOnly={!canEditUrl}
      handleValidation={handleVirtualVisitLinkValidation}
    />
  );
};

const mapStateToProps = ({ activityModal }) => {
  const { activityProperties, formErrors, canEditFields: { canEditUrl } } = activityModal;

  return { activityProperties, formErrors, canEditUrl };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalActivityProperties = (dispatch) => {
    return (activityProperties) => {
      dispatch(setActivityModalActivityProperties(activityProperties));
    };
  };

  const dispatchSetActivityModalFormErrors = (dispatch) => {
    return (formErrors) => {
      dispatch(setActivityModalFormErrors(formErrors));
    };
  };

  return {
    setActivityModalActivityProperties: dispatchSetActivityModalActivityProperties(dispatch),
    setActivityModalFormErrors: dispatchSetActivityModalFormErrors(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VirtualVisitLink);
