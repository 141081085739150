import moment from 'ct-moment';

// Returns order of a full week ending in the isoWeekday specified
// e.g. argument of 3 which is isoWeekday for Wednesday, will return:
// => ['thu', 'fri', 'sat', 'sun', 'mon', 'tue', 'wed']

export const getWeekOrderWrapper = (timeProvider = moment) => {
  return (rangeEndIsoDate) => {
    const rangeEndIsoWeekday = timeProvider(rangeEndIsoDate).isoWeekday();

    const ISO_WEEKDAY_DEFINITIONS = {
      1: 'mon',
      2: 'tue',
      3: 'wed',
      4: 'thu',
      5: 'fri',
      6: 'sat',
      7: 'sun',
    };

    const isoWeekOrder = [1, 2, 3, 4, 5, 6, 7];

    for (let i = 0; i < rangeEndIsoWeekday; i++) {
      const isoWeekday = isoWeekOrder.shift();
      isoWeekOrder.push(isoWeekday);
    }

    const weekOrder = isoWeekOrder.map((isoWeekday) => {
      return ISO_WEEKDAY_DEFINITIONS[isoWeekday];
    });

    return weekOrder;
  };
};

export default getWeekOrderWrapper(moment);
