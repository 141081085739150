import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';
import Patient from '../Patient';
import BlankSlate from 'Components/Base/BlankSlate';
import filterAndSortPatients from './filterAndSortPatients';
import addArchiveFieldToPatient from './addArchiveFieldToPatient';

const i18nOpts = { scope: 'patient.index' };

const BlankPatientsList = () => (
  <section className="patients">
    <BlankSlate iconName="search" body={t('empty', i18nOpts)} />
  </section>
);

const PatientsList = ({
  patients,
  sortBy,
  searchInput,
  myPatientIds,
  archivedPatientIds,
  isShowingMyPatients,
  isShowingArchivedPatients
}) => {
  const ELEMENT_RENDER_LIMIT = 20;
  const [offset, setOffset] = useState(ELEMENT_RENDER_LIMIT)

  const lastPatientRef = useRef(null);

  const observer = useRef(
    new IntersectionObserver((entries) => {
      if(entries[0].isIntersecting) {
        setOffset((currentLimit) => currentLimit + ELEMENT_RENDER_LIMIT);
      }
    })
  );

  const queriedPatients = useMemo(() => {
    const filteredAndSorted = filterAndSortPatients(
      patients,
      myPatientIds,
      isShowingMyPatients,
      searchInput,
      sortBy,
      archivedPatientIds,
      isShowingArchivedPatients
    );
    return addArchiveFieldToPatient(filteredAndSorted, archivedPatientIds);
  }, [patients, myPatientIds, isShowingMyPatients, searchInput, sortBy, archivedPatientIds, isShowingArchivedPatients]);

  const renderedPatients = queriedPatients.slice(0, offset)

  // reset offset whenever user changes query
  useEffect(() => {
    setOffset(ELEMENT_RENDER_LIMIT);
  }, [queriedPatients])

  // once the next page of patients has loaded, reconnect to the last element of the next page
  useEffect(() => {
    if (observer.current) {
      observer.current.disconnect();
    }

    const hasMorePatientsToRender = queriedPatients.length > offset; // condition necessary to prevent infinite loop

    if (lastPatientRef.current && hasMorePatientsToRender) {
      observer.current.observe(lastPatientRef.current)
    }
  }, [renderedPatients])

  if (!queriedPatients.length) return <BlankPatientsList />;

  return (
    <section className="patients">
      { renderedPatients.map((patient, renderIndex) => {
        const {
          id,
          name,
          uuid,
          photo,
          birthday,
          last_sign_in_at,
          invitations_pending,
          new_patient,
          unconfirmed_self_registrations_pending,
          medical_record_number,
          thrive_id,
          archived
        } = patient;

        const isLastPatient = renderIndex === renderedPatients.length - 1;

        const path = `/patients/${uuid}`;

        return (
          <div key={id} ref={isLastPatient ? lastPatientRef : null}>
            <Patient
              name={name}
              uuid={uuid}
              path={path}
              photo={photo}
              birthday={birthday}
              lastSignInAt={last_sign_in_at}
              invitationsPending={invitations_pending}
              newPatient={new_patient}
              selfRegCarePlan={unconfirmed_self_registrations_pending}
              medicalRecordNumber={medical_record_number}
              thriveId={thrive_id}
              archived={archived}
            />
          </div>
        );
      })
    }
    </section>
  );
};

const mapStateToProps = ({ placeUsersPatientsIndexPage }) => {
  const { patients, sortBy, searchInput, myPatientIds, isShowingMyPatients, archivedPatientIds, isShowingArchivedPatients } = placeUsersPatientsIndexPage;

  return { patients, sortBy, searchInput, myPatientIds, isShowingMyPatients, archivedPatientIds, isShowingArchivedPatients };
};

export default connect(mapStateToProps, null)(PatientsList);
