import React from 'react';
import { t } from 'i18n';
import ActionButton from 'Components/Base/ActionButton';

const i18nOpts = { scope: 'dashboard.device' };

const ConsentMissing = () => {
  const redirectToConsentRequest = () => window.location.assign('/oauth/identos/devices_request');

  return (
    <div className="stats-highlight__error stats-highlight__error--primary">
      <div className="stats-highlight__error__header">{t('consent_missing_patient_message', i18nOpts)}</div>
      <ul>
        <li>
          <div className="stats-highlight__number stats-highlight__number--large">
            {t('number_placeholder', i18nOpts)}
          </div>
          <div className="stats-highlight__header">{t('average_glucose', i18nOpts)}</div>
        </li>
        <li>
          <div className="stats-highlight__number stats-highlight__number--large">
            {t('percent_placeholder', i18nOpts)}
          </div>
          <div className="stats-highlight__header">{t('gmi', i18nOpts)}</div>
        </li>
        <li>
          <div className="stats-highlight__number stats-highlight__number--large">
            {t('percent_placeholder', i18nOpts)}
          </div>
          <div className="stats-highlight__header">{t('cgm_active', i18nOpts)}</div>
        </li>
      </ul>
      <div className="stats-highlight__error__cta">
        <ActionButton
          content="Connect devices"
          onClick={redirectToConsentRequest}
        />
      </div>
    </div>
  );
};

export default ConsentMissing;
