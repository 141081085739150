import LibraryDocumentCopiesApi from 'Api/CarePlanCreator/LibraryDocumentCopies'
import LibraryDocumentTemplateCopiesApi from 'Api/TemplateCreator/LibraryDocumentTemplateCopies'

class LibraryDocumentCopyDispatcher {
  constructor(parentId, isTemplate) {
    this.parentId = parentId
    this.isTemplate = isTemplate
  }

  update(data) {
    return this.api.update(data)
  }

  get api() {
    if (this.isTemplate) {
      return new LibraryDocumentTemplateCopiesApi(this.parentId)
    } else {
      return new LibraryDocumentCopiesApi(this.parentId)
    }
  }
}

export default LibraryDocumentCopyDispatcher
