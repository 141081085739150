import getSessionInfoApi from './getSessionInfoApi';

export const handleSessionInfoSetupWrapper = (getSessionInfoApiProvider = getSessionInfoApi) => {
  return async (setSessionInfoState, setSessionInfoData, carePlanId) => {
    setSessionInfoState('LOADING');

    try {
      const { responseType, canAdd, timeZone, patientFirstName, locale } = await getSessionInfoApiProvider(
        carePlanId
      );

      if (responseType === 'DATA') {
        setSessionInfoData({ canAdd, timeZone, patientFirstName, locale });
        setSessionInfoState('MAIN_CONTENT');
        return true;
      } else {
        throw 'Could not get session info.';
      }
    } catch (error) {
      setSessionInfoState('ERROR');
      console.error(error);
      return false;
    }
  };
};

export default handleSessionInfoSetupWrapper(getSessionInfoApi);
