import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, Icon, IconButton, ModalFooter } from 'Components/Base';

const i18nOptsModal = { scope: 'team.modal' };
const i18nOptsCode = { scope: 'team.invite_code_modal' };

const InviteCodeModal = ({
  teamType,
  startUrl,
  requestAccessToken,
  uhcpAccessToken,
  invitationLink,
  copied,
  onInviteTypeSet,
  onCancel,
  onCopy,
}) => (
  <div>
    <h2 className="invite-code-modal__title">
      {t(`${teamType}_team_invite_title`, { ...i18nOptsModal, inviteType: 'code' })}
    </h2>

    <div className="invite-code-modal__header-title text-medium">{t('title', i18nOptsCode)}</div>

    <div className="invite-code-modal__header-subtitle">{t('subtitle', i18nOptsCode)}</div>

    <div className="invite-code-modal__header-title text-medium">{t('first_option', i18nOptsCode)}</div>

    <div
      className="invite-code-modal__header-subtitle"
      dangerouslySetInnerHTML={{
        __html: t('first_option_subtitle', {
          ...i18nOptsCode,
          link: startUrl,
        }),
      }}
    />

    <div className="invite-code-modal__code text-medium">
      {teamType === 'health' ? uhcpAccessToken : requestAccessToken}
    </div>

    <div className="invite-code-modal__header-title text-medium">{t('second_option', i18nOptsCode)}</div>

    <div className="invite-code-modal__header-subtitle">{t('second_option_subtitle', i18nOptsCode)}</div>

    <div className="invite-code-modal__copy-row">
      <input readOnly type="text" value={invitationLink} className="invite-code-modal__code-input" />

      <CopyToClipboard text={invitationLink} onCopy={onCopy}>
        <IconButton
          iconName="link_copy"
          className="invite-code-modal__code-button"
          text={t('get_link', i18nOptsCode)}
        />
      </CopyToClipboard>
    </div>

    <div className="invite-code-modal__button-footer">
      <Icon name="or" className="icon_or invite-code-modal__or-icon" />
    </div>

    <div className="invite-code-modal__invite-type">
      <Button
        data-test="add"
        className="btn btn--link invite-code-modal__invite-type-change-button"
        onClick={onInviteTypeSet}
      >
        {t('invite_by_email', { ...i18nOptsModal })}
      </Button>
    </div>

    <ModalFooter
      className="invite-code-modal__footer"
      renderRight={() => (
        <Button text={t('done', i18nOptsCode)} className="btn btn--secondary" onClick={onCancel} />
      )}
      renderLeft={() =>
        copied && (
          <div className="invite-code-modal__copied">
            <span>
              <Icon name="check" className="icon_or invite-code-modal__check-icon" />
              <span className="invite-code-modal__copied-title">{t('link_copied', i18nOptsCode)}</span>
            </span>

            <div className="invite-code-modal__copied-link">{invitationLink}</div>
          </div>
        )
      }
    />
  </div>
);

InviteCodeModal.propTypes = {
  copied: PropTypes.bool.isRequired,
  invitationLink: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  onInviteTypeSet: PropTypes.func.isRequired,
  requestAccessToken: PropTypes.string,
  startUrl: PropTypes.string.isRequired,
  teamType: PropTypes.string,
  uhcpAccessToken: PropTypes.string,
};

InviteCodeModal.defaultProps = {
  requestAccessToken: null,
  teamType: '',
  uhcpAccessToken: null,
};

export default InviteCodeModal;
