import React, { Component } from 'react';
import Select from 'react-select';
import MultiSelectStyle from 'styles/MultiSelect';

class AssignableInput extends Component {
  constructor(props) {
    super(props);

    this.state = { selectedOptions: props.selectedOptions };
  }

  handleChange(selectedOptions, { action, removedValue }) {
    switch (action) {
      case 'remove-value':
      case 'pop-value':
        if (removedValue.readOnly) {
          return;
        }
        break;
    }

    if (selectedOptions === null) {
      selectedOptions = [];
    }

    this.setState({ selectedOptions });
  }

  orderOptions(values) {
    if (values === null) {
      return values;
    }

    // read only options appear first
    return values.sort((a, b) => (a.readOnly < b.readOnly ? 1 : -1));
  }

  render() {
    return (
      <div>
        <input type="hidden" name={`${this.props.model}[assignments][]`} />

        <Select
          name={`${this.props.model}[assignments][][id]`}
          styles={MultiSelectStyle}
          value={this.orderOptions(this.state.selectedOptions)}
          className="multi-select"
          onChange={this.handleChange.bind(this)}
          isMulti
          options={this.props.options}
          isClearable={false}
          maxMenuHeight={160}
        />
      </div>
    );
  }
}

AssignableInput.defaultProps = {
  selectedOptions: [],
};

export default AssignableInput;
