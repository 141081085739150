export const setupDescriptionValidator = (formErrors, setActivityModalFormErrors, t, i18nOpts) => {
  return (description) => {
    if (!description) {
      setActivityModalFormErrors({ ...formErrors, description: t('error_empty', i18nOpts) });
      return false;
    } else {
      setActivityModalFormErrors({ ...formErrors, description: '' });
      return true;
    }
  };
};

export const setupVirtualVisitLinkValidator = (formErrors, setActivityModalFormErrors, t, i18nOpts) => {
  const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

  const httpPresent = (url) => {
    return url.includes('http://') || url.includes('https://');
  };

  return (virtualVisitLink) => {
    if (!virtualVisitLink || (urlRegex.test(virtualVisitLink) && httpPresent(virtualVisitLink))) {
      setActivityModalFormErrors({ ...formErrors, virtualVisitLink: '' });
      return true;
    }

    if (!urlRegex.test(virtualVisitLink)) {
      setActivityModalFormErrors({ ...formErrors, virtualVisitLink: t('invalid_link', i18nOpts) });
      return false;
    }

    // temporary check if link includes http or https
    if (!httpPresent(virtualVisitLink)) {
      setActivityModalFormErrors({ ...formErrors, virtualVisitLink: t('missing_http', i18nOpts) });
      return false;
    }
  };
};
