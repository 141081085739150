import { request } from 'Apiv2/handleRequest';

export const updatePreferencesApiWrapper = (apiRequest = request) => {
  return async ({ payload }) => {
    try {
      const ENDPOINT_URL = `/patients/update_preferences`
      const response = await apiRequest(ENDPOINT_URL, 'PATCH', payload);
      const { data } = await response.json();

      if (response.status !== 200) {
        throw new Error('Could not update preferences');
      }

      return {
        content_type: 'SUCCESS',
        data,
      };
    } catch (error) {
      return {
        content_type: 'ERROR',
        error
      };
    }
  };
};

export default updatePreferencesApiWrapper(request);