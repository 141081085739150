import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import Address from '../FormFields/Address';
import FormField from '../FormFields/FormField';
import DOBPicker from 'Components/Forms/DOBPicker';
import Icon from 'Components/Base/Icon';
import ActionButton from 'Components/Base/ActionButton';
import FooterWithCareHub from './FooterWithCareHub';

const i18nOpts = { scope: 'patient.edit.index' };

const Patient = ({
  patient,
  canInvite,
  updateCareHub,
  isCareHubPatient,
  canManageInvitation,
  audits,
  locale,
  onChange,
  onSubmit,
  formErrors,
  submitError,
  isProcessing
}) => {
  const [patientEmail] = useState(patient?.email);
  const shouldShowInvitationMessage = patient?.email && patientEmail !== patient?.email;

  return <form className="form form--background" onSubmit={onSubmit}>
    <div className="form__row">
      <FormField
        className='input'
        name="patient.firstName"
        value={patient.firstName}
        audit={audits.firstName}
        formErrors={formErrors}
        onChange={onChange}
        label={t('first_name', i18nOpts)}
        icon={isCareHubPatient ? <Icon name="sync" className="sync-icon" /> : null}
      />

      <FormField
        className='input'
        name="patient.lastName"
        value={patient.lastName}
        audit={audits.lastName}
        formErrors={formErrors}
        onChange={onChange}
        label={t('last_name', i18nOpts)}
        icon={isCareHubPatient ? <Icon name="sync" className="sync-icon" /> : null}
      />
    </div>

    <div className="form__row">
      <div className="input">
        <label htmlFor='patient.gender'>
          {t('gender', i18nOpts)}
        </label>
        <select
          name='patient.gender'
          value={patient.gender || ''}
          onChange={onChange}
        >
          <option key="blank" value="">
            {t('select', i18nOpts)}
          </option>
          {['female', 'male', 'other'].map((opt) => (
            <option key={opt} value={opt}>{t(opt, i18nOpts)}</option>
          ))}
        </select>
      </div>

      <FormField
        className='input'
        name="patient.patientHealthInformation.medicalRecordNumber"
        value={patient.patientHealthInformation.medicalRecordNumber}
        audit={audits.patientHealthInformation.medicalRecordNumber}
        formErrors={formErrors}
        onChange={onChange}
        label={t('medical_record_number', i18nOpts)}
      />
    </div>

    <div className="input--half">
      <label htmlFor='patient.birthday'>
        {t('birthday', i18nOpts)}
      </label>

      <DOBPicker
        className="input"
        name="patient.birthday"
        id="patient.birthday"
        value={patient.birthday}
        audit={audits.birthday}
        onChange={onChange}
      />
    </div>

    <hr />
    <h4 className="patient_information__subheader">{t('contact_information', i18nOpts)}</h4>

    <label htmlFor='patient.email' className="inline-icon-label">
      {t('email', i18nOpts)} {isCareHubPatient && <Icon name="sync" className="sync-icon" />}
    </label>
    <div className="form__row row patient-email-row">
      <div className="row__col patient-email-row__email-input">
        <FormField
          name="patient.email"
          value={patient.email}
          formErrors={formErrors}
          onChange={onChange}
        />
      </div>
      {(canManageInvitation || canInvite) && <div className="row__col patient-email-row__email-remove-btn">
        <a
          className="patient-email-row__email-input__remove"
          onClick={() => {
            onChange({ target: { name: "patient.email", value: "" } });
          }}
        >
          <Icon name="trash" />
        </a>
      </div>}
    </div>
    <div className='patient-email__invitation-message'>
      {(canManageInvitation || canInvite) && shouldShowInvitationMessage && (
        <div className='invitation-message'>{t('invitation_message', i18nOpts)}</div>
      )}
    </div>

    <div className="form__row">
      <FormField
        name="patient.preferredPhoneNumber.number"
        value={patient.preferredPhoneNumber.number}
        type="tel"
        errors={formErrors}
        onChange={onChange}
        label={t('phone_number', i18nOpts)}
      />
    </div>

    <hr />
    <label className="patient_information__subheader">{t('address', i18nOpts)}</label>

    <Address
      address={patient.preferredAddress}
      name='patient.preferredAddress'
      onChange={onChange}
      locale={locale}
      formErrors={formErrors}
    />

    <hr />
    <h4 className="patient_information__subheader">{t('primary_physician.header', i18nOpts)}</h4>

    <FormField
      name="patient.patientHealthInformation.primaryPhysician"
      value={patient.patientHealthInformation.primaryPhysician}
      audit={audits.patientHealthInformation.primaryPhysician}
      placeholder={t('primary_physician.placeholder', i18nOpts)}
      onChange={onChange}
      formErrors={formErrors}
    />

    <hr />

    {isCareHubPatient
      ?
      <FooterWithCareHub
        updateCareHub={updateCareHub}
        submitError={submitError}
        isProcessing={isProcessing}
        onChange={onChange}
      />
      :
      <div className='form__submit'>
        <ActionButton content={t('submit', i18nOpts)} isLoading={isProcessing} />
      </div>
    }
  </form>;
};

Patient.propTypes = {
  // Patient properties are delegated to the associated demographic
  audits: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    gender: PropTypes.string,
    birthday: PropTypes.string,
    patientHealthInformation: PropTypes.shape({
      primaryPhysician: PropTypes.string,
      medicalRecordNumber: PropTypes.string,
    }),
  }),
  canInvite: PropTypes.bool,
  canManageInvitation: PropTypes.bool,
  formErrors: PropTypes.object,
  isProcessing: PropTypes.bool,
  submitError: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  patient: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    gender: PropTypes.string,
    birthday: PropTypes.string,
    patientHealthInformation: PropTypes.shape({
      primaryPhysician: PropTypes.string,
      medicalRecordNumber: PropTypes.string,
    }),
    preferredAddress: PropTypes.shape({
      label: PropTypes.string,
      address: PropTypes.string,
      extendedAddress: PropTypes.string,
      city: PropTypes.string,
      region: PropTypes.string,
      postalCode: PropTypes.string,
      countryCode: PropTypes.string,
    }),
    preferredPhoneNumber: PropTypes.shape({
      label: PropTypes.string,
      number: PropTypes.string,
    }),
  }),
};

Patient.defaultProps = {
  audits: {
    additionalUserData: {},
    patientHealthInformation: {},
  },
  canManageInvitation: false,
  formErrors: {},
  isProcessing: false,
  submitError: false,
  patient: {
    additionalUserData: {},
    patientHealthInformation: {},
    preferredAddress: {},
    preferredPhoneNumber: {},
  },
};

export default Patient;
