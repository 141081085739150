import React from 'react';

import ProviderWrapper from 'Components/Base/ProviderWrapper';
import SignupsContainer from 'Components/CarePlanPlaceInvitationsV2/SignupsContainer';

const CarePlanPlaceInvitationsV2 = () => {
  return (
    <ProviderWrapper>
      <SignupsContainer />
    </ProviderWrapper>
  );
};

export default CarePlanPlaceInvitationsV2;
