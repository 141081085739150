import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { debounce } from 'lodash';

import { Loader } from 'Components/Base';
import Filters from './Filters';
import Header from './Header';
import ContactList from './ContactList';
import ChannelHeader from './ChannelHeader/ChannelHeader';
import ChannelList from './ChannelList';
import ChannelPaginator from './ChannelPaginator';

import { fetchAllContacts } from 'Actions/inbox/sideNav/newContacts';
import { fetchCarePlans } from 'Actions/inbox/sideNav/carePlans';
import { fetchChannels } from 'Actions/inbox/sideNav/channel';
import { sortBy, search, fetchChannelSearch, updateChannelSortBy } from 'Actions/inbox/sideNav/general';
import { fetchNextChannelContactsPage, fetchSortedChannelContacts } from 'Actions/inbox/sideNav/contacts';

import {
  fetchConversation,
  startNewDeskConversation,
  receiveNewConversation,
} from 'Actions/inbox/conversation';

import { markConversationAsRead } from 'Actions/inbox/sideNav/contacts';

import {
  getChannelContacts,
  getChannelSearchContacts,
  checkMissingSearchResult,
  getConversation,
  getDeskContacts,
  getDirectContacts,
  getMemberContacts,
  getSideNavOpenState,
  getSideNavFetchingState,
  getSideNavFetchingChannelPageState,
  getHasMoreChannelPage,
  getIsSearchingForChannel,
  getChannelSortBy,
} from 'Selectors/inbox';

import { getCurrentUser } from 'Selectors/currentUser';

class ContactsContainer extends Component {
  constructor(props) {
    super(props);

    this.renderLists = this.renderLists.bind(this);
    this.setContactListRef = (element) => {
      this.contactListRef = element;
    };

    // debouncedFetchChannelSearch:
    // * need to initialize `debounce` here because if you initalize it outside the component and pass it to `mapDispatchToProps`,
    //   it won't work because `mapDispatchToProps` will reinitialize it repeatedly, causing the timeouts to null, so the target function
    //   will never get called
    // * need to pass event.target.value here instead of `mapDispatchToProps` because
    //   react 16 and earlier reuses `SyntheticEvent` objects by pooling them. if you try to access a `SyntheticEvent` asynchronously,
    //   which we have to do in debounce, the event object will no longer exist. So we have to pass the event.target.value here
    this.debouncedFetchChannelSearch = debounce(this.props.fetchChannelSearch, 500);
    this.debouncedSearch = (e) => {
      if (e.currentTarget) {
        this.debouncedFetchChannelSearch(e.currentTarget.value);
      } else {
        this.debouncedFetchChannelSearch('');
      }
      this.props.search(e);
    };

    this.updateSortByAndFetch = (e) => {
      this.props.sortBy(e);
      this.props.updateChannelSortBy(e.currentTarget.value);
      this.props.fetchSortedChannelContacts(e.currentTarget.value);
    };
  }

  componentDidMount() {
    // Scroll to the current contact if it exists
    if (this.contactListRef) {
      const currentContact = this.contactListRef.querySelector('.inbox-menu__contact--current');

      if (currentContact) {
        const { y: contactListPosition } = this.contactListRef.getBoundingClientRect();
        const { y: currentContactPosition } = currentContact.getBoundingClientRect();
        const listHeaderHeight = 60;

        this.contactListRef.scrollTo(0, currentContactPosition - contactListPosition - listHeaderHeight);
      }
    }
  }

  renderLists() {
    const {
      deskContacts,
      directContacts,
      memberContacts,
      channelContacts,
      channelSearchContacts,
      newConversation,
      conversationId,
      openConversation,
      isFetchingChannelPage,
      hasMoreChannelPage,
      fetchNextChannelContactsPage,
      isSearchingForChannel,
      channelSortBy,
      missingSearchResult,
      currentUser: { channelsEnabled, desksEnabled, directMessagesEnabled, isPlaceUser },
    } = this.props;

    const contactProps = { conversationId, openConversation };

    const filteredDeskContact = deskContacts.filter((c) => c.deskMessagingEnabled);

    return (
      <Fragment>
        {/* Non-health care Desk section */}
        {!!filteredDeskContact.length && (
          <div>
            <Header type="desk" />
            <ContactList contacts={filteredDeskContact} {...contactProps} />
          </div>
        )}

        {/* Channels */}
        {channelsEnabled && (
          <Fragment>
            <ChannelHeader />
            <ChannelList
              channels={channelContacts}
              channelSearchContacts={channelSearchContacts}
              conversationId={conversationId}
              openConversation={openConversation}
              isSearchingForChannel={isSearchingForChannel}
              missingSearchResult={missingSearchResult}
            />
            {/* Only activate pagination for PlaceUsers */}
            {isPlaceUser && (
              <ChannelPaginator
                isFetchingChannelPage={isFetchingChannelPage}
                hasMoreChannelPage={hasMoreChannelPage}
                fetchNextChannelContactsPage={fetchNextChannelContactsPage}
                isSearchingForChannel={isSearchingForChannel}
                channelSortBy={channelSortBy}
                lastChannelContact={channelContacts[channelContacts.length - 1]}
              />
            )}
          </Fragment>
        )}

        {/* Health care Desk conversations*/}
        {desksEnabled && (
          <Fragment>
            <Header onClick={newConversation.bind(null, isPlaceUser)} type="health_care_direct" />
            <ContactList contacts={memberContacts} type="health_care_direct" {...contactProps} />
          </Fragment>
        )}

        {/*Non-health care direct messages*/}
        {directMessagesEnabled && (
          <Fragment>
            <Header type="direct" />
            <ContactList contacts={directContacts} type="direct" {...contactProps} />
          </Fragment>
        )}
      </Fragment>
    );
  }

  render() {
    const { currentUser, isOpen, isFetching, sortBy, search } = this.props;

    const searchFunc = currentUser.channelsEnabled ? this.debouncedSearch : search;
    const sortFunc = currentUser.channelsEnabled ? this.updateSortByAndFetch : sortBy;

    return (
      <aside className={`inbox-menu ${isOpen && 'inbox-menu--open'}`}>
        {currentUser.isPlaceUser && <Filters search={searchFunc} sort={sortFunc} />}

        <section className="inbox-menu__contacts">
          <div className="inbox-menu__contacts-scroll" ref={this.setContactListRef}>
            {isFetching ? <Loader /> : this.renderLists()}
          </div>
        </section>
      </aside>
    );
  }
}

ContactsContainer.propTypes = {
  channelSortBy: PropTypes.string,
  conversationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currentUser: PropTypes.shape({
    channelsEnabled: PropTypes.bool,
    desksEnabled: PropTypes.bool,
    directMessagesEnabled: PropTypes.bool,
    isPlaceUser: PropTypes.bool,
  }),
  deskContacts: PropTypes.array,
  directContacts: PropTypes.array,
  fetchChannelSearch: PropTypes.func.isRequired,
  fetchNextChannelContactsPage: PropTypes.func.isRequired,
  fetchSortedChannelContacts: PropTypes.func.isRequired,
  hasMoreChannelPage: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isFetchingChannelPage: PropTypes.bool.isRequired,
  isSearchingForChannel: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  memberContacts: PropTypes.array,
  newConversation: PropTypes.func.isRequired,
  openConversation: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  sortBy: PropTypes.func.isRequired,
  updateChannelSortBy: PropTypes.func.isRequired,
};

ContactsContainer.defaultProps = {
  conversationId: '',
  currentUser: {
    isPlaceUser: false,
    channelsEnabled: false,
    desksEnabled: false,
    directMessagesEnabled: false,
  },
  deskContacts: [],
  directContacts: [],
  memberContacts: [],
};

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  conversationId: getConversation(state).id,
  channelContacts: getChannelContacts(state),
  channelSearchContacts: getChannelSearchContacts(state),
  missingSearchResult: checkMissingSearchResult(state),
  currentUser: getCurrentUser(state),
  deskContacts: getDeskContacts(state),
  directContacts: getDirectContacts(state),
  isOpen: getSideNavOpenState(state),
  isFetching: getSideNavFetchingState(state),
  isFetchingChannelPage: getSideNavFetchingChannelPageState(state),
  memberContacts: getMemberContacts(state),
  hasMoreChannelPage: getHasMoreChannelPage(state),
  isSearchingForChannel: getIsSearchingForChannel(state),
  channelSortBy: getChannelSortBy(state),
});

const mapDispatchToProps = (dispatch) => ({
  sortBy: ({ target: { value } }) => dispatch(sortBy(value)),
  search: ({ target: { value } }) => dispatch(search(value)),
  fetchChannelSearch: (value) => dispatch(fetchChannelSearch(value)),
  updateChannelSortBy: (value) => dispatch(updateChannelSortBy(value)),
  openConversation: (conversation) => {
    if (conversation.type === 'DESK_CONVERSATION') {
      dispatch(startNewDeskConversation(conversation));
    } else if (conversation.type === 'NEW_DIRECT_MESSAGE' || conversation.type === 'NEW_CHANNEL') {
      dispatch(receiveNewConversation(conversation));
    } else {
      dispatch(fetchConversation(conversation.id));
      dispatch(markConversationAsRead(conversation.id));
    }
  },
  newConversation: (isPlaceUser) => {
    if (isPlaceUser) {
      dispatch(fetchCarePlans());
    } else {
      dispatch(fetchAllContacts());
    }
  },
  fetchNextChannelContactsPage: (page_options) => dispatch(fetchNextChannelContactsPage(page_options)),
  fetchSortedChannelContacts: (sortBy) => dispatch(fetchSortedChannelContacts(sortBy)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactsContainer);
