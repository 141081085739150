import React from 'react'

import ProviderWrapper from 'Components/Base/ProviderWrapper'
import SupportTeamContainer from './SupportTeamContainer'

const SupportTeam = (props) => {
  return (
    <ProviderWrapper>
      <SupportTeamContainer {...props} />
    </ProviderWrapper>
  )
}

export default SupportTeam
