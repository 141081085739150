import React, { useContext } from 'react';

import { t } from 'i18n';
import Card from 'Components/Base/Card';
import ErrorDisplay from 'Components/Base/Dashboard/ErrorDisplay';
import Highlight from 'Components/Base/Highlight';
import ActionButton from 'Components/Base/ActionButton';
import { CarePlanContext } from 'Components/Dashboard/Dashboard';
import ConnectionFailed from '../InsulinPumpCard/Error/ConnectionFailed';

const i18nOpts = { scope: 'dashboard' };

const Error = ({ status }) => {
  const redirectToConsentRequest = () => window.location.assign('/oauth/identos/devices_request');

  const {
    userDetails: { patientFirstName, isPlaceUser },
  } = useContext(CarePlanContext);

  if (isPlaceUser && status === 'FAILURE_MISSING_ACCESS_TOKEN') {
    return (
      <Card title={t('insulin_pump_settings', i18nOpts)} id={'insulin_pump_settings'}>
        <Highlight
          type="warning"
          message={t('device.consent_missing_hcp_message', { ...i18nOpts, firstName: patientFirstName })}
        />
      </Card>
    );
  }

  switch (status) {
    case 'ERROR_PUMP_SETTINGS_EMPTY':
      return (
        <Card title={t('insulin_pump_settings', i18nOpts)} id={'insulin_pump_settings'}>
          <div className="item-pill-section--item-list-empty">{t('device.no_data_present', i18nOpts)}</div>
        </Card>
      );
    case 'FAILURE_MISSING_FHIR_TOKEN':
      return (
        <Card title={t('insulin_pump_settings', i18nOpts)} id={'insulin_pump_settings'}>
          <div className="item-pill-section--item-list-empty">
            {t('device.no_user_accepted_error', { ...i18nOpts, firstName: patientFirstName })}
          </div>
        </Card>
      );
    case 'FAILURE_MISSING_ACCESS_TOKEN':
      return (
        <Card title={t('insulin_pump_settings', i18nOpts)} id={'insulin_pump_settings'}>
          <Highlight
            type="success-alert"
            message={
              <>
                <span>{t('device.consent_missing_patient_message', i18nOpts)}</span>
                <span>
                  <ActionButton
                    content="Connect devices"
                    onClick={redirectToConsentRequest}
                  />
                </span>
              </>
            }
          />
        </Card>
      );
    case 'FAILURE_DATA_RETRIEVAL_FAILED':
      return (
        <Card title={t('insulin_pump_settings', i18nOpts)} id={'insulin_pump_settings'}>
          <ConnectionFailed />
        </Card>
      );
    default:
      return <ErrorDisplay title={t('insulin_pump_settings', i18nOpts)} status={status} />;
  }
};

export default Error;
