import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Formik } from 'formik'

import { Card } from 'Components/Base'
import { Form } from './'

import HealthTeamContactApi from 'Api/CarePlan/HealthTeamContact'

const i18nOpts = { scope: 'team.health_team.edit_health_team' }

class EditHealthTeamContacts extends Component {
  constructor(props) {
    super(props)

    this.handleToggleList = this.handleToggleList.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.state = {
      isOpen: true,
    }
  }

  get unselectedMembers() {
    const selectedIds = this.props.selectedMembers.map(({ ownerId }) => ownerId)
    return this.props.invitableMembers.filter(({ ownerId }) => !selectedIds.includes(ownerId))
  }

  get selectedMembers() {
    const selectedIds = this.props.selectedMembers.map(({ ownerId }) => ownerId)
    return this.props.invitableMembers.filter(({ ownerId }) => selectedIds.includes(ownerId))
  }

  handleToggleList() {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }))
  }

  handleSwapFields(setValues, values, key, member) {
    const newKey = {
      selectedMembers: 'unselectedMembers',
      unselectedMembers: 'selectedMembers',
    }[key]

    setValues({
      [key]: values[key].filter((value) => value.ownerId !== member.ownerId),
      [newKey]: [...values[newKey], member],
    })
  }

  async handleSubmit({ selectedMembers, unselectedMembers }, { setSubmitting }) {
    setSubmitting(true)

    const mapToParams = (selected, member) => ({ ownerId: member.ownerId, selected })
    const params = [
      ...selectedMembers.map(mapToParams.bind(null, true)),
      ...unselectedMembers.map(mapToParams.bind(null, false)),
    ]

    try {
      const api = new HealthTeamContactApi(this.props.carePlanUuid);
      const response = await api.update(params);

      if (!response.ok) {
        throw new Error('api error');
      }

      const data = await response.json();

      window.flash_messages.addMessage(t('success', i18nOpts))
      return this.props.onSuccess(data.members);
    } catch(err) {
      window.flash_messages.addMessage(t('error', i18nOpts))
      setSubmitting(false)
    }
  }

  render() {
    return (
      <Card title={t('header', i18nOpts)}>
        <h5 className="text-primary-lighter">{t('subheader', i18nOpts)}</h5>

        <Formik
          initialValues={{
            selectedMembers: this.selectedMembers,
            unselectedMembers: this.unselectedMembers,
          }}
          onSubmit={this.handleSubmit}
          render={({ values, setValues, isSubmitting }) => (
            <Form
              isOpen={this.state.isOpen}
              isSubmitting={isSubmitting}
              onToggleList={this.handleToggleList}
              onCancel={this.props.onCancel}
              placeName={this.props.placeName}
              onChange={this.handleSwapFields.bind(null, setValues)}
              values={values}
            />
          )}
        />
      </Card>
    )
  }
}

EditHealthTeamContacts.propTypes = {
  carePlanUuid: PropTypes.string,
  invitableMembers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      isCarePlanCreator: PropTypes.bool.isRequired,
      location: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      ownerId: PropTypes.number.isRequired,
      path: PropTypes.string.isRequired,
      phoneNumber: PropTypes.string,
      photo: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  placeName: PropTypes.string,
  selectedMembers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      isCarePlanCreator: PropTypes.bool.isRequired,
      location: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      ownerId: PropTypes.number.isRequired,
      path: PropTypes.string.isRequired,
      phoneNumber: PropTypes.string,
      photo: PropTypes.string,
      title: PropTypes.string,
    })
  ),
}

EditHealthTeamContacts.defaultProps = {
  carePlanUuid: '',
  invitableMembers: [],
  placeName: '',
  selectedMembers: [],
}

export default EditHealthTeamContacts
