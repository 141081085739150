import Resource from './Api'

class DeferredImage extends Resource {
  constructor() {
    super()
  }

  retrieveImageUrl(imageEntitlementToken) {
    return super.request(`${this.url}/retrieve_image_url`, 'POST', {
      imageEntitlementToken,
    })
  }

  get url() {
    return `/deferred_images`
  }
}

export default DeferredImage
