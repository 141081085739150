import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Chip } from 'Components/Base'

const i18nOpts = { scope: 'patient.show.phone_number' }

const PhoneNumber = ({ label, number, edit, editable }) => (
  <dl className="patient-details__additional-detail">
    <dd>
      {number}
      {editable && (
        <span className="patient-details__edit-additional-detail">
          - <a onClick={edit}>{t('edit', i18nOpts)}</a>
        </span>
      )}
      <Chip
        color="light-grey"
        text={t(label, { scope: 'patient.show.phone_number', defaultValue: t('unknown', i18nOpts) })}
      />
    </dd>
  </dl>
)

PhoneNumber.propTypes = {
  edit: PropTypes.func,
  editable: PropTypes.bool,
  label: PropTypes.string,
  number: PropTypes.string.isRequired,
}

PhoneNumber.defaultProps = {
  edit: null,
  editable: false,
  label: 'mobile',
}

export default PhoneNumber
