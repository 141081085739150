import { request } from 'Apiv2/handleRequest';

export const getSessionInfoApiWrapper = (requestProvider = request) => {
  return async (carePlanId) => {
    try {
      const response = await requestProvider(
        `/care_plans/${carePlanId}/tasks/weekly/session_info`,
        'GET'
      );

      const status = response.status;
      const { time_zone, locale } = await response.json();

      if (status === 200) {
        return {
          responseType: 'DATA',
          timeZone: time_zone,
          locale,
        };
      } else {
        throw 'Could not get session information.';
      }
    } catch (error) {
      console.error(error);
      return { responseType: 'API_ERROR' };
    }
  };
};

export default getSessionInfoApiWrapper(request);
