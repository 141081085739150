import React, { Component } from 'react'
import _ from 'lodash'
import { t } from 'i18n'

import AddressApi from '../../../api/Patient/Address'

import Address from '../FormFields/Address';

const i18nOpts = { scope: 'patient.edit.address' };

class AddressForm extends Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleErrors = this.handleErrors.bind(this)

    this.state = {
      address: props.address,
      errors: {},
    }
  }

  handleChange({ target: { name, value } }) {
    if (name == 'address.countryCode' && value == 'GB') {
      this.setState((prevState) => ({
        ...prevState,
        address: {
          ...prevState.address,
          region: '',
          countryCode: value
        }
      }))
    } else {
      this.setState((prevState) => _.set(prevState, name, value))
    }
  }

  handleSubmit(evt) {
    evt.preventDefault()
    const { carePlanUuid, onSuccess } = this.props
    const { address } = this.state

    new AddressApi(carePlanUuid, address.id).update(address).then((res) => {
      if (res.ok) {
        res.json().then(onSuccess)
      } else if (res.status === 422) {
        res.json().then(this.handleErrors)
      }
    })
  }

  handleErrors(errors) {
    this.setState({ errors })
  }

  render() {
    const { address, errors } = this.state
    const { locale } = this.props

    return (
      <form className="form form--background" onSubmit={this.handleSubmit}>
        <label className="patient_information__subheader">{t('address', i18nOpts)}</label>

        <Address
          address={address}
          name='address'
          onChange={this.handleChange}
          locale={locale}
          formErrors={errors}
        />

        <div className="form__submit">
          <input className='btn' type="submit" value={t('save', i18nOpts)} />
        </div>
      </form>
    )
  }
}

export default AddressForm;
