import React from 'react'
import PropTypes from 'prop-types'

const Button = ({ text, children, ...rest }) => <button {...rest}>{text || children}</button>

Button.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string,
}

Button.defaultProps = {
  type: 'button',
  className: 'btn',
  text: '',
}

export default Button
