import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import { setActivityModalAssignmentsDropdownIsOpen } from 'Actions/activityModal';

import DotsLoader from 'Components/Base/DotsLoader';
import Backdrop from 'Components/Base/Backdrop';
import OpenButton from './OpenButton';
import AssignmentsMenu from './AssignmentsMenu';
import AssignmentsList from './AssignmentsList';

const i18nOpts = { scope: 'activity_modal.form_fields.who' };

export const Who = ({
  assignmentsDropdown,
  setActivityModalAssignmentsDropdownIsOpen,
}) => {
  /**
   * If any other activityType modification is required in the future
   * then those should be extacted into their own respective activity
   * component such as Appointment, Task, etc. as follows:
   *
   * <WhoDescription />
   * <Who />
   * <WhoNotification />
   *
   */
  const { isOpen, assignmentsDropdownState } = assignmentsDropdown;

  let content;
  switch (assignmentsDropdownState) {
    case 'LOADING':
      content = (
        <div className="assignments_dropdown">
          <DotsLoader />
        </div>
      );
      break;
    case 'ERROR':
      content = (
        <div className="assignments_dropdown assignments_dropdown--error">
          {t('error_contacts_load', i18nOpts)}
        </div>
      );
      break;
    case 'DEFAULT':
      content = <AssignmentsMenu />;
      break;
    default:
      content = (
        <div className="assignments_dropdown assignments_dropdown--error">
          {t('error_contacts_load', i18nOpts)}
        </div>
      );
      break;
  }

  return (
    <>
      <div className="activity_modal__input_field activity_modal__input_field--assignments">
        <div className="activity_modal__input_field__label activity_modal__input_field__label--assignments">
          {t('title', i18nOpts)}
        </div>
        <div className="activity_modal__input_field__container">
          <div className="activity_modal__assignments">
            <AssignmentsList />
            <OpenButton />
          </div>
          {isOpen && (
            <div className="activity_modal__assignments__menu_container">
              {content}
              <Backdrop
                transparent
                onClick={() => {
                  setActivityModalAssignmentsDropdownIsOpen(false);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ activityModal }) => {
  const { assignmentsDropdown } = activityModal;

  return { assignmentsDropdown };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalAssignmentsDropdownIsOpen = (dispatch) => {
    return (isOpen) => {
      dispatch(setActivityModalAssignmentsDropdownIsOpen(isOpen));
    };
  };

  return {
    setActivityModalAssignmentsDropdownIsOpen: dispatchSetActivityModalAssignmentsDropdownIsOpen(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Who);
