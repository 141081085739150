import React from 'react';
import { connect } from 'react-redux';

export const TemplateError = ({ formErrors }) => {
  const { checkInTemplateId, formTemplateId } = formErrors;

  if (checkInTemplateId || formTemplateId) {
    return <div className="error">{checkInTemplateId || formTemplateId}</div>;
  }

  return null;
};

const mapStateToProps = ({ activityModal }) => {
  const { formErrors } = activityModal;

  return { formErrors };
};

export default connect(mapStateToProps, null)(TemplateError);
