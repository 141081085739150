import dataApiRequestHandler from 'Components/Recommendations/api/dataApiRequestHandler';
import userDetailsApiRequestHandler from 'Components/Recommendations/api/userDetailsApiRequestHandler';

export const fetchDataAndUserDetails = (
  getDataFromApi = dataApiRequestHandler,
  getUserDetailsFromApi = userDetailsApiRequestHandler
) => {
  return async (carePlanUuid, setRecommendations, setUserDetails, setPageState) => {
    const dataResponse = await getDataFromApi(carePlanUuid, 'GET');
    const userDetailsResponse = await getUserDetailsFromApi(carePlanUuid);

    if (dataResponse.content_type === 'ERROR' || userDetailsResponse.content_type === 'ERROR') {
      setPageState('error');
      return;
    }

    if (dataResponse.data) {
      setRecommendations(dataResponse.data.recommendations);
    }

    setUserDetails({
      currentUserId: userDetailsResponse.current_user_id,
      currentUserName: userDetailsResponse.current_user_name,
      carePlanUuid: userDetailsResponse.care_plan_uuid,
      canModify: userDetailsResponse.can_modify,
    });

    setPageState('success');
  };
};

export default fetchDataAndUserDetails(dataApiRequestHandler, userDetailsApiRequestHandler);
