import {
  SET_RECOMMENDATIONS,
  SET_EDITING_RECOMMENDATION_INDEX,
  SET_IS_LIST_TRUNCATED,
  SET_REMOVING_RECOMMENDATION_INDEX,
  SET_USER_DETAILS,
  SET_IS_PROCESSING,
} from 'Actions/recommendationsBlock';

const initialState = {
  recommendations: [],
  editingRecommendationIndex: null,
  isListTruncated: true,
  truncatedCount: 3,
  removingRecommendationIndex: null,
  isProcessing: false,
  userDetails: {
    carePlanUuid: null,
    currentUserId: null,
    currentUserName: null,
    canModify: false,
  },
};

const recommendationsBlock = (state = initialState, action) => {
  switch (action.type) {
    case SET_RECOMMENDATIONS:
      return {
        ...state,
        recommendations: action.recommendations,
      };
    case SET_EDITING_RECOMMENDATION_INDEX:
      return {
        ...state,
        editingRecommendationIndex: action.editingRecommendationIndex,
      };
    case SET_IS_LIST_TRUNCATED:
      return {
        ...state,
        isListTruncated: action.isListTruncated,
      };
    case SET_REMOVING_RECOMMENDATION_INDEX:
      return {
        ...state,
        removingRecommendationIndex: action.removingRecommendationIndex,
      };
    case SET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.userDetails,
      };
    case SET_IS_PROCESSING:
      return {
        ...state,
        isProcessing: action.isProcessing,
      };
    default:
      return state;
  }
};

export default recommendationsBlock;
