import React from "react";
import { connect } from 'react-redux';

import Description from '../FormFields/Description';
import DueAt from '../FormFields/When/DueAt';
import Who from '../FormFields/Who/Who';
import Resources from '../FormFields/Resources';
import Error from '../Error';

const Task = ({ dueAt }) => {
  const isScheduled = !!dueAt;
  return (<div className="activity_modal--edit">
    <Description />
    {isScheduled && <DueAt />}
    <Resources />
    <Who />
    <Error />
  </div>);
};

const mapStateToProps = ({ activityModal }) => {
  const { activityProperties: { dueAt } } = activityModal;

  return { dueAt };
};

export default connect(mapStateToProps, null)(Task);