import React from 'react';
import { t } from 'i18n';
import { Icon } from 'Components/Base';

const i18nOpts = { scope: 'care_plan_action_plan_page.activities_container.tasks_card.task' };

const Task = ({ text, completedAt, dueAtFormatted }) => (
  <li className="order">
    <div className="order__section text-truncate">
      <div>
        <strong>{text || t('unnamed_task', i18nOpts)}</strong>
      </div>
      <span>{dueAtFormatted && t('due_at_statement', { ...i18nOpts, dueDate: dueAtFormatted })}</span>
    </div>
    <div className="order__section">
      {completedAt ? (
        <span className="order--complete">
          <Icon name="check_alt3" className="order__section--check-icon" />
          {t('completed', i18nOpts)}
        </span>
      ) : (
        <span className="order--due">{t('not_completed', i18nOpts)}</span>
      )}
    </div>
  </li>
);

export default Task;
