import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import DisplayText from './DisplayText';
import NextButtons from './NextButtons';
import PageNumberButtons from './PageNumberButtons';
import PreviousButtons from './PreviousButtons';

const PaginationButtons = ({ maxItemCountPerPage, totalItemCount }) => (
  <>
    {totalItemCount > maxItemCountPerPage && (
      <nav className="pagination">
        <PreviousButtons />
        <PageNumberButtons />
        <NextButtons />
      </nav>
    )}
    <DisplayText />
  </>
);

const mapStateToProps = ({ carePlanPlaceInvitationsPage }) => {
  const {
    filteredSignups,
    pagination: { maxItemCountPerPage },
  } = carePlanPlaceInvitationsPage;

  return {
    maxItemCountPerPage,
    totalItemCount: filteredSignups.length,
  };
};

PaginationButtons.propTypes = {
  maxItemCountPerPage: PropTypes.number,
  totalItemCount: PropTypes.number,
};

export default connect(mapStateToProps, null)(PaginationButtons);
