import moment from 'ct-moment';
import { t } from 'i18n';

const i18nOptsClipboardCopy = { scope: 'dashboard.clipboard_copy' };
const i18nOptsInsulin = { scope: 'dashboard.insulin' };
const STANDARD_SPACE = ' '.repeat(12); // Note: 10 space is the best between en and fr locale versions.

const buildList = (rows) => {
  let list = '';

  rows.forEach((row) => {
    list += `\n${row[0]}` + ' '.repeat(6) + row[1]; // 4 spaces to fit with length of timestamp
  });

  return list;
};

const buildBasalList = (profiles) => {
  let basalRates = '';
  let nonActiveBasalRates = [];

  let basalRatesHeader = '';

  const basalRatesTableHeader =
    t('start', i18nOptsInsulin) +
    STANDARD_SPACE +
    t('basal_rates_title', i18nOptsClipboardCopy) +
    ' ' +
    t('basal_rate_unit', i18nOptsClipboardCopy);

  profiles.forEach((profile) => {
    const basalRatesData = `${buildList(profile.rows)}\n`;

    if (profile.isActive) {
      basalRatesHeader = `\n${t('basal_rates_title', i18nOptsClipboardCopy)}: ${profile.name} (${t(
        'active',
        i18nOptsInsulin
      )})\n`;
      basalRates += basalRatesHeader + basalRatesTableHeader + basalRatesData;
    } else {
      basalRatesHeader = `\n${t('basal_rates_title', i18nOptsClipboardCopy)}: ${profile.name}\n`;
      nonActiveBasalRates.push(basalRatesHeader + basalRatesTableHeader + basalRatesData);
    }
  });

  basalRates += nonActiveBasalRates.join('');

  return basalRates;
};

const buildICRatioSettings = (insulinCarbRatios) => {
  const icRatioSettingsHeader = `\n${t('i_c_ratio_settings', i18nOptsClipboardCopy)} (${t(
    'active',
    i18nOptsInsulin
  )})\n`;
  const icRationSettingsTableHeader =
    t('start', i18nOptsInsulin) +
    STANDARD_SPACE +
    t('ICR', i18nOptsInsulin) +
    ' ' +
    t('i_c_ration_unit', i18nOptsClipboardCopy);
  const icRatioSettingsData = `${buildList(insulinCarbRatios.rows)}\n`;

  return icRatioSettingsHeader + icRationSettingsTableHeader + icRatioSettingsData;
};

const buildIsfPrograms = (insulinSensitivityFactors) => {
  const isfProgramsHeader = `\n${t('isf_programs', i18nOptsClipboardCopy)} (${t(
    'active',
    i18nOptsInsulin
  )})\n`;
  const isfProgramsTableHeader =
    t('start', i18nOptsInsulin) +
    STANDARD_SPACE +
    t('ISF', i18nOptsInsulin) +
    ' ' +
    t('isf_unit', i18nOptsClipboardCopy);
  const isfProgramsData = `${buildList(insulinSensitivityFactors.rows)}\n`;

  return isfProgramsHeader + isfProgramsTableHeader + isfProgramsData;
};

const buildTargetBloodGlucose = (bloodGlucoseTargets) => {
  const targetBloodGlucoseHeader = `\n${t('target_blood_glucose', i18nOptsClipboardCopy)} (${t(
    'active',
    i18nOptsInsulin
  )})\n`;
  const targetBloodGlucoseTableHeader =
    t('start', i18nOptsInsulin) +
    STANDARD_SPACE +
    t('bg_target', i18nOptsClipboardCopy) +
    ' ' +
    t('isf_unit', i18nOptsClipboardCopy);
  const targetBloodGlucoseData = `${buildList(bloodGlucoseTargets.rows)}\n`;

  return targetBloodGlucoseHeader + targetBloodGlucoseTableHeader + targetBloodGlucoseData;
};

const getDayDiff = (currentTime, days) => {
  return currentTime.clone().subtract(days, 'days');
};

const getReportingPeriod = (selectedHistoricalTab) => {
  const result = { fromDate: '', toDate: '' };
  const currentTime = moment();

  let fromTime = getDayDiff(currentTime, 8);
  let toTime = getDayDiff(currentTime, 1);

  switch (selectedHistoricalTab) {
    case '7days':
      fromTime = getDayDiff(currentTime, 8);
      toTime = getDayDiff(currentTime, 1);
      break;
    case '14days':
      fromTime = getDayDiff(currentTime, 15);
      toTime = getDayDiff(currentTime, 1);
      break;
    case '30days':
      fromTime = getDayDiff(currentTime, 31);
      toTime = getDayDiff(currentTime, 1);
      break;
    case '90days':
      fromTime = getDayDiff(currentTime, 91);
      toTime = getDayDiff(currentTime, 1);
      break;
  }

  result.fromDate = fromTime.format('MMM Do YYYY');
  result.toDate = toTime.format('MMM Do YYYY');

  return result;
};

const buildAvgDailyInsulin = (selectedHistoricalTab, pumpStatistics) => {
  if (pumpStatistics.status !== 'SUCCESS') {
    return `${t('average_daily_insulin', i18nOptsClipboardCopy)}\n--\n`;
  }

  const { fromDate, toDate } = getReportingPeriod(selectedHistoricalTab);
  const historicalInsulinData = pumpStatistics.data[selectedHistoricalTab];

  const averageDailyInsulinHeader = `${t('average_daily_insulin', i18nOptsClipboardCopy)}\n`;

  const reportingPeriod = `${t('reporting_period', i18nOptsClipboardCopy)}: ${fromDate} to ${toDate}\n`;

  const averageBasalText = `${t('average_basal', i18nOptsClipboardCopy)}: ${
    historicalInsulinData.averageBasal.percentBasal
  }% (${historicalInsulinData.averageBasal.value} ${t('units', i18nOptsClipboardCopy)})\n`;

  const averageBolusText = `${t('average_bolus', i18nOptsClipboardCopy)}: ${
    historicalInsulinData.averageBolus.percentBolus
  }% (${historicalInsulinData.averageBolus.value} ${t('units', i18nOptsClipboardCopy)})\n`;

  const averageDailyText = `${t('average_daily_dose', i18nOptsClipboardCopy)}: ${
    historicalInsulinData.averageDailyDose.value
  } ${t('units', i18nOptsClipboardCopy)}`;

  return averageDailyInsulinHeader + reportingPeriod + averageBasalText + averageBolusText + averageDailyText;
};

const getReportHeader = (userDetails, currentDate) => {
  const patientFullName = `${userDetails.patientFullName}\n`;
  const dateOfBirth = `${t('date_of_birth', i18nOptsClipboardCopy)}: ${userDetails.patientBirthday}\n`;
  const exportedDate = `${t('exported_date', i18nOptsClipboardCopy)}: ${currentDate}\n\n`;

  return patientFullName + dateOfBirth + exportedDate;
};

const getReportBody = (data, pumpStatistics, selectedHistoricalTab) => {
  const activeInsulinTime = data.basalProgram.list[0].value;
  const insulinActionText = `${t('insulin_action', i18nOptsClipboardCopy)}: ${activeInsulinTime}\n`;
  const basalRatesText = `${buildBasalList(data.basalRates.profiles)}`;
  const icRatioSettingsText = `${buildICRatioSettings(data.insulinCarbRatios)}`;
  const isfProgramsText = `${buildIsfPrograms(data.insulinSensitivityFactors)}`;
  const targetBloodGlucoseText = `${buildTargetBloodGlucose(data.bloodGlucoseTargets)}\n`;
  const avgDailyInsulinText = buildAvgDailyInsulin(selectedHistoricalTab, pumpStatistics);

  return (
    insulinActionText +
    basalRatesText +
    icRatioSettingsText +
    isfProgramsText +
    targetBloodGlucoseText +
    avgDailyInsulinText
  );
};

const buildInsulinPumpTextFromBlock = (insulinPumpData) => {
  const currentDate = moment().format('MMM Do YYYY');
  const data = insulinPumpData.statsData;
  const userDetails = insulinPumpData.userDetails;
  const pumpStatistics = insulinPumpData.pumpStatistics;
  const selectedHistoricalTab = insulinPumpData.tab;

  return (
    getReportHeader(userDetails, currentDate) + getReportBody(data, pumpStatistics, selectedHistoricalTab)
  );
};

export default buildInsulinPumpTextFromBlock;
