import Api from './Api'

class RepeatedCheckIn extends Api {
  constructor(carePlanId, id) {
    super()

    this.id = id
    this.carePlanId = carePlanId
  }

  get url() {
    return `/care_plans/${this.carePlanId}/repeated_check_ins`
  }
}

export default RepeatedCheckIn
