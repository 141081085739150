import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import classnames from 'classnames';

import RelationshipInput from 'Components/Forms/RelationshipInput';
import InputGroup from 'Components/Forms/InputGroup';
import SubstituteDecisionMakerInput from './SubstituteDecisionMakerInput';

const i18nOpts = { scope: 'care_plan_creators.careteam.primary_caregiver_form' };

const PrimaryCaregiver = ({
  name,
  email,
  firstName,
  lastName,
  phone,
  relationship,
  onChange,
  errors,
  optional,
  sdmInfo,
  patientName
}) => {
  const hasErrors = !!Object.keys(errors).find((key) => key.includes(`${name}.invitation`));
  const classNames = classnames({
    caregiver: true,
    'caregiver--errors': hasErrors,
  });

  return (
    <div className="caregiver_container">
      <div className={`${classNames} form__row`}>
        <InputGroup
          name={`${name}.invitation.email`}
          type="text"
          className="caregiver__email input"
          component="input"
          value={email}
          onChange={onChange}
          label={t('email', i18nOpts)}
          errors={errors}
        />

        <RelationshipInput
          className="relationship-input"
          name={`${name}.relationship`}
          value={relationship}
          errors={errors}
          label={t('relationship', i18nOpts)}
          onChange={onChange}
        />
      </div>
      <div className={`${classNames} ${classNames} form__row form__row--stc-invitation`}>
        <InputGroup
          name={`${name}.invitation.firstName`}
          type="text"
          className="caregiver__firstName input"
          component="input"
          value={firstName}
          onChange={onChange}
          label={t('first_name', i18nOpts)}
          errors={errors}
        />
        <InputGroup
          name={`${name}.invitation.lastName`}
          type="text"
          className="caregiver__lastName input"
          component="input"
          value={lastName}
          onChange={onChange}
          label={t('last_name', i18nOpts)}
          errors={errors}
        />
        <InputGroup
          name={`${name}.invitation.phone`}
          type="tel"
          className="caregiver__phone input"
          component="input"
          value={phone}
          onChange={onChange}
          label={t('phone', i18nOpts)}
          errors={errors}
          optional={optional}
        />
      </div>
      <SubstituteDecisionMakerInput
        name={name}
        onChange={onChange}
        patientName={patientName}
        sdmInfo={sdmInfo}
        errors={errors}
      />
    </div>
  );
};

PrimaryCaregiver.propTypes = {
  email: PropTypes.string,
  errors: PropTypes.object,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phone: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  relationship: PropTypes.string,
};

PrimaryCaregiver.defaultProps = {
  errors: {},
  optional: true,
};

export default PrimaryCaregiver;
