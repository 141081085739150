import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const PageNumberButtons = ({
  currentPageNumber,
  onPageChange,
  totalPageCount,
  buttonCount
}) => {

  const sliceButtons = (buttonArray) => {
    let beginSlice = currentPageNumber - Math.floor(buttonCount / 2) - 1
    let endSlice = currentPageNumber + Math.floor(buttonCount / 2)

    if (beginSlice < 0) {
      const amountToShift = 0 - beginSlice
      beginSlice += amountToShift
      endSlice += amountToShift
    } else if ( endSlice > totalPageCount ) {
      const amountToShift = endSlice - totalPageCount
      beginSlice -= amountToShift
      endSlice -= amountToShift
    }

    return buttonArray.slice(beginSlice, endSlice)
  }

  let pageNumberButtonArray = []
  for (let pageNumber = 1; pageNumber < totalPageCount + 1; pageNumber++) {
    pageNumberButtonArray.push(pageNumber)
  }

  if ( totalPageCount > buttonCount ) {
    pageNumberButtonArray = sliceButtons(pageNumberButtonArray)
  }

  return (
    <Fragment>
      { pageNumberButtonArray
          .map(number => {
            return number === currentPageNumber
              ? <span className='page current' key={number}>{ number }&#32;</span>
              : <span className='page' key={number} onClick={() => { onPageChange(number) }}><a>{ number }&#32;</a></span>
          })
      }
    </Fragment>
  )
}

PageNumberButtons.propTypes = {
  buttonCount: PropTypes.number,
  currentPageNumber: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  totalPageCount: PropTypes.number.isRequired,
}

PageNumberButtons.defaultProps = {
  buttonCount: 5
}

export default PageNumberButtons
