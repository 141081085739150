import { SET_EVENT_FILTER, TOGGLE_CARE_PLAN_FILTER, OPEN_ASIDE, CLOSE_ASIDE } from 'Actions/filter'

const toggleCarePlan = (excludedCarePlans, uuid) =>
  excludedCarePlans.includes(uuid)
    ? excludedCarePlans.filter((selected) => selected !== uuid)
    : [uuid, ...excludedCarePlans]

const initialState = {
  filter: 'all',
  excludedCarePlans: [],
  isOpen: false,
}

const filters = (state = initialState, action) => {
  switch (action.type) {
    case SET_EVENT_FILTER:
      return {
        ...state,
        filter: action.filter,
      }
    case TOGGLE_CARE_PLAN_FILTER:
      return {
        ...state,
        excludedCarePlans: toggleCarePlan(state.excludedCarePlans, action.uuid),
      }
    case OPEN_ASIDE:
      return {
        ...state,
        isOpen: true,
      }
    case CLOSE_ASIDE:
      return {
        ...state,
        isOpen: false,
      }
    default:
      return state
  }
}

export default filters
