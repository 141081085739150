import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import ItemPillLoader from './ItemPillLoader'

const ItemPillActions = ({ children, isLoading, large }) => {
  const className = classNames('item-pill__actions', {
    'item-pill__actions--large': large,
  })

  return <div className={className}>{isLoading ? <ItemPillLoader /> : children}</div>
}

ItemPillActions.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
  isLoading: PropTypes.bool,
  large: PropTypes.bool,
}

ItemPillActions.defaultProps = {
  isLoading: false,
  large: false,
}

export default ItemPillActions
