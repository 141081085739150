import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const PreviousButtons = ({
  currentPageNumber,
  onPageChange
}) => {

  return currentPageNumber > 1
    ? <Fragment>
        <span className='first' onClick={() => { onPageChange(1) }}><a>{ '«' }</a></span>
        <span className='previous' onClick={() => { onPageChange(currentPageNumber - 1) }}><a>{ '‹' }</a></span>
      </Fragment>
    : <Fragment>
        <span className='first disabled'>{ '«' }</span>
        <span className='previous disabled'>{ '‹' }</span>
      </Fragment>
}

PreviousButtons.propTypes = {
  currentPageNumber: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired
}

export default PreviousButtons
