import { SET_CACHED_IMAGE_URL } from 'Actions/deferredImages'

const initialState = {
  deferredImages: {},
}

const deferredImages = (state = initialState, action) => {
  switch (action.type) {
    case SET_CACHED_IMAGE_URL:
      return {
        ...state,
        deferredImages: { ...state.deferredImages, [action.imageEntitlementToken]: action.imageUrl },
      }

    default:
      return state
  }
}

export default deferredImages
