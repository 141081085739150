import React from 'react';
import { t } from 'i18n';
import moment from 'ct-moment';

const i18nOpts = { scope: 'dashboard.insulin' };

const UpdatedAt = ({ userName, updatedAt }) => {
  const formattedUpdatedAt = moment(updatedAt).format('MMMM D, YYYY');

  return (
    <section className="insulin-dosing__updated-at">
      <p className="insulin-dosing__updated-at__text">
        {t('updated_at', { ...i18nOpts, userName, updatedAt: formattedUpdatedAt })}
      </p>
    </section>
  );
};

export default UpdatedAt;
