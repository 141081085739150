import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import Order from './Order'

const i18nOpts = { scope: 'care_plan_creators.tasks.index' }

const OrderList = ({
  orders,
  repeatedTasks,
  repeatedAppointments,
  deleteRepeatedTask,
  deleteRepeatedAppointment,
  deleteOrder,
  editOrder,
}) => {
  const allOrders = [...orders, ...repeatedTasks, ...repeatedAppointments]

  return !allOrders.length || !allOrders.filter((o) => !o._destroy).length ? (
    <div className="careplan__empty-list" dangerouslySetInnerHTML={{ __html: t('empty_html', i18nOpts) }} />
  ) : (
    <div className="careplan-wizard__order-list">
      {orders.map((order, i) => {
        return (
          <Order
            key={i}
            deleteOrder={order.type !== 'referral' ? deleteOrder.bind(null, order.id) : null}
            editOrder={order.type !== 'referral' ? editOrder.bind(null, order) : null}
            order={order}
          />
        )
      })}
      {repeatedTasks.map((repeatedTask, i) => {
        return (
          <Order
            key={i}
            deleteOrder={
              repeatedTask.type !== 'RepeatedTask' ? deleteRepeatedTask.bind(null, repeatedTask.id) : null
            }
            editOrder={repeatedTask.type !== 'RepeatedTask' ? editOrder.bind(null, repeatedTask) : null}
            order={repeatedTask}
          />
        )
      })}
      {repeatedAppointments.map((repeatedAppointment, i) => {
        return (
          <Order
            key={i}
            deleteOrder={
              repeatedAppointment.type !== 'RepeatedAppointment'
                ? deleteRepeatedAppointment.bind(null, repeatedAppointment.id)
                : null
            }
            editOrder={
              repeatedAppointment.type !== 'RepeatedAppointment'
                ? editOrder.bind(null, repeatedAppointment)
                : null
            }
            order={repeatedAppointment}
          />
        )
      })}
    </div>
  )
}

OrderList.propTypes = {
  deleteOrder: PropTypes.func.isRequired,
  deleteRepeatedAppointment: PropTypes.func.isRequired,
  deleteRepeatedTask: PropTypes.func.isRequired,
  editOrder: PropTypes.func.isRequired,
  orders: PropTypes.array.isRequired,
  repeatedAppointments: PropTypes.array,
  repeatedTasks: PropTypes.array,
}

OrderList.defaultProps = {
  repeatedAppointments: [],
  repeatedTasks: [],
}

export default OrderList
