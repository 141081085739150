import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import {
  setActivityModalActivityProperties,
  setActivityModalTemplatesDropdownIsOpen,
  setActivityModalTemplatesDropdownState,
  setActivityModalTemplatesDropdownSearchInput,
  setActivityModalTemplatesDropdownTemplates,
  setActivityModalTemplatesDropdownTemplatesToDisplay,
} from 'Actions/activityModal';
import handleTemplatesSetup from './handleTemplatesSetup';

import { Button } from 'Components/Base';

const i18nOpts = { scope: 'activity_modal.form_fields.templates' };

export const OpenButton = ({
  carePlanId,
  activityType,
  templatesDropdown,
  setActivityModalActivityProperties,
  setActivityModalTemplatesDropdownIsOpen,
  setActivityModalTemplatesDropdownState,
  setActivityModalTemplatesDropdownSearchInput,
  setActivityModalTemplatesDropdownTemplates,
  setActivityModalTemplatesDropdownTemplatesToDisplay,
}) => {
  const { isOpen } = templatesDropdown;

  return (
    <Button
      className="btn btn--secondary"
      onClick={() => {
        if (isOpen) {
          setActivityModalTemplatesDropdownIsOpen(false);
          setActivityModalTemplatesDropdownSearchInput('');
        } else {
          setActivityModalTemplatesDropdownIsOpen(true);
          setActivityModalTemplatesDropdownSearchInput('');
          handleTemplatesSetup(
            setActivityModalTemplatesDropdownState,
            setActivityModalTemplatesDropdownTemplates,
            setActivityModalTemplatesDropdownTemplatesToDisplay,
            carePlanId,
            activityType
          );
        }
      }}
      text={t('fill_using_a_template', i18nOpts)}
    />
  );
};

const mapStateToProps = ({ activityModal }) => {
  const { carePlanId, activityType, templatesDropdown } = activityModal;

  return { carePlanId, activityType, templatesDropdown };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalActivityProperties = (dispatch) => {
    return (activityProperties) => {
      dispatch(setActivityModalActivityProperties(activityProperties));
    };
  };

  const dispatchSetActivityModalTemplatesDropdownIsOpen = (dispatch) => {
    return (isOpen) => {
      dispatch(setActivityModalTemplatesDropdownIsOpen(isOpen));
    };
  };

  const dispatchSetActivityModalTemplatesDropdownState = (dispatch) => {
    return (templatesDropdownState) => {
      dispatch(setActivityModalTemplatesDropdownState(templatesDropdownState));
    };
  };

  const dispatchSetActivityModalTemplatesDropdownSearchInput = (dispatch) => {
    return (searchInput) => {
      dispatch(setActivityModalTemplatesDropdownSearchInput(searchInput));
    };
  };

  const dispatchSetActivityModalTemplatesDropdownTemplates = (dispatch) => {
    return (templates) => {
      dispatch(setActivityModalTemplatesDropdownTemplates(templates));
    };
  };

  const dispatchSetActivityModalTemplatesDropdownTemplatesToDisplay = (dispatch) => {
    return (templatesToDisplay) => {
      dispatch(setActivityModalTemplatesDropdownTemplatesToDisplay(templatesToDisplay));
    };
  };

  return {
    setActivityModalActivityProperties: dispatchSetActivityModalActivityProperties(dispatch),
    setActivityModalTemplatesDropdownIsOpen: dispatchSetActivityModalTemplatesDropdownIsOpen(dispatch),
    setActivityModalTemplatesDropdownState: dispatchSetActivityModalTemplatesDropdownState(dispatch),
    setActivityModalTemplatesDropdownSearchInput:
      dispatchSetActivityModalTemplatesDropdownSearchInput(dispatch),
    setActivityModalTemplatesDropdownTemplates: dispatchSetActivityModalTemplatesDropdownTemplates(dispatch),
    setActivityModalTemplatesDropdownTemplatesToDisplay:
      dispatchSetActivityModalTemplatesDropdownTemplatesToDisplay(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenButton);
