import React from 'react'
import PropTypes from 'prop-types'
import ProviderWrapper from 'Components/Base/ProviderWrapper'
import Status from './Status'

const Task = ({ task }) => (
  <ProviderWrapper>
    <Status canUpdate={task.canComplete} {...task} />
  </ProviderWrapper>
)

Task.propTypes = {
  task: PropTypes.object.isRequired,
}

Task.defaultProps = {}

export default Task
