import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import InputGroup from '../../Forms/InputGroup'
import Submit from '../../Forms/Submit'

const i18nOpts = { scope: 'team.role.role_form' }

const roles = () => {
  return ['primary_caregiver', 'caregiver', 'support'].map((key) => {
    return (
      <option key={key} value={key}>
        {t(key, { scope: 'role' })}
      </option>
    )
  })
}

const RoleForm = ({ newRole, onSubmit, onChange, cancel }) => (
  <form onSubmit={onSubmit} className="form form--background">
    <InputGroup
      className="input"
      component="select"
      errors={{}}
      name="newRole"
      value={newRole}
      onChange={onChange}
      children={roles()}
    />

    <div className="form__submit">
      <Submit />
      <a className="form__secondary-submit btn btn--secondary" onClick={cancel}>
        {t('cancel', i18nOpts)}
      </a>
    </div>
  </form>
)

RoleForm.propTypes = {
  cancel: PropTypes.func.isRequired,
  newRole: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

RoleForm.defaultProps = {}

export default RoleForm
