import React, { useContext } from 'react';
import { t } from 'i18n';
import { CarePlanContext } from 'Components/Dashboard/Dashboard';

const i18nOpts = { scope: 'dashboard.device' };

const GraphErrorMessage = ({ status }) => {
  const {
    userDetails: { patientFirstName, isPlaceUser, carehubUrl },
  } = useContext(CarePlanContext);

  if (!isPlaceUser && status === 'EMPTY_CHART_DATA') {
    return (
      <div className="blood-glucose-chart__empty-data-text">
        <span>{t('no_chdb_data_present_patient_part_1', i18nOpts)}</span>
        <a href={carehubUrl}>{t('no_chdb_data_present_patient_part_2', i18nOpts)}</a>
      </div>
    );
  }

  const errorMessage = (status) => {
    switch (status) {
      case 'EMPTY_CHART_DATA':
        return t('no_chdb_data_present_placeuser', i18nOpts)
      case 'EMPTY_COMPARISON_CHART_DATA':
        return t('empty_comparison_chart_data', i18nOpts)
      case 'FAILURE_MISSING_FHIR_TOKEN':
        return t('no_user_accepted_error', { ...i18nOpts, firstName: patientFirstName })
      default:
        return null;
    }
  };

  return <div className="blood-glucose-chart__empty-data-text">{errorMessage(status)}</div>;
};

export default GraphErrorMessage;
