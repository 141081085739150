import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { onAssignmentChange } from 'Utilities/utilities'
import InputGroup from 'Components/Forms/InputGroup'
import { Modal } from 'Components/Base/Modal'
import Label from 'Components/Forms/Label'
import ResourceListItem from 'Components/Resources/ResourceListItem'
import { RequestForm, RequestButton, RequestedHelp } from 'Components/HelpRequest'
import RecurrenceInput from '../RecurrenceInput'
import TemplateRecurrenceInput from '../TemplateRecurrenceInput'

const i18nOpts = {
  scope: 'care_plans.generic_order_section.generic_order_form',
}

const GenericOrderForm = (props) => {
  const {
    assignments,
    assignmentOptions,
    className,
    errors,
    frequencyType,
    handleChange,
    handleSubmit,
    helpRequested,
    helpRequest,
    isEdit,
    isTemplate,
    onCloseModal,
    onOpenModal,
    onSubmitHelpRequest,
    modalIsOpen,
    renderSubmit,
    taskTemplates,
    text,
    timeZone,
    volunteersEnabled,
    selectedResources,
    onRemoveResource,
    onSelectActivityTemplate,
    selectedActivityTemplateId,
    patientFirstName,
  } = props

  return (
    <form className={className} onSubmit={handleSubmit} noValidate>
      {!isEdit && (
        <div className="input activity-template-group">
          <InputGroup
            component="select"
            className="input"
            name="taskTemplates"
            label={t('task_template', i18nOpts)}
            onChange={onSelectActivityTemplate}
            value={selectedActivityTemplateId}
          >
            <option value={-1}>{t('select_task_template', i18nOpts)}</option>
            {taskTemplates.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </InputGroup>

          <hr />
          <div className="activity-template-group__hint">{t('start_new_task', i18nOpts)}</div>
        </div>
      )}

      <InputGroup
        component="textarea"
        label={t('text', i18nOpts)}
        errors={errors}
        name="text"
        onChange={handleChange}
        value={text}
        required
        type="textarea"
      />

      <div className="form__row">
        {!isTemplate && (
          <InputGroup
            name="assignments"
            component="multi-select"
            label={`${t('assignments', i18nOpts)} ${t('optional', i18nOpts)}`}
            subheader={t('assignments_header', { ...i18nOpts, name: patientFirstName })}
            value={assignments}
            options={assignmentOptions}
            onChange={onAssignmentChange(handleChange)}
            errors={errors}
            className={`input ${volunteersEnabled && 'input--volunteers-enabled'}`}
          />
        )}
      </div>

      {volunteersEnabled && frequencyType !== 'recurring' && (
        <div className="form__row--request-button">
          <RequestButton helpRequested={helpRequested} action={onOpenModal} />
        </div>
      )}

      {isTemplate ? <TemplateRecurrenceInput {...props} /> : <RecurrenceInput {...props} />}

      {selectedResources.length ? (
        <div className="item-pill-section__item-list">
          <Label text={t('resources', i18nOpts)} inputName={t('resources', i18nOpts)} />
          {selectedResources.map((resource) => (
            <ResourceListItem
              key={resource.id}
              id={resource.id}
              name={resource.name}
              kind={resource.kind}
              deleteResource={onRemoveResource}
              isLinking
            />
          ))}
        </div>
      ) : null}

      <Modal
        className="ReactModal__Content"
        isOpen={modalIsOpen}
        closeModal={onCloseModal}
        title={t('add_request', i18nOpts)}
      >
        <RequestForm
          helpRequest={helpRequest}
          onClose={onCloseModal}
          onSubmit={onSubmitHelpRequest}
          timeZone={timeZone}
        />
      </Modal>

      <RequestedHelp helpRequested={helpRequested} action={onOpenModal} frequencyType={frequencyType} />

      {renderSubmit()}
    </form>
  )
}

GenericOrderForm.propTypes = {
  assignmentOptions: PropTypes.array,
  assignments: PropTypes.array,
  className: PropTypes.string,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  daysOffset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dueAt: PropTypes.string,
  endRepeat: PropTypes.string,
  errors: PropTypes.object.isRequired,
  frequency: PropTypes.string,
  frequencyType: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  helpRequest: PropTypes.object,
  helpRequested: PropTypes.bool,
  interval: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isEdit: PropTypes.bool,
  isTemplate: PropTypes.bool,
  modalIsOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,
  onOpenModal: PropTypes.func,
  onRemoveResource: PropTypes.func.isRequired,
  onSelectActivityTemplate: PropTypes.func.isRequired,
  onSubmitHelpRequest: PropTypes.func,
  patientFirstName: PropTypes.string,
  renderSubmit: PropTypes.func.isRequired,
  selectedActivityTemplateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectedResources: PropTypes.array,
  taskTemplates: PropTypes.array,
  text: PropTypes.string.isRequired,
  timeZone: PropTypes.string,
  until: PropTypes.string,
  volunteersEnabled: PropTypes.bool,
}

GenericOrderForm.defaultProps = {
  assignmentOptions: [],
  assignments: [],
  className: 'form',
  count: '',
  daysOffset: '',
  dueAt: '',
  endRepeat: '',
  frequency: '',
  frequencyType: 'once',
  helpRequest: {},
  helpRequested: false,
  interval: '',
  isEdit: false,
  isTemplate: false,
  modalIsOpen: false,
  onCloseModal: null,
  onOpenModal: null,
  onSubmitHelpRequest: null,
  selectedActivityTemplateId: '',
  selectedResources: [],
  taskTemplates: [],
  timeOffset: '',
  timeZone: '',
  until: '',
  volunteersEnabled: false,
}

export default GenericOrderForm
