import updateVisibilityApi from './updateVisibilityApi';

export const handleSetCheckInVisibilityWrapper = (
  updateVisibilityApiProvider = updateVisibilityApi
) => {
  return async (
    resourceId,
    visibleToAll,
    setIsLoading,
    setApiError
  ) => {
    setApiError(false);
    setIsLoading(true);

    try {
      const { responseType } = await updateVisibilityApiProvider(resourceId, visibleToAll);
      if (responseType === 'DATA') {
        setIsLoading(false);
        return true; // looks weird, but we need it to navigate to next section
      } else {
        throw "Couldn't update";
      }
    } catch (error) {
      setIsLoading(false);
      setApiError(true);
      console.error(error);
      return false;
    }
  };
};

export default handleSetCheckInVisibilityWrapper(updateVisibilityApi);
