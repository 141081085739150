import getSessionInfoApi from './getSessionInfoApi';

export const handleSessionInfoSetupWrapper = (getSessionInfoApiProvider = getSessionInfoApi) => {
  return async (setSessionInfoState, setActivityModalCarePlanId, setActivityModalTimeZone, setActivityModalLocale, carePlanId) => {
    setSessionInfoState('LOADING');

    try {
      const { responseType, timeZone, locale } = await getSessionInfoApiProvider(carePlanId);

      if (responseType === 'DATA') {
        setActivityModalCarePlanId(carePlanId);
        setActivityModalTimeZone(timeZone);
        setActivityModalLocale(locale);
        setSessionInfoState('MAIN_CONTENT');
        return true;
      } else {
        throw 'Could not get session info.';
      }
    } catch (error) {
      setSessionInfoState('ERROR');
      console.error(error);
      return false;
    }
  };
};

export default handleSessionInfoSetupWrapper(getSessionInfoApi);
