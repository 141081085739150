import React from 'react';
import PropTypes from 'prop-types';
import tabClassName from './tabClassName';

const Tab = ({ text, onClick, isSelected, isFocused, isDisabled, additionalClasses }) => {
  const tabClass = tabClassName(isSelected, isFocused, isDisabled);

  if (isDisabled) return <div className={tabClass}>{text}</div>;

  return (
    <div
      className={`${tabClass} ${additionalClasses}`}
      tabIndex='0'
      onClick={onClick}
      onKeyDown={({key}) => {
        if (key === 'Enter' || key === ' ') {
          onClick();
        }
      }}
    >
      {text}
    </div>
  );
};

Tab.propTypes = {
  isDisabled: PropTypes.bool,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  additionalClasses: PropTypes.string,
};

Tab.defaultProps = {
  isDisabled: false,
  isFocused: false,
  isSelected: false,
  additionalClasses: '',
};

export default Tab;
