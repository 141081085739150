import React from 'react';
import { t } from 'i18n';

import FormField from '../FormField';
import CountrySelector from './CountrySelector';
import ExtendedAddressByCountry from './ExtendedAddressByCountry';

const i18nOpts = { scope: 'care_plan_creators.library_appointment_templates.form' };

const Address = ({ address, onChange, formErrors, locale }) => (
  <div className="patient_address_form__input_field__container">
    <CountrySelector
      label={t('country', i18nOpts)}
      value={address.countryCode}
      onChange={onChange}
      locale={locale}
    />
    <FormField
      label={t('street_address', i18nOpts)}
      name="address"
      value={address.address}
      placeholder={t('street_address', i18nOpts)}
      onChange={onChange}
      formErrors={formErrors}
    />
    <FormField
      name="extendedAddress"
      label={t('extended_address', i18nOpts)}
      value={address.extendedAddress}
      placeholder={t('extended_address', i18nOpts)}
      onChange={onChange}
      formErrors={formErrors}
    />
    <ExtendedAddressByCountry
      address={address}
      onChange={onChange}
      formErrors={formErrors}
    />
  </div>
);

export default Address;
