import React from 'react';
import { t } from 'i18n';
import { connect } from 'react-redux';
import ActionButton from 'Components/Base/ActionButton';
import RemoveModal from './RemoveModal';
import attributionText from './attributionText';
import Icon from 'Components/Base/Icon';
import { setEditingRecommendationIndex, setRemovingRecommendationIndex } from 'Actions/recommendationsBlock';

const i18nOpts = { scope: 'blocks.recommendations' };

const Show = ({
  recommendation,
  index,
  setViewState,
  setEditingRecommendationIndex,
  setRemovingRecommendationIndex,
  currentUserId,
  isProcessing,
}) => {
  // Only allow original creator of recommendation to edit or remove
  const isCreatedByCurrentUser = currentUserId === recommendation.user_id;

  return (
    <>
      <RemoveModal index={index} recommendation={recommendation} />
      <div className="recommendation__container">
        <div className="recommendation__content">{recommendation.content}</div>
        <div className="recommendation__attribution">{attributionText(recommendation)}</div>
        {isCreatedByCurrentUser && (
          <div className="recommendation__action-button__container">
            <ActionButton
              className="button-subtle recommendation__action-button"
              disabled={isProcessing}
              content={
                <>
                  <Icon name="file-edit" />
                  {t('edit', i18nOpts)}
                </>
              }
              onClick={() => {
                setViewState('edit');
                setEditingRecommendationIndex(index);
              }}
            />

            <ActionButton
              className="button-subtle recommendation__action-button"
              disabled={isProcessing}
              content={
                <>
                  <Icon name="trash" />
                  {t('remove', i18nOpts)}
                </>
              }
              onClick={() => {
                setRemovingRecommendationIndex(index);
                setEditingRecommendationIndex(null);
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({
  recommendationsBlock: {
    isProcessing,
    userDetails: { currentUserId },
  },
}) => {
  return { currentUserId, isProcessing };
};

const mapDispatchToProps = (dispatch) => ({
  setEditingRecommendationIndex: (index) => dispatch(setEditingRecommendationIndex(index)),
  setRemovingRecommendationIndex: (index) => dispatch(setRemovingRecommendationIndex(index)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Show);
