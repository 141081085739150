import Api from './Api'

// Singleton routes, ie No ID
class Resource extends Api {
  show() {
    return this.request(this.url)
  }

  update(body) {
    return this.request(`${this.url}`, 'PATCH', body)
  }

  destroy(body) {
    return this.request(`${this.url}`, 'DELETE', body)
  }
}

export default Resource
