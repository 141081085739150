import React, { useContext } from 'react';
import { t } from 'i18n';
import { CarePlanContext } from 'Components/Dashboard/Dashboard';
import Highlight from 'Components/Base/Highlight';
import ConsentMissing from './ConsentMissing';
import ConnectionFailed from './ConnectionFailed';

const Error = ({ status }) => {
  const { userDetails } = useContext(CarePlanContext);

  if (userDetails.isPlaceUser && status === 'FAILURE_MISSING_ACCESS_TOKEN') {
    const i18nOpts = { scope: 'dashboard.device' };
    return (
      <Highlight
        type="warning"
        message={t('consent_missing_hcp_message', { ...i18nOpts, firstName: userDetails.patientFirstName })}
      />
    );
  }

  switch (status) {
    case 'FAILURE_MISSING_ACCESS_TOKEN':
      return <ConsentMissing />;
    case 'FAILURE_DATA_RETRIEVAL_FAILED':
    default:
      return (
        <div className="blood-glucose-chart__wrapper">
          <ConnectionFailed />
        </div>
      );
  }
};

export default Error;
