import Api from 'Api/Api'

class CarePlanTemplate extends Api {
  constructor(carePlanTemplateId) {
    super()

    this.id = carePlanTemplateId
    this.updateDetails = this.updateDetails.bind(this)
    this.updateName = this.updateName.bind(this)
  }

  updateDetails({ carePlan }) {
    return super.request(`${this.url}/${this.id}/update_details`, 'PATCH', { carePlan })
  }

  updateInstructions({ carePlan }) {
    return super.request(`${this.url}/${this.id}/update_instructions`, 'PATCH', { carePlan })
  }

  updateName({ carePlan }) {
    return super.request(`${this.url}/${this.id}/update_name`, 'PATCH', { carePlan })
  }

  get url() {
    return '/care_plan_templates'
  }
}

export default CarePlanTemplate
