import {
  SET_CHANNEL_CARE_PLAN_TITLE,
  TOGGLE_CHANNEL_MEMBER,
  REQUEST_CHANNEL_CONTACTS,
  NAME_CHANNEL,
} from 'Actions/inbox/sideNav/channel'

const initialState = { carePlanId: '', title: '', members: [] }

const toggleMembers = (id, contact) =>
  contact.id === id ? { ...contact, isSelected: !contact.isSelected } : contact

const channel = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHANNEL_CARE_PLAN_TITLE:
      return {
        ...state,
        title: action.title,
      }
    case TOGGLE_CHANNEL_MEMBER:
      return {
        ...state,
        members: state.members.map(toggleMembers.bind(null, action.member.id)),
      }
    case NAME_CHANNEL:
      return {
        ...state,
        members: action.members,
      }
    case REQUEST_CHANNEL_CONTACTS:
      return {
        ...state,
        carePlanId: action.id,
      }
    default:
      return state
  }
}

export default channel
