import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import {
  handleCloseActivityModal,
  setActivityModalState,
  setActivityModalFormErrors,
} from 'Actions/activityModal';

import {
  activityModalState as activityModalStateDefinitions,
  mode as modeDefinitions,
} from '../../modalContentStateDefinitions';
import {
  setupDescriptionValidator,
  setupVirtualVisitLinkValidator,
} from '../../FormFields/formFieldsValidators';
import handleUpdateActivity from './handleUpdateActivity';

import ActionButton from 'Components/Base/ActionButton';
import DeleteButton from './DeleteButton';

const i18nOpts = { scope: 'activity_modal.footer' };

export const fieldsValidatorsPass = (activityType, activityProperties, validators) => {
  if (activityType === 'APPOINTMENT') {
    const { validateDescription, validateVirtualVisitLink } = validators;
    const { description, virtualVisitLink } = activityProperties;
    return validateDescription(description) && validateVirtualVisitLink(virtualVisitLink);
  }
  if (activityType === 'TASK') {
    const { validateDescription } = validators;
    const { description } = activityProperties;
    return validateDescription(description);
  }

  return true;
};

export const EditFooter = ({
  carePlanId,
  activityId,
  activityType,
  activityProperties,
  activityModalState,
  formErrors,
  handleCloseActivityModal,
  setActivityModalState,
  setActivityModalFormErrors,
  onUpdate,
  resources,
  mode,
  canDeleteActivity,
}) => {
  const { LOADING } = activityModalStateDefinitions;
  const { EDIT } = modeDefinitions;

  const validatorI18nOpts = {
    scope: 'activity_modal.form_fields.validators',
  };

  const validateDescription = setupDescriptionValidator(
    formErrors,
    setActivityModalFormErrors,
    t,
    validatorI18nOpts
  );
  const validateVirtualVisitLink = setupVirtualVisitLinkValidator(
    formErrors,
    setActivityModalFormErrors,
    t,
    validatorI18nOpts
  );

  const validators = {
    validateDescription,
    validateVirtualVisitLink,
  };

  return (<div className="action_modal__footer action_modal__footer--edit">
    <div>
      {mode === EDIT && canDeleteActivity && <DeleteButton />}
    </div>
    <div className="action_modal__footer__right_col">
      <ActionButton
        className="button-subtle action_modal__footer__cancel_button"
        isLoading={activityModalState === LOADING}
        disabled={activityModalState === LOADING}
        onClick={handleCloseActivityModal}
        content={t('cancel', i18nOpts)}
      />
      <ActionButton
        isLoading={activityModalState === LOADING}
        disabled={activityModalState === LOADING}
        onClick={() => {
          if (fieldsValidatorsPass(activityType, activityProperties, validators)) {
            handleUpdateActivity({
              setActivityModalState,
              handleCloseModal: handleCloseActivityModal,
              carePlanId,
              activityId,
              activityType,
              activityProperties,
              onUpdate,
              allResources: resources,
            });
          }
        }}
        content={t('save', i18nOpts)}
      />
    </div>
  </div>);
};

const mapStateToProps = ({ activityModal }) => {
  const {
    carePlanId,
    activityId,
    activityType,
    activityProperties,
    activityModalState,
    formErrors,
    resourcesDropdown: { resources },
    mode,
    canDeleteActivity,
  } = activityModal;

  return {
    carePlanId,
    activityId,
    activityType,
    activityProperties,
    activityModalState,
    formErrors,
    resources,
    mode,
    canDeleteActivity,
  };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchHandleCloseActivityModal = (dispatch) => {
    return () => {
      dispatch(handleCloseActivityModal());
    };
  };

  const dispatchSetActivityModalState = (dispatch) => {
    return (activityModalState) => {
      dispatch(setActivityModalState(activityModalState));
    };
  };

  const dispatchSetActivityModalFormErrors = (dispatch) => {
    return (formErrors) => {
      dispatch(setActivityModalFormErrors(formErrors));
    };
  };

  return {
    handleCloseActivityModal: dispatchHandleCloseActivityModal(dispatch),
    setActivityModalState: dispatchSetActivityModalState(dispatch),
    setActivityModalFormErrors: dispatchSetActivityModalFormErrors(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditFooter);
