import React from 'react';
import { connect } from 'react-redux';

import LineChart from './LineChart';
import ScatterPlot from './ScatterPlot';

const Chart = ({ monitorType }) => {
  return monitorType === 'blood_glucose_meter' ? <ScatterPlot /> : <LineChart />;
};

const mapStateToProps = ({ dashboardComparisonChart: { monitorType } }) => {
  return { monitorType };
};

export default connect(mapStateToProps, null)(Chart);
