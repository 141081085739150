import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { connect } from 'react-redux'
import { toggleEditing } from 'Actions/documents'
import { getDocuments, getIsEditing } from 'Selectors/documents'

import Card from 'Components/Base/Card'
import { EditActionHeader } from 'Components/Base/Card/'
import Edit from './Edit'
import Show from './Show'

const i18nOpts = { scope: 'care_plans.document_section' }

class DocumentSectionContainer extends Component {
  constructor(props) {
    super(props)
  }

  get cardProps() {
    const props = {
      collapsable: true,
      id: 'documents',
      title: t(this.props.isEditing ? 'edit_header' : 'show_header', i18nOpts),
    }

    if (this.props.canEdit && !this.props.isEditing) {
      props.renderHeaderAction = (actionWrapper) => (
        <EditActionHeader onClick={() => actionWrapper(this.props.toggleEditing)} />
      )
    }

    return props
  }

  render() {
    return (
      <Card {...this.cardProps}>
        {this.props.isEditing && this.props.canEdit ? (
          <Edit
            carePlanUuid={this.props.carePlanUuid}
            documents={this.props.documents}
            healthCareView={this.props.healthCareView}
          />
        ) : (
          <Show documents={this.props.documents} />
        )}
      </Card>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  toggleEditing() {
    dispatch(toggleEditing())
  },
})

const mapStateToProps = (state) => ({
  documents: getDocuments(state),
  isEditing: getIsEditing(state),
})

DocumentSectionContainer.propTypes = {
  // dispatch
  canEdit: PropTypes.bool.isRequired,
  // state
  carePlanUuid: PropTypes.string.isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.object,
      canModify: PropTypes.bool,
      createdAt: PropTypes.string,
      documentFileName: PropTypes.string,
      downloadUrl: PropTypes.string,
      id: PropTypes.number,
      libraryDocumentId: PropTypes.number,
      name: PropTypes.string,
      url: PropTypes.string,
    })
  ).isRequired,
  // props
  healthCareView: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool,
  toggleEditing: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentSectionContainer)
