import { request } from 'Apiv2/handleRequest';
import { t } from 'i18n';

const i18nOpts = { scope: 'terms_of_service' };

export const handleSubmitAgreement = (translate = t, translationOptions = i18nOpts) => {
  return async (hasAgreed, setIsDialogOpen, setError, setIsProcessing) => {
    const endpointUrl = '/profile/accept_terms_of_service';

    setIsProcessing(true);

    if (!hasAgreed) {
      setError({ message: translate('required', translationOptions) });
      setIsProcessing(false);
      return;
    }

    try {
      const response = await request(endpointUrl, 'POST', { hasAgreed });
      if (response.status === 200) {
        setIsDialogOpen(false);
      } else {
        setError({ message: translate('error_occurred', translationOptions) });
      }
    } catch {
      setError({ message: translate('error_occurred', translationOptions) });
    }

    setIsProcessing(false);
  };
};

export default handleSubmitAgreement(t, i18nOpts);
