import React, { Component } from 'react'
import { t } from 'i18n'
import _ from 'lodash'
import PropTypes from 'prop-types'

import InputGroup from 'Components/Forms/InputGroup'
import Submit from 'Components/Forms/Submit'

import CheckInTemplateApi from 'Api/TemplateCreator/CheckInTemplate'

const i18nOpts = { scope: 'care_plan_creators.check_in_templates.name' }

class NameSection extends Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSuccess = this.handleSuccess.bind(this)
    this.handleErrors = this.handleErrors.bind(this)
    this.navigateToNextSection = this.navigateToNextSection.bind(this)

    this.state = {
      checkInTemplate: {
        name: this.props.name || '',
      },
      isSubmitting: false,
      errors: {},
    }
  }

  handleChange({ target: { name, value } }) {
    this.setState((prevState) => _.set(prevState, name, value))
  }

  handleErrors({ checkInTemplate }) {
    this.setState({ errors: { checkInTemplate: { ...checkInTemplate } } })
  }

  handleSuccess({ checkInTemplate }) {
    this.setState({
      isSubmitting: false,
      errors: {},
      checkInTemplate,
    })
    this.navigateToNextSection()
  }

  handleSubmit(evt) {
    evt.preventDefault()
    this.setState({ isSubmitting: true })

    const endpoint = new CheckInTemplateApi(this.props.resourceId).update(this.state.checkInTemplate)

    endpoint.then((res) => {
      if (res.ok) {
        res.json().then(this.handleSuccess)
      } else if (res.status === 422) {
        this.setState({ isSubmitting: false })
        res.json().then(this.handleErrors)
      }
    })
  }

  navigateToNextSection() {
    window.location.hash = '#questions'
  }

  render() {
    const { checkInTemplate, isSubmitting, errors } = this.state
    const { name } = checkInTemplate

    return (
      <form onSubmit={this.handleSubmit} noValidate>
        <div className="input string required care_plan_title">
          <label className="string required careplan-wizard__details-title" htmlFor="name">
            <abbr title="required">*</abbr> {t('title_label', i18nOpts)}
            <p className="text-primary-lighter careplan-wizard__details-subtitle">
              {t('title_sublabel', i18nOpts)}
            </p>
          </label>
        </div>

        <InputGroup
          component="input"
          placeholder={t('title_placeholder', i18nOpts)}
          name="checkInTemplate.name"
          value={name}
          errors={errors}
          onChange={this.handleChange}
          required
          type="text"
        />

        <div className="card__button-footer">
          <div className="card__button-footer-right">
            <Submit value={t('next', { scope: 'care_plan_creators.name' })} disabled={isSubmitting} />
          </div>
        </div>
      </form>
    )
  }
}

NameSection.propTypes = {
  name: PropTypes.string,
  resourceId: PropTypes.number.isRequired,
}

NameSection.defaultProps = {
  name: '',
}

export default NameSection
