import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';
import InputGroup from 'Components/Forms/InputGroup';
const i18nOpts = { scope: 'activity_modal.form_fields.when' };
import { setActivityModalActivityProperties } from 'Actions/activityModal';
import {
  mode as modeDefinitions,
} from '../../modalContentStateDefinitions';
import FieldLabel from './FieldLabel';

const DueAt = ({
  activityProperties,
  formErrors,
  timeZone,
  mode,
  setActivityModalActivityProperties,
}) => (<>
  {mode === modeDefinitions.EDIT && <FieldLabel />}
  <div className='activity_modal__input_field__container'>
    <InputGroup
      name="dueAt"
      value={activityProperties.dueAt}
      label={t('date_and_time', i18nOpts)}
      errors={formErrors}
      timeZone={timeZone}
      onChange={({ target: { value } }) => {
        setActivityModalActivityProperties({
          ...activityProperties,
          dueAt: value,
        });
      }}
      component="datetime"
      className="input due_at_input"
    />
  </div>
</>);

const mapStateToProps = ({ activityModal }) => {
  const { activityProperties, formErrors, timeZone, mode } = activityModal;

  return {
    activityProperties,
    formErrors,
    timeZone,
    mode,
  };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalActivityProperties = (dispatch) => {
    return (activityProperties) => {
      dispatch(setActivityModalActivityProperties(activityProperties));
    };
  };

  return {
    setActivityModalActivityProperties: dispatchSetActivityModalActivityProperties(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DueAt);