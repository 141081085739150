const LEGEND_COLORS_MAP = {
  morning: '#BFA0DD',
  afternoon: '#845AAE',
  evening: '#411E64',
  noData: '#FFF',
};

const calculateFill = (name, value = 0) => (value === 0 ? LEGEND_COLORS_MAP.noData : LEGEND_COLORS_MAP[name]);

export { calculateFill as default, LEGEND_COLORS_MAP as legendMap };
