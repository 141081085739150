import React from 'react';
import { t } from 'i18n';
import { connect } from 'react-redux';

const i18nOpts = { scope: 'blocks.recommendations' };

const EmptyRecommendationsList = ({ recommendations, editingRecommendationIndex }) => {
  const isRecommendationsEmpty = !recommendations?.length;
  const isAddingNewRecommendation = editingRecommendationIndex == -1;

  if (isRecommendationsEmpty && !isAddingNewRecommendation) {
    return <div className="item-pill-section--item-list-empty">{t('empty', i18nOpts)}</div>;
  }

  return null;
};

const mapStateToProps = ({ recommendationsBlock: { recommendations, editingRecommendationIndex } }) => {
  return { recommendations, editingRecommendationIndex };
};

export default connect(mapStateToProps, null)(EmptyRecommendationsList);
