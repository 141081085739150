import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import conversation from './conversation'
import sideNav from './sideNav'
import currentUser from 'Reducers/current-user'
import messageDraft from './messageDraft'

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    conversation,
    sideNav,
    currentUser,
    messageDraft,
  })
