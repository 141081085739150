import filterPatients from './filterPatients';
import filterMyPatients from './filterMyPatients';
import filterArchivedPatients from './filterArchivedPatients';
import sortPatients from './sortPatients';

const filterAndSortPatients =
  (
    filterPatientsProvider = filterPatients,
    filterMyPatientsProvider = filterMyPatients,
    sortPatientsProvider = sortPatients,
    filterArchivedPatientsProvider = filterArchivedPatients
  ) =>
  (patients, myPatientIds, isShowingMyPatients, searchInput, sortBy, archivedPatientIds, isShowingArchivedPatients) => {
    const selectedMyPatients = filterMyPatientsProvider(patients, myPatientIds, isShowingMyPatients);
    const selectedPatients = filterArchivedPatientsProvider(selectedMyPatients, archivedPatientIds, isShowingArchivedPatients);
    const filteredPatients = filterPatientsProvider(selectedPatients, searchInput);

    if (!filteredPatients.length) return filteredPatients;

    const sortedPatients = sortPatientsProvider(filteredPatients, sortBy);

    return sortedPatients;
  };

export default filterAndSortPatients(filterPatients, filterMyPatients, sortPatients, filterArchivedPatients);
