import { request } from 'Apiv2/handleRequest';

const fetchGlucoseDataForTimeRange = async (carePlanUuid, timeRange, currentUserTime, setDataState) => {
  const endpointUrl = `/care_plans/${carePlanUuid}/dashboard/blood_glucose_monitor_data?currentUserTime=${currentUserTime}&timeRange=${timeRange}`;

  const response = await request(endpointUrl);
  const parsedResponse = await response.json();

  if (parsedResponse.status !== 'SUCCESS') {
    throw new Error('data retrieval failed'); // bubble up errors to parent so that the promise is rejected
  }

  const { data } = parsedResponse;

  setDataState((state) => ({ ...state, ...data }));
};

export default fetchGlucoseDataForTimeRange;
