import React, { Component } from 'react'
import { t } from 'i18n'

import ActivitySearchWorker from 'Workers/activity_search.worker.js'
import Utilities from 'Utilities/utilities'

import Loader from 'Components/Base/Loader'
import BlankSlate from 'Components/Base/BlankSlate'
import Activity from './Activity'

const i18nOpts = { scope: 'activity_feed.index' }

class ActivityFeed extends Component {
  constructor(props) {
    super(props)

    this.initialActivities = this.initialActivities.bind(this)
    this.update = this.update.bind(this)
    this.filterActivities = this.filterActivities.bind(this)

    this.state = {
      loading: true,
      activities: [],
      currentActivities: [],
      typeFilter: null,
    }

    this.utils = new Utilities()
  }

  componentDidMount() {
    // Create a WebWorker to handle AJAX request and search index building
    this.worker = new ActivitySearchWorker()
    this.worker.addEventListener('message', this.initialActivities)

    this.searchInput = this.utils.queryCTSelector('search-box')
    this.filterInput = this.utils.queryCTSelector('search-filter')
    this.searchForm = this.utils.queryCTSelector('search-form')

    this.searchInput.addEventListener('keyup', this.search.bind(this))
    this.searchForm.addEventListener('reset', this.reset.bind(this))
    this.searchForm.addEventListener('submit', (evt) => evt.preventDefault())
    this.filterInput.addEventListener('change', this.filterActivities.bind(this))
    this.worker.postMessage({ type: 'setup', value: `${window.location.origin}${window.location.pathname}` })
  }

  initialActivities({ data: activities }) {
    this.worker.removeEventListener('message', this.initialActivities)
    this.worker.addEventListener('message', this.update)

    this.setState({
      loading: false,
      currentActivities: activities,
      activities,
    })
  }

  update({ data: currentActivities }) {
    this.setState({ currentActivities })
  }

  filterActivities({ target: { value } }) {
    this.setState({ typeFilter: value })
  }

  search() {
    this.worker.postMessage({ type: 'search', value: this.searchInput.value })
  }

  reset() {
    this.searchInput.value = ''
    this.search()
    this.searchInput.focus()
  }

  render() {
    const { loading, activities, currentActivities, typeFilter } = this.state

    if (loading) {
      return <Loader />
    } else if (!activities.length) {
      return <BlankSlate iconName="group" body={t('welcome', i18nOpts)} />
    } else {
      return (
        <ul className="activities">
          {!currentActivities.length ? (
            <BlankSlate iconName="search" body={t('empty', i18nOpts)} />
          ) : (
            currentActivities
              .filter((a) => a.type === typeFilter || !typeFilter)
              .map((a) => <Activity key={a.id} {...a} />)
          )}
        </ul>
      )
    }
  }
}

ActivityFeed.propTypes = {}

ActivityFeed.defaultProps = {}

export default ActivityFeed
