import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import Icon from 'Components/Base/Icon'

import emptyMessageIcon from 'direct_message_empty.svg';
import emptyPersonalMessageIcon from 'direct_message_personal_empty.svg';

const i18nOpts = { scope: 'inbox.conversation.messages' }

class BlankMessage extends PureComponent {
  constructor(props) {
    super(props)

    this.renderEmpty = this.renderEmpty.bind(this)
    this.renderNewDeskConversation = this.renderNewDeskConversation.bind(this);
    this.renderNewDirectConversation = this.renderNewDirectConversation.bind(this);
    this.renderConversations = this.renderConversations.bind(this);
  }

  renderEmpty() {
    return (
      <Fragment>
        <img src={emptyMessageIcon} className='empty-messages__personal-message'/>
        <p>{t('new_desk_conversation_one', { scope: 'inbox.conversation.messages' })}</p>
      </Fragment>
    )
  }

  renderNewDeskConversation() {
    return (
      <Fragment>
        <img src={emptyMessageIcon} className='empty-messages__desk-message'/>
        <p>{t('new_desk_conversation_one', i18nOpts)}</p>
        <p>{t('new_desk_conversation_two', { ...i18nOpts, name: this.props.name })}</p>
        <Icon name="down_arrow" className="icon messages__icon messages__icon--arrow" />
      </Fragment>
    )
  }

  renderNewDirectConversation() {
    return (
      <Fragment>
        <img src={emptyPersonalMessageIcon} className='empty-messages__personal-message'/>
        <p>{t('new_conversation_one', { ...i18nOpts, name: this.props.name })}</p>
        <p>{t('new_conversation_two', i18nOpts)}</p>
      </Fragment>
    )
  }

  renderConversations() {
    if (this.props.noConversations) {
      return this.renderEmpty();
    } else if (this.props.type === 'DESK_CONVERSATION') {
      return this.renderNewDeskConversation();
    } else {
      return this.renderNewDirectConversation();
    }
  }

  render() {
    return (
      <div className="message message--blank text-brand">
        {this.renderConversations()}
      </div>
    )
  }
}

BlankMessage.propTypes = {
  disclaimer: PropTypes.string,
  name: PropTypes.string,
  noConversations: PropTypes.bool,
  type: PropTypes.string,
}

BlankMessage.defaultProps = {
  disclaimer: null,
  name: '',
  noConversations: false,
  type: '',
}

export default BlankMessage
