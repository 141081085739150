import { request } from 'Apiv2/handleRequest';

const getMyPatientsList =
  (requestProvider = request) =>
  async () => {
    try {
      const ENDPOINT_URL = '/patients/my_patients';
      const response = await requestProvider(ENDPOINT_URL, 'GET');

      if (response.status === 200) {
        const { my_patient_ids, show_my_patient_list } = await response.json();

        return {
          responseType: 'DATA',
          myPatientIds: my_patient_ids,
          isShowingMyPatients: show_my_patient_list,
        };
      } else {
        throw 'Missing data';
      }
    } catch (error) {
      console.error(error);
      return { responseType: 'ERROR' };
    }
  };

export default getMyPatientsList(request);
