import Api from 'Api/Api'

class MedicalDocumentTemplate extends Api {
  constructor(carePlanTemplateId, id) {
    super()

    this.id = id
    this.carePlanTemplateId = carePlanTemplateId
    this.previewLink = this.previewLink.bind(this)
  }

  previewLink() {
    return this.request(`${this.url}/${this.id}/preview_link`)
  }

  get url() {
    return `/care_plan_templates/${this.carePlanTemplateId}/medical_document_templates`
  }
}

export default MedicalDocumentTemplate
