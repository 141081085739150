import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import keycodes from 'Utilities/keycodes';

import Locale from '../Base/Locale';
import Backdrop from '../Base/Backdrop';
import Icon from 'Components/Base/Icon';

class LocaleSelect extends Component {
  constructor(props) {
    super(props);

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.renderOption = this.renderOption.bind(this);
    this.handleMenuNavigation = this.handleMenuNavigation.bind(this);

    this.state = {
      isOpen: this.props.isOpen || false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ isOpen: nextProps.isOpen });
  }

  open(event) {
    const keycode = keycodes[event.which];

    if (!keycode || ['ENTER', 'SPACE', 'UP', 'DOWN'].includes(keycode)) {
      this.setState({ isOpen: true }, () => this.refs[this.props.value].focus());
    }
  }

  close() {
    this.setState({ isOpen: false });
  }

  handleSelect(value, event) {
    const keycode = keycodes[event.which];

    if (!keycode || ['ENTER', 'SPACE'].includes(keycode)) {
      this.close();
      this.props.onChange(value);
    }
  }

  handleMenuNavigation(event) {
    event.preventDefault();

    const keycode = keycodes[event.which];
    const activeNode = document.activeElement;

    if (keycode === 'UP' && activeNode.previousSibling) {
      activeNode.previousSibling.focus();
    } else if (keycode === 'DOWN' && activeNode.nextSibling) {
      activeNode.nextSibling.focus();
    } else if (keycode === 'ESCAPE') {
      this.close();
    }
  }

  renderOption(value) {
    return (
      <div
        key={value}
        tabIndex="0"
        ref={value}
        className="select-option select-option__locale-options"
        onKeyDown={this.handleSelect.bind(null, value)}
        onClick={this.handleSelect.bind(null, value)}
      >
        <Locale locale={value} />
        {this.props.value === value && <Icon name="circle_check" className="icon--primary" />}
      </div>
    );
  }

  render() {
    const classes = classNames('select-input', {
      'select-input--borderless': !this.props.displayBorder,
    });

    return (
      <div style={{ position: 'relative' }} id={this.props.name}>
        <div className={classes} onKeyDown={this.open} onClick={this.open} tabIndex="0">
          <Locale locale={this.props.value} />
        </div>
        <div
          onKeyDown={this.handleMenuNavigation}
          className={`select-list ${this.state.isOpen && 'select-list--show'}`}
        >
          {this.props.availableLocales.map(this.renderOption)}
        </div>
        {this.state.isOpen && <Backdrop transparent onClick={this.close} />}
      </div>
    );
  }
}

LocaleSelect.propTypes = {
  availableLocales: PropTypes.array.isRequired,
  displayBorder: PropTypes.bool,
  isOpen: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

LocaleSelect.defaultProps = {
  displayBorder: true,
};

export default LocaleSelect;
