import React from 'react'
import PropTypes from 'prop-types'

import TextGroup from './TextGroup'
import Status from './Status'
import Details from './Details'
import Avatar from 'PulseBackports/Avatar'

const Invitation = ({
  url,
  patientPhoto,
  patientName,
  carePlanTitle,
  placeName,
  status,
  caregiverName,
  caregiverEmail,
  submittedDate,
  resolvedDate,
  requestType,
}) => {
  return (
    <a href={url} className="item-pill item-pill--stretch item-pill--flat invitation">
      <div className="invitation__profile">
        <div className="invitation__photo">
          <Avatar
            src={patientPhoto}
            fullName={patientName}
            size={2.5}
          />
        </div>
        <TextGroup text={patientName} subtext={carePlanTitle} />
      </div>

      <Details
        placeName={placeName}
        requestType={requestType}
        caregiverName={caregiverName}
        caregiverEmail={caregiverEmail}
        submittedDate={submittedDate}
        resolvedDate={resolvedDate}
      />
      <Status status={status} />
    </a>
  )
}

Invitation.propTypes = {
  carePlanTitle: PropTypes.string,
  caregiverEmail: PropTypes.string,
  caregiverName: PropTypes.string,
  patientName: PropTypes.string.isRequired,
  patientPhoto: PropTypes.string,
  placeName: PropTypes.string.isRequired,
  requestType: PropTypes.string.isRequired,
  resolvedDate: PropTypes.string,
  status: PropTypes.string.isRequired,
  submittedDate: PropTypes.string,
  url: PropTypes.string.isRequired,
}

Invitation.defaultProps = {
  carePlanTitle: '',
  caregiverEmail: '',
  caregiverName: '',
  resolvedDate: '',
  submittedDate: '',
}

export default Invitation
