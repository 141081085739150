import { PureComponent } from 'react'
import PropTypes from 'prop-types'

class YesNoQuestion extends PureComponent {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    if (!this.props.checkInQuestionOptions.length) {
      this.props.onChange({ target: { name: 'checkInQuestionOptions', value: this.defaultOptions } })
    }
  }

  get defaultOptions() {
    return [
      {
        type: 'BooleanBasedOption',
        data: {
          index: 0,
          order: 1,
          score: 0,
        },
      },
      {
        type: 'BooleanBasedOption',
        data: {
          index: 1,
          order: 0,
          score: 1,
        },
      },
    ]
  }

  render() {
    return null
  }
}

YesNoQuestion.propTypes = {
  checkInQuestionOptions: PropTypes.array,
  onChange: PropTypes.func,
}

YesNoQuestion.defaultProps = {
  checkInQuestionOptions: [],
  onChange: null,
}

export default YesNoQuestion
