import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'Components/Base';

const ItemPillLargeActionV2 = ({ iconName, label, onClick, iconClass }) => (
  <div className="item-pill__action item-pill__action--large" onClick={onClick} tabIndex="0">
    <div className="icon">
      <Icon name={iconName} className={iconClass} />
    </div>
    <small className="text-brand">{label}</small>
  </div>
);

ItemPillLargeActionV2.propTypes = {
  iconName: PropTypes.string.isRequired,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  iconClass: PropTypes.string,
};

ItemPillLargeActionV2.defaultProps = {
  label: '',
};

export default ItemPillLargeActionV2;
