import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TagForm from './TagForm'
import { set } from 'lodash'
import TagApi from 'Api/Tag'

class TagFormContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      id: props.tag.id,
      text: props.tag.text,
      status: props.tag.status,
      errors: {},
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleErrors = this.handleErrors.bind(this)
    this.handleRedirect = this.handleRedirect.bind(this)
    this.handleDestroy = this.handleDestroy.bind(this)
  }

  handleChange({ target: { name, value } }) {
    this.setState((prevState) => set(prevState, name, value))
  }

  handleSubmit(evt) {
    evt.preventDefault()
    const creatorId = this.props.tag.creatorId || ''
    const params = {
      tag: { text: this.state.text, status: this.state.status, creator_id: creatorId },
    }
    const endpoint = this.props.tag.id ? this.api.update(params) : this.api.create(params)

    endpoint.then((res) => {
      if (res.ok) {
        res.json().then(this.handleRedirect)
      } else if (res.status === 422) {
        res.json().then(this.handleErrors)
      }
    })
  }

  handleErrors(errors) {
    this.setState({ errors })
  }

  handleRedirect() {
    window.location.assign(this.props.url)
  }

  handleDestroy() {
    return this.api.destroy().then((res) => {
      if (res.ok) {
        this.handleRedirect()
      }
    })
  }

  get api() {
    return new TagApi(this.props.tag.id)
  }

  render() {
    return (
      <div className="card">
        <TagForm
          tag={this.state}
          errors={this.state.errors}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          onRedirect={this.handleRedirect}
          onDestroy={this.handleDestroy}
        />
      </div>
    )
  }
}

TagFormContainer.propTypes = { tag: PropTypes.object.isRequired, url: PropTypes.string.isRequired }

export default TagFormContainer
