import { request } from 'Apiv2/handleRequest';

const fetchInsulinDataApi = async (carePlanUuid) => {
  const endpointUrl = `/care_plans/${carePlanUuid}/dashboard/insulin_dosing_data`;

  try {
    const response = await request(endpointUrl, 'GET');
    const { data, created_by_user_name, updated_at, errors } = await response.json();

    if (errors) {
      throw errors;
    }

    if (response.status === 200) {
      return { content_type: 'INSULIN_DATA', data, created_by_user_name, updated_at };
    } else {
      return { content_type: 'ERROR', errors: ['UNKNOWN_STATUS'] };
    }
  } catch (error) {
    return { content_type: 'ERROR', error };
  }
};

export default fetchInsulinDataApi;
