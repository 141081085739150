import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import { setActivityModalLibraryDropdownIsOpen } from 'Actions/activityModal';

import { Button } from 'Components/Base';
import DotsLoader from 'Components/Base/DotsLoader';
import Backdrop from 'Components/Base/Backdrop';
import TemplateSelector from './TemplateSelector/TemplateSelector';
import TemplateError from './TemplateError';
import FormField from '../FormField';
import LibraryMenu from './LibraryMenu/LibraryMenu';

const i18nOpts = { scope: 'activity_modal.form_fields.select_from_library' };

export const SelectFromLibrary = ({
  activityProperties,
  libraryDropdown,
  setActivityModalLibraryDropdownIsOpen,
}) => {
  const { isOpen, libraryDropdownState } = libraryDropdown;

  let content;
  switch (libraryDropdownState) {
    case 'LOADING':
      content = (
        <div className="library_dropdown">
          <DotsLoader />
        </div>
      );
      break;
    case 'ERROR':
      content = <div className="library_dropdown library_dropdown--error">{t('api_error', i18nOpts)}</div>;
      break;
    case 'DEFAULT':
      content = <LibraryMenu />;
      break;
    default:
      content = <div className="library_dropdown library_dropdown--error">{t('api_error', i18nOpts)}</div>;
      break;
  }

  return (
    <div className="activity_modal__library">
      <TemplateSelector />
      <TemplateError />

      {isOpen && (
        <div className="activity_modal__library__menu_container">
          {content}
          <Backdrop
            transparent
            onClick={() => {
              setActivityModalLibraryDropdownIsOpen(false);
            }}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ activityModal }) => {
  const { libraryDropdown, activityProperties } = activityModal;

  return { libraryDropdown, activityProperties };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalLibraryDropdownIsOpen = (dispatch) => {
    return (isOpen) => {
      dispatch(setActivityModalLibraryDropdownIsOpen(isOpen));
    };
  };

  return {
    setActivityModalLibraryDropdownIsOpen: dispatchSetActivityModalLibraryDropdownIsOpen(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectFromLibrary);
