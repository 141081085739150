import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import Textarea from 'react-autosize-textarea'
import Submit from 'Components/Forms/Submit'

const i18nOpts = { scope: 'activity_feed.comments.form' }

const Form = ({ text, errors, loading, onChange, onSubmit }) => (
  <Fragment>
    <form className="form status-form" onSubmit={onSubmit}>
      <Textarea
        value={text}
        onChange={onChange}
        component="textarea"
        name="text"
        errors={errors}
        rows={1}
        placeholder={t('text', i18nOpts)}
      />

      <Submit value={t('submit', i18nOpts)} className="btn" disabled={loading} />
    </form>

    <small className="text-grey-alt">{t('note', i18nOpts)}</small>
  </Fragment>
)

Form.propTypes = {
  errors: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
}

Form.defaultProps = {
  loading: false,
}

export default Form
