import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'Components/Base/Icon';
import { t } from 'i18n';

const RenderCategories = ({ categories }) => (
  <div className="block-visual block-visual__categories">
    {categories.map((category, index) => (
      <div key={index} className="block-visual__categories__symbol">
        {category === t('no_data', { scope: 'blocks' }) ? (
          <Icon key={index} name="close" className={`item item--${index} no-data`} />
        ) : (
          <div className={`item item--${index}`} />
        )}
        <span className="description">{category}</span>
      </div>
    ))}
  </div>
);

RenderCategories.propTypes = { categories: PropTypes.array.isRequired };

export default RenderCategories;
