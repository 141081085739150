import React from 'react';
import { EditActionHeader, CancelActionHeader } from 'Components/Base/Card';

const headerAction = (isEditing, setIsEditing) => {
  return isEditing
    ? (actionWrapper) => <CancelActionHeader onClick={() => actionWrapper(() => setIsEditing(false))} />
    : (actionWrapper) => <EditActionHeader onClick={() => actionWrapper(() => setIsEditing(true))} />;
};

export default headerAction;
