import React from 'react'
import PropTypes from 'prop-types'

import { t } from 'i18n'
const i18nOpts = { scope: 'team.health_team' }

const PendingPlaceInvitations = ({ pendingPlaceInvitations, render }) => (
  <div className="card-subsection">
    <h3 className="card-subsection__header">{t('pending_health_teams', i18nOpts)}</h3>

    <ul className="item-pill-section__item-list ">{pendingPlaceInvitations.map(render)}</ul>
  </div>
)

PendingPlaceInvitations.propTypes = {
  pendingPlaceInvitations: PropTypes.array,
  render: PropTypes.func.isRequired,
}

PendingPlaceInvitations.defaultProps = {
  pendingPlaceInvitations: [],
}

export default PendingPlaceInvitations
