import React from 'react';
import { t } from 'i18n';

const i18nOpts = { scope: 'patient.index' };

const Header = () => (
  <div className="patients patients__filter-patients input">
    <h2>{t('header', i18nOpts)}</h2>
  </div>
);

export default Header;
