import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'
import _ from 'lodash'
import vex from 'CT/vex'

import Checkbox from 'Components/Forms/Checkbox'
import InputGroup from 'Components/Forms/InputGroup'
import RelationshipInput from 'Components/Forms/RelationshipInput'
import Submit from 'Components/Forms/Submit'

const i18nOpts = { scope: 'team.user_form' }

class UserForm extends Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.togglePermission = this.togglePermission.bind(this)
    this.confirm = this.confirm.bind(this)
    this.consentCheckbox = this.consentCheckbox.bind(this)

    this.state = {
      role: props.role,
      permission: !props.needsPatientPermission,
      relationship: 'other',
      invitation: {
        email: '',
      },
    }
  }

  togglePermission() {
    this.setState((prevState) => ({ permission: !prevState.permission }))
  }

  handleChange({ target: { name, value } }) {
    this.setState((prevState) => _.set(prevState, name, value))
  }

  handleSubmit(event) {
    event.preventDefault()
    vex.dialog.confirm({
      message: t('confirm', i18nOpts),
      callback: this.confirm,
    })
  }

  confirm(value) {
    if (!value) return
    const {
      role,
      permission,
      relationship,
      invitation: { email },
    } = this.state

    return this.props
      .createSupportTeamEntry(this.props.carePlanId, email, relationship, role, permission)
      .then(() => {
        if (_.isEmpty(this.props.errors)) this.props.toggleForm()
      })
  }

  consentCheckbox() {
    return (
      <Checkbox
        checked={this.state.permission}
        name="permission"
        text={t('permission', { scope: 'care_plan_creators.careteam.primary_caregiver_form' })}
        errors={this.props.errors}
        onChange={this.togglePermission}
      />
    )
  }

  render() {
    return (
      <form className="form form--background" onSubmit={this.handleSubmit} noValidate>
        <div className="form--inline">
          <InputGroup
            className="input input--flex"
            component="input"
            errors={this.props.errors}
            name="invitation.email"
            onChange={this.handleChange}
            label={t('email', i18nOpts)}
            value={this.state.invitation.email}
            required
            type="email"
          />
          <RelationshipInput
            className="input input--flex"
            name="relationship"
            errors={this.props.errors}
            value={this.state.relationship}
            label={t('relationship', { ...i18nOpts, name: this.props.patientName })}
            onChange={this.handleChange}
          />
          {this.props.needsPatientPermission && (
            <div className="hidden--lg hidden--med">{this.consentCheckbox()}</div>
          )}
          <Submit value={t('submit', i18nOpts)} />
        </div>
        {this.props.needsPatientPermission && <div className="hidden--sm">{this.consentCheckbox()}</div>}
      </form>
    )
  }
}

UserForm.propTypes = {
  carePlanId: PropTypes.string.isRequired,
  createSupportTeamEntry: PropTypes.func.isRequired,
  errors: PropTypes.object,
  needsPatientPermission: PropTypes.bool.isRequired,
  patientName: PropTypes.string,
  role: PropTypes.string.isRequired,
  toggleForm: PropTypes.func,
}

UserForm.defaultProps = {
  errors: {},
  patientName: null,
  toggleForm: null,
}

export default UserForm
