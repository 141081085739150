import React, { useState } from 'react';
import { t } from 'i18n';
import PropTypes from 'prop-types';

import InputGroup from 'Components/Forms/InputGroup';
import Submit from 'Components/Forms/Submit';
import { Footer } from 'Components/Base/Card/';
import { Button, Icon } from 'Components/Base/';
import CalendarModal from './CalendarModal';
import setupStartAtUpdater from './setupStartAtUpdater';

const i18nOpts = { scope: 'care_plan_creators.details.details_form' };

const DetailsForm = ({
  currentDate,
  onSubmit,
  errors,
  title,
  onChange,
  startAt,
  resourceId,
  diagnosesView,
  isSubmitting,
  isTemplate,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalState, setModalStatus] = useState('ready');
  const [error, setDateApiError] = useState(null);
  const [date, setStartAt] = useState(startAt);

  const closeModal = () => {
    setModalIsOpen(false);
    setDateApiError(null);
    setStartAt(startAt)
  }

  const updateStartAt = setupStartAtUpdater(
    date,
    resourceId,
    setStartAt,
    setModalIsOpen,
    setModalStatus,
    setDateApiError,
    modalState
  );

  return (
    <form onSubmit={onSubmit} noValidate>
      <div className="input string required care_plan_title">
        <label className="string required careplan-wizard__details-title">
          <abbr title="required">*</abbr> {t('title_label', i18nOpts)}
          <p className="text-primary-lighter careplan-wizard__details-subtitle">
            {t('title_sublabel', i18nOpts)}
          </p>
        </label>
      </div>

      <InputGroup
        component="input"
        placeholder={t('title_placeholder', i18nOpts)}
        name="carePlan.title"
        value={title}
        errors={errors}
        onChange={onChange}
        required
        type="text"
      />

      {diagnosesView}

      <CalendarModal
        modalState={modalState}
        isOpen={modalIsOpen}
        date={date}
        onClose={closeModal}
        currentDate={currentDate}
        originalDate={startAt}
        setStartAt={setStartAt}
        updateStartAt={updateStartAt}
        setModalStatus={setModalStatus}
        errors={error}
      />

      <Footer
        left={
          !isTemplate && (
            <>
              <div className="card__button-footer-left-wide">
                <div className={'card__button-footer-left-wide__text-wrapper'}>
                  {t('date', { ...i18nOpts, startAt })}
                </div>
                <Button
                  type="button"
                  className="btn--link card__header-link"
                  onClick={() => {
                    setModalIsOpen(true);
                  }}
                >
                  <Icon name="pencil-edit_alt" className="card__header-link-icon" />
                  <span>{t('edit', i18nOpts)}</span>
                </Button>
              </div>
            </>
          )
        }
        right={
          <>
            <Submit value={t('next', { scope: 'care_plan_creators.details' })} disabled={isSubmitting} />
          </>
        }
      />
    </form>
  );
};

DetailsForm.propTypes = {
  currentDate: PropTypes.string,
  diagnosesView: PropTypes.node,
  errors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  resourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  startAt: PropTypes.string.isRequired,
  title: PropTypes.string,
};

DetailsForm.defaultProps = {
  title: '',
  errors: {},
};

export default DetailsForm;
