import { connect } from 'react-redux'

import { closeModal } from 'Actions/modal'
import {
  approveRequest,
  declineRequest,
  changeRole,
  toggleConsent,
  setModalView,
} from 'Actions/accessRequests'
import {
  getSelectedRequest,
  getRole,
  getConsent,
  getIsSubmitting,
  getIsPlaceUser,
  getModalView,
} from 'Selectors/accessRequests'

import { AcceptanceModal } from './'

const mapStateToProps = (state) => ({
  accessRequest: getSelectedRequest(state),
  consent: getConsent(state),
  isSubmitting: getIsSubmitting(state),
  role: getRole(state),
  isPlaceUser: getIsPlaceUser(state),
  view: getModalView(state),
})

const mapDispatchToProps = {
  onApprove: approveRequest,
  onChange: changeRole,
  onClose: closeModal,
  onDecline: declineRequest,
  onToggleConsent: toggleConsent,
  setModalView,
}

export default connect(mapStateToProps, mapDispatchToProps)(AcceptanceModal)
