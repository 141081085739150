import { request } from 'Apiv2/handleRequest';
import { activityType } from '../../../modalContentStateDefinitions';

export const buildRequestURL = (carePlanId, type) => {
  const { APPOINTMENT, CHECK_IN, FORM, TASK } = activityType;

  let url = `/care_plans/${carePlanId}/activity_modal/`;

  switch (type) {
    case CHECK_IN:
      url += 'check_in_templates';
      break;

    case FORM:
      url += 'form_templates';
      break;

    default:
      url = '/404';
  }

  return url;
};

export const getTemplatesApiWrapper = (
  requestProvider = request,
  buildRequestURLProvider = buildRequestURL
) => {
  return async (carePlanId, type) => {
    try {
      const url = buildRequestURLProvider(carePlanId, type);
      const response = await requestProvider(url, 'GET');

      const status = response.status;

      if (status === 200) {
        const { templates } = await response.json();
        return {
          responseType: 'DATA',
          templates,
        };
      } else {
        throw 'Missing data';
      }
    } catch (error) {
      console.error(error);
      return { responseType: 'ERROR' };
    }
  };
};

export default getTemplatesApiWrapper(request, buildRequestURL);
