import Api from './Api'

class AccessRequest extends Api {
  constructor(id = null) {
    super()

    this.id = id
  }

  accept({ role, consent }) {
    return super.request(`${this.url}/${this.id}/accept`, 'PUT', { role, consent })
  }

  decline() {
    return super.request(`${this.url}/${this.id}/decline`, 'PUT')
  }

  get url() {
    return `/access_requests`
  }
}

export default AccessRequest
