export const SET_CAREPLAN_ID = 'SET_CAREPLAN_ID';
export const SET_SESSION_INFO_STATE = 'SET_SESSION_INFO_STATE';
export const SET_SESSION_INFO_DATA = 'SET_SESSION_INFO_DATA';
export const SET_APPOINTMENTS_CARD_STATE = 'SET_APPOINTMENTS_CARD_STATE';
export const SET_APPOINTMENTS_CARD_APPOINTMENTS_LIST = 'SET_APPOINTMENTS_CARD_APPOINTMENTS_LIST';
export const SET_CHECK_INS_CARD_STATE = 'SET_CHECK_INS_CARD_STATE';
export const SET_CHECK_INS_CARD_CHECK_INS_LIST = 'SET_CHECK_INS_CARD_CHECK_INS_LIST';
export const SET_TASKS_CARD_STATE = 'SET_TASKS_CARD_STATE';
export const SET_TASKS_CARD_TASKS_LIST = 'SET_TASKS_CARD_TASKS_LIST';
export const SET_FORMS_CARD_STATE = 'SET_FORMS_CARD_STATE';
export const SET_FORMS_CARD_FORMS_LIST = 'SET_FORMS_CARD_FORMS_LIST';

const setCarePlanId = (carePlanId) => ({
  type: SET_CAREPLAN_ID,
  carePlanId,
});

const setSessionInfoState = (sessionInfoState) => ({
  type: SET_SESSION_INFO_STATE,
  sessionInfoState,
});

const setSessionInfoData = (data) => ({
  type: SET_SESSION_INFO_DATA,
  data,
});

const setAppointmentsCardState = (appointmentsCardState) => ({
  type: SET_APPOINTMENTS_CARD_STATE,
  appointmentsCardState,
});

const setAppointmentsCardAppointmentsList = (appointmentsList) => ({
  type: SET_APPOINTMENTS_CARD_APPOINTMENTS_LIST,
  appointmentsList,
});

const setCheckInsCardState = (checkInsCardState) => ({
  type: SET_CHECK_INS_CARD_STATE,
  checkInsCardState,
});

const setCheckInsCardCheckInsList = (checkInsList) => ({
  type: SET_CHECK_INS_CARD_CHECK_INS_LIST,
  checkInsList,
});

const setTasksCardState = (tasksCardState) => ({
  type: SET_TASKS_CARD_STATE,
  tasksCardState,
});

const setTasksCardTasksList = (tasksList) => ({
  type: SET_TASKS_CARD_TASKS_LIST,
  tasksList,
});

const setFormsCardState = (formsCardState) => ({
  type: SET_FORMS_CARD_STATE,
  formsCardState,
});

const setFormsCardFormsList = (formsList) => ({
  type: SET_FORMS_CARD_FORMS_LIST,
  formsList,
});

export {
  setCarePlanId,
  setSessionInfoState,
  setSessionInfoData,
  setAppointmentsCardState,
  setAppointmentsCardAppointmentsList,
  setCheckInsCardState,
  setCheckInsCardCheckInsList,
  setTasksCardState,
  setTasksCardTasksList,
  setFormsCardState,
  setFormsCardFormsList,
};
