import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import InputGroup from '../../Forms/InputGroup'
import Checkbox from '../../Forms/Checkbox'

const i18nOpts = { scope: 'care_plans.prescription_section.prescription_form' }

const PrescriptionForm = ({
  className,
  handleSubmit,
  handleChange,
  errors,
  text,
  isTemplate,
  renderSubmit,
  completedAt,
}) => (
  <form className={className} onSubmit={handleSubmit} noValidate>
    <InputGroup
      component="textarea"
      label={t('medication', i18nOpts)}
      errors={errors}
      name="text"
      onChange={handleChange}
      value={text}
      required
      type="textarea"
    />

    {!isTemplate && (
      <Checkbox
        name="completedAt"
        text={t('completed_at', i18nOpts)}
        checked={!completedAt}
        onChange={() =>
          handleChange({
            target: { name: 'completedAt', value: !completedAt },
          })
        }
      />
    )}

    {renderSubmit()}
  </form>
)

PrescriptionForm.propTypes = {
  className: PropTypes.string,
  completedAt: PropTypes.bool,
  errors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  renderSubmit: PropTypes.func.isRequired,
  text: PropTypes.string,
}

PrescriptionForm.defaultProps = {
  className: 'form',
  errors: {},
  text: '',
  completedAt: false,
}

export default PrescriptionForm
