import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'i18n';
import moment from 'ct-moment';

import { roundTimeUp } from 'Utilities/time';

import {
  setActivityModalIsOpen,
  setActivityModalCarePlanId,
  setActivityModalMode,
  setActivityModalActivityType,
  setActivityModalTimeZone,
  setActivityModalShowScheduledFields,
  setActivityModalShowRepeatsFields,
  setActivityModalActivityProperties,
} from 'Actions/activityModal';

import { Card, AddActionHeader } from 'Components/Base/Card/';
import Loader from 'Components/Base/Loader';
import Appointment from './Appointment';
import RepeatedAppointment from './RepeatedAppointment';

const i18nOpts = { scope: 'care_plan_action_plan_page.activities_container.appointments_card' };

const renderHeaderAction = (onClick) => {
  return (actionWrapper) => {
    return (
      <AddActionHeader
        onClick={() => {
          actionWrapper(onClick);
        }}
      />
    );
  };
};

const renderAppointmentsList = (appointments, repeatedAppointments) => {
  if (appointments?.length === 0 && repeatedAppointments?.length === 0) {
    return <div className="item-pill-section--item-list-empty">{t('empty', i18nOpts)}</div>;
  }

  return (
    <ul>
      {appointments.map((appointment) => {
        return (
          <Appointment
            key={`appointment-${appointment.id}`}
            practitioner={appointment.practitioner}
            link={appointment.link}
            text={appointment.text}
            url={appointment.url}
            dueAtFormatted={appointment.dueAtFormatted}
            completedAt={appointment.completedAt}
            helpRequest={appointment.helpRequest}
          />
        );
      })}

      {repeatedAppointments.map((repeatedAppointment) => {
        return (
          <RepeatedAppointment
            key={`repeated-appointment-${repeatedAppointment.id}`}
            name={repeatedAppointment.text}
            localizedFrequency={repeatedAppointment.localizedFrequency}
            until={repeatedAppointment.until}
            formattedTime={repeatedAppointment.formattedTime}
          />
        );
      })}
    </ul>
  );
};

const AppointmentsCard = ({
  carePlanId,
  sessionInfo,
  appointmentsCard,
  setActivityModalIsOpen,
  setActivityModalCarePlanId,
  setActivityModalMode,
  setActivityModalActivityType,
  setActivityModalTimeZone,
  setActivityModalShowScheduledFields,
  setActivityModalShowRepeatsFields,
  setActivityModalActivityProperties,
  activityProperties,
}) => {
  const { appointmentsCardState, appointments, repeatedAppointments } = appointmentsCard;

  let content;
  switch (appointmentsCardState) {
    case 'LOADING':
      content = <Loader />;
      break;
    case 'ERROR':
      content = <div className="activities-container__error">{t('api_error', i18nOpts)}</div>;
      break;
    case 'MAIN_CONTENT':
      content = renderAppointmentsList(appointments, repeatedAppointments);
      break;
    default:
      content = <div className="activities-container__error">{t('unexpected_error', i18nOpts)}</div>;
      break;
  }

  return (
    <Card
      id='appointments'
      title={t('title', i18nOpts)}
      cardContainerClass='activities-card-container'
      collapsable={true}
      renderHeaderAction={
        sessionInfo.canAdd &&
        renderHeaderAction(() => {
          setActivityModalMode('CREATE');
          setActivityModalActivityType('APPOINTMENT');

          // we only want the default to be scheduled for appointments
          setActivityModalShowScheduledFields(true)
          setActivityModalShowRepeatsFields(false)

          setActivityModalActivityProperties({
            ...activityProperties,
            frequencyType: 'scheduled',
            dueAt: roundTimeUp(5, moment()).tz(sessionInfo.timeZone).format(),
          })

          // carePlanId and timeZone can be fetched as an api method on the modal
          // something to consider
          // in the future it needs to be in redux and availabel to every component
          setActivityModalCarePlanId(carePlanId);
          setActivityModalTimeZone(sessionInfo.timeZone);
          setActivityModalIsOpen(true);
        })
      }
    >
      {content}
    </Card>
  );
};

const mapStateToProps = ({ carePlanActionPlanViewPage }) => {
  const { carePlanId, sessionInfo, appointmentsCard, activityProperties } = carePlanActionPlanViewPage;

  return {
    carePlanId,
    sessionInfo,
    appointmentsCard,
    activityProperties,
  };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalIsOpen = (dispatch) => {
    return (isOpen) => {
      dispatch(setActivityModalIsOpen(isOpen));
    };
  };

  const dispatchSetActivityModalMode = (dispatch) => {
    return (mode) => {
      dispatch(setActivityModalMode(mode));
    };
  };

  const dispatchSetActivityModalActivityType = (dispatch) => {
    return (activityType) => {
      dispatch(setActivityModalActivityType(activityType));
    };
  };

  const dispatchSetActivityModalCarePlanId = (dispatch) => {
    return (carePlanId) => {
      dispatch(setActivityModalCarePlanId(carePlanId));
    };
  };

  const dispatchSetActivityModalTimeZone = (dispatch) => {
    return (timeZone) => {
      dispatch(setActivityModalTimeZone(timeZone));
    };
  };

  const dispatchSetActivityModalShowScheduledFields = (dispatch) => {
    return (showScheduledFields) => {
      dispatch(setActivityModalShowScheduledFields(showScheduledFields));
    };
  };

  const dispatchSetActivityModalShowRepeatsFields = (dispatch) => {
    return (showRepeatsFields) => {
      dispatch(setActivityModalShowRepeatsFields(showRepeatsFields));
    };
  };

  const dispatchSetActivityModalActivityProperties= (dispatch) => {
    return (activityProperties) => {
      dispatch(setActivityModalActivityProperties(activityProperties));
    };
  };

  return {
    setActivityModalIsOpen: dispatchSetActivityModalIsOpen(dispatch),
    setActivityModalCarePlanId: dispatchSetActivityModalCarePlanId(dispatch),
    setActivityModalMode: dispatchSetActivityModalMode(dispatch),
    setActivityModalActivityType: dispatchSetActivityModalActivityType(dispatch),
    setActivityModalTimeZone: dispatchSetActivityModalTimeZone(dispatch),
    setActivityModalShowScheduledFields: dispatchSetActivityModalShowScheduledFields(dispatch),
    setActivityModalShowRepeatsFields: dispatchSetActivityModalShowRepeatsFields(dispatch),
    setActivityModalActivityProperties: dispatchSetActivityModalActivityProperties(dispatch),
  };
};

AppointmentsCard.propTypes = {
  carePlanId: PropTypes.string,
  sessionInfo: PropTypes.object,
  appointmentsCard: PropTypes.object,
  setActivityModalIsOpen: PropTypes.func,
  setActivityModalCarePlanId: PropTypes.func,
  setActivityModalMode: PropTypes.func,
  setActivityModalActivityType: PropTypes.func,
  setActivityModalTimeZone: PropTypes.func,
  setActivityModalShowScheduledFields: PropTypes.func,
  setActivityModalShowRepeatsFields: PropTypes.func,
  setActivityModalActivityProperties: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentsCard);
