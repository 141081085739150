import { request } from 'Apiv2/handleRequest';

const fetchDeviceData = async (carePlanUuid, currentUserTime) => {
  const endpointUrl = `/care_plans/${carePlanUuid}/dashboard/device_data?currentUserTime=${currentUserTime}`;

  try {
    const response = await request(endpointUrl);
    const data = await response.json();

    if (data.status === 'FAILURE') {
      throw new Error('data retrieval failed');
    }

    return { status: 'success', data };
  } catch (error) {
    return { status: 'error', message: error.message };
  }
};

export default fetchDeviceData;
