import { request } from 'Apiv2/handleRequest';

export const getCheckInsApiWrapper = (requestProvider = request) => {
  return async (carePlanId) => {
    try {
      const response = await requestProvider(
        `/care_plans/${carePlanId}/care_plan_action_plan_view_page/check_ins`,
        'GET'
      );

      const status = response.status;
      const { check_ins, repeated_check_ins } = await response.json();

      if (status === 200) {
        return {
          responseType: 'DATA',
          checkIns: check_ins,
          repeatedCheckIns: repeated_check_ins,
        };
      } else {
        throw 'Missing data';
      }
    } catch (error) {
      console.error(error);
      return { responseType: 'API_ERROR' };
    }
  };
};

export default getCheckInsApiWrapper(request);
