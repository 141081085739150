import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { CSSTransition, TransitionGroup } from 'react-transition-group';

import fallbackImage from 'profile-pic-upload.svg';
import RemoveButton from 'button-remove-photo.svg';

const AvatarPreview = ({ alt, onUpload, onRemove, previewImageSource, defaultImage, noBackground }) => {
  const imageSource = previewImageSource || defaultImage;
  const classes = classNames('avatar avatar--thumb', {
    'avatar--without-radius': !previewImageSource,
    'avatar--clickable': !previewImageSource,
    'avatar--no-background': noBackground,
  });

  return (
    <div className="profile-pic-upload__preview-container">
      <TransitionGroup className={classes}>
        <CSSTransition key={imageSource} classNames="fade" timeout={250}>
          <img alt={alt} src={imageSource} onClick={onUpload} className={classes} />
        </CSSTransition>
      </TransitionGroup>

      {!!previewImageSource && onRemove && (
        <img src={RemoveButton} onClick={onRemove} className="profile-pic-upload__remove-preview" />
      )}
    </div>
  );
};

AvatarPreview.propTypes = {
  alt: PropTypes.string.isRequired,
  defaultImage: PropTypes.string,
  noBackground: PropTypes.bool,
  onRemove: PropTypes.func,
  onUpload: PropTypes.func,
  previewImageSource: PropTypes.string,
};

AvatarPreview.defaultProps = {
  defaultImage: fallbackImage,
  noBackground: false,
  onRemove: null,
  onUpload: () => {},
  previewImageSource: '',
};

export default AvatarPreview;
