import { t } from 'i18n';
import React from 'react';
import EditModalContainer from './EditModalContainer';
import TaskToggle from './TaskToggle';

const i18nOpts = { scope: 'tasks.actions_container' };

const TaskActions = ({
  task,
  carePlanId,
  address,
  resources,
  assignments,
  assignmentOptions,
  timeZone,
  locale,
  canDeleteActivity,
  canEditDescription,
  canEditResources,
  canDeleteResources,
  canModify,
}) => (
  <>
    {canModify && (
      <EditModalContainer
        task={task}
        carePlanId={carePlanId}
        address={address}
        resources={resources}
        assignments={assignments}
        assignmentOptions={assignmentOptions}
        timeZone={timeZone}
        locale={locale}
        canDeleteActivity={canDeleteActivity}
        canEditDescription={canEditDescription}
        canEditResources={canEditResources}
        canDeleteResources={canDeleteResources}
        editBtnStyle='SUBTLE'
        editBtnText={t('edit', i18nOpts)}
      />
    )}
    {task.canComplete && (
      <TaskToggle
        carePlanUuid={task.carePlanUuid}
        completedAt={task.completedAt}
        id={task.id}
        isComplete={task.isComplete}
        kind={task.kind}
        messages={task.messages}
        nestedActivities={task.nestedActivities}
        text={task.text}
        timeZone={task.timeZone}
        toggle={task.toggle}
      />
    )}
  </>
);

export default TaskActions;
