import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import {
  setActivityModalActivityProperties,
  setActivityModalShowAddressFields,
  setActivityModalShowScheduledFields,
  setActivityModalShowRepeatsFields,
  setActivityModalTemplatesDropdownIsOpen,
  setActivityModalTemplatesDropdownSearchInput,
} from 'Actions/activityModal';

const i18nOpts = { scope: 'activity_modal.form_fields.templates' };

export const populateActivityFieldsFromTemplate = (
  setActivityModalActivityProperties,
  setActivityModalShowAddressFields,
  setActivityModalShowScheduledFields,
  setActivityModalShowRepeatsFields,
  templateProperties
) => {
  const { id, text, url, address, frequencyType, dueAt, frequency, count, endRepeat, interval, services } =
    templateProperties;

  const activityProperties = {
    selectedActivityTemplateId: id,
    description: text,
    virtualVisitLink: url,
    frequencyType,
    frequency,
    count,
    endRepeat,
    interval,
    resources: services ? services : [],
  };

  if (address) {
    setActivityModalShowAddressFields(true);
    activityProperties.addressFields = {
      address: address.address,
      city: address.city,
      countryCode: address.countryCode,
      countryName: address.countryName,
      postalCode: address.postalCode,
      region: address.region,
    };
  }

  if (dueAt) {
    setActivityModalShowScheduledFields(true);
    activityProperties.dueAt = dueAt;
  }

  if (frequencyType === 'recurring') {
    setActivityModalShowRepeatsFields(true);
  } else {
    setActivityModalShowRepeatsFields(false);
  }

  setActivityModalActivityProperties(activityProperties);
};

const TemplateItem = ({
  activityType,
  template,
  setActivityModalActivityProperties,
  setActivityModalShowAddressFields,
  setActivityModalShowScheduledFields,
  setActivityModalShowRepeatsFields,
  setActivityModalTemplatesDropdownIsOpen,
  setActivityModalTemplatesDropdownSearchInput,
}) => {
  let unnamedTemplate;
  switch (activityType) {
    case 'APPOINTMENT':
      unnamedTemplate = t('unnamed_appointment', i18nOpts);
      break;
    case 'TASK':
      unnamedTemplate = t('unnamed_task', i18nOpts);
      break;
    case 'FORM':
      unnamedTemplate = t('unnamed_form', i18nOpts);
      break;
    default:
      unnamedTemplate = t('unnamed_appointment', i18nOpts);
      break;
  }

  const onClick = () => {
    populateActivityFieldsFromTemplate(
      setActivityModalActivityProperties,
      setActivityModalShowAddressFields,
      setActivityModalShowScheduledFields,
      setActivityModalShowRepeatsFields,
      template
    );
    setActivityModalTemplatesDropdownIsOpen(false);
    setActivityModalTemplatesDropdownSearchInput('');
  };

  const handleKeyDown = (evt) => {
    if (evt.key === ' ') {
      evt.preventDefault();
      onClick();
    }
    return;
  };

  return (
    <li
      className="templates_dropdown__templates_list__item"
      onClick={onClick}
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      {template.name || unnamedTemplate}
    </li>
  );
};

const mapStateToProps = ({ activityModal }) => {
  const { activityType } = activityModal;

  return { activityType };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalActivityProperties = (dispatch) => {
    return (activityProperties) => {
      dispatch(setActivityModalActivityProperties(activityProperties));
    };
  };

  const dispatchSetActivityModalShowAddressFields = (dispatch) => {
    return (showAddressFields) => {
      dispatch(setActivityModalShowAddressFields(showAddressFields));
    };
  };

  const dispatchSetActivityModalTemplatesDropdownIsOpen = (dispatch) => {
    return (isOpen) => {
      dispatch(setActivityModalTemplatesDropdownIsOpen(isOpen));
    };
  };

  const dispatchSetActivityModalTemplatesDropdownSearchInput = (dispatch) => {
    return (searchInput) => {
      dispatch(setActivityModalTemplatesDropdownSearchInput(searchInput));
    };
  };

  const dispatchSetActivityModalShowScheduledFields = (dispatch) => {
    return (showScheduledFields) => {
      dispatch(setActivityModalShowScheduledFields(showScheduledFields));
    };
  };

  const dispatchSetActivityModalShowRepeatsFields = (dispatch) => {
    return (showRepeatsFields) => {
      dispatch(setActivityModalShowRepeatsFields(showRepeatsFields));
    };
  };

  return {
    setActivityModalActivityProperties: dispatchSetActivityModalActivityProperties(dispatch),
    setActivityModalShowAddressFields: dispatchSetActivityModalShowAddressFields(dispatch),
    setActivityModalTemplatesDropdownIsOpen: dispatchSetActivityModalTemplatesDropdownIsOpen(dispatch),
    setActivityModalTemplatesDropdownSearchInput:
      dispatchSetActivityModalTemplatesDropdownSearchInput(dispatch),
    setActivityModalShowScheduledFields: dispatchSetActivityModalShowScheduledFields(dispatch),
    setActivityModalShowRepeatsFields: dispatchSetActivityModalShowRepeatsFields(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateItem);
