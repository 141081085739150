import { Resource } from 'Api/Api'

class LibraryDocumentTemplateCopies extends Resource {
  constructor(carePlanTemplateId) {
    super()

    this.carePlanTemplateId = carePlanTemplateId
  }

  update(selectedLibraryDocuments) {
    return super.update({ selected_library_documents: selectedLibraryDocuments })
  }

  get url() {
    return `/care_plan_templates/${this.carePlanTemplateId}/library_document_copies`
  }
}

export default LibraryDocumentTemplateCopies
