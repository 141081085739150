import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';
import { activityType as activityTypeDefinitions } from '../../modalContentStateDefinitions';

const i18nOpts = { scope: 'activity_modal.form_fields.when_should_be_completed' };

const FieldLabel = ({
  activityType,
}) => {
  const { APPOINTMENT, CHECK_IN, FORM, TASK } = activityTypeDefinitions;

  let fieldLabel;
  switch (activityType) {
    case CHECK_IN:
      fieldLabel = t('check_in_label', i18nOpts);
      break;
    case FORM:
      fieldLabel = t('form_label', i18nOpts);
      break;
    case TASK:
      fieldLabel = t('task_label', i18nOpts);
      break;
    case APPOINTMENT:
      fieldLabel = t('appointment_label', i18nOpts);
      break;
    default:
      fieldLabel = t('check_in_label', i18nOpts);
      break;
  }

  return <div className="activity_modal__input_field__label activity_modal__input_field__label--when">{fieldLabel}</div>;
};

const mapStateToProps = ({ activityModal }) => {
  const { activityType } = activityModal;

  return { activityType };
};

export default connect(mapStateToProps, null)(FieldLabel);