import Api from './Api'

class RepeatedCheckInResponse extends Api {
  constructor(carePlanId, repeatedCheckInId) {
    super()

    this.carePlanId = carePlanId
    this.repeatedCheckInId = repeatedCheckInId
  }

  get url() {
    return `/care_plans/${this.carePlanId}/repeated_check_ins/${this.repeatedCheckInId}/check_in_responses`
  }
}

export default RepeatedCheckInResponse
