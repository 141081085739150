const setupComparisonChart = (
  data,
  error,
  monitorType,
  getInitialRangeEndDate,
  getWeekOrder,
  setChartData,
  setChartError,
  setMaximumDate,
  setRangeEndDate,
  setWeekOrder,
  setMonitorType,
  setPageState
) => {
  try {
    const previousDayIsoDate = getInitialRangeEndDate();
    const weekOrder = getWeekOrder(previousDayIsoDate);

    setChartData(data);
    setChartError(error);
    setRangeEndDate(previousDayIsoDate);
    setMaximumDate(previousDayIsoDate);
    setWeekOrder(weekOrder);
    setMonitorType(monitorType);
    setPageState('success');
  } catch (err) {
    console.error(err);
    setPageState('error');
  }
};

export default setupComparisonChart;
