import React from 'react';
import moment from 'ct-moment';
import { t } from 'i18n';

const i18nOpts = { scope: 'dashboard.insulin' };

const UploadedDate = ({ date }) => {
  if (!date) return null;

  const formattedDate = moment(date).format('LL');

  return (
    <p className="pump-settings__uploaded-date">
      {t('last_uploaded_on', { ...i18nOpts, date: formattedDate })}
    </p>
  );
};

export default UploadedDate;
