export const encodeToBase64 = async (file) => {
  if (!file) {
    return null;
  }

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const imageBase = await toBase64(file);

  return {
    imageBase,
    imageName: file.name,
    imageType: imageBase.match(/image\/(?:png|jpg|jpeg)/)[0],
  };
};
