import React from 'react';
import PropTypes from 'prop-types';

import NestedAppointmentForm from '../NestedAppointmentForm';
import Icon from '../../Base/Icon';

import { t } from 'i18n';

const i18nOpts = { scope: 'tasks.show.status' };

const CompleteStatus = ({
  id,
  practitioner,
  text,
  carePlanUuid,
  timeZone,
  skip,
  canUpdate,
  messages: { text: msgText },
  services,
  url,
}) => (
  <div>
    <section className="card-section card-section--status">
      <div className="icon icon--large">
        <Icon name="check_alt" />
      </div>

      <div className="card-section__content">
        <h3 className="card-section__header">{t('heading', i18nOpts)}</h3>

        <p className="card-section__status card-section__status--complete">{msgText}</p>
      </div>
    </section>

    {canUpdate && (
      <NestedAppointmentForm
        carePlanUuid={carePlanUuid}
        parent_id={id}
        practitioner={practitioner}
        text={text}
        skip={skip}
        timeZone={timeZone}
        services={services}
        url={url}
      />
    )}
  </div>
);

CompleteStatus.propTypes = {
  canUpdate: PropTypes.bool.isRequired,
  carePlanUuid: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  messages: PropTypes.object,
  practitioner: PropTypes.string,
  services: PropTypes.array,
  skip: PropTypes.func.isRequired,
  text: PropTypes.string,
  timeZone: PropTypes.string,
};

CompleteStatus.defaultProps = {
  messages: { text: '' },
  practitioner: '',
  services: [],
  text: '',
  timeZone: 'Etc/UTC',
};

export default CompleteStatus;
