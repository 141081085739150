import React from 'react';
import { connect } from 'react-redux';
import Recommendation from './Recommendation';
import TruncationToggler from './TruncationToggler';
import EmptyRecommendationsList from './EmptyRecommendationsList';
import truncateRecommendations from './truncateRecommendations';

const RecommendationsList = ({ recommendations, isListTruncated, truncatedCount }) => {
  const recommendationsToDisplay = truncateRecommendations(isListTruncated, recommendations, truncatedCount);

  return (
    <>
      {recommendationsToDisplay.map((recommendation, index) => {
        return <Recommendation key={index} index={index} recommendation={recommendation} />;
      })}
      <EmptyRecommendationsList />
      <TruncationToggler />
    </>
  );
};

const mapStateToProps = ({ recommendationsBlock: { recommendations, isListTruncated, truncatedCount } }) => {
  return { recommendations, isListTruncated, truncatedCount };
};

export default connect(mapStateToProps, null)(RecommendationsList);
