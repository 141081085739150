import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toggleTask } from 'Actions/editButton'

import CompleteStatus from './CompleteStatus'
import IncompleteStatus from './IncompleteStatus'

import withTaskActions from '../withTaskActions'

const Status = (props) => {
  if (!props.isComplete && props.completedAt) {
    return <CompleteStatus {...props} />
  } else {
    return (
      <IncompleteStatus
        isComplete={props.isComplete}
        messages={props.messages}
      />
    )
  }
}

Status.propTypes = {
  canUpdate: PropTypes.bool.isRequired,
  carePlanUuid: PropTypes.string.isRequired,
  completedAt: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  isComplete: PropTypes.bool,
  messages: PropTypes.object,
  practitioner: PropTypes.string,
  skip: PropTypes.func.isRequired,
  text: PropTypes.string,
  timeZone: PropTypes.string,
  toggle: PropTypes.func.isRequired,
}

Status.defaultProps = {
  completedAt: '',
  isComplete: false,
  messages: { text: '', action: '' },
  practitioner: '',
  text: '',
  timeZone: 'Etc/UTC',
}

const mapDispatchToProps = (dispatch) => ({
  toggle: () => dispatch(toggleTask()),
})

export default connect(null, mapDispatchToProps)(withTaskActions(Status))
