import { t } from 'i18n';
import React, { useState } from 'react';

import ActionButton from 'Components/Base/ActionButton';
import Icon from 'Components/Base/Icon';
import CustomHighlight from 'Components/Base/Highlight/CustomHighlight';
import QuillEditor from 'Components/Base/Quilljs/QuillEditor';

import Dialog from 'Components/Base/Dialog';
import QuillViewer from 'Components/Base/Quilljs/QuillViewer';

import updateEmergencyInstructionWrapper from './updateEmergencyInstructionWrapper';

const Edit = ({
  defaultEmergencyInstruction,
  setContentFormatted,
  contentFormatted,
  discardChanges,
  setEditForm,
}) => {
  const MAX_CHARS = 800;
  const i18nOpts = { scope: 'emergency_instruction' };

  const header = t('header', i18nOpts);

  const [modalOpenState, setModalOpenState] = useState(false);
  const [quill, setQuill] = useState(null);
  const [contentText, setContentText] = useState(defaultEmergencyInstruction);

  const [isLoading, setIsLoading] = useState(false);
  const [updateError, setUpdateError] = useState(false);
  const [flashError, setFlashError] = useState(false);

  const toggleFlash = () => {
    setFlashError(true);
    setTimeout(() => {
      setFlashError(false);
    }, 1000);
  };

  const revertToDefault = () => setContentFormatted(defaultEmergencyInstruction);

  const numCharsLeft = MAX_CHARS - contentText?.length;

  const closeModal = () => setModalOpenState(false);
  const openModal = () => setModalOpenState(true);

  const updateEmergencyInstruction = () => {
    if (contentText.length == 0) return;
    if (numCharsLeft < 0) {
      toggleFlash();
      return;
    }

    const updateWrapper = updateEmergencyInstructionWrapper();
    updateWrapper(contentText, contentFormatted, setIsLoading, setUpdateError, setEditForm);
  };

  return (
    <div className="edit">
      <Dialog
        className="emergency-instruction-dialog"
        title={header}
        isOpen={modalOpenState}
        onClose={closeModal}
        content={<QuillViewer contentFormatted={contentFormatted} />}
      ></Dialog>

      <div className="editor-section">
        <div className="quill-editor-header-container">
          <h2 className="header">{header}</h2>
          <button className="action-btn btn--link card__header-link" onClick={revertToDefault}>
            <Icon name="revert" className="revert_icon" />
            {t('revert', i18nOpts)}
          </button>
        </div>
        <QuillEditor
          contentFormatted={contentFormatted}
          setContentFormatted={setContentFormatted}
          setContentText={setContentText}
        />
      </div>
      <div className="chars-container">
        <p
          className={`chars ${numCharsLeft <= 0 ? 'error' : null} ${flashError ? 'flash-error-chars' : null}`}
        >
          {numCharsLeft} {t('characters_left', i18nOpts)}
        </p>
      </div>

      <section>
        <CustomHighlight icon="info_alt" message={t('instruction_translation', i18nOpts)}></CustomHighlight>
      </section>
      <section>
        {updateError && <p className="error">{t('error', i18nOpts)}</p>}
        <div className="buttons-section">
          <ActionButton
            className="button-subtle"
            content={t('discard', i18nOpts)}
            onClick={discardChanges}
            disabled={isLoading}
          />
          <div className="buttons-right">
            <ActionButton
              className="button-subtle"
              content={t('preview', i18nOpts)}
              onClick={openModal}
              disabled={isLoading}
            />
            <ActionButton
              content={t('save', i18nOpts)}
              onClick={updateEmergencyInstruction}
              isLoading={isLoading}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Edit;
