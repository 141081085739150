import React, { lazy, Suspense, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import { Icon, Tooltip } from 'Components/Base';
import Dialog from 'Components/Base/Dialog';
import CustomHighlight from 'Components/Base/Highlight/CustomHighlight';

const i18nOpts = { scope: 'inbox.conversation.header' };

// QuillViewer needs to be lazy loaded here, otherwise Quill will be undefined
const QuillViewer = lazy(() => import('Components/Base/Quilljs/QuillViewer'));

const Header = ({ link, subheader, onClick, emergencyInstruction, isPlaceUser, conversationType }) => {
  const [modalOpenState, setModalOpenState] = useState(false);

  const defaultEmergencyInstruction = t('default', { scope: 'emergency_instruction' });
  const defaultEmergencyInstructionFormatted = [{ insert: defaultEmergencyInstruction }];

  if (!isPlaceUser) emergencyInstruction ??= defaultEmergencyInstructionFormatted;

  const showEmergencyInstruction = !isPlaceUser && conversationType != 'direct_message';

  const closeModal = () => setModalOpenState(false);
  const openModal = () => setModalOpenState(true);

  const channnelType = link ? 'inbox-conversation-header--group' : 'inbox-conversation-header--direct';

  const highlightMsg = (
    <>
      <p>
        {t('emergency_instruction_p1', i18nOpts)}&nbsp;
        <a className="inbox-conversation-header__highlight-link" onClick={openModal}>
          {t('emergency_instruction_p2', i18nOpts)}
        </a>
      </p>
    </>
  );

  return (
    <div className={`inbox-conversation-header ${channnelType}`}>
      <div className="inbox-menu-toggle" onClick={onClick}>
        <Icon name="carrot-left" />
        <Icon name="group" />
      </div>

      <div>
        <div className="inbox-conversation-header__title text-truncate">{subheader}</div>
        {showEmergencyInstruction && (
          <Fragment>
            <Dialog
              className="emergency-instruction-dialog"
              title={t('emergency_instruction_header', i18nOpts)}
              isOpen={modalOpenState}
              onClose={closeModal}
              content={
                <Suspense fallback={t('loading', i18nOpts)}>
                  <QuillViewer contentFormatted={emergencyInstruction} />
                </Suspense>
              }
            ></Dialog>

            <CustomHighlight icon="info_alt" message={highlightMsg}></CustomHighlight>
          </Fragment>
        )}
      </div>
      {link ? (
        <div className="inbox-conversation-header__link">
          <a href={link} alt={t('view_details', i18nOpts)}>
            <Fragment>
              <Tooltip text={t('view_careplan', i18nOpts)} position="down">
                <Icon name="info_alt" className="icon icon--20px" />
              </Tooltip>
            </Fragment>

            <Fragment>
              <button className="btn btn--secondary btn--extra-small">{t('view_careplan', i18nOpts)}</button>
            </Fragment>
          </a>
        </div>
      ) : (
        <span>&nbsp;</span>
      )}
    </div>
  );
};

Header.propTypes = {
  link: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  subheader: PropTypes.string,
  emergencyInstruction: PropTypes.array,
  isPlaceUser: PropTypes.bool,
  conversationType: PropTypes.string,
};

Header.defaultProps = {
  link: '',
  subheader: '',
};

export default Header;
