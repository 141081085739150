import { combineReducers } from 'redux';

import { RECEIVE_CONTACTS, REQUEST_CONTACTS, SHOW_CONTACTS } from 'Actions/inbox/sideNav/contacts';

import { REQUEST_ALL_CONTACTS, RECEIVE_ALL_CONTACTS } from 'Actions/inbox/sideNav/newContacts';

import { REQUEST_CARE_PLANS, RECEIVE_CARE_PLANS, SHOW_CARE_PLANS } from 'Actions/inbox/sideNav/carePlans';

import {
  TOGGLE_SIDEBAR,
  CLOSE_SIDEBAR,
  SORT_BY,
  SEARCH,
  REQUEST_CHANNEL_SEARCH,
  END_CHANNEL_SEARCH,
  MISSING_SEARCH_RESULT,
  FOUND_SEARCH_RESULT,
  SORT_BY_NEWEST,
  SORT_BY_OLDEST,
  SORT_BY_A_Z,
  SORT_BY_Z_A,
} from 'Actions/inbox/sideNav/general';

import {
  REQUEST_CHANNEL_CONTACTS,
  REQUEST_CHANNEL_CARE_PLANS,
  SHOW_CHANNEL_CARE_PLANS,
  SHOW_CHANNEL_CONTACTS,
  NAME_CHANNEL,
} from 'Actions/inbox/sideNav/channel';

const isOpen = (isOpen = false, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return !isOpen;
    case CLOSE_SIDEBAR:
      return false;
    default:
      return isOpen;
  }
};

const mode = (mode = 'ongoing', action) => {
  switch (action.type) {
    case REQUEST_CONTACTS:
    case SHOW_CONTACTS:
      return 'ongoing';
    case REQUEST_ALL_CONTACTS:
      return 'new';
    case REQUEST_CARE_PLANS:
    case SHOW_CARE_PLANS:
      return 'carePlans';
    case REQUEST_CHANNEL_CARE_PLANS:
      return 'ongoing';
    case SHOW_CHANNEL_CARE_PLANS:
      return 'channelCarePlans';
    case REQUEST_CHANNEL_CONTACTS:
    case SHOW_CHANNEL_CONTACTS:
      return 'channelMembers';
    case NAME_CHANNEL:
      return 'channelName';
    default:
      return mode;
  }
};

const channelSortBy = (state = { sortBy: 'newest' }, action) => {
  // Matches the options in Inbox/SideNav/Contacts/Filters.jsx
  switch (action.type) {
    case SORT_BY_NEWEST:
      return {
        ...state,
        sortBy: 'newest',
      };
    case SORT_BY_OLDEST:
      return {
        ...state,
        sortBy: 'oldest',
      };
    case SORT_BY_A_Z:
      return {
        ...state,
        sortBy: 'nameAsc',
      };
    case SORT_BY_Z_A:
      return {
        ...state,
        sortBy: 'nameDesc',
      };
    default:
      return state;
  }
};

const channelSearchQuery = (state = { isSearchingForChannel: false, missingSearchResult: false }, action) => {
  switch (action.type) {
    case REQUEST_CHANNEL_SEARCH:
      return {
        ...state,
        missingSearchResult: false,
        isSearchingForChannel: true,
      };
    case END_CHANNEL_SEARCH:
      return {
        ...state,
        isSearchingForChannel: false,
        missingSearchResult: false,
      };
    case MISSING_SEARCH_RESULT:
      return {
        ...state,
        missingSearchResult: true,
      };
    case FOUND_SEARCH_RESULT:
      return {
        ...state,
        missingSearchResult: false,
      };
    default:
      return state;
  }
};

const searchQuery = (query = '', action) => {
  switch (action.type) {
    case SEARCH:
      return action.searchQuery;
    case RECEIVE_CONTACTS:
    case SHOW_CONTACTS:
    case RECEIVE_ALL_CONTACTS:
    case RECEIVE_CARE_PLANS:
      return '';
    default:
      return query;
  }
};

const sortBy = (sortBy = 'newest', action) => {
  switch (action.type) {
    case SORT_BY:
      return action.sortBy;
    default:
      return sortBy;
  }
};

export default combineReducers({
  isOpen,
  mode,
  channelSearchQuery,
  channelSortBy,
  searchQuery,
  sortBy,
});
