import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Button, Icon } from 'Components/Base'

class AdminSearch extends Component {
  constructor(props) {
    super(props)

    this.state = { value: props.value || '' }

    this.handleSearch = this.handleSearch.bind(this)
    this.handleReset = this.handleReset.bind(this)
  }

  handleSearch({ target: { value } }) {
    this.setState({ value })
  }

  handleReset(event) {
    event.persist()
    const form = event.target.closest('form')
    this.setState({ value: '' }, () => form.submit())
  }

  render() {
    const { value } = this.state
    const { inputName, placeholder } = this.props

    return (
      <Fragment>
        <input
          className="input-icon input-icon--search"
          name={inputName}
          onChange={this.handleSearch}
          placeholder={placeholder}
          type="text"
          value={value}
        />

        {value && (
          <Button className="search-reset" onClick={this.handleReset}>
            <Icon name="close" />
          </Button>
        )}
      </Fragment>
    )
  }
}

AdminSearch.propTypes = {
  inputName: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
}

AdminSearch.defaultProps = {
  placeholder: '',
  value: '',
}

export default AdminSearch
