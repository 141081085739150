import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Chip } from 'Components/Base'

const i18nOpts = { scope: 'patient.show.address' }

const Address = ({
  label,
  address,
  extendedAddress,
  city,
  region,
  postalCode,
  countryName,
  edit,
  editable,
}) => (
  <dl className="patient-details__additional-detail">
    <dd>
      {extendedAddress ? `${extendedAddress}-${address}` : address}
      {editable && (
        <span className="patient-details__edit-additional-detail">
          - <a onClick={edit}>{t('edit', i18nOpts)}</a>
        </span>
      )}
      <Chip
        color="light-grey"
        text={t(label, { scope: 'patient.show.address', defaultValue: t('unknown', i18nOpts) })}
      />
    </dd>
    <dd>{[city, region].filter((p) => p).join(', ')}</dd>
    <dd>{[postalCode, countryName].filter((p) => p).join(', ')}</dd>
  </dl>
)

Address.propTypes = {
  address: PropTypes.string,
  city: PropTypes.string,
  countryName: PropTypes.string,
  edit: PropTypes.func,
  editable: PropTypes.bool,
  extendedAddress: PropTypes.string,
  label: PropTypes.string,
  postalCode: PropTypes.string,
  region: PropTypes.string,
}

Address.defaultProps = {
  city: '',
  countryName: '',
  edit: null,
  editable: false,
  extendedAddress: '',
  label: '',
  postalCode: '',
  region: '',
}

export default Address
