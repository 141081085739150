import Api from '../Api';

class ChannelSearch extends Api {
  constructor({ searchQuery = null }) {
    super();

    this.searchQuery = searchQuery;
  }

  search() {
    return super.request(`${this.url}?query=${this.searchQuery}`, 'GET', null, false);
  }

  get url() {
    return '/inbox/search';
  }
}

export default ChannelSearch;
