import React from 'react';
import PropTypes from 'prop-types';

const ApiErrorMessage = ({ apiErrorMessage }) => {
  if (apiErrorMessage) {
    return <div className="registrationV2__form_body__api_error">{apiErrorMessage}</div>;
  } else {
    return null;
  }
};

ApiErrorMessage.propTypes = {
  apiErrorMessage: PropTypes.string,
};

export default ApiErrorMessage;
