import Api from './Api'

class CarePlan extends Api {
  constructor(carePlanId) {
    super()

    this.id = carePlanId

    this.updateDetails = this.updateDetails.bind(this)
  }

  // PATCH update_details_care_plan_creator_path
  updateDetails({ carePlan }) {
    return super.request(`${this.url}/${this.id}/update_details`, 'PATCH', { carePlan })
  }

  updateInstructions({ carePlan }) {
    return super.request(`${this.url}/${this.id}/update_instructions`, 'PATCH', { carePlan })
  }

  readOrUpdateToken() {
    return super.request(`/care_plans/${this.id}/read_or_update_token`, 'PATCH')
  }

  get url() {
    return '/intake'
  }
}

export default CarePlan
