import { request } from 'Apiv2/handleRequest';

const fetchUserDetails = async (carePlanUuid) => {
  const endpointUrl = `/care_plans/${carePlanUuid}/dashboard/user_details`;

  try {
    const response = await request(endpointUrl);
    const { is_place_user, patient_first_name, patient_full_name, patient_birthday, carehub_url } =
      await response.json();

    return {
      status: 'success',
      isPlaceUser: is_place_user,
      patientFirstName: patient_first_name,
      patientFullName: patient_full_name,
      patientBirthday: patient_birthday,
      carehubUrl: carehub_url,
    };
  } catch (error) {
    return { status: 'error', message: error.message };
  }
};

export default fetchUserDetails;
