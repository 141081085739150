import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';

class Card extends React.Component {
  constructor() {
    super();
    this.handleCollapse = this.handleCollapse.bind(this);
    this.actionWrapper = this.actionWrapper.bind(this);
    this.state = { collapsed: false };
  }

  actionWrapper(callback) {
    if (this.state.collapsed) {
      this.setState({ collapsed: false });
    }

    callback();
  }

  handleCollapse() {
    this.setState((prevState) => ({ collapsed: !prevState.collapsed }));
  }

  render() {
    const cardClassName = this.state.collapsed ? 'card card--collapsed' : 'card';
    const { id, renderHeaderAction, renderChip, title, collapsable, children, cardHeaderClass, cardContainerClass } = this.props;

    return (
      <div id={id} className={`${cardClassName} ${cardContainerClass}`}>
        <h3 className={`card__header ${cardHeaderClass}`}>
          <span>
            {title}
            {renderChip}
          </span>
          {collapsable && (
            <span className="card__header-section">
              {renderHeaderAction && renderHeaderAction(this.actionWrapper)}
              <div
                className="icon icon--large"
                onClick={this.handleCollapse}
                tabIndex="0"
                onKeyDown={({ key }) => (key === 'Enter' || key === ' ') && this.handleCollapse()}
              >
                <Icon name={this.state.collapsed ? 'carrot-down' : 'carrot-up'} />
              </div>
            </span>
          )}
        </h3>

        {!this.state.collapsed && children}
      </div>
    );
  }
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
  collapsable: PropTypes.bool,
  id: PropTypes.string,
  renderChip: PropTypes.object,
  renderHeaderAction: PropTypes.func,
  title: PropTypes.string.isRequired,
  cardHeaderClass: PropTypes.string,
  cardContainerClass: PropTypes.string,
};

Card.defaultProps = {
  collapsable: false,
  id: '',
  renderChip: null,
  renderHeaderAction: null,
  cardContainerClass: '',
};

export default Card;
