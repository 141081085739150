import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { t } from 'i18n'

import InputGroup from 'Components/Forms/InputGroup'
import Submit from 'Components/Forms/Submit'
import AvatarPreview from 'Components/Forms/ProfilePictureInput/AvatarPreview'
import { MAX_FILE_SIZE } from 'Utilities/constants'
import { rekeyFormDataToBracketNotation } from 'Utilities/form-data'
import Errors from 'Components/Forms/ProfilePictureInput/Errors'
import defaultImage from 'award.png'

import GoalApi from 'Api/Goal'

const i18nOpts = { scope: 'care_plans.goal_section.goal_form' }

class GoalForm extends Component {
  constructor(props) {
    super(props)

    this.fileUploadRef = null
    this.setFileUploadRef = (element) => {
      this.fileUploadRef = element
    }
    this.setFormRef = (element) => {
      this.formRef = element
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleErrors = this.handleErrors.bind(this)
    this.handleFileChange = this.handleFileChange.bind(this)
    this.handleRemove = this.handleRemove.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleUploadFile = this.handleUploadFile.bind(this)

    this.state = {
      errors: {},
      title: '',
      description: '',
      photo_file_name: '',
      previewImageSource: null,
    }
  }

  get currentFile() {
    return this.fileUploadRef.files[0]
  }

  get currentFileIsPresent() {
    return this.fileUploadRef && this.fileUploadRef.files[0]
  }

  get previewImageSource() {
    if (this.currentFileIsPresent) {
      return URL.createObjectURL(this.currentFile)
    } else {
      return null
    }
  }

  handleUploadFile(event) {
    if (this.fileUploadRef) {
      this.fileUploadRef.click()

      if (event) {
        // stop event propagation; otherwise, the form will lose focus
        event.stopPropagation()
        event.preventDefault()
      }
    }
  }

  handleRemove() {
    if (this.currentFileIsPresent) {
      // https://stackoverflow.com/a/3162319
      this.fileUploadRef.value = ''
      this.handleFileChange()
    }
  }

  handleFileChange() {
    const { name = null, size = null } = this.currentFile || {}
    const fileSizeExceeded = size > MAX_FILE_SIZE
    const savingDisabled = !name || fileSizeExceeded
    // disable saving when there is no filename and no record being edited
    // (e.g. a new document must have a file), or if file size has been exceeded

    this.setState({
      photo_file_name: name,
      savingDisabled,
      fileSizeExceeded,
      previewImageSource: this.previewImageSource,
    })
  }

  handleChange({ target: { name, value } }) {
    this.setState((prevState) => _.set(prevState, name, value))
  }

  handleErrors(errors) {
    this.setState({ errors })
  }

  handleSubmit(event) {
    event.preventDefault()
    const { carePlanId } = this.props

    let formData = new FormData(this.formRef)
    formData = rekeyFormDataToBracketNotation(formData)

    new GoalApi(carePlanId).create(formData).then((res) => {
      if (res.ok) {
        res.json().then(this.props.onSuccess)
      } else if (res.status === 422) {
        res.json().then(this.handleErrors)
      }
    })
  }

  render() {
    const { title, description, photo_file_name, previewImageSource, errors } = this.state

    return (
      <form noValidate className="form form--background" onSubmit={this.handleSubmit} ref={this.setFormRef}>
        <InputGroup
          component="input"
          label={t('title', i18nOpts)}
          errors={errors}
          name="title"
          onChange={this.handleChange}
          value={title}
          required
          type="text"
        />

        <input name="kind" type="hidden" value={this.props.kind} />

        <InputGroup
          component="textarea"
          label={t('description', i18nOpts)}
          errors={errors}
          name="description"
          onChange={this.handleChange}
          value={description}
          type="textarea"
        />

        <input
          ref={this.setFileUploadRef}
          onChange={this.handleFileChange}
          className="profile-pic-upload__input"
          type="file"
          accept="image/*"
          name="photo"
          id="photo"
        />

        <div className="profile-pic-upload">
          <AvatarPreview
            alt={photo_file_name || t('default_alt', i18nOpts)}
            onUpload={this.handleUploadFile}
            onRemove={this.handleRemove}
            previewImageSource={previewImageSource}
            defaultImage={defaultImage}
            noBackground
          />

          <div className="profile-upload__text">
            <a className="profile-pic-upload__text-link text-medium" onClick={this.handleUploadFile}>
              {this.currentFileIsPresent ? t('update', i18nOpts) : t('upload', i18nOpts)}
            </a>
            <span className="text-grey">&nbsp;{t('optional', i18nOpts)}</span>

            <div>{!!this.state.errors['photo'] && <Errors errors={this.state.errors['photo']} />}</div>
          </div>
        </div>

        <div className="form__submit">
          <Submit value={t('submit', i18nOpts)} />
        </div>
      </form>
    )
  }
}

GoalForm.propTypes = {
  carePlanId: PropTypes.string.isRequired,
  kind: PropTypes.string,
  onSuccess: PropTypes.func.isRequired,
}

GoalForm.defaultProps = {
  kind: 'patient',
}

export default GoalForm
