import React from 'react';

import SelectFromLibrary from '../FormFields/SelectFromLibrary/SelectFromLibrary';
import Visibility from '../FormFields/Visibility';
import WhenShouldBeCompleted from '../FormFields/When/WhenShouldBeCompleted';
import Error from '../Error';

export const CheckIn = () => {
  return (
    <div className="activity_modal">
      <SelectFromLibrary />
      <Visibility />
      <WhenShouldBeCompleted />
      <Error />
    </div>
  );
};

export default CheckIn;
