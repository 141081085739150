import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Button, Icon } from 'Components/Base'
import { Footer } from 'Components/Base/Card/'

import banner from 'health_team_invite_banner.svg'

const i18nOpts = { scope: 'care_plans.health_team_banner.banner' }

const Banner = ({ onClose, onInvite, patientName, title }) => (
  <div className="card card--wave-header">
    <div className="card__image-header">
      <div className="card__image-header-text">{t('header', { ...i18nOpts, patientName, title })}</div>

      <div className="card__image-header-image-center">
        <img src={banner} />
      </div>

      <a onClick={onClose} className="card__image-header-close" data-test="close">
        <Icon name="close" />
      </a>
    </div>

    <div className="card__body">
      <p className="h3 text-grey-darker">{t('body_one', i18nOpts)}</p>
      <br />

      <p className="h3 text-grey-darker">{t('body_two', i18nOpts)}</p>
      <br />

      <Footer
        right={
          <>
            <Button
              className="btn btn--secondary"
              onClick={onClose}
              text={t('close', i18nOpts)}
              data-test="close"
            />

            <Button onClick={onInvite} text={t('invite', i18nOpts)} data-test="invite" />
          </>
        }
      />
    </div>
  </div>
)

Banner.propTypes = {
  onClose: PropTypes.func.isRequired,
  onInvite: PropTypes.func.isRequired,
  patientName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default Banner
