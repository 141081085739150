import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Referral } from './'

import ReferralApi from 'Api/Referral'

class ReferralContainer extends Component {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)

    this.state = {
      completedAt: props.completedAt,
      isCompleting: false,
    }
  }

  handleClick(evt) {
    evt.stopPropagation()
    evt.preventDefault()

    this.setState({ isCompleting: true })

    const call = this.state.completedAt ? 'incomplete' : 'complete'

    return new ReferralApi(this.props.carePlanUuid, this.props.id)[call]().then((res) => {
      if (res.ok) {
        return res.json().then(({ referral }) => {
          this.setState({ ...referral, isCompleting: false })
        })
      }
    })
  }

  render() {
    const { canComplete, carePlan, isHealthCareView, patientName, provider, status, text } = this.props
    const { completedAt, isCompleting } = this.state
    return (
      <Referral
        canComplete={canComplete}
        carePlan={carePlan}
        completedAt={completedAt}
        isCompleting={isCompleting}
        isHealthCareView={isHealthCareView}
        patientName={patientName}
        provider={provider}
        status={status}
        text={text}
        onClick={this.handleClick}
      />
    )
  }
}

ReferralContainer.propTypes = {
  canComplete: PropTypes.bool,
  carePlan: PropTypes.shape({
    title: PropTypes.string,
    place: PropTypes.shape({
      name: PropTypes.string,
      logo: PropTypes.string,
    }),
  }),
  carePlanUuid: PropTypes.string.isRequired,
  completedAt: PropTypes.string,
  id: PropTypes.number.isRequired,
  isHealthCareView: PropTypes.bool,
  patientName: PropTypes.string,
  provider: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  text: PropTypes.string,
}

ReferralContainer.defaultProps = {
  canComplete: false,
  carePlan: {},
  completedAt: '',
  isHealthCareView: false,
  patientName: null,
  text: '',
}

export default ReferralContainer
