import updateActivityApi from './updateActivityApi';

export const handleUpdateActivityWrapper = (updateActivityApiProvider = updateActivityApi) => {
  return async ({
    setActivityModalState,
    handleCloseModal,
    carePlanId,
    activityId,
    activityType,
    activityProperties,
    onUpdate,
    allResources,
  }) => {
    setActivityModalState('LOADING');

    try {
      const { responseType } = await updateActivityApiProvider({
        carePlanId,
        activity: activityProperties,
        type: activityType,
        activityId,
        allResources,
      });

      if (responseType === 'DATA') {
        handleCloseModal();
        onUpdate();
      } else {
        throw 'Missing data';
      }
    } catch (error) {
      setActivityModalState('ERROR');
      console.error(error);
    }
  };
};

export default handleUpdateActivityWrapper(updateActivityApi);
