import React from 'react'

import ProviderWrapper from 'Components/Base/ProviderWrapper'
import UnaffiliatedContactsContainer from './UnaffiliatedContactsContainer'

const UnaffiliatedContacts = () => (
  <ProviderWrapper>
    <UnaffiliatedContactsContainer />
  </ProviderWrapper>
)

export default UnaffiliatedContacts
