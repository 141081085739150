import React from 'react';
import PropTypes from 'prop-types';

const Row = ({ row, columnCount }) => {
  const columnIndexArray = [];

  for (let i = 0; i < columnCount; i++) {
    columnIndexArray.push(i);
  }

  return (
    <tr className="stats-table__row">
      {columnIndexArray.map((columnIdx) => (
        <td className="stats-table__column" key={columnIdx}>
          {row[columnIdx]}
        </td>
      ))}
    </tr>
  );
};

Row.propTypes = {
  columnCount: PropTypes.number.isRequired,
  row: PropTypes.array.isRequired,
};

export default Row;
