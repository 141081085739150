import React from 'react'
import PropTypes from 'prop-types'

import Avatar from 'PulseBackports/Avatar'

const Contact = ({ photo, name, title, isUnread, isActive, onClick }) => (
  <div onClick={onClick} className={`inbox-menu__contact ${isActive && 'inbox-menu__contact--current'}`}>
    <Avatar
      src={photo}
      fullName={name}
      size={2.625}
      border={true}
    />
    <div className="inbox-menu__contact-details">
      <div className="inbox-menu__contact-name text-truncate">{name}</div>
      <div className="inbox-menu__contact-careplan">{title}</div>
    </div>

    <div className="inbox-menu__contact-unread">
      {isUnread && <div className="inbox-menu__contact-unread-badge" />}
    </div>
  </div>
)

Contact.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isUnread: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  photo: PropTypes.string,
  title: PropTypes.string,
}

Contact.defaultProps = {
  isUnread: false,
  title: '',
}

export default Contact
