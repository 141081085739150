import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Label from './Label'
import LocaleSelect from './LocaleSelect'
import { t } from 'i18n'

class LocaleInput extends Component {
  constructor(props) {
    super(props)

    this.setLocale = this.setLocale.bind(this)
    this.handleLabelClick = this.handleLabelClick.bind(this)

    const locale = this.props.availableLocales.includes(this.props.locale) ? this.props.locale : 'en'

    this.state = { locale, isOpen: false }
  }

  setLocale(locale) {
    this.setState({ locale })
  }

  handleLabelClick() {
    this.setState({ isOpen: true })
  }

  render() {
    return (
      <div className="input">
        <Label
          text={t('language', { scope: 'forms.locale_input' })}
          inputName={this.props.inputId}
          handleClick={this.handleLabelClick}
          required={true}
        />
        <input type="hidden" name={this.props.inputName} id={this.props.inputId} value={this.state.locale} />
        <LocaleSelect
          value={this.state.locale}
          name={'locale-select'}
          onChange={this.setLocale}
          availableLocales={this.props.availableLocales}
          isOpen={this.state.isOpen}
        />
      </div>
    )
  }
}

LocaleInput.propTypes = {
  availableLocales: PropTypes.array.isRequired,
  inputId: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
}

export default LocaleInput
