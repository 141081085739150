import AccessRequests from './AccessRequests'
import AccessRequest from './AccessRequest'
import AccessRequestsContainer from './AccessRequestsContainer'
import AccessRequestsModule from './AccessRequestsModule'
import AcceptanceModal from './AcceptanceModal'
import AcceptanceModalContainer from './AcceptanceModalContainer'
import AccessRequestRoleSelect from './AccessRequestRoleSelect'
import AccessRequestConsent from './AccessRequestConsent'

export {
  AccessRequests,
  AccessRequest,
  AccessRequestsModule,
  AccessRequestsContainer,
  AcceptanceModal,
  AcceptanceModalContainer,
  AccessRequestRoleSelect,
  AccessRequestConsent,
}

export default AccessRequestsModule
