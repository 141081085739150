import React from 'react'
import PropTypes from 'prop-types'
import ProviderWrapper from './ProviderWrapper'
import ButtonLink from './ButtonLink'

const EditButton = ({ link, modifiable }) => (
  <ProviderWrapper>
    <ButtonLink link={link} modifiable={modifiable} />
  </ProviderWrapper>
)

EditButton.propTypes = {
  link: PropTypes.string.isRequired,
  modifiable: PropTypes.bool.isRequired,
}

export default EditButton
