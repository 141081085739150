import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import PaginationButtons from 'Components/Base/PaginationButtons'
import Label from 'Components/Forms/Label'

import { t } from 'i18n'

const i18nOpts = { scope: 'care_plan_creators.resources' }

class ResourceList extends Component {
  constructor(props) {
    super(props)

    this.state = { currentPageNumber: 1 }
  }

  componentDidUpdate(prevProps) {
    if (this.props.children.length !== prevProps.children.length) {
      this.handlePageChange(1)
    }
  }

  handlePageChange = (pageNumber) => {
    this.setState({ currentPageNumber: Number(pageNumber) })
  }

  render() {
    const beginSlice = (this.state.currentPageNumber - 1) * this.props.maxResourcesPerPage
    const endSlice = this.state.currentPageNumber * this.props.maxResourcesPerPage

    return (
      <Fragment>
        { this.props.children.length > 0 &&
          <Fragment>
            <Label inputName={this.props.selectedTab} text={this.props.selectedTab} />
            {this.props.children.slice(beginSlice, endSlice)}
          </Fragment>
        }

        <PaginationButtons
          itemName={t('resources', i18nOpts)}
          currentPageNumber={this.state.currentPageNumber}
          onPageChange={this.handlePageChange}
          maxItemCountPerPage={this.props.maxResourcesPerPage}
          totalItemCount={this.props.children.length}
        />
      </Fragment>
    )
  }
}

ResourceList.propTypes = {
  children: PropTypes.array,
  maxResourcesPerPage: PropTypes.number,
  selectedTab: PropTypes.string.isRequired
}

ResourceList.defaultProps = {
  children: [],
  maxResourcesPerPage: 12
}

export default ResourceList
