import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import RecommendationInput from 'Components/Recommendations/RecommendationsCard/RecommendationsContainer/RecommendationInput';
import handleUpdateRecommendation from './handleUpdateRecommendation';
import {
  setRecommendations,
  setEditingRecommendationIndex,
  setIsProcessing,
} from 'Actions/recommendationsBlock';

const Edit = ({
  recommendation,
  setViewState,
  index,
  editingRecommendationIndex,
  setEditingRecommendationIndex,
  setRecommendations,
  setIsProcessing,
  carePlanUuid,
}) => {
  useLayoutEffect(() => {
    // if user begins editing another recommendation, reverts to 'show' view; can only edit one at a time
    if (index !== editingRecommendationIndex) setViewState('show');
  }, [index, editingRecommendationIndex]);

  return (
    <RecommendationInput
      content={recommendation.content}
      handleCancel={() => setViewState('show')}
      handleSave={(textContent, setErrors) =>
        handleUpdateRecommendation(
          carePlanUuid,
          textContent,
          recommendation.id,
          setRecommendations,
          setEditingRecommendationIndex,
          setErrors,
          setIsProcessing
        )
      }
    />
  );
};

const mapStateToProps = ({
  recommendationsBlock: {
    editingRecommendationIndex,
    userDetails: { carePlanUuid },
  },
}) => {
  return { editingRecommendationIndex, carePlanUuid };
};

const mapDispatchToProps = (dispatch) => ({
  setRecommendations: (recommendations) => dispatch(setRecommendations(recommendations)),
  setEditingRecommendationIndex: (index) => dispatch(setEditingRecommendationIndex(index)),
  setIsProcessing: (isProcessing) => dispatch(setIsProcessing(isProcessing)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
