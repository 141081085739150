import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Button, Icon } from 'Components/Base'

class SearchInput extends PureComponent {
  constructor(props) {
    super(props)

    this.setInputRef = (element) => {
      this.inputRef = element
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleReset = this.handleReset.bind(this)
  }

  get classes() {
    return classNames('input-icon', 'input-icon--search', this.props.className)
  }

  handleSubmit(evt) {
    evt.preventDefault()
  }

  handleReset() {
    this.props.onChange({ target: { value: '' } })
    this.inputRef.focus()
  }

  render() {
    return (
      <form className="search-input" noValidate onSubmit={this.handleSubmit} onReset={this.handleReset}>
        <input
          ref={this.setInputRef}
          name={this.props.name}
          className={this.classes}
          placeholder={this.props.placeholder}
          aria-label={this.props.placeholder}
          onChange={this.props.onChange}
          type="text"
          autoComplete="off"
          required
        />

        <Button className="search-reset" type="reset" onClick={this.handleReset}>
          <Icon name="close" />
        </Button>
      </form>
    )
  }
}

SearchInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
}

SearchInput.defaultProps = {
  className: '',
  name: 'search',
  placeholder: '',
}

export default SearchInput
