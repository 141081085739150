import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import TaskList from 'Components/Tasks/Index/TaskList'
import EventList from 'Components/Tasks/Index/EventList'

const WeeklyList = ({ placeUserId }) => (
  <Fragment>
    <TaskList healthCareView placeUserId={placeUserId} />
    <EventList healthCareView placeUserId={placeUserId} />
  </Fragment>
)

WeeklyList.propTypes = {
  placeUserId: PropTypes.number.isRequired,
}

WeeklyList.defaultProps = {}

export default WeeklyList
