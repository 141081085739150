import React from 'react';
import PropTypes from 'prop-types';
import Icon from './../Icon';
import { translateDataKeys } from './../legendFunctions';
import parseDataValues from './../parseDataValues';

const Row = ({ dataKey, dataValue, isIconsDisplayed }) => {
  if (!dataValue) return null;

  return (
    <div className="blood-glucose-legend__row">
      {isIconsDisplayed && <Icon type={dataKey} />}
      <span className="blood-glucose-legend__item blood-glucose-legend__item--desktop">
        {`${translateDataKeys(dataKey, false)}: ${parseDataValues(dataValue, dataKey)}`}
      </span>
      <span className="blood-glucose-legend__item blood-glucose-legend__item--mobile">
        {`${translateDataKeys(dataKey, true)}: ${parseDataValues(dataValue, dataKey)}`}
      </span>
    </div>
  );
};

Row.propTypes = {
  dataKey: PropTypes.string.isRequired,
  dataValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

Row.defaultProps = {
  dataValue: '',
};

export default Row;
