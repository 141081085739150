import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'i18n';

import Icon from 'Components/Base/Icon';
import { Spinner } from 'Components/Base/Loader';
import { Tooltip } from 'Components/Base';

import { actions } from 'Actions/notificationTray';

const i18nOpts = { scope: 'notifications' };

class Notification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };

    this.handleMarkAsRead = this.handleMarkAsRead.bind(this);
    this.markAsReadAndPassthrough = this.markAsReadAndPassthrough.bind(this);
  }

  async markAsReadAndPassthrough(event) {
    event.preventDefault();
    const destination = event.currentTarget.href;
    this.setState({ isLoading: true });
    await this.props.markAsRead(this.props.id);
    window.location.assign(destination);
  }

  handleMarkAsRead(event) {
    event.preventDefault();

    this.setState({ isLoading: true });
    this.props.markAsRead(this.props.id);
  }

  renderActions() {
    if (this.state.isLoading) return <Spinner />;

    return (
      <div className="icon__container" onClick={this.handleMarkAsRead} data-test="markAsRead">
        <Tooltip text={t('tooltips.remove_notification', i18nOpts)} tabIndex="0" position="left">
          <Icon name="remove-notification" />
        </Tooltip>
      </div>
    );
  }

  markupContent() {
    return { __html: this.props.body };
  }

  render() {
    return (
      <li>
        <a href={this.props.link} onClick={this.markAsReadAndPassthrough} className="notification">
          <div className="notification__content">
            <div className="notification__content-text" dangerouslySetInnerHTML={this.markupContent()} />

            <div className="notification__content-time">{this.props.timestamp}</div>
          </div>
        </a>
        <div className="notification__actions">{this.renderActions()}</div>
      </li>
    );
  }
}

Notification.propTypes = {
  body: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired,
  markAsRead: PropTypes.func.isRequired,
  timestamp: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    markAsRead: (id) => dispatch(actions.markAsRead(id)),
  };
};

export default connect(null, mapDispatchToProps)(Notification);
