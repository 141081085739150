import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import _ from 'lodash';

import Avatar from 'PulseBackports/Avatar';
import PhoneNumbers from './PhoneNumbers';
import PhoneNumber from './PhoneNumber';
import Addresses from './Addresses';
import Address from './Address';
import InvitationForm from './InvitationForm';
import ResendInvitation from '../ResendInvitation';

const i18nOpts = { scope: 'patient.show.index' };

const Patient = ({
  patient,
  canInvite,
  canManageInvitation,
  editAddress,
  editPhoneNumber,
  addEmail,
  patient: {
    addresses,
    teamMembershipId,
    patientHealthInformation: phi,
    phoneNumbers,
    preferredPhoneNumber,
    preferredAddress,
  },
  patientMember,
  isCareHubPatient
}) => (
  <div>
    <div className="patient-details">
      <div className="patient-details__section patient-details__section--avatar">
        <Avatar
          src={patient.photo}
          fullName={patient.name}
          size={8.25}
        />
      </div>
      <div className="patient-details__section patient-details__section--wrapper">
        <div className="patient-details__section patient-details__section--content">
          <dl className="patient-details__section patient-details__section--gender">
            <dt>{t('name', i18nOpts)}</dt>
            <dd>{patient.name}</dd>
            <dt>{t('gender', i18nOpts)}</dt>
            <dd>
              {t(patient.gender, { scope: 'patient.show.index', defaultValue: t('unknown', i18nOpts) })}
            </dd>
            <dt>{t('birthday', i18nOpts)}</dt>
            <dd>{patient.birthday || t('unknown', i18nOpts)}</dd>
          </dl>

          <dl className="patient-details__section">
            <dt>{t('primary_physician.header', i18nOpts)}</dt>
            <dd>{phi.primaryPhysician || t('unknown', i18nOpts)}</dd>
            <dt>{t('medical_record_number', i18nOpts)}</dt>
            <dd>{phi.medicalRecordNumber || t('unknown', i18nOpts)}</dd>
            <dt>{t('email', i18nOpts)}</dt>
            <dd>{patient.email || t('unknown', i18nOpts)}</dd>
            {canManageInvitation && <ResendInvitation member={patientMember} />}
          </dl>

          <dl className="patient-details__section">
            <dt>{t('phone_number', i18nOpts)}</dt>
            <dd>
              {preferredPhoneNumber.id ? <PhoneNumber {...preferredPhoneNumber} /> : t('unknown', i18nOpts)}
            </dd>
            <dt>{t('address', i18nOpts)}</dt>
            <dd>
              {!_.isEmpty(_.pickBy(_.omit(preferredAddress, ['id', 'label']), (value) => value)) ? (
                <Address {...preferredAddress} />
              ) : (
                t('unknown', i18nOpts)
              )}
            </dd>
          </dl>
        </div>
        {canInvite && <InvitationForm onSuccess={addEmail} teamMembershipId={teamMembershipId} isCareHubPatient={isCareHubPatient} />}
      </div>
    </div>

    {!!phoneNumbers.length && <PhoneNumbers edit={editPhoneNumber} phoneNumbers={phoneNumbers} />}
    {!!addresses.length && <Addresses edit={editAddress} addresses={addresses} />}
  </div>
);

Patient.propTypes = {
  addEmail: PropTypes.func.isRequired,
  canInvite: PropTypes.bool,
  canManageInvitation: PropTypes.bool,
  carePlanUuid: PropTypes.string.isRequired,
  editAddress: PropTypes.func.isRequired,
  editPhoneNumber: PropTypes.func.isRequired,
  patient: PropTypes.shape({
    birthday: PropTypes.string,
    patientHealthInformation: PropTypes.shape({
      primaryPhysician: PropTypes.string,
      medicalRecordNumber: PropTypes.string,
    }),
    invitation: PropTypes.shape({
      email: PropTypes.string,
    }),
    patientMember: PropTypes.shape({
      id: PropTypes.number,
      email: PropTypes.string,
      role: PropTypes.string,
    }),
    photo: PropTypes.string,
    preferredAddress: PropTypes.object,
    preferredPhoneNumber: PropTypes.object,
    address: PropTypes.array,
    teamMembershipId: PropTypes.number,
    addresses: PropTypes.array,
    phoneNumbers: PropTypes.array,
    name: PropTypes.string,
    gender: PropTypes.string,
    age: PropTypes.string,
    email: PropTypes.string,
  }),
  patientMember: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
};

Patient.defaultProps = {
  canInvite: false,
  canManageInvitation: false,
  patient: {
    addresses: [],
    invitation: {},
    patientHealthInformation: {},
    phoneNumbers: [],
    preferredAddress: {},
    preferredPhoneNumber: {},
  },
};

export default Patient;
