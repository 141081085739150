import Api from '../Api'

class CarePlan extends Api {
  constructor(id) {
    super()

    this.id = id
  }

  carePlanContacts() {
    return super.request(`${this.url}/${this.id}/care_plan_contacts`)
  }

  channelContacts() {
    return super.request(`${this.url}/${this.id}/channel_contacts`)
  }

  get url() {
    return '/inbox/care_plans'
  }
}

export default CarePlan
