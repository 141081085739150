const isChartDataEmpty = (chartData, weekOrder, renderedDays) => {
  let isEmpty = true;

  // Checks if there is any renderedDays and value is empty
  for (const [index, day] of weekOrder.entries()) {
    const isDayRendered = !!renderedDays[day];
    const hasDataForDay = !!chartData[index];

    if (isDayRendered && hasDataForDay) {
      isEmpty = false;
      break;
    }
  }

  return isEmpty;
};

export default isChartDataEmpty;
