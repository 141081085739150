import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import ToggledIndicator from './ToggledIndicator';
import ToggleButton from './ToggleButton';

const DayToggler = ({ weekOrder }) => {
  if (!weekOrder.length) return null;

  return (
    <div className="comparison-chart__days-container">
      {weekOrder.map((day) => (
        <Fragment key={`${day}-toggle`}>
          <ToggledIndicator day={day} />
          <ToggleButton day={day} />
        </Fragment>
      ))}
    </div>
  );
};

const mapStateToProps = ({ dashboardComparisonChart: { weekOrder } }) => {
  return { weekOrder };
};

export default connect(mapStateToProps, null)(DayToggler);
