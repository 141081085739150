import React from 'react';
import ProviderWrapper from 'Components/Base/ProviderWrapper';
import Header from './Header';

const HeaderWrapper = () => (
  <ProviderWrapper>
    <Header />
  </ProviderWrapper>
);

export default HeaderWrapper;
