import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
  Activity,
  ActivityBody,
  TaskCheckBox,
  AssignmentsWithDueAt,
  AssignmentActions,
  ServiceLink,
  CarePlanBadge,
  TaskDetails,
  TaskAppointmentForm,
} from '../';

import { redirectTo } from 'Utilities/linkHelpers';

class TaskContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.renderFooter = this.renderFooter.bind(this);
  }

  get showAppointmentForm() {
    return this.props.completedAt && !this.props.isComplete;
  }

  renderFooter() {
    return this.showAppointmentForm ? (
      <TaskAppointmentForm
        carePlanUuid={this.props.carePlanUuid}
        skip={this.props.skip}
        practitioner={this.props.practitioner}
        text={this.props.text}
        parentId={this.props.id}
        timeZone={this.props.timeZone}
        url={this.props.url}
      />
    ) : (
      this.props.isHealthCareView && this.props.myAssignment.status === 'pending' && (
        <AssignmentActions updateStatus={this.props.updateStatus} />
      )
    );
  }

  render() {
    return (
      <Activity onClick={redirectTo.bind(null, this.props.link)} footer={this.renderFooter()}>
        <TaskCheckBox
          onClick={this.props.handleClick}
          canComplete={this.props.canComplete}
          completedAt={this.props.completedAt}
          isCompleting={this.props.isCompleting}
        />
        {this.props.carePlan.place && !this.props.isHealthCareView && (
          <CarePlanBadge {...this.props.carePlan} />
        )}

        <ActivityBody text={this.props.text}>
          <TaskDetails title={this.props.carePlan.title} patientName={this.props.patientName} />
          {this.props.activitiesServices.map((service) => (
            <ServiceLink key={service.service.id} service={service.service} link={service.serviceLink} />
          ))}
        </ActivityBody>
        <AssignmentsWithDueAt
          assignments={this.props.allAssignments || this.props.assignments}
          dueAt={this.props.dueAt}
        />
      </Activity>
    );
  }
}

TaskContainer.propTypes = {
  activitiesServices: PropTypes.array,
  allAssignments: PropTypes.array,
  assignments: PropTypes.array,
  canComplete: PropTypes.bool,
  carePlan: PropTypes.shape({
    title: PropTypes.string,
    place: PropTypes.shape({
      name: PropTypes.string,
      logo: PropTypes.string,
    }),
  }),
  carePlanUuid: PropTypes.string.isRequired,
  completedAt: PropTypes.string,
  dueAt: PropTypes.any,
  handleClick: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  isComplete: PropTypes.bool,
  isCompleting: PropTypes.bool,
  isHealthCareView: PropTypes.bool,
  link: PropTypes.string,
  myAssignment: PropTypes.object,
  patientName: PropTypes.string,
  practitioner: PropTypes.string,
  services: PropTypes.array,
  skip: PropTypes.func.isRequired,
  text: PropTypes.string,
  timeZone: PropTypes.string,
  updateStatus: PropTypes.func,
};

TaskContainer.defaultProps = {
  activitiesServices: [],
  allAssignments: null,
  assignments: [],
  canComplete: false,
  carePlan: {},
  completedAt: '',
  dueAt: '',
  isComplete: false,
  isCompleting: false,
  isHealthCareView: false,
  link: '',
  myAssignment: null,
  patientName: null,
  practitioner: '',
  services: [],
  text: '',
  timeZone: 'Etc/UTC',
  updateStatus: null,
};

export default TaskContainer;
