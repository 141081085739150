import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { snakeCase } from 'lodash'
import { t } from 'i18n'

import { Form as FormikForm } from 'formik'

import { Button } from 'Components/Base'
import { Footer } from 'Components/Base/Card/'
import Submit from 'Components/Forms/Submit'
import ItemListHeader from 'Components/Forms/ItemListHeader'
import { HealthTeamContactInput } from './'

const i18nOpts = { scope: 'team.health_team.edit_health_team' }

class Form extends PureComponent {
  constructor(props) {
    super(props)

    this.renderContacts = this.renderContacts.bind(this)
  }

  renderContacts(values, key, onChange) {
    return values[key] && values[key].length ? (
      <ul className="item-pill-section__item-list">
        {values[key].map((member, index) => (
          <HealthTeamContactInput
            key={index}
            {...member}
            selected={key === 'selectedMembers'}
            onToggle={onChange.bind(null, values, key, member)}
          />
        ))}
      </ul>
    ) : (
      <p className="item-pill-section--item-list-empty">{t(`no_${snakeCase(key)}`, i18nOpts)}</p>
    )
  }

  render() {
    const { placeName, values, isOpen, isSubmitting, onChange, onCancel, onToggleList } = this.props

    return (
      <FormikForm>
        <ItemListHeader
          headerText={t('selected_header', { ...i18nOpts, place: placeName })}
          iconName="htc"
          itemsSelectedCount={values.selectedMembers.length}
          listIsOpen={isOpen}
          toggleListVisibility={onToggleList}
        />
        {isOpen && this.renderContacts(values, 'selectedMembers', onChange)}

        <ItemListHeader
          headerText={t('unselected_header', { ...i18nOpts, place: placeName })}
          iconName="htc_add"
          listIsOpen
        />
        {this.renderContacts(values, 'unselectedMembers', onChange)}

        <Footer
          right={
            <>
              <Button
                className="btn btn--secondary"
                disabled={isSubmitting}
                onClick={onCancel}
                text={t('cancel', i18nOpts)}
              />
              <Submit value={t('submit', i18nOpts)} disabled={isSubmitting} />
            </>
          }
        />
      </FormikForm>
    )
  }
}

Form.propTypes = {
  isOpen: PropTypes.bool,
  isSubmitting: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onToggleList: PropTypes.func.isRequired,
  placeName: PropTypes.string,
  values: PropTypes.shape({
    selectedMembers: PropTypes.array.isRequired,
    unselectedMembers: PropTypes.array.isRequired,
  }).isRequired,
}

Form.defaultProps = {
  isOpen: true,
  placeName: '',
}

export default Form
