import { Resource } from './Api'

class User extends Resource {
  constructor() {
    super()

    this.updateNotifications = this.updateNotifications.bind(this)
    this.updateLocale = this.updateLocale.bind(this)
  }

  updateNotifications({ allSms: all_sms, allEmails: all_emails }) {
    return this.request(`${this.url}/notifications`, 'PATCH', {
      additional_user_data: { all_sms, all_emails },
    })
  }

  updateLocale(locale) {
    return this.request(`${this.url}/locale`, 'PATCH', { user: { locale } })
  }

  get url() {
    return '/profile'
  }
}

export default User
