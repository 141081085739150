import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Modal } from 'Components/Base/Modal'
import Button from 'Components/Base/Button'
import IconButton from 'Components/Base/IconButton'

const DeleteModal = ({ modalIsOpen, closeModal, deleteOccurrence, deleteSeries }) => (
  <Modal isOpen={modalIsOpen} closeModal={closeModal} onRequestclose={closeModal}>
    <p>{t('delete_series', { scope: 'repeated_tasks.occurrences.edit' })}</p>

    <div className="form__submit">
      <IconButton
        iconName="refresh"
        id="delete-series"
        onClick={deleteSeries}
        text={t('this_series', { scope: 'repeated_tasks.occurrences.edit' })}
      />

      <IconButton
        iconName="task"
        id="delete-occurrence"
        onClick={deleteOccurrence}
        text={t('this_event', { scope: 'repeated_tasks.occurrences.edit' })}
      />

      <Button
        className="btn btn--secondary"
        id="cancel-delete"
        onClick={closeModal}
        text={t('cancel', { scope: 'repeated_tasks.occurrences.edit' })}
      />
    </div>
  </Modal>
)

DeleteModal.propTypes = {
  closeModal: PropTypes.func,
  deleteOccurrence: PropTypes.func,
  deleteSeries: PropTypes.func,
  modalIsOpen: PropTypes.bool,
}

export default DeleteModal
