import React from 'react';
import { t } from 'i18n';

import Icon from 'Components/Base/Icon';

const i18nOpts = {
  scope: 'care_plan_action_plan_page.activities_container.forms_card.repeated_form',
};

const RepeatedForm = ({ name, localizedFrequency, until, formattedTime }) => (
  <li className="order order__repeat">
    <div className="order__repeat-icon">
      <strong>
        <Icon className="icon--medium" name="refresh" />
      </strong>
    </div>
    <div className="order__repeat-text">
      <div>
        <strong>{name || 'unnamed_form'}</strong>
      </div>
      <span>
        {until
          ? t('repeat_statement', { ...i18nOpts, localizedFrequency, until, formattedTime })
          : t('repeat_statement_infinity', { ...i18nOpts, localizedFrequency, formattedTime })}
      </span>
    </div>
  </li>
);

export default RepeatedForm;
