import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Button, ModalFooter } from 'Components/Base'
import { ItemPill, ItemPillText, ItemPillActions, ItemPillLargeAction } from 'Components/Base/ItemPill'
import Checkbox from 'Components/Forms/Checkbox'
import RoleChip from 'Components/Team/RoleChip'

import banner from 'approve_request.svg'

const i18nOpts = { scope: 'team.acceptance_modal.consent' }

class AccessRequestConsent extends PureComponent {
  constructor(props) {
    super(props)
  }

  get submitIsDisabled() {
    return !this.props.consent || this.props.isSubmitting
  }

  render() {
    return (
      <Fragment>
        <header className="wave-header">
          <h2 className="wave-header__title">{this.props.accessRequest && t('header_title', i18nOpts)}</h2>
          <img src={banner} className="wave-header__banner wave-header__banner--circle" />
        </header>

        <h2 className="team-invitations-modal__review-title hidden--sm hidden--vert-sm">
          {t('header_title', i18nOpts)}
        </h2>

        <div className="team-invitations-modal__role-title">
          <h3 className="team-invitations-modal__role-name text-medium">{t('description', i18nOpts)}</h3>
          <RoleChip role={this.props.role} />
        </div>

        <ItemPill stretch flat large>
          <ItemPillText
            text={this.props.accessRequest.name}
            subtext={t(`relationship.${this.props.accessRequest.relationship}`)}
            truncate={false}
          />
          <ItemPillActions>
            <ItemPillLargeAction iconName="edit" label={t('edit', i18nOpts)} onClick={this.props.onBack} />
          </ItemPillActions>
        </ItemPill>

        <Checkbox
          checked={this.props.consent}
          name="consent"
          text={t('declaration', i18nOpts)}
          onChange={this.props.onToggleConsent}
        />

        <ModalFooter
          renderRight={() => (
            <Fragment>
              <Button
                text={t('decline', i18nOpts)}
                onClick={this.props.onDecline}
                className="btn btn--secondary"
              />
              <Button
                text={t('approve', i18nOpts)}
                className="btn"
                onClick={this.props.onApprove}
                disabled={this.submitIsDisabled}
              />
            </Fragment>
          )}
          renderLeft={() => (
            <Button
              text={t('cancel', i18nOpts)}
              onClick={this.props.onClose}
              className="btn btn--secondary"
            />
          )}
        />
      </Fragment>
    )
  }
}

AccessRequestConsent.propTypes = {
  accessRequest: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    photo: PropTypes.string,
    preferredPhoneNumber: PropTypes.shape({
      number: PropTypes.string.isRequired,
    }),
    relationship: PropTypes.string.isRequired,
  }).isRequired,
  consent: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onApprove: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  onToggleConsent: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
}

AccessRequestConsent.defaultProps = {
  consent: false,
  isSubmitting: false,
}
export default AccessRequestConsent
