import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'i18n';

import RepresentativeForm from './RepresentativeForm';
import UserForm from './UserForm';
import PatientForm from './PatientForm';
import AvatarForm from './AvatarForm';
import Icon from 'Components/Base/Icon';

import welcome from 'team_communication.svg';

const i18nOpts = { scope: 'registration.index' };
const patientSignupFlow = [RepresentativeForm, UserForm, AvatarForm];
const caregiverSignupFlow = [RepresentativeForm, UserForm, PatientForm, AvatarForm];

const SignupContainer = ({
  role,
  loginUrl,
  placeName,
  validateUserUrl,
  validatePatientUrl,
  registrationUrl,
}) => {
  const isPatientSignUp = role === 'patient';
  const signupComponents = isPatientSignUp ? patientSignupFlow : caregiverSignupFlow;

  return (
    <div>
      <div className="registrationV2__header login-header">
        <div className="login-header login-header__already-have-account">
          {isPatientSignUp && (
            <>
              {t('already_have_account', i18nOpts)}
              <a className="btn btn--secondary btn--extra-small" href={loginUrl}>
                {t('login', i18nOpts)}
              </a>
            </>
          )}
        </div>
      </div>
      <div className="registrationV2">
        <div className="registrationV2-col registrationV2-col__forms">
          {signupComponents.map((Form, index) => {
            return (
              <div key={`form-${index}`} className="form">
                <Form
                  index={index}
                  placeName={placeName}
                  validateUserUrl={validateUserUrl}
                  validatePatientUrl={validatePatientUrl}
                  registrationUrl={registrationUrl}
                />
              </div>
            );
          })}
        </div>

        <div className="registrationV2-col">
          <div className="welcome-intro">
            <div className="welcome-intro__header">
              <h1 className="welcome-intro__header__title">{t('welcome', i18nOpts)}</h1>
              <div className="welcome-intro__header__subtitle">{t('subtitle', i18nOpts)}</div>
            </div>

            <div className="welcome-intro__teaser">
              {t('teasers', i18nOpts).map((listItem, index) => (
                <div className="welcome-intro__teaser__point" key={`teaser-${index}`}>
                  <Icon className="welcome-intro__teaser__point__icon" name="check" />
                  <div className="welcome-intro__teaser__point__text">{listItem}</div>
                </div>
              ))}
            </div>

            <img className="welcome-intro__image" src={welcome} />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ signupPage }) => {
  const {
    currentStep,
    user: { role },
  } = signupPage;

  return { currentStep, role };
};

SignupContainer.propTypes = {
  loginUrl: PropTypes.string.isRequired,
  placeName: PropTypes.string.isRequired,
  registrationUrl: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  validatePatientUrl: PropTypes.string.isRequired,
  validateUserUrl: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(SignupContainer);
