import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { t } from 'i18n'

import SectionFooter from 'Components/CarePlanCreators/SectionFooter'
import InputGroup from 'Components/Forms/InputGroup'
import CarePlanDispatcher from 'Api/Dispatchers/CarePlanDispatcher'

const i18nOpts = { scope: 'care_plan_creators.instructions' }

class InstructionsSection extends Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleErrors = this.handleErrors.bind(this)

    this.state = {
      notes: this.props.notes || '',
      errors: {},
      isSubmitting: false,
    }
  }

  handleErrors(errors) {
    this.setState({ errors })
  }

  handleChange({ target: { name, value } }) {
    this.setState((prevState) => _.set(prevState, name, value))
  }

  handleSubmit(evt) {
    evt.preventDefault()
    this.setState({ isSubmitting: true })

    return new CarePlanDispatcher(this.props.resourceId, this.props.isTemplate)
      .updateInstructions({ carePlan: { notes: this.state.notes } })
      .then((res) => {
        if (res.ok) {
          this.setState({ isSubmitting: false })

          this.navigateToNextSection()
        } else if (res.status === 422) {
          this.setState({ isSubmitting: false })
          return res.json().then(this.handleErrors)
        }
      })
  }

  navigateToNextSection() {
    if (this.props.isTemplate) {
      window.location.hash = '#finish'
    } else {
      window.location.hash = '#careteam'
    }
  }

  render() {
    const { notes, isSubmitting, errors } = this.state

    return (
      <form noValidate>
        <h4 className="careplan__subheader">{t('subheader', i18nOpts)}</h4>

        <InputGroup
          label={t('label', i18nOpts)}
          value={notes}
          component="textarea"
          onChange={this.handleChange}
          errors={errors}
          name="notes"
        />
        <SectionFooter onNext={this.handleSubmit} isSubmitting={isSubmitting} />
      </form>
    )
  }
}

InstructionsSection.propTypes = {
  isTemplate: PropTypes.bool,
  notes: PropTypes.string,
  resourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

InstructionsSection.defaultProps = {
  isTemplate: false,
  notes: '',
}

export default InstructionsSection
