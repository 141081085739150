import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import Assignment from './Assignment'
import MyAssignment from './MyAssignment'
import ClaimTask from './ClaimTask'

const i18nOpts = { scope: 'activities.assignments.index' }

class Assignments extends Component {
  constructor(props) {
    super(props)

    this.renderAssignment = this.renderAssignment.bind(this)
    this.isClaimableByCurrentUser = this.isClaimableByCurrentUser.bind(this)
  }

  isClaimableByCurrentUser() {
    return (
      this.props.canClaim &&
      !this.props.assignments.filter((a) => a.teamMembership.id === this.props.currentTeamMembershipId).length
    )
  }

  renderAssignment(assignment) {
    const { currentTeamMembershipId, path } = this.props
    const assignmentProps = { key: assignment.id, assignment }

    if (assignment.teamMembership.id === currentTeamMembershipId) {
      return (
        <MyAssignment
          accept={this.props.updateStatus.bind(this, assignment.id, assignment.teamMembership.id, 'accepted')}
          decline={this.props.updateStatus.bind(
            this,
            assignment.id,
            assignment.teamMembership.id,
            'declined'
          )}
          {...assignmentProps}
        />
      )
    } else {
      return (
        <Assignment
          claim={this.props.dismissAndClaim.bind(this, assignment.id, assignment.teamMembership.id)}
          claimable={this.isClaimableByCurrentUser()}
          dismiss={this.props.updateStatus.bind(
            this,
            assignment.id,
            assignment.teamMembership.id,
            'dismissed'
          )}
          currentTeamMembershipId={currentTeamMembershipId}
          activityPath={path}
          {...assignmentProps}
        />
      )
    }
  }

  filter(status, assignment) {
    return assignment.status === status
  }

  render() {
    const accepted = this.filter.bind(null, 'accepted')
    const declined = this.filter.bind(null, 'declined')
    const pending = this.filter.bind(null, 'pending')
    const dismissed = this.filter.bind(null, 'dismissed')
    return (
      <ul className="assignments">
        {(() => {
          if (this.props.assignments.length) {
            return [
              this.props.assignments.filter(accepted).map(this.renderAssignment),
              this.props.assignments.filter(declined).map(this.renderAssignment),
              this.props.assignments.filter(pending).map(this.renderAssignment),
              this.props.assignments.filter(dismissed).map(this.renderAssignment),
            ].reduce((a, b) => a.concat(b))
          } else if (!this.isClaimableByCurrentUser()) {
            return <li className="assignment assignment--blank">{t('no_one_assigned', i18nOpts)}</li>
          }
        })()}

        {this.isClaimableByCurrentUser() && <ClaimTask claim={this.props.claim} />}
      </ul>
    )
  }
}

Assignments.propTypes = {
  assignments: PropTypes.array,
  canClaim: PropTypes.bool.isRequired,
  carePlanId: PropTypes.string.isRequired,
  claim: PropTypes.func,
  currentTeamMembershipId: PropTypes.number.isRequired,
  dismissAndClaim: PropTypes.func.isRequired,
  id: PropTypes.number,
  path: PropTypes.string.isRequired,
  updateStatus: PropTypes.func,
}

Assignments.defaultProps = {
  assignments: [],
}

export default Assignments
