export const toggleShadowClass = () => {
  const modalFooter = document.querySelector('.ReactModal__ContentFooter')
  const previousElementSibling = modalFooter ? modalFooter.previousElementSibling : null

  if (previousElementSibling === null) {
    return
  }

  const { top: modalFootertop } = modalFooter.getBoundingClientRect()
  const { bottom: lastElementBottom } = previousElementSibling.getBoundingClientRect()

  if (lastElementBottom > modalFootertop) {
    modalFooter.classList.add('ReactModal__ContentFooter--shadow')
  } else {
    modalFooter.classList.remove('ReactModal__ContentFooter--shadow')
  }
}
