import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'
import moment from 'ct-moment'
import 'moment-timezone'

import { roundTimeUp } from 'Utilities/time'

import CheckIn from './CheckIn'
import RepeatedActivity from '../RepeatedActivity'
import CheckInModalForm from './CheckInModalForm'
import { Card, AddActionHeader } from 'Components/Base/Card/'

import CheckInApi from 'Api/CheckIn'
import RepeatedCheckInApi from 'Api/RepeatedCheckIn'

const i18nOpts = { scope: 'care_plans.checkin_section.index' }

class CheckInSection extends Component {
  constructor(props) {
    super(props)

    this.handleActionClick = this.handleActionClick.bind(this)
    this.resetFormState = this.resetFormState.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSuccess = this.handleSuccess.bind(this)
    this.handleErrors = this.handleErrors.bind(this)
    this.checkInParams = this.checkInParams.bind(this)

    this.state = {
      ...this.initialState,
      showForm: props.showForm,
      checkInTemplateId: props.selectedTemplateId,
      checkIns: props.checkIns,
      repeatedCheckIns: props.repeatedCheckIns,
    }
  }

  get initialState() {
    const currentTime = moment().tz(this.props.timeZone)

    return {
      frequencyType: 'once',
      frequency: '',
      dueAt: roundTimeUp(5, currentTime).format(),
      until: undefined,
      count: undefined,
      endRepeat: 'never',
      interval: 1,
      errors: {},
    }
  }

  get api() {
    if (this.state.frequencyType === 'recurring') {
      return new RepeatedCheckInApi(this.props.parentId, '')
    } else {
      return new CheckInApi(this.props.parentId, '')
    }
  }

  get cardProps() {
    let renderHeaderAction = null

    if (this.props.canAdd) {
      const onActionClick = this.handleActionClick

      renderHeaderAction = (actionWrapper) => <AddActionHeader onClick={() => actionWrapper(onActionClick)} />
    }

    return {
      title: t('header', i18nOpts),
      collapsable: true,
      renderHeaderAction,
    }
  }

  resetFormState() {
    this.setState({ ...this.initialState })
  }

  checkInParams() {
    const {
      frequencyType,
      count,
      endRepeat,
      dueAt,
      checkInTemplateId,
      frequency,
      until,
      timeZone,
      interval,
    } = this.state

    const paramsDueAt = frequencyType === 'once' ? '' : dueAt
    const paramsCount = endRepeat === 'times' ? count : ''
    const paramsUntil = endRepeat === 'on_a_day' ? until : ''

    return {
      frequencyType,
      frequency,
      timeZone,
      endRepeat,
      interval,
      checkInTemplateId,
      dueAt: paramsDueAt,
      until: paramsUntil,
      count: paramsCount,
    }
  }

  handleActionClick() {
    this.setState((prevState) => ({ ...this.initialState, showForm: !prevState.showForm }))
  }

  handleChange({ target: { name, value } }) {
    this.setState((prevState) => {
      if (name === 'frequencyType' && value === 'recurring' && this.state.frequency === '') {
        return {
          ...prevState,
          frequencyType: value,
          frequency: 'daily',
        }
      } else {
        return {
          ...prevState,
          [name]: value,
        }
      }
    })
  }

  handleSubmit(evt) {
    evt.preventDefault()

    this.api.create(this.checkInParams()).then((res) => {
      if (res.ok) {
        res.json().then(this.handleSuccess)
      } else if (res.status === 422) {
        res.json().then(this.handleErrors)
      }
    })
  }

  handleSuccess({ checkIn }) {
    if (checkIn.frequencyType === 'recurring') {
      this.setState((prevState) => ({
        repeatedCheckIns: [checkIn, ...prevState.repeatedCheckIns],
        showForm: false,
      }))
    } else {
      this.setState((prevState) => ({ checkIns: [checkIn, ...prevState.checkIns], showForm: false }))
    }
    this.resetFormState()
  }

  handleErrors(errors) {
    this.setState({ errors })
  }

  render() {
    const { checkInTemplates, timeZone } = this.props
    const { repeatedCheckIns, checkIns, checkInTemplateId } = this.state

    return (
      <Fragment>
        <Card {...this.cardProps}>
          <ul>
            {checkIns.map((checkIn) => (
              <CheckIn key={checkIn.id} {...checkIn} />
            ))}

            {repeatedCheckIns.map((repeatedCheckIn) => (
              <RepeatedActivity key={repeatedCheckIn.id} {...repeatedCheckIn} />
            ))}

            {repeatedCheckIns.length + checkIns.length === 0 && (
              <li className="item-pill-section--item-list-empty">{t('empty', i18nOpts)}</li>
            )}
          </ul>
        </Card>
        <CheckInModalForm
          isOpen={this.state.showForm}
          checkInTemplates={checkInTemplates}
          checkInTemplateId={checkInTemplateId}
          onActionClick={this.handleActionClick}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          timeZone={timeZone}
          {...this.state}
        />
      </Fragment>
    )
  }
}

CheckInSection.propTypes = {
  canAdd: PropTypes.bool.isRequired,
  checkInTemplates: PropTypes.array,
  checkIns: PropTypes.array,
  parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  repeatedCheckIns: PropTypes.array,
  selectedTemplateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showForm: PropTypes.bool,
  timeZone: PropTypes.string.isRequired,
}

CheckInSection.defaultProps = {
  checkInTemplates: [],
  checkIns: [],
  parentId: '',
  repeatedCheckIns: [],
  selectedTemplateId: '',
  showForm: false,
}

export default CheckInSection
