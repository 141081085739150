import { request } from 'Apiv2/handleRequest';

export const getTasksApiWrapper = (requestProvider = request) => {
  return async (carePlanId) => {
    try {
      const response = await requestProvider(
        `/care_plans/${carePlanId}/care_plan_action_plan_view_page/tasks`,
        'GET'
      );

      const status = response.status;
      const { tasks, repeated_tasks } = await response.json();

      if (status === 200) {
        return {
          responseType: 'DATA',
          tasks: tasks,
          repeatedTasks: repeated_tasks,
        };
      } else {
        throw 'Missing data';
      }
    } catch (error) {
      console.error(error);
      return { responseType: 'API_ERROR' };
    }
  };
};

export default getTasksApiWrapper(request);
