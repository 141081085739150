import React, { useState } from 'react';
import { Cell, Pie, PieChart } from 'recharts';
import PropTypes from 'prop-types';
import PeriodTabs from './PeriodTabs';
import ProviderWrapper from 'Components/Base/ProviderWrapper';

const AverageDailyInsulin = ({ statistics }) => {
  const [selectedTab, setSelectedTab] = useState('7days');

  const selectedStats = statistics?.data[selectedTab];
  const { header, chartData, averageBasal, averageBolus, averageDailyDose } = selectedStats;

  const cellColors = {
    averageBasal: '#3DADD6',
    averageBolus: '#036294',
  };

  return (
    <ProviderWrapper>
      <div className="pump-settings__historical__container">
        <div className="pump-settings__historical__container__historical-header">{header}</div>

        <PeriodTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />

        <PieChart width={100} height={100}>
          <Pie data={chartData} dataKey="value" nameKey="key" cx="50%" cy="50%">
            {chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} stroke={cellColors[entry.key]} fill={cellColors[entry.key]} />
            ))}
          </Pie>
        </PieChart>
        <div className="pump-settings__historical-table">
          <div className="pump-settings__historical-table__label">
            <span className="pump-settings__circle-basal"></span>
            {averageBasal.header}
          </div>
          <div className="pump-settings__historical-table__value">
            {averageBasal.percentBasal}% ({averageBasal.value} {averageBasal.units})
          </div>
          <div className="pump-settings__historical-table__label">
            <span className="pump-settings__circle-bolus"></span>
            {averageBolus.header}
          </div>
          <div className="pump-settings__historical-table__value">
            {averageBolus.percentBolus}% ({averageBolus.value} {averageBolus.units})
          </div>
        </div>
        <div className="pump-settings__divider"></div>
        <div className="pump-settings__historical-table">
          <div className="pump-settings__historical-table__label">{averageDailyDose.header}</div>
          <div className="pump-settings__historical-table__value">
            {averageDailyDose.value} {averageDailyDose.units}
          </div>
        </div>
      </div>
    </ProviderWrapper>
  );
};

AverageDailyInsulin.propTypes = {
  statistics: PropTypes.object,
};

AverageDailyInsulin.defaultProps = {
  statistics: {
    data: {},
    status: 'error',
  },
};

export default AverageDailyInsulin;
