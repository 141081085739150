import { request } from 'Apiv2/handleRequest';

export const getAssignmentsApiWrapper = (requestProvider = request) => {
  return async (carePlanId) => {
    try {
      const response = await requestProvider(`/care_plans/${carePlanId}/tasks/weekly/assignment_options`, 'GET');

      const status = response.status;

      if (status === 200) {
        const { assignments } = await response.json();
        return {
          responseType: 'DATA',
          assignments,
        };
      } else {
        throw 'Missing data';
      }
    } catch (error) {
      console.error(error);
      return { responseType: 'ERROR' };
    }
  };
};

export default getAssignmentsApiWrapper(request);
