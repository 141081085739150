import React from 'react';
import PropTypes from 'prop-types';
import { iconClassNames } from './legendFunctions';

const Icon = ({ type }) => {
  const iconClass = iconClassNames(type);

  return (
    <div className={iconClass['icon']}>
      <div className="blood-glucose-legend__icon__half"></div>
      <div className={iconClass['coloredArea']}></div>
    </div>
  );
};

Icon.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Icon;
