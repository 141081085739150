import React from 'react';
import ProviderWrapper from 'Components/Base/ProviderWrapper';
import InsulinDosingContainer from './InsulinDosingContainer';

const InsulinDosingCard = () => (
  <ProviderWrapper>
    <InsulinDosingContainer />
  </ProviderWrapper>
);

export default InsulinDosingCard;
