import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { getDisplayName } from '../../utils/hoc';
import createActivityApi from 'Apiv2/activities/createActivityApi';
import editActivityApi from 'Apiv2/activities/editActivityApi';
import deleteActivityApi from 'Apiv2/activities/deleteActivityApi';
import createActivityTemplateApi from 'Apiv2/activityTemplates/createActivityTemplateApi';
import editActivityTemplateApi from 'Apiv2/activityTemplates/editActivityTemplateApi';
import deleteActivityTemplateApi from 'Apiv2/activityTemplates/deleteActivityTemplateApi';

import moment from 'ct-moment';
import 'moment-timezone';
import { roundTimeUp } from 'Utilities/time';
import { t } from 'i18n';

function orderCreator(WrappedComponent) {
  class OrderCreator extends Component {
    constructor(props) {
      super(props);
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleSuccess = this.handleSuccess.bind(this);
      this.handleErrors = this.handleErrors.bind(this);
      this.handleActionClick = this.handleActionClick.bind(this);
      this.deleteOrder = this.deleteOrder.bind(this);
      this.deleteRepeatedTask = this.deleteRepeatedTask.bind(this);
      this.deleteRepeatedAppointment = this.deleteRepeatedAppointment.bind(this);
      this.resetFormState = this.resetFormState.bind(this);
      this.editOrder = this.editOrder.bind(this);
      this.handleOpenModal = this.handleOpenModal.bind(this);
      this.handleCloseModal = this.handleCloseModal.bind(this);
      this.handleSubmitHelpRequest = this.handleSubmitHelpRequest.bind(this);
      this.handleToggleLinkResourcesModal = this.handleToggleLinkResourcesModal.bind(this);
      this.handleSelectResources = this.handleSelectResources.bind(this);
      this.handleRemoveResource = this.handleRemoveResource.bind(this);
      this.handleSelectActivityTemplate = this.handleSelectActivityTemplate.bind(this);

      this.state = {
        ...this.initialState,
        orders: props.orders,
        repeatedTasks: props.repeatedTasks,
        repeatedAppointments: props.repeatedAppointments,
        taskTemplates: props.taskTemplates,
        appointmentTemplates: props.appointmentTemplates,
      };
    }

    get defaultAddress() {
      return {
        id: null,
        address: '',
        city: '',
        region: '',
        postalCode: '',
        countryCode: '',
      };
    }

    get helpRequestState() {
      const { timeZone, patientInfo, currentTime } = this.props;

      const time = currentTime.tz(timeZone);

      return {
        stringNameTo: '',
        addressFrom: {
          address: '',
          city: '',
          region: '',
          postalCode: '',
          countryCode: '',
        },
        stringNameFrom: '',
        addressTo: (patientInfo && patientInfo.addressTo) || {
          address: '',
          city: '',
          region: '',
          postalCode: '',
          countryCode: '',
        },
        phoneNumberRecipient: (patientInfo && patientInfo.phoneNumber) || { number: '' },
        datetimeDueAt: roundTimeUp(5, time).format(),
        stringMoreInfo: '',
      };
    }

    get availableResources() {
      const resources = _.flatten(Object.values(this.props.resources));
      const allResources = _.flattenDeep([this.state.selectedResources, this.props.adHocResources, resources]);
      return _.uniqBy(allResources, (allResources) => {
        return allResources.id.valueOf()
      });
    }

    get initialState() {
      const { timeZone, kind, resources } = this.props;
      const currentTime = this.props.currentTime.tz(timeZone);

      return {
        id: null,
        showForm: false,
        text: '',
        url: '',
        practitioner: '',
        assignments: [],
        daysOffset: undefined,
        dueAt: roundTimeUp(5, currentTime).format(),
        type: 'Task',
        kind,
        completedAt: false,
        address: this.defaultAddress,
        modalIsOpen: false,
        errors: {},
        helpRequest: this.helpRequestState,
        helpRequested: false,
        showLinkResource: false,
        resources,
        selectedResources: [],
        count: undefined,
        endRepeat: 'never',
        frequency: 'daily',
        frequencyType: 'once',
        interval: 1,
        until: undefined,
        timeOffset: undefined,
        isEdit: false,
        selectedActivityTemplateId: -1,
        taskTemplateId: null,
        appointmentTemplateId: null,
      };
    }

    get editing() {
      return !!this.state.id;
    }

    handleSubmit(evt) {
      evt.preventDefault();

      let params = {
        id: this.state.id,
        due_at: this.state.frequencyType === 'once' ? null : this.state.dueAt,
        practitioner: this.state.practitioner,
        assignments: this.state.assignments.map((a) => ({ id: a.value })),
        url: this.state.url,
        kind: this.state.kind,
        completed_at: this.state.completedAt,
        type: this.state.type,
        text: this.state.text,
        resources: this.filterResources(),
        address_attributes: this.state.address,
        interval: this.state.interval,
        count: this.state.endRepeat === 'times' ? this.state.count : null,
        until: this.state.endRepeat === 'on_a_day' ? this.state.until : null,
        days_offset: this.state.daysOffset,
        time_offset: this.state.timeOffset,
        end_repeat: this.state.endRepeat,
        frequency: this.state.frequency,
        frequency_type: this.state.frequencyType,
        selectedActivityTemplateId:
          this.state.selectedActivityTemplateId !== -1 ? this.state.selectedActivityTemplateId : null,
      };

      if (this.state.helpRequested) {
        params['help_request'] = this.state.helpRequest;
      }

      // this is not clean code
      // next part of the rewrite will extract this
      // and move it to individual components to handle
      const type = this.state.type;
      const recurring = this.state.frequencyType === 'recurring';
      const isTemplate = this.props.isTemplate;

      if (this.editing) {
        const editApi = isTemplate ? editActivityTemplateApi : editActivityApi;

        editApi(this.props.parentId, this.state.id, params, type, recurring).then((response) => {
          if (response.status === 200) {
            this.handleSuccess(response);
          } else {
            this.handleErrors(response);
          }
        });
      } else {
        const createApi = isTemplate ? createActivityTemplateApi : createActivityApi;

        createApi(this.props.parentId, params, type, recurring).then((response) => {
          const status = response.status;
          // status here needs to be 201 only
          if (status === 200 || status === 201) {
            this.handleSuccess(response);
          } else {
            this.handleErrors(response);
          }
        });
      }
    }

    handleChange({ target: { name, value } }) {
      this.setState((prevState) => {
        if (name === 'kind') {
          const type = value !== 'referral' ? value : 'Referral';
          return _.set(prevState, 'kind', type);
        } else {
          return _.set(prevState, name, value);
        }
      })
    }

    handleSuccess({ order, repeatedTask, repeatedAppointment }) {
      if (this.editing) {
        if (order) {
          this.setState(({ orders }) => ({
            orders: orders.map((existing) => (existing.id === order.id ? order : existing)),
          }));
        } else if (repeatedTask) {
          this.setState(({ repeatedTasks }) => ({
            repeatedTasks: repeatedTasks.map((existing) =>
              existing.id === repeatedTask.id ? repeatedTask : existing
            ),
          }));
        } else if (repeatedAppointment) {
          this.setState(({ repeatedAppointments }) => ({
            repeatedAppointments: repeatedAppointments.map((existing) =>
              existing.id === repeatedAppointment.id ? repeatedAppointment : existing
            ),
          }));
        }
      } else {
        if (order) {
          order.helpRequest && window.flash_messages.addMessage(t('success', { scope: 'forms.help_request' }));
          const orders = [...this.state.orders, order];
          this.setState({ orders });
        } else if (repeatedTask) {
          const repeatedTasks = [...this.state.repeatedTasks, repeatedTask];
          this.setState({ repeatedTasks });
        } else if (repeatedAppointment) {
          const repeatedAppointments = [...this.state.repeatedAppointments, repeatedAppointment];
          this.setState({ repeatedAppointments });
        }
      }
      this.resetFormState();
    }

    resetFormState() {
      const initialState = {
        ...this.initialState,
        taskTemplates: this.state.taskTemplates,
        appointmentTemplates: this.state.appointmentTemplates,
      };
      this.setState(initialState);
    }

    handleErrors(errors) {
      this.setState({ errors });
    }

    handleActionClick() {
      this.setState((prevState) => ({ showForm: !prevState.showForm, helpRequested: false }));
    }

    editOrder(order) {
      let body;
      if (!order.dueAt) {
        const currentTime = this.props.currentTime.tz(this.props.timeZone);
        body = { dueAt: roundTimeUp(5, currentTime).format(), frequencyType: 'once' };
        this.setState({ dueAt: roundTimeUp(5, currentTime).format() });
      } else {
        body = { frequencyType: 'scheduled' };
      }

      this.setState({
        ...this.initialState,
        ...order,
        ...body,
        isEdit: true,
        showForm: true,
        selectedResources: order.services,
      });
    }

    filterResources() {
      return this.availableResources.map((r) => {
        const selectedItem = this.state.selectedResources.find((item) => item.id === r.id)
        return { ...r, selected: selectedItem ? true : false };
      });
    }

    deleteOrder(id) {
      let targetOrder;

      const orders = this.state.orders.map((order) => {
        if (order.id === id) {
          order.loading = true;
          targetOrder = order;
        }
        return order;
      });

      this.setState({ orders, id }, () => {
        const deleteApi = this.props.isTemplate ? deleteActivityTemplateApi : deleteActivityApi;

        deleteApi(this.props.parentId, id, targetOrder.type, targetOrder.frequencyType === 'recurring').then(
          (res) => {
            if (res.status === 200) {
              const orders = this.state.orders.filter((order) => order.id !== id);
              this.setState({ orders });
            }
          }
        );
      });
    }

    deleteRepeatedTask(id) {
      // deleteRepeatedTask will be extracted in the future
      // this is not a permanent solution
      let targetOrder;

      const repeatedTasks = this.state.repeatedTasks.map((order) => {
        if (order.id === id) {
          targetOrder = {
            ...order,
            loading: true,
            type: 'Task',
            frequencyType: 'recurring',
          };
        }
        return order;
      })

      this.setState({ repeatedTasks, id }, () => {
        const deleteApi = this.props.isTemplate ? deleteActivityTemplateApi : deleteActivityApi;

        deleteApi(this.props.parentId, id, targetOrder.type, targetOrder.frequencyType === 'recurring').then(
          (res) => {
            if (res.status === 200) {
              const repeatedTasks = this.state.repeatedTasks.filter((repeatedTask) => repeatedTask.id !== id);
              this.setState({ repeatedTasks });
            }
          }
        );
      });
    }

    deleteRepeatedAppointment(id) {
      // deleteRepeatedAppointment will be extracted in the future
      // this is not a permanent solution
      let targetOrder;

      const repeatedAppointments = this.state.repeatedAppointments.map((order) => {
        if (order.id === id) {
          targetOrder = {
            ...order,
            loading: true,
            type: 'Appointment',
            frequencyType: 'recurring',
          };
        }
        return order;
      })

      this.setState({ repeatedAppointments, id }, () => {
        const deleteApi = this.props.isTemplate ? deleteActivityTemplateApi : deleteActivityApi;

        deleteApi(this.props.parentId, id, targetOrder.type, targetOrder.frequencyType === 'recurring').then(
          (res) => {
            if (res.status === 200) {
              const repeatedAppointments = this.state.repeatedAppointments.filter(
                (repeatedAppointment) => repeatedAppointment.id !== id
              );
              this.setState({ repeatedAppointments });
            }
          }
        );
      });
    }

    handleOpenModal() {
      this.setState({ modalIsOpen: true });
    }

    handleCloseModal() {
      this.setState({ modalIsOpen: false });
    }

    handleSubmitHelpRequest(params) {
      this.setState({
        helpRequested: true,
        modalIsOpen: false,
        helpRequest: {
          ...params,
          phoneNumberRecipient: { number: params.number },
        },
      });
    }

    handleToggleLinkResourcesModal() {
      this.setState((prevState) => ({ showLinkResource: !prevState.showLinkResource }));
    }

    handleSelectResources(selected, callback) {
      const selectedResources = _.filter(this.availableResources, (resource) => selected[resource.id]);

      this.setState({ selectedResources }, callback);
    }

    handleRemoveResource(id) {
      this.setState((state) => ({
        selectedResources: _.filter(state.selectedResources, (resource) => resource.id !== id),
      }));
    }

    handleSelectActivityTemplate({ target: { name, value: templateId } }) {
      if (templateId < 0) {
        this.setState({ selectedActivityTemplateId: -1, ...this.initialState });
      } else {
        const { address, services, dueAt, frequencyType, id, type, ...rest } = this.props[name].find(
          (t) => t.id === Number(templateId)
        );
        if (type === 'LibraryAppointmentTemplate' && frequencyType !== 'once') {
          this.setState({ type: 'Appointment' });
        }

        this.setState({
          selectedActivityTemplateId: templateId,
          frequencyType,
          selectedResources: services,
          dueAt: dueAt ? dueAt : roundTimeUp(5, this.props.currentTime.tz(this.props.timeZone)).format(),
          address: address ? address : this.defaultAddress,
          ...rest,
        });
      }
    }

    render() {
      const wrappedProps = _.assign({}, this.props, this.state);

      return (
        <WrappedComponent
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          handleSuccess={this.handleSuccess}
          handleErrors={this.handleErrors}
          handleActionClick={this.handleActionClick}
          onSubmitHelpRequest={this.handleSubmitHelpRequest}
          onToggleLinkResources={this.handleToggleLinkResourcesModal}
          onRemoveResource={this.handleRemoveResource}
          onSelectResources={this.handleSelectResources}
          onOpenModal={this.handleOpenModal}
          onCloseModal={this.handleCloseModal}
          deleteOrder={this.deleteOrder}
          deleteRepeatedTask={this.deleteRepeatedTask}
          deleteRepeatedAppointment={this.deleteRepeatedAppointment}
          onSelectActivityTemplate={this.handleSelectActivityTemplate}
          editOrder={this.editOrder}
          resetFormState={this.resetFormState}
          {...wrappedProps}
        />
      );
    }
  }

  const resourceGroupShape = PropTypes.shape({
    id: PropTypes.number.isRequired,
    kind: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  });

  OrderCreator.propTypes = {
    adHocResources: PropTypes.arrayOf(resourceGroupShape),
    appointmentTemplates: PropTypes.array,
    patientInfo: PropTypes.shape({
      addressTo: PropTypes.shape({
        address: PropTypes.string,
        city: PropTypes.string,
        region: PropTypes.string,
        postalCode: PropTypes.string,
        countryCode: PropTypes.string,
      }),
      phoneNumber: PropTypes.shape({ number: PropTypes.string }),
    }),
    repeatedAppointments: PropTypes.array,
    repeatedTasks: PropTypes.array,
    resources: PropTypes.shape({
      information: PropTypes.arrayOf(resourceGroupShape),
      service: PropTypes.arrayOf(resourceGroupShape),
      practitioner: PropTypes.arrayOf(resourceGroupShape),
      people: PropTypes.arrayOf(resourceGroupShape),
      other: PropTypes.arrayOf(resourceGroupShape),
    }).isRequired,
    taskTemplates: PropTypes.array,
    volunteersEnabled: PropTypes.bool,
  }
  OrderCreator.defaultProps = {
    adHocResources: [],
    appointmentTemplates: [],
    currentTime: moment(),
    patientInfo: {
      addressTo: {
        address: '',
        city: '',
        region: '',
        postalCode: '',
        countryCode: '',
      },
      phoneNumber: { number: '' },
    },
    repeatedAppointments: [],
    repeatedTasks: [],
    resources: {
      information: [],
      service: [],
      practitioner: [],
      people: [],
      other: [],
    },
    taskTemplates: [],
    volunteersEnabled: false,
  };

  OrderCreator.displayName = `OrderCreator(${getDisplayName(WrappedComponent)})`;
  return OrderCreator;
}

export default orderCreator;
