import React from 'react';
import PropTypes from 'prop-types';

const languageNames = {
  en: 'English',
  fr: 'Français',
  es: 'Español',
  ko: '한국',
  sv: 'Svenska',
};

const Locale = ({ locale, tabIndex }) => <span tabIndex={tabIndex}>{languageNames[locale]}</span>;

Locale.propTypes = {
  locale: PropTypes.string.isRequired,
  tabIndex: PropTypes.string,
};

Locale.defaultProps = {
  tabIndex: '-1',
};

export default Locale;
