import getTemplatesApi from './getTemplatesApi';

export const handleTemplatesSetupWrapper = (getTemplatesApiProvider = getTemplatesApi) => {
  return async (
    setActivityModalTemplatesDropdownState,
    setActivityModalTemplatesDropdownTemplates,
    setActivityModalTemplatesDropdownTemplatesToDisplay,
    carePlanId,
    activityType
  ) => {
    setActivityModalTemplatesDropdownState('LOADING');

    try {
      const { responseType, templates } = await getTemplatesApiProvider(carePlanId, activityType);

      if (responseType === 'DATA') {
        setActivityModalTemplatesDropdownTemplates(templates);
        setActivityModalTemplatesDropdownTemplatesToDisplay(templates);
        setActivityModalTemplatesDropdownState('DEFAULT');
      } else {
        throw 'Missing data';
      }
    } catch (error) {
      setActivityModalTemplatesDropdownState('ERROR');
      console.error(error);
    }
  };
};

export default handleTemplatesSetupWrapper(getTemplatesApi);
