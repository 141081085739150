import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import CarePlan from './CarePlan'
import Contact from 'Components/Inbox/SideNav/NewContacts/Contact'
import Button from 'Components/Base/Button'
import Checkbox from 'Components/Forms/Checkbox'

const i18nOpts = { scope: 'inbox.side_nav.channels' }
const selectAllText = (count = '') => t('select_all', { scope: 'inbox.side_nav.channels', count })

const ChannelMembers = ({ contacts, onClick, onToggle, selectedContacts, title, selectAll, selected }) => (
  <div className="channel">
    <CarePlan title={title} />

    <hr />

    <p className="channel__info">{t('info', i18nOpts)}</p>

    <div className="channel__contacts">
      <div className="channel__select_all">
        <Checkbox
          checked={selected}
          name={'select_all'}
          text={selectAllText(selectedContacts.length)}
          onChange={selectAll.bind(null, selected)}
          selected
        />
      </div>
      <section className="inbox-menu__contacts">
        <div className="inbox-menu__contacts-scroll">
          {contacts.map(({ id, isSelected, ...rest }) => (
            <Contact
              key={id}
              isSelected={isSelected}
              onClick={onToggle.bind(null, { id, ...rest })}
              {...rest}
            />
          ))}
        </div>
      </section>
    </div>

    <Button
      className="btn channel__btn"
      onClick={onClick}
      disabled={!selectedContacts.length}
      text={t('next', i18nOpts)}
    />
  </div>
)

ChannelMembers.propTypes = {
  contacts: PropTypes.array,
  onClick: PropTypes.func,
  onToggle: PropTypes.func.isRequired,
  selectAll: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  selectedContacts: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
}

ChannelMembers.defaultProps = {
  contacts: [],
}

export default ChannelMembers
