import Api from '../Api'

class Address extends Api {
  constructor(carePlanUuid, id = null) {
    super()

    this.carePlanUuid = carePlanUuid
    this.id = id
  }

  create({
    label,
    address,
    extendedAddress: extended_address,
    city,
    region,
    postalCode: postal_code,
    countryCode: country_code,
  }) {
    return super.create({
      address: {
        label,
        address,
        extended_address,
        city,
        region,
        postal_code,
        country_code,
      },
    })
  }

  update({
    label,
    address,
    extendedAddress: extended_address,
    city,
    region,
    postalCode: postal_code,
    countryCode: country_code,
  }) {
    return super.update({
      address: {
        label,
        address,
        extended_address,
        city,
        region,
        postal_code,
        country_code,
      },
    })
  }

  get url() {
    return `/care_plans/${this.carePlanUuid}/patient/addresses`
  }
}

export default Address
