import { Resource } from 'Api/Api'

class HealthTeamContact extends Resource {
  constructor(carePlanUuid) {
    super()

    this.carePlanUuid = carePlanUuid
  }

  update(healthTeamContacts) {
    return super.update({
      carePlan: {
        healthTeamContacts,
      },
    })
  }

  get url() {
    return `/care_plans/${this.carePlanUuid}/health_team_contacts`
  }
}

export default HealthTeamContact
