import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'i18n';

import AvatarUpload from './avatarUpload';
import ActionButton from 'Components/Base/ActionButton';
import ApiErrorMessage from '../ApiErrorMessage';

import { trackUserPhotoUploaded, trackUserPhotoSkipped } from 'Mixpanel';

import {
  setCurrentStep,
  setAvatar,
  setIsLoading,
  setApiErrorMessage,
  goToNextStep,
} from 'Actions/signupPage';

import setupUserValidator from '../../setupUserValidator';
import getFormHeaderClass from '../getFormHeaderClass';

const i18nOpts = { scope: 'registration.avatar' };

const AvatarForm = ({
  currentStep,
  file,
  index,
  setAvatar,
  isLoading,
  apiErrorMessage,
  user,
  registrationUrl,
  setApiErrorMessage,
  setIsLoading,
  goToNextStep,
}) => {
  const [skipAvatar, setSkipAvatar] = useState(false);
  const handleRemoveAvatar = () => setAvatar(null);

  const enableSubmit = file || skipAvatar;
  const isOpen = currentStep === index;

  const validateUser = setupUserValidator(
    setApiErrorMessage,
    setIsLoading,
    goToNextStep,
    trackUserPhotoUploaded
  );

  useEffect(() => {
    if (skipAvatar) {
      trackUserPhotoSkipped();
    }
  }, [skipAvatar]);

  return (
    <>
      <div className={getFormHeaderClass(false, isOpen)}>
        <div className="header-title">
          {t('upload', i18nOpts)}
          <span className="header-title--optional">{t('optional', i18nOpts)}</span>
        </div>

        {isOpen && <div className="header-subtitle">{t('subtitle', i18nOpts)}</div>}
      </div>

      {isOpen && (
        <>
          <div className="form__body">
            <AvatarUpload onSetAvatar={setAvatar} onRemoveAvatar={handleRemoveAvatar} file={file} />
            <div className="registrationV2__form_body__api_error">
              <ApiErrorMessage apiErrorMessage={apiErrorMessage} />
            </div>
          </div>
          <div className="form__footer">
            <ActionButton
              isLoading={isLoading}
              disabled={isLoading}
              className={
                enableSubmit ? 'form__footer__submit' : 'sform__footer__submit button-subtle'
              }
              onClick={() => {
                enableSubmit ? validateUser(user, registrationUrl) : setSkipAvatar(!skipAvatar);
              }}
              content={enableSubmit ? t('finish', i18nOpts) : t('skip', i18nOpts)}
            />
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ signupPage: { currentStep, user, file, isLoading, apiErrorMessage } }) => ({
  currentStep,
  user,
  file,
  isLoading,
  apiErrorMessage,
});

const mapDispatchToProps = {
  setCurrentStep: (index) => setCurrentStep(index),
  setAvatar: (file) => setAvatar(file),
  setIsLoading: (isLoading) => setIsLoading(isLoading),
  setApiErrorMessage: (apiErrorMessage) => setApiErrorMessage(apiErrorMessage),
  goToNextStep,
};

AvatarForm.propTypes = {
  currentStep: PropTypes.number.isRequired,
  file: PropTypes.object,
  index: PropTypes.number.isRequired,
  setAvatar: PropTypes.func.isRequired,
};

AvatarForm.defaultProps = {
  file: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(AvatarForm);
