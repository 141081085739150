import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from 'Components/Base'

const PainScaleOption = ({ modalSelectedOptionValue, onClick, optionData }) => {
  const isSelected = String(optionData.id) === modalSelectedOptionValue
  const styles = {
    color: isSelected ? '#fff' : optionData.data.colorCode,
    background: isSelected ? optionData.data.colorCode : '#fff',
  }

  return (
    <a onClick={onClick} value={optionData.id}>
      <div className="ReactModal__CheckIn--QuestionOption" style={styles}>
        <Icon name={optionData.data.iconName || ''} className="checkin-icon" />
        <div className="ReactModal__CheckIn--QuestionOption-text">
          <span>
            <b className={isSelected ? 'text-white' : 'text-brand'}>{optionData.data.text}</b>
          </span>
          <span className={isSelected ? 'text-white' : 'text-brand'}>{optionData.data.description}</span>
        </div>
      </div>
    </a>
  )
}

PainScaleOption.propTypes = {
  modalSelectedOptionValue: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  optionData: PropTypes.object.isRequired,
}

PainScaleOption.defaultProps = {
  modalSelectedOptionValue: '',
}

export default PainScaleOption
