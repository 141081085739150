import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

const i18nOpts = { scope: 'dashboard.chart' };

import { setIsTogglingDays, setRenderedDays } from 'Actions/dashboardComparisonChart';

const ResetButton = ({ isTogglingDays, setIsTogglingDays, setRenderedDays }) => {
  if (!isTogglingDays) return null;

  const handleResetDays = () => {
    setIsTogglingDays(false);
    setRenderedDays({
      mon: true,
      tue: true,
      wed: true,
      thu: true,
      fri: true,
      sat: true,
      sun: true,
    });
  };

  return (
    <p className="comparison-chart__reset-button" onClick={handleResetDays}>
      {t('reset', i18nOpts)}
    </p>
  );
};

const mapStateToProps = ({ dashboardComparisonChart: { isTogglingDays } }) => {
  return { isTogglingDays };
};

const mapDispatchToProps = (dispatch) => ({
  setIsTogglingDays: (isTogglingDays) => dispatch(setIsTogglingDays(isTogglingDays)),
  setRenderedDays: (renderedDays) => dispatch(setRenderedDays(renderedDays)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetButton);
