import React from 'react'
import PropTypes from 'prop-types'

import { Icon, Loader, Tooltip } from 'Components/Base'

import { t } from 'i18n'

const Aside = ({ onClick, header, isFetching, isOpen, render }) => (
  <aside className={`inbox-menu inbox-menu--new ${isOpen && 'inbox-menu--open'}`}>
    <div className="inbox-menu__new-message-header">
      <div className="inbox-menu__new-message-header-icon" onClick={onClick}>
        <Tooltip
          className="inbox-menu__new-message-header-icon-tooltip"
          text={t('back', { scope: 'inbox.side_nav.care_plans' })}
          position="right"
          tabIndex="0"
        >
          <Icon name="arrow-left" />
        </Tooltip>
      </div>
      <span>{header}</span>
    </div>

    {isFetching ? <Loader /> : render()}
  </aside>
)

Aside.propTypes = {
  header: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  render: PropTypes.func.isRequired,
}

export default Aside
