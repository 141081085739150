import React from 'react'
import PropTypes from 'prop-types'

import { ItemPill, ItemPillText, ItemPillActions, ItemPillActionIcon } from 'Components/Base/ItemPill'

const ResourceListItem = ({ id, name, isProcessing, isLinking, editResource, deleteResource }) => (
  <ItemPill selected>
    <ItemPillText text={name} data-test="item-list-option-name" />

    <ItemPillActions isLoading={isProcessing}>
      {!isLinking && (
        <ItemPillActionIcon
          iconName="file-edit"
          onClick={editResource.bind(null, id)}
          data-test="edit-button"
        />
      )}
      {deleteResource && (
        <ItemPillActionIcon
          iconName="minus-circle"
          onClick={deleteResource.bind(null, id)}
          data-test="delete-button"
        />
      )}
    </ItemPillActions>
  </ItemPill>
)

ResourceListItem.propTypes = {
  deleteResource: PropTypes.func,
  editResource: PropTypes.func,
  id: PropTypes.number.isRequired,
  isLinking: PropTypes.bool,
  isProcessing: PropTypes.bool,
  name: PropTypes.string.isRequired,
}

ResourceListItem.defaultProps = {
  deleteResource: null,
  editResource: null,
  isLinking: false,
  isProcessing: false,
}

export default ResourceListItem
