import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'Components/Base'
import { t } from 'i18n'

import Chip from '../Base/Chip'

const i18nOpts = { scope: 'activity_feed.assignments' }

const DISPLAY_NUMBER = 4

const Assignments = ({ assignments, minimalistic }) => {
  let output = ''
  const assignmentsLength = assignments.length

  if (!assignmentsLength) {
    output = !minimalistic && <Chip color="accent-darker" text={t('open', i18nOpts)} />
  } else {
    const displayAssignments = assignments.slice(0, DISPLAY_NUMBER)
    const remainingAssignments = assignmentsLength - displayAssignments.length

    output = (
      <Fragment>
        {displayAssignments.map((a) => (
          <Tooltip key={a.id} className="activity-assignment" text={t(a.status, i18nOpts)} tabIndex="0">
            <div className={`activity-assignment__body badge badge--status badge--${a.status}`}>
              <img src={a.photo} className="activity-assignment__avatar" />
            </div>
          </Tooltip>
        ))}
        {remainingAssignments > 0 && (
          <div className="activity-assignment">
            <div className="activity-assignment__body avatar-number avatar-number--hidden">
              + {remainingAssignments}
            </div>
          </div>
        )}
      </Fragment>
    )
  }

  return (
    <div className="activity__assignments">
      {!minimalistic && <span className="activity__assignments-label">{t('assigned_to', i18nOpts)}</span>}
      {output}
    </div>
  )
}

Assignments.propTypes = {
  assignments: PropTypes.array,
  minimalistic: PropTypes.bool,
}

Assignments.defaultProps = {
  assignments: [],
  minimalistic: false,
}

export default Assignments
