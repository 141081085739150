import React from 'react'
import PropTypes from 'prop-types'

import { Icon, Tooltip } from 'Components/Base'

import { t } from 'i18n'

const ItemPillActionIcon = ({ iconName, onClick, ...rest }) => (
  <div className="icon item-pill__action" onClick={onClick} {...rest}>
    <Tooltip text={t(iconName, { scope: 'care_plan_creators.pill_actions' })} tabIndex="0">
      <Icon className="item-pill__action-icon" name={iconName} />
    </Tooltip>
  </div>
)

ItemPillActionIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

ItemPillActionIcon.defaultProps = {}

export default ItemPillActionIcon
