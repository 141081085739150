import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Label from './Label';
import Icon from '../Base/Icon';
import InputError from './InputError';

const Checkbox = ({ checked, name, text, errors, onChange, large }) => {
  const handleKeyDown = (evt) => {
    // trigger onChange when Space is pressed
    if (evt.keyCode === 32) {
      evt.preventDefault();
      onChange();
    }
    return;
  };

  const classes = classNames({
    input: true,
    'input--checkbox': true,
    'input--errors': errors[name],
    'input--checkbox-large': large,
  });

  const iconName = classNames({
    'box-checked': !large && checked,
    'box-empty': !large && !checked,
    'checkbox-selected': large && checked,
    'checkbox-unselected': large && !checked,
  });

  return (
    <div className={classes} onClick={onChange}>
      <div
        tabIndex={0}
        className="icon-wrapper"
        onKeyDown={handleKeyDown}
        data-test="checkbox-icon-wrapper"
        aria-checked={checked}
        role="checkbox"
      >
        <Icon name={iconName} className={iconName} />
      </div>
      <div className="label">
        <Label text={text} inputName={name} />
        {errors[name] && <InputError error={errors[name]} />}
      </div>
    </div>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  errors: PropTypes.object,
  large: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string,
};

Checkbox.defaultProps = {
  checked: false,
  errors: {},
  large: false,
  text: '',
};

export default Checkbox;
