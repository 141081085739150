import getFormsApi from './getFormsApi';

export const handleFormsSetupWrapper = (getFormsApiProvider = getFormsApi) => {
  return async (setFormsCardState, setFormsCardFormsList, carePlanId) => {
    setFormsCardState('LOADING');

    try {
      const { responseType, forms, repeatedForms } = await getFormsApiProvider(carePlanId);

      if (responseType === 'DATA') {
        setFormsCardFormsList({ forms, repeatedForms });
        setFormsCardState('MAIN_CONTENT');
      } else {
        throw 'Could not get forms.';
      }
    } catch (error) {
      setFormsCardState('ERROR');
      console.error(error);
    }
  };
};

export default handleFormsSetupWrapper(getFormsApi);
