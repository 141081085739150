import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ModalFooter } from 'Components/Base';
import ActionButton from 'Components/Base/ActionButton';

import {
  setIsTargetSignupModalOpen,
  setTargetSignupModalStatus,
  setTargetSignupModalApiError,
  setSignups,
  setPageState,
  setFilteredSignups,
  setCurrentPageNumber,
} from 'Actions/carePlanPlaceInvitationsPage';
import setupUpdateSignup from './setupUpdateSignup';
import { leftButtonContent, rightButtonContent } from './buttonContent';
import { leftButtonAction, rightButtonAction } from './buttonActions';

const ActionButtonsSection = ({
  setIsTargetSignupModalOpen,
  setTargetSignupModalStatus,
  setTargetSignupModalApiError,
  setSignups,
  setPageState,
  targetSignupModal,
  setFilteredSignups,
  setCurrentPageNumber,
  search,
  filterStatus,
}) => {
  const { target, status } = targetSignupModal;

  const updateSignup = setupUpdateSignup(
    setIsTargetSignupModalOpen,
    setTargetSignupModalStatus,
    setTargetSignupModalApiError,
    setSignups,
    setPageState,
    setFilteredSignups,
    setCurrentPageNumber,
    search,
    filterStatus
  );

  const isLoading = status === 'loading';
  const disabled = status === 'apiSuccess';

  return (
    <ModalFooter
      renderRight={() => (
        <>
          {(target.status !== 'accepted' && target.entityType !== 'Patient') && (
            <ActionButton
              className="button-subtle"
              isLoading={isLoading}
              disabled={disabled}
              onClick={leftButtonAction(updateSignup, target)}
              content={leftButtonContent(target)}
            />
          )}
          <ActionButton
            isLoading={isLoading}
            disabled={disabled}
            onClick={rightButtonAction(setIsTargetSignupModalOpen, updateSignup, target)}
            content={rightButtonContent(target)}
          />
        </>
      )}
    />
  );
};

const mapStateToProps = ({ carePlanPlaceInvitationsPage }) => {
  const {
    targetSignupModal,
    signups,
    currentSearchInput,
    filterByStatusDropdown: { input },
  } = carePlanPlaceInvitationsPage;

  return {
    targetSignupModal,
    signups,
    search: currentSearchInput,
    filterStatus: input,
  };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetIsTargetSignupModalOpen = (dispatch) => {
    return (isOpen) => {
      dispatch(setIsTargetSignupModalOpen(isOpen));
    };
  };

  const dispatchSetTargetSignupModalStatus = (dispatch) => {
    return (status) => {
      dispatch(setTargetSignupModalStatus(status));
    };
  };

  const dispatchSetTargetSignupModalApiError = (dispatch) => {
    return (apiError) => {
      dispatch(setTargetSignupModalApiError(apiError));
    };
  };

  const dispatchSetSignups = (dispatch) => {
    return (signups) => {
      dispatch(setSignups(signups));
    };
  };

  const dispatchSetPageState = (dispatch) => {
    return (pageState) => {
      dispatch(setPageState(pageState));
    };
  };

  const dispatchSetFilteredSignups = (dispatch) => {
    return (signups) => {
      dispatch(setFilteredSignups(signups));
    };
  };

  const dispatchSetCurrentPageNumber = (dispatch) => {
    return (pageNumber) => {
      dispatch(setCurrentPageNumber(pageNumber));
    };
  };

  return {
    setIsTargetSignupModalOpen: dispatchSetIsTargetSignupModalOpen(dispatch),
    setTargetSignupModalStatus: dispatchSetTargetSignupModalStatus(dispatch),
    setTargetSignupModalApiError: dispatchSetTargetSignupModalApiError(dispatch),
    setSignups: dispatchSetSignups(dispatch),
    setPageState: dispatchSetPageState(dispatch),
    setFilteredSignups: dispatchSetFilteredSignups(dispatch),
    setCurrentPageNumber: dispatchSetCurrentPageNumber(dispatch),
  };
};

ActionButtonsSection.propTypes = {
  filterStatus: PropTypes.string,
  search: PropTypes.string,
  setCurrentPageNumber: PropTypes.func,
  setFilteredSignups: PropTypes.func,
  setIsTargetSignupModalOpen: PropTypes.func,
  setTargetSignupModalApiError: PropTypes.func,
  setTargetSignupModalStatus: PropTypes.func,
  setSignups: PropTypes.func,
  setPageState: PropTypes.func,
  tab: PropTypes.string,
  targetSignupModal: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionButtonsSection);
