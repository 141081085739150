import {
  SET_IS_EDITING,
  SET_DOSE_VALUES,
  SET_INPUT_ERRORS,
  SET_CREATED_BY_USER,
  SET_UPDATED_AT,
  SET_SELECTED_HISTORICAL_TAB,
} from 'Actions/dashboardInsulinDosing';

const initialState = {
  isEditing: false,
  inputErrors: null,
  selectedHistoricalTab: '7days',
  createdByUser: '',
  updatedAt: '',
  doseValues: {
    longInsulinType: '',
    rapidInsulinType: '',
    breakfastLong: '0.0',
    breakfastICR: '0.0',
    breakfastISF: '0.0',
    lunchLong: '0.0',
    lunchICR: '0.0',
    lunchISF: '0.0',
    dinnerLong: '0.0',
    dinnerICR: '0.0',
    dinnerISF: '0.0',
    bedtimeLong: '0.0',
    bedtimeICR: '0.0',
    bedtimeISF: '0.0',
  },
};

const dashboardInsulinDosing = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_EDITING:
      return {
        ...state,
        isEditing: action.isEditing,
      };
    case SET_DOSE_VALUES:
      return {
        ...state,
        doseValues: action.doseValues,
      };
    case SET_INPUT_ERRORS:
      return {
        ...state,
        inputErrors: action.inputErrors,
      };
    case SET_SELECTED_HISTORICAL_TAB:
      return {
        ...state,
        selectedHistoricalTab: action.selectedHistoricalTab,
      };
    case SET_CREATED_BY_USER:
      return {
        ...state,
        createdByUser: action.createdByUser,
      };
    case SET_UPDATED_AT:
      return {
        ...state,
        updatedAt: action.updatedAt,
      };
    default:
      return state;
  }
};

export default dashboardInsulinDosing;
