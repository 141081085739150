import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';
import moment from 'ct-moment';

import { roundTimeUp } from 'Utilities/time';

import {
  setActivityModalActivityProperties,
  setActivityModalShowScheduledFields,
  setActivityModalShowRepeatsFields,
} from 'Actions/activityModal';

import { SwitchToggle } from 'Components/Base';
import Scheduled from './Scheduled';

const i18nOpts = { scope: 'activity_modal.form_fields.when_should_be_completed' };

export const WhenShouldBeCompleted = ({
  activityProperties,
  showScheduledFields,
  setActivityModalActivityProperties,
  setActivityModalShowScheduledFields,
  setActivityModalShowRepeatsFields,
  timeZone
}) => {
  const fieldLabel = t('task_label', i18nOpts);

  return (
    <>
      <div className="activity_modal__input_field__label">{fieldLabel}</div>

      <div className="activity_modal__input_field__container">
        <SwitchToggle
          leftOptionName={t('any_time', i18nOpts)}
          rightOptionName={t('scheduled', i18nOpts)}
          className="when_toggle"
          onChange={() => {
            if (showScheduledFields) {
              // clear and hide all Repeats fields if we are hiding Scheduled fields
              setActivityModalShowRepeatsFields(false);
              setActivityModalActivityProperties({
                ...activityProperties,
                count: null,
                dueAt: null,
                endRepeat: null,
                frequency: null,
                frequencyType: 'once',
                interval: null,
                until: null,
                formattedTime: null,
                localizedFrequency: null,
              });
            } else {
              setActivityModalActivityProperties({
                ...activityProperties,
                frequencyType: 'scheduled',
                dueAt: roundTimeUp(5, moment()).tz(timeZone).format(),
              });
            }
            setActivityModalShowScheduledFields(!showScheduledFields);
          }}
          toggledRight={showScheduledFields}
        />

        {showScheduledFields && <Scheduled />}
      </div>
    </>
  );
};

const mapStateToProps = ({ activityModal }) => {
  const { activityProperties, showScheduledFields, timeZone } = activityModal;

  return { activityProperties, showScheduledFields, timeZone };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalActivityProperties = (dispatch) => {
    return (activityProperties) => {
      dispatch(setActivityModalActivityProperties(activityProperties));
    };
  };

  const dispatchSetActivityModalShowScheduledFields = (dispatch) => {
    return (showScheduledFields) => {
      dispatch(setActivityModalShowScheduledFields(showScheduledFields));
    };
  };

  const dispatchSetActivityModalShowRepeatsFields = (dispatch) => {
    return (showRepeatsFields) => {
      dispatch(setActivityModalShowRepeatsFields(showRepeatsFields));
    };
  };

  return {
    setActivityModalActivityProperties: dispatchSetActivityModalActivityProperties(dispatch),
    setActivityModalShowScheduledFields: dispatchSetActivityModalShowScheduledFields(dispatch),
    setActivityModalShowRepeatsFields: dispatchSetActivityModalShowRepeatsFields(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WhenShouldBeCompleted);
