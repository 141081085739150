export const getTranslatedHoursWrapper = () => {
  return ({ slept, awoke, hours_slept }) => {
    if ((slept == null && awoke == null) || [slept, awoke].includes('')) {
      return false;
    }

    let [startHour, startMin] = slept.split(':').map((e) => Number(e));

    let [endHour, endMin] = awoke.split(':').map((e) => Number(e));

    // If someone is on night schedule (sleeps in AM, wakes up in PM)
    // then this hack is needed to get the correct value for endHour so it will display properly
    if (startHour < 12 && endHour > 12) {
      endHour = endHour - 24;
    }

    // Need to flip the startHour b/c the chart scale goes from noon->(pm)->midnight->(am)->noon (inverse of experienced time)
    startHour = startHour >= 12 ? startHour - 12 : startHour + 12;

    return {
      sleptAt: { startHour, startMin: startMin / 60 },
      awokeAt: { endHour, endMin: endMin / 60 },
      hoursSlept: Number(hours_slept),
    };
  };
};

export default getTranslatedHoursWrapper();
