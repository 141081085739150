const getDayLabels = (code, t, i18nOpts) => {
  switch (code) {
    case 'sun':
      return {
        desktop: t('sunday', i18nOpts),
        mobile: t('sunday_abbreviated', i18nOpts),
      };
    case 'mon':
      return {
        desktop: t('monday', i18nOpts),
        mobile: t('monday_abbreviated', i18nOpts),
      };
    case 'tue':
      return {
        desktop: t('tuesday', i18nOpts),
        mobile: t('tuesday_abbreviated', i18nOpts),
      };
    case 'wed':
      return {
        desktop: t('wednesday', i18nOpts),
        mobile: t('wednesday_abbreviated', i18nOpts),
      };
    case 'thu':
      return {
        desktop: t('thursday', i18nOpts),
        mobile: t('thursday_abbreviated', i18nOpts),
      };
    case 'fri':
      return {
        desktop: t('friday', i18nOpts),
        mobile: t('friday_abbreviated', i18nOpts),
      };
    case 'sat':
      return {
        desktop: t('saturday', i18nOpts),
        mobile: t('saturday_abbreviated', i18nOpts),
      };
    default:
      return { desktop: '', mobile: '' };
  }
};

export default getDayLabels;
