import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';

import TextGroup from './TextGroup';

import Icon from 'Components/Base/Icon';
const i18nOpts = { scope: 'care_plan_place_invitations.index' };

const Details = ({ placeName, entityType, carePlanTitle, patientName, submitted, resolvedAt }) => {
  switch (entityType) {
    case 'CarePlanPlaceInvitation':
      return (
        <div className="invitation__hospital-details">
          <Icon name="hospital" className="invitation__hospital-icon text-grey" />
          <div>
            <h3 className="invitation__title">{placeName}</h3>
            <div className="invitation__text text-normal">
              {t('sent_at', { ...i18nOpts, sent_at: submitted })}
            </div>
          </div>
        </div>
      );
    case 'AccessRequest':
      return (
        <div className="invitation__caregiver-details">
          <TextGroup text={patientName} subtext={carePlanTitle}/>
          <TextGroup text={submitted} />
          <TextGroup text={resolvedAt} />
        </div>
      );
    case 'Patient':
      return (
        <div className="invitation__patient-details">
          <TextGroup text={submitted} />
          <TextGroup text={resolvedAt} />
        </div>
      );
    default:
      return null;
  }
};

Details.propTypes = {
  carePlanTitle: PropTypes.string,
  entityType: PropTypes.string.isRequired,
  patientName: PropTypes.string.isRequired,
  placeName: PropTypes.string,
  resolvedAt: PropTypes.string,
  submitted: PropTypes.string,
};

Details.defaultProps = {
  carePlanTitle: '',
  placeName: '',
  resolvedAt: '',
  submitted: '',
};

export default Details;
