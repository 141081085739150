import React from 'react';
import PatientsSetupWrapper from './PatientsSetupWrapper';
import ProviderWrapper from 'Components/Base/ProviderWrapper';

const PatientsWrapper = () => {
  return (
    <ProviderWrapper>
      <PatientsSetupWrapper />
    </ProviderWrapper>
  );
};

export default PatientsWrapper;
