import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import InputGroup from 'Components/Forms/InputGroup';
import { Button } from 'Components/Base';
import Submit from 'Components/Forms/Submit';
import { t } from 'i18n';
import { capitalize } from 'lodash';
import { Footer } from 'Components/Base/Card/';

const i18nOpts = { scope: 'tags.form' };

const TagForm = ({ tag: { id, text, status }, onChange, errors, onSubmit, onRedirect, onDestroy }) => (
  <form className="form" onSubmit={onSubmit} noValidate>
    <div className="card__header">
      <h3>{id ? t('edit', i18nOpts) : t('add', i18nOpts)}</h3>
      {id && (
        <Button
          className="btn btn--secondary"
          onClick={onDestroy}
          ct-delete-confirm={t('edit_confirm', i18nOpts)}
        >
          <span className="btn__text text-red">{t('delete', i18nOpts)}</span>
        </Button>
      )}
    </div>
    <div className="form__row">
      <InputGroup
        name="text"
        label={t('name', i18nOpts)}
        component="input"
        value={text}
        onChange={onChange}
        errors={errors}
        required
      />
    </div>
    <div className="form__row">
      <InputGroup
        component="select"
        name="status"
        label={t('status', i18nOpts)}
        onChange={onChange}
        value={status}
        help={t('edit_status_help', i18nOpts)}
        required
      >
        {t('statuses', i18nOpts).map((status, index) => (
          <option key={index} value={status}>
            {capitalize(status)}
          </option>
        ))}
      </InputGroup>
    </div>

    <Footer
      right={
        <Fragment>
          <Button className="btn btn--secondary" text={t('cancel', i18nOpts)} onClick={onRedirect} />
          <Submit value={t('submit', i18nOpts)} name="submit" />
        </Fragment>
      }
    />
  </form>
);

TagForm.propTypes = {
  errors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onDestroy: PropTypes.func.isRequired,
  onRedirect: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  tag: PropTypes.object.isRequired,
};

TagForm.defaultProps = { errors: {} };

export default TagForm;
