import fetchSignupsApi from 'Apiv2/fetchSignupsApi';

export const setupSignupsFetcherWrapper = (getSignupsApiProvider = fetchSignupsApi) => {
  return (setSignups, setPageState, setApiError) => {
    return async () => {
      setPageState('loading');

      try {
        const { status, data } = await getSignupsApiProvider();
        if (status === 200 && data && data.signups) {
          const signups = data.signups;
          setSignups(signups);
          setPageState('success');

          // returning the list to do initial filter on first load :(
          return signups;
        } else {
          throw 'Missing data';
        }
      } catch (error) {
        setPageState('apiError');

        if (typeof error === 'string') {
          setApiError(error);
        } else if (
          typeof error === 'object' &&
          error !== null && // null is an object in JS
          typeof error.error === 'string'
        ) {
          setApiError(error.error);
        }
      }
    };
  };
};

export default setupSignupsFetcherWrapper(fetchSignupsApi);
