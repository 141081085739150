import React from 'react'
import PropTypes from 'prop-types'

import {
  ItemPill,
  ItemPillIcon,
  ItemPillActions,
  ItemPillActionIcon,
  ItemPillActionLink,
  ItemPillText,
} from 'Components/Base/ItemPill'

const Document = ({
  displayFileName,
  document: { document_file_name, name },
  deleteDocument,
  editDocument,
  isProcessing,
  previewUrl,
}) => (
  <ItemPill selected>
    <ItemPillIcon iconName="file" />

    <ItemPillText text={displayFileName ? document_file_name : name} data-test="display-name" />

    <ItemPillActions isLoading={isProcessing}>
      {previewUrl && (
        <ItemPillActionLink
          link={previewUrl}
          iconName="external-link"
          data-test="preview-url"
          target="_blank"
        />
      )}

      {editDocument && (
        <ItemPillActionIcon iconName="file-edit" onClick={editDocument} data-test="edit-icon" />
      )}

      {deleteDocument && (
        <ItemPillActionIcon iconName="minus-circle" onClick={deleteDocument} data-test="delete-icon" />
      )}
    </ItemPillActions>
  </ItemPill>
)

Document.propTypes = {
  deleteDocument: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  displayFileName: PropTypes.bool,
  document: PropTypes.object.isRequired,
  editDocument: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  isProcessing: PropTypes.bool,
  previewUrl: PropTypes.string,
}

Document.defaultProps = {
  deleteDocument: false,
  displayFileName: false,
  editDocument: false,
  isProcessing: false,
  previewUrl: '',
}

export default Document
