import React, { Component } from 'react'
import PropTypes from 'prop-types'

class FreeTextQuestion extends Component {
  constructor(props) {
    super(props)

    this.state = {
      text: '',
    }
  }

  componentDidMount() {
    if (!this.props.checkInQuestionOptions.length) {
      this.props.onChange({ target: { name: 'checkInQuestionOptions', value: this.defaultOptions } })
    }
  }

  get defaultOptions() {
    return [
      {
        type: 'TextBasedOption',
        data: {
          order: 0,
          text: '',
        },
      },
    ]
  }

  render() {
    return null
  }
}

FreeTextQuestion.propTypes = {
  checkInQuestionOptions: PropTypes.array,
  onChange: PropTypes.func,
}

FreeTextQuestion.defaultProps = {
  checkInQuestionOptions: [],
  onChange: null,
}

export default FreeTextQuestion
