import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ItemPill = ({ children, flat, selected, stretch, large, isolated, multiline, borderless }) => {
  const className = classNames({
    'item-pill': true,
    'item-pill--selected': selected,
    'item-pill--stretch': stretch,
    'item-pill--flat': flat,
    'item-pill--large': large,
    'item-pill--isolated': isolated,
    'item-pill--multiline': multiline,
    'item-pill--borderless': borderless,
  });

  return <div className={className}>{children}</div>;
};

ItemPill.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  flat: PropTypes.bool,
  isolated: PropTypes.bool,
  large: PropTypes.bool,
  multiline: PropTypes.bool,
  selected: PropTypes.bool,
  stretch: PropTypes.bool,
  borderless: PropTypes.bool,
};

ItemPill.defaultProps = {
  flat: false,
  isolated: false,
  large: false,
  multiline: false,
  selected: false,
  stretch: false,
  borderless: false,
};

export default ItemPill;
