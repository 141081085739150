import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'
import _ from 'lodash'

import RoleForm from './RoleForm'
import TeamMembership from '../../../api/TeamMembership'

const i18nOpts = { scope: 'team.role.index' }

class Role extends Component {
  constructor(props) {
    super()

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.toggleEdit = this.toggleEdit.bind(this)

    this.state = {
      editing: false,
      role: props.role,
      newRole: props.role,
    }
  }

  handleChange({ target: { name, value } }) {
    this.setState((prevState) => _.set(prevState, name, value))
  }

  handleSubmit(evt) {
    evt.preventDefault()

    const { id, carePlanId } = this.props
    const role = this.state.newRole
    new TeamMembership(carePlanId).update({ id, role }).then((res) => {
      if (res.ok) {
        res.json().then(({ role }) => this.setState({ role, editing: false }))
      }
    })
  }

  toggleEdit() {
    this.setState({
      newRole: this.state.role,
      editing: !this.state.editing,
    })
  }

  render() {
    const { changeable } = this.props
    const { role, newRole } = this.state

    if (this.state.editing) {
      return (
        <RoleForm
          cancel={this.toggleEdit}
          newRole={newRole}
          onSubmit={this.handleSubmit}
          onChange={this.handleChange}
        />
      )
    } else {
      return (
        role !== 'patient' && (
          <div className={`user-profile__role user-profile__role--${role}`}>
            {t(role, { scope: 'role' })}
            {changeable && (
              <a className="link--inline" onClick={this.toggleEdit}>
                {t('change', i18nOpts)}
              </a>
            )}
          </div>
        )
      )
    }
  }
}

Role.propTypes = {
  carePlanId: PropTypes.string.isRequired,
  changeable: PropTypes.bool,
  id: PropTypes.number.isRequired,
  role: PropTypes.string.isRequired,
}

Role.defaultProps = {
  changeable: false,
}

export default Role
