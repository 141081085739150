import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Icon from 'Components/Base/Icon'

const IconButton = ({ text, iconName, isFlat, isReversed, isSecondary, className, ...rest }) => {
  const buttonClass = classNames({
    [className]: !!className,
    btn: true,
    'btn--flat': isFlat,
    'btn--icon': !isReversed,
    'btn--icon-reversed': isReversed,
    'btn--secondary': isSecondary,
  })

  return (
    <button title={text} {...rest} className={buttonClass}>
      <div className="icon btn__icon">
        <Icon name={iconName} />
      </div>
      <span className="btn__text">{text}</span>
    </button>
  )
}

IconButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  iconName: PropTypes.string.isRequired,
  isFlat: PropTypes.bool,
  isReversed: PropTypes.bool,
  isSecondary: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
}

IconButton.defaultProps = {
  className: '',
  type: 'button',
  isFlat: false,
  isReversed: false,
  isSecondary: false,
  text: '',
}

export default IconButton
