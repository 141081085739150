import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'
import _ from 'lodash'

import CheckInForm from './CheckInForm'
import CheckInList from './CheckInList'
import TemplateDropBox from './TemplateDropBox'
import Icon from 'Components/Base/Icon'
import CarePlanTemplateCheckInTemplateApi from 'Api/TemplateCreator/CarePlanTemplateCheckInTemplate'
import RepeatedCheckInApi from 'Api/RepeatedCheckIn'
import CheckInApi from 'Api/CheckIn'
import SectionFooter from '../SectionFooter'

import moment from 'ct-moment'
import 'moment-timezone'
import { roundTimeUp } from 'Utilities/time'

const i18nOpts = { scope: 'care_plan_creators.check_ins.index' }

class CheckInSection extends Component {
  constructor(props) {
    super(props)

    this.openForm = this.openForm.bind(this)
    this.resetFormState = this.resetFormState.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSuccess = this.handleSuccess.bind(this)
    this.handleErrors = this.handleErrors.bind(this)
    this.toggleDropbox = this.toggleDropbox.bind(this)
    this.editCheckIn = this.editCheckIn.bind(this)
    this.deleteCheckIn = this.deleteCheckIn.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.checkInParams = this.checkInParams.bind(this)

    this.state = {
      ...this.initialState,
      checkIns: props.checkIns,
    }
  }

  get initialState() {
    const currentTime = moment().tz(this.props.timeZone)

    return {
      id: null,
      showForm: false,
      showDropbox: false,
      selectedTemplateId: undefined,
      frequencyType: 'once',
      daysOffset: '',
      frequency: '',
      dueAt: roundTimeUp(5, currentTime).format(),
      timeOffset: undefined,
      until: undefined,
      count: undefined,
      endRepeat: 'never',
      interval: 1,
      visibleToAll: true,
      errors: {},
    }
  }

  get api() {
    if (this.props.isTemplate) {
      return new CarePlanTemplateCheckInTemplateApi(this.props.parentId, this.state.id)
    } else if (this.state.frequencyType === 'recurring') {
      return new RepeatedCheckInApi(this.props.parentId, this.state.id)
    } else {
      return new CheckInApi(this.props.parentId, this.state.id)
    }
  }

  get editing() {
    return !!this.state.id
  }

  openForm({ id, visibleToAll }) {
    this.resetFormState()
    this.setState({
      selectedTemplateId: id,
      showForm: true,
      visibleToAll
    })
  }

  checkInParams() {
    const {
      id,
      frequencyType,
      count,
      endRepeat,
      dueAt,
      selectedTemplateId,
      daysOffset,
      frequency,
      until,
      timeZone,
      interval,
      timeOffset,
      visibleToAll,
    } = this.state

    const paramsDueAt = frequencyType === 'once' ? '' : dueAt
    const paramsDaysOffset = frequencyType === 'once' ? undefined : daysOffset
    const paramsCount = endRepeat === 'times' ? count : ''
    const paramsUntil = endRepeat === 'on_a_day' ? until : ''

    return {
      id,
      frequencyType,
      daysOffset: paramsDaysOffset,
      frequency,
      timeZone,
      endRepeat,
      interval,
      timeOffset,
      dueAt: paramsDueAt,
      until: paramsUntil,
      count: paramsCount,
      checkInTemplateId: selectedTemplateId,
      visibleToAll
    }
  }

  handleSubmit(evt) {
    evt.preventDefault()
    const params = this.checkInParams()
    const endpoint = this.editing ? this.api.update(params) : this.api.create(params)

    if (!this.state.frequencyType) {
      this.setState({ errors: { frequency_type: t('invalid', i18nOpts) } })
      return false
    }

    endpoint.then((res) => {
      if (res.ok) {
        res.json().then(this.handleSuccess)
      } else if (res.status === 422) {
        res.json().then(this.handleErrors)
      }
    })
  }

  handleSuccess({ checkIn }) {
    if (this.editing) {
      this.setState(({ checkIns }) => ({
        checkIns: checkIns.map((existing) => (existing.id === checkIn.id ? checkIn : existing)),
      }))
    } else {
      const checkIns = [...this.state.checkIns, checkIn]
      this.setState({ checkIns })
    }
    this.resetFormState()
  }

  handleErrors(errors) {
    this.setState({ errors })
  }

  resetFormState() {
    this.setState({ ...this.initialState })
  }

  handleChange({ target: { name, value } }) {
    this.setState((prevState) => {
      if (name === 'frequencyType' && value === 'recurring' && this.state.frequency === '') {
        return {
          ...prevState,
          frequencyType: value,
          frequency: 'daily',
        }
      } else {
        return {
          ...prevState,
          [name]: value,
        }
      }
    })
  }

  toggleDropbox() {
    this.setState((prevState) => ({ showDropbox: !prevState.showDropbox }))
  }

  editCheckIn(checkIn) {
    if (this.state.showForm && this.state.id === checkIn.id) {
      this.resetFormState()
    } else {
      this.setState({
        id: checkIn.id,
        frequencyType: checkIn.frequencyType,
        frequency: checkIn.frequency,
        daysOffset: checkIn.daysOffset,
        interval: checkIn.interval,
        endRepeat: checkIn.endRepeat,
        count: checkIn.count,
        until: checkIn.until,
        dueAt: checkIn.dueAt,
        selectedTemplateId: checkIn.checkInTemplateId,
        showForm: true,
        errors: {},
        timeOffset: checkIn.timeOffset,
        visibleToAll: checkIn.visibleToAll
      })
    }
  }

  deleteCheckIn(id, frequencyType) {
    const checkIns = this.state.checkIns.map((checkIn) => {
      if (checkIn.id === id) {
        checkIn.loading = true
      }
      return checkIn
    })

    this.setState({ checkIns, id, frequencyType }, () =>
      this.api.destroy().then((res) => {
        if (res.ok) {
          const checkIns = this.state.checkIns.filter((checkIn) => checkIn.id !== id)
          this.setState({ checkIns })
          this.resetFormState()
        }
      })
    )
  }

  navigateToNextSection() {
    window.location.hash = '#forms'
  }

  render() {
    const { checkIns, showDropbox, showForm } = this.state
    const { checkInTemplates } = this.props
    const wrappedProps = _.assign({}, this.props, this.state)

    return (
      <div>
        <CheckInList deleteCheckIn={this.deleteCheckIn} editCheckIn={this.editCheckIn} checkIns={checkIns} />

        {!showForm && (
          <button className="btn btn--link careplan__list-add-button" onClick={this.toggleDropbox}>
            <Icon name="plus_square" className="careplan-wizard__icon_add" />
            {t('add_check_in', i18nOpts)}
            <Icon name="carrot-down" className="icon--medium" />
          </button>
        )}

        {showDropbox && (
          <TemplateDropBox
            openForm={this.openForm}
            toggle={this.toggleDropbox}
            templates={checkInTemplates}
          />
        )}

        {showForm && (
          <CheckInForm
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            editing={this.editing}
            {...wrappedProps}
          />
        )}
        <SectionFooter onNext={this.navigateToNextSection} />
      </div>
    )
  }
}

CheckInSection.propTypes = {
  checkInTemplates: PropTypes.array,
  checkIns: PropTypes.array,
  isTemplate: PropTypes.bool.isRequired,
  parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  timeZone: PropTypes.string.isRequired,
}

CheckInSection.defaultProps = {
  checkInTemplates: [],
  checkIns: [],
}

export default CheckInSection
