import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import { firstName } from 'Utilities/patientHelper';

import Icon from 'Components/Base/Icon';
import Checkbox from 'Components/Forms/Checkbox';
import InputList from 'Components/CarePlanCreators/InputList';
import PrimaryCaregiver from './PrimaryCaregiver';

const i18nOpts = { scope: 'care_plan_creators.careteam.primary_caregiver_form' };

const PrimaryCaregiverForm = ({ onChange, patientName, errors, primaryCaregivers, changeAndSubmit }) => {
  const togglePermission = (index) => () => {
    onChange({
      target: {
        name: `primaryCaregivers[${index}].permission`,
        value: !primaryCaregivers[index].permission,
      },
    });
  };

  const addPrimaryCaregiver = (evt) => {
    evt.preventDefault();

    onChange({
      target: {
        name: 'primaryCaregivers',
        value: [
          ...primaryCaregivers,
          {
            invitation: {
              email: '',
              firstName: '',
              lastName: '',
              phone: ''
            },
            sdmInfo: {
              isSdm: false,
              isConfirmed: false,
              sdmType: "none",
            },
            permission: false,
          },
        ],
      },
    });
  };

  return (
    <div className="careplan-wizard__section-stc-form">
      <h4 className="careplan-wizard__section-subheader">{t('header', i18nOpts)}</h4>

      <p className="text-primary-lighter careplan-wizard__section-sublabel">
        {t('sublabel', { ...i18nOpts, firstName: firstName(patientName) })}
      </p>

      <InputList
        list={primaryCaregivers}
        onChange={onChange}
        changeAndSubmit={changeAndSubmit}
        name="primaryCaregivers"
        render={(
          {
            relationship,
            permission,
            sdmInfo,
            invitation: {
              email,
              firstName,
              lastName,
              phone
            }
          }, name
        ) => (
          <PrimaryCaregiver
            name={name}
            email={email}
            firstName={firstName}
            lastName={lastName}
            phone={phone}
            permission={permission}
            relationship={relationship}
            errors={errors}
            onChange={onChange}
            togglePermission={togglePermission}
            sdmInfo={sdmInfo}
            patientName={patientName}
          />
        )}
        renderExtra={({ permission }, name, i) => (
          <Checkbox
            key={`checkbox${i}`}
            checked={permission}
            name={`${name}.permission`}
            text={t('permission', { ...i18nOpts, firstName: firstName(patientName) })}
            errors={errors}
            onChange={togglePermission(i)}
          />
        )}
        removeInviteClass="careplan-wizard__input-btn-delete-stc-invitation"
      />

      <div className='careplan-wizard__add-stc-button__container'>
        <button className="btn btn--link careplan__list-add-button" onClick={addPrimaryCaregiver}>
          <Icon name="plus_square" className="careplan-wizard__icon_add" />
          {t('add', i18nOpts)}
        </button>
      </div>
    </div>
  );
};

PrimaryCaregiverForm.propTypes = {
  errors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  patientName: PropTypes.string.isRequired,
  primaryCaregivers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      invitation: PropTypes.shape({
        email: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        phone: PropTypes.string,
      }),
      permission: PropTypes.bool,
      relationship: PropTypes.string,
    }).isRequired
  ),
};

PrimaryCaregiverForm.defaultProps = {
  errors: {},
  primaryCaregivers: [],
};

export default PrimaryCaregiverForm;
