import React, { Component } from 'react';
import PropTypes from 'prop-types';

import moment from 'ct-moment';
import 'moment-timezone';

import DateTimeInput from 'Components/Forms/DateTimeInput';
import Submit from 'Components/Forms/Submit';
import Button from 'Components/Base/Button';

import { roundTimeUp } from 'Utilities/time';

import Appointment from 'Api/Appointment';

import { t } from 'i18n';

class NestedAppointmentForm extends Component {
  constructor(props) {
    super(props);

    this.filterResources = this.filterResources.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleErrors = this.handleErrors.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    const currentTime = props.currentTime.tz(props.timeZone);

    this.state = {
      due_at: roundTimeUp(5, currentTime).format(),
      errors: {},
    };
  }

  handleChange({ target: { value } }) {
    this.setState({ due_at: value });
  }

  handleErrors(errors) {
    this.setState({ errors });
  }

  handleSubmit(evt) {
    evt.preventDefault();

    new Appointment(this.props.carePlanUuid).create(this.params).then((res) => {
      if (res.ok) {
        res.json().then(({ appointment, order }) => {
          const link = appointment ? appointment.link : order.link;
          window.location.href = link;
          return;
        });
      } else if (res.status === 422) {
        res.json().then(this.handleErrors);
      }
    });
  }

  filterResources() {
    return this.props.services.map((service) => {
      return { ...service, selected: true };
    });
  }

  get params() {
    return {
      parent_id: this.props.parent_id,
      due_at: this.state.due_at,
      practitioner: this.props.practitioner,
      text: this.props.text,
      kind: this.props.kind,
      resources: this.filterResources(),
      url: this.props.url,
    };
  }

  render() {
    return (
      <form className="form form--inline form--background" onSubmit={this.handleSubmit}>
        <div className="input">
          <label>{t('add_appointment', { scope: 'tasks.nested_appointment_form' })}</label>
          <DateTimeInput
            id="due_at"
            name="due_at"
            value={this.state.due_at}
            onChange={this.handleChange}
            timeZone={this.props.timeZone}
          />
        </div>

        <div className="form__submit">
          <Submit />
          <Button className="btn btn--secondary" onClick={this.props.skip}>
            {t('skip', { scope: 'tasks.nested_appointment_form' })}
          </Button>
        </div>
      </form>
    );
  }
}

NestedAppointmentForm.propTypes = {
  carePlanUuid: PropTypes.string.isRequired,
  currentTime: PropTypes.object,
  kind: PropTypes.string,
  parent_id: PropTypes.number.isRequired,
  practitioner: PropTypes.string,
  services: PropTypes.array,
  skip: PropTypes.func.isRequired,
  text: PropTypes.string,
  timeZone: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string,
};

NestedAppointmentForm.defaultProps = {
  currentTime: moment(),
  kind: 'medical',
  practitioner: '',
  services: [],
  text: '',
  timeZone: 'Etc/UTC',
  type: 'Appointment',
};

export default NestedAppointmentForm;
