import React from 'react'
import PropTypes from 'prop-types'

import Icon from 'Components/Base/Icon'

const ItemPillIcon = ({ iconName }) => (
  <div className="item-pill__icon">
    <Icon name={iconName} />
  </div>
)

ItemPillIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
}

ItemPillIcon.defaultProps = {}

export default ItemPillIcon
