import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../Base/Icon';

import { t } from 'i18n';

const i18nOpts = { scope: 'tasks.show.status' };

const IncompleteStatus = ({ isComplete, messages: { text: msgText } }) => (
  <section className="card-section card-section--status">
    <div className="icon icon--large">
      <Icon name="check_alt" />
    </div>

    <div className="card-section__content">
      <h3 className="card-section__header">{t('heading', i18nOpts)}</h3>

      {isComplete ? (
        <p className="card-section__status card-section__status--complete">
          <Icon name="check_alt3" className="card__section--check-icon" />{msgText}
        </p>
      ) : (
        <p className="card-section__status card-section__status--incomplete">{msgText}</p>
      )}

    </div>
  </section>
);

IncompleteStatus.propTypes = {
  isComplete: PropTypes.bool,
  messages: PropTypes.object,
};

IncompleteStatus.defaultProps = {
  isComplete: false,
  messages: { text: '' },
};

export default IncompleteStatus;
