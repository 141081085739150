import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import SupportTeamMemberRow from './SupportTeamMemberRow';
class SupportTeamMember extends PureComponent {
  constructor(props) {
    super(props);
  }

  get owner() {
    return this.props.member.owner || this.props.member.pendingOwner || this.props.member.declinedOwner;
  }

  render() {
    const { id, path, relationship, status, canUpdateInvitation, substituteDecisionMaker } = this.props.member;

    // Note: canUpdateInvitation is a permission that allows user to update, resend, delete an Invitation
    //       canEditInvitation is a permission that allows user to update an Invitation

    return (
      <Fragment>
        <SupportTeamMemberRow
          id={id}
          path={path}
          relationship={relationship}
          substituteDecisionMaker={substituteDecisionMaker}
          status={status}
          owner={this.owner}
          showPending={!!canUpdateInvitation}
        />
      </Fragment>
    );
  }
}

SupportTeamMember.propTypes = {
  carePlanId: PropTypes.string.isRequired,
  member: PropTypes.shape({
    canUpdateInvitation: PropTypes.bool,
    canEditInvitation: PropTypes.bool,
    id: PropTypes.number.isRequired,
    path: PropTypes.string.isRequired,
    relationship: PropTypes.string,
    role: PropTypes.string,
    status: PropTypes.string.isRequired,
    owner: PropTypes.object,
    pendingOwner: PropTypes.object,
    declinedOwner: PropTypes.object,
  }).isRequired,
};

SupportTeamMember.defaultProps = {
  member: {
    canUpdateInvitation: false,
    canEditInvitation: false,
  },
};

export default SupportTeamMember;
