import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import CarePlan from './CarePlan'

const CarePlanFilters = ({ carePlans, excludedCarePlans, onChange }) => (
  <div className="sidebar-section">
    <h3 className="sidebar-section__header">{t('header', { scope: 'tasks.index.sidebar' })}</h3>

    {carePlans.map(({ uuid, title, link, creatorName }) => (
      <CarePlan
        key={uuid}
        creatorName={creatorName}
        link={link}
        onChange={onChange.bind(null, uuid)}
        isChecked={!excludedCarePlans.includes(uuid)}
        title={title}
      />
    ))}
  </div>
)

CarePlanFilters.propTypes = {
  carePlans: PropTypes.array.isRequired,
  excludedCarePlans: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default CarePlanFilters
