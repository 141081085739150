import React from 'react'
import PropTypes from 'prop-types'

const Footer = ({ left, right }) => {
  return (
    <div className="card__button-footer">
      {left && <div className="card__button-footer-left">{left}</div>}
      {right && <div className="card__button-footer-right">{right}</div>}
    </div>
  )
}

Footer.propTypes = {
  left: PropTypes.any,
  right: PropTypes.any,
}

Footer.defaultProps = {
  left: null,
  right: null,
}

export default Footer
