import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Document from 'Components/CarePlanCreators/Documents/Document'
import DocumentForm from 'Components/Forms/DocumentForm'
import MedicalDocumentDispatcher from 'Api/Dispatchers/MedicalDocumentDispatcher'
import { Modal } from 'Components/Base/Modal'

import { t } from 'i18n'
const i18nOpts = { scope: 'care_plan_creators.documents.uploaded_document_list' }

class UploadedDocumentList extends Component {
  constructor(props) {
    super(props)

    const recordsProcessing = props.documents.reduce((result, document) => {
      result[document.id] = false
      return result
    }, {})

    this.state = {
      activeDocument: {},
      documents: props.documents,
      errors: {},
      editing: false,
      fileName: null,
      isSubmitting: false,
      recordsProcessing,
    }

    this.createDocument = this.createDocument.bind(this)
    this.editDocument = this.editDocument.bind(this)
    this.updateDocument = this.updateDocument.bind(this)
    this.deleteDocument = this.deleteDocument.bind(this)
    this.addDocument = this.addDocument.bind(this)
    this.replaceDocument = this.replaceDocument.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  createDocument(formData) {
    this.setState({ isSubmitting: true, errors: {} })

    return new MedicalDocumentDispatcher(this.props.parentId, this.props.isTemplate)
      .create(formData)
      .then((res) => {
        if (res.ok) {
          return res.json().then(({ medicalDocument }) => {
            this.addDocument(medicalDocument)
            this.closeModal()
          })
        } else if (res.status === 422) {
          return res.json().then((errors) => this.setState({ errors, isSubmitting: false }))
        }
      })
  }

  editDocument(document) {
    this.setState({ activeDocument: document, editing: true })
    this.props.openModal()
  }

  updateDocument(formData, id) {
    this.setState({ isSubmitting: true, errors: {} })

    return new MedicalDocumentDispatcher(this.props.parentId, this.props.isTemplate, id)
      .update(formData)
      .then((res) => {
        if (res.ok) {
          return res.json().then(({ medicalDocument }) => {
            this.replaceDocument(id, medicalDocument)
            this.closeModal()
          })
        } else if (res.status === 422) {
          return res.json().then((errors) => this.setState({ errors, isSubmitting: false }))
        }
      })
  }

  deleteDocument(id) {
    this.setState((prevState) => ({ recordsProcessing: { ...prevState.recordsProcessing, [id]: true } }))

    return new MedicalDocumentDispatcher(this.props.parentId, this.props.isTemplate, id)
      .destroy()
      .then((res) => {
        if (res.ok) {
          const documents = this.state.documents.filter((document) => document.id !== id)
          const recordsProcessing = { ...this.state.recordsProcessing }
          delete recordsProcessing[id]

          this.setState({ documents, recordsProcessing })
        }
      })
  }

  addDocument(medicalDocument) {
    this.setState((prevState) => ({ documents: [medicalDocument, ...prevState.documents] }))
  }

  replaceDocument(currentDocumentId, newDocument) {
    const updatedDocuments = this.state.documents.map((document) => {
      return document.id === currentDocumentId ? newDocument : document
    })

    this.setState({ documents: updatedDocuments })
  }

  closeModal() {
    this.setState({ activeDocument: {}, editing: false, errors: {}, isSubmitting: false })
    this.props.closeModal()
  }

  render() {
    return (
      <div className="item-pill-section__item-list" data-test="upload-list">
        <Modal
          isOpen={this.props.modalIsOpen}
          closeModal={this.closeModal}
          title={this.state.editing ? t('edit_document', i18nOpts) : t('upload_document', i18nOpts)}
        >
          <DocumentForm
            activeDocument={this.state.activeDocument}
            addDocument={this.addDocument}
            parentId={this.props.parentId}
            createDocument={this.createDocument}
            editing={this.state.editing}
            errors={this.state.errors}
            isSubmitting={this.state.isSubmitting}
            onCancel={this.closeModal}
            updateDocument={this.updateDocument}
          />
        </Modal>

        {this.state.documents.length ? (
          this.state.documents.map((document) => (
            <Document
              key={document.id}
              deleteDocument={this.deleteDocument.bind(null, document.id)}
              document={document}
              editDocument={this.editDocument.bind(null, document)}
              isProcessing={this.state.recordsProcessing[document.id]}
              previewDocument={this.props.previewDocument.bind(null, document)}
            />
          ))
        ) : (
          <div className="item-pill-section--item-list-empty" data-test="empty-upload-list">
            <span
              dangerouslySetInnerHTML={{ __html: t('no_files_uploaded', i18nOpts) }}
              className="careplan__empty-list"
            />
          </div>
        )}
      </div>
    )
  }
}

UploadedDocumentList.propTypes = {
  closeModal: PropTypes.func.isRequired,
  documents: PropTypes.array,
  modalIsOpen: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  previewDocument: PropTypes.func.isRequired,
}

UploadedDocumentList.defaultProps = {
  documents: [],
}

export default UploadedDocumentList
