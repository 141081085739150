import dataApiRequestHandler from 'Components/Recommendations/api/dataApiRequestHandler';

export const handleRemoveRecommendation = (makeApiRequest = dataApiRequestHandler) => {
  return async (
    carePlanUuid,
    recommendationId,
    setRecommendations,
    setRemovingRecommendationIndex,
    setIsProcessing
  ) => {
    setIsProcessing(true);

    const response = await makeApiRequest(carePlanUuid, 'DELETE', null, recommendationId);

    setRemovingRecommendationIndex(null);
    setIsProcessing(false);

    if (response.content_type === 'RECOMMENDATIONS_DATA') {
      setRecommendations(response.data.recommendations);
    }
  };
};

export default handleRemoveRecommendation(dataApiRequestHandler);
