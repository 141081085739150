import Api from './Api';

class Referral extends Api {
  constructor(carePlanId, id) {
    super();

    this.id = id;
    this.carePlanId = carePlanId;
  }

  createLaunchString(body) {
    return this.request(this.url, 'POST', body, false);
  }

  complete() {
    return this.request(`${this.url}/${this.id}/complete`, 'PATCH');
  }

  incomplete() {
    return this.request(`${this.url}/${this.id}/incomplete`, 'PATCH');
  }

  get url() {
    return `/care_plans/${this.carePlanId}/referrals`;
  }
}

export default Referral;
