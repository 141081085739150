import {
  SET_CURRENT_STEP,
  SET_TARGET_USER_INFO,
  SET_AVATAR,
  SUBMIT_CODE,
  GO_TO_NEXT_STEP,
  SET_IS_LOADING,
  SET_API_ERROR_MESSAGE,
} from 'Actions/signupPage';

const initialState = {
  currentStep: 0,
  code: '',
  file: null,
  user: {
    firstName: '',
    lastName: '',
    email: '',
    avatar: null,
    password: '',
    passwordConfirmation: '',
    termsOfService: false,
    consentToDisclosure: false,
    role: 'patient',
    patientFirstName: '',
    patientLastName: '',
  },
  isLoading: false,
  apiErrorMessage: '',
};

const signupPage = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.index,
      };

    case GO_TO_NEXT_STEP: {
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    }

    case SET_TARGET_USER_INFO: {
      const { name, value } = action.targetInfo;

      return {
        ...state,
        user: {
          ...state.user,
          [name]: value,
        },
      };
    }

    case SET_AVATAR: {
      return {
        ...state,
        file: action.file,
        user: {
          ...state.user,
          avatar: action.encodedAvatar,
        },
      };
    }

    case SUBMIT_CODE:
      return {
        ...state,
        currentStep: state.currentStep + 1,
        code: action.code,
      };

    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };

    case SET_API_ERROR_MESSAGE:
      return {
        ...state,
        apiErrorMessage: action.apiErrorMessage,
      };

    default:
      return state;
  }
};

export default signupPage;
