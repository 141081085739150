import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from './Table';
import InputGroup from 'Components/Forms/InputGroup';
import { t } from 'i18n';

const i18nOpts = { scope: 'dashboard' };

const BasalSettings = ({ profiles, title, header }) => {
  if (!profiles.length)
    return (
      <div className="pump-settings__basal-rates__container">
        <div className="pump-settings__settings-group__title">{title}</div>
      </div>
    );

  if (profiles.length === 1) {
    const profile = profiles[0];
    return (
      <div className="pump-settings__basal-rates__container">
        <div className="pump-settings__settings-group__title">{title}</div>
        <div>
          <div className="pump-settings__basal-rates__select__header">{header}</div>
          <div className="pump-settings__basal-rates__select">
            {profile.isActive ? `${profile.name} (${t('insulin.active', i18nOpts)})` : profile.name}
          </div>
        </div>
        <Table data={profile} />
      </div>
    );
  }

  const activeProfileId = profiles.find((profile) => profile.isActive)?.id;

  // In case no profile is active, just default to showing the first profile
  const [selectedProfileId, setSelectedProfileId] = useState(activeProfileId || profiles[0].id);
  const selectedProfileData = profiles.find((profile) => profile.id === selectedProfileId);

  return (
    <div className="pump-settings__basal-rates__container">
      <div className="pump-settings__settings-group__title">{title}</div>
      <div className="pump-settings__basal-rates__select__container">
        <div className="pump-settings__basal-rates__select__group">
          <div className="pump-settings__basal-rates__select__header">{header}</div>
          <InputGroup
            component="select"
            name="basalRateSettings"
            className="pump-settings__basal-rates__select"
            onChange={(evt) => setSelectedProfileId(evt.target.value)}
            value={selectedProfileId}
          >
            {profiles.map((profile) => (
              <option value={profile.id} key={profile.id}>
                {profile.isActive ? `${profile.name} (${t('insulin.active', i18nOpts)})` : profile.name}
              </option>
            ))}
          </InputGroup>
        </div>
        <Table data={selectedProfileData} />
      </div>
    </div>
  );
};

BasalSettings.propTypes = {
  header: PropTypes.string,
  profiles: PropTypes.array,
  title: PropTypes.string,
};

BasalSettings.defaultProps = {
  header: '',
  profiles: [],
  title: '',
};

export default BasalSettings;
