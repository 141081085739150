import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'i18n';
import Input from './Input';
import Submit from 'Components/Forms/Submit';
import { Footer } from 'Components/Base/Card';
import handleValidateAndSubmit from './handleValidateAndSubmit';
import { setIsEditing } from 'Actions/dashboardInsulinDosing';
import ActionButton from 'Components/Base/ActionButton';

import { CarePlanContext } from 'Components/Dashboard/Dashboard';

const i18nOpts = { scope: 'dashboard.insulin' };

const Edit = ({ setIsEditing, doseValues }) => {
  const { carePlanUuid } = useContext(CarePlanContext);

  const [hasSubmissionError, setHasSubmissionError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  return (
    <form
      className="form form--background"
      onSubmit={(event) => {
        event.preventDefault();
        setIsLoading(true);
        setDisabled(true);
        handleValidateAndSubmit(
          setIsEditing,
          setIsLoading,
          setDisabled,
          setHasSubmissionError,
          doseValues,
          carePlanUuid
        );
      }}
    >
      <div className="form__row">
        <div className="stats-table__drug-input-section">
          <Input label={t('rapid_insulin_type', i18nOpts)} name="rapidInsulinType" />
          <Input label={t('long_insulin_type', i18nOpts)} name="longInsulinType" />
        </div>
      </div>
      <div className="form__row">
        <div className="stats-table__dose-input-section">
          <span className="stats-table__column-label">{/* intentionally blank */}</span>
          <span className="stats-table__column-label">{t('long', i18nOpts)}</span>
          <span className="stats-table__column-label">{t('ICR', i18nOpts)}</span>
          <span className="stats-table__column-label">{t('ISF', i18nOpts)}</span>

          <div className="stats-table__row-label">
            <p>{t('breakfast', i18nOpts)}</p>
          </div>
          <Input className="stats-table__dose-input" type="number" name="breakfastLong" />
          <Input className="stats-table__dose-input" type="number" name="breakfastICR" />
          <Input className="stats-table__dose-input" type="number" name="breakfastISF" />

          <div className="stats-table__row-label">
            <p>{t('lunch', i18nOpts)}</p>
          </div>
          <Input className="stats-table__dose-input" type="number" name="lunchLong" />
          <Input className="stats-table__dose-input" type="number" name="lunchICR" />
          <Input className="stats-table__dose-input" type="number" name="lunchISF" />

          <div className="stats-table__row-label">
            <p>{t('dinner', i18nOpts)}</p>
          </div>
          <Input className="stats-table__dose-input" type="number" name="dinnerLong" />
          <Input className="stats-table__dose-input" type="number" name="dinnerICR" />
          <Input className="stats-table__dose-input" type="number" name="dinnerISF" />

          <div className="stats-table__row-label">
            <p>{t('bedtime', i18nOpts)}</p>
          </div>
          <Input className="stats-table__dose-input" type="number" name="bedtimeLong" />
          <Input className="stats-table__dose-input" type="number" name="bedtimeICR" />
          <Input className="stats-table__dose-input" type="number" name="bedtimeISF" />
        </div>
      </div>

      {hasSubmissionError && <div className="stats-table__text--api-error">{t('save_error', i18nOpts)}</div>}

      <Footer
        right={
          <ActionButton
            isLoading={isLoading}
            disabled={disabled}
            content={t('save', i18nOpts)}
          />
        }
      />
    </form>
  );
};

Edit.propTypes = {
  doseValues: PropTypes.object.isRequired,
  setIsEditing: PropTypes.func.isRequired,
};

const mapStateToProps = ({ dashboardInsulinDosing: { doseValues } }) => {
  return { doseValues };
};

const mapDispatchToProps = (dispatch) => ({
  setIsEditing: (isEditing) => dispatch(setIsEditing(isEditing)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
