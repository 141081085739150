import React from 'react';
import PropTypes from 'prop-types';

import {
  ItemPill,
  ItemPillIcon,
  ItemPillActions,
  ItemPillActionIcon,
  ItemPillText,
} from 'Components/Base/ItemPill';

const FormLine = ({ form: { title, loading }, deleteForm, editForm }) => (
  <ItemPill selected>
    <ItemPillIcon iconName="task" />

    <ItemPillText text={title} data-test="display-name" />

    <ItemPillActions isLoading={loading}>
      <ItemPillActionIcon iconName="file-edit" onClick={editForm} data-test="edit-icon" />
      <ItemPillActionIcon iconName="minus-circle" onClick={deleteForm} data-test="delete-icon" />
    </ItemPillActions>
  </ItemPill>
);

FormLine.propTypes = {
  deleteForm: PropTypes.func.isRequired,
  editForm: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
};

FormLine.defaultProps = {};

export default FormLine;
