import React from 'react'
import PropTypes from 'prop-types'

import Backdrop from 'Components/Base/Backdrop'
import { t } from 'i18n'

const i18nOpts = { scope: 'care_plan_creators.check_ins.index' }

const TemplateDropBox = ({ openForm, toggle, templates }) => (
  <div className="dropdown careplan-wizard__dropdown">
    <div className=" dropdown__menu">
      {templates.length ? (
        templates.map((template) => {
          return (
            <div className="dropdown__item" key={template.id} onClick={() => openForm(template)}>
              {template.name}
            </div>
          )
        })
      ) : (
        <div className="dropdown__item">{t('no_templates_available', i18nOpts)}</div>
      )}
    </div>
    <Backdrop transparent onClick={toggle} />
  </div>
)

TemplateDropBox.propTypes = {
  openForm: PropTypes.func.isRequired,
  templates: PropTypes.array,
  toggle: PropTypes.func.isRequired,
}

TemplateDropBox.defaultProps = {
  templates: [],
}

export default TemplateDropBox
