import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import {
  handleCloseActivityModal,
  setActivityModalState,
  setActivityModalFormErrors,
} from 'Actions/activityModal';

import { activityModalState as activityModalStateDefinitions } from '../../modalContentStateDefinitions';
import {
  setupDescriptionValidator,
  setupVirtualVisitLinkValidator,
  setupСheckInTemplateIdValidator,
  setupFormTemplateIdValidator,
} from '../../FormFields/formFieldsValidators';
import handleCreateActivity from './handleCreateActivity';

import ActionButton from 'Components/Base/ActionButton';

const i18nOpts = { scope: 'activity_modal.footer' };

export const fieldsValidatorsPass = (activityType, activityProperties, validators) => {
  if (activityType === 'APPOINTMENT') {
    const { validateDescription, validateVirtualVisitLink } = validators;
    const { description, virtualVisitLink } = activityProperties;
    return validateDescription(description) && validateVirtualVisitLink(virtualVisitLink);
  }
  if (activityType === 'CHECK_IN') {
    const { validateСheckInTemplateId } = validators;
    const { checkInTemplateId } = activityProperties;
    return validateСheckInTemplateId(checkInTemplateId);
  }
  if (activityType === 'TASK') {
    const { validateDescription } = validators;
    const { description } = activityProperties;
    return validateDescription(description);
  }
  if (activityType === 'FORM') {
    const { validateDescription, validateFormId } = validators;
    const { description, formTemplateId } = activityProperties;
    return validateFormId(formTemplateId);
  }

  return true;
};

export const CreateFooter = ({
  carePlanId,
  activityType,
  activityProperties,
  activityModalState,
  formErrors,
  handleCloseActivityModal,
  setActivityModalState,
  setActivityModalFormErrors,
  onCreate,
}) => {
  const { LOADING } = activityModalStateDefinitions;

  const validatorI18nOpts = {
    scope: 'activity_modal.form_fields.validators',
  };

  const validateDescription = setupDescriptionValidator(
    formErrors,
    setActivityModalFormErrors,
    t,
    validatorI18nOpts
  );
  const validateVirtualVisitLink = setupVirtualVisitLinkValidator(
    formErrors,
    setActivityModalFormErrors,
    t,
    validatorI18nOpts
  );
  const validateСheckInTemplateId = setupСheckInTemplateIdValidator(
    formErrors,
    setActivityModalFormErrors,
    t,
    validatorI18nOpts
  );
  const validateFormId = setupFormTemplateIdValidator(
    formErrors,
    setActivityModalFormErrors,
    t,
    validatorI18nOpts
  );

  const validators = {
    validateDescription,
    validateVirtualVisitLink,
    validateСheckInTemplateId,
    validateFormId,
  };

  return (
    <div className="action_modal__footer">
      <ActionButton
        className="button-subtle action_modal__footer__cancel_button"
        isLoading={activityModalState === LOADING}
        disabled={activityModalState === LOADING}
        onClick={handleCloseActivityModal}
        content={t('cancel', i18nOpts)}
      />
      <ActionButton
        isLoading={activityModalState === LOADING}
        disabled={activityModalState === LOADING}
        onClick={() => {
          if (fieldsValidatorsPass(activityType, activityProperties, validators)) {
            handleCreateActivity(
              setActivityModalState,
              handleCloseActivityModal,
              carePlanId,
              activityType,
              activityProperties,
              onCreate
            );
          }
        }}
        content={t('save', i18nOpts)}
      />
    </div>
  );
};

const mapStateToProps = ({ activityModal }) => {
  const { carePlanId, activityType, activityProperties, activityModalState, formErrors } = activityModal;

  return {
    carePlanId,
    activityType,
    activityProperties,
    activityModalState,
    formErrors,
  };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchHandleCloseActivityModal = (dispatch) => {
    return () => {
      dispatch(handleCloseActivityModal());
    };
  };

  const dispatchSetActivityModalState = (dispatch) => {
    return (activityModalState) => {
      dispatch(setActivityModalState(activityModalState));
    };
  };

  const dispatchSetActivityModalFormErrors = (dispatch) => {
    return (formErrors) => {
      dispatch(setActivityModalFormErrors(formErrors));
    };
  };

  return {
    handleCloseActivityModal: dispatchHandleCloseActivityModal(dispatch),
    setActivityModalState: dispatchSetActivityModalState(dispatch),
    setActivityModalFormErrors: dispatchSetActivityModalFormErrors(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateFooter);
