import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'i18n';

import { setActivityModalActivityProperties, setActivityModalFormErrors } from 'Actions/activityModal';
import { setupDescriptionValidator } from './formFieldsValidators';

import FormField from './FormField';

const i18nOpts = { scope: 'activity_modal.form_fields.what' };

export const Description = ({
  activityProperties,
  activityType,
  formErrors,
  setActivityModalActivityProperties,
  setActivityModalFormErrors,
}) => {
  const handleDescriptionValidation = setupDescriptionValidator(
    formErrors,
    setActivityModalFormErrors,
    t,
    i18nOpts
  );

  const labelKey = activityType === 'TASK' ? 'task_description' : 'appointment_description';
  const labelDescription = t(labelKey, i18nOpts);

  return (
    <div className="activity_modal__input_field--description">
      <div className="activity_modal__input_field__label activity_modal__input_field__label--description">
        {labelDescription}
      </div>
      <div className="activity_modal__input_field__container">
        <FormField
          formErrors={formErrors}
          value={activityProperties.description}
          name="description"
          onChange={(value) => {
            setActivityModalActivityProperties({
              ...activityProperties,
              description: value,
            });
          }}
          handleValidation={handleDescriptionValidation}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ activityModal }) => {
  const { activityProperties, activityType, formErrors } = activityModal;

  return { activityProperties, activityType, formErrors };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalActivityProperties = (dispatch) => {
    return (activityProperties) => {
      dispatch(setActivityModalActivityProperties(activityProperties));
    };
  };

  const dispatchSetActivityModalFormErrors = (dispatch) => {
    return (formErrors) => {
      dispatch(setActivityModalFormErrors(formErrors));
    };
  };

  return {
    setActivityModalActivityProperties: dispatchSetActivityModalActivityProperties(dispatch),
    setActivityModalFormErrors: dispatchSetActivityModalFormErrors(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Description);
