import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Icon } from 'Components/Base/'

import useToggleState from 'Hooks/useToggleState'

const i18nOpts = { scope: 'patient.form_result' }

const FormResult = ({ form }) => {
  const [collapsed, handleCollapse] = useToggleState(true)

  return (
    <div className="form-result">
      <div className="form-result__heading">
        <div className="form-result__name">
          <div className="icon icon--large" onClick={handleCollapse}>
            <Icon name={collapsed ? 'carrot-down' : 'carrot-up'} />
          </div>
          <span className="text-bold text-grey-darkest">{form.text}</span>
        </div>
        <div>
          <span className="text-normal">
            {t('submitted_by', { ...i18nOpts, date: form.submitedData.date, name: form.submitedData.by })}
          </span>
        </div>
      </div>

      {!collapsed && (
        <div
          className="form-result__response text-normal"
          dangerouslySetInnerHTML={{ __html: form.submitedData.readableResponse }}
        />
      )}
    </div>
  )
}

FormResult.propTypes = {
  form: PropTypes.object.isRequired,
}

FormResult.defaultProps = {}

export default FormResult
