import React from 'react';
import { t } from 'i18n';
import moment from 'ct-moment';

import { connect } from 'react-redux';

import { roundTimeUp } from 'Utilities/time';

import IconButton from 'Components/Base/IconButton';

import {
  setActivityModalIsOpen,
  setActivityModalMode,
  setActivityModalActivityType,
  setActivityModalShowScheduledFields,
  setActivityModalActivityProperties
} from 'Actions/activityModal';

const AddActivityButton = ({
  activityType, // TASK || APPOINTMENT
  activityProperties,
  setActivityModalIsOpen,
  setActivityModalMode,
  setActivityModalActivityType,
  setActivityModalShowScheduledFields,
  setActivityModalActivityProperties,
  timeZone
}) => {
  let labelKey, iconName;

  if (activityType === 'TASK') {
    labelKey = 'add_task';
    iconName = "task";
  } else {
    labelKey = 'add_appointment';
    iconName = "calendar";
  }

  const handleOpenModal = () => {
    // Patient/Support Team cannot create unscheduled Appointments, so dueAt must be set on open
    if (activityType === 'APPOINTMENT') {
      setActivityModalShowScheduledFields(true);
      setActivityModalActivityProperties({
        ...activityProperties,
        frequencyType: 'scheduled',
        dueAt: roundTimeUp(5, moment()).tz(timeZone).format(),
      });
    }

    setActivityModalMode('CREATE');
    setActivityModalActivityType(activityType);
    setActivityModalIsOpen(true);
  }

  return (
    <IconButton
      iconName={iconName}
      text={t(labelKey, { scope: 'tasks.index' })}
      onClick={handleOpenModal}
    />
  )
}

const mapStateToProps = ({ activityModal }) => {
  const { activityProperties, timeZone } = activityModal;

  return { activityProperties, timeZone };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalIsOpen = (dispatch) => {
    return (isOpen) => {
      dispatch(setActivityModalIsOpen(isOpen));
    };
  };

  const dispatchSetActivityModalMode = (dispatch) => {
    return (mode) => {
      dispatch(setActivityModalMode(mode));
    };
  };

  const dispatchSetActivityModalActivityType = (dispatch) => {
    return (activityType) => {
      dispatch(setActivityModalActivityType(activityType));
    };
  };

  const dispatchSetActivityModalShowScheduledFields = (dispatch) => {
    return (isShown) => {
      dispatch(setActivityModalShowScheduledFields(isShown));
    };
  }

  const dispatchSetActivityModalActivityProperties = (dispatch) => {
    return (activityProperties) => {
      dispatch(setActivityModalActivityProperties(activityProperties));
    }
  }

  return {
    setActivityModalIsOpen: dispatchSetActivityModalIsOpen(dispatch),
    setActivityModalMode: dispatchSetActivityModalMode(dispatch),
    setActivityModalActivityType: dispatchSetActivityModalActivityType(dispatch),
    setActivityModalShowScheduledFields: dispatchSetActivityModalShowScheduledFields(dispatch),
    setActivityModalActivityProperties: dispatchSetActivityModalActivityProperties(dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddActivityButton);
