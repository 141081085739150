import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import {
  setCarePlanId,
  setSessionInfoState,
  setSessionInfoData,
  setAppointmentsCardState,
  setAppointmentsCardAppointmentsList,
  setCheckInsCardState,
  setCheckInsCardCheckInsList,
  setTasksCardState,
  setTasksCardTasksList,
  setFormsCardState,
  setFormsCardFormsList,
} from 'Actions/carePlanActionPlanViewPage';

import handleSessionInfoSetup from './handleSessionInfoSetup';
import handleAppointmentsSetup from './handleAppointmentsSetup';
import handleCheckInsSetup from './handleCheckInsSetup';
import handleTasksSetup from './handleTasksSetup';
import handleFormsSetup from './handleFormsSetup';

import Loader from 'Components/Base/Loader';
import AppointmentsCard from './AppointmentsCard';
import CheckInsCard from './CheckInsCard/CheckInsCard';
import TasksCard from './TasksCard/TasksCard';
import FormsCard from './FormsCard';
import ActivityModal from 'Components/Activities/ActivityModal';

const i18nOpts = { scope: 'care_plan_action_plan_page.activities_container' };

// temporary measures to get careplanId from URL
// until we are able to put it in Redux
export const getCarePlanIdFromPath = (path) => {
  return path.split('/care_plans/').pop();
};

const ApiError = () => {
  return (
    <div className="card">
      <div className="activities-container__error">{t('api_error', i18nOpts)}</div>
    </div>
  );
};

const UnexpectedError = () => {
  return (
    <div className="card">
      <div className="activities-container__error">{t('unexpected_error', i18nOpts)}</div>
    </div>
  );
};

const ActivitiesContainer = ({
  carePlanId,
  sessionInfoState,
  setCarePlanId,
  setSessionInfoState,
  setSessionInfoData,
  setAppointmentsCardState,
  setAppointmentsCardAppointmentsList,
  setCheckInsCardState,
  setCheckInsCardCheckInsList,
  setTasksCardState,
  setTasksCardTasksList,
  setFormsCardState,
  setFormsCardFormsList,
}) => {
  useEffect(() => {
    const carePlanIdFromPath = getCarePlanIdFromPath(window.location.pathname);
    setCarePlanId(carePlanIdFromPath);

    const handleActivitiesSetup = async () => {
      const sessionInfoReady = await handleSessionInfoSetup(
        setSessionInfoState,
        setSessionInfoData,
        carePlanIdFromPath
      );

      if (sessionInfoReady) {
        handleAppointmentsSetup(
          setAppointmentsCardState,
          setAppointmentsCardAppointmentsList,
          carePlanIdFromPath
        );
        handleCheckInsSetup(setCheckInsCardState, setCheckInsCardCheckInsList, carePlanIdFromPath);
        handleTasksSetup(setTasksCardState, setTasksCardTasksList, carePlanIdFromPath);
        handleFormsSetup(setFormsCardState, setFormsCardFormsList, carePlanIdFromPath);
      }
    };

    handleActivitiesSetup();
  }, []);

  switch (sessionInfoState) {
    case 'LOADING':
      return (
        <div className="card">
          <Loader />
        </div>
      );
    case 'ERROR':
      return <ApiError />;
    case 'MAIN_CONTENT':
      return (
        <>
          <ActivityModal
            onCreate={() => {
              handleAppointmentsSetup(
                setAppointmentsCardState,
                setAppointmentsCardAppointmentsList,
                carePlanId
              );
              handleCheckInsSetup(setCheckInsCardState, setCheckInsCardCheckInsList, carePlanId);
              handleTasksSetup(setTasksCardState, setTasksCardTasksList, carePlanId);
              handleFormsSetup(setFormsCardState, setFormsCardFormsList, carePlanId);
            }}
          />
          <AppointmentsCard />
          <CheckInsCard />
          <TasksCard />
          <FormsCard />
        </>
      );
    default:
      return <UnexpectedError />;
  }
};

const mapStateToProps = ({ carePlanActionPlanViewPage }) => {
  const { sessionInfo, carePlanId } = carePlanActionPlanViewPage;
  const { sessionInfoState } = sessionInfo;

  return { sessionInfoState, carePlanId };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetCarePlanId = (dispatch) => {
    return (carePlanId) => {
      dispatch(setCarePlanId(carePlanId));
    };
  };

  const dispatchSetSessionInfoState = (dispatch) => {
    return (sessionInfoState) => {
      dispatch(setSessionInfoState(sessionInfoState));
    };
  };

  const dispatchSetSessionInfoData = (dispatch) => {
    return (data) => {
      dispatch(setSessionInfoData(data));
    };
  };

  const dispatchSetAppointmentsCardState = (dispatch) => {
    return (state) => {
      dispatch(setAppointmentsCardState(state));
    };
  };

  const dispatchSetAppointmentsCardAppointmentsList = (dispatch) => {
    return (appointmentsList) => {
      dispatch(setAppointmentsCardAppointmentsList(appointmentsList));
    };
  };

  const dispatchSetCheckInsCardState = (dispatch) => {
    return (state) => {
      dispatch(setCheckInsCardState(state));
    };
  };

  const dispatchSetCheckInsCardCheckInsList = (dispatch) => {
    return (checkInsList) => {
      dispatch(setCheckInsCardCheckInsList(checkInsList));
    };
  };

  const dispatchSetTasksCardState = (dispatch) => {
    return (state) => {
      dispatch(setTasksCardState(state));
    };
  };

  const dispatchSetTasksCardTasksList = (dispatch) => {
    return (tasksList) => {
      dispatch(setTasksCardTasksList(tasksList));
    };
  };

  const dispatchSetFormsCardState = (dispatch) => {
    return (state) => {
      dispatch(setFormsCardState(state));
    };
  };

  const dispatchSetFormsCardFormsList = (dispatch) => {
    return (formsList) => {
      dispatch(setFormsCardFormsList(formsList));
    };
  };

  return {
    setCarePlanId: dispatchSetCarePlanId(dispatch),
    setSessionInfoState: dispatchSetSessionInfoState(dispatch),
    setSessionInfoData: dispatchSetSessionInfoData(dispatch),
    setAppointmentsCardState: dispatchSetAppointmentsCardState(dispatch),
    setAppointmentsCardAppointmentsList: dispatchSetAppointmentsCardAppointmentsList(dispatch),
    setCheckInsCardState: dispatchSetCheckInsCardState(dispatch),
    setCheckInsCardCheckInsList: dispatchSetCheckInsCardCheckInsList(dispatch),
    setTasksCardState: dispatchSetTasksCardState(dispatch),
    setTasksCardTasksList: dispatchSetTasksCardTasksList(dispatch),
    setFormsCardState: dispatchSetFormsCardState(dispatch),
    setFormsCardFormsList: dispatchSetFormsCardFormsList(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesContainer);
