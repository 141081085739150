import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InputGroup from 'Components/Forms/InputGroup';
import { setDoseValues, setInputErrors } from 'Actions/dashboardInsulinDosing';

import handleInputChange from './handleInputChange';
import handleValidateAndShowErrors from './handleValidateAndShowErrors';

const Input = ({ name, label, doseValues, setDoseValues, setInputErrors, type, inputErrors, className }) => {
  return (
    <InputGroup
      className={className}
      component="input"
      name={name}
      label={label}
      value={doseValues[name]}
      onChange={(event) => {
        handleInputChange(event, name, doseValues, setDoseValues);
      }}
      onBlur={() => {
        handleValidateAndShowErrors(doseValues, setInputErrors);
      }}
      type={type}
      step="any"
      errors={inputErrors}
    />
  );
};

Input.propTypes = {
  doseValues: PropTypes.object.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  setDoseValues: PropTypes.func.isRequired,
  type: PropTypes.string,
};

Input.defaultProps = {
  label: '',
  name: '',
  type: '',
};

const mapStateToProps = ({ dashboardInsulinDosing: { doseValues, inputErrors } }) => {
  return { doseValues, inputErrors };
};

const mapDispatchToProps = (dispatch) => ({
  setDoseValues: (doseValues) => dispatch(setDoseValues(doseValues)),
  setInputErrors: (inputErrors) => dispatch(setInputErrors(inputErrors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Input);
