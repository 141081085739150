import React from 'react';
import PropTypes from 'prop-types';

const IFrame = ({ id, link, onClose, parent, className }) => {
  if (parent !== null) {
    window.addEventListener('message', (event) => {
      onClose(event, parent);
    });
  }

  return (
    <div>
      <iframe id={id} src={link} frameBorder={0} className={className} />
    </div>
  );
};

IFrame.propTypes = {
  id: PropTypes.string,
  link: PropTypes.string.isRequired,
  parent: PropTypes.object,
  className: PropTypes.string,
};

IFrame.defaultProps = {
  parent: null,
  id: 'default',
  className: '',
};

export default IFrame;
