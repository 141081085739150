import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Button } from 'Components/Base/'

import CustomHighlight from 'Components/Base/Highlight/CustomHighlight'

const i18nOpts = { scope: 'base.action_headers' }

const CancelActionHeaderWithCareHub = ({ onClick }) => (
  <div className="card__header-profile_sub_section">
    <CustomHighlight type="success" message={t('carehub_sync', i18nOpts)} icon="sync" />
    <div className="card__header-vertical-line"></div>
    <Button className="btn--link card__header-link" onClick={onClick}>
      {t('cancel', i18nOpts)}
    </Button>
  </div>
)

CancelActionHeaderWithCareHub.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default CancelActionHeaderWithCareHub

