import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'i18n';
import StatsTable from 'Components/Base/Dashboard/StatsTable';
import StatsList from 'Components/Base/Dashboard/StatsList';
import formatDataForDisplay from './formatDataForDisplay';
import transformDataToCamelCase from './transformDataToCamelCase';
import fetchData from './fetchData';
import Loader from 'Components/Base/Loader';

import { CarePlanContext } from 'Components/Dashboard/Dashboard';
import { setDoseValues } from 'Actions/dashboardInsulinDosing';
import { setCreatedByUser, setUpdatedAt } from '../../../../../actions/dashboardInsulinDosing';
import UpdatedAt from '../UpdatedAt';

const i18nOpts = { scope: 'dashboard.insulin' };

const Show = ({ doseValues, createdByUser, updatedAt, setDoseValues, setCreatedByUser, setUpdatedAt }) => {
  const { carePlanUuid } = useContext(CarePlanContext);
  const [pageState, setPageState] = useState('loading');
  const formattedData = formatDataForDisplay(doseValues, i18nOpts);

  useEffect(() => {
    fetchData(
      carePlanUuid,
      setPageState,
      setDoseValues,
      setCreatedByUser,
      setUpdatedAt,
      transformDataToCamelCase
    );
  }, []);

  switch (pageState) {
    case 'loaded':
      return (
        <>
          {createdByUser && updatedAt && <UpdatedAt userName={createdByUser} updatedAt={updatedAt} />}
          <StatsList label={formattedData.insulin.label} list={formattedData.insulin.insulinTypes} />
          <StatsTable data={formattedData.dosing} />
        </>
      );
    case 'loading':
      return <Loader />;
    default:
      return <div className="stats-table__text--api-error">{t('fetch_error', i18nOpts)}</div>;
  }
};

Show.propTypes = {
  doseValues: PropTypes.object.isRequired,
  setDoseValues: PropTypes.func.isRequired,
  createdByUser: PropTypes.string,
  setCreatedByUser: PropTypes.func,
  updatedAt: PropTypes.string,
  setUpdatedAt: PropTypes.func,
};

const mapStateToProps = ({ dashboardInsulinDosing: { doseValues, createdByUser, updatedAt } }) => {
  return { doseValues, createdByUser, updatedAt };
};

const mapDispatchToProps = (dispatch) => ({
  setDoseValues: (doseValues) => dispatch(setDoseValues(doseValues)),
  setCreatedByUser: (createdByUser) => dispatch(setCreatedByUser(createdByUser)),
  setUpdatedAt: (updatedAt) => dispatch(setUpdatedAt(updatedAt)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Show);
