import moment from 'ct-moment';

// Return an array of data from the previous 7 days ending on (and including) rangeEndDate;

export const selectAndTransformChartDataWrapper = (timeProvider = moment) => {
  return (chartData, rangeEndDate, transform) => {
    const dataForDates = [6, 5, 4, 3, 2, 1, 0].map((daysPrior) => {
      const date = timeProvider(rangeEndDate).subtract(daysPrior, 'days').format('YYYY-MM-DD');
      const dataForDate = chartData[date];

      if (!dataForDate) return null;

      // transform the data
      return transform(dataForDate);
    });

    return dataForDates;
  };
};

export default selectAndTransformChartDataWrapper(moment);
