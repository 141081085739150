import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

const i18nOpts = { scope: 'patient.check_in_score' }

const BooleanOption = ({ modalSelectedOptionValue, optionData, onClick }) => {
  const score = optionData.data.score
  const colorCode = score === 1 ? '#56a701' : '#e30712'

  return (
    <a onClick={onClick} value={optionData.id}>
      {String(optionData.id) === modalSelectedOptionValue ? (
        <div
          className={'ReactModal__CheckIn--QuestionOption-selected-generic ReactModal__CheckIn--yes-no'}
          style={{ background: colorCode }}
        >
          <div className="ReactModal__CheckIn--QuestionOption-text">
            <span>
              <b>{t(score, i18nOpts)}</b>
            </span>
          </div>
        </div>
      ) : (
        <div className="ReactModal__CheckIn--QuestionOption ReactModal__CheckIn--yes-no">
          <div className="ReactModal__CheckIn--QuestionOption-text">
            <span>
              <b>{t(score, i18nOpts)}</b>
            </span>
          </div>
        </div>
      )}
    </a>
  )
}

BooleanOption.propTypes = {
  modalSelectedOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func.isRequired,
  optionData: PropTypes.object.isRequired,
}

BooleanOption.defaultProps = {
  modalSelectedOptionValue: '',
}

export default BooleanOption
