import { t } from 'i18n'
import { uniqBy } from 'lodash'

import AccessRequestApi from 'Api/AccessRequest'

import { openModal, closeModal } from 'Actions/modal'
import { receiveSupportTeamEntry } from 'Actions/supportTeam'

export const SET_ACCESS_REQUESTS = 'accessRequests/SET_ACCESS_REQUESTS'
export const SET_SELECTED_REQUEST = 'accessRequests/SET_SELECTED_REQUEST'
export const REMOVE_REQUEST = 'accessRequests/REMOVE_REQUEST'
export const CHANGE_ROLE = 'accessRequests/CHANGE_ROLE'
export const SET_IS_PLACE_USER = 'accessRequests/SET_IS_PLACE_USER'
export const TOGGLE_CONSENT = 'accessRequests/TOGGLE_CONSENT'
export const SET_MODAL_VIEW = 'accessRequests/SET_MODAL_VIEW'

const setAccessRequests = (accessRequests) => {
  const filtered = uniqBy(accessRequests, (accessRequests) => {
    return accessRequests.requesterId
  })
  return { type: SET_ACCESS_REQUESTS, accessRequests: filtered }
}

const setSelectedRequest = (id) => {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_REQUEST,
      id,
    })

    return dispatch(openModal('accessRequests'))
  }
}

const removeRequest = (id) => ({
  type: REMOVE_REQUEST,
  id,
})

const approveRequest = (id, role, consent) => {
  return (dispatch) => {
    return new AccessRequestApi(id).accept({ role, consent }).then((res) => {
      if (res.ok) {
        return res.json().then(({ teamMembership }) => {
          dispatch(receiveSupportTeamEntry(teamMembership))
          window.flash_messages.addMessage(t('team.access_requests.approved'))
          dispatch(removeRequest(id))
          return dispatch(closeModal())
        })
      } else {
        window.flash_messages.addMessage(t('team.access_requests.failure'))
        return
      }
    })
  }
}

const declineRequest = (id) => {
  return (dispatch) => {
    return new AccessRequestApi(id).decline().then((res) => {
      if (res.ok) {
        window.flash_messages.addMessage(t('team.access_requests.declined'))
        dispatch(removeRequest(id))
        return dispatch(closeModal())
      } else {
        window.flash_messages.addMessage(t('team.access_requests.failure'))
        return
      }
    })
  }
}

const changeRole = (role) => ({
  type: CHANGE_ROLE,
  role,
})

const setIsPlaceUser = (isPlaceUser) => ({
  type: SET_IS_PLACE_USER,
  isPlaceUser,
})

const toggleConsent = () => ({
  type: TOGGLE_CONSENT,
})

const setModalView = (view) => ({
  type: SET_MODAL_VIEW,
  view,
})

export {
  setAccessRequests,
  setSelectedRequest,
  approveRequest,
  declineRequest,
  changeRole,
  setIsPlaceUser,
  toggleConsent,
  setModalView,
}
