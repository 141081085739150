import React from 'react'
import PropTypes from 'prop-types'

import {
  ItemPill,
  ItemPillIcon,
  ItemPillActions,
  ItemPillActionIcon,
  ItemPillText,
} from 'Components/Base/ItemPill'

const Document = ({
  displayFileName,
  document: { document_file_name, name },
  deleteDocument,
  editDocument,
  isProcessing,
  previewDocument,
}) => (
  <ItemPill selected>
    <ItemPillIcon iconName="file" />

    <ItemPillText text={displayFileName ? document_file_name : name} data-test="display-name" />

    <ItemPillActions isLoading={isProcessing}>
      {previewDocument && <ItemPillActionIcon iconName="external-link" onClick={previewDocument} />}

      {editDocument && (
        <ItemPillActionIcon iconName="file-edit" onClick={editDocument} data-test="edit-icon" />
      )}

      {deleteDocument && (
        <ItemPillActionIcon iconName="minus-circle" onClick={deleteDocument} data-test="delete-icon" />
      )}
    </ItemPillActions>
  </ItemPill>
)

Document.propTypes = {
  deleteDocument: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  displayFileName: PropTypes.bool,
  document: PropTypes.object.isRequired,
  editDocument: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  isProcessing: PropTypes.bool,
  previewDocument: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
}

Document.defaultProps = {
  deleteDocument: false,
  displayFileName: false,
  editDocument: false,
  isProcessing: false,
  previewDocument: false,
}

export default Document
