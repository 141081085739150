import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import Icon from 'Components/Base/Icon'
import SearchInput from 'Components/Forms/SearchInput'
import DocumentList from './DocumentList'

const i18nOpts = { scope: 'care_plans.document_section.edit.library_documents' }

const UnselectedDocuments = ({
  libraryDocuments,
  libraryIsEmpty,
  onSearchChange,
  searchQuery,
  toggleSelection,
}) => {
  const emptyStateWarning = libraryIsEmpty
    ? 'library_empty'
    : searchQuery.length
    ? 'no_search_match'
    : 'all_selected'

  return (
    <Fragment>
      <h3 className="item-pill-section__header">
        <Icon name="documents-lg" className="item-pill-section__header-icon" />
        {t('unselected_section_header', i18nOpts)}
      </h3>

      {!libraryIsEmpty && (!!libraryDocuments.length || !!searchQuery.length) && (
        <div className="item-pill-section__item-list-search">
          <SearchInput onChange={onSearchChange} placeholder={t('search_placeholder', i18nOpts)} />
        </div>
      )}

      <DocumentList
        emptyStateWarning={t(emptyStateWarning, i18nOpts)}
        libraryDocuments={libraryDocuments}
        selected={false}
        toggleSelection={toggleSelection}
      />
    </Fragment>
  )
}

UnselectedDocuments.propTypes = {
  libraryDocuments: PropTypes.array,
  libraryIsEmpty: PropTypes.bool.isRequired,
  onSearchChange: PropTypes.func,
  searchQuery: PropTypes.string,
  toggleSelection: PropTypes.func.isRequired,
}

UnselectedDocuments.defaultProps = {
  libraryDocuments: [],
  onSearchChange: null,
  searchQuery: '',
}

export default UnselectedDocuments
