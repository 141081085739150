import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import getDayLabels from './getDayLabels';

import DemoLine from './DemoLine';

import { setRangeEndDate, setIsTogglingDays, setRenderedDays } from 'Actions/dashboardComparisonChart';

const i18nOpts = { scope: 'dashboard.chart' };

const ToggleButton = ({ day, isTogglingDays, renderedDays, setIsTogglingDays, setRenderedDays }) => {
  const handleClickDay = (day) => {
    if (isTogglingDays) {
      setRenderedDays({
        ...renderedDays,
        [day]: !renderedDays[day],
      });
    } else {
      setIsTogglingDays(true);
      setRenderedDays({ [day]: true });
    }
  };

  const dayName = getDayLabels(day, t, i18nOpts);

  return (
    <div onClick={() => handleClickDay(day)} className="comparison-chart__day-selector__button">
      <span className="comparison-chart__day-selector__day-label--desktop">{dayName.desktop}</span>
      <span className="comparison-chart__day-selector__day-label--mobile">{dayName.mobile}</span>
      <DemoLine day={day} isVisible={renderedDays[day]} />
    </div>
  );
};

const mapStateToProps = ({ dashboardComparisonChart: { rangeEndDate, renderedDays, isTogglingDays } }) => {
  return { rangeEndDate, renderedDays, isTogglingDays };
};

const mapDispatchToProps = (dispatch) => ({
  setRangeEndDate: (rangeEndDate) => dispatch(setRangeEndDate(rangeEndDate)),
  setIsTogglingDays: (isTogglingDays) => dispatch(setIsTogglingDays(isTogglingDays)),
  setRenderedDays: (renderedDays) => dispatch(setRenderedDays(renderedDays)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ToggleButton);
