import React from 'react'
import PropTypes from 'prop-types'

import BlankSlate from 'Components/Base/BlankSlate'
import Loader from 'Components/Base/Loader'

import EventListNavigation from './EventListNavigation'
import EventListRange from './EventListRange'
import DailyEventList from './DailyEventList'

import emptyCalendarIcon from 'calendar_empty-state.svg'

import { t } from 'i18n'

const i18nOpts = { scope: 'tasks.index.agenda' }

const EventList = ({
  current,
  events,
  hasEvents,
  isFetching,
  hasFilter,
  next,
  previous,
  firstDay,
  lastDay,
}) => (
  <section data-test="weekly-list">
    <EventListNavigation isLoading={isFetching} previous={previous} current={current} next={next}>
      <EventListRange firstDay={firstDay} lastDay={lastDay} />
    </EventListNavigation>

    {isFetching ? (
      <Loader />
    ) : hasEvents ? (
      Object.keys(events)
        .sort()
        .map((day) => <DailyEventList key={day} day={day} events={events[day]} />)
    ) : (
      <BlankSlate
        flat
        icon={<img src={emptyCalendarIcon} className='weekly-tasks__empty_calendar_icon' />}
        body={t(hasFilter ? 'filtered_blankslate' : 'blankslate', i18nOpts)}
      />
    )}
  </section>
)

EventList.propTypes = {
  current: PropTypes.func.isRequired,
  events: PropTypes.object,
  firstDay: PropTypes.string,
  hasEvents: PropTypes.bool,
  hasFilter: PropTypes.bool,
  isFetching: PropTypes.bool,
  lastDay: PropTypes.string,
  next: PropTypes.func.isRequired,
  previous: PropTypes.func.isRequired,
}

EventList.defaultProps = {
  events: {},
  firstDay: '',
  hasEvents: false,
  hasFilter: false,
  isFetching: false,
  lastDay: '',
}

export default EventList
