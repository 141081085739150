import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';
import Icon from 'Components/Base/Icon';

import { setSearchInput } from 'Actions/placeUsersPatientsIndexPage';

const i18nOpts = { scope: 'patient.index' };

const SearchFilter = ({ setSearchInput }) => {
  const [textField, setTextField] = useState('');

  useEffect(() => {
    const DEBOUNCE_DELAY = 500;

    const updateSearchInput = setTimeout(() => {
      setSearchInput(textField);
    }, DEBOUNCE_DELAY);

    return () => clearTimeout(updateSearchInput);
  }, [textField]);

  return (
    <form onSubmit={(evt) => evt.preventDefault()} >
      <fieldset className="search-input-parent">
        <input
          className="input-icon input-icon--search"
          id="search-patients"
          type="text"
          placeholder={t('search_patients_placeholder', i18nOpts)}
          aria-label={t('search_patients_aria', i18nOpts)}
          value={textField}
          onChange={(evt) => setTextField(evt.target.value)}
        />

        {textField && (
          <button className="search-reset" type="reset" onClick={() => setTextField('')}>
            <Icon name="close" />
          </button>
        )}
      </fieldset>
    </form>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setSearchInput: (searchFilter) => dispatch(setSearchInput(searchFilter)),
});

export default connect(null, mapDispatchToProps)(SearchFilter);
