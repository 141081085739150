import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import PhoneNumberApi from '../../../api/Patient/PhoneNumber'

import PhoneNumberForm from '../../Forms/PhoneNumberForm'

class PhoneNumber extends Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleErrors = this.handleErrors.bind(this)

    this.state = {
      phoneNumber: {
        number: '',
        label: 'mobile',
      },
      errors: {},
    }
  }

  handleChange({ target: { name, value } }) {
    this.setState((prevState) => _.set(prevState, name, value))
  }

  handleSubmit(evt) {
    evt.preventDefault()
    const { carePlanUuid, onSuccess } = this.props
    const { phoneNumber } = this.state

    new PhoneNumberApi(carePlanUuid).create(phoneNumber).then((res) => {
      if (res.ok) {
        res.json().then(onSuccess)
      } else if (res.status === 422) {
        res.json().then(this.handleErrors)
      }
    })
  }

  handleErrors(errors) {
    this.setState({ errors })
  }

  render() {
    const { phoneNumber, errors } = this.state
    return (
      <PhoneNumberForm
        phoneNumber={phoneNumber}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
        errors={errors}
      />
    )
  }
}

PhoneNumber.propTypes = {
  carePlanUuid: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

PhoneNumber.defaultProps = {}

export default PhoneNumber
