import React from 'react'
import PropTypes from 'prop-types'

import ReactSwitch from 'react-switch'

const Switch = ({ onChange, isChecked, label, className }) => (
  <ReactSwitch
    className={className}
    onChange={onChange}
    checked={isChecked}
    onColor="#17324d"
    offColor="#7e7e7e"
    handleDiameter={16}
    height={20}
    width={30}
    checkedIcon={false}
    uncheckedIcon={false}
    activeBoxShadow="0 0 3px 3px #17324d"
    aria-label={label}
  />
)

Switch.propTypes = {
  className: PropTypes.string,
  isChecked: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

Switch.defaultProps = {
  className: '',
  isChecked: false,
  label: '',
}

export default Switch
