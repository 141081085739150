import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Button, ModalFooter } from 'Components/Base'
import AccordionSelect from 'Components/Forms/AccordionSelect'
import RoleOption from 'Components/Team/InvitationModal/RoleOption'

import banner from 'circle-of-care-teams.svg'

const i18nOpts = { scope: 'team.acceptance_modal.role_select' }

class AccessRequestRoleSelect extends PureComponent {
  constructor(props) {
    super(props)

    this.renderButtons = this.renderButtons.bind(this)
  }

  get requireConsent() {
    return this.props.role === 'primary_caregiver' && this.props.isPlaceUser
  }

  get submitIsDisabled() {
    return !this.props.role || this.props.isSubmitting
  }

  get accessRequest() {
    return this.props.accessRequest
  }

  get roleOptionsAvailable() {
    return this.accessRequest.uhcpRequest
      ? ['unaffiliated_health_care']
      : ['primary_caregiver', 'caregiver', 'support']
  }

  renderButtons() {
    return (
      <Fragment>
        <Button text={t('decline', i18nOpts)} onClick={this.props.onDecline} className="btn btn--secondary" />
        <Button
          className="btn"
          text={t(this.requireConsent ? 'next' : 'approve', i18nOpts)}
          onClick={this.requireConsent ? this.props.onNext : this.props.onApprove}
          disabled={this.submitIsDisabled}
        />
      </Fragment>
    )
  }

  render() {
    return (
      <Fragment>
        <header className="wave-header">
          <h2 className="wave-header__title">
            {t('header_title', { ...i18nOpts, name: this.accessRequest.name })}
          </h2>
          <img src={banner} className="wave-header__banner wave-header__banner--circle" />
        </header>

        <h2 className="team-invitations-modal__header header hidden--sm hidden--vert-sm">
          {t('header_title', { ...i18nOpts, name: this.accessRequest.name })}
        </h2>

        <p className="team-invitations-modal__description">
          {t('description', { scope: 'team.modal.role' })}
        </p>

        <AccordionSelect
          onChange={this.props.onChange}
          options={this.roleOptionsAvailable}
          selected={this.props.role}
          renderOption={(role, collapsed, selected, onExpand, onSelect) => (
            <RoleOption
              role={role}
              collapsed={collapsed}
              selected={selected}
              onExpand={onExpand}
              onSelect={onSelect}
            />
          )}
        />

        <ModalFooter
          renderRight={this.renderButtons}
          renderLeft={() => (
            <Button
              text={t('cancel', i18nOpts)}
              onClick={this.props.onClose}
              className="btn btn--secondary"
            />
          )}
        />
      </Fragment>
    )
  }
}

AccessRequestRoleSelect.propTypes = {
  accessRequest: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    photo: PropTypes.string,
    preferredPhoneNumber: PropTypes.shape({
      number: PropTypes.string.isRequired,
    }),
    relationship: PropTypes.string.isRequired,
    uhcpRequest: PropTypes.bool,
  }),
  isPlaceUser: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onApprove: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
}

AccessRequestRoleSelect.defaultProps = {
  accessRequest: {},
  isPlaceUser: false,
  isSubmitting: false,
  uhcpRequest: false,
}

export default AccessRequestRoleSelect
