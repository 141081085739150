import React from 'react';
import { t } from 'i18n';
import { connect } from 'react-redux';
import Card from 'Components/Base/Card';
import AddButton from './AddButton';

const i18nOpts = { scope: 'blocks.recommendations' };

import { setEditingRecommendationIndex } from 'Actions/recommendationsBlock';
import RecommendationsContainer from './RecommendationsContainer';

const RecommendationsCard = ({ canModify, editingRecommendationIndex, setEditingRecommendationIndex }) => {
  return (
    <Card
      title={t('title', i18nOpts)}
      id="recommendations"
      renderHeaderAction={AddButton(canModify, editingRecommendationIndex, setEditingRecommendationIndex)}
      collapsable
    >
      <RecommendationsContainer />
    </Card>
  );
};

const mapStateToProps = ({
  recommendationsBlock: {
    editingRecommendationIndex,
    userDetails: { canModify },
  },
}) => {
  return { editingRecommendationIndex, canModify };
};

const mapDispatchToProps = (dispatch) => ({
  setEditingRecommendationIndex: (index) => dispatch(setEditingRecommendationIndex(index)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecommendationsCard);
