import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Receipt from './Receipt'
import Disclaimer from './Disclaimer'
import BlankMessage from './BlankMessage'

class Messages extends Component {
  constructor(props) {
    super(props)

    this.scrollToBottom = this.scrollToBottom.bind(this)

    this.setContainerRef = (element) => {
      this.containerRef = element
    }
    this.setInnerRef = (element) => {
      this.innerRef = element
    }

    this.getUnreadReceiptId = this.getUnreadReceiptId.bind(this);
  }

  componentDidMount() {
    this.scrollToBottom()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.receipts.length < this.props.receipts.length) {
      this.scrollToBottom()
    }
  }

  scrollToBottom() {
    if (this.containerRef) {
      const { height } = this.innerRef.getBoundingClientRect()
      this.containerRef.scrollTo(0, height)
    }
  }

  getUnreadReceiptId() {
    // the only time `unread_receipt_id` occurs in the URL param
    // is if the user clicks on the 'View Message' link of an unread message email
    const searchParams = new URLSearchParams(window.location.search);

    return searchParams.get('unread_receipt');
  }

  render() {
    const { receipts, disclaimer, name, noConversations, type } = this.props
    const unread_receipt_id = this.getUnreadReceiptId();

    return (
      <div className="messages">
        <div className="messages__container" ref={this.setContainerRef}>
          <div className="messages__scroll" ref={this.setInnerRef}>
            {!!receipts.length && receipts.map((r) =>
              <Receipt key={r.id} isEarliestUnreadReceipt={r.id == unread_receipt_id} {...r} />)}
            {disclaimer && <Disclaimer disclaimer={disclaimer} />}
            {!receipts.length && (
              <BlankMessage disclaimer={disclaimer} type={type} name={name} noConversations={noConversations} />
            )}
          </div>
        </div>
      </div>
    )
  }
}

Messages.propTypes = {
  disclaimer: PropTypes.string,
  name: PropTypes.string,
  noConversations: PropTypes.bool,
  receipts: PropTypes.array,
  type: PropTypes.string,
}

Messages.defaultProps = {
  disclaimer: '',
  name: '',
  noConversations: false,
  receipts: [],
  type: '',
}

export default Messages
