import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from 'Components/Base/Icon';
import Dropdown from './Dropdown';
import buttonText from './buttonText';

import { setIsFilterByStatusDropdownOpen } from 'Actions/carePlanPlaceInvitationsPage';

const SignupsFilterByStatus = ({ input, isDropdownOpen, setIsFilterByStatusDropdownOpen }) => {
  return (
    <div
      className="signups__status-filter"
      onClick={() => {
        setIsFilterByStatusDropdownOpen(!isDropdownOpen);
      }}
    >
      {buttonText(input)}

      <Icon name="carrot-down" />

      {isDropdownOpen && <Dropdown />}
    </div>
  );
};

const mapStateToProps = ({ carePlanPlaceInvitationsPage }) => {
  const {
    filterByStatusDropdown: { input, isDropdownOpen },
  } = carePlanPlaceInvitationsPage;

  return {
    input,
    isDropdownOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetIsFilterByStatusDropdownOpen = (dispatch) => {
    return (isDropdownOpen) => {
      dispatch(setIsFilterByStatusDropdownOpen(isDropdownOpen));
    };
  };

  return {
    setIsFilterByStatusDropdownOpen: dispatchSetIsFilterByStatusDropdownOpen(dispatch),
  };
};

SignupsFilterByStatus.propTypes = {
  input: PropTypes.string.isRequired,
  isDropdownOpen: PropTypes.bool.isRequired,
  setIsFilterByStatusDropdownOpen: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupsFilterByStatus);
