import React from 'react';
import PropTypes from 'prop-types';
import XAxisTickFormatter from '../XAxisTickFormatter';
import RenderNoDataPoint from '../RenderNoDataPoint';

import { BarChart, Bar, ResponsiveContainer, XAxis } from 'recharts';

const Chart = ({ data }) => {
  return (
    <div className="chart symptom-intensity">
      <ResponsiveContainer width="100%" height={175}>
        <BarChart height={40} data={data} barCategoryGap="12%">
          {data.map(({ data }, index) => (
            <Bar
              key={index}
              data={data}
              dataKey="displayValue"
              name="name"
              fill="fill"
              className="barchart bar"
              label={(args) => <RenderNoDataPoint {...args} data={data} y={75} />}
            />
          ))}
          <XAxis
            dataKey="day"
            type="category"
            allowDuplicatedCategory={false}
            axisLine={false}
            tickLine={false}
            interval={0}
            tick={<XAxisTickFormatter />}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

Chart.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Chart;
