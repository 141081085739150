import React from 'react'
import PropTypes from 'prop-types'

import Button from 'Components/Base/Button'
import { Footer } from 'Components/Base/Card/'
import { t } from 'i18n'

const SectionFooter = ({ children, isSubmitting, onDone }) => {
  const i18nOpts = { scope: 'care_plans.document_section.edit' }
  const doneButtonClass = isSubmitting ? 'btn btn--disabled' : 'btn'

  return (
    <Footer
      left={children}
      right={<Button text={t('done', i18nOpts)} className={doneButtonClass} onClick={onDone} />}
    />
  )
}

SectionFooter.propTypes = {
  children: PropTypes.any,
  isSubmitting: PropTypes.bool.isRequired,
  onDone: PropTypes.func.isRequired,
}

export default SectionFooter
