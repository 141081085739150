import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import 'regenerator-runtime/runtime';
import formatUnixTime from './../formatUnixTime';
import GraphErrorMessage from './../GraphErrorMessage';
import { TooltipLegend } from './../Legend';

import {
  ResponsiveContainer,
  ReferenceArea,
  ReferenceLine,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Label,
  ScatterChart,
  Scatter,
  Cell,
} from 'recharts';

import {
  CARTESIAN_GRID_COLOR,
  CHART_DOMAIN,
  CHART_TICKS,
  CHART_HEIGHT,
  CHART_LABEL_INNER_HEIGHT,
  TARGET_RANGE_UPPER_BOUND,
  TARGET_RANGE_LOWER_BOUND,
  REFERENCE_LINE_COLOR,
  Y_AXIS_TICK_COUNT,
  Y_AXIS_DOMAIN,
  DATA_COLOR,
  DATA_FOCUS_COLOR,
} from './../constants';

const i18nOpts = { scope: 'dashboard.chart' };

const ScatterPlot = ({ data, error }) => {
  const [focusCell, setFocusCell] = useState(null);

  return (
    <div className="text-normal">
      <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
        <ScatterChart width="100%" height="100%" margin={{ left: -12, bottom: 12, top: 40 }}>
          <CartesianGrid stroke={CARTESIAN_GRID_COLOR} />
          <XAxis
            dataKey="time"
            name="Time"
            tickFormatter={formatUnixTime}
            type="number"
            height={CHART_LABEL_INNER_HEIGHT}
            domain={CHART_DOMAIN}
            ticks={CHART_TICKS}
          >
            <Label position="insideBottom" offset={0}>
              {t('time_of_day', i18nOpts)}
            </Label>
          </XAxis>
          <YAxis dataKey="glucoseValue" tickCount={Y_AXIS_TICK_COUNT} domain={Y_AXIS_DOMAIN} type="number">
            <Label angle={270} position="insideLeft" style={{ textAnchor: 'middle' }} offset={18}>
              {t('glucose', i18nOpts)}
            </Label>
          </YAxis>

          {/* Do not update ReferenceArea and ReferenceLine into a single fragment
            until recharts library has been updated to a React Fragment supporting version */}
          {data?.length && (
            <ReferenceArea
              y1={TARGET_RANGE_LOWER_BOUND}
              y2={TARGET_RANGE_UPPER_BOUND}
              fill={REFERENCE_LINE_COLOR}
              fillOpacity={0.1}
            />
          )}
          {data?.length && <ReferenceLine y={TARGET_RANGE_UPPER_BOUND} stroke={REFERENCE_LINE_COLOR} />}
          {data?.length && <ReferenceLine y={TARGET_RANGE_LOWER_BOUND} stroke={REFERENCE_LINE_COLOR} />}
          {data?.length && <Tooltip content={<TooltipLegend hasTarget={false} isIconsDisplayed={false} />} />}

          <Scatter
            name="glucoseChart"
            data={data}
            fill={DATA_COLOR}
            onMouseOver={(e) => setFocusCell(e.payload)}
            onMouseOut={() => setFocusCell(null)}
          >
            {data.map((entry, index) => {
              const matched = focusCell === entry;
              return (
                <Cell
                  key={`cell-${index}`}
                  fill={matched ? DATA_FOCUS_COLOR : DATA_COLOR}
                  stroke={matched ? DATA_FOCUS_COLOR : null}
                  strokeWidth={matched ? 4 : 0}
                />
              );
            })}
          </Scatter>
        </ScatterChart>
      </ResponsiveContainer>
      {error && <GraphErrorMessage status={error} />}
    </div>
  );
};

ScatterPlot.propTypes = {
  data: PropTypes.array,
  error: PropTypes.string,
  isChartDataLoaded: PropTypes.bool,
  timeRange: PropTypes.string.isRequired,
};

ScatterPlot.defaultProps = {
  data: [],
  error: '',
  isChartDataLoaded: false,
};

export default ScatterPlot;
