import calculateFill from './calculateFill';

const getRenderData = (data) => {
  const renderData = data.reduce(
    (acc, { name, data }) => {
      const maxValue = Math.max(...data.map((d) => d.value));
      if (maxValue > acc.maxValue) {
        acc.maxValue = maxValue;
      }

      const dataWithFill = data.map(({ day, value, event_occurred }) => ({
        day,
        value,
        eventOccurred: event_occurred,
        fill: calculateFill(name),
      }));

      return {
        ...acc,
        results: [...acc.results, { name, data: dataWithFill }],
      };
    },
    { results: [], maxValue: 0 }
  );

  const eventsOccurred = data[0].data.map((d) => {
    if (d.value === '') {
      return null;
    } else {
      return d.event_occurred;
    }
  });

  return { ...renderData, eventsOccurred };
};

export default getRenderData;
