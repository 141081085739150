import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Chip } from 'Components/Base'
const i18nOpts = { scope: 'care_plan_place_invitations.index' }

const Status = ({ status }) => {
  let color
  switch (status) {
    case 'pending':
      color = 'blue-foam'
      break
    case 'declined':
      color = 'light-grey'
      break
    case 'accepted':
      color = 'accepted-grey'
      break
    default:
      color = 'light-grey'
  }

  return (
    <div className="invitation__chip">
      <Chip color={color} text={t(`status.${status}`, i18nOpts)} />
    </div>
  )
}

Status.propTypes = {
  status: PropTypes.oneOf(['accepted', 'pending', 'declined']).isRequired,
}
export default Status
