import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';
import 'regenerator-runtime/runtime';

import GraphErrorMessage from '../../GraphErrorMessage';

import transformData from '../../transformData';
import formatUnixTime from '../../formatUnixTime';
import selectAndTransformChartData from './selectAndTransformChartData';
import isChartDataEmpty from './isChartDataEmpty';

import {
  ResponsiveContainer,
  ReferenceLine,
  ReferenceArea,
  // Area,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  // Tooltip,
  Label,
  ComposedChart,
} from 'recharts';

import {
  CHART_DOMAIN,
  CHART_TICKS,
  CHART_HEIGHT,
  CHART_LABEL_INNER_HEIGHT,
  Y_AXIS_TICK_COUNT,
  Y_AXIS_DOMAIN,
  TARGET_RANGE_UPPER_BOUND,
  TARGET_RANGE_LOWER_BOUND,
  REFERENCE_LINE_COLOR,
  CARTESIAN_GRID_COLOR,
  comparisonChartStyles,
} from '../../constants';

const i18nOpts = { scope: 'dashboard.chart' };

const LineChart = ({ chartData, chartError, rangeEndDate, renderedDays, weekOrder }) => {
  const chartDataForTimeRange = useMemo(
    () => selectAndTransformChartData(chartData, rangeEndDate, transformData),
    [chartData, rangeEndDate, transformData]
  );

  const isChartEmpty = isChartDataEmpty(chartDataForTimeRange, weekOrder, renderedDays)
  const comparisonChartError = isChartEmpty && !chartError ? 'EMPTY_COMPARISON_CHART_DATA' : chartError;

  return (
    <div className="text-normal">
      <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
        <ComposedChart margin={{ left: -12, bottom: 12 }}>
          <CartesianGrid stroke={CARTESIAN_GRID_COLOR} />
          <XAxis
            dataKey={'time'}
            tickFormatter={formatUnixTime}
            type="number"
            height={CHART_LABEL_INNER_HEIGHT}
            domain={CHART_DOMAIN}
            ticks={CHART_TICKS}
          >
            <Label position="insideBottom" offset={0}>
              {t('time_of_day', i18nOpts)}
            </Label>
          </XAxis>

          <YAxis tickCount={Y_AXIS_TICK_COUNT} domain={Y_AXIS_DOMAIN} type="number">
            <Label angle={270} position="insideLeft" style={{ textAnchor: 'middle' }} offset={18}>
              {t('glucose', i18nOpts)}
            </Label>
          </YAxis>

          {/* Do not update ReferenceArea and ReferenceLine into a single fragment
            until recharts library has been updated to a React Fragment supporting version */}
          {!isChartEmpty && (
            <ReferenceArea
              y1={TARGET_RANGE_LOWER_BOUND}
              y2={TARGET_RANGE_UPPER_BOUND}
              fill={REFERENCE_LINE_COLOR}
              fillOpacity={0.1}
            />
          )}
          {!isChartEmpty && <ReferenceLine y={TARGET_RANGE_UPPER_BOUND} stroke={REFERENCE_LINE_COLOR} />}
          {!isChartEmpty && <ReferenceLine y={TARGET_RANGE_LOWER_BOUND} stroke={REFERENCE_LINE_COLOR} />}

          {weekOrder.map((day, index) => {
            const isRendered = renderedDays[day];

            if (!isRendered) return null;

            const dataForDayOfWeek = chartDataForTimeRange[index];

            return (
              <Line
                key={`${day}-line`}
                data={dataForDayOfWeek}
                strokeDasharray={comparisonChartStyles[day].dashPattern}
                stroke={comparisonChartStyles[day].color}
                dot={false}
                dataKey="glucoseValue"
                strokeWidth={2}
                type="linear"
                isAnimationActive={false}
              />
            );
          })}
        </ComposedChart>
      </ResponsiveContainer>

      {comparisonChartError && <GraphErrorMessage status={comparisonChartError} />}
    </div>
  );
};

const mapStateToProps = ({
  dashboardComparisonChart: { rangeEndDate, renderedDays, weekOrder, chartData, chartError },
}) => {
  return {
    rangeEndDate,
    renderedDays,
    weekOrder,
    chartData,
    chartError,
  };
};

export default connect(mapStateToProps, null)(LineChart);
