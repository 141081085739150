import {
  SET_API_ERROR,
  SET_CURRENT_PAGE_NUMBER,
  SET_CURRENT_SEARCH_INPUT,
  SET_CURRENT_TAB,
  SET_FILTERED_SIGNUPS,
  SET_FILTER_BY_STATUS_DROPDOWN_INPUT,
  SET_IS_FILTER_BY_STATUS_DROPDOWN_OPEN,
  SET_IS_TARGET_SIGNUP_MODAL_OPEN,
  SET_PAGE_STATE,
  SET_SIGNUPS,
  SET_TARGET_SIGNUP_MODAL_API_ERROR,
  SET_TARGET_SIGNUP_MODAL_STATUS,
  SET_TARGET_SIGNUP_MODAL_TARGET,
} from 'Actions/carePlanPlaceInvitationsPage';

const initialState = {
  apiError: null,
  currentTab: 'CarePlanPlaceInvitation',
  currentSearchInput: '',
  filterByStatusDropdown: {
    input: 'all',
    isDropdownOpen: false,
  },
  filteredSignups: [],
  pagination: {
    buttonCount: 5,
    currentPageNumber: 1,
    maxItemCountPerPage: 12,
    totalPageCount: 0,
  },
  pageState: 'loading',
  signups: [],
  signupsInCurrentPage: [],
  targetSignupModal: {
    target: {
      carePlanTitle: '',
      carePlanUuid: '',
      caregiverEmail: '',
      caregiverName: '',
      entityType: '',
      id: 0,
      patientName: '',
      patientPhoto: '',
      placeName: '',
      resolved: '',
      status: '',
      submitted: '',
    },
    isOpen: false,
    status: 'displayingTargetSignup',
    apiError: null,
  },
};

const carePlanPlaceInvitationsPage = (state = initialState, action) => {
  switch (action.type) {
    case SET_API_ERROR:
      return {
        ...state,
        apiError: action.apiError,
      };

    case SET_CURRENT_PAGE_NUMBER:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPageNumber: action.pageNumber,
        },
      };

    case SET_CURRENT_SEARCH_INPUT:
      return {
        ...state,
        currentSearchInput: action.input,
      };

    case SET_CURRENT_TAB:
      return {
        ...state,
        currentTab: action.currentTab,
      };

    case SET_FILTERED_SIGNUPS:
      return {
        ...state,
        filteredSignups: action.signups,
      };

    case SET_FILTER_BY_STATUS_DROPDOWN_INPUT:
      return {
        ...state,
        filterByStatusDropdown: {
          ...state.filterByStatusDropdown,
          input: action.input,
        },
      };

    case SET_IS_FILTER_BY_STATUS_DROPDOWN_OPEN:
      return {
        ...state,
        filterByStatusDropdown: {
          ...state.filterByStatusDropdown,
          isDropdownOpen: action.isDropdownOpen,
        },
      };

    case SET_IS_TARGET_SIGNUP_MODAL_OPEN:
      return {
        ...state,
        targetSignupModal: {
          ...state.targetSignupModal,
          isOpen: action.isOpen,
        },
      };

    case SET_PAGE_STATE:
      return {
        ...state,
        pageState: action.pageState,
      };

    case SET_SIGNUPS:
      return {
        ...state,
        filteredSignups: action.signups,
        signups: action.signups,
      };

    case SET_TARGET_SIGNUP_MODAL_API_ERROR:
      return {
        ...state,
        targetSignupModal: {
          ...state.targetSignupModal,
          apiError: action.apiError,
        },
      };

    case SET_TARGET_SIGNUP_MODAL_STATUS:
      return {
        ...state,
        targetSignupModal: {
          ...state.targetSignupModal,
          status: action.status,
        },
      };

    case SET_TARGET_SIGNUP_MODAL_TARGET:
      return {
        ...state,
        targetSignupModal: {
          ...state.targetSignupModal,
          target: action.target,
        },
      };

    default:
      return state;
  }
};

export default carePlanPlaceInvitationsPage;
