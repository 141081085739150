import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'Components/Base/Icon'
import { t } from 'i18n'

const i18nOpts = { scope: 'care_plans.generic_order_section.generic_order.repeated_task' }

const RepeatedActivity = ({ name, localizedFrequency, until, formattedTime }) => (
  <li className="order order__repeat">
    <div className="order__repeat-icon">
      <strong>
        <Icon className="icon--medium" name="refresh" />
      </strong>
    </div>
    <div className="order__repeat-text">
      <div>
        <strong>{name}</strong>
      </div>
      <span>
        {until
          ? t('repeat_statement', { ...i18nOpts, localizedFrequency, until, formattedTime })
          : t('repeat_statement_infinity', { ...i18nOpts, localizedFrequency, formattedTime })}
      </span>
    </div>
  </li>
)

RepeatedActivity.propTypes = {
  formattedTime: PropTypes.string.isRequired,
  localizedFrequency: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  until: PropTypes.string,
}

RepeatedActivity.defaultProps = {
  until: '',
}

export default RepeatedActivity
