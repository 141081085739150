// 02:00 through 22:00 in unix time
export const CHART_TICKS = [36000, 43200, 50400, 57600, 64800, 72000, 79200, 86400, 93600, 100800, 108000];

// 00:00 through 24:00 in unix time
export const CHART_DOMAIN = [28800, 115200];

export const CHART_HEIGHT = 420;
export const CHART_LABEL_INNER_HEIGHT = 50;
export const X_AXIS_TICK_COUNT = 12;
export const Y_AXIS_TICK_COUNT = 8;
export const Y_AXIS_DOMAIN = [0, 21];
export const TARGET_RANGE_UPPER_BOUND = 10;
export const TARGET_RANGE_LOWER_BOUND = 4;

export const CARTESIAN_GRID_COLOR = '#CCCCCC';
export const INNER_80_PCT_STROKE_COLOR = '#e30712';
export const INNER_50_PCT_STROKE_COLOR = '#ffac40';
export const AVG_GLUCOSE_STROKE_COLOR = '#56a701';
export const REFERENCE_LINE_COLOR = '#3dadd6';
export const DATA_COLOR = '#007FAE';
export const DATA_FOCUS_COLOR = '#003B69';

export const comparisonChartStyles = {
  sun: {
    color: '#ED9F0E',
    dashPattern: '8 3',
  },
  mon: {
    color: '#E02E81',
    dashPattern: '2 2',
  },
  tue: {
    color: '#56A701',
    dashPattern: '10 2',
  },
  wed: {
    color: '#007FAE',
    dashPattern: '15 2',
  },
  thu: {
    color: '#003B69',
    dashPattern: '30 3',
  },
  fri: {
    color: '#71D1CE',
    dashPattern: '45 2',
  },
  sat: {
    color: '#A383E2',
    dashPattern: '',
  },
  disabled: {
    color: '#DDDDDD',
    dashPattern: '',
  },
};
