import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import Backdrop from 'Components/Base/Backdrop'
const i18nOpts = { scope: 'activities.kind' }

const OrderDropbox = ({ referralsEnabled, openModal, toggle }) => (
  <div className="dropdown careplan-wizard__dropdown">
    <div className=" dropdown__menu">
      {(referralsEnabled ? ['medical', 'generic_health', 'referral'] : ['medical', 'generic_health']).map(
        (key) => (
          <div className="dropdown__item" key={key} onClick={() => openModal(key)}>
            {t(key, i18nOpts)}
          </div>
        )
      )}
    </div>
    <Backdrop transparent onClick={toggle} />
  </div>
)

OrderDropbox.propTypes = {
  openModal: PropTypes.func.isRequired,
  referralsEnabled: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
}

OrderDropbox.defaultProps = {
  referralsEnabled: false,
}

export default OrderDropbox
