import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Button } from 'Components/Base'
import MultipleChoiceQuestionOption from './MultipleChoiceQuestionOption'

const i18nOpts = { scope: 'care_plan_creators.check_in_templates.questions' }

class MultipleChoiceQuestion extends Component {
  constructor(props) {
    super(props)

    this.renderOption = this.renderOption.bind(this)
    this.buildQuestionOptions = this.buildQuestionOptions.bind(this)
    this.handleAddOption = this.handleAddOption.bind(this)
    this.removeOption = this.removeOption.bind(this)
    this.updateOptions = this.updateOptions.bind(this)

    let questionOptions = props.checkInQuestionOptions || []
    const count = this.props.minimumCount

    if (_.isEmpty(questionOptions)) {
      questionOptions = this.buildQuestionOptions(count)
      this.props.onChange({ target: { name: 'checkInQuestionOptions', value: questionOptions } })
    }

    this.state = {
      count,
      errors: {},
      questionOptions,
    }
  }

  buildQuestionOptions(maxValue) {
    const _this = this
    return _.map(_.range(1, maxValue + 1), (index) => {
      return _this.buildBlankOption(index - 1)
    })
  }

  buildBlankOption(index) {
    return {
      type: 'ScaleBasedOption',
      data: {
        text: '',
        description: '',
        response: t('default_response', i18nOpts),
        index,
        order: index,
        score: index + 1,
      },
    }
  }

  handleAddOption() {
    const { count, questionOptions } = this.state
    this.setState({ count: count + 1, questionOptions: [...questionOptions, this.buildBlankOption(count)] })
  }

  removeOption(option) {
    const { count, questionOptions } = this.state
    const filteredOptions = questionOptions.filter((opt) => opt.data.score !== option)
    const newOptions = filteredOptions.map((opt, index) => {
      opt.data = {
        ...opt.data,
        index,
        order: index,
        score: index + 1,
      }
      return opt
    })

    this.setState({ count: count - 1, questionOptions: newOptions })
    this.props.onChange({ target: { name: 'checkInQuestionOptions', value: questionOptions } })
  }

  updateOptions(optionValues) {
    const index = optionValues.index
    const { questionOptions } = this.state

    const questionOption = _.find(questionOptions, (q) => {
      return q.data.index === index
    })

    Object.assign(questionOption.data, optionValues)
    this.setState({ questionOptions })
    this.props.onChange({ target: { name: 'checkInQuestionOptions', value: questionOptions } })
  }

  renderOption(questionOption, index) {
    return (
      <MultipleChoiceQuestionOption
        isActive={this.props.isActive}
        key={index}
        questionOption={questionOption}
        renderConditionalResponse={this.props.renderConditionalResponse}
        updateOptions={this.updateOptions}
        removeOption={this.removeOption}
        errors={this.props.errors}
        editing={this.props.editing}
      />
    )
  }

  render() {
    const { questionOptions } = this.state

    return (
      <Fragment>
        {questionOptions.map(this.renderOption)}
        {questionOptions.length < this.props.maximumCount && !this.props.editing ? (
          <div className="multiple-choice-button">
            <Button
              onClick={this.handleAddOption}
              className="btn btn--secondary"
              text={t('add_response', i18nOpts)}
            />
          </div>
        ) : null}
      </Fragment>
    )
  }
}

MultipleChoiceQuestion.propTypes = {
  checkInQuestionOptions: PropTypes.array,
  editing: PropTypes.bool,
  errors: PropTypes.object,
  isActive: PropTypes.bool,
  maximumCount: PropTypes.number,
  minimumCount: PropTypes.number,
  onChange: PropTypes.func,
  renderConditionalResponse: PropTypes.func.isRequired,
}

MultipleChoiceQuestion.defaultProps = {
  checkInQuestionOptions: [],
  editing: false,
  errors: {},
  isActive: false,
  maximumCount: 10,
  minimumCount: 2,
  onChange: null,
}

export default MultipleChoiceQuestion
