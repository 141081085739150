import React from 'react'
import PropTypes from 'prop-types'

import { Chip } from 'Components/Base'

const ReferralProvider = ({ provider }) =>
  provider ? (
    <div className="row__col row__col--fixed">
      <Chip color="light-grey" text={provider} />
    </div>
  ) : null

ReferralProvider.propTypes = {
  provider: PropTypes.string,
}

ReferralProvider.defaultProps = {
  provider: null,
}

export default ReferralProvider
