import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import Select from 'Components/Forms/Select'
import Option from 'Components/Forms/Option'
import { Icon, Tooltip } from 'Components/Base'

const scope = 'tasks.index.filters'

const Filters = ({ onChange, value, healthCareView }) => (
  <Tooltip text={t('heading', { scope })} className="select-header__select select-container" position="left">
    <Fragment>
      <div className="select-container__icon tasks-filter">
        <Icon name="filter" />
      </div>

      <Select onChange={onChange} value={value} className="select-container__select">
        <Option option="all" scope={scope} />
        {healthCareView ? (
          <Fragment>
            <Option option="accepted" scope={scope} />
            <Option option="pending" scope={scope} />
          </Fragment>
        ) : (
          <Fragment>
            <Option option="assigned" scope={scope} />
            <Option option="unassigned" scope={scope} />
            <Option option="mine" scope={scope} />
          </Fragment>
        )}
      </Select>
    </Fragment>
  </Tooltip>
)

Filters.propTypes = {
  healthCareView: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
}

Filters.defaultProps = {
  healthCareView: false,
  value: 'all',
}

export default Filters
