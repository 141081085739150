import AdHocResourcesApi from 'Api/CarePlan/AdHocResources';
import AdHocResourceTemplatesApi from 'Api/TemplateCreator/AdHocResourceTemplates';

class AdHocResourceDispatcher {
  constructor(resourceId, isTemplate, id = null) {
    this.resourceId = resourceId;
    this.id = id;
    this.isTemplate = isTemplate;
  }

  create(data) {
    return this.api.create(data);
  }

  update(data) {
    return this.api.update(data);
  }

  destroy() {
    return this.api.destroy();
  }

  get api() {
    if (this.isTemplate) {
      return new AdHocResourceTemplatesApi(this.resourceId, this.id);
    } else {
      return new AdHocResourcesApi(this.resourceId, this.id);
    }
  }
}

export default AdHocResourceDispatcher;
