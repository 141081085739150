import React, { Component } from 'react'
import _ from 'lodash'
import { t } from 'i18n'
import PropTypes from 'prop-types'

import PainScaleQuestionOption from './PainScaleQuestionOption'

const i18nOpts = { scope: 'care_plan_creators.check_in_templates.questions' }
const defaultMinValue = 1
const defaultMaxValue = 5

const iconNameMap = {
  0: 'no-pain',
  1: 'mild-pain',
  2: 'moderate-pain',
  3: 'severe-pain',
  4: 'worst-pain-possible',
}

const colorCodeMap = {
  0: '#6B6BED',
  1: '#1085C6',
  2: '#31A829',
  3: '#EB6A00',
  4: '#DB0000',
}

class PainScaleQuestion extends Component {
  constructor(props) {
    super(props)
    this.renderOption = this.renderOption.bind(this)
    this.buildQuestionOptions = this.buildQuestionOptions.bind(this)
    this.updateOptions = this.updateOptions.bind(this)

    let questionOptions = props.checkInQuestionOptions || []

    if (_.isEmpty(questionOptions)) {
      questionOptions = this.buildQuestionOptions(defaultMinValue, defaultMaxValue)
      this.props.onChange({ target: { name: 'checkInQuestionOptions', value: questionOptions } })
    }

    this.state = {
      errors: {},
      questionOptions,
    }
  }

  buildQuestionOptions(minValue, maxValue) {
    return _.range(minValue, maxValue + 1).map((index) => this.buildBlankOption(index - 1))
  }

  buildBlankOption(index) {
    return {
      type: 'ScaleBasedOption',
      data: {
        text: '',
        description: '',
        response: t('default_response', i18nOpts),
        index,
        order: index,
        score: index + 1,
        iconName: iconNameMap[index],
        colorCode: colorCodeMap[index],
      },
    }
  }

  updateOptions(optionValues) {
    const index = optionValues.index
    const { questionOptions } = this.state
    const questionOption = _.find(questionOptions, (q) => q.data.index === index)

    Object.assign(questionOption.data, optionValues)
    this.setState({ questionOptions })
    this.props.onChange({ target: { name: 'checkInQuestionOptions', value: questionOptions } })
  }

  renderOption(questionOption, index) {
    return (
      <PainScaleQuestionOption
        isActive={this.props.isActive}
        renderConditionalResponse={this.props.renderConditionalResponse}
        key={index}
        questionOption={questionOption}
        updateOptions={this.updateOptions}
      />
    )
  }

  render() {
    const { questionOptions } = this.state
    return questionOptions.map(this.renderOption)
  }
}

PainScaleQuestion.propTypes = {
  checkInQuestionOptions: PropTypes.array,
  isActive: PropTypes.bool,
  onChange: PropTypes.func,
  renderConditionalResponse: PropTypes.func.isRequired,
}

PainScaleQuestion.defaultProps = {
  checkInQuestionOptions: [],
  isActive: false,
  onChange: null,
}

export default PainScaleQuestion
