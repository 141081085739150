import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from 'Components/Base'
import { Activity, ActivityBody, AppointmentDetails, AssignmentsWithDueAt } from '../'

import { redirectTo } from 'Utilities/linkHelpers'

const RepeatedAppointment = ({ dueAt, practitioner, text, link, assignments }) => (
  <Activity onClick={redirectTo.bind(null, link)}>
    <div className="row__col row__col--fixed">
      <Icon className="icon--large" name="calendar" />
    </div>

    <ActivityBody text={text}>
      <AppointmentDetails practitioner={practitioner} />
    </ActivityBody>

    <AssignmentsWithDueAt assignments={assignments} dueAt={dueAt} repeated />
  </Activity>
)

RepeatedAppointment.propTypes = {
  assignments: PropTypes.array,
  dueAt: PropTypes.string.isRequired,
  link: PropTypes.string,
  practitioner: PropTypes.string,
  text: PropTypes.string.isRequired,
}

RepeatedAppointment.defaultProps = {
  assignments: [],
  link: null,
  practitioner: null,
}

export default RepeatedAppointment
