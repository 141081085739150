import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import InputGroup from 'Components/Forms/InputGroup'

const i18nOpts = { scope: 'patient.check_in_score' }

const TextOption = ({ onClick, errors, maximum, userInput }) => {
  const isError = Object.keys(errors).length

  return (
    <div className="ReactModal__CheckIn--QuestionOption--text-container">
      <div className={`ReactModal__CheckIn--QuestionOption--text${isError ? ' error-border' : ''}`}>
        <InputGroup
          component="input"
          name="userInput"
          placeholder={t('placeholder', i18nOpts)}
          value={userInput}
          onChange={onClick}
          required
          maxLength={maximum}
          className="ReactModal__CheckIn--QuestionOption--text-input"
          type="text"
          min={1}
          max={maximum}
        />
      </div>
      <span>{t('remaining', { ...i18nOpts, val: maximum - userInput.length })}</span>
    </div>
  )
}

TextOption.propTypes = {
  errors: PropTypes.object,
  maximum: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  userInput: PropTypes.string,
}

TextOption.defaultProps = {
  errors: {},
  maximum: 150,
  userInput: '',
}

export default TextOption
