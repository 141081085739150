import Api from './Api'

class TeamMembership extends Api {
  constructor(carePlanId) {
    super()

    this.carePlanId = carePlanId
  }

  batchCreate(team_memberships) {
    return this.request(`/care_plans/${this.carePlanId}/team/batch_create`, 'POST', { team_memberships })
  }

  create({ role, relationship, email, permission }) {
    return super.create({
      team_membership: {
        role,
        relationship,
        permission,
        invitation_attributes: { email },
      },
    })
  }

  update({ id, role, relationship }) {
    this.id = id
    return super.update({
      role,
      relationship,
    })
  }

  get url() {
    return `/care_plans/${this.carePlanId}/team`
  }
}

export default TeamMembership
