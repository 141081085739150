import React from 'react'
import PropTypes from 'prop-types'

import {
  ItemPill,
  ItemPillIcon,
  ItemPillActions,
  ItemPillActionLink,
  ItemPillText,
} from 'Components/Base/ItemPill'

const Document = ({ document: { name, url, downloadUrl } }) => (
  <ItemPill selected>
    <ItemPillIcon iconName="file" />

    <ItemPillText text={name} />

    <ItemPillActions>
      <ItemPillActionLink link={downloadUrl} iconName="document-download" data-test="download-url" />
      <ItemPillActionLink link={url} iconName="external-link" target="_blank" data-test="preview-url" />
    </ItemPillActions>
  </ItemPill>
)

Document.propTypes = {
  document: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    downloadUrl: PropTypes.string,
  }).isRequired,
}

export default Document
