import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Button, Icon } from 'Components/Base/'

import CustomHighlight from 'Components/Base/Highlight/CustomHighlight'

const i18nOpts = { scope: 'base.action_headers' }

const EditActionHeaderWithCareHub = ({ onClick }) => (
  <div className="card__header-profile_sub_section">
    <CustomHighlight type="success" message={t('carehub_sync', i18nOpts)} icon="sync" />
    <div className="card__header-vertical-line"></div>
    <Button type="button" className="btn--link card__header-link" onClick={onClick}>
      <Icon name="pencil-edit_alt" className="card__header-link-icon" />
      <span>{t('edit', i18nOpts)}</span>
    </Button>
  </div>
)

EditActionHeaderWithCareHub.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default EditActionHeaderWithCareHub
