import React, { Component } from 'react'
import { t } from 'i18n'

import InputGroup from 'Components/Forms/InputGroup'
import FormField from 'Components/Forms/FormField';
import { onAssignmentChange } from 'Utilities/utilities'
import AddressForm from 'Components/Forms/AddressForm'
import { RequestButton } from 'Components/HelpRequest'
import RecurrenceInput from '../RecurrenceInput'
import TemplateRecurrenceInput from '../TemplateRecurrenceInput'

const i18nOpts = {
  scope: 'care_plans.medical_order_section.medical_order_form',
}

class AppointmentFields extends Component {
  constructor(props) {
    super(props)
    this.renderVisitationUrl = this.renderVisitationUrl.bind(this)
    this.renderDueAt = this.renderDueAt.bind(this)
    this.renderAddress = this.renderAddress.bind(this)
    this.renderAssignments = this.renderAssignments.bind(this)
  }

  get defaultAddress() {
    return this.props.address === null
      ? {
          address: '',
          city: '',
          region: '',
          extendedAddress: '',
          postalCode: '',
          countryCode: '',
        }
      : this.props.address
  }

  renderVisitationUrl() {
    const { url, handleChange, errors } = this.props

    return (
      <FormField
        name="url"
        label={t('virtual_visit', i18nOpts)}
        value={url}
        onChange={handleChange}
        formErrors={errors}
      />
    )
  }

  renderDueAt() {
    const { isEdit, isTemplate, dueAt, errors, timeZone, handleChange } = this.props

    return isTemplate ? (
      <TemplateRecurrenceInput {...this.props} />
    ) : isEdit ? (
      <InputGroup
        name="dueAt"
        value={dueAt}
        label={t('time', i18nOpts)}
        errors={errors}
        timeZone={timeZone}
        onChange={handleChange}
        component="datetime"
        required
      />
    ) : (
      <RecurrenceInput {...this.props} />
    )
  }

  renderAddress() {
    const { errors, handleChange, locale } = this.props

    return (
      <>
        <section className="activity_modal__address_header_label">
          <label>{t('address', i18nOpts)}</label>
        </section>
        <AddressForm className="activity_modal" address={this.defaultAddress} name='address' locale={locale} onChange={handleChange} errors={errors} />
      </>
    )
  }

  renderAssignments() {
    const {
      assignments,
      assignmentOptions,
      handleChange,
      errors,
      frequencyType,
      isTemplate,
      volunteersEnabled,
      helpRequested,
      onOpenModal,
    } = this.props
    return !isTemplate ? (
      <div className="form__row">
        <InputGroup
          name="assignments"
          component="multi-select"
          label={t('assignments', i18nOpts)}
          value={assignments}
          options={assignmentOptions}
          onChange={onAssignmentChange(handleChange)}
          errors={errors}
          className={`input ${volunteersEnabled && 'input--volunteers-enabled'}`}
        />
        {volunteersEnabled && frequencyType !== 'recurring' && (
          <RequestButton helpRequested={helpRequested} action={onOpenModal} />
        )}
      </div>
    ) : null
  }

  render() {
    return this.props.isEdit ? (
      this.props.isTemplate ? (
        <>
          {this.renderVisitationUrl()}
          {this.renderDueAt()}
          {this.renderAddress()}
        </>
      ) : (
        <>
          {this.renderVisitationUrl()}
          {this.renderDueAt()}
          {this.renderAddress()}
          {this.renderAssignments()}
        </>
      )
    ) : (
      <>
        {this.renderDueAt()}
        {this.renderVisitationUrl()}
        {this.renderAddress()}
        {this.renderAssignments()}
      </>
    )
  }
};

export default AppointmentFields;
