import React from 'react';
import { t } from 'i18n';
import Error from './Error';
import formatNumber from './formatNumber';
import timeRangeToDate from '../timeRangeToDate';

const i18nOpts = { scope: 'dashboard' };

const UpperHighlight = ({ data, timeRange, status }) => {
  if (status !== 'SUCCESS') {
    return <Error status={status} />;
  }

  const dataForTimeRange = data[timeRange];
  const hasDataForTimeRange = dataForTimeRange.cgmActivePercentage.number > 0;
  const daysActive = formatNumber(
    dataForTimeRange.cgmActivePercentage?.days_active,
    'integer',
    hasDataForTimeRange,
    1
  );

  return (
    <div className="stats-highlight__upper-container">
      <div className="stats-highlight__upper-container__date">{timeRangeToDate(timeRange)}</div>
      <div className="stats-highlight__upper-container__cgm">
        <div className="stats-highlight__cgm-display">
          <div className="stats-highlight__number stats-highlight__number--x-large">
            {formatNumber(dataForTimeRange.cgmActivePercentage.number, 'percentage', hasDataForTimeRange)}
          </div>
          <div className="stats-highlight__header">{dataForTimeRange.cgmActivePercentage?.header}</div>
          <div className="stats-highlight__subheader">
            {t('days_active', { ...i18nOpts, days: daysActive })}
          </div>
        </div>
        <div className="stats-highlight__range-display">
          <div className="stats-highlight__range-display__header">
            <div>{t('percent_symbol', i18nOpts)}</div>
            <div>{t('level', i18nOpts)}</div>
            <div>{t('mmol_per_litre', i18nOpts)}</div>
          </div>
          {dataForTimeRange.glucoseRange.map((datum) => (
            <div className="stats-highlight__range-display__unit" key={datum.header}>
              <div className="stats-highlight__header">{datum.header}</div>
              <div className="stats-highlight__number">
                {formatNumber(datum.number, datum.type, hasDataForTimeRange, 0)}
              </div>
              <div className="stats-highlight__header">
                {datum?.subheader}
                <span className="stats-highlight__units">{datum?.units}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UpperHighlight;
