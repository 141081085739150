import React, { useState, useEffect } from 'react';
import PasswordInput from 'Components/Forms/PasswordInput';
import { t } from 'i18n';

import useComponentDidMount from 'Hooks/useComponentDidMount';

const i18nOpts = { scope: 'registration.user' };

const PasswordField = ({ passwordError }) => {
  const isComponentMounted = useComponentDidMount();

  const [passwordFocused, setPasswordFocused] = useState(false);
  const [passwordDirty, setPasswordDirty] = useState(false);
  const [formErrors, setFormErrors] = useState({ 'user[password]': passwordError });
  const [password, setPassword] = useState('');

  const setupPasswordValidator = (formErrors, setFormErrors, t, i18nOpts) => {
    return (password) => {
      const passwordRegex =
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!\"#$%&`()*+,-./:;<=>?@\[\\\]^_{|}~]).{8,}$/;

      let errorStr = '';
      if (!password) {
        errorStr = t('blank_password', i18nOpts);
      }
      setFormErrors({ ...formErrors, 'user[password]': errorStr });
    };
  };

  const handlePasswordValidation = setupPasswordValidator(formErrors, setFormErrors, t, i18nOpts);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }
    handlePasswordValidation(password);
  }, [passwordFocused]);

  return (
    <PasswordInput
      id="user_password"
      name="user[password]"
      value={password}
      onChange={({ target }) => {
        if (passwordDirty) {
          handlePasswordValidation(target.value);
        }
        setPassword(target.value);
      }}
      onBlur={() => {
        setPasswordDirty(true);
        setPasswordFocused(!passwordFocused);
      }}
      errors={formErrors}
    />
  );
};

export default PasswordField;
