import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { t } from 'i18n'

import { AttributeWithIcon, Chip, DeferredImage } from 'Components/Base'

import MostResponsibleProviderChip from '../../MostResponsibleProviderChip';

import avatar from 'avatar.png'

const i18nOpts = { scope: 'team.health_team' }

const HealthTeamContact = ({
  isCarePlanCreator,
  location,
  name,
  imageEntitlementToken,
  title,
  path,
  placeLink,
  phoneNumber,
  mostResponsibleProvider
}) => (
  <li className="support-team-member support-team-member--health-team-contact">
    <a href={path} className="support-team-member__avatar">
      <DeferredImage
        imageEntitlementToken={imageEntitlementToken}
        alt={name}
        className="avatar avatar--small"
        fallbackImage={avatar}
      />
    </a>

    <div className="support-team-member__details">
      <div className="support-team-member__section support-team-member__section--name">
        <h3
          className={classNames('support-team-member__name', {
            'support-team-member__name--author': isCarePlanCreator,
          })}
        >
          <a href={path}>{name}</a>
          {isCarePlanCreator && <Chip color="light-grey" text={t('author', i18nOpts)} />}
        </h3>

        {mostResponsibleProvider && <MostResponsibleProviderChip />}

        <span className="support-team-member__relationship">{title}</span>
      </div>

      <div className="support-team-member__contact-information text-truncate">
        <div className="support-team-member__section support-team-member__section--phone text-truncate">
          <AttributeWithIcon iconName="phone_alt">
            {phoneNumber ? (
              <a className="text-truncate" href={`tel:${phoneNumber}`}>
                {phoneNumber}
              </a>
            ) : (
              <span className="text-grey-alt">{t('unknown', i18nOpts)}</span>
            )}
          </AttributeWithIcon>
        </div>

        <div className="support-team-member__section support-team-member__section--email text-truncate">
          <AttributeWithIcon iconName="pin">
            <a href={placeLink} className="text-truncate">
              {location}
            </a>
          </AttributeWithIcon>
        </div>
      </div>
    </div>
  </li>
)

HealthTeamContact.propTypes = {
  imageEntitlementToken: PropTypes.string,
  isCarePlanCreator: PropTypes.bool.isRequired,
  location: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string,
  title: PropTypes.string,
}

HealthTeamContact.defaultProps = {
  imageEntitlementToken: '',
  phoneNumber: '',
  title: '',
}

export default HealthTeamContact
