import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import Card from 'Components/Base/Card';
import UploadedDate from './UploadedDate';
import StatsList from 'Components/Base/Dashboard/StatsList';
import BolusSettings from './BolusSettings';
import BasalSettings from './BasalSettings';
import AverageDailyInsulin from './AverageDailyInsulin';
import Error from './Error';
import { CarePlanContext } from 'Components/Dashboard/Dashboard';
import { connect } from 'react-redux';
import CopyButton from '../../Buttons/CopyButton';
import buildTextFromBlocks from '../../buildTextFromBlocks';

const i18nOpts = { scope: 'dashboard' };

const InsulinPump = ({ data, pumpStatistics, status, selectedHistoricalTab }) => {
  const { userDetails } = useContext(CarePlanContext);

  const handleButtonClick = () => {
    const text = buildTextFromBlocks('INSULIN_PUMP', {
      statsData: data,
      tab: selectedHistoricalTab,
      pumpStatistics,
      userDetails,
    });

    // To test this in local, check readme
    navigator.clipboard?.writeText(text);
  };

  return status === 'SUCCESS' ? (
    <Card
      title={t('insulin_pump_settings', i18nOpts)}
      id="insulin_pump_settings"
      cardHeaderClass="card__header__dashboard"
    >
      <div className="insulin-dosing__copy-dashboard">
        <CopyButton onCopyButtonClick={handleButtonClick} />
      </div>
      <UploadedDate date={data.uploadedDate} />
      <StatsList list={data.basalProgram.list} />
      <BolusSettings
        insulinCarbRatios={data.insulinCarbRatios}
        insulinSensitivityFactors={data.insulinSensitivityFactors}
        bloodGlucoseTargets={data.bloodGlucoseTargets}
      />
      <div className="pump-setttings__row">
        <AverageDailyInsulin statistics={pumpStatistics} />
        <BasalSettings
          profiles={data.basalRates.profiles}
          title={data.basalRates.title}
          header={data.basalRates.header}
        />
      </div>
    </Card>
  ) : (
    <Error status={status} />
  );
};

InsulinPump.propTypes = {
  data: PropTypes.shape({
    basalProgram: PropTypes.shape({
      list: PropTypes.array,
    }),
    basalRates: PropTypes.object.isRequired,
    bloodGlucoseTargets: PropTypes.object.isRequired,
    insulinSensitivityFactors: PropTypes.object.isRequired,
    insulinCarbRatios: PropTypes.object.isRequired,
    pumpStatistics: PropTypes.object,
    uploadedDate: PropTypes.string,
  }).isRequired,
  status: PropTypes.string,
};

InsulinPump.defaultProps = {
  status: 'ERROR',
  uploadedDate: null,
};

const mapStateToProps = ({ dashboardInsulinDosing: { selectedHistoricalTab } }) => {
  return { selectedHistoricalTab };
};

export default connect(mapStateToProps)(InsulinPump);
