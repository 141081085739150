import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import InputGroup from './InputGroup';

const i18nOpts = { scope: 'relationship' };

const RelationshipInput = ({ className, errors, value, name, label, onChange }) => (
  <InputGroup
    className={className}
    component="select"
    value={value}
    errors={errors}
    name={name}
    onChange={onChange}
    label={label}
  >
    <option value="">{t('empty_relationship', i18nOpts)}</option>
    {[
      'other',
      'spouse',
      'parent',
      'child',
      'grandchild',
      'sibling',
      'cousin',
      'friend',
      'neighbour',
      'step_parent',
      'ex_spouse',
      'professional_caregiver',
      'volunteer',
    ].map((opt) => (
      <option key={opt} value={opt}>
        {t(opt, i18nOpts)}
      </option>
    ))}
  </InputGroup>
);

RelationshipInput.propTypes = {
  className: PropTypes.string,
  errors: PropTypes.object.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

RelationshipInput.defaultProps = {
  className: 'input',
  value: '',
  name: 'relationship',
  label: '',
};

export default RelationshipInput;
