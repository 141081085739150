import React from 'react'
import PropTypes from 'prop-types'

import { Appointment, CheckIn, Form, Referral, RepeatedAppointment, RepeatedTask, Task } from './'

const ActivityContainer = ({ type, ...props }) => {
  switch (type) {
    case 'appointment':
      return <Appointment {...props} />
    case 'checkIn':
      return <CheckIn {...props} />
    case 'referral':
      return <Referral {...props} />
    case 'form':
      return <Form {...props} />
    case 'task':
      return <Task {...props} />
    case 'repeated_task':
      return <RepeatedTask {...props} />
    case 'repeated_appointment':
      return <RepeatedAppointment {...props} />
    case 'repeated_check_in':
      return <CheckIn {...props} isRepeated />
    case 'repeated_form':
      return <Form {...props} isRepeated />
  }
}

ActivityContainer.propTypes = {
  assignments: PropTypes.array,
  canComplete: PropTypes.bool,
  carePlan: PropTypes.shape({
    title: PropTypes.string,
    place: PropTypes.shape({
      name: PropTypes.string,
      logo: PropTypes.string,
    }),
  }),
  carePlanUuid: PropTypes.string,
  completedAt: PropTypes.string,
  dueAt: PropTypes.string,
  id: PropTypes.number,
  isComplete: PropTypes.bool,
  kind: PropTypes.string,
  link: PropTypes.string,
  patientName: PropTypes.string,
  practitioner: PropTypes.string,
  services: PropTypes.array,
  text: PropTypes.string,
  type: PropTypes.string.isRequired,
  url: PropTypes.string,
}

ActivityContainer.defaultProps = {
  assignments: [],
  canComplete: false,
  carePlan: {},
  carePlanUuid: '',
  completedAt: '',
  dueAt: null,
  id: null,
  isComplete: false,
  kind: '',
  link: '',
  patientName: null,
  practitioner: '',
  services: [],
  text: '',
  url: '',
}

export default ActivityContainer
