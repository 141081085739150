import React from 'react';
import { Icon } from 'Components/Base';
import PropTypes from 'prop-types';

// Type prop: currently accepted types are 'warning' or 'success'
// Message prop: can accept either a string or a DOM element eg <span><a>Click Here</a> for details.</span>

const Highlight = ({ type, message }) => {
  switch (type) {
    case 'warning':
      return (
        <div className="highlight highlight--warning">
          <Icon className="icon highlight__icon highlight__icon--warning" name="error-circle_alt" />
          <div className="highlight__message">{message}</div>
        </div>
      );
    case 'success-alert':
      return (
        <div className="highlight highlight--success">
          <Icon className="icon highlight__icon highlight__icon--success" name="error-circle_alt" />
          <div className="highlight__message">{message}</div>
        </div>
      );
    default:
      return (
        <div className="highlight highlight--success">
          <Icon className="icon highlight__icon highlight__icon--success" name="check_alt2" />
          <div className="highlight__message">{message}</div>
        </div>
      );
  }
};

Highlight.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.oneOf(['warning', 'success']),
};

Highlight.defaultProps = {
  message: '',
  type: 'success',
};

export default Highlight;
