import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Loader from 'Components/Base/Loader';
import { t } from 'i18n';
import classNames from 'classnames';

import setupBlockFetcher from '../setupBlockFetcher';
import CertaintyExpectationChart from './Chart';
import ErrorDisplay from 'Components/Base/Dashboard/ErrorDisplay';
import getRenderData from './getRenderData';
import Icon from 'Components/Base/Icon';
import RenderCategories from '../RenderCategories';

const i18nOpts = { scope: 'blocks.certainty_expectation' };

const SymptomOccurrence = ({ carePlanUuid, blockName }) => {
  const [data, setData] = useState({});
  const [pageState, setPageState] = useState('loading');

  useEffect(() => {
    setupBlockFetcher(carePlanUuid, blockName, setData, setPageState);
  }, []);

  switch (pageState) {
    case 'success': {
      const renderData = getRenderData(data);
      const { eventsOccurred } = renderData;

      return (
        <div className="card block-visual certainty-expectation">
          <div className="block-visual block-visual__header">
            <h3 className="title">{t('title', i18nOpts)}</h3>
          </div>
          <div className="block-visual block-visual__chart-wrapper">
            <div className="event-happened__wrapper">
              <RenderCategories categories={[t('event_happened', i18nOpts)]} />
              <div className="event-happened__indicator">
                {eventsOccurred.map((event, index) => {
                  const noDataEvent = event === null;
                  const classes = classNames({
                    event: true,
                    'event--occurred': event,
                    'event--not-occurred': !event && !noDataEvent,
                    'event--no-data no-data': noDataEvent,
                  });

                  return <Icon key={index} name={event ? 'check' : 'close'} className={classes} />;
                })}
              </div>
            </div>
            <div className="category-padding" />
            <CertaintyExpectationChart data={renderData} />
          </div>
        </div>
      );
    }
    case 'error':
      return <ErrorDisplay title={t('fetch_error', i18nOpts)} status="ERROR" />;
    default:
      return <Loader />;
  }
};

SymptomOccurrence.propTypes = {
  carePlanUuid: PropTypes.string.isRequired,
  blockName: PropTypes.string.isRequired,
};

export default SymptomOccurrence;
