import React from 'react';
import PropTypes from 'prop-types';
import LinkDecorator from 'Components/Base/LinkDecorator';
import Avatar from 'PulseBackports/Avatar';

const Comment = ({ author: { name, photo, relationship }, createdAt, text }) => (
  <div className="comment">
    <header className="activity__header">
      <Avatar
        src={photo}
        fullName={name}
        size={2}
        className="activity__avatar activity__avatar--comment"
      />

      <div className="activity__header-text">
        <h4 className="activity__username activity__username--comment">
          {name}&nbsp;
          <span className="activity__relationship activity__relationship--comment">({relationship})</span>
        </h4>

        <span className="activity__created">{createdAt}</span>
      </div>
    </header>

    <p className="comment__body">
      <LinkDecorator>{text}</LinkDecorator>
    </p>
  </div>
)

Comment.propTypes = {
  author: PropTypes.shape({
    name: PropTypes.string.isRequired,
    photo: PropTypes.string,
    relationship: PropTypes.string.isRequired,
  }).isRequired,
  createdAt: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

Comment.defaultProps = {}

export default Comment
