import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Loader from 'Components/Base/Loader';
import { t } from 'i18n';
import ErrorDisplay from 'Components/Base/Dashboard/ErrorDisplay';
import setupBlockFetcher from '../setupBlockFetcher';
import SymptomIntensityChart from './Chart';
import getRenderData from './getRenderData';
import RenderCategories from '../RenderCategories';

const i18nOpts = { scope: 'blocks.symptom_intensity' };

const SymptomIntensity = ({ carePlanUuid, blockName }) => {
  const [data, setData] = useState({});
  const [pageState, setPageState] = useState('loading');

  useEffect(() => {
    setupBlockFetcher(carePlanUuid, blockName, setData, setPageState);
  }, []);

  switch (pageState) {
    case 'success': {
      return (
        <div className="card block-visual symptom-intensity">
          <div className="block-visual block-visual__header">
            <h3 className="title">{t('title', i18nOpts)}</h3>
          </div>
          <div className="block-visual block-visual__chart-wrapper">
            <RenderCategories categories={t('categories', i18nOpts)} />
            <SymptomIntensityChart data={getRenderData(data)} />
          </div>
        </div>
      );
    }
    case 'error': {
      return <ErrorDisplay title={t('fetch_error', i18nOpts)} status="ERROR" />;
    }
    case 'loading': {
      return <Loader />;
    }
    default: {
      return <ErrorDisplay title={t('fetch_error', i18nOpts)} status="ERROR" />;
    }
  }
};

SymptomIntensity.propTypes = {
  carePlanUuid: PropTypes.string.isRequired,
  blockName: PropTypes.string.isRequired,
};

export default SymptomIntensity;
