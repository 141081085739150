import React from 'react'
import PropTypes from 'prop-types'

const MenuItemLink = ({ link, text, mobile, className, openNewTab = undefined }) => (
  <a href={link}
    className={`dropdown__item ${mobile && 'dropdown__item--mobile'} ${className}`}
    target={openNewTab && "_blank"}
  >
    {text}
  </a>
)

MenuItemLink.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string.isRequired,
  mobile: PropTypes.bool,
  text: PropTypes.string.isRequired,
}

MenuItemLink.defaultProps = {
  className: '',
  mobile: false,
}

export default MenuItemLink
