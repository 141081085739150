import React from 'react';
import { connect } from 'react-redux';

import Description from '../FormFields/Description';
import DueAt from '../FormFields/When/DueAt';
import Where from '../FormFields/Where';
import Who from '../FormFields/Who/Who';
import Resources from '../FormFields/Resources';
import Error from '../Error';

const Appointment = ({ dueAt }) => {
  const isScheduled = !!dueAt;
  return (
    <div className="activity_modal--edit">
      <Description />
      {isScheduled && <DueAt />}
      <Where />
      <Resources />
      <Who />
      <Error />
    </div>
  );
};

const mapStateToProps = ({ activityModal }) => {
  const {
    activityProperties: { dueAt },
  } = activityModal;

  return { dueAt };
};

export default connect(mapStateToProps, null)(Appointment);
