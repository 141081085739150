import React from 'react'
import PropTypes from 'prop-types'

const TextGroup = ({ text, subtext }) => (
  <div className="invitation__text-group text-truncate">
    <h3 className="invitation__title">{text || '-'}</h3>
    {subtext && <span className="invitation__text">{subtext}</span>}
  </div>
)

TextGroup.propTypes = {
  subtext: PropTypes.string,
  text: PropTypes.string,
}

TextGroup.defaultProps = {
  subtext: '',
  text: '-',
}

export default TextGroup
