import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { fetchTasks, fetchIntegratedTasks, fetchHealthCareTasks } from 'Actions/tasks'

import { getVisibleTasks, isFetchingTasks, isShowingCompletedTasks } from 'Selectors/tasks'

import { getTimeZone } from 'Selectors/currentUser'
import { hasFilter } from 'Selectors/filter'

import { Loader } from 'Components/Base/'
import { TaskListHeader, TaskList } from './'

const TaskListContainer = ({ loadTasks, completed, isFetching, toggle, ...props }) => {
  useEffect(loadTasks, [])
  const onClick = toggle.bind(null, completed)

  return (
    <section data-test="task-list">
      <TaskListHeader completed={completed} />
      {isFetching ? <Loader /> : <TaskList {...props} isCompleted={completed} onClick={onClick} />}
    </section>
  )
}

TaskListContainer.propTypes = {
  completed: PropTypes.bool,
  hasFilter: PropTypes.bool,
  isFetching: PropTypes.bool,
  loadTasks: PropTypes.func.isRequired,
  tasks: PropTypes.array,
  timeZone: PropTypes.string,
  toggle: PropTypes.func.isRequired,
}

TaskListContainer.defaultProps = {
  completed: false,
  hasFilter: false,
  isFetching: true,
  tasks: [],
  timeZone: 'Etc/UTC',
}

const mapStateToProps = (state, ownProps) => ({
  tasks: getVisibleTasks(state),
  isFetching: isFetchingTasks(state),
  hasFilter: hasFilter(state),
  completed: isShowingCompletedTasks(state),
  timeZone: getTimeZone(state),
  ...ownProps,
})

const mapDispatchToProps = (
  dispatch,
  { carePlanId, patientUuid, placeUserId, overview, healthCareView }
) => ({
  loadTasks() {
    if (overview) {
      dispatch(fetchIntegratedTasks(patientUuid))
    } else if (healthCareView) {
      dispatch(fetchHealthCareTasks(placeUserId))
    } else {
      dispatch(fetchTasks(carePlanId))
    }
  },
  toggle(completed) {
    if (overview) {
      dispatch(fetchIntegratedTasks(patientUuid, !completed))
    } else if (healthCareView) {
      dispatch(fetchHealthCareTasks(placeUserId, !completed))
    } else {
      dispatch(fetchTasks(carePlanId, !completed))
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(TaskListContainer)
