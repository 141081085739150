import React, { useEffect, useState, createContext } from 'react';
import PropTypes from 'prop-types';
import Loader from 'Components/Base/Loader';
import { t } from 'i18n';
import BloodGlucoseCard from './BloodGlucoseCard';
import InsulinDataCard from './InsulinDataCard';
import Card from 'Components/Base/Card';
import fetchAndUpdateDeviceData from './fetchAndUpdateDeviceData';

const i18nOpts = { scope: 'dashboard' };
const i18nDeviceOpts = { scope: 'dashboard.device' };

export const CarePlanContext = createContext(null);

const Dashboard = ({ carePlanUuid, dashboardType }) => {
  const [data, setData] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [pageState, setPageState] = useState('loading');

  useEffect(() => {
    fetchAndUpdateDeviceData(carePlanUuid, setData, setUserDetails, setPageState)();
    // note immediate invocation of returned function
  }, []);

  switch (pageState) {
    case 'success': {
      const { cgmStatistics, bloodGlucoseMonitor, message = '' } = data;
      if (message) {
        return (
          <CarePlanContext.Provider value={{ carePlanUuid, dashboardType, userDetails }}>
            <BloodGlucoseCard monitorType={dashboardType} statisticsStatus={data.status} error={message} />
            <InsulinDataCard error={message} />
          </CarePlanContext.Provider>
        );
      } else {
        return (
          <CarePlanContext.Provider value={{ carePlanUuid, dashboardType, userDetails }}>
            <BloodGlucoseCard
              statisticsData={cgmStatistics?.data}
              statisticsStatus={cgmStatistics.status}
              initialMonitorData={bloodGlucoseMonitor?.data}
              initialMonitorStatus={bloodGlucoseMonitor.status}
              monitorType={bloodGlucoseMonitor?.device_type}
            />
            <InsulinDataCard data={data} userDetails={userDetails} />
          </CarePlanContext.Provider>
        );
      }
    }
    case 'error': {
      return (
        <>
          <Card title={t('glucose_levels', i18nOpts)}>
            <div className="stats-highlight__api-error">
              <span>
                {t('unknown_error', i18nDeviceOpts)}
                <a href='mailto:support@getcareteam.com'>{'support@getcareteam.com.'}</a>
              </span>
            </div>
          </Card>
          <Card
            title={
              dashboardType === 'pump_glucometer' || dashboardType === 'pump_sensor'
                ? t('insulin_pump_settings', i18nOpts)
                : t('insulin_dosing', i18nOpts)
            }
          >
            <div className="stats-highlight__api-error">
              <span>
                {t('unknown_error', i18nDeviceOpts)}
                <a href='mailto:support@getcareteam.com'>{'support@getcareteam.com.'}</a>
              </span>
            </div>
          </Card>
        </>
      );
    }
    default: {
      return <Loader />;
    }
  }
};

Dashboard.propTypes = {
  carePlanUuid: PropTypes.string.isRequired,
  dashboardType: PropTypes.string.isRequired,
};

export default Dashboard;
