import calculateFill from './calculateFill';

const getRenderData = (data) =>
  data.reduce(
    (acc, { name, data: question }) => {
      const maxValue = Math.max(...question.map(({ value }) => value));
      if (maxValue > acc.maxValue) {
        acc.maxValue = maxValue;
      }

      const dataWithFill = question.map((response) => ({
        ...response,
        fill: calculateFill(name, response.value),
      }));

      return {
        ...acc,
        results: [...acc.results, { name, data: dataWithFill }],
      };
    },
    { results: [], maxValue: 0 }
  );

export default getRenderData;
