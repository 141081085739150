import { createSelector } from 'reselect'
import DocumentSearchIndex from '../indexes/document_search_index'

export const getDocumentSelectionStates = (state) => state.documents.documentSelectionStates
export const getIsEditing = (state) => state.documents.isEditing
export const getIsFetchingDocuments = (state) => state.documents.isFetching
export const getLibraryDocumentsReceived = (state) => state.documents.libraryDocumentsReceived
export const getSearchQuery = (state) => state.documents.searchQuery

// ------------------------------
// LIBRARY DOCUMENTS
// ------------------------------

export const getLibraryDocuments = (state) => state.documents.libraryDocuments

export const getSelectedLibraryDocuments = (state) => {
  return state.documents.libraryDocuments.filter((document) => {
    return state.documents.documentSelectionStates[document.id]
  })
}

export const getUnselectedLibraryDocuments = (state) => {
  return state.documents.libraryDocuments.filter((libraryDocument) => {
    return !state.documents.documentSelectionStates[libraryDocument.id]
  })
}

export const getSelectedLibraryDocumentIds = (state) => {
  return getSelectedLibraryDocuments(state).map((libraryDocument) => libraryDocument.id)
}

export const getPendingLibraryDocumentCopies = (state) => {
  const existingCopyIds = getLibraryDocumentCopies(state).map((document) => document.libraryDocumentId)

  return getSelectedLibraryDocuments(state)
    .filter((libraryDocument) => !existingCopyIds.includes(libraryDocument.id))
    .map((libraryDocument) => {
      return { ...libraryDocument, libraryDocumentId: libraryDocument.id }
    })
}

// ------------------------------
// CARE PLAN ATTACHED DOCUMENTS
// ------------------------------

export const getDocuments = (state) => state.documents.documents

export const getUploadedDocuments = (state) => {
  return state.documents.documents.filter((document) => document.libraryDocumentId === null)
}

export const getModifiableUploadedDocuments = (state) => {
  return getUploadedDocuments(state).filter((document) => document.canModify)
}

export const getLibraryDocumentCopies = (state) => {
  return state.documents.documents.filter((document) => document.libraryDocumentId !== null)
}

export const getDroppedLibraryDocumentCopies = (state) => {
  const documentSelectionStates = getDocumentSelectionStates(state)

  return getLibraryDocumentCopies(state).filter(
    (document) => !documentSelectionStates[document.libraryDocumentId]
  )
}

// ------------------------------
// MEMOIZED SELECTORS
// ------------------------------

export const getLibraryDocumentsIndex = createSelector([getLibraryDocuments], (libraryDocuments) => {
  return new DocumentSearchIndex(libraryDocuments)
})

export const getQueriedUnselectedLibraryDocuments = createSelector(
  [getLibraryDocumentsIndex, getSearchQuery, getUnselectedLibraryDocuments],
  (libraryDocumentsIndex, searchQuery, unselectedLibraryDocuments) => {
    return libraryDocumentsIndex
      .search(searchQuery)
      .filter((document) => unselectedLibraryDocuments.includes(document))
  }
)
