import { request } from 'Apiv2/handleRequest';

const updateEmergencyInstructionWrapper = (requestProvider = request) => {
  return async (content, contentFormatted, setIsLoading, setUpdateError, setEditForm) => {
    try {
      setIsLoading(true);
      const body = { content, contentFormatted };

      const response = await requestProvider('/emergency_instruction', 'PATCH', body);

      if (response.status != 200) throw new Error('Request did not process');

      setUpdateError(false);

      window.location.reload();

      return;
    } catch (error) {
      setIsLoading(false);
      setUpdateError(true);

      return;
    }
  };
};

export default updateEmergencyInstructionWrapper;
