import {
  REQUEST_CONTACTS,
  RECEIVE_CONTACTS,
  RECEIVE_CHANNEL_CONTACTS,
  REQUEST_CHANNEL_PAGE,
  RECEIVE_CHANNEL_PAGE,
  MARK_CONVERSATION_AS_READ,
  MARK_CONVERSATION_AS_UNREAD,
  ADD_CONTACT,
  ADD_CHANNEL_CONTACT,
  UPDATE_HAS_MORE_CHANNEL_PAGE,
  RECEIVE_CHANNEL_SEARCH,
  CLEAR_CHANNEL_SEARCH,
} from 'Actions/inbox/sideNav/contacts';

import { RECEIVE_CONVERSATION_MESSAGE } from 'Actions/inbox/conversation';

const addNewContact = (contacts, contact) => {
  const isNewContact = !contacts.find((c) => c.conversation.id === contact.id);
  return isNewContact
    ? [contact, ...contacts]
    : contacts.map((c) => {
        if (c.conversation.id === contact.id) {
          c.conversation.lastMessageAt = contact.conversation.last_message_at;
        }
        return c;
      });
};

const markConversationAsRead = (contacts, id) => {
  return contacts.map((contact) => {
    if (contact.conversation.id === id) {
      contact.conversation.isUnread = false;
    }

    return contact;
  });
};

const markConversationAsUnread = (contacts, id) => {
  return contacts.map((contact) => {
    if (contact.conversation.id === id) {
      contact.conversation.isUnread = true;
    }

    return contact;
  });
};

const updateContactConversation = (contacts, id) => {
  return contacts.map((contact) => {
    if (contact.conversation.id === id) {
      contact.conversation.lastMessageAt = Math.round(new Date().getTime() / 1000);
    }

    return contact;
  });
};

const contacts = (
  state = {
    contacts: [],
    channelContacts: [],
    channelSearchContacts: [],
    isFetching: true,
    isFetchingChannelPage: false,
    hasMoreChannelPage: true,
  },
  action
) => {
  switch (action.type) {
    case REQUEST_CONTACTS:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_CONTACTS:
      return {
        ...state,
        contacts: action.contacts,
        isFetching: false,
        isFetchingChannelPage: false,
      };
    case RECEIVE_CHANNEL_CONTACTS:
      return {
        ...state,
        channelContacts: action.channelContacts,
        isFetching: false,
        isFetchingChannelPage: false,
      };
    case RECEIVE_CHANNEL_SEARCH:
      return {
        ...state,
        channelSearchContacts: action.channelContacts,
      };
    case CLEAR_CHANNEL_SEARCH:
      return {
        ...state,
        channelSearchContacts: [],
      };
    case REQUEST_CHANNEL_PAGE:
      return {
        ...state,
        isFetchingChannelPage: true,
      };
    case RECEIVE_CHANNEL_PAGE:
      return {
        ...state,
        channelContacts: [...state.channelContacts, ...action.channelContacts],
        isFetchingChannelPage: false,
      };
    case UPDATE_HAS_MORE_CHANNEL_PAGE:
      return {
        ...state,
        hasMoreChannelPage: action.hasMoreChannelPage,
      };
    case ADD_CONTACT:
      return {
        ...state,
        contacts: addNewContact(state.contacts, action.contact),
      };
    case ADD_CHANNEL_CONTACT:
      return {
        ...state,
        channelContacts: addNewContact(state.channelContacts, action.contact),
      };
    case MARK_CONVERSATION_AS_READ:
      return {
        ...state,
        contacts: markConversationAsRead(state.contacts, action.id),
      };
    case MARK_CONVERSATION_AS_UNREAD:
      return {
        ...state,
        contacts: markConversationAsUnread(state.contacts, action.id),
      };
    case RECEIVE_CONVERSATION_MESSAGE:
      return {
        ...state,
        contacts: updateContactConversation(state.contacts, action.id),
      };
    default:
      return state;
  }
};

export default contacts;
