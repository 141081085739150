import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { t } from 'i18n'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import IconButton from 'Components/Base/IconButton'

import SectionFooter from './SectionFooter'
import UploadedDocuments from './UploadedDocuments'
import LibraryDocuments from './LibraryDocuments'
import LibraryDocumentCopiesApi from 'Api/LibraryDocumentCopies'

import { fetchLibraryDocuments, updateLibraryDocumentCopies, toggleEditing } from 'Actions/documents'

import {
  getLibraryDocuments,
  getLibraryDocumentsReceived,
  getSelectedLibraryDocuments,
  getSelectedLibraryDocumentIds,
  getQueriedUnselectedLibraryDocuments,
  getModifiableUploadedDocuments,
} from 'Selectors/documents'

const i18nOpts = { scope: 'care_plans.document_section.edit' }

export class DocumentSection extends Component {
  constructor(props) {
    super(props)

    this.libraryTabIndex = 0
    this.uploadedDocumentsTabIndex = 1

    this.state = {
      isSubmitting: false,
      modalIsOpen: false,
      tabIndex: this.libraryTabIndex,
    }

    this.handleDone = this.handleDone.bind(this)
    this.handleTabSelect = this.handleTabSelect.bind(this)
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.renderHealthCareView = this.renderHealthCareView.bind(this)
    this.renderUploadedDocuments = this.renderUploadedDocuments.bind(this)
    this.submitLibraryDocumentSelection = this.submitLibraryDocumentSelection.bind(this)
  }

  componentDidMount() {
    if (!this.props.libraryDocumentsReceived && this.props.healthCareView) {
      this.props.loadLibraryDocuments()
    }
  }

  handleDone() {
    this.props.healthCareView ? this.submitLibraryDocumentSelection() : this.props.toggleEditing()
  }

  handleTabSelect(tabIndex) {
    this.setState({ tabIndex })
  }

  openModal() {
    this.setState({ modalIsOpen: true, tabIndex: this.uploadedDocumentsTabIndex })
  }

  closeModal() {
    this.setState({ modalIsOpen: false })
  }

  submitLibraryDocumentSelection() {
    this.setState({ isSubmitting: true })

    return new LibraryDocumentCopiesApi(this.props.carePlanUuid)
      .update(this.props.selectedLibraryDocumentIds)
      .then((res) => {
        if (res.ok) {
          this.setState({ isSubmitting: false })
          this.props.updateLibraryDocumentCopies()
        } else if (res.status === 422) {
          return res.json().then(console.error)
        }
      })
  }

  renderHealthCareView() {
    return (
      <Tabs selectedIndex={this.state.tabIndex} onSelect={this.handleTabSelect}>
        <TabList>
          <Tab>{t('library', i18nOpts)}</Tab>
          <Tab>{t('uploaded', i18nOpts)}</Tab>
        </TabList>

        <TabPanel>
          <LibraryDocuments
            libraryIsEmpty={!this.props.libraryDocuments.length}
            selectedDocuments={this.props.selectedDocuments}
            unselectedDocuments={this.props.unselectedDocuments}
          />
        </TabPanel>
        <TabPanel>{this.renderUploadedDocuments()}</TabPanel>
      </Tabs>
    )
  }

  renderUploadedDocuments() {
    return (
      <UploadedDocuments
        carePlanUuid={this.props.carePlanUuid}
        documents={this.props.uploadedDocuments}
        openModal={this.openModal}
        closeModal={this.closeModal}
        modalIsOpen={this.state.modalIsOpen}
      />
    )
  }

  render() {
    return (
      <Fragment>
        {this.props.healthCareView ? this.renderHealthCareView() : this.renderUploadedDocuments()}

        <SectionFooter onDone={this.handleDone} isSubmitting={this.state.isSubmitting}>
          <IconButton
            iconName="file-upload"
            isSecondary
            onClick={this.openModal}
            text={t('upload_documents', i18nOpts)}
          />
        </SectionFooter>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  libraryDocuments: getLibraryDocuments(state),
  libraryDocumentsReceived: getLibraryDocumentsReceived(state),
  selectedDocuments: getSelectedLibraryDocuments(state),
  unselectedDocuments: getQueriedUnselectedLibraryDocuments(state),
  uploadedDocuments: getModifiableUploadedDocuments(state),
  selectedLibraryDocumentIds: getSelectedLibraryDocumentIds(state),
})

const mapDispatchToProps = (dispatch) => ({
  loadLibraryDocuments() {
    dispatch(fetchLibraryDocuments())
  },
  toggleEditing() {
    dispatch(toggleEditing())
  },
  updateLibraryDocumentCopies() {
    dispatch(updateLibraryDocumentCopies())
  },
})

const documentPropType = PropTypes.shape({
  author: PropTypes.object,
  canModify: PropTypes.bool,
  createdAt: PropTypes.string,
  documentFileName: PropTypes.string,
  downloadUrl: PropTypes.string,
  id: PropTypes.number,
  libraryDocumentId: PropTypes.number,
  name: PropTypes.string,
  url: PropTypes.string,
})

DocumentSection.propTypes = {
  // dispatch
  carePlanUuid: PropTypes.string,
  documents: PropTypes.arrayOf(documentPropType),
  healthCareView: PropTypes.bool,
  // state
  libraryDocuments: PropTypes.arrayOf(documentPropType),
  libraryDocumentsReceived: PropTypes.bool.isRequired,
  loadLibraryDocuments: PropTypes.func.isRequired,
  selectedDocuments: PropTypes.arrayOf(documentPropType),
  selectedLibraryDocumentIds: PropTypes.arrayOf(PropTypes.number),
  toggleEditing: PropTypes.func.isRequired,
  // props
  unselectedDocuments: PropTypes.arrayOf(documentPropType),
  updateLibraryDocumentCopies: PropTypes.func.isRequired,
  uploadedDocuments: PropTypes.arrayOf(documentPropType),
}

DocumentSection.defaultProps = {
  documents: [],
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentSection)
