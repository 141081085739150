import {
  SET_CAREPLAN_ID,
  SET_SESSION_INFO_STATE,
  SET_SESSION_INFO_DATA,
  SET_APPOINTMENTS_CARD_STATE,
  SET_APPOINTMENTS_CARD_APPOINTMENTS_LIST,
  SET_CHECK_INS_CARD_STATE,
  SET_CHECK_INS_CARD_CHECK_INS_LIST,
  SET_TASKS_CARD_STATE,
  SET_TASKS_CARD_TASKS_LIST,
  SET_FORMS_CARD_STATE,
  SET_FORMS_CARD_FORMS_LIST,
} from 'Actions/carePlanActionPlanViewPage';

const initialState = {
  carePlanId: '',
  sessionInfo: {
    sessionInfoState: 'LOADING',
    canAdd: false,
    timeZone: '',
    patientFirstName: '',
    locale: '',
  },
  appointmentsCard: {
    appointmentsCardState: 'LOADING',
    appointments: [],
    repeatedAppointments: [],
  },
  checkInsCard: {
    checkInsCardState: 'LOADING',
    checkIns: [],
    repeatedCheckIns: [],
  },
  tasksCard: {
    tasksCardState: 'LOADING',
    tasks: [],
    repeatedTasks: [],
  },
  formsCard: {
    formsCardState: 'LOADING',
    forms: [],
    repeatedForms: [],
  },
};

const carePlanActionPlanViewPage = (state = initialState, action) => {
  switch (action.type) {
    case SET_CAREPLAN_ID:
      return {
        ...state,
        carePlanId: action.carePlanId,
      };

    case SET_SESSION_INFO_STATE:
      return {
        ...state,
        sessionInfo: {
          ...state.sessionInfo,
          sessionInfoState: action.sessionInfoState,
        },
      };

    case SET_SESSION_INFO_DATA:
      return {
        ...state,
        sessionInfo: {
          ...state.sessionInfo,
          ...action.data,
        },
      };

    case SET_APPOINTMENTS_CARD_STATE:
      return {
        ...state,
        appointmentsCard: {
          ...state.appointmentsCard,
          appointmentsCardState: action.appointmentsCardState,
        },
      };

    case SET_APPOINTMENTS_CARD_APPOINTMENTS_LIST:
      return {
        ...state,
        appointmentsCard: {
          ...state.appointmentsCard,
          ...action.appointmentsList,
        },
      };

    case SET_CHECK_INS_CARD_STATE:
      return {
        ...state,
        checkInsCard: {
          ...state.checkInsCard,
          checkInsCardState: action.checkInsCardState,
        },
      };

    case SET_CHECK_INS_CARD_CHECK_INS_LIST:
      return {
        ...state,
        checkInsCard: {
          ...state.checkInsCard,
          ...action.checkInsList,
        },
      };

    case SET_TASKS_CARD_STATE:
      return {
        ...state,
        tasksCard: {
          ...state.tasksCard,
          tasksCardState: action.tasksCardState,
        },
      };

    case SET_TASKS_CARD_TASKS_LIST:
      return {
        ...state,
        tasksCard: {
          ...state.tasksCard,
          ...action.tasksList,
        },
      };

    case SET_FORMS_CARD_STATE:
      return {
        ...state,
        formsCard: {
          ...state.formsCard,
          formsCardState: action.formsCardState,
        },
      };

    case SET_FORMS_CARD_FORMS_LIST:
      return {
        ...state,
        formsCard: {
          ...state.formsCard,
          ...action.formsList,
        },
      };

    default:
      return state;
  }
};

export default carePlanActionPlanViewPage;
