import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import WeekSelector from '../WeekSelector';
import DayToggler from '../DayToggler';
import Chart from '../Chart';
import Loader from 'Components/Base/Loader';

import setupComparisonChart from './setupComparisonChart';
import getInitialRangeEndDate from './getInitialRangeEndDate';
import getWeekOrder from './getWeekOrder';

import {
  setChartData,
  setChartError,
  setChartMaximumDate,
  setRangeEndDate,
  setWeekOrder,
  setPageState,
  setMonitorType,
} from 'Actions/dashboardComparisonChart';

const i18nDeviceOpts = { scope: 'dashboard.device' };

const ChartContainer = ({
  data,
  error,
  monitor,
  pageState,
  setChartData,
  setChartError,
  setChartMaximumDate,
  setRangeEndDate,
  setWeekOrder,
  setMonitorType,
  setPageState,
}) => {
  useLayoutEffect(() => {
    setupComparisonChart(
      data,
      error,
      monitor,
      getInitialRangeEndDate,
      getWeekOrder,
      setChartData,
      setChartError,
      setChartMaximumDate,
      setRangeEndDate,
      setWeekOrder,
      setMonitorType,
      setPageState
    );
  }, []);

  switch (pageState) {
    case 'loading':
      return <Loader />;
    case 'success':
      return (
        <>
          <WeekSelector />
          <DayToggler />
          <Chart />
        </>
      );
    case 'error':
    default:
      return (
        <div className="stats-highlight__api-error">
          <p>{t('data_not_available', i18nDeviceOpts)}</p>
        </div>
      );
  }
};

const mapStateToProps = ({ dashboardComparisonChart: { pageState } }) => {
  return { pageState };
};

const mapDispatchToProps = (dispatch) => ({
  setChartData: (chartData) => dispatch(setChartData(chartData)),
  setChartError: (chartError) => dispatch(setChartError(chartError)),
  setRangeEndDate: (rangeEndDate) => dispatch(setRangeEndDate(rangeEndDate)),
  setWeekOrder: (weekOrder) => dispatch(setWeekOrder(weekOrder)),
  setPageState: (pageState) => dispatch(setPageState(pageState)),
  setChartMaximumDate: (chartMaximumDate) => dispatch(setChartMaximumDate(chartMaximumDate)),
  setMonitorType: (monitorType) => dispatch(setMonitorType(monitorType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChartContainer);
