import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import RepeatedTaskOccurrenceApi from 'Api/RepeatedTaskOccurrence'
import Button from 'Components/Base/Button'
import { Icon, Tooltip } from 'Components/Base'
import DeleteModal from './DeleteModal'

import { t } from 'i18n'

class DeleteRepeatedTaskButton extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isSubmitting: false,
      modalIsOpen: false,
      deleteOccurrenceOnly: true,
    }

    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.delete = this.delete.bind(this)
  }

  get api() {
    return new RepeatedTaskOccurrenceApi(
      this.props.carePlanId,
      this.props.repeatedTaskId,
      this.props.occurrence
    )
  }

  openModal() {
    this.setState({ modalIsOpen: true })
  }

  closeModal() {
    this.setState({ modalIsOpen: false })
  }

  delete({ occurrenceOnly }) {
    this.setState({ modalIsOpen: false, isSubmitting: true })

    return this.api.destroy({ delete_occurrence_only: occurrenceOnly }).then((res) => {
      if (res.ok) {
        window.location.assign(res.headers.get('location'))
      }
    })
  }

  render() {
    return (
      <Fragment>
        <Button
          className="card-image-header__btn btn btn--red btn--circle"
          id="delete-occurrence"
          onClick={this.openModal}
        >
          <Tooltip text={t('delete', { scope: 'activities' })}>
            <Icon name="trash_alt" />
          </Tooltip>
        </Button>

        <DeleteModal
          modalIsOpen={this.state.modalIsOpen}
          closeModal={this.closeModal}
          deleteOccurrence={this.delete.bind(this, { occurrenceOnly: true })}
          deleteSeries={this.delete.bind(this, { occurrenceOnly: false })}
        />
      </Fragment>
    )
  }
}

DeleteRepeatedTaskButton.propTypes = {
  carePlanId: PropTypes.string.isRequired,
  occurrence: PropTypes.string.isRequired,
  repeatedTaskId: PropTypes.number.isRequired,
}

export default DeleteRepeatedTaskButton
