import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import ResourceListItem from 'Components/Resources/ResourceListItem'

const ResourcesSection = ({ selectedResources, editResource, onDeleteResource }) => (
  <div className="item-pill-section">
    <h3 className="item-pill-section__header">
      {t('resources', { scope: 'care_plans.generic_order_section.generic_order' })}
    </h3>

    {selectedResources.length ? (
      <div className="item-pill-section__item-list">
        {selectedResources.map((resource) => (
          <ResourceListItem
            key={resource.id}
            id={resource.id}
            name={resource.name}
            kind={resource.kind}
            editResource={editResource}
            deleteResource={onDeleteResource}
            isLinking
          />
        ))}
      </div>
    ) : (
      <div className="item-pill-section--item-list-empty">
        <span>{t('no_resources_present', { scope: 'tasks.form' })}</span>
      </div>
    )}
  </div>
)

ResourcesSection.propTypes = {
  editResource: PropTypes.func.isRequired,
  onDeleteResource: PropTypes.func,
  selectedResources: PropTypes.array.isRequired,
}

ResourcesSection.defaultProps = { onDeleteResource: null }
export default ResourcesSection
