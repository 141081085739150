import { REQUEST_CARE_PLANS, RECEIVE_CARE_PLANS } from 'Actions/inbox/sideNav/carePlans'

import { REQUEST_CHANNEL_CARE_PLANS } from 'Actions/inbox/sideNav/channel'

const initialState = { carePlans: [], isFetching: false }

const carePlans = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_CARE_PLANS:
    case REQUEST_CHANNEL_CARE_PLANS:
      return {
        ...state,
        isFetching: true,
      }
    case RECEIVE_CARE_PLANS:
      return {
        ...state,
        carePlans: action.carePlans,
        isFetching: false,
      }
    default:
      return state
  }
}

export default carePlans
