import fetchBlockApi from 'Apiv2/fetchBlockApi';

export const setupBlockFetcherWrapper = (getBlockData = fetchBlockApi) => {
  return async (carePlanUuid, blockName, setData, setPageState) => {
    setPageState('loading');

    try {
      const { status, data } = await getBlockData(carePlanUuid, blockName);
      if (status === 200 && data) {
        setData(data);
        setPageState('success');
      } else {
        throw 'Missing data';
      }
    } catch (error) {
      return setPageState('error');
    }
  };
};

export default setupBlockFetcherWrapper(fetchBlockApi);
