import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Icon } from 'Components/Base'

const i18nOpts = { scope: 'inbox.side_nav.contacts.header' }
import Button from 'Components/Base/Button'

const iconMap = {
  desk: 'desk-conversations',
  direct: 'user-circle',
  health_care_direct: 'desk-conversations',
  channels: 'users',
}

const Header = ({ type, onClick }) => (
  <div className="inbox-menu__header">
    <Icon name={iconMap[type]} className={`icon icon--${type}`} />
    <span className="inbox-menu__header-text text-medium">
      {t(type, i18nOpts)}
      <div className="inbox-menu__header-subtext text-normal">{t(`${type}_subtext`, i18nOpts)}</div>
    </span>

    {onClick && (
      <div className="inbox-menu__header-link">
        <Button onClick={onClick} className="btn btn--extra-small">
          {t(iconMap[type], i18nOpts)}
        </Button>
      </div>
    )}
  </div>
)

Header.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string,
}

Header.defaultProps = {
  onClick: null,
  type: 'desk',
}

export default Header
