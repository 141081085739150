import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';

import { Icon, Tooltip } from 'Components/Base';

const i18nOpts = { scope: 'care_plans.goal_section.goal' };

const Goal = ({ title, link, description, photo, completedAt }) => (
  <li className="goal">
    <div className="goal__section goal__section--image">
      {/* TODO: This isn't an avatar, this should be cleaned out */}
      <img src={photo} alt={title} className="activity__avatar activity__avatar--comment" />
    </div>
    <div className="goal__section">
      <b>{title}</b>
      <div className="goal__description">
        {completedAt ? t('achieved', { ...i18nOpts, datetime: completedAt }) : description}
      </div>
    </div>

    <div className="goal__section goal__section--link">
      <span className={`${completedAt ? 'goal__section--complete' : 'goal__section--incomplete'}`}>
        {completedAt ? (
          <>
            <Icon name="check_alt3" className="goal__section--check-icon" />
            {t('complete', i18nOpts)}
          </>
        ) : (
          t('incomplete', i18nOpts)
        )}
      </span>
      <Tooltip className="goal__section--tooltip" text={t('edit', i18nOpts)}>
        <a href={link}>
          <Icon name="pencil-edit" />
        </a>
      </Tooltip>
    </div>
  </li>
);

Goal.propTypes = {
  completedAt: PropTypes.string,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  photo: PropTypes.string,
  title: PropTypes.string.isRequired,
};

Goal.defaultProps = {
  completedAt: '',
  photo: '',
};

export default Goal;
