import React, { forwardRef } from 'react';
import { t } from 'i18n';
import moment from 'ct-moment';
import Avatar from 'PulseBackports/Avatar';

const i18nOpts = { scope: 'patient.index.patient' };

const getAgeString = (birthday) => {
  if (!birthday) return null;

  return `${moment(birthday).format('LL')} | ${moment().diff(moment(birthday), 'years')} ${t('years_old', i18nOpts)}`;
};

// NOTE: This component is used by a) PlaceUser patients list AND b) UHCP unaffiliated care plan list

const Patient = forwardRef(({
  name,
  path,
  photo,
  birthday,
  lastSignInAt,
  invitationsPending,
  newPatient,
  selfRegCarePlan,
  medicalRecordNumber,
  thriveId,
  archived,
}, patientRef) => {

  const isNewReferral = newPatient && !!thriveId;
  const age = getAgeString(birthday);

  return (
    <a href={path} ref={patientRef} className="patient-item" tabIndex="0">
      <div className="patient-item__section patient-item__section--avatar">
        <Avatar
          fullName={name}
          src={photo}
        />
      </div>

      <div className="patient-item__section patient-item__section--details">
        <div className="patient-item__section patient-item__section--name">
          <span className="text-bold">{name}</span>
          {archived && (
            <span className="chip chip--archived patient-item__section--chip">{t('archived', i18nOpts)}</span>
          )}
          {invitationsPending && (
            <span className="chip chip--pending patient-item__section--chip">{t('pending', i18nOpts)}</span>
          )}
          {newPatient && (
            <span className="chip chip--new-patient patient-item__section--chip">
              {isNewReferral ? t('new_referral', i18nOpts) : t('new_patient', i18nOpts)}
            </span>
          )}
          {selfRegCarePlan && (
            <span className="chip chip--self-reg-patient patient-item__section--chip">
              {t('self_registered', i18nOpts)}
            </span>
          )}

          <h4>{age}</h4>
        </div>

        <div className="patient-item__section">
          <h4 className="text-bold">{t('medical_record_number', i18nOpts)}</h4>
          <h4>{medicalRecordNumber || t('unknown', i18nOpts)}</h4>
          {lastSignInAt && (
            <h4 data-test="last-sign-in-at">
              {t('last_logged_in', {
                ...i18nOpts,
                date: moment(lastSignInAt).format('ll'),
                time: moment(lastSignInAt).format('LT'),
              })}
            </h4>
          )}
        </div>
      </div>
    </a>
  );
});

export default Patient;
