import React from 'react';
import { t } from 'i18n';

import FormField from '../FormField';
import CountrySelector from './CountrySelector';
import ExtendedAddressByCountry from './ExtendedAddressByCountry';
import AddressTypeSelector from './AddressTypeSelector';

const i18nOpts = { scope: 'patient.edit.address' }

const Address = ({ address, onChange, name, formErrors, locale }) => (
  <div className="patient_address_form__input_field__container">
    <AddressTypeSelector name={`${name}.label`} label={address.label} onChange={onChange} />

    <CountrySelector
      label={t('country', i18nOpts)}
      value={address.countryCode}
      name={`${name}.countryCode`}
      onChange={onChange}
      locale={locale}
    />
    <FormField
      label={t('street_address', i18nOpts)}
      name={`${name}.address`}
      value={address.address}
      placeholder={t('street_address', i18nOpts)}
      onChange={onChange}
      formErrors={formErrors}
    />
    <FormField
      name={`${name}.extendedAddress`}
      label={t('extended_address', i18nOpts)}
      value={address.extendedAddress}
      placeholder={t('extended_address', i18nOpts)}
      onChange={onChange}
      formErrors={formErrors}
    />
    <ExtendedAddressByCountry
      address={address}
      name={name}
      onChange={onChange}
      formErrors={formErrors}
    />
  </div>
);

export default Address;
