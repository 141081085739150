import Api from './Api'

class Address extends Api {
  constructor(id = null) {
    super()

    this.id = id
  }

  validate(address) {
    return super.request(`${this.url}/validate`, 'POST', { address })
  }

  get url() {
    return `/addresses`
  }
}

export default Address
