import React, { Component } from 'react'
import store from '../../../store'
import PropTypes from 'prop-types'

import { Provider } from 'react-redux'
import DocumentSectionContainer from './DocumentSectionContainer'

import { setDocuments } from 'Actions/documents'

class DocumentSectionModule extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    store.dispatch(setDocuments(this.props.documents))

    return (
      <Provider store={store}>
        <DocumentSectionContainer
          canEdit={this.props.canEdit}
          carePlanUuid={this.props.carePlanUuid}
          healthCareView={this.props.healthCareView}
        />
      </Provider>
    )
  }
}

DocumentSectionModule.propTypes = {
  canEdit: PropTypes.bool,
  carePlanUuid: PropTypes.string.isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.object,
      createdAt: PropTypes.string,
      documentFileName: PropTypes.string,
      downloadUrl: PropTypes.string,
      id: PropTypes.number,
      libraryDocumentId: PropTypes.number,
      name: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  healthCareView: PropTypes.bool.isRequired,
}

DocumentSectionModule.defaultProps = {
  canEdit: false,
  documents: [],
}

export default DocumentSectionModule
