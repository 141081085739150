import React from 'react';

import Description from '../FormFields/Description';
import WhenMandatory from '../FormFields/When/WhenMandatory';
import Where from '../FormFields/Where';
import Who from '../FormFields/Who/Who';
import Error from './Error';

export const Appointment = () => {
  return (
    <div className="activity_modal">
      <Description />
      <WhenMandatory />
      <Where />
      <Who /> {/* NOTE: Keep Who as the last element of ActivityModal */}
      <Error />
    </div>
  );
};

export default Appointment;
