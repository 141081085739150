import { request } from 'Apiv2/handleRequest';
import { getAddressFields, parseAssignments, getActivityKind } from '../../ApiUtils';

export const buildRequestParams = (carePlanId, type, dueAt, frequencyType, body) => {
  let requestUrl = `/care_plans/${carePlanId}/`;
  let requestBody;
  let activityType = type;

  // unscheduled appointment is a task :eye_roll:
  if (activityType === 'APPOINTMENT' && !dueAt) {
    activityType = 'TASK';
  }
  const recurring = frequencyType === 'recurring';

  switch (activityType) {
    case 'TASK':
      if (recurring) {
        requestUrl += 'repeated_tasks/weekly';
        requestBody = { ...body };
      } else {
        requestUrl += 'tasks';
        requestBody = { task: { ...body } };
      }
      break;
    case 'APPOINTMENT':
      if (recurring) {
        requestUrl += 'repeated_appointments';
        requestBody = { ...body };
      } else {
        requestUrl += 'appointments';
        requestBody = { appointment: { ...body } };
      }
      break;
    case 'CHECK_IN':
      if (recurring) {
        requestUrl += 'repeated_check_ins';
        requestBody = { ...body };
      } else {
        requestUrl += 'check_ins';
        requestBody = { ...body };
      }
      break;
    case 'FORM':
      if (recurring) {
        requestUrl += 'repeated_forms';
        requestBody = { ...body };
      } else {
        requestUrl += 'forms';
        requestBody = { ...body };
      }
      break;
    default:
      requestUrl = '/404';
      break;
  }

  return { requestUrl, requestBody };
};

export const createActivityApiWrapper = (
  requestProvider = request,
  buildRequestParamsProvider = buildRequestParams
) => {
  return async (carePlanId, activity, type) => {
    try {
      const {
        description,
        virtualVisitLink,
        addressFields,
        frequencyType,
        dueAt,
        frequency,
        count,
        endRepeat,
        until,
        interval,
        assignments,
        resources,
        selectedActivityTemplateId,
        checkInTemplateId,
        name,
        formTemplateId,
        visibleToAll
      } = activity;

      const body = {
        type,
        kind: getActivityKind(type), // will be removed in the future
        text: description,
        url: virtualVisitLink,
        address_attributes: getAddressFields(addressFields),
        frequency_type: frequencyType, // 'scheduled', 'recurring', 'once'
        due_at: dueAt,
        frequency, // 'daily', 'weekly', 'monthly', 'yearly'
        count,
        end_repeat: endRepeat,
        until,
        interval,
        assignments: parseAssignments(assignments),
        resources,
        selected_activity_template_id: selectedActivityTemplateId,
        check_in_template_id: checkInTemplateId,
        name: type === 'FORM' ? description : name,
        form_template_id: formTemplateId,
        visible_to_all: visibleToAll
      };

      const { requestUrl, requestBody } = buildRequestParamsProvider(
        carePlanId,
        type,
        dueAt,
        frequencyType,
        body
      );
      const response = await requestProvider(requestUrl, 'POST', requestBody);

      if (response.status === 422) {
        throw new Error();
      }

      return { responseType: 'DATA' };
    } catch (error) {
      return { status: 422, error, responseType: 'ERROR' };
    }
  };
};

export default createActivityApiWrapper(request, buildRequestParams);
