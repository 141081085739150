import React from 'react'
import PropTypes from 'prop-types'

import { t } from 'i18n'

const i18nOpts = { scope: 'activities.assignments.assignment' }

const AssignmentActions = ({ updateStatus }) => (
  <div className="card__button-footer card__button-footer--small">
    <div>
      <button
        data-test="accept"
        type="button"
        onClick={(evt) => updateStatus(evt, 'accepted')}
        className="btn btn--extra-small"
      >
        {t('accept', i18nOpts)}
      </button>
      <button
        data-test="decline"
        type="button"
        onClick={(evt) => updateStatus(evt, 'declined')}
        className="btn btn--secondary btn--extra-small"
      >
        {t('decline', i18nOpts)}
      </button>
    </div>
  </div>
)

AssignmentActions.propTypes = {
  updateStatus: PropTypes.func.isRequired,
}

export default AssignmentActions
