import React from 'react';
import { createAvatar } from '@dicebear/core';
import * as initials from '@dicebear/initials';

import profilePicUpload from './profile-pic-upload.svg';

function getCorrespondingColor(color) {
  let colorMap = {
    '#E0E8E4': '516C5A',
    '#DEEBFE': '345EA1',
    '#FAE8D5': 'DE8950',
    "#EBE3D9": '996B3C',
    "#F9E9ED": 'D75C75'
  }

  return [colorMap[color]];
}

export default function Avatar({
  fullName = '',
  alt = '',
  size = 3.75,
  avatarFile,
  src,
  className,
  border = false,
  pending = false,
}) {
  // Note that status indicator is not supported in the backport version

  let avatar;
  let imageSource;
  // Prevent XSS attacks by removing < and > from the name
  let name = fullName?.replace( /[<>]/ig, '');

  if (name === null || pending || src || avatarFile) {
    if (pending || name === null) {
      imageSource = profilePicUpload;
    } else if (avatarFile) {
      imageSource = URL.createObjectURL(avatarFile)
    } else {
      imageSource = src;
    }

    avatar = <img
      className={`pulse_avatar__image ${border ? 'pulse_avatar__image--border' : ''}`}
      alt={alt != '' && alt ? alt : name}
      src={imageSource}
      style={{ width: `${size}rem`, height: `${size}rem` }}
    />
  } else {
    // Why the same colour over and over? For more diversity, otherwise it will stick with green
    const backgroundColors = [
      "E0E8E4", "DEEBFE", "FAE8D5", "EBE3D9", "F9E9ED",
      "E0E8E4", "DEEBFE", "FAE8D5", "EBE3D9", "F9E9ED",
      "E0E8E4", "DEEBFE", "FAE8D5", "EBE3D9", "F9E9ED"
    ]

    // Pure complete hack to be able to match the background colour with the text colour
    const initialAvatar = createAvatar(initials, { seed: name, backgroundColor: backgroundColors });
    const avatarObject = createAvatar(initials, {
      seed: name,
      size: `${size}rem`,
      radius: 50,
      backgroundColor: backgroundColors,
      textColor: getCorrespondingColor(initialAvatar.toJson().extra.primaryBackgroundColor),
      fontFamily: ["Yantramanav", "sans-serif"],
      fontWeight: 400,
      fontSize: 40,
    });

    avatar = <div className='pulse_avatar__svg' style={{ width: `${size}rem`, height: `${size}rem` }}>
      {border ? <span
        style={{ width: `${size}rem`, height: `${size}rem` }}
        className='pulse_avatar__svg--border'
      ></span> : ''}
      <img
        alt={alt != '' && alt ? alt : name}
        src={avatarObject.toDataUriSync()}
      />
    </div>
  }

  return (<div
    className={`pulse_avatar ${className ? className : ''}`}
  >
    {avatar}
  </div>);
};
