import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import { handleCloseActivityModal } from 'Actions/activityModal';

import { ModalV2 } from 'Components/Base/ModalV2';
import Create from './Create';
import CreateFooter from './Create/CreateFooter';

const i18nOpts = { scope: 'activity_modal' };

export const ActivityModal = ({ isOpen, activityType, handleCloseActivityModal, onCreate }) => {
  const titleKey = activityType === 'TASK' ? 'add_task_title' : 'add_appointment_title';
  const title = t(titleKey, i18nOpts);

  const Footer = <CreateFooter onCreate={onCreate} />;

  return (
    <ModalV2 title={title} isOpen={isOpen} onClose={handleCloseActivityModal} footer={Footer}>
      <Create activityType={activityType} />
    </ModalV2>
  );
};

const mapStateToProps = ({ activityModal }) => {
  const { isOpen, mode, activityType } = activityModal;

  return { isOpen, mode, activityType };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchHandleCloseActivityModal = (dispatch) => {
    return () => {
      dispatch(handleCloseActivityModal());
    };
  };

  return {
    handleCloseActivityModal: dispatchHandleCloseActivityModal(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityModal);
