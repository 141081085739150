import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis, Text, CartesianGrid } from 'recharts';
import XAxisTickFormatter from '../XAxisTickFormatter';
import RenderLegend from '../RenderLegend';
import RenderNoDataPoint from '../RenderNoDataPoint';
import getYAxisTickValue from './getYAxisTickValue';

const i18nOpts = { scope: 'blocks.my_sleep' };

const Chart = ({ data }) => {
  return (
    <div className="chart my-sleep">
      <ResponsiveContainer width="100%" height={250}>
        <BarChart width={730} height={250} data={data} margin={{ left: 25, top: 5 }}>
          <CartesianGrid vertical={false} />
          <Bar
            dataKey="value"
            fill="fill"
            label={(args) => <RenderNoDataPoint {...args} y={105} data={data} />}
          />
          <YAxis
            // combination of the tickcount and interval prop will
            // display an yaxis from noon-noon (24hrs) and 1 value
            // for very 2 ticks. This way we get 2 hr intervals
            tickMargin={25}
            type="number"
            domain={[0, 24]}
            tickCount={25}
            interval={1}
            tick={({ x, y, className, payload: { value } }) => (
              <g transform={`translate(${x - 55}, ${y + 5})`}>
                <Text className={`${className} text text-subtle`}>{getYAxisTickValue(value)}</Text>
              </g>
            )}
          />
          <XAxis
            dataKey="day"
            type="category"
            allowDuplicatedCategory={false}
            axisLine={false}
            tickLine={false}
            interval={0}
            tick={<XAxisTickFormatter />}
          />
        </BarChart>
      </ResponsiveContainer>
      <RenderLegend legend={t('legend', i18nOpts)} />
    </div>
  );
};

Chart.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Chart;
