import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import { CSSTransition } from 'react-transition-group';

import Icon from 'Components/Base/Icon';
import { Tooltip } from 'Components/Base';

const i18nOpts = { scope: 'aside' };

const CloseIcon = ({ position, onClick }) => {
  return (
    <div className="icon__container" onClick={onClick}>
      <div className={`icon aside__header-icon aside__header-icon--${position}`}>
        <Tooltip
          className="icon aside__header-icon"
          text={t('tooltips.close', i18nOpts)}
          tabIndex="0"
          position={position === 'left' ? 'right' : 'left'}
        >
          <Icon name={`arrow-${position}`} />
        </Tooltip>
      </div>
    </div>
  );
};

const Header = ({ position, headerColor, onClose, header }) => {
  const headerContent = typeof header === 'string' ? <span>{header}</span> : header();
  return (
    <div className={`aside__header aside__header--${position} aside__header--${headerColor}`}>
      <div className={`aside__header-content`}>{headerContent}</div>
      <CloseIcon position={position} onClick={onClose} />
    </div>
  );
};

const Aside = ({
  children,
  header,
  headerColor,
  isVisible,
  onClose,
  position,
  timeout,
}) => {
  return (
    <CSSTransition
      in={isVisible}
      classNames="aside__animation"
      timeout={timeout}
      appear
      mountOnEnter
    >
      <aside className={`aside aside--${position}`}>
        <div className="aside__container">
          <Header
            position={position}
            headerColor={headerColor}
            onClose={onClose}
            header={header}
          />
          <div className="aside__content">{children}</div>
        </div>
      </aside>
    </CSSTransition>
  );
};

Aside.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  header: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.string]).isRequired,
  headerColor: PropTypes.oneOf(['grey', 'blue']),
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  position: PropTypes.oneOf(['right', 'left', null]),
  timeout: PropTypes.number,
}

Aside.defaultProps = {
  headerColor: 'grey',
  position: 'right',
  timeout: 250,
}

export default Aside
