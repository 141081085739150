import React from 'react'
import PropTypes from 'prop-types'

import { PainScaleOption, GenericScaleOption, NumericOption, BooleanOption, TextOption } from './'

const CheckInQuestionOptionContainer = ({ type, ...rest }) => {
  switch (type) {
    case 'pain_scale_5':
      return <PainScaleOption {...rest} />
    case 'generic_scale_5':
    case 'generic_scale_10':
    case 'generic_scale_11':
    case 'mood_scale_10':
    case 'multiple_choice':
      return <GenericScaleOption {...rest} />
    case 'numeric_input':
      return <NumericOption {...rest} />
    case 'yes_no':
      return <BooleanOption {...rest} />
    case 'free_text':
      return <TextOption {...rest} />
    default:
      return null
  }
}

CheckInQuestionOptionContainer.propTypes = {
  type: PropTypes.string.isRequired,
}

export default CheckInQuestionOptionContainer
