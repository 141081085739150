import React from 'react';
import { t } from 'i18n'
import SearchFilter from './SearchFilter';
import SortBy from './SortBy';

import ShowMyList from './ShowMyList';
import ShowArchived from './ShowArchived';

const i18nOpts = { scope: 'patient.index' }

const FilterMenu = () => {
  return (
    <nav className="card patients-filters">
      <SearchFilter />
      <SortBy />
      <div className="patients-filters__checks">
        <span className="patients-filters__checks__title">
          {t('filter_checkbox_label', i18nOpts)}
        </span>
        <ShowMyList />
        <ShowArchived />
      </div>
    </nav>
  );
};

export default FilterMenu;
