import Api from 'Api/Api'

class CheckInQuestion extends Api {
  constructor(checkInTemplateId, id) {
    super()

    this.id = id
    this.checkInTemplateId = checkInTemplateId
  }

  get url() {
    return `/check_in_templates/${this.checkInTemplateId}/check_in_questions`
  }
}

export default CheckInQuestion
