export const SET_EVENT_FILTER = 'SET_EVENT_FILTER'
export const TOGGLE_CARE_PLAN_FILTER = 'TOGGLE_CARE_PLAN_FILTER'
export const OPEN_ASIDE = 'OPEN_ASIDE'
export const CLOSE_ASIDE = 'CLOSE_ASIDE'

export const setEventFilter = (filter) => ({
  type: SET_EVENT_FILTER,
  filter,
})

export const toggleCarePlanFilter = (uuid) => ({
  type: TOGGLE_CARE_PLAN_FILTER,
  uuid,
})

export const openAside = () => ({ type: OPEN_ASIDE })
export const closeAside = () => ({ type: CLOSE_ASIDE })
