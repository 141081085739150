import React from 'react';
import { t } from 'i18n';
import moment from 'ct-moment';

const i18nOpts = { scope: 'blocks.recommendations' };

export const attributionText = (translate = t, translateOptions = i18nOpts, timeFormatter = moment) => {
  return (recommendation) => {
    const { user_name, created_at, updated_at } = recommendation;
    const displayDate = timeFormatter(updated_at).format('LL');

    // If the recommendation has never been updated, created_at and updated_at will be the same
    return updated_at === created_at
      ? translate('created_by_with_date', { ...translateOptions, created_at: displayDate, name: user_name })
      : translate('updated_by_with_date', { ...translateOptions, updated_at: displayDate, name: user_name });
  };
};

export default attributionText(t, i18nOpts, moment);
