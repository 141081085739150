import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import DocumentList from './DocumentList'
import ItemListHeader from 'Components/Forms/ItemListHeader'

const i18nOpts = { scope: 'care_plans.document_section.edit.library_documents' }

const SelectedDocuments = ({ libraryDocuments, listIsOpen, toggleListVisibility, toggleSelection }) => {
  return (
    <Fragment>
      <ItemListHeader
        headerText={t('selected_section_header', i18nOpts)}
        iconName="documents-selected"
        itemsSelectedCount={libraryDocuments.length}
        toggleListVisibility={toggleListVisibility}
        listIsOpen={listIsOpen}
      />

      {listIsOpen && (
        <DocumentList
          emptyStateWarning={t('selection_empty', i18nOpts)}
          libraryDocuments={libraryDocuments}
          selected={true}
          toggleSelection={toggleSelection}
        />
      )}
    </Fragment>
  )
}

SelectedDocuments.propTypes = {
  libraryDocuments: PropTypes.array,
  listIsOpen: PropTypes.bool.isRequired,
  toggleListVisibility: PropTypes.func.isRequired,
  toggleSelection: PropTypes.func.isRequired,
}

SelectedDocuments.defaultProps = {
  libraryDocuments: [],
}

export default SelectedDocuments
