import { Resource } from './Api'

class WelcomeBanner extends Resource {
  constructor(banner) {
    super()

    this.banner = banner
    this.dismiss = this.dismiss.bind(this)
  }

  dismiss() {
    return super.update({
      banner: this.banner,
    })
  }

  get url() {
    return `/profile/dismiss`
  }
}

export default WelcomeBanner
