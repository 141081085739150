import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ResourceForm from 'Components/Forms/ResourceForm';

class ResourcesLibraryContainer extends Component {
  constructor(props) {
    super(props);

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
  }

  handleCancel() {
    const { service } = this.props;

    service ? window.location.assign(`/services/${service.id}`) : window.location.assign('/services');
  }

  handleSuccess({ service }) {
    window.location.assign(`/services/${service.id}`);
  }

  render() {
    const { allTags, isAdHoc, service, caredoveEnabled, locale } = this.props;

    return (
      <ResourceForm
        isAdHoc={isAdHoc}
        serviceId={service ? service.id : null}
        service={service}
        allTags={allTags}
        locale={locale}
        onCancel={this.handleCancel}
        onSuccess={this.handleSuccess}
        caredoveEnabled={caredoveEnabled}
      />
    );
  }
}

ResourcesLibraryContainer.propTypes = {
  allTags: PropTypes.array,
  isAdHoc: PropTypes.bool,
  service: PropTypes.object,
  caredoveEnabled: PropTypes.bool
};

ResourcesLibraryContainer.defaultProps = {
  allTags: [],
  isAdHoc: false,
  service: null,
  caredoveEnabled: false
};

export default ResourcesLibraryContainer;
