import React from 'react'

import Status from './Status'
import ProviderWrapper from 'Components/Base/ProviderWrapper'

const Goal = ({ goal, canUpdate }) => (
  <ProviderWrapper>
    <Status canUpdate={canUpdate} {...goal} />
  </ProviderWrapper>
)

export default Goal
