import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import {
  setActivityModalAssignmentsDropdownSearchInput,
  setActivityModalAssignmentsDropdownAssignmentsToDisplay,
} from 'Actions/activityModal';

import sortByKey from '../../sortByKey';

import AssignmentItem from './AssignmentItem';
import FormField from '../../FormField';

const i18nOpts = { scope: 'activity_modal.form_fields.who' };

const renderMainContent = (assignmentsToDisplay) => {
  if (assignmentsToDisplay?.length > 0) {
    const sortedAssignments = sortByKey(assignmentsToDisplay, 'label');
    const assignments = sortedAssignments.map((assignment, id) => {
      return <AssignmentItem key={`assignment-${id}`} assignment={assignment} />;
    });
    return <ul className="assignments_dropdown__assignments_list">{assignments}</ul>;
  } else {
    return <div className="assignments_dropdown__no_assignments">{t('no_team_members', i18nOpts)}</div>;
  }
};

export const handleAssignmentsSearch = (
  setActivityModalAssignmentsDropdownSearchInput,
  setActivityModalAssignmentsDropdownAssignmentsToDisplay,
  assignments,
  assignmentsToDisplay,
  inputValue
) => {
  setActivityModalAssignmentsDropdownSearchInput(inputValue);

  if (assignments?.length > 0) {
    const filteredAssignments = assignments.filter(({ label }) => {
      return label.toLowerCase().includes(inputValue.toLowerCase());
    });
    setActivityModalAssignmentsDropdownAssignmentsToDisplay(filteredAssignments);
  }
};

const removeSelectedAssignments = (assignments, selectedAssignments) => {
  if (!assignments || assignments.length <= 0 || !selectedAssignments || selectedAssignments.length <= 0) {
    return assignments;
  }

  let filteredAssignments = assignments;

  selectedAssignments.forEach((selectedAssignment) => {
    filteredAssignments = filteredAssignments.filter((assignment) => {
      return assignment.value !== selectedAssignment.value;
    });
  });

  return filteredAssignments;
};

export const AssignmentsMenu = ({
  assignmentsDropdown,
  activityProperties,
  setActivityModalAssignmentsDropdownSearchInput,
  setActivityModalAssignmentsDropdownAssignmentsToDisplay,
}) => {
  const { searchInput, assignments, assignmentsToDisplay } = assignmentsDropdown;

  // remove assignments that were already selected
  const filteredAssignments = removeSelectedAssignments(assignmentsToDisplay, activityProperties.assignments);

  return (
    <div className="assignments_dropdown">
      <FormField
        className="assignments_dropdown__search_input"
        value={searchInput}
        name="searchInput"
        onChange={(value) => {
          handleAssignmentsSearch(
            setActivityModalAssignmentsDropdownSearchInput,
            setActivityModalAssignmentsDropdownAssignmentsToDisplay,
            assignments,
            assignmentsToDisplay,
            value
          );
        }}
      />

      {renderMainContent(filteredAssignments)}
    </div>
  );
};

const mapStateToProps = ({ activityModal }) => {
  const { assignmentsDropdown, activityProperties } = activityModal;

  return { assignmentsDropdown, activityProperties };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalAssignmentsDropdownSearchInput = (dispatch) => {
    return (searchInput) => {
      dispatch(setActivityModalAssignmentsDropdownSearchInput(searchInput));
    };
  };

  const dispatchSetActivityModalAssignmentsDropdownAssignmentsToDisplay = (dispatch) => {
    return (assignmentsToDisplay) => {
      dispatch(setActivityModalAssignmentsDropdownAssignmentsToDisplay(assignmentsToDisplay));
    };
  };

  return {
    setActivityModalAssignmentsDropdownSearchInput:
      dispatchSetActivityModalAssignmentsDropdownSearchInput(dispatch),
    setActivityModalAssignmentsDropdownAssignmentsToDisplay:
      dispatchSetActivityModalAssignmentsDropdownAssignmentsToDisplay(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentsMenu);
