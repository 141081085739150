import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';

import Label from './Label';

const i18nOpts = { scope: 'forms.password_input' };

export const getInputContainerClass = (inputContainerClassName, error, focused) => {
  let inputContainerClass = 'input_container';

  if(inputContainerClassName) {
    inputContainerClass += ` ${inputContainerClassName}`;
  }

  if (error) {
    inputContainerClass += ' input_container--error';
  }

  if (!error && focused) {
    inputContainerClass += ' input_container--focused';
  }

  return inputContainerClass;
};

export const getContainerClass = (containerClassName) => {
  let containerClass = 'password';
  if (containerClassName) {
    containerClass += ` ${containerClassName}`;
  }

  return containerClass;
};

const PasswordInput = ({
  id,
  label,
  required,
  name,
  value,
  onChange,
  containerClassName,
  inputContainerClassName,
  hint,
  onBlur,
  errors={},
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [focused, setFocused] = useState(false);
  const error = errors[name];

  return (
    <div className={getContainerClass(containerClassName)}>
      {label && <Label text={label} inputName={name} required={required} />}
      <div className={getInputContainerClass(inputContainerClassName, error, focused)}>
        <input
          id={id}
          name={name}
          value={value}
          required
          hint="false"
          autoComplete="false"
          onChange={onChange}
          className="input_container__input_field"
          type={showPassword ? 'text' : 'password'}
          onBlur={(target) => {
            setFocused(false);
            onBlur && onBlur(target);
          }}
          onFocus={() => {
            setFocused(true);
          }}
        />
        <a
          className="input_container__password_show"
          onClick={() => {
            setShowPassword(!showPassword);
          }}
        >
          {showPassword ? t('hide', i18nOpts) : t('show', i18nOpts)}
        </a>
      </div>
      {!error && hint && <div className="input_container__hint">{hint}</div>}
      {error && <div className="input_container__error">{error}</div>}
    </div>
  );
};

PasswordInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  containerClassName: PropTypes.string,
  inputContainerClassName: PropTypes.string,
  hint: PropTypes.string,
  onBlur: PropTypes.func,
  errors: PropTypes.object,
};

PasswordInput.defaultProps = {
  id: 'password',
};

export default PasswordInput;
