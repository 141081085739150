import React, { useState } from 'react';
import { t } from 'i18n';

import Card from 'Components/Base/CardEditable/Card';
import Show from './Show';
import Edit from './Edit';

const EmergencyInstruction = (props) => {
  const { originalEmergencyInstructionFormatted, updatedAt, updatedBy } = props;

  const i18nOpts = { scope: 'emergency_instruction' };

  const defaultEmergencyInstruction = t('default', i18nOpts);
  const formattedDefault = [{ insert: defaultEmergencyInstruction }];

  const [editForm, setEditForm] = useState(false);

  const [emergencyInstructionFormatted, setEmergencyInstructionFormatted] = useState(
    originalEmergencyInstructionFormatted || formattedDefault
  );

  const handleClick = () => {
    setEditForm(!editForm);
  };

  const discardChanges = () => {
    setEmergencyInstructionFormatted(originalEmergencyInstructionFormatted || formattedDefault);
    setEditForm(false);
  };

  return (
    <Card
      title={t('header', i18nOpts)}
      handleClick={handleClick}
      containerClass="emergency-instruction"
      showEdit={!editForm}
    >
      <p className="info">{t('info', i18nOpts)}</p>
      {editForm ? (
        <Edit
          defaultEmergencyInstruction={formattedDefault}
          contentFormatted={emergencyInstructionFormatted}
          setContentFormatted={setEmergencyInstructionFormatted}
          discardChanges={discardChanges}
          setEditForm={setEditForm}
        />
      ) : (
        <Show
          emergencyInstructionFormatted={emergencyInstructionFormatted}
          updatedAt={updatedAt}
          updatedBy={updatedBy}
        />
      )}
    </Card>
  );
};

export default EmergencyInstruction;
