import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import Button from '../Base/Button'
import Icon from '../Base/Icon'
import { Footer } from 'Components/Base/Card/'

import banner from 'my_tasks_banner.svg'

const i18nOpts = { scope: 'welcome_banner.my_tasks_banner' }

const MyTasksBanner = ({ close }) => (
  <div className="card card--wave-header">
    <header className="card__image-header">
      <div className="card__image-header-text">{t('header', i18nOpts)}</div>

      <div className="card__image-header-image-center">
        <img src={banner} />
      </div>

      <div onClick={close} className="card__image-header-close">
        <Icon name="close" />
      </div>
    </header>

    <div className="card__body">
      <p className="h3 text-grey-darker">{t('basic_details', i18nOpts)}</p>
      <br />

      <Footer right={<Button text={t('button', i18nOpts)} onClick={close} />} />
    </div>
  </div>
)

MyTasksBanner.propTypes = {
  close: PropTypes.func.isRequired,
}

export default MyTasksBanner
