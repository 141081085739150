import React from 'react'
import PropTypes from 'prop-types'

import SupportTeamMember from './SupportTeamMember'
import ServiceMember from './ServiceMember'

const TeamMember = ({ address, locale, member, carePlanId, toggleInviteFlash }) => {
  if (member.role === 'service') {
    return <ServiceMember address={address} locale={locale} {...member} />
  } else {
    return <SupportTeamMember member={member} carePlanId={carePlanId} toggleInviteFlash={toggleInviteFlash} />
  }
}

TeamMember.propTypes = {
  address: PropTypes.string.isRequired,
  carePlanId: PropTypes.string,
  locale: PropTypes.string.isRequired,
  member: PropTypes.object.isRequired,
  toggleInviteFlash: PropTypes.func,
}

export default TeamMember
