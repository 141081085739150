import validateDoseValues from './validateDoseValues';

export const handleValidateAndShowErrors = (validationProvider) => (doseValues, setErrors) => {
  const { isValid, inputErrors } = validationProvider(doseValues);

  if (!isValid) {
    setErrors(inputErrors);
  } else {
    setErrors(null);
  }
};

export default handleValidateAndShowErrors(validateDoseValues);
