import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'i18n';

import InputGroup from 'Components/Forms/InputGroup';
import Button from 'Components/Base/Button';

import { submitCode } from 'Actions/signupPage';

const i18nOpts = { scope: 'registration.code' };

const CodeForm = ({ placeCode, submitCode, isOpen }) => {
  const [code, setCode] = useState(placeCode);

  return (
    <>
      <div className="form__header">
        <p className="h3 text-grey-darker text-bold">{t('enter_code', i18nOpts)}</p>
        {isOpen && <small className="text-primary-lighter">{t('subtitle', i18nOpts)}</small>}
      </div>

      {isOpen && (
        <>
          <div className="form__body">
            <InputGroup
              component="input"
              name="code"
              label={t('label', i18nOpts)}
              value={code}
              onChange={(evt) => setCode(evt.target.value)}
              className="input input--one-third"
            />
          </div>

          <div className="form__footer">
            <Button className="form__footer__submit" onClick={() => submitCode(code)}>
              {t('next', i18nOpts)}
            </Button>
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ signupPage: { code } }) => ({ placeCode: code });

const mapDispatchToProps = {
  submitCode: (code) => submitCode(code),
};

CodeForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  placeCode: PropTypes.string.isRequired,
  submitCode: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CodeForm);
