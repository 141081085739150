import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import banner from 'invite_confirm.svg'
import RoleChip from '../RoleChip'
import MemberCard from './MemberCard'
import { Icon, Button, ModalFooter } from 'Components/Base'

const i18nOpts = { scope: 'team.modal.review' }

const ReviewStep = ({ membersByRole, error, onEdit, onDelete, onAdd, onInvite, onCancel }) => {
  return (
    <Fragment>
      <header className="wave-header">
        <h2 className="wave-header__title">{t('header_title', i18nOpts)}</h2>
        <img src={banner} className="wave-header__banner wave-header__banner--invite-confirm" />
      </header>

      <h2 className="team-invitations-modal__review-title hidden--sm hidden--vert-sm">
        {t('header_title', i18nOpts)}
      </h2>

      {error && (
        <div className="input--errors">
          <div className="error">{t('error', i18nOpts)}</div>
        </div>
      )}

      <div>
        {['primary_caregiver', 'caregiver', 'support', 'unaffiliated_health_care'].map(
          (role) =>
            membersByRole[role] && (
              <Fragment key={role}>
                <div className="team-invitations-modal__role-title">
                  <h3 className="team-invitations-modal__role-name text-medium">{t(role, i18nOpts)}</h3>
                  {role !== 'unaffiliated_health_care' && <RoleChip role={role} />}
                </div>

                {membersByRole[role].map((member) => (
                  <MemberCard
                    key={member.id}
                    member={member}
                    onEdit={onEdit.bind(null, member.id)}
                    onDelete={onDelete.bind(null, member.id)}
                  />
                ))}
              </Fragment>
            )
        )}
      </div>

      <Button data-test="add" className="btn btn--link careplan__list-add-button" onClick={onAdd}>
        <Icon name="plus_square" className="careplan-wizard__icon_add" />
        {t('add_another', i18nOpts)}
      </Button>

      <ModalFooter
        renderRight={() => (
          <Fragment>
            <Button
              data-test="cancel"
              text={t('cancel', i18nOpts)}
              onClick={onCancel}
              className="btn btn--secondary"
            />
            <Button data-test="invite" text={t('invite', i18nOpts)} className="btn" onClick={onInvite} />
          </Fragment>
        )}
      />
    </Fragment>
  )
}

ReviewStep.propTypes = {
  error: PropTypes.bool,
  membersByRole: PropTypes.object,
  onAdd: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onInvite: PropTypes.func.isRequired,
}

ReviewStep.defaultProps = {
  error: false,
  membersByRole: {},
}

export default ReviewStep
