export const setupFirstNameValidator = (formErrors, setFormErrors, t, i18nOpts) => {
  return (firstName) => {
    if (!firstName) {
      setFormErrors({ ...formErrors, firstName: t('blank_first_name', i18nOpts) });
    } else {
      setFormErrors({ ...formErrors, firstName: '' });
    }
  };
};

export const setupLastNameValidator = (formErrors, setFormErrors, t, i18nOpts) => {
  return (lastName) => {
    if (!lastName) {
      setFormErrors({ ...formErrors, lastName: t('blank_last_name', i18nOpts) });
    } else {
      setFormErrors({ ...formErrors, lastName: '' });
    }
  };
};

export const setupEmailValidator = (formErrors, setFormErrors, t, i18nOpts) => {
  return (email) => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email) {
      setFormErrors({ ...formErrors, email: t('blank_email', i18nOpts) });
    } else if (!emailRegex.test(email)) {
      setFormErrors({ ...formErrors, email: t('invalid_email', i18nOpts) });
    } else {
      setFormErrors({ ...formErrors, email: '' });
    }
  };
};

export const setupPasswordValidator = (formErrors, setFormErrors, t, i18nOpts) => {
  return (password) => {
    const passwordRegex =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!\"#$%&`()*+,-./:;<=>?@\[\\\]^_{|}~]).{8,}$/;

    if (!password) {
      setFormErrors({ ...formErrors, password: t('blank_password', i18nOpts) });
    } else if (!passwordRegex.test(password)) {
      setFormErrors({ ...formErrors, password: t('invalid_password', i18nOpts) });
    } else {
      setFormErrors({ ...formErrors, password: '' });
    }
  };
};
