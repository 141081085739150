import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { t } from 'i18n'

import { Icon } from 'Components/Base';
import InputGroup from 'Components/Forms/InputGroup'
import Submit from 'Components/Forms/Submit'

import InvitationApi from 'Api/Invitation'

const i18nOpts = { scope: 'care_plan_creators.careteam.patient_form' }

class InvitationForm extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleErrors = this.handleErrors.bind(this)
    this.handleSubmitError = this.handleSubmitError.bind(this)

    this.state = {
      patient: { invitation: { email: '' } },
      errors: {},
      submitError: false
    }
  }

  get email() {
    return this.state.patient.invitation.email
  }

  get params() {
    return { email: this.email, sendInvitation: true }
  }

  handleChange({ target: { name, value } }) {
    this.setState((prevState) => _.set(prevState, name, value))
  }

  handleSubmit(evt) {
    evt.preventDefault()

    return new InvitationApi(this.props.teamMembershipId).update(this.params).then((res) => {
      if (res.ok) {
        res.json().then(({ teamMembership: { invitation: { email } } }) => this.props.onSuccess(email))
      } else {
        this.handleSubmitError();
      }
    })
  }

  handleErrors(errors) {
    this.setState({ errors })
  }

  handleSubmitError() {
    this.setState({ submitError: true })
  }

  render() {
    return (
      <Fragment>
        <hr />

        <form className="form form--inline" onSubmit={this.handleSubmit} noValidate>
          <InputGroup
            className="input input--flex input--invitations email-invitation"
            name="patient.invitation.email"
            type="text"
            component="input"
            value={this.email}
            onChange={this.handleChange}
            label={t('email', i18nOpts)}
            errors={this.state.errors}
            icon={this.props.isCareHubPatient && <Icon className="sync-icon" name='sync' />}
          />

          <Submit value={t('invite', i18nOpts)} />
        </form>
        {this.state.submitError && <p className="submit_error">{t('submit_error', i18nOpts)}</p>}
      </Fragment>
    )
  }
}

InvitationForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  teamMembershipId: PropTypes.number.isRequired,
}

InvitationForm.defaultProps = {}

export default InvitationForm
