import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

import configureStore from './configureStore';

import SideNav from './SideNav';
import Conversation from './Conversation';

import { setCurrentUser } from 'Actions/current-user';
import { receiveConversation, updateEmergencyInstruction } from 'Actions/inbox/conversation';
import {
  receiveContacts,
  receiveChannelContacts,
  addContact,
  markConversationAsUnread,
  updateHasMoreChannelPage,
} from 'Actions/inbox/sideNav/contacts';
import { getConversation } from 'Selectors/inbox';

class Inbox extends Component {
  constructor(props) {
    super(props);

    const { store } = configureStore(
      {
        currentUser: {},
      },
      props.currentPath
    );

    this.store = store;
    this.createSubscription = this.createSubscription.bind(this);
  }

  createSubscription() {
    App.cable.subscriptions.create(
      { channel: 'ContactsChannel' },
      {
        received: (data) => {
          this.store.dispatch(addContact(data));

          // Do not set unread for currently open conversation
          if (data.conversation.id !== getConversation(this.store.getState()).id) {
            this.store.dispatch(markConversationAsUnread(data.id));
          }
        },
      }
    );
  }

  componentDidMount() {
    this.store.dispatch(setCurrentUser(this.props.currentUser));
    this.store.dispatch(receiveContacts(this.props.contacts));
    this.store.dispatch(receiveChannelContacts(this.props.contacts));
    this.store.dispatch(receiveConversation(this.props.conversation));
    this.store.dispatch(updateHasMoreChannelPage(this.props.hasMoreChannelPage));
    this.store.dispatch(updateEmergencyInstruction(this.props.emergencyInstruction));
    this.createSubscription();
  }

  render() {
    return (
      <Provider store={this.store}>
        <section className="container-inbox">
          <SideNav isPlaceUser={this.props.currentUser.isPlaceUser} />

          <Conversation isPlaceUser={this.props.currentUser.isPlaceUser} />
        </section>
      </Provider>
    );
  }
}

Inbox.propTypes = {
  contacts: PropTypes.array,
  conversation: PropTypes.object,
  currentPath: PropTypes.string,
  currentUser: PropTypes.object,
  hasMoreChannelPage: PropTypes.bool,
};

Inbox.defaultProps = {
  contacts: [],
  conversation: {},
  currentPath: '',
  currentUser: {},
  hasMoreChannelPage: false,
};

export default Inbox;
