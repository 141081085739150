import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Tooltip } from 'Components/Base';

import { t } from 'i18n';

const i18nOpts = { scope: 'care_plan_creators.pill_actions' };

class InputList extends Component {
  constructor(props) {
    super(props);

    this.onDelete = this.onDelete.bind(this);
    this.renderItem = this.renderItem.bind(this);
  }

  onDelete(name) {
    const target = {
      name: `${name}._destroy`,
      value: '1',
    };

    // Run `changeAndSubmit` if it's passed in as a prop. Otherwise run the default `onChange`
    if (typeof this.props.changeAndSubmit == 'function') {
      this.props.changeAndSubmit({ target });
    } else {
      this.props.onChange({ target });
    }
  }

  renderItem(item, i) {
    if (!item._destroy) {
      const name = `${this.props.name}[${i}]`;
      const removeInviteClass = this.props.removeInviteClass;
      return (
        <div key={i}>
          <div className="careplan-wizard__input-item">
            {this.props.render(item, name)}

            <button
              className={`${removeInviteClass} btn--link btn--icon careplan-wizard__input-btn-delete`}
              type="button"
              onClick={this.onDelete.bind(null, name)}
            >
              <Tooltip text={t('minus-circle', i18nOpts)}>
                <Icon className="icon--medium icon__trash" name="trash" />
              </Tooltip>
            </button>
          </div>

          {this.props.renderExtra && this.props.renderExtra(item, name, i)}
        </div>
      );
    }
  }

  render() {
    const { list, emptyMessage } = this.props;

    if (!list.length || !list.filter((i) => !i._destroy).length) {
      return <p dangerouslySetInnerHTML={{ __html: emptyMessage }} className="careplan__empty-list" />;
    } else {
      return list.map(this.renderItem);
    }
  }
}

InputList.propTypes = {
  emptyMessage: PropTypes.string.isRequired,
  list: PropTypes.array,
  name: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
  renderExtra: PropTypes.func,
  removeInviteClass: PropTypes.string,
  changeAndSubmit: PropTypes.func,
};

InputList.defaultProps = {
  list: [],
};

export default InputList;
