export const SET_IS_EDITING = 'SET_IS_EDITING';
export const SET_DOSE_VALUES = 'SET_DOSE_VALUES';
export const SET_CREATED_BY_USER = 'SET_CREATED_BY_USER';
export const SET_UPDATED_AT = 'SET_UPDATED_AT';
export const SET_INPUT_ERRORS = 'SET_INPUT_ERRORS';
export const SET_SELECTED_HISTORICAL_TAB = 'SET_SELECTED_HISTORICAL_TAB';

const setIsEditing = (isEditing) => ({
  type: SET_IS_EDITING,
  isEditing,
});

const setDoseValues = (doseValues) => ({
  type: SET_DOSE_VALUES,
  doseValues,
});

const setUpdatedAt = (updatedAt) => ({
  type: SET_UPDATED_AT,
  updatedAt,
});

const setCreatedByUser = (createdByUser) => ({
  type: SET_CREATED_BY_USER,
  createdByUser,
});

const setInputErrors = (inputErrors) => ({
  type: SET_INPUT_ERRORS,
  inputErrors,
});

const setSelectedHistoricalTab = (selectedHistoricalTab) => ({
  type: SET_SELECTED_HISTORICAL_TAB,
  selectedHistoricalTab,
});

export {
  setIsEditing,
  setDoseValues,
  setInputErrors,
  setSelectedHistoricalTab,
  setCreatedByUser,
  setUpdatedAt,
};
