import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Button, IconButton, ModalFooter } from 'Components/Base'

import { ItemPill, ItemPillText } from 'Components/Base/ItemPill'

import banner from 'modify_invitation_cancel.svg'

const i18nOpts = { scope: 'team.remove_invitation' }

const RemoveInvitation = ({ member, onBack, onRemove }) => {
  return (
    <Fragment>
      <div className="ReactModal__ContentBanner ReactModal__ContentBanner--simple">
        <div className="ReactModal__ContentBanner-text">{t('remove', i18nOpts)}</div>

        <img src={banner} className="ReactModal__ContentBanner-image" />
      </div>

      <div className="ReactModal__ContentBody-text">
        <div className="text-medium">{t('confirmation', i18nOpts)}</div>
        {t('information', i18nOpts)}
      </div>

      <ItemPill isolated flat stretch>
        <ItemPillText text={member.email} subtext={t(`team.roles.${member.role}`)} large inline />
      </ItemPill>

      <ModalFooter
        renderRight={() => <Button onClick={onRemove} text={t('remove', i18nOpts)} />}
        renderLeft={() => (
          <IconButton text={t('back', i18nOpts)} isSecondary onClick={onBack} iconName="chevron-left" />
        )}
      />
    </Fragment>
  )
}

RemoveInvitation.propTypes = {
  member: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
  onBack: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
}

export default RemoveInvitation
