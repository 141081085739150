import { encodeToBase64 } from 'Utilities/encodeToBase64';

export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
export const GO_TO_NEXT_STEP = 'GO_TO_NEXT_STEP';
export const SET_TARGET_USER_INFO = 'SET_TARGET_USER_INFO';
export const SET_AVATAR = 'SET_AVATAR';
export const SUBMIT_CODE = 'SUBMIT_CODE';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_API_ERROR_MESSAGE = 'SET_API_ERROR_MESSAGE';

const setCurrentStep = (index) => ({
  type: SET_CURRENT_STEP,
  index,
});

const setTargetUserInfo = (targetInfo) => ({
  type: SET_TARGET_USER_INFO,
  targetInfo,
});

const setAvatar = (file) => {
  return async (dispatch) => {
    try {
      const encodedAvatar = await encodeToBase64(file);
      dispatch({
        type: SET_AVATAR,
        file,
        encodedAvatar,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

const submitCode = (code) => ({
  type: SUBMIT_CODE,
  code,
});

const goToNextStep = () => ({ type: GO_TO_NEXT_STEP });

const setIsLoading = (isLoading) => ({
  type: SET_IS_LOADING,
  isLoading,
});

const setApiErrorMessage = (apiErrorMessage) => ({
  type: SET_API_ERROR_MESSAGE,
  apiErrorMessage,
});

export {
  setCurrentStep,
  setTargetUserInfo,
  setAvatar,
  submitCode,
  goToNextStep,
  setIsLoading,
  setApiErrorMessage,
};
