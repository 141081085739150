import { Component } from 'react'
import PropTypes from 'prop-types'

import InvitationApi from 'Api/Invitation'

class InvitationForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: props.member.email,
      errors: {},
      isSubmitting: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleErrors = this.handleErrors.bind(this)
  }

  get params() {
    return { email: this.state.email, sendInvitation: true }
  }

  get emailChanged() {
    return this.state.email !== this.props.member.email
  }

  handleChange({ target: { value: email } }) {
    this.setState({ email })
  }

  handleErrors(errors) {
    this.setState({ errors, isSubmitting: false })
  }

  handleSubmit() {
    this.setState({ isSubmitting: true })

    return new InvitationApi(this.props.member.id).update(this.params).then((res) => {
      if (res.ok) {
        window.location.reload()
      } else if (res.status === 422) {
        return res.json().then(this.handleErrors)
      }
    })
  }

  render() {
    return this.props.renderProps({
      email: this.state.email,
      isSubmitting: this.state.isSubmitting,
      errors: this.state.errors,
      onChange: this.handleChange,
      onSubmit: this.handleSubmit,
      emailChanged: this.emailChanged,
    })
  }
}

InvitationForm.propTypes = {
  member: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
  renderProps: PropTypes.func.isRequired,
}

export default InvitationForm
