import React from 'react'
import { t } from 'i18n'

const i18nOpts = { scope: 'team.manage_invitation' }

const ManageInviteButton = ({ carePlanUuid }) => (
  <a href={`/care_plans/${carePlanUuid}/patient`} className="btn btn--extra-small sidebar-section__manage-invitation-btn">
    {t('header', i18nOpts)}
  </a>
);

export default ManageInviteButton;
