import { Resource } from '../Api'

class User extends Resource {
  constructor(carePlanUuid) {
    super()

    this.carePlanUuid = carePlanUuid
  }

  update({ patient, updateCareHub = false }) {
    const {
      firstName: first_name,
      lastName: last_name,
      gender,
      birthday,
      email,
      patientHealthInformation: {
        medicalRecordNumber: medical_record_number,
        primaryPhysician: primary_physician,
      },
      preferredPhoneNumber: preferred_phone_number,
      preferredAddress: {
        address,
        extendedAddress: extended_address,
        city,
        region,
        postalCode: postal_code,
        countryCode: country_code,
        label,
      },
    } = patient

    return super.update({
      updateCareHub,
      patient: {
        first_name,
        last_name,
        gender,
        email,
        birthday,
        patient_health_information: {
          medical_record_number,
          primary_physician,
        },
        preferred_phone_number,
        preferred_address: {
          address,
          extended_address,
          city,
          region,
          postal_code,
          country_code,
          label,
        },
      },
      team_membership: {
        invitation_attributes: {
          email
        }
      },
    })
  }

  get url() {
    return `/care_plans/${this.carePlanUuid}/patient`
  }
}

export default User
