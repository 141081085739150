import { request } from 'Apiv2/handleRequest';

const getPatientsAndArchivedApiWrapper =
  (requestProvider = request) =>
  async () => {
    try {
      const ENDPOINT_URL = '/patients';
      const response = await requestProvider(ENDPOINT_URL, 'GET');

      if (response.status === 200) {
        const { patients, archived_patient_ids } = await response.json();

        return {
          responseType: 'DATA',
          patients,
          archivedPatientIds: archived_patient_ids,
        };
      } else {
        throw 'Missing data';
      }
    } catch (error) {
      console.error(error);
      return { responseType: 'ERROR' };
    }
  };

export default getPatientsAndArchivedApiWrapper(request);
