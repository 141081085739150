import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { CSSTransition, TransitionGroup, Transition } from 'react-transition-group'

import Contacts from './Contacts'
import NewContacts from './NewContacts'
import CarePlans from './CarePlans'
import Channels from './Channels'

import { getSideNavMode } from 'Selectors/inbox'

function sideNav({ mode }) {
  switch (mode) {
    case 'new':
      return (
        <CSSTransition key="new" classNames="slide" timeout={250}>
          <NewContacts />
        </CSSTransition>
      )
    case 'ongoing':
      return (
        <Transition key="ongoing" timeout={{ enter: 0, exit: 500 }} in={false}>
          <Contacts />
        </Transition>
      )
    case 'carePlans':
    case 'channelCarePlans':
      return (
        <CSSTransition key="new" classNames="slide" timeout={250}>
          <CarePlans />
        </CSSTransition>
      )
    case 'channelMembers':
    case 'channelName':
      return (
        <CSSTransition key="new" classNames="slide" timeout={250}>
          <Channels />
        </CSSTransition>
      )
    default:
      return null
  }
}

const SideNav = (props) => <TransitionGroup>{sideNav(props)}</TransitionGroup>

SideNav.propTypes = {
  mode: PropTypes.string,
}

SideNav.defaultProps = {
  mode: 'ongoing',
}

const mapStateToProps = (state, ownProps) => ({
  mode: getSideNavMode(state),
  ...ownProps,
})

export default connect(mapStateToProps, null)(SideNav)
