// https://github.com/zloirock/core-js/issues/741#issuecomment-570677904
// https://github.com/zloirock/core-js/issues/751
// Importing this library here instead of polyfills.js due to an issue
// with the Inbox components failing. This is possibly due to another
// component using regexp in a different way than fetch.
import 'core-js/stable/regexp';

class Api {
  index() {
    return this.request(this.url);
  }

  show() {
    return this.request(`${this.url}/${this.id}`);
  }

  new(query) {
    return this.request(`${this.url}/new${query}`);
  }

  create(body) {
    return this.request(this.url, 'POST', body);
  }

  update(body) {
    return this.request(`${this.url}/${this.id}`, 'PATCH', body);
  }

  destroy(body) {
    return this.request(`${this.url}/${this.id}`, 'DELETE', body);
  }

  request(url, method = 'GET', body = null, dot_json = true) {
    const formDataRequest = body instanceof FormData;

    const headers = {
      Accept: 'application/json',
      'X-CSRF-Token': this.csrfToken,
    };

    // https://itnext.io/uploading-files-to-your-rails-api-6b293a4a5c90
    if (!formDataRequest) {
      headers['Content-Type'] = 'application/json';
    }

    const options = {
      credentials: 'include',
      headers,
      method,
    };

    if (body) {
      options['body'] = formDataRequest ? body : JSON.stringify(body);
    }

    if (dot_json) {
      url = `${url}.json`;
    }

    return fetch(`${url}`, options)
      .then((res) => {
        if (res.status === 401) {
          res.json().then(() => window.location.assign('/login'));
        } else if (res.status === 404) {
          res.json().then(({ error }) => error && window.flash_messages.addMessage(error));
        }
        return res;
      })
      .catch((err) => console.error(`${method} request to ${url} failed: ${err.message}`));
  }

  get url() {
    throw 'missing url for API request';
  }

  get csrfToken() {
    const tokenElement = document.querySelector('meta[name="csrf-token"]');

    return tokenElement && tokenElement.content;
  }
}

export default Api;
