import Api from './Api'

class Appointment extends Api {
  constructor(carePlanId, id) {
    super()

    this.carePlanId = carePlanId
    this.id = id
    this.create = this.create.bind(this)
  }

  create({
    parent_id,
    due_at,
    url,
    practitioner,
    text,
    kind,
    address_attributes,
    assignments,
    help_request,
    resources,
    selectedActivityTemplateId,
  }) {
    return super.create({
      appointment: {
        practitioner,
        parent_id,
        due_at,
        url,
        text,
        kind,
        address_attributes,
        assignments,
        help_request,
        resources,
        selectedActivityTemplateId,
      },
    })
  }

  update({
    parent_id,
    due_at,
    url,
    practitioner,
    text,
    kind,
    address_attributes,
    assignments,
    help_request,
    resources,
  }) {
    return super.update({
      appointment: {
        practitioner,
        parent_id,
        due_at,
        url,
        text,
        kind,
        address_attributes,
        assignments,
        help_request,
        resources,
      },
    })
  }

  get url() {
    return `/care_plans/${this.carePlanId}/appointments`
  }
}

export default Appointment
