import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'
import { ascBy } from 'Utilities/sort'

import { Card } from 'Components/Base/Card/'
import FormResult from './FormResultSection/FormResult'

const i18nOpts = { scope: 'patient.form_result' }

const FormResultSection = ({ submittedForms }) => {
  return (
    <Card title={`${t('header', i18nOpts)}`} collapsable>
      {submittedForms.length ? (
        submittedForms.sort(ascBy('completedAt')).map((form) => {
          return <FormResult key={form.id} form={form} />
        })
      ) : (
        <div className="item-pill-section--item-list-empty">{t('none', i18nOpts)}</div>
      )}
    </Card>
  )
}

FormResultSection.propTypes = {
  submittedForms: PropTypes.array.isRequired,
}

FormResultSection.defaultProps = {}

export default FormResultSection
