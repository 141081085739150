import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import Filters from '../Filters'

const TaskListHeader = ({ completed }) => (
  <div className="header select-header">
    <h2 className="text-medium">
      {t('heading', { scope: `tasks.index.tasks.${completed ? 'completed' : 'incomplete'}` })}
    </h2>

    <Filters />
  </div>
)

TaskListHeader.propTypes = {
  completed: PropTypes.bool,
}

TaskListHeader.defaultProps = {
  completed: false,
}

export default TaskListHeader
