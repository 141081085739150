import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { ItemPill, ItemPillText, ItemPillActions } from 'Components/Base/ItemPill'
import Icon from 'Components/Base/Icon'

class InputItemPill extends PureComponent {
  constructor(props) {
    super(props)
  }

  get checkboxProps() {
    const { radio, selected } = this.props

    const listStyle = radio ? 'circle' : 'checkbox'
    const iconName = selected ? `${listStyle}-selected` : `${listStyle}-unselected`
    const className = classNames({
      icon: true,
      'icon--medium': true,
      'icon--green': selected,
    })
    return { name: iconName, className }
  }

  render() {
    const { selected, stretch } = this.props
    const { name, renderActions, renderImage, title, titleProminence, toggleSelection } = this.props

    return (
      <ItemPill selected={selected} stretch={stretch}>
        {renderImage()}

        { titleProminence ? (
            <ItemPillText text={title} subtext={name} data-test="item-list-option-title" />
          ) : (
            <ItemPillText text={name} subtext={title} data-test="item-list-option-name" />
          )
        }

        <ItemPillActions>
          {renderActions && renderActions()}

          <div
            className="item-pill__action item-pill__action--medium"
            onClick={toggleSelection}
            data-test="checkbox-icon"
          >
            <Icon {...this.checkboxProps} />
          </div>
        </ItemPillActions>
      </ItemPill>
    )
  }
}

InputItemPill.propTypes = {
  name: PropTypes.string.isRequired,
  radio: PropTypes.bool,
  renderActions: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  renderImage: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  stretch: PropTypes.bool,
  title: PropTypes.string,
  titleProminence: PropTypes.bool,
  toggleSelection: PropTypes.func.isRequired,
}

InputItemPill.defaultProps = {
  radio: false,
  renderActions: false,
  stretch: false,
  title: '',
}

export default InputItemPill
