import React from 'react';
import Icon from 'Components/Base/Icon';
import { t } from 'i18n';
import classNames from 'classnames';

const ICON_MAP = {
  doctor: 'doctor',
  home_school: 'school',
  sick: 'sick',
  online: 'online',
  holiday: 'backpack',
  vacation: 'vacation',
  weekend: 'calendar-weekend',
  chronic_pain: 'chronic_pain',
  attended: 'check',
};

const i18nOpts = { scope: 'blocks.going_to_school' };

const Chart = ({ data }) => {
  return (
    <div className="chart going-to-school">
      {data.map(({ day, value }, index) => {
        const classes = classNames({
          event: true,
          'event--no-data': value === '',
          'event--future': value === null,
        });
        return (
          <div key={index} className="day">
            <div className={`${classes}`}>
              <span>{t(value || 'no_data', i18nOpts)}</span>
              <Icon name={ICON_MAP[value] || 'close'} />
            </div>
            <span className="xAxis text text-subtle">{t(day, { scope: 'blocks.days' })}</span>
          </div>
        );
      })}
    </div>
  );
};

export default Chart;
