import fetchDataApiRequest from './fetchDataApiRequest';

export const fetchData = (getDataFromApi = fetchDataApiRequest) => {
  return async (
    carePlanUuid,
    setPageState,
    setDoseValues,
    setCreatedByUser,
    setUpdatedAt,
    transformDataToCamelCase
  ) => {
    const response = await getDataFromApi(carePlanUuid);

    if (response.content_type === 'ERROR') {
      setPageState('error');
      return;
    }

    // If there is data returned, transform and put that data into state
    if (response.data) {
      const transformedData = transformDataToCamelCase(response.data);
      setDoseValues(transformedData);
      setCreatedByUser(response.created_by_user_name);
      setUpdatedAt(response.updated_at);
    }

    // If request is successful but API returns no data, no need to call setDoseValues -
    // component will use default values provided by reducer
    setPageState('loaded');
  };
};

export default fetchData(fetchDataApiRequest);
