import { Resources } from '../Api'

class LibraryDocument extends Resources {
  constructor() {
    super()
  }

  get url() {
    return `/library_documents`
  }
}

export default LibraryDocument
