import React from 'react';

const SkeletonChannel = () => (
  <div className="inbox-menu__contact">
    <div className="inbox-menu__contact-avatars">
      <div className="inbox-menu__contact-avatar avatar skeleton"></div>
    </div>

    <div className="inbox-menu__skeleton">
      <div className="inbox-menu__skeleton-container">
        <span className="skeleton skeleton__subject"></span>
      </div>
      <div className="inbox-menu__skeleton-container">
        <span className="skeleton skeleton__participants"></span>
      </div>
    </div>
  </div>
);

export default SkeletonChannel;
