import React from 'react';
import ReactModal from 'react-modal';
import { t } from 'i18n';

import { Icon } from 'Components/Base';
import DateModalContents from './DateModalContents';
import DateModalButtons from './DateModalButtons';
import PropTypes from 'prop-types';

const i18nOpts = { scope: 'care_plan_creators.details.details_form' };

const CalendarModal = ({ isOpen, onClose, setStartAt, updateStartAt, date, currentDate, modalState, originalDate, setModalStatus, errors }) => (
  <ReactModal
    ariaHideApp={false}
    closeTimeoutMS={150}
    isOpen={isOpen}
    onRequestClose={onClose}
    shouldCloseOnOverlayClick={false}
    shouldReturnFocusAfterClose={false}
    className="ReactModal__Calendar__Content"
  >
    <div className="ReactModal__Calendar__Header">
      <h2 className="text-truncate text-medium">
        <span>{t('date_modal_header', i18nOpts)}</span>
      </h2>
      <a className="ReactModal__Calendar__ModalClose" onClick={onClose}>
        <Icon name="close" />
      </a>
    </div>
    <div className="ReactModal__ContentBody ReactModal__Calendar__ContentBody">
      <DateModalContents
        setDate={setStartAt}
        setModalStatus={setModalStatus}
        modalState={modalState}
        date={date}
        originalDate={originalDate}
        currentDate={currentDate}
        errors={errors}
      />
      <DateModalButtons onClose={onClose} updateDate={updateStartAt} modalState={modalState} />
    </div>
  </ReactModal>
);

CalendarModal.propTypes = {
  currentDate: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  errors: PropTypes.object,
  isOpen: PropTypes.bool,
  modalState: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  setStartAt: PropTypes.func.isRequired,
  updateStartAt: PropTypes.func.isRequired,
  setModalStatus: PropTypes.func.isRequired
};

CalendarModal.defaultProps = {
  errors: {},
  isOpen: false,
}

export default CalendarModal;
