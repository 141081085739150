import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setCurrentPageNumber } from 'Actions/carePlanPlaceInvitationsPage';

const PreviousButtons = ({ currentPageNumber, setCurrentPageNumber }) =>
  currentPageNumber > 1 ? (
    <>
      <span
        className="first"
        onClick={() => {
          setCurrentPageNumber(1);
        }}
      >
        <a>{ '«' }</a>
      </span>
      <span
        className="previous"
        onClick={() => {
          setCurrentPageNumber(currentPageNumber - 1);
        }}
      >
        <a>{ '‹' }</a>
      </span>
    </>
  ) : (
    <>
      <span className="first--disabled">{ '«' }</span>
      <span className="previous--disabled">{ '‹' }</span>
    </>
  );

const mapStateToProps = ({ carePlanPlaceInvitationsPage }) => {
  const { currentPageNumber } = carePlanPlaceInvitationsPage.pagination;

  return {
    currentPageNumber,
  };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetCurrentPageNumber = (dispatch) => {
    return (page) => {
      dispatch(setCurrentPageNumber(page));
    };
  };

  return {
    setCurrentPageNumber: dispatchSetCurrentPageNumber(dispatch),
  };
};

PreviousButtons.propTypes = {
  currentPageNumber: PropTypes.number,
  setCurrentPageNumber: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviousButtons);
