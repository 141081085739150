import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { getDisplayName } from 'Utilities/hoc'
import AssignmentApi from 'Api/Assignment'
import { connect } from 'react-redux'
import { getCurrentUser } from 'Selectors/currentUser'

function withAssignments(WrappedComponent) {
  class WithAssignments extends Component {
    constructor(props) {
      super(props)

      this.state = {
        assignments: props.assignments,
      }

      this.updateStatus = this.updateStatus.bind(this)
      this.updateAssignment = this.updateAssignment.bind(this)
    }

    get isHealthCareView() {
      return !!this.props.patientName
    }

    get myAssignment() {
      return _.find(
        this.state.assignments,
        (assignment) => assignment.assignedUserId === this.props.placeUserId
      )
    }

    updateAssignment({ assignment }) {
      const assignments = this.state.assignments.map((a) =>
        a.id === assignment.id ? { ...a, status: assignment.status } : a
      )

      this.setState({ assignments })
    }

    updateStatus(evt, status) {
      evt.stopPropagation()
      evt.preventDefault()

      const api = new AssignmentApi(
        this.props.carePlan.uuid,
        this.myAssignment.activityId,
        this.myAssignment.id
      )

      return api.update({ status }).then((res) => {
        if (res.ok) {
          return res.json().then(this.updateAssignment)
        } else if (res.status === 422) {
          return res.json().then(console.error)
        }
      })
    }

    render() {
      return (
        <WrappedComponent
          allAssignments={this.state.assignments}
          updateStatus={this.updateStatus}
          updateAssignment={this.updateAssignment}
          isHealthCareView={this.isHealthCareView}
          myAssignment={this.myAssignment}
          {...this.props}
        />
      )
    }
  }

  WithAssignments.propTypes = {
    assignments: PropTypes.array,
    carePlan: PropTypes.shape({
      uuid: PropTypes.string,
    }),
    patientName: PropTypes.string,
    placeUserId: PropTypes.number.isRequired,
  }

  WithAssignments.defaultProps = {
    assignments: [],
    carePlan: {},
    patientName: null,
  }

  const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    placeUserId: getCurrentUser(state).id,
  })

  WithAssignments.displayName = `WithAssignments(${getDisplayName(WrappedComponent)})`

  return connect(mapStateToProps, null)(WithAssignments)
}

export default withAssignments
