import React from 'react'
import PropTypes from 'prop-types'
import LinkDecorator from 'Components/Base/LinkDecorator'

import { t } from 'i18n'

const i18nOpts = { scope: 'tasks.index.check_in' }

const interpolateResponse = (response, patientName) => {
  return (
    <LinkDecorator>
      {response ? (
        response.replace('%name', patientName)
      ) : (
        <div>
          <div>
            <strong>{t('thanks', { scope: 'tasks.index.check_in', name: patientName })}</strong>
          </div>
          <div>
            <strong>{t('response_recorded', i18nOpts)}</strong>
          </div>
        </div>
      )}
    </LinkDecorator>
  )
}

export const CheckInSubmittedModal = ({
  patientName,
  link,
  responseText,
  nextQuestionPresent,
  onNextQuestion,
}) => {
  return (
    <div>
      <div className="ReactModal__CheckIn--Text">
        <div>
          <strong>{interpolateResponse(responseText, patientName)}</strong>
        </div>
      </div>
      <div className="ReactModal__CheckIn--BlankDiv" />
      {nextQuestionPresent ? (
        <div onClick={onNextQuestion} className="ReactModal__CheckIn--Submit centered">
          <span>{t('next', i18nOpts)}</span>
        </div>
      ) : (
        <a href={link}>
          <div className="ReactModal__CheckIn--Submit centered">
            <span>{t('check_in_link', i18nOpts)}</span>
          </div>
        </a>
      )}
    </div>
  )
}

CheckInSubmittedModal.propTypes = {
  link: PropTypes.string,
  nextQuestionPresent: PropTypes.bool.isRequired,
  onNextQuestion: PropTypes.func.isRequired,
  patientName: PropTypes.string,
  responseText: PropTypes.string,
}

CheckInSubmittedModal.defaultProps = {
  link: '',
  patientName: '',
  responseText: '',
}

export default CheckInSubmittedModal
