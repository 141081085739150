import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'
import _ from 'lodash'

import ReferralConfirmation from 'Components/Resources/ReferralLink'
import { Icon, OutboundUrlLink, Tooltip } from 'Components/Base'

const i18nOpts = { scope: 'team.service_member' }

const generateLinksOutput = ({ address, locale, name, referralLink, referralLinkLabel, url }) => {
  const noLinks = !url && !referralLink
  if (noLinks) {
    return <b>{t('unknown', i18nOpts)}</b>
  } else {
    return (
      <Fragment>
        {url && (
          <div className="team-member__url-link">
            <Tooltip text={_.truncate(url, { length: 30, separator: ' ' })}>
              <OutboundUrlLink url={url}>
                {t('website', i18nOpts)}
                <Icon name="external-link" />
              </OutboundUrlLink>
            </Tooltip>
          </div>
        )}

        {referralLink && (
          <div className="team-member__url-link">
            <Tooltip text={_.truncate(referralLink, { length: 30, separator: ' ' })}>
              <ReferralConfirmation
                address={address}
                label={referralLinkLabel}
                link={referralLink}
                locale={locale}
                title={name}
              />
            </Tooltip>
          </div>
        )}
      </Fragment>
    )
  }
}

const ServiceMember = ({ id, address, locale, path, owner }) => {
  const { name, preferredPhoneNumber = {}, referralLink, referralLinkLabel, url } = owner

  return (
    <li key={id} className="team-member">
      <div className="team-member__section">
        <h3>
          <a href={path}>{name}</a>
        </h3>
      </div>

      <div className="team-member__section">
        <h4>{t('phone_number', i18nOpts)}</h4>
        {preferredPhoneNumber && preferredPhoneNumber.number ? (
          <a href={`tel:${preferredPhoneNumber.number}`}>{preferredPhoneNumber.number}</a>
        ) : (
          <b>{t('unknown', i18nOpts)}</b>
        )}
      </div>

      <div className="team-member__section">
        <h4>{t('links', i18nOpts)}</h4>
        {generateLinksOutput({ address, locale, name, referralLink, referralLinkLabel, url })}
      </div>
    </li>
  )
}

ServiceMember.propTypes = {
  address: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  locale: PropTypes.string.isRequired,
  owner: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    preferredPhoneNumber: PropTypes.shape({
      number: PropTypes.string,
    }),
    referralLink: PropTypes.string,
    referralLinkLabel: PropTypes.string,
  }).isRequired,
  path: PropTypes.string.isRequired,
}

ServiceMember.defaultProps = {}

export default ServiceMember
