import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import InputGroup from 'Components/Forms/InputGroup'

const i18nOpts = { scope: 'patient.check_in_score' }

const NumericOption = ({ onClick, optionData, errors, userInput }) => {
  const isError = Object.keys(errors).length
  const min = optionData.data.lowerLimit ? optionData.data.lowerLimit.toString() : null
  const max = optionData.data.upperLimit ? optionData.data.upperLimit.toString() : null

  return (
    <div className="ReactModal__CheckIn--QuestionOption--numeric-container">
      <div className={`ReactModal__CheckIn--QuestionOption--numeric ${isError ? 'error-border' : ''}`}>
        <InputGroup
          component="input"
          name="userInput"
          className="ReactModal__CheckIn--QuestionOption--input"
          value={userInput}
          onChange={onClick}
          type="number"
          min={min}
          max={max}
        />
        <div className="ReactModal__CheckIn--QuestionOption--unit">{optionData.data.units}</div>
      </div>
      <span>
        {min && t('min', { ...i18nOpts, val: min })} {''}
        {max && t('max', { ...i18nOpts, val: max })}
      </span>
    </div>
  )
}

NumericOption.propTypes = {
  errors: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  optionData: PropTypes.object.isRequired,
  userInput: PropTypes.string,
}

NumericOption.defaultProps = {
  errors: {},
  userInput: '',
}

export default NumericOption
