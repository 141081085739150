import React from 'react';

const Checkbox = ({
  className,
  onClick,
  checked,
  name,
  id,
  label
}) => (
  <div
    className={className ? `checkbox-container ${className}` : "checkbox-container"}
    onClick={(evt) => { onClick && onClick(evt); }}
    onKeyDown={(evt) => {
      if (evt.key === 'Enter') {
        onClick && onClick(evt);
      }
    }}
  >
    <input
      checked={checked}
      onChange={(evt) => { onClick && onClick(evt); }}
      onClick={evt => evt.stopPropagation()}
      name={name}
      id={id}
      type="checkbox"
    />
    <label
      htmlFor={id}
      onClick={evt => evt.stopPropagation()}
    >
      {label}
    </label>
  </div>
)

export default Checkbox;
