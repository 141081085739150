import React from 'react'
import PropTypes from 'prop-types'

const CarePlanBadge = ({ place: { name, logo } }) => (
  <div className="hidden--sm row__col row__col--fixed activity-assignment">
    <div className="activity-assignment__body">
      <img src={logo} className="activity-assignment__avatar" alt={name} />
    </div>
  </div>
)

CarePlanBadge.propTypes = {
  place: PropTypes.shape({
    name: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
  }).isRequired,
}

export default CarePlanBadge
