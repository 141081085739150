import { request } from 'Apiv2/handleRequest';

const dataApiRequestHandler = async (carePlanUuid, method, payload = null, id = null) => {
  const endpointUrl = id
    ? `/care_plans/${carePlanUuid}/recommendations/${id}`
    : `/care_plans/${carePlanUuid}/recommendations`;

  try {
    const response = await request(endpointUrl, method, payload);

    const { data, errors } = await response.json();

    if (errors) {
      throw errors;
    }

    if (response.status === 200) {
      return { content_type: 'RECOMMENDATIONS_DATA', data };
    } else {
      return { content_type: 'ERROR', errors: ['UNKNOWN_STATUS'] };
    }
  } catch (error) {
    return { content_type: 'ERROR', error };
  }
};

export default dataApiRequestHandler;
