import calculateFill from './calculateFill';
import getTranslatedHours from './getTranslatedHours';

export const getRenderDataWrapper =
  (hourTranslator = getTranslatedHours) =>
  (data) =>
    data.map((sleep) => {
      const { slept, awoke, quality, hours_slept } = sleep;
      const sleepData = hourTranslator({ slept, awoke, hours_slept });

      const renderData = {
        ...sleep,
        value: '',
        hoursSlept: hours_slept || '',
        fill: calculateFill(quality),
      };

      if (sleepData) {
        const {
          sleptAt: { startHour, startMin },
          awokeAt: { endHour, endMin },
          hoursSlept,
        } = sleepData;

        const validSleepHours = endHour < startHour;

        renderData.hoursSlept = validSleepHours ? hoursSlept : '';
        renderData.value = validSleepHours ? [24 - (startHour + startMin), 12 - (endHour + endMin)] : '';
      } else if (slept == null && awoke == null) {
        // Set to null so values in the future are blank
        renderData.value = null;
      }

      return renderData;
    });

export default getRenderDataWrapper(getTranslatedHours);
