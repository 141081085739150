import React from 'react';
import { Text } from 'recharts';
import { t } from 'i18n';

const XAxisTickFormatter = ({ x, y, className, payload: { value } }) => (
  <g transform={`translate(${x},${y})`}>
    <Text dy={15} verticalAnchor="end" textAnchor="middle" className={`${className} xAxis text text-subtle`}>
      {t(value.replace(/\-.*/, ''), { scope: 'blocks.days' })}
    </Text>
  </g>
);

export default XAxisTickFormatter;
