import moment from 'ct-moment';
import { t } from 'i18n';

const i18nOptsInsulin = { scope: 'dashboard.insulin' };
const i18nOptsClipboardCopy = { scope: 'dashboard.clipboard_copy' };

const buildInsulinTypes = (insulinTypes) => {
  let insulinTypesText = '';
  insulinTypes.forEach((insulinType) => {
    insulinTypesText += `${insulinType.property}: ${
      insulinType.subproperty.length === 0 ? '--' : insulinType.subproperty
    }\n`;
  });

  return insulinTypesText;
};

// Note: This file will add random number of spaces to format the copied text into non-monospace platform.
const buildDosing = (dosing) => {
  let dosingText = '';

  dosingText += ' '.repeat(t('bedtime', i18nOptsInsulin).length + 4); // 4 spaces for indentation

  // build the headers
  dosing.columns.forEach((headerForDosing) => {
    if (headerForDosing.length > 0) {
      let headerText = ' '.repeat(4) + headerForDosing;

      // If the header is `Long` or the first of the columns,
      // we don't need to indent as we already added in the breakfast.length + 4 spacing.
      if (headerForDosing == t('long', i18nOptsInsulin)) {
        headerText = ' '.repeat(7) + headerForDosing; // Note: 7 is the goldilocks number between en and fr locales.
      } else if (headerForDosing == t('ICR', i18nOptsInsulin)) {
        headerText = ' '.repeat(4) + headerForDosing;
      }

      dosingText += headerText;
    }
  });

  dosingText += '\n';

  // build the rows
  dosing.rows.forEach((row, rowIndex) => {
    row.forEach((data, dataIndex) => {
      if (dataIndex == 0) {
        // All headers for timing of Dose need to be indented depending on the longest string of the above headers.
        // Current max is breakfast.
        let text = '';

        switch (data) {
          case t('breakfast', i18nOptsInsulin):
            text = t('breakfast', i18nOptsInsulin);
            break;
          case t('lunch', i18nOptsInsulin):
            text = t('lunch', i18nOptsInsulin);
            break;
          case t('dinner', i18nOptsInsulin):
            text = t('dinner', i18nOptsInsulin);
            break;
          case t('bedtime', i18nOptsInsulin):
            text = t('bedtime', i18nOptsInsulin);
            break;
          default:
            break;
        }

        dosingText += text + ' '.repeat(t('bedtime', i18nOptsInsulin).length - text.length + 4);
      } else if (dataIndex == 1) {
        // Note: adding 2 more spaces for Lunch and Dinner data as they are shorter
        if (rowIndex == 1 || rowIndex == 2) {
          dosingText += ' '.repeat(2);
        }

        dosingText += data;
      } else if (dataIndex == 2) {
        // Note: Bedtime locale is long so doesn't require additional 2 spaces.
        if (rowIndex == 4) {
          dosingText += ' '.repeat(t('long', i18nOptsInsulin).length) + data;
        } else {
          dosingText += ' '.repeat(t('long', i18nOptsInsulin).length + 2) + data;
        }
      } else if (dataIndex == 3) {
        if (rowIndex == 4) {
          dosingText += ' '.repeat(t('ICR', i18nOptsInsulin).length + 1) + data;
        }
        dosingText += ' '.repeat(t('ICR', i18nOptsInsulin).length + 2) + data;
      }
    });
    dosingText += `\n`;
  });

  return dosingText;
};

const getReportHeader = (userDetails, currentDate) => {
  const patientFullName = `${userDetails.patientFullName}\n`;
  const dateOfBirth = `${t('date_of_birth', i18nOptsClipboardCopy)}: ${userDetails.patientBirthday}\n`;
  const exportedDate = `${t('exported_date', i18nOptsClipboardCopy)}: ${currentDate}\n\n`;
  return patientFullName + dateOfBirth + exportedDate;
};

const getReportBody = (formattedData) => {
  const insulinLabel = `${formattedData.insulin.label}\n`;
  const insulinTypesText = `${buildInsulinTypes(formattedData.insulin.insulinTypes)}\n`;
  const dosingTitleText = `${formattedData.dosing.title}:\n`;
  const dosingDataText = buildDosing(formattedData.dosing);

  return insulinLabel + insulinTypesText + dosingTitleText + dosingDataText;
};

const buildMDITextFromBlock = (mdiData) => {
  const formattedData = mdiData.statsData;
  const userDetails = mdiData.userDetails;
  const currentDate = moment().format('MMM Do YYYY');

  return getReportHeader(userDetails, currentDate) + getReportBody(formattedData);
};

export default buildMDITextFromBlock;
