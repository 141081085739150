import React from 'react';
import { t } from 'i18n';

import Scheduled from './Scheduled';

const i18nOpts = { scope: 'activity_modal.form_fields.when_should_be_completed' };

// Patient/Support-created appointments cannot be unscheduled,
// so we do not render the toggle for unscheduled/scheduled
export const WhenMandatory = () => {
  const fieldLabel = t('appointment_label', i18nOpts);

  return (
    <>
      <div className="activity_modal__input_field__label">{fieldLabel}</div>
      <div className="activity_modal__input_field__container">
        <Scheduled />
      </div>
    </>
  );
};

export default WhenMandatory;
