import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import DotsLoader from 'Components/Base/DotsLoader';

import ActivityModal from './ActivityModal/ActivityModal';
import AddActivityButton from './AddActivityButton';

import handleSessionInfoSetup from './handleSessionInfoSetup';
import getCarePlanIdFromPath from './getCarePlanIdFromPath';

import { fetchTasks } from 'Actions/tasks'
import { fetchAgenda } from 'Actions/agenda'
import { setActivityModalCarePlanId, setActivityModalTimeZone, setActivityModalLocale } from 'Actions/activityModal';

const AddActivityHeaderContents = ({
  setActivityModalCarePlanId,
  setActivityModalTimeZone,
  setActivityModalLocale,
  fetchTasks,
  fetchAgenda
}) => {
  const carePlanIdFromPath = getCarePlanIdFromPath(window.location.pathname);

  const [sessionInfoState, setSessionInfoState] = useState('LOADING');

  useEffect(() => {
    handleSessionInfoSetup(
      setSessionInfoState,
      setActivityModalCarePlanId,
      setActivityModalTimeZone,
      setActivityModalLocale,
      carePlanIdFromPath
    );
  }, [])

  const refetchData = () => { // refetch calendar after adding a new activity
    fetchTasks(carePlanIdFromPath);
    fetchAgenda(carePlanIdFromPath);
  }

  switch(sessionInfoState) {
    case 'MAIN_CONTENT':
      return (
        <div className="action-header">
          <h1>{t('heading', { scope: 'tasks.index' })}</h1>

          <div className="action-header__button-section">
            <AddActivityButton activityType='TASK' />
            <AddActivityButton activityType='APPOINTMENT' />
          </div>

          <ActivityModal onCreate={refetchData}/>
        </div>
      );
    case 'LOADING':
      return (
        <div className="action-header">
          <h1>{t('heading', { scope: 'tasks.index' })}</h1>

          <div className="action-header__button-section">
            <DotsLoader />
          </div>
        </div>
      )
    case 'ERROR':
    default:
      return (
        <div className="action-header">
          <h1>{t('heading', { scope: 'tasks.index' })}</h1>
        </div>
      )
  };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalCarePlanId = (dispatch) => {
    return (state) => {
      dispatch(setActivityModalCarePlanId(state));
    };
  };

  const dispatchSetActivityModalTimeZone = (dispatch) => {
    return (state) => {
      dispatch(setActivityModalTimeZone(state));
    };
  };

  const dispatchSetActivityModalLocale = (dispatch) => {
    return (state) => {
      dispatch(setActivityModalLocale(state));
    }
  }

  const dispatchFetchTasks = (dispatch) => {
    return (carePlanUuid) => {
      dispatch(fetchTasks(carePlanUuid))
    }
  }

  const dispatchFetchAgenda = (dispatch) => {
    return (carePlanUuid) => {
      dispatch(fetchAgenda(carePlanUuid))
    }
  }

  return {
    setActivityModalCarePlanId: dispatchSetActivityModalCarePlanId(dispatch),
    setActivityModalTimeZone: dispatchSetActivityModalTimeZone(dispatch),
    setActivityModalLocale: dispatchSetActivityModalLocale(dispatch),
    fetchTasks: dispatchFetchTasks(dispatch),
    fetchAgenda: dispatchFetchAgenda(dispatch)
  };
};

export default connect(null, mapDispatchToProps)(AddActivityHeaderContents)
