import React, { Fragment } from 'react';
import { t } from 'i18n';
import _ from 'lodash';
import { ascBy } from 'Utilities/sort';

import { LineChart } from 'react-chartkick';

import { Card } from 'Components/Base/Card/';
import CheckInResponseHistory from './CheckInResponseHistory/CheckInResponseHistory'

const i18nOpts = { scope: 'patient.check_in_result' };

const dataForGraph = (checkIns, index) => {
  const data = [];
  data.push({ name: t('submitted', i18nOpts), data: {} });
  data.push({ name: t('submitted_late', i18nOpts), data: {} });
  data.push({ name: t('skipped', i18nOpts), data: {} });

  checkIns.map((checkIn) => {
    const submittedData = checkIn.submittedData[index];
    const pastDueAt = checkIn.pastDueAt;
    const submitter = pastDueAt ? t('submitted_late', i18nOpts) : t('submitted', i18nOpts);
    const submitterGroup = data.find((group) => group.name === submitter);
    const skippedGroup = data.find((group) => group.name === t('skipped', i18nOpts));

    if (submittedData?.checkInQuestionOptionId !== null) {
      submitterGroup.data[submittedData.dateForGraph] = submittedData.userInput || submittedData.score;
    } else {
      skippedGroup.data[submittedData.dateForGraph] = 0;
    }
  });

  return data;
};

const LineChartSection = ({ checkIns, index, question }) => {
  let min, max;

  switch (question.questionType) {
    case 'free_text':
      return null;
    case 'numeric_input': {
      const option = question.checkInQuestionOptions[0];
      min = option.data.lowerLimit;
      max = option.data.upperLimit;
      break;
    }
    case 'pain_scale_5':
    case 'generic_scale_5':
      min = 1;
      max = 5;
      break;
    case 'generic_scale_10':
      min = 1;
      max = 10;
      break;
    case 'generic_scale_11':
      min = 0;
      max = 10;
      break;
    case 'mood_scale_10':
      min = 0;
      max = 10;
      break;
    case 'yes_no':
      min = 0;
      max = 1;
      break;
    case 'multiple_choice':
      min = 0;
      max = 10;
      break;
  }

  return (
    <figure className="checkin-chart">
      <figcaption className="checkin-results__question text-bold text-grey-darkest">
        {question.text}
      </figcaption>
      <LineChart
        data={dataForGraph(checkIns, index)}
        curve={false}
        colors={['#007FAE', '#ED9F0E', '#CECFCE']}
        min={min}
        max={max}
        legend={true}
        dataset={{ radius: 7, borderColor: 'rgba(0,0,0,0)' }}
      />
    </figure>
  );
};

const CheckInsResult = ({ groupedCheckIns }) => {
  return Object.keys(groupedCheckIns).map((key) => {
    const checkIns = groupedCheckIns[key].sort(ascBy('completedAt'));
    const lastCheckin = _.last(checkIns);
    const questions = lastCheckin.checkInQuestions;
    const completedQuestionIds = lastCheckin.completedQuestionIds;

    return (
      <section key={key} className="checkin-results__wrapper">
        <p>{lastCheckin.text}</p>
        {questions.map((question) => {
          const dataIndex = completedQuestionIds.findIndex((q) => q === question.id);
          const skipped = !lastCheckin.submittedData[dataIndex]?.checkInQuestionOptionId;

          return (
            <Fragment key={`question-id-${question.id}`}>
              <CheckInResponseHistory
                checkIns={checkIns}
                question={question}
                lastCheckin={lastCheckin}
                skipped={skipped}
                dataIndex={dataIndex}
              />
              <LineChartSection checkIns={checkIns} question={question} index={dataIndex} />
            </Fragment>
          );
        })}
      </section>
    );
  });
};

const CheckInResultSection = ({ groupedCheckIns }) => {
  return (
    <Card id="check_in_result" title={`${t('header', i18nOpts)}`} collapsable>
      {Object.keys(groupedCheckIns).length ? (
        <CheckInsResult groupedCheckIns={groupedCheckIns} />
      ) : (
        <div className="item-pill-section--item-list-empty">{t('none', i18nOpts)}</div>
      )}
    </Card>
  );
}

export default CheckInResultSection;
