import moment from 'ct-moment';
import { t } from 'i18n';

const i18nOpts = { scope: 'dashboard' };
const i18nGlucoseAverageOpts = { scope: 'dashboard.glucose_average' };
const i18nGlucoseRangeOpts = { scope: 'dashboard.glucose_range' };
const i18nOptsClipboardCopy = { scope: 'dashboard.clipboard_copy' };
const i18nOptsDevice = { scope: 'dashboard.device' };

const extractBGdata = (dayStats) => {
  const result = {
    lowAverageDailyReadings: '--',
    inRangeDailyReadings: '--',
    highDailyReadings: '--',
    cgmActivePercentage: '--',
    averageGlucose: '--',
    gmi: '--',
    coeffV: '--',
    sd: '--',
  };

  result.lowAverageDailyReadings =
    (dayStats.glucoseRange[0].status === 'SUCCESS' && `${dayStats.glucoseRange[0].number}%`) || '--';
  result.inRangeDailyReadings =
    (dayStats.glucoseRange[1].status === 'SUCCESS' && `${dayStats.glucoseRange[1].number}%`) || '--';
  result.highDailyReadings =
    (dayStats.glucoseRange[2].status === 'SUCCESS' && `${dayStats.glucoseRange[2].number}%`) || '--';
  result.cgmActivePercentage =
    (dayStats.cgmActivePercentage.status === 'SUCCESS' && `${dayStats.cgmActivePercentage.number}%`) || '--';
  result.averageGlucose =
    (dayStats.glucoseAverages[0].status == 'SUCCESS' && `${dayStats.glucoseAverages[0].number}`) || '--';
  result.gmi =
    (dayStats.glucoseAverages[1].status == 'SUCCESS' && `${dayStats.glucoseAverages[1].number}%`) || '--';
  result.coeffV =
    (dayStats.glucoseAverages[2].status == 'SUCCESS' && `${dayStats.glucoseAverages[2].number}%`) || '--';
  result.sd =
    (dayStats.glucoseAverages[3].status == 'SUCCESS' && `${dayStats.glucoseAverages[3].number}%`) || '--';

  return result;
};

const getDayDiff = (currentTime, days) => {
  return currentTime.clone().subtract(days, 'days');
};

const getReportingPeriod = (selectedTab, currentTime) => {
  const result = { fromDate: '', toDate: '' };
  let fromTime = getDayDiff(currentTime, 1);
  let toTime = getDayDiff(currentTime, 1);

  switch (selectedTab) {
    case '1day':
      fromTime = getDayDiff(currentTime, 1);
      toTime = getDayDiff(currentTime, 1);

      break;
    case '7days':
      fromTime = getDayDiff(currentTime, 8);
      toTime = getDayDiff(currentTime, 1);
      break;
    case '14days':
      fromTime = getDayDiff(currentTime, 15);
      toTime = getDayDiff(currentTime, 1);
      break;
    case '30days':
      fromTime = getDayDiff(currentTime, 31);
      toTime = getDayDiff(currentTime, 1);
      break;
    case '90days':
      fromTime = getDayDiff(currentTime, 91);
      toTime = getDayDiff(currentTime, 1);
      break;
  }

  result.fromDate = fromTime.format('MMM Do YYYY');
  result.toDate = toTime.format('MMM Do YYYY');

  return result;
};

const buildBloodGlucoseTextFromBlock = (data) => {
  const currentTime = moment();
  const currentDate = moment().format('MMM Do YYYY');
  const selectedTab = data.tab;
  const userDetails = data.userDetails;
  const dayStats = data.statsData[selectedTab];

  const bloodGlucoseData = extractBGdata(dayStats);

  const { fromDate, toDate } = getReportingPeriod(selectedTab, currentTime);

  const getReportHeader = (userDetails, currentDate, fromDate, toDate) => {
    const userName = `${userDetails.patientFullName}\n`;
    const dateOfBirth = `${t('date_of_birth', i18nOptsClipboardCopy)}: ${userDetails.patientBirthday}\n`;
    const exportedDate = `${t('exported_date', i18nOptsClipboardCopy)}: ${currentDate}\n\n`;
    const reportingPeriod = `${t('reporting_period', i18nOptsClipboardCopy)}: ${fromDate} to ${toDate}\n\n`;

    return userName + dateOfBirth + exportedDate + reportingPeriod;
  };

  const getReportBody = (bloodGlucoseData) => {
    const {
      lowAverageDailyReadings,
      inRangeDailyReadings,
      highDailyReadings,
      cgmActivePercentage,
      averageGlucose,
      gmi,
      coeffV,
      sd,
    } = bloodGlucoseData;

    const averageDailyReadingsHeader = `${t('average_daily_readings', i18nOptsClipboardCopy)}:\n`;

    const lowDailyReadingsReport = `${t('low', i18nGlucoseRangeOpts)} (<3.9 ${t(
      'mmol_per_litre',
      i18nOpts
    )}): ${lowAverageDailyReadings}\n`;

    const inRangeDailyReadingsReport = `${t('in_range', i18nGlucoseRangeOpts)} (3.9 - 10 ${t(
      'mmol_per_litre',
      i18nOpts
    )}): ${inRangeDailyReadings}\n`;

    const highDailyReadingsReport = `${t('high', i18nGlucoseRangeOpts)} (>10 ${t(
      'mmol_per_litre',
      i18nOpts
    )}): ${highDailyReadings}\n\n`;

    const cgmActivePercentageReport = `${t('cgm_active', i18nOptsDevice)}: ${cgmActivePercentage}\n`;

    const averageGlucoseReport = `${t('average_glucose', i18nGlucoseAverageOpts)} (${t(
      'mmol_per_litre',
      i18nOpts
    )}): ${averageGlucose}\n`;

    const gmiReport = `${t('gmi', i18nGlucoseAverageOpts)}: ${gmi}\n`;

    const cvReport = `${t('cv', i18nGlucoseAverageOpts)}: ${coeffV}\n`;

    const sdReport = `${t('standard_deviation', i18nOptsClipboardCopy)} (${t(
      'mmol_per_litre',
      i18nOpts
    )}): ${sd}`;

    return (
      averageDailyReadingsHeader +
      lowDailyReadingsReport +
      inRangeDailyReadingsReport +
      highDailyReadingsReport +
      cgmActivePercentageReport +
      averageGlucoseReport +
      gmiReport +
      cvReport +
      sdReport
    );
  };

  return getReportHeader(userDetails, currentDate, fromDate, toDate) + getReportBody(bloodGlucoseData);
};

export default buildBloodGlucoseTextFromBlock;
