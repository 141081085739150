import ContactsApi from 'Api/Inbox/Contact'

export const REQUEST_ALL_CONTACTS = 'REQUEST_ALL_CONTACTS'
export const RECEIVE_ALL_CONTACTS = 'RECEIVE_ALL_CONTACTS'

export const fetchAllContacts = () => {
  return (dispatch) => {
    dispatch({ type: REQUEST_ALL_CONTACTS })

    return new ContactsApi()
      .all()
      .then((res) => res.json())
      .then(({ contacts }) =>
        dispatch({
          type: RECEIVE_ALL_CONTACTS,
          contacts,
        })
      )
  }
}
