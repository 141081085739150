import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

const Option = ({ option, scope }) => <option value={option}>{t(option, { scope })}</option>

Option.propTypes = {
  option: PropTypes.string.isRequired,
  scope: PropTypes.string,
}

Option.defaultProps = {
  scope: '',
}

export default Option
