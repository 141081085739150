// sort alphabetically and put unnamed templates at the end

const sortByKey = (target, key) => {
  target.sort((a, b) => {
    if (a[key] && !b[key]) {
      return -1;
    } else if (!a[key] && b[key]) {
      return 1;
    }
    return a[key]?.localeCompare(b[key]);
  });

  return target;
};

export default sortByKey;
