import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'i18n';

import Card from 'Components/Base/Card';
import headerAction from './headerAction';
import Show from './Show';
import Edit from './Edit';
import Icon from 'Components/Base/Icon';
import { CarePlanContext } from 'Components/Dashboard/Dashboard';
import formatDataForDisplay from './Show/formatDataForDisplay';

import { setIsEditing } from 'Actions/dashboardInsulinDosing';
import CopyButton from '../../Buttons/CopyButton';
import buildTextFromBlocks from '../../buildTextFromBlocks';

const i18nOpts = { scope: 'dashboard' };
const i18nOptsInsulin = { scope: 'dashboard.insulin' };

const InsulinDosingContainer = ({ isEditing, setIsEditing, doseValues, createdByUser, updatedAt }) => {
  const { userDetails } = useContext(CarePlanContext);

  const handleButtonClick = () => {
    const formattedData = formatDataForDisplay(doseValues, i18nOptsInsulin);
    const text = buildTextFromBlocks('MDI', { statsData: formattedData, userDetails });

    // To test this in local, check readme
    navigator.clipboard?.writeText(text);
  };

  return (
    <Card
      title={t('insulin_dosing', i18nOpts)}
      id={'insulin_dosing'}
      collapsable
      renderHeaderAction={headerAction(isEditing, setIsEditing)}
    >
      {isEditing ? <Edit /> : <Show />}
      <div className="insulin-dosing__buttons">
        <a
          href="http://www.bcchildrens.ca/health-info/coping-support/diabetes/insulin-calculators-apps/simple-bolus-calculator"
          rel="noreferrer"
          target="_blank"
          className="insulin-dosing__bolus-calculator"
        >
          <Icon name="open" />
          <span>{t('bolus_calculator', i18nOpts)}</span>
        </a>
        <div className="insulin-dosing__copy-mdi">
          <CopyButton onCopyButtonClick={handleButtonClick} />
        </div>
      </div>
    </Card>
  );
};

InsulinDosingContainer.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  doseValues: PropTypes.object.isRequired,
};

const mapStateToProps = ({ dashboardInsulinDosing: { isEditing, doseValues, createdByUser, updatedAt } }) => {
  return { isEditing, doseValues, createdByUser, updatedAt };
};

const mapDispatchToProps = (dispatch) => ({
  setIsEditing: (isEditing) => dispatch(setIsEditing(isEditing)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InsulinDosingContainer);
