import { request } from 'Apiv2/handleRequest';

export const fetchCarePlanBlocksApiWrapper = (requestProvider = request) => {
  return async (carePlanUuid) => {
    const ENDPOINT_URL = `/care_plans/${carePlanUuid}/dashboard/fetch_blocks`;

    try {
      const response = await requestProvider(ENDPOINT_URL);
      const status = response.status;
      const data = await response.json();

      return { status, data };
    } catch (error) {
      return { status: 'error', error };
    }
  };
};

export default fetchCarePlanBlocksApiWrapper(request);
