import React from 'react'
import { t } from 'i18n'

import FormField from './FormField'
import CountrySelector from './CountrySelector';
import ExtendedAddressByCountry from './ExtendedAddressByCountry';

const i18nOpts = { scope: 'forms.address_form' }

const AddressForm = ({ className = 'activity_modal', address, name, locale, onChange, errors }) => (
  <div className={`${className}__address_form__input_field__container`}>
    <CountrySelector
      className={className}
      label={t('country', i18nOpts)}
      value={address.countryCode}
      name={`${name}.countryCode`}
      locale={locale}
      onChange={onChange}
      errors={errors}
    />
    <FormField
      label={t('address', i18nOpts)}
      name={`${name}.address`}
      value={address.address}
      onChange={onChange}
      formErrors={errors}
    />
    <FormField
      name={`${name}.extendedAddress`}
      label={t('extended_address', i18nOpts)}
      value={address.extendedAddress || ''}
      placeholder={t('extended_address', i18nOpts)}
      onChange={onChange}
      formErrors={errors}
    />
    <ExtendedAddressByCountry
      className={className}
      address={address}
      name={name}
      onChange={onChange}
      formErrors={errors}
    />
  </div>
)

export default AddressForm;
