import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { t } from 'i18n';

import Icon from 'Components/Base/Icon';
import RenderLegend from '../RenderLegend';

const allSymptoms = [
  'action_taken',
  'stomache_ache',
  'headache',
  'back_pain',
  'dizziness',
  'limb_pain',
  'fast_heartbeat',
  'nausea',
  'body_weakness',
];

const getCellClassName = ({ occurred, actionTaken }) =>
  classNames({
    'chart cell': true,
    'cell--occurred': occurred && !actionTaken,
    'icon cell--action-taken': occurred && actionTaken,
    'icon cell--no-response': !occurred && actionTaken,
  });

const getDayClassName = (dayMissed) =>
  classNames({
    day: true,
    'day-missed': dayMissed,
  });

const Chart = ({ data }) => {
  return (
    <div className="chart symptom-occurrence">
      {data.map(({ day, symptoms }, dataIndex) => {
        const dayMissed = !symptoms.map(({ no_response }) => no_response).includes(false);

        return (
          <div key={dataIndex} className={getDayClassName(dayMissed)}>
            <div className={`time-of-day-wrapper ${dayMissed && 'day-missed'}`}>
              {symptoms.map((affected, dayIndex) => {
                if (affected.no_response) {
                  return (
                    <div key={dayIndex} className="time-of-day time-of-day--no-response">
                      <Icon name="close" className="cell no-data" />
                    </div>
                  );
                }
                return (
                  <div
                    key={dayIndex}
                    className={`time-of-day ${affected.is_future && 'time-of-day--future'}`}
                  >
                    {allSymptoms.map((type, symptomIndex) => {
                      const occurred = affected[type];
                      const actionTaken = type === 'action_taken';

                      return (
                        <div key={symptomIndex} className={getCellClassName({ occurred, actionTaken })}>
                          {occurred && actionTaken ? <Icon key={symptomIndex} name="check" /> : null}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
              <span className="xAxis text text-subtle">{t(day, { scope: 'blocks.days' })}</span>
            </div>
          </div>
        );
      })}
      <RenderLegend legend={t('legend', { scope: 'blocks.symptom_occurrence' })} />
    </div>
  );
};

Chart.propTypes = {
  data: PropTypes.array.isRequired,
};

export { Chart as default, getCellClassName, getDayClassName };
