import getResourcesApi from './getResourcesApi';

export const handleResourcesSetupWrapper = (getResourcesApiProvider = getResourcesApi) => {
  return async (
    setActivityModalResourcesDropdownState,
    setActivityModalResourcesDropdownResources,
    setActivityModalResourcesDropdownResourcesToDisplay,
    carePlanId
  ) => {
    setActivityModalResourcesDropdownState('LOADING');

    try {
      const { responseType, resources } = await getResourcesApiProvider(carePlanId);

      if (responseType === 'DATA') {
        setActivityModalResourcesDropdownResources(resources);
        setActivityModalResourcesDropdownResourcesToDisplay(resources);
        setActivityModalResourcesDropdownState('DEFAULT');
      } else {
        throw 'Missing data';
      }
    } catch (error) {
      setActivityModalResourcesDropdownState('ERROR');
      console.error(error);
    }
  };
};

export default handleResourcesSetupWrapper(getResourcesApi);
