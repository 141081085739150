import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ActivityFeedBanner from './ActivityFeedBanner';
import TasksBanner from './TasksBanner';
import MyTasksBanner from './MyTasksBanner';
import CarePlanBanner from './CarePlanBanner';
import PatientBanner from './PatientBanner';
import UhcpActionPlanIndexBanner from './UhcpActionPlanIndexBanner';
import UhcpActionPlanShowBanner from './UhcpActionPlanShowBanner';
import UacgCarePlanBanner from './UacgCarePlanBanner';

import WelcomeBannerApi from '../../api/WelcomeBanner';

class WelcomeBanner extends Component {
  constructor(props) {
    super(props);

    this.close = this.close.bind(this);
    this.state = { visible: true };
  }

  get bannerComponent() {
    const component = {
      activity_feed: ActivityFeedBanner,
      tasks: TasksBanner,
      my_tasks: MyTasksBanner,
      care_plan: CarePlanBanner,
      patient: PatientBanner,
      uhcp_action_plan_index: UhcpActionPlanIndexBanner,
      uhcp_action_plan_show: UhcpActionPlanShowBanner,
      uacg_care_plan: UacgCarePlanBanner,
    };

    return component[this.props.page];
  }

  close() {
    this.setState({ visible: false });
    new WelcomeBannerApi(this.props.page).dismiss();
  }

  render() {
    if (!this.state.visible) {
      return null;
    }

    const Banner = this.bannerComponent;
    return (
      <Banner
        selfSignup={this.props.selfSignup}
        close={this.close}
        placeName={this.props.placeName}
        userName={this.props.userName}
        patientName={this.props.patientName}
      />
    );
  }
}

WelcomeBanner.propTypes = {
  page: PropTypes.string.isRequired,
  patientName: PropTypes.string,
  placeName: PropTypes.string,
  selfSignup: PropTypes.bool,
  userName: PropTypes.string,
};

WelcomeBanner.defaultProps = {
  patientName: null,
  placeName: null,
  selfSignup: false,
  userName: null,
};

export default WelcomeBanner;
