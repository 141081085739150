import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import ItemPill, { ItemPillText } from 'Components/Base/ItemPill'
import { DeferredImage } from 'Components/Base'
import Button from 'Components/Base/Button'
import ModifyPlaceInvitationContainer from 'Components/Team/ModifyInvitation/ModifyPlaceInvitationContainer'

import location from 'location.png'

const PendingPlaceInvitation = ({ id, name, address, imageEntitlementToken }) => (
  <ItemPill stretch flat>
    <DeferredImage
      alt={name}
      className="new-contact__avatar avatar"
      fallbackImage={location}
      imageEntitlementToken={imageEntitlementToken}
    />

    <ItemPillText text={name} subtext={address} data-test="pending-place-invitation" />

    <ModifyPlaceInvitationContainer
      member={{ id, name, address, imageEntitlementToken }}
      renderProp={(onClick) => (
        <Button
          className="btn btn--secondary btn--extra-small"
          onClick={onClick}
          text={t('team.manage_invitation.header')}
        />
      )}
    />
  </ItemPill>
)

PendingPlaceInvitation.propTypes = {
  address: PropTypes.string,
  id: PropTypes.number.isRequired,
  imageEntitlementToken: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

PendingPlaceInvitation.defaultProps = {
  address: '',
}

export default PendingPlaceInvitation
