import React, { useState } from 'react';
import { t } from 'i18n';

import RadioOption from 'Components/Base/RadioOption';
import ActionButton from 'Components/Base/ActionButton';

import handleSetCheckInVisibility from './handleSetCheckInVisibility';

const i18nOpts = { scope: 'care_plan_creators.check_in_templates.visibility' };

const Visibility = ({
  resourceId,
  visibleToAll
}) => {
  const [visibleToAllInput, setVisibleToAllInput] = useState(visibleToAll);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(undefined);

  return (
    <>
      <label className="careplan-wizard__visibility-label">{t('who_can_see', i18nOpts)}</label>

      <div className="careplan-wizard__visibility-options">
        <RadioOption
          isSelected={visibleToAllInput}
          onClick={() => setVisibleToAllInput(true)}
          className="everyone"
        >
          {t('everyone', i18nOpts)}
        </RadioOption>
        <RadioOption
          isSelected={!visibleToAllInput}
          onClick={() => setVisibleToAllInput(false)}
        >
          {t('hcp_only', i18nOpts)}
        </RadioOption>
      </div>

      {apiError && <div className="careplan-wizard__error">{t('api_error', i18nOpts)}</div>}

      <div className="card__button-footer">
        <div className="card__button-footer-with-description">
          <p className="description">{t('will_not_affect', i18nOpts)}</p>
          <ActionButton
            className="btn"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={() => {
              // not a good pattern, but cleanest I could come up with
              if (handleSetCheckInVisibility(
                resourceId,
                visibleToAllInput,
                setIsLoading,
                setApiError
              )) {
                window.location.hash = '#finish';
              }
            }}
            content={t('next', i18nOpts)}
          />
        </div>
      </div>
    </>
  );
}

export default Visibility;
