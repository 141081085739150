import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Label from './Label';
import Field from './Field';
import InputError from './InputError';

const InputGroup = (props) => {
  const { name, className, label, required, errors, audit, hint, optional, help, subheader, icon } = props;
  const error = _.get(errors, name);
  return (
    <div
      className={`${className} ${hint && 'input--hint'} ${error && 'input--errors'}`}
      data-ct-file-upload={props.type === 'file'}
    >
      {label && <Label text={label} inputName={name} required={required} optional={optional} />}
      {icon && icon}
      {subheader && <div className="input--subheader">{subheader}</div>}
      <Field {...props} />
      {audit && <span className="audited">{audit}</span>}
      {hint && <div className="hint">{hint}</div>}
      {error && <InputError error={error} />}
      {help && <p className="input--help">{help}</p>}
    </div>
  );
};

InputGroup.propTypes = {
  audit: PropTypes.string,
  className: PropTypes.string,
  component: PropTypes.string.isRequired,
  errors: PropTypes.object,
  hint: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  readonly: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.number]),
  optional: PropTypes.bool,
  help: PropTypes.string,
};

InputGroup.defaultProps = {
  name: 'input',
  required: false,
  placeholder: '',
  label: '',
  type: 'text',
  className: 'input',
  audit: '',
  errors: {},
  hint: '',
  readonly: false,
};

export default InputGroup;
