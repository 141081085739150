import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';

import Channel from './Channel';
import SkeletonChannel from './SkeletonChannel';

const NoConversations = () => (
  <div className="inbox-menu__contact inbox-menu__contact--empty">
    {t('no_contacts', { scope: 'inbox.side_nav.contacts.contact_list.no_contacts' })}
  </div>
);

class ChannelList extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      channels,
      channelSearchContacts,
      conversationId,
      openConversation,
      isSearchingForChannel,
      missingSearchResult,
    } = this.props;

    const channelItems = channels.map(({ id, participants, title, conversation }) => (
      <Channel
        key={id}
        participants={participants}
        title={title}
        onClick={openConversation.bind(null, conversation)}
        isActive={conversationId === conversation.id}
        isUnread={conversation.isUnread}
      />
    ));
    const channelSearchItems = this.props.channelSearchContacts.map(
      ({ id, participants, title, conversation }) => (
        <Channel
          key={id}
          participants={participants}
          title={title}
          onClick={openConversation.bind(null, conversation)}
          isActive={conversationId === conversation.id}
          isUnread={conversation.isUnread}
        />
      )
    );

    return (
      <div>
        {/* default */}
        {!isSearchingForChannel && channels.length === 0 && <NoConversations />}

        {!isSearchingForChannel && channels.length > 0 && <div>{channelItems}</div>}

        {isSearchingForChannel && (
          <div>
            {missingSearchResult && <NoConversations />}

            {channelSearchContacts.length > 0 && <div>{channelSearchItems}</div>}

            {!missingSearchResult && channelSearchContacts.length === 0 && <SkeletonChannel />}
          </div>
        )}
      </div>
    );
  }
}

ChannelList.propTypes = {
  channels: PropTypes.array.isRequired,
  channelSearchContacts: PropTypes.array.isRequired,
  conversationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isSearchingForChannel: PropTypes.bool.isRequired,
  openConversation: PropTypes.func.isRequired,
  missingSearchResult: PropTypes.bool.isRequired,
};

export default ChannelList;
