import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'
import _ from 'lodash'

import Show from './Show/PHISection'
import Edit from './Edit/PHISection'
import { Card, EditActionHeader, CancelActionHeader } from 'Components/Base/Card/'

import PatientApi from '../../api/Patient/PatientHealthInformation'

const i18nOpts = { scope: 'patient.phi_section' }

class PHISection extends Component {
  constructor(props) {
    super(props)

    this.toggleEditing = this.toggleEditing.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSuccess = this.handleSuccess.bind(this)
    this.handleErrors = this.handleErrors.bind(this)

    this.state = {
      editing: false,
      name: props.name,
      value: props.value,
      saved: props.value,
      audit: props.audit,
      errors: {},
    }
  }

  get camelName() {
    return _.camelCase(this.props.name)
  }

  get cardProps() {
    let renderHeaderAction = null

    if (this.props.canUpdate) {
      const onActionClick = this.toggleEditing
      const RenderHeaderComponent = this.state.editing ? CancelActionHeader : EditActionHeader
      renderHeaderAction = (actionWrapper) => (
        <RenderHeaderComponent onClick={() => actionWrapper(onActionClick)} />
      )
    }

    return {
      id: this.props.name,
      title: t(this.props.name, i18nOpts),
      renderHeaderAction,
      collapsable: true,
    }
  }

  toggleEditing() {
    this.setState({ editing: !this.state.editing })
  }

  handleChange({ target: { value } }) {
    this.setState({ value })
  }

  handleSubmit(evt) {
    evt.preventDefault()
    const { carePlanUuid } = this.props
    const { name, value } = this.state
    const data = {}
    data[name] = value

    new PatientApi(carePlanUuid).update(data).then((res) => {
      if (res.ok) {
        res.json().then(this.handleSuccess)
      } else if (res.status === 422) {
        res.json().then(this.handleErrors)
      }
    })
  }

  handleSuccess({ audits, patient }) {
    const value = patient.patientHealthInformation[this.camelName]
    const audit = audits.patientHealthInformation[this.camelName]
    this.setState({ editing: false, value, audit, saved: value })
  }

  handleErrors(errors) {
    this.setState({ errors })
  }

  render() {
    const { editing, value, saved, audit, errors } = this.state

    return (
      <Card {...this.cardProps}>
        {editing ? (
          <Edit
            value={value}
            audit={audit}
            errors={errors}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
          />
        ) : saved ? (
          <Show body={saved} />
        ) : (
          <div className="item-pill-section--item-list-empty">{t('none', i18nOpts)}</div>
        )}
      </Card>
    )
  }
}

PHISection.propTypes = {
  audit: PropTypes.string,
  canUpdate: PropTypes.bool.isRequired,
  carePlanUuid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
}

PHISection.defaultProps = {
  audit: '',
  value: '',
}

export default PHISection
