import React from 'react';
import { t } from 'i18n';
import PropTypes from 'prop-types';
import { TabContainer, Tab } from 'Components/Base/Tabs';
import { setSelectedHistoricalTab } from 'Actions/dashboardInsulinDosing';
import { connect } from 'react-redux';

const i18nOpts = { scope: 'dashboard' };

const translation = (translation) => {
  return (
    <>
      <span className="tabs-V2__tab__text--mobile-only">{t(translation, i18nOpts)}</span>
      <span className="tabs-V2__tab__text--desktop-only">{t(translation, i18nOpts)}</span>
    </>
  );
};

const PeriodTabs = ({ selectedTab, setSelectedTab, setSelectedHistoricalTab }) => {
  const timeRange = [
    {
      key: '7days',
      translation: 'time_range.7d',
    },
    {
      key: '14days',
      translation: 'time_range.14d',
    },
    {
      key: '30days',
      translation: 'time_range.30d',
    },
    {
      key: '90days',
      translation: 'time_range.90d',
    },
  ];

  // Returns two tab components - one for Mobile and one for Desktop
  return (
    <>
      <TabContainer>
        {timeRange.map((tr) => (
          <Tab
            text={translation(tr.translation)}
            isSelected={selectedTab === tr.key}
            onClick={() => {
              setSelectedTab(tr.key);
              setSelectedHistoricalTab(tr.key);
            }}
            key={tr.key}
            additionalClasses={'tabs-V2__tab__dark-bg'}
          />
        ))}
      </TabContainer>
    </>
  );
};

PeriodTabs.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setSelectedHistoricalTab: (selectedTab) => dispatch(setSelectedHistoricalTab(selectedTab)),
});
export default connect(null, mapDispatchToProps)(PeriodTabs);
