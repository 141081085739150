import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'

import { toggleShadowClass } from '../Base/ModalFooterToggleShadow'

class AccordionSelect extends Component {
  constructor(props) {
    super(props)

    this.handleExpand = this.handleExpand.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.renderOption = this.renderOption.bind(this)

    this.state = { open: isMobile ? '' : props.options[0] }
  }

  componentDidUpdate() {
    toggleShadowClass()
  }

  handleExpand(value) {
    this.setState({ open: value })
  }

  handleSelect(value) {
    this.setState({ open: value }, this.props.onChange.bind(null, value))
  }

  handleKeyPress(value, collapsed, { key }) {
    if (['Enter', ' '].includes(key)) {
      if (collapsed) {
        this.handleExpand(value)
      } else {
        this.handleSelect(value)
      }
    }
  }

  renderOption(option) {
    const collapsed = this.state.open !== option
    const selected = this.props.selected === option
    const classes = classNames('accordion-option', 'text-brand', {
      'accordion-option--collapsed': collapsed,
      'accordion-option--selected': selected,
    })

    return (
      <section
        key={option}
        className={classes}
        aria-expanded={!collapsed}
        tabIndex="0"
        onKeyPress={this.handleKeyPress.bind(null, option, collapsed)}
      >
        {this.props.renderOption(
          option,
          collapsed,
          selected,
          this.handleExpand.bind(null, option),
          this.handleSelect.bind(null, option)
        )}
      </section>
    )
  }

  render() {
    return <div className="accordion">{this.props.options.map(this.renderOption)}</div>
  }
}

AccordionSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string),
  renderOption: PropTypes.func.isRequired,
  selected: PropTypes.string,
}

AccordionSelect.defaultProps = {
  options: [],
  selected: '',
}

export default AccordionSelect
