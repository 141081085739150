import React from 'react'
import PropTypes from 'prop-types'

const PHISection = ({ body }) => <span style={{ whiteSpace: 'pre-wrap' }}>{body}</span>

PHISection.propTypes = {
  body: PropTypes.string.isRequired,
}

PHISection.defaultProps = {}

export default PHISection
