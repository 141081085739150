import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Icon from './Icon'

const BlankSlate = ({ iconName, icon, body, flat }) => {
  const classes = classNames('blankslate', {
    'blankslate--flat': flat,
  })

  return (
    <div className={classes}>
      {iconName ? <Icon name={iconName} /> : icon}
      <p
        dangerouslySetInnerHTML={{
          __html: body,
        }}
      />
    </div>
  )
}

BlankSlate.propTypes = {
  body: PropTypes.string.isRequired,
  flat: PropTypes.bool,
  iconName: PropTypes.string.isRequired,
}

BlankSlate.defaultProps = {
  flat: false,
}

export default BlankSlate
