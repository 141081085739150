import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { debounce } from 'lodash'

import { toggleShadowClass } from './ModalFooterToggleShadow'

class ModalFooter extends Component {
  constructor(props) {
    super(props)

    this.handleScroll = debounce(this.handleScroll.bind(this), 200)
  }

  componentDidMount() {
    const modalBody = document.querySelector('.ReactModal__ContentBody')
    if (modalBody !== null) {
      this.handleScroll()
      document.querySelector('.ReactModal__ContentBody').addEventListener('scroll', this.handleScroll)
    }
  }

  componentWillUnmount() {
    document.querySelector('.ReactModal__ContentBody').removeEventListener('scroll', this.handleScroll)
  }

  handleScroll() {
    toggleShadowClass()
  }

  render() {
    const { renderLeft, renderRight, className } = this.props

    const classes = classNames('ReactModal__ContentFooter', className)

    return (
      <div className={classes}>
        {renderRight && <div className="ReactModal__ContentFooter-right">{renderRight()}</div>}

        {renderLeft && <div className="ReactModal__ContentFooter-left">{renderLeft()}</div>}
      </div>
    )
  }
}

ModalFooter.propTypes = {
  className: PropTypes.string,
  renderLeft: PropTypes.func,
  renderRight: PropTypes.func,
}

ModalFooter.defaultProps = {
  className: null,
  renderLeft: null,
  renderRight: null,
}

export default ModalFooter
