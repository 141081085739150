import React from 'react';

import SelectFromLibrary from '../FormFields/SelectFromLibrary/SelectFromLibrary';
import Description from '../FormFields/Description';
import WhenShouldBeCompleted from '../FormFields/When/WhenShouldBeCompleted';
import Error from '../Error';

export const Form = () => {
  return (
    <div className="activity_modal">
      <SelectFromLibrary />
      <WhenShouldBeCompleted />
      <Error />
    </div>
  );
};

export default Form;
