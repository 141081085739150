import Api from './Api'

class CarePlanPlaceInvitation extends Api {
  constructor(id = null) {
    super()

    this.id = id
  }

  batchCreate({ placeIds = [], permission, carePlanUuid: carePlanId }) {
    return super.request(`${this.url}/batch_create`, 'POST', {
      carePlanId,
      carePlan: {
        carePlanPlaceInvitations: placeIds.map((placeId) => ({ placeId })),
        permission,
      },
    })
  }

  sendInvitation() {
    return super.request(`${this.url}/${this.id}/send_invitation`, 'PATCH')
  }

  get url() {
    return '/care_plan_place_invitations'
  }
}

export default CarePlanPlaceInvitation
