import React from 'react'
import PropTypes from 'prop-types'
import store from '../../store'
import { Provider } from 'react-redux'

const ProviderWrapper = ({ children }) => <Provider store={store}>{children}</Provider>

ProviderWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
}

export default ProviderWrapper
