import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'Components/Base/Icon'

const Errors = ({ errors }) => (
  <span className="profile-pic-upload__errors">
    <Icon name="warning" /> {errors}
  </span>
)

Errors.propTypes = {
  errors: PropTypes.string.isRequired,
}

export default Errors
