import React from 'react';

import Address from './Address';
import VirtualVisitLink from './VirtualVisitLink';

export const Where = () => (
  <div className="activity_modal__input_field activity_modal__input_field--address">
    <div className="activity_modal__input_field__container">
      <VirtualVisitLink />
      <Address />
    </div>
  </div>
);

export default Where;
