import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import { handleCloseActivityModal } from 'Actions/activityModal';
import {
  mode as modeDefinitions,
  activityType as activityTypeDefinitions,
} from './modalContentStateDefinitions';

import { ModalV2 } from 'Components/Base/ModalV2';
import Create from './Create';
import CreateFooter from './Create/CreateFooter';
import Edit from './Edit';
import EditFooter from './Edit/EditFooter';

const i18nOpts = { scope: 'activity_modal' };

export const ActivityModal = ({
  isOpen,
  mode,
  activityType,
  handleCloseActivityModal,
  onCreate,
  onUpdate
}) => {

  const { VIEW, CREATE, EDIT } = modeDefinitions;
  const { APPOINTMENT, CHECK_IN, FORM, TASK } = activityTypeDefinitions;

  let mainContent;
  let footer;

  switch (mode) {
    // case VIEW:
    //   mainContent = <View />;
    //   footer = <ViewFooter onView={onView} />;
    //   break;

    case CREATE:
      mainContent = <Create />;
      footer = <CreateFooter onCreate={onCreate} />;
      break;

    case EDIT:
      mainContent = <Edit />;
      footer = <EditFooter onUpdate={onUpdate} />;
      break;

    default:
      mainContent = <Create />;
      footer = <CreateFooter onCreate={onCreate} />;
      break;
  }

  let title;
  switch (activityType) {
    case APPOINTMENT:
      title = t(mode === CREATE ? 'add_appointment_title' : 'edit_appointment_title', i18nOpts);
      break;

    case CHECK_IN:
      title = t('add_check_in_title', i18nOpts);
      break;

    case FORM:
      title = t('add_form_title', i18nOpts);
      break;

    case TASK:
      title = t(mode === CREATE ? 'add_task_title' : 'edit_task_title', i18nOpts);
      break;

    default:
      title = t(mode === CREATE ? 'add_task_title' : 'edit_task_title', i18nOpts);
      break;
  }

  return (
    <ModalV2 title={title} isOpen={isOpen} onClose={handleCloseActivityModal} footer={footer}>
      {mainContent}
    </ModalV2>
  );
};

const mapStateToProps = ({ activityModal }) => {
  const { isOpen, mode, activityType } = activityModal;

  return { isOpen, mode, activityType };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchHandleCloseActivityModal = (dispatch) => {
    return () => {
      dispatch(handleCloseActivityModal());
    };
  };

  return {
    handleCloseActivityModal: dispatchHandleCloseActivityModal(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityModal);
