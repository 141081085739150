import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import * as i18nCountries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import frLocale from "i18n-iso-countries/langs/fr.json";

import { setActivityModalActivityProperties } from 'Actions/activityModal';

const i18nOpts = { scope: 'country_codes' }

const CountrySelector = ({ activityProperties, locale, setActivityModalActivityProperties }) => {
  i18nCountries.registerLocale(enLocale);
  i18nCountries.registerLocale(frLocale);

  const FOCUSED_COUNTRIES = ['CA', 'US', 'GB'];
  const countryDefinitions = i18nCountries.getNames(locale, { select: 'official' });

  const sortedDefs = Object.entries(countryDefinitions).sort((a, b) => ( a[1].localeCompare(b[1]) ));
  const sortedCountryDefinitions = sortedDefs.filter(([code]) => !FOCUSED_COUNTRIES.includes(code));

  return (
    <div className="activity_modal__address_form__input_field__country_selector">
      <label htmlFor="address.countryCode">
        {t('country', { scope: 'activity_modal.form_fields.where.address' })}
      </label>
      <select
        value={activityProperties?.addressFields?.countryCode}
        name="address.countryCode"
        onChange={({ target: { value }}) => {
          let region = activityProperties?.addressFields?.region;
          if (value == 'GB') {
            region = '';
          }
          setActivityModalActivityProperties({
            ...activityProperties,
            addressFields: {
              ...activityProperties.addressFields,
              region,
              countryCode: value,
            }
          })
        }}
      >
        <option key="blank" value="">
          {t('blank', i18nOpts)}
        </option>
        {
          FOCUSED_COUNTRIES.map(code => (
            <option key={`focused-country-${code}`} value={code}>
              { i18nCountries.getName(code, locale, { select: 'official' }) }
            </option>
          ))
        }
        {
          sortedCountryDefinitions.map(([code, displayName]) => (
            <option key={`sorted-country-${code}`} value={code}>{ displayName }</option>
          ))
        }
      </select>
    </div>
  )
};


const mapStateToProps = ({ activityModal, carePlanActionPlanViewPage }) => {
  const { activityProperties } = activityModal;
  const { sessionInfo: { locale } } = carePlanActionPlanViewPage;

  return { activityProperties, locale };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalActivityProperties = (dispatch) => {
    return (activityProperties) => {
      dispatch(setActivityModalActivityProperties(activityProperties));
    };
  };

  return {
    setActivityModalActivityProperties: dispatchSetActivityModalActivityProperties(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CountrySelector);
