import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import Switch from 'Components/Forms/Switch'
import { Tooltip } from 'Components/Base'

const CarePlan = ({ title, onChange, link, creatorName, isChecked }) => (
  <div className="overview-filter">
    <Tooltip text={t('filter', { scope: 'tasks.index.sidebar' })} position="up-left">
      <Switch className="overview-filter__switch" onChange={onChange} label={title} isChecked={isChecked} />
    </Tooltip>

    <div className="overview-filter__label">
      <a href={link} className="overview-filter__link">
        {title}
      </a>

      <small className="overview-filter__sublabel">{creatorName}</small>
    </div>
  </div>
)

CarePlan.propTypes = {
  creatorName: PropTypes.string,
  isChecked: PropTypes.bool.isRequired,
  link: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
}

CarePlan.defaultProps = {
  creatorName: '',
}

export default CarePlan
