import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';

import * as Yup from 'yup';

import { Formik, Form } from 'formik';

import { Button, ModalFooter } from 'Components/Base';
import Checkbox from 'Components/Forms/Checkbox';
import InputGroup from 'Components/Forms/InputGroup';
import Submit from 'Components/Forms/Submit';
import Referral from 'Api/Referral';

const i18nOpts = { scope: 'resources.confirmation' };

const validationSchema = Yup.object({
  confirmation: Yup.boolean().oneOf([true]),
});

function handleReferralRequest(onClick, carePlanId, menuTop, menuSub, address, locale, isSSO) {
  // Used in two places:
  //   - ReferralSection/ReferralConfirmation
  //   - Resources/ReferralLink/ReferralConfirmation
  // onClick is passed by ReferralSection/ReferralConfirmation
  //   which opens up the link in an iframe
  // In the Resources/ReferalLink/ReferralConfirmation,
  //   it does not pass onClick, so link opens up in a new window
  if (onClick) {
    onClick(isSSO, menuTop, menuSub, address);
  } else {
    const api = new Referral(carePlanId);

    return api.createLaunchString({ isSSO }).then((res) => {
      if (res.ok) {
        res.json().then(({ launch_string }) => {
          // `launch_string` is the raw one-time generated url provided by caredove
          // here we concat searchParams to the url for it to
          // preselect options when the url opens
          // menuTop: correponds to `services.referral_category`
          // menuSub: corresponds to `services.referral_subcategory`
          const link = new URL(launch_string);
          const rel = 'nofollow noopener noreferrer';

          link.searchParams.set('searchurl', 'true');
          if (menuTop) link.searchParams.set('menutop', menuTop);
          if (menuSub) link.searchParams.set('menusub', menuSub);
          if (address) link.searchParams.set('address', address);
          link.searchParams.set('lan', locale || 'en');

          window.open(link.href, rel);
        });
      }
    });
  }
}

const ReferralForm = ({
  address,
  locale,
  link,
  isSSO,
  onClick,
  closeModal,
  onBehalf,
  isPlaceUser,
  carePlanId,
  menuTop,
  menuSub,
}) => (
  <Formik
    initialValues={{ address, isSSO, confirmation: false, locale, link }}
    validationSchema={validationSchema}
    onSubmit={(values) => {
      handleReferralRequest(onClick, carePlanId, menuTop, menuSub, values.address, locale, values.isSSO);
    }}
    isInitialValid={false}
  >
    {({ values, isValid, handleChange, setFieldValue, isSubmitting }) => (
      <Form noValidate>
        <InputGroup
          name="address"
          component="input"
          label={t('address_label', i18nOpts)}
          placeholder={t('placeholder', i18nOpts)}
          value={values.address}
          hint={t('address_update_hint', i18nOpts)}
          onChange={handleChange}
        />
        <Checkbox
          checked={values.confirmation}
          name="confirmation"
          onChange={setFieldValue.bind(null, 'confirmation', !values.confirmation, true)}
          text={
            onBehalf ? t('confirmation_description', i18nOpts) : t('self_confirmation_description', i18nOpts)
          }
        />
        {
          isPlaceUser &&
          <Checkbox
            checked={values.isSSO}
            name="isSSO"
            onChange={setFieldValue.bind(null, 'isSSO', !values.isSSO, true)}
            text={t('sso_description', i18nOpts)}
          />
        }

        <ModalFooter
          renderRight={() => (
            <Fragment>
              <Button text={t('cancel', i18nOpts)} className="btn btn--secondary" onClick={closeModal} />
              <Submit value={t('next', i18nOpts)} disabled={!isValid || isSubmitting} />
            </Fragment>
          )}
        />
      </Form>
    )}
  </Formik>
);

ReferralForm.propTypes = {
  address: PropTypes.string,
  closeModal: PropTypes.func,
  link: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  isSSO: PropTypes.bool,
  onClick: PropTypes.func,
  onBehalf: PropTypes.bool,
};

ReferralForm.defaultProps = {
  address: '',
  isSSO: false,
  onClick: null,
  closeModal: null,
  onBehalf: false,
  isPlaceUser: false,
};

export default ReferralForm;
