import React, { useState, useEffect, useRef } from 'react';
import Quill from 'quill';

// This is used to view the output of the `QuillEditor`. See `QuillEditor` for more documentation
const QuillViewer = ({ contentFormatted }) => {
  const viewerContainerRef = useRef(null);
  const viewerRef = useRef(null);

  const [quill, setQuill] = useState();

  const options = {
    readOnly: true,
  };

  useEffect(() => {
    const viewerEl = viewerRef.current;
    const quillLocal = new Quill(viewerEl, options);
    setQuill(quillLocal);

    quillLocal.setContents(contentFormatted);

    return () => {
      // viewerContainerRef needs to be removed manually, otherwise it will stay there even if the React component is gone
      viewerContainerRef.current.remove();
    };
  }, []);

  return (
    <div id="quill-viewer-container" ref={viewerContainerRef}>
      <div id="quill-viewer" ref={viewerRef}></div>
    </div>
  );
};

export default QuillViewer;
