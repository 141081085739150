import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { MenuItem, MenuItemLink } from 'Components/Base/Menu'
import Backdrop from 'Components/Base/Backdrop'

const i18nOpts = { scope: 'patient.index.new_care_plan_menu' }

const NewCarePlanMenu = ({ closeMenu, newCarePlanPath, onUseTemplateClick }) => (
  <div className="dropdown">
    <div className="dropdown__menu">
      <MenuItemLink link={newCarePlanPath} text={t('create_new_care_plan', i18nOpts)} />

      <MenuItem onClick={onUseTemplateClick} text={t('use_template', i18nOpts)} />
    </div>

    <Backdrop transparent onClick={closeMenu} />
  </div>
)

NewCarePlanMenu.propTypes = {
  closeMenu: PropTypes.func.isRequired,
  newCarePlanPath: PropTypes.string.isRequired,
  onUseTemplateClick: PropTypes.func.isRequired,
}

export default NewCarePlanMenu
