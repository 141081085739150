import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

class InviteCode extends Component {
  constructor(props) {
    super(props)

    this.state = { templateCode: '' }
  }

  componentDidMount() {
    const templateInput = this.refs.groupRef
      .closest('.invitation-modal')
      .querySelector('#place_users_invite_form_care_plan_template_code')

    if (templateInput) {
      this.setState({ templateCode: templateInput.value })
      templateInput.addEventListener('change', (evt) => {
        this.setState({ templateCode: evt.target.value })
      })
    }
  }

  get url() {
    const queryParams = [this.props.code, this.state.templateCode].filter((p) => p).join('-')
    return `${this.props.path}?code=${queryParams}`
  }

  render() {
    return (
      <div className="input-group" ref="groupRef">
        <span className="input-group__addon">{t('link', { scope: 'base.invite_code' })}</span>
        <input value={this.url} readOnly type="url" />
      </div>
    )
  }
}

InviteCode.propTypes = {
  code: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
}

export default InviteCode
