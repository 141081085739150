import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'
import Flash from 'Components/Base/Flash'

import { Card, AddActionHeader, CancelActionHeader } from 'Components/Base/Card/'
import TeamMember from './TeamMember'
import RoleChip from './RoleChip'

const i18nOpts = { scope: 'team.team' }

class Team extends Component {
  constructor(props) {
    super(props)

    this.state = {
      members: this.props.members,
      carePlanId: this.props.carePlanId,
      showInviteFlash: false,
      showInviteForm: false,
    }
  }

  get cardProps() {
    let renderHeaderAction = null

    if (this.props.canAdd) {
      const onActionClick = this.handleActionClick.bind(this)
      const RenderHeaderComponent = this.state.showInviteForm ? CancelActionHeader : AddActionHeader
      renderHeaderAction = (actionWrapper) => (
        <RenderHeaderComponent onClick={() => actionWrapper(onActionClick)} />
      )
    }

    return {
      renderHeaderAction,
      renderChip: <RoleChip role={this.props.role} className="team-member__role-chip" />,
      collapsable: true,
      id: this.props.id,
      title: this.props.title,
    }
  }

  handleActionClick() {
    this.setState((prevState) => ({ showInviteForm: !prevState.showInviteForm }))
  }

  handleSuccess({ teamMembership }) {
    this.setState((prevState) => ({
      members: prevState.members.concat(teamMembership),
      showInviteForm: false,
    }))
  }

  toggleInviteFlash() {
    this.setState({ showInviteFlash: true })
  }

  render() {
    // @TODO: Remove once we no longer use this component for services
    const { address, form, locale, name, role, useReduxState } = this.props
    const { carePlanId, showInviteFlash, showInviteForm } = this.state
    const members = useReduxState ? this.props.members : this.state.members

    return (
      <Card {...this.cardProps}>
        {role && (
          <div className="team-member__role-description">
            <div className="team-member__role-description-text">
              {t(`${this.props.role}.description`, { ...i18nOpts, name })}
            </div>
          </div>
        )}
        {showInviteFlash && <Flash text={t('invite_success', i18nOpts)} />}
        {showInviteForm &&
          form &&
          React.cloneElement(form, {
            onSuccess: this.handleSuccess.bind(this),
            toggleForm: this.handleActionClick.bind(this),
          })}

        {members.length ? (
          <ul>
            {members.map((member) => (
              <TeamMember
                address={address}
                locale={locale}
                key={member.id}
                member={member}
                carePlanId={carePlanId}
                toggleInviteFlash={this.toggleInviteFlash.bind(this)}
              />
            ))}
          </ul>
        ) : (
          <p className="item-pill-section--item-list-empty">{t('no_members', i18nOpts)}</p>
        )}
      </Card>
    )
  }
}

Team.propTypes = {
  address: PropTypes.string,
  canAdd: PropTypes.bool,
  carePlanId: PropTypes.string,
  form: PropTypes.element,
  id: PropTypes.string,
  locale: PropTypes.string,
  members: PropTypes.array.isRequired,
  name: PropTypes.string,
  role: PropTypes.string,
  title: PropTypes.string.isRequired,
  useReduxState: PropTypes.bool,
}

Team.defaultProps = {
  address: '',
  canAdd: false,
  carePlanId: '',
  form: null,
  id: '',
  locale: 'en',
  members: [],
  name: null,
  role: '',
  useReduxState: false,
}

export default Team
