import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import {
  setActivityModalActivityProperties,
  setActivityModalLibraryDropdownIsOpen,
  setActivityModalLibraryDropdownState,
  setActivityModalLibraryDropdownSearchInput,
  setActivityModalLibraryDropdownTemplates,
  setActivityModalLibraryDropdownTemplatesToDisplay,
} from 'Actions/activityModal';
import handleTemplatesSetup from './handleTemplatesSetup';

const i18nOpts = { scope: 'activity_modal.form_fields.select_from_library' };

export const OpenButton = ({
  carePlanId,
  activityType,
  activityProperties,
  libraryDropdown,
  setActivityModalActivityProperties,
  setActivityModalLibraryDropdownIsOpen,
  setActivityModalLibraryDropdownState,
  setActivityModalLibraryDropdownSearchInput,
  setActivityModalLibraryDropdownTemplates,
  setActivityModalLibraryDropdownTemplatesToDisplay,
}) => {
  const { isOpen } = libraryDropdown;

  const onClick = () => {
    if (isOpen) {
      setActivityModalLibraryDropdownIsOpen(false);
      setActivityModalLibraryDropdownSearchInput('');
    } else {
      setActivityModalLibraryDropdownIsOpen(true);
      setActivityModalLibraryDropdownSearchInput('');
      handleTemplatesSetup(
        setActivityModalLibraryDropdownState,
        setActivityModalLibraryDropdownTemplates,
        setActivityModalLibraryDropdownTemplatesToDisplay,
        carePlanId,
        activityType
      );
    }
  };

  let labelText;
  switch (activityType) {
    case 'CHECK_IN':
      labelText = t('select_check_in', i18nOpts);
      break;
    case 'FORM':
    default:
      labelText = t('select_form', i18nOpts);
      break;
  }

  return (
    <>
      <label
        className="activity_modal__library__template_selector__template_selector_label"
        onClick={onClick}
      >
        {labelText}
      </label>
      <div
        name="templateSelector"
        id="templateSelector"
        className="activity_modal__library__template_selector"
        onClick={onClick}
        tabIndex={0}
        onFocus={onClick}
      >
        {activityProperties?.name || ''}
      </div>
    </>
  );
};

const mapStateToProps = ({ activityModal }) => {
  const { carePlanId, activityType, activityProperties, libraryDropdown } = activityModal;

  return { carePlanId, activityType, activityProperties, libraryDropdown };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalActivityProperties = (dispatch) => {
    return (activityProperties) => {
      dispatch(setActivityModalActivityProperties(activityProperties));
    };
  };

  const dispatchSetActivityModalLibraryDropdownIsOpen = (dispatch) => {
    return (isOpen) => {
      dispatch(setActivityModalLibraryDropdownIsOpen(isOpen));
    };
  };

  const dispatchSetActivityModalLibraryDropdownState = (dispatch) => {
    return (libraryDropdownState) => {
      dispatch(setActivityModalLibraryDropdownState(libraryDropdownState));
    };
  };

  const dispatchSetActivityModalLibraryDropdownSearchInput = (dispatch) => {
    return (searchInput) => {
      dispatch(setActivityModalLibraryDropdownSearchInput(searchInput));
    };
  };

  const dispatchSetActivityModalLibraryDropdownTemplates = (dispatch) => {
    return (templates) => {
      dispatch(setActivityModalLibraryDropdownTemplates(templates));
    };
  };

  const dispatchSetActivityModalLibraryDropdownTemplatesToDisplay = (dispatch) => {
    return (templatesToDisplay) => {
      dispatch(setActivityModalLibraryDropdownTemplatesToDisplay(templatesToDisplay));
    };
  };

  return {
    setActivityModalActivityProperties: dispatchSetActivityModalActivityProperties(dispatch),
    setActivityModalLibraryDropdownIsOpen: dispatchSetActivityModalLibraryDropdownIsOpen(dispatch),
    setActivityModalLibraryDropdownState: dispatchSetActivityModalLibraryDropdownState(dispatch),
    setActivityModalLibraryDropdownSearchInput: dispatchSetActivityModalLibraryDropdownSearchInput(dispatch),
    setActivityModalLibraryDropdownTemplates: dispatchSetActivityModalLibraryDropdownTemplates(dispatch),
    setActivityModalLibraryDropdownTemplatesToDisplay:
      dispatchSetActivityModalLibraryDropdownTemplatesToDisplay(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenButton);
