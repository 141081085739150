import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ItemPillText = ({ text, subtext, inline, large, truncate, ...rest }) => {
  const classes = classNames('item-pill__text', {
    'text-truncate': truncate,
    'item-pill__text--inline': inline,
    'item-pill__text--large': large,
  });
  const innerClass = classNames('item-pill__name', { 'text-truncate': truncate });
  return (
    <div className={classes} {...rest}>
      <div className={innerClass}>{text}</div>

      {subtext && <small>{subtext}</small>}
    </div>
  );
};

ItemPillText.propTypes = {
  inline: PropTypes.bool,
  large: PropTypes.bool,
  subtext: PropTypes.string,
  text: PropTypes.string.isRequired,
  truncate: PropTypes.bool,
};

ItemPillText.defaultProps = {
  inline: false,
  large: false,
  subtext: '',
  truncate: true,
};

export default ItemPillText;
