import React from 'react'
import { t } from 'i18n'

const i18nOpts = { scope: 'team.manage_invitation' }

const ManageInviteLink = ({ carePlanUuid }) => (
  <a href={`/care_plans/${carePlanUuid}/patient`} className="team-member__section team-member__section--resend-invitation">
    {t('header', i18nOpts)}
  </a>
);

export default ManageInviteLink;
