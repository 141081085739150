import React from 'react';
import Icon from '../Icon';

const Card = ({ title, children, handleClick, containerClass, showEdit }) => (
  <main className={`card-editable ${containerClass}`}>
    <section className="card-editable__header">
      <h2>{title}</h2>
      {showEdit && (
        <button className={`btn--link card__header-link edit_btn`} onClick={handleClick}>
          <Icon name="file-edit" className="edit_icon" />
          Edit
        </button>
      )}
    </section>

    <section className="card-editable__section">{children}</section>
  </main>
);

export default Card;
