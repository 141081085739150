import { combineReducers } from 'redux';

import accessRequests from './accessRequests';
import agenda from './agenda';
import currentUser from './current-user';
import documents from './documents';
import editButton from './editButton';
import filters from './filters';
import modal from './modal';
import tasks from './tasks';
import notificationTray from './notificationTray';
import supportTeam from './supportTeam';
import deferredImages from './deferredImages';
import teamInvitationsModal from './teamInvitationsModal';
import dashboardInsulinDosing from './dashboardInsulinDosing';
import dashboardComparisonChart from './dashboardComparisonChart';
import carePlanPlaceInvitationsPage from './carePlanPlaceInvitationsPage';
import carePlanActionPlanViewPage from './carePlanActionPlanViewPage';
import signupPage from './signupPage';
import recommendationsBlock from './recommendationsBlock';
import activityModal from './activityModal';
import placeUsersPatientsIndexPage from './placeUsersPatientsIndexPage';

export default combineReducers({
  currentUser,
  documents,
  editButton,
  filters,
  tasks,
  agenda,
  modal,
  notificationTray,
  supportTeam,
  deferredImages,
  teamInvitationsModal,
  accessRequests,
  carePlanPlaceInvitationsPage,
  carePlanActionPlanViewPage,
  signupPage,
  dashboardInsulinDosing,
  dashboardComparisonChart,
  recommendationsBlock,
  activityModal,
  placeUsersPatientsIndexPage,
});
