import updatePreferencesApi from './updatePreferencesApi';

export const updatePreferencesWrapper = (
  updatePreferencesApiProvider = updatePreferencesApi
) => {
  return async ({ payload }) => {
    try {
      updatePreferencesApiProvider({ payload });
    } catch {
      console.error('Could not update preferences');
    }
  };
};

export default updatePreferencesWrapper(updatePreferencesApi);