import { useEffect, useRef } from 'react';

// https://stackoverflow.com/questions/57858803/react-how-to-skip-useeffect-on-first-render
// Added to prevent error messages on first load until the user interacts with the form
const useComponentDidMount = () => {
  const ref = useRef();
  useEffect(() => {
    ref.current = true;
  }, []);
  return ref.current;
};

export default useComponentDidMount;
