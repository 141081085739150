import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import ProviderWrapper from 'Components/Base/ProviderWrapper'
import { Banner } from './index'
import { InvitationModal } from 'Components/Team/HealthTeamContacts'

class HealthTeamBannerContainer extends Component {
  constructor(props) {
    super(props)

    this.handleCloseBanner = this.handleCloseBanner.bind(this)
    this.handleModalOpen = this.handleModalOpen.bind(this)
    this.handleModalClose = this.handleModalClose.bind(this)
    this.handleSuccess = this.handleSuccess.bind(this)
    this.renderBanner = this.renderBanner.bind(this)

    this.state = {
      showBanner: true,
      modalIsOpen: false,
    }
  }

  handleCloseBanner() {
    this.setState({ showBanner: false })
  }

  handleModalOpen() {
    this.setState({ modalIsOpen: true })
  }

  handleModalClose() {
    this.setState({ modalIsOpen: false })
  }

  handleSuccess() {
    this.setState({ modalIsOpen: false, showBanner: false })
  }

  renderBanner() {
    return (
      <Banner
        onClose={this.handleCloseBanner}
        onInvite={this.handleModalOpen}
        patientName={this.props.patientName}
        title={this.props.title}
      />
    )
  }

  render() {
    return (
      <ProviderWrapper>
        <Fragment>
          {this.state.showBanner && this.renderBanner()}

          <InvitationModal
            onClose={this.handleModalClose}
            modalIsOpen={this.state.modalIsOpen}
            places={this.props.places}
            carePlanUuid={this.props.carePlanUuid}
            onSuccess={this.handleSuccess}
          />
        </Fragment>
      </ProviderWrapper>
    )
  }
}

HealthTeamBannerContainer.propTypes = {
  carePlanUuid: PropTypes.string.isRequired,
  patientName: PropTypes.string.isRequired,
  places: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      address: PropTypes.string,
      logo: PropTypes.string,
      phoneNumber: PropTypes.string,
    })
  ),
  title: PropTypes.string.isRequired,
}

HealthTeamBannerContainer.defaultProps = {
  places: [],
}

export default HealthTeamBannerContainer
