import Fuse from 'fuse.js'

class SearchIndex {
  constructor(items = []) {
    this.items = items
    this.fuseSearch = new Fuse(this.items, this.fuseOptions)
  }

  search(query) {
    if (!query) return this.items
    return this.fuseSearch.search(query)
  }

  get fuseOptions() {
    console.err('Implement this on your child class!')
  }
}

export default SearchIndex
