import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';

import { Button, ModalFooter } from 'Components/Base';

import { ItemPill, ItemPillText, ItemPillActions, ItemPillLargeActionV2 } from 'Components/Base/ItemPill';

const i18nOpts = { scope: 'team.manage_invitation' };

const ActionSelect = ({ member, onClose, onEdit, onRemoveInvite, onResend, canEdit, statusMessage }) => {
  const renderItemPill = (statusMessage, inner) => {
    let snippet;
    if (statusMessage) {
      snippet = (
        <div className="modal--wrapper">
          <ItemPill flat large stretch multiline borderless>
            {inner}
          </ItemPill>
          {statusMessage && <div className="modal--status-message">{statusMessage}</div>}
        </div>
      );
    } else {
      snippet = (
        <ItemPill flat large stretch multiline isolated>
          {inner}
        </ItemPill>
      );
    }
    return snippet;
  };

  return (
    <Fragment>
      <div
        className="ReactModal__ContentBody-text"
        dangerouslySetInnerHTML={{ __html: t('body', i18nOpts) }}
      />

      {renderItemPill(
        statusMessage,
        <>
          <ItemPillText text={member.email} subtext={t(`team.roles.${member.role}`)} truncate={false} />
          <ItemPillActions>
            <ItemPillLargeActionV2
              iconName="revert"
              iconClass="icon__team-member-modal-revert"
              label={t('resend', i18nOpts)}
              onClick={onResend}
            />
            {canEdit &&
              <ItemPillLargeActionV2
                iconClass="icon__team-member-modal-edit"
                iconName="edit_alt"
                label={t('edit', i18nOpts)}
                onClick={onEdit}
              />
            }
            <ItemPillLargeActionV2
              iconClass="icon__team-member-modal-trash"
              iconName="trash"
              label={t('remove', i18nOpts)}
              onClick={onRemoveInvite}
            />
          </ItemPillActions>
        </>
      )}
      <ModalFooter
        renderRight={() => (
          <Button text={t('close', i18nOpts)} className="btn btn--secondary" onClick={onClose} />
        )}
      />
    </Fragment>
  );
};

ActionSelect.propTypes = {
  member: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemoveInvite: PropTypes.func.isRequired,
  onResend: PropTypes.func.isRequired,
  statusMessage: PropTypes.string,
  canEdit: PropTypes.bool,
};

ActionSelect.defaultProps = {
  canEdit: false,
}

export default ActionSelect;
