import Api from './Api'

class Tag extends Api {
  constructor(id = null) {
    super()

    this.id = id
  }

  update(body = {}) {
    return this.request(`${this.url}/${this.id}`, 'PATCH', body)
  }

  destroy(body = {}) {
    return this.request(`${this.url}/${this.id}`, 'DELETE', body)
  }

  get url() {
    return `/admin/tags`
  }
}

export default Tag
