import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import ResourceList from './ResourceList';
import Resource from './Resource';
import ResourceNavigation from './ResourceNavigation';
import ResourceForm from 'Components/Forms/ResourceForm';
import { Modal } from 'Components/Base/Modal';
import { Button } from 'Components/Base';
import { Footer } from 'Components/Base/Card/';
import ResourceFormContainer from './ResourceFormContainer';
import AdHocResourceDispatcherApi from 'Api/Dispatchers/AdHocResourceDispatcher';
import ServiceDispatcherApi from 'Api/Dispatchers/ServiceDispatcher';

import { t } from 'i18n';

const i18nOpts = { scope: 'care_plan_creators.resources' };
const i18nOptsTags = { scope: 'tags' };

class ResourceSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabs: [],
      input: '',
      selectedTab: '',
      resources: [],
      filteredResources: [],
      placeResourceSelectionStates: {},
      documents: [],
      editingResourceId: null,
      isModalOpen: false,
      isAddResourceModalOpen: false,
      recordsProcessing: {},
      filteredModalResources: [],
      ...this.initialState,
    };
  }

  get initialState() {
    const initialState = this.setInitialState();

    return {
      tabs: initialState.tabs,
      documents: initialState.documents,
      selectedTab: initialState.selectedTab,
      resources: initialState.resources,
      filteredResources: initialState.resources,
      placeResourceSelectionStates: initialState.placeResourceSelectionStates,
      filteredModalResources: initialState.filteredModalResources,
    };
  }

  setInitialState = () => {
    const { placeResources, selectedAdHocResources, selectedPlaceResources, tagMetadata } =
      this.props.resources;

    const adHocSelected = selectedAdHocResources.map((res) => ({ ...res, isAdHoc: true }));
    const placeSelected = selectedPlaceResources
      .map((res) => ({ ...res, isAdHoc: false }))
      .sort((a, b) => b.usage - a.usage);

    const selectedResources = [...adHocSelected, ...placeSelected];

    const filteredModalResources = this.props.resources.placeResources;

    const filteredModalResourcesIds = filteredModalResources.map(res => res.id);
    const placeResourceSelectionStates = {};
    placeResources.forEach((res) => {
      if (filteredModalResourcesIds.includes(res.id)) placeResourceSelectionStates[res.id] = false;
    });
    selectedPlaceResources.forEach((res) => {
      if (filteredModalResourcesIds.includes(res.id)) placeResourceSelectionStates[res.id] = true;
    });

    const selectedTab = t('all_resources', i18nOpts);
    const tags = tagMetadata.map((tag) => tag.text);

    const tabs = [selectedTab, ...tags];

    return {
      tabs,
      documents: this.props.documents,
      selectedTab,
      resources: selectedResources,
      placeResourceSelectionStates,
      filteredModalResources,
    };
  };

  handleFilterResources = (input, selectedTab, resources = this.state.resources) => {
    let filteredResources =
      selectedTab === this.state.tabs[0]
        ? resources.filter((res) => res.name.toLowerCase().includes(input.toLowerCase())) // All Resources
        : resources.filter(
          (res) =>
            res.name.toLowerCase().includes(input.toLowerCase()) &&
            res.tags.map((tag) => tag.label).includes(selectedTab)
        );

    if (selectedTab === t('other', i18nOptsTags)) {
      filteredResources = resources.filter(
        (res) => res.name.toLowerCase().includes(input.toLowerCase()) && res.tags.length < 1
      );
    }

    this.setState({ filteredResources });
  };

  handleInputChange = (evt) => {
    this.setState({ input: evt.target.value });
    this.handleFilterResources(evt.target.value, this.state.selectedTab);
  };

  handleSelectTab = (selectedTab) => {
    this.setState({ selectedTab });
    this.handleFilterResources(this.state.input, selectedTab);
  };

  handleRemoveResourceFromState = (resourceToRemove) => {
    const resources = this.state.resources.filter((resource) => resource.id !== resourceToRemove.id);
    let placeResourceSelectionStates = { ...this.state.placeResourceSelectionStates };
    if (!resourceToRemove.isAdHoc) {
      placeResourceSelectionStates[resourceToRemove.id] = false;
    }

    this.setState({ resources, placeResourceSelectionStates });
    this.handleFilterResources(this.state.input, this.state.selectedTab, resources);
  };

  setRecordsProcessing = (resource, isResourceProcessing) => {
    this.setState((prevState) => ({
      recordsProcessing: Object.assign(prevState.recordsProcessing, { [resource.id]: isResourceProcessing }),
    }));
  };

  toggleResourceSelected = (resource) => {
    const newSelectionState = !this.state.placeResourceSelectionStates[resource.id];
    this.setState((prevState) => ({
      placeResourceSelectionStates: {
        ...prevState.placeResourceSelectionStates,
        [resource.id]: newSelectionState,
      },
    }));
  };

  dispatchRemoveResource = async (resourceToRemove) => {
    this.setRecordsProcessing(resourceToRemove, true);

    const api = resourceToRemove.isAdHoc
      ? new AdHocResourceDispatcherApi(
        this.props.isTemplate ? this.props.parentId : this.props.carePlanUuid,
        this.props.isTemplate,
        resourceToRemove.id
      )
      : new ServiceDispatcherApi(
        this.props.isTemplate ? this.props.parentId : this.props.carePlanUuid,
        this.props.isTemplate
      );

    let response;
    if (resourceToRemove.isAdHoc) {
      response = await api.destroy();
    } else {
      const updatedSelectionStates = [{ owner_id: resourceToRemove.id, selected: false }];
      response = await api.update(updatedSelectionStates);
    }

    if (response.ok) {
      this.handleRemoveResourceFromState(resourceToRemove);
      if (!resourceToRemove.isAdHoc) {
        this.setState((state) => ({
          recordsProcessing: { ...state.recordsProcessing, [resourceToRemove.id]: false },
        }));
      }
    } else if (response.status === 422) {
      this.setRecordsProcessing(resourceToRemove, false);
      return response.json().then(console.error);
    }
  };

  handleOpenModal = (resourceId) => {
    this.setState({ isModalOpen: true, editingResourceId: resourceId });
  };

  handleCloseModal = () => {
    this.setState({ isModalOpen: false, editingResourceId: null });
  };

  navigateToNextSection = () => {
    window.location.hash = '#documents';
  };

  handleOpenAddResourceModal = () => {
    this.setState({
      filteredModalResources: this.props.resources.placeResources,
      isAddResourceModalOpen: true,
    });
  };

  handleCloseAddResourceModal = () => {
    this.setState({ isAddResourceModalOpen: false });
  };

  handleFilterModalResources = (input, tags = []) => {
    const filteredModalResources = this.props.resources.placeResources.filter(
      (res) =>
        res.name.toLowerCase().includes(input.toLowerCase()) &&
        tags.map((tag) => tag.label).every((label) => res.tags.map((tag) => tag.label).includes(label))
    );

    this.setState({ filteredModalResources });
  };

  handleSubmit = async () => {
    const requestObject = Object.entries(this.state.placeResourceSelectionStates).map((entry) => ({
      owner_id: entry[0],
      selected: entry[1],
    }));

    const api = new ServiceDispatcherApi(
      this.props.isTemplate ? this.props.parentId : this.props.carePlanUuid,
      this.props.isTemplate
    );

    const response = await api.update(requestObject);

    if (response.ok) {
      this.handleCloseAddResourceModal();

      let resources = [...this.state.resources];
      let recordsProcessing = {};

      for (const placeResourceId in this.state.placeResourceSelectionStates) {
        const placeResource = this.props.resources.placeResources.find(
          (resource) => String(resource.id) === placeResourceId
        );

        if (this.state.placeResourceSelectionStates[placeResourceId]) {
          if (!resources.some((resource) => resource.id === placeResource.id)) {
            // Allow place resources the user just added to be modified (in the UI) by the user
            resources.push({ ...placeResource, canModify: true });
          }
        } else {
          resources = resources.filter((resource) => resource.id !== placeResource.id);
        }
      }

      this.setState({ resources, recordsProcessing });
      this.handleFilterResources(this.state.input, this.state.selectedTab);
    } else if (response.status === 422) {
      return response.json().then(console.error);
    }
  };

  handleServiceSuccess = (service) => {
    service.isAdHoc = true;
    let resources = [...this.state.resources];

    if (resources.some((resource) => resource.id === service.id)) {
      resources = resources.map((resource) => (resource.id === service.id ? { ...service, canModify: true } : resource));
    } else {
      resources = [...resources, { ...service, canModify: true }];
    }

    this.setState({
      resources,
      isModalOpen: false,
      isAddResourceModalOpen: false,
      editingResourceId: null,
    });
    this.handleFilterResources(this.state.input, this.state.selectedTab);
  };

  resourceAttributes = (res) => {
    const isRemovable = this.props.canEditResources && res.authorType !== 'User' && res.canModify;
    const isModifiable = isRemovable && res.isAdHoc;

    const isResourceSelected = this.state.placeResourceSelectionStates[res.id] || res.isAdHoc;
    const checkboxProps = {
      name: isResourceSelected ? 'checkbox-selected' : 'checkbox-unselected',
      className: classNames({
        icon: true,
        'icon--medium': true,
        'icon--green': isResourceSelected,
      }),
    };

    const tagString = res.tags
      .map((tag) => tag.label)
      .filter((tag) => tag !== t('other', i18nOptsTags))
      .sort((a, b) => a.localeCompare(b))
      .join(', ');

    return {
      isRemovable,
      isModifiable,
      isResourceSelected,
      checkboxProps,
      tagString,
    };
  };

  renderModalForm = () => {
    const service = this.state.resources.find((res) => res.id === this.state.editingResourceId);

    return (
      <Modal
        isOpen={this.state.isModalOpen}
        closeModal={this.handleCloseModal}
        title={this.state.editingResourceId ? t('form.update', i18nOpts) : t('form.add', i18nOpts)}
      >
        {this.state.isModalOpen && (
          <ResourceForm
            allTags={this.props.allTags}
            isTemplate={this.props.isTemplate}
            isAdHoc={service.isAdHoc}
            parentId={this.props.isTemplate ? this.props.parentId : this.props.carePlanUuid}
            serviceId={this.state.editingResourceId}
            service={service}
            locale={this.props.locale}
            onCancel={this.handleCloseModal}
            onSuccess={this.handleServiceSuccess}
            caredoveEnabled={this.props.caredoveEnabled}
          />
        )}
      </Modal>
    );
  };

  renderAddResourceModal = () => {
    return (
      <Modal
        isOpen={this.state.isAddResourceModalOpen}
        closeModal={this.handleCloseAddResourceModal}
        title={t('form.add', i18nOpts)}
      >
        <ResourceFormContainer
          allTags={this.props.allTags}
          dispatchRemoveResource={this.dispatchRemoveResource}
          filteredModalResources={this.state.filteredModalResources}
          onCloseAddResourceModal={this.handleCloseAddResourceModal}
          onFilterModalResources={this.handleFilterModalResources}
          onOpenModal={this.handleOpenModal}
          onSubmit={this.handleSubmit}
          resourceAttributes={this.resourceAttributes}
          toggleResourceSelected={this.toggleResourceSelected}
          onCancel={this.handleCloseAddResourceModal}
          onSuccess={this.handleServiceSuccess}
          parentId={this.props.isTemplate ? this.props.parentId : this.props.carePlanUuid}
          locale={this.props.locale}
          isSupportTeam={this.props.isSupportTeam}
          isTemplate={this.props.isTemplate}
          caredoveEnabled={this.props.caredoveEnabled}
        />
      </Modal>
    );
  };

  render() {
    return (
      <>
        {this.renderModalForm()}
        {this.renderAddResourceModal()}

        <ResourceNavigation
          // TODO CAR-3897: add resource button
          tabs={this.state.tabs}
          input={this.state.input}
          selectedTab={this.state.selectedTab}
          handleInputChange={this.handleInputChange}
          handleSelectTab={this.handleSelectTab}
          handleOpenAddResourceModal={this.handleOpenAddResourceModal}
          isNavigationDisplayed={this.props.isNavigationDisplayed}
        />

        <ResourceList
          selectedTab={this.state.selectedTab}
          maxResourcesPerPage={this.props.maxResourcesPerPage}
        >
          {this.state.filteredResources.map((res) => {
            const resAttributes = this.resourceAttributes(res);

            return (
              <Resource
                key={res.id}
                resource={res}
                checkboxProps={resAttributes.checkboxProps}
                isProcessing={!!this.state.recordsProcessing[res.id]}
                isTogglable={resAttributes.isTogglable}
                isRemovable={resAttributes.isRemovable}
                isModifiable={resAttributes.isModifiable}
                toggleResourceSelected={this.toggleResourceSelected}
                onRemoveResource={this.dispatchRemoveResource}
                onOpenModal={this.handleOpenModal}
                isResourceSelected={resAttributes.isResourceSelected}
                tagString={resAttributes.tagString}
              />
            );
          })}
        </ResourceList>

        {this.props.isCreatingAp && (
          <Footer
            right={<Button text={t('next', i18nOpts)} className="btn" onClick={this.navigateToNextSection} />}
          />
        )}
      </>
    );
  }
}

ResourceSection.propTypes = {
  allTags: PropTypes.array,
  carePlanUuid: PropTypes.string,
  canEditResources: PropTypes.bool,
  documents: PropTypes.array,
  isCreatingAp: PropTypes.bool.isRequired,
  isNavigationDisplayed: PropTypes.bool,
  isSupportTeam: PropTypes.bool,
  isTemplate: PropTypes.bool.isRequired,
  maxResourcesPerPage: PropTypes.number,
  parentId: PropTypes.number,
  resources: PropTypes.shape({
    placeResources: PropTypes.array,
    selectedAdHocResources: PropTypes.array,
    selectedPlaceResources: PropTypes.array,
    tagMetadata: PropTypes.array,
  }),
  caredoveEnabled: PropTypes.bool,
};

ResourceSection.defaultProps = {
  allTags: [],
  carePlanUuid: '',
  canEditResources: false,
  documents: [],
  isNavigationDisplayed: true,
  isSupportTeam: false,
  maxResourcesPerPage: 12,
  parentId: null,
  resources: {
    placeResources: [],
    selectedAdHocResources: [],
    selectedPlaceResources: [],
    tagMetadata: [],
  },
  caredoveEnabled: false,
};

export default ResourceSection;
