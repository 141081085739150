import Api from './Api'

class RepeatedForm extends Api {
  constructor(carePlanId, id) {
    super()

    this.id = id
    this.carePlanId = carePlanId
  }

  get url() {
    return `/care_plans/${this.carePlanId}/repeated_forms`
  }
}

export default RepeatedForm
