import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import _ from 'lodash';

import InputGroup from 'Components/Forms/InputGroup';

import TemplateRecurrenceInput from './../CarePlans/TemplateRecurrenceInput';
import LibraryActivityTemplateApi from 'Api/TemplateCreator/LibraryActivityTemplate';
import { Button } from 'Components/Base';
import { Footer } from 'Components/Base/Card/';
import { Modal } from 'Components/Base/Modal';
import Label from 'Components/Forms/Label';
import ResourceListItem from 'Components/Resources/ResourceListItem';
import LinkResources from 'Components/Resources/LinkResources';
import LinkResourceButton from 'Components/Tasks/Index/LinkResourceButton';

import AddressForm from './FormFields/Address';
import FormField from './FormFields/FormField';

const i18nOpts = (templateType) => ({
  scope: `care_plan_creators.library_${templateType.toLowerCase()}_templates.form`,
});

class LibraryActivityTemplate extends Component {
  constructor(props) {
    super(props);

    const {
      name,
      text,
      count,
      daysOffset,
      endRepeat,
      frequency,
      frequencyType,
      interval,
      timeOffset,
      services,
      timeZone,
      practitioner,
      url,
    } = props.activityTemplate;

    const address = props.activityTemplate.address
      ? props.activityTemplate.address
      : { address: '', extendedAddress: '', city: '', region: '', postalCode: '', countryCode: '' };

    this.state = {
      address: address.address,
      extendedAddress: address.extendedAddress,
      city: address.city,
      region: address.region,
      postalCode: address.postalCode,
      countryCode: address.countryCode,
      name,
      text,
      count,
      daysOffset,
      endRepeat,
      frequency,
      frequencyType,
      interval,
      timeOffset,
      timeZone,
      selectedResources: services,
      id: props.id,
      isResourcesModalOpen: false,
      errors: {},
      practitioner,
      url,
    };

    this.handleSelectResources = this.handleSelectResources.bind(this);
  }

  handleChange = (event) => {
    const { id, name, value } = event.target;
    const frequency = this.state.frequency;

    if (name === 'frequencyType' && value === 'recurring' && frequency === '') {
      this.setState({ frequencyType: value, frequency: 'daily' });
    } else if (id) {
      const newState = { ...this.state };
      newState[id] = value;
      this.setState(newState);
    } else if (name === 'timeOffset') {
      this.setState({ timeOffset: value });
    } else {
      this.setState({ [name]: value })
    }
  };

  get availableResources() {
    const resources = _.flatten(Object.values(this.props.resources));

    return _.uniqBy(resources, (res) => {
      return res.id.valueOf();
    });
  }

  getParams = () => {
    const {
      name,
      text,
      count,
      daysOffset,
      endRepeat,
      frequency,
      frequencyType,
      interval,
      timeOffset,
      timeZone,
      address,
      extendedAddress,
      city,
      region,
      postalCode,
      countryCode,
      url,
      practitioner,
      selectedResources,
    } = this.state;

    const taskParams = {
      library_task_template: {
        name,
        resources: selectedResources.map((res) => ({ id: res.id, selected: true })),
        text,
        count,
        daysOffset,
        endRepeat,
        frequency,
        frequencyType,
        interval,
        timeOffset,
        timeZone,
      },
    };

    const appointmentParams = {
      library_appointment_template: {
        address_attributes: { address, extendedAddress, city, region, postalCode, countryCode },
        url,
        practitioner,
        ...taskParams.library_task_template,
      },
    };

    return this.props.templateType === 'Appointment' ? appointmentParams : taskParams;
  };

  handleSubmit = async () => {
    const api = new LibraryActivityTemplateApi(this.state.id, this.props.templateType);
    const data = this.getParams();

    const response = await api.update(data);

    if (response.status === 200) {
      window.location.hash = '#finish';
    } else {
      const errors = await response.json();
      this.setState((prevState) => ({ ...prevState, errors }));
    }
  };

  openResourcesModal = () => {
    this.setState({ isResourcesModalOpen: true });
  };

  closeResourcesModal = () => {
    this.setState({ isResourcesModalOpen: false });
  };

  handleSelectResources = (selected, callback) => {
    const selectedResources = _.filter(this.availableResources, (resource) => selected[resource.id]);

    this.setState({ selectedResources }, callback);
  };

  handleRemoveResource = (idToRemove) => {
    const selectedResources = [...this.state.selectedResources].filter((res) => res.id != idToRemove);
    this.setState((prevState) => ({
      ...prevState,
      selectedResources,
    }));
  };

  renderAppointmentInput() {
    const { errors, practitioner, url } = this.state;

    return (
      <>
        <div className="form__row">
          <FormField
            label={t('practitioner', i18nOpts(this.props.templateType))}
            className="input"
            errors={errors}
            value={practitioner}
            name="practitioner"
            onChange={this.handleChange}
          />
          <FormField
            label={t('virtual_visit', i18nOpts(this.props.templateType))}
            className="input"
            errors={errors}
            value={url}
            name="url"
            onChange={this.handleChange}
          />
        </div>
      </>
    );
  }

  renderAddress() {
    const { errors, address, extendedAddress, city, region, postalCode, countryCode } = this.state;
    const { locale } = this.props;
    const fullAddress = {
      address,
      extendedAddress,
      city,
      region,
      postalCode,
      countryCode
    }

    return (
      <AddressForm
        address={fullAddress}
        onChange={this.handleChange}
        locale={locale}
        formErrors={errors}
      />
    )
  }

  render() {
    const { templateType, resources, allTags } = this.props;
    const {
      errors,
      text,
      name,
      isResourcesModalOpen,
      selectedResources,
      endRepeat,
      frequency,
      frequencyType,
      interval,
      count,
      daysOffset,
      timeOffset,
      timeZone,
    } = this.state;

    return (
      <>
        <section>
          <div className="input string required care_plan_title">
            <label className="string required careplan-wizard__details-title">
              {t('title_label', i18nOpts(templateType))}
              <p className="text-primary-lighter careplan-wizard__details-subtitle">
                {t('title_sublabel', i18nOpts(templateType))}
              </p>
            </label>
          </div>

          <FormField
            errors={errors}
            name="name"
            value={name}
            onChange={this.handleChange}
            placeholder={t('title_placeholder', i18nOpts(templateType))}
          />
        </section>

        <section>
          <div className="input string required care_plan_title">
            <label className="string required careplan-wizard__details-title">
              {t('description_label', i18nOpts(templateType))}
              <p className="text-primary-lighter careplan-wizard__details-subtitle">
                {t('description_sublabel', i18nOpts(templateType))}
              </p>
            </label>
          </div>

          <InputGroup
            component="textarea"
            errors={errors}
            name="text"
            value={text}
            onChange={this.handleChange}
          />
        </section>

        {templateType === 'Appointment' && (
          <>
            {this.renderAppointmentInput()}
            {this.renderAddress()}
          </>
        )}

        <section>
          <div className="input string required care_plan_title">
            <LinkResourceButton action={this.openResourcesModal} />
            <Modal
              isOpen={isResourcesModalOpen}
              closeModal={this.closeResourcesModal}
              title={t('link_resources', i18nOpts(templateType))}
            >
              {isResourcesModalOpen && (
                <LinkResources
                  allTags={allTags}
                  resources={resources}
                  selectedResources={selectedResources}
                  onBack={this.closeResourcesModal}
                  isLinking
                  onSelectResources={this.handleSelectResources}
                />
              )}
            </Modal>
          </div>
        </section>

        <section>
          {selectedResources.length ? (
            <div className="item-pill-section__item-list input--half">
              <Label
                text={t('resources', i18nOpts(templateType))}
                inputName={t('resources', i18nOpts(templateType))}
              />
              {selectedResources.map((resource) => (
                <ResourceListItem
                  key={resource.id}
                  id={resource.id}
                  name={resource.name}
                  kind={resource.kind}
                  deleteResource={this.handleRemoveResource}
                  isLinking
                />
              ))}
            </div>
          ) : null}
        </section>

        <section>
          <div className="input string required care_plan_title">
            <label className="string required careplan-wizard__details-title">
              {t('recurrence_label', i18nOpts(templateType))}
              <p className="text-primary-lighter careplan-wizard__details-subtitle">
                {t('recurrence_sublabel', i18nOpts(templateType))}
              </p>
            </label>
          </div>

          <TemplateRecurrenceInput
            handleChange={this.handleChange}
            errors={errors}
            endRepeat={endRepeat}
            frequency={frequency}
            frequencyType={frequencyType}
            interval={interval}
            count={count}
            daysOffset={daysOffset}
            timeOffset={timeOffset}
            timeZone={timeZone}
            required={false}
            type={templateType}
          />
        </section>

        <Footer
          right={
            <>
              <Button text={t('next', i18nOpts(this.props.templateType))} onClick={this.handleSubmit} />
            </>
          }
        />
      </>
    );
  }
}

const resourceGroupShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  kind: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

LibraryActivityTemplate.propTypes = {
  activityTemplate: PropTypes.shape({
    address: PropTypes.object,
    name: PropTypes.string,
    text: PropTypes.string,
    count: PropTypes.string,
    daysOffset: PropTypes.number,
    endRepeat: PropTypes.string,
    frequency: PropTypes.string,
    frequencyType: PropTypes.string,
    interval: PropTypes.string,
    timeOffset: PropTypes.string,
    selectedResources: PropTypes.array,
    services: PropTypes.array,
    timeZone: PropTypes.string,
  }),
  resources: PropTypes.shape({
    placeResources: PropTypes.array,
    adHocResources: PropTypes.array,
  }),
  id: PropTypes.number,
  templateType: PropTypes.string,
};

LibraryActivityTemplate.defaultProps = {
  activityTemplate: {
    address: {},
    name: '',
    text: '',
    count: '',
    daysOffset: 0,
    endRepeat: '',
    frequency: '',
    frequencyType: '',
    interval: '',
    timeOffset: '',
    selectedResources: [],
    services: [],
    timeZone: 'Etc/UTC',
  },
  resources: {
    placeResources: [],
    adHocResources: [],
  },
  id: null,
  templateType: '',
};

export default LibraryActivityTemplate;
