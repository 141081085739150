import ChannelSearchApi from 'Api/Inbox/ChannelSearch';

import { RECEIVE_CHANNEL_SEARCH, CLEAR_CHANNEL_SEARCH } from './contacts';

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
export const SORT_BY = 'SORT_BY';
export const SEARCH = 'SEARCH';
export const REQUEST_CHANNEL_SEARCH = 'REQUEST_CHANNEL_SEARCH';
export const END_CHANNEL_SEARCH = 'END_CHANNEL_SEARCH';
export const MISSING_SEARCH_RESULT = 'MISSING_SEARCH_RESULT';
export const FOUND_SEARCH_RESULT = 'FOUND_SEARCH_RESULT';
export const SORT_BY_NEWEST = 'SORT_BY_NEWEST';
export const SORT_BY_OLDEST = 'SORT_BY_OLDEST';
export const SORT_BY_A_Z = 'SORT_BY_A_Z';
export const SORT_BY_Z_A = 'SORT_BY_Z_A';

export const toggleSidebar = () => ({ type: TOGGLE_SIDEBAR });
export const closeSidebar = () => ({ type: CLOSE_SIDEBAR });
export const search = (searchQuery) => ({ type: SEARCH, searchQuery });
export const sortBy = (sortBy) => ({ type: SORT_BY, sortBy });

export const updateChannelSortBy = (sortBy) => {
  return (dispatch) => {
    if (sortBy === 'newest') {
      dispatch({ type: SORT_BY_NEWEST });
      return;
    } else if (sortBy === 'oldest') {
      dispatch({ type: SORT_BY_OLDEST });
      return;
    } else if (sortBy === 'nameAsc') {
      dispatch({ type: SORT_BY_A_Z });
      return;
    } else if (sortBy === 'nameDesc') {
      dispatch({ type: SORT_BY_Z_A });
      return;
    }
  };
};

export const fetchChannelSearch = (searchQuery) => {
  // searchChannel is different from search in that it makes an API call and the backend performs the search
  // this is necessary because channel contacts are lazy loaded (other contact types are all loaded at initialization)
  return (dispatch) => {
    if (!searchQuery) {
      dispatch({ type: END_CHANNEL_SEARCH });
      dispatch({ type: CLEAR_CHANNEL_SEARCH });
      return;
    }

    dispatch({ type: REQUEST_CHANNEL_SEARCH });
    dispatch({ type: RECEIVE_CHANNEL_SEARCH, channelContacts: [] }); // clear out previous search results

    return new ChannelSearchApi({ searchQuery: searchQuery })
      .search()
      .then((res) => res.json())
      .then(({ channelContacts }) => {
        if (channelContacts.length === 0) {
          dispatch({ type: MISSING_SEARCH_RESULT });
          dispatch({ type: RECEIVE_CHANNEL_SEARCH, channelContacts: [] }); // clear out previous search results
        } else {
          dispatch({ type: FOUND_SEARCH_RESULT });
          dispatch({ type: RECEIVE_CHANNEL_SEARCH, channelContacts });
        }
      })
      .catch((err) => {
        dispatch({ type: MISSING_SEARCH_RESULT });
        dispatch({ type: RECEIVE_CHANNEL_SEARCH, channelContacts: [] }); // clear out previous search results
      });
  };
};
