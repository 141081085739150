import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import MobileHeader from './MobileHeader'
import Aside from 'Components/Base/Aside'
import AsideHeader from './AsideHeader'
import CarePlanFilters from './CarePlanFilters'

const SideBar = ({
  back,
  carePlans,
  excludedCarePlans,
  name,
  photo,
  isOpen,
  onChange,
  onOpenAside,
  onCloseAside,
  isCurrentUser,
}) => (
  <Fragment>
    <MobileHeader name={name} photo={photo} onOpenAside={onOpenAside} back={back} isCurrentUser={isCurrentUser} />
    <div className="hidden--sm hidden--med">
      <CarePlanFilters carePlans={carePlans} excludedCarePlans={excludedCarePlans} onChange={onChange} />
    </div>

    <div className="hidden--lg">
      <Aside
        isVisible={isOpen}
        position="right"
        header={() => <AsideHeader name={name} photo={photo} />}
        onClose={onCloseAside}
      >
        <CarePlanFilters carePlans={carePlans} excludedCarePlans={excludedCarePlans} onChange={onChange} />
      </Aside>
    </div>
  </Fragment>
)

SideBar.propTypes = {
  back: PropTypes.string,
  carePlans: PropTypes.array,
  excludedCarePlans: PropTypes.array,
  isOpen: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onCloseAside: PropTypes.func.isRequired,
  onOpenAside: PropTypes.func.isRequired,
  photo: PropTypes.string,
  isCurrentUser: PropTypes.bool,
}

SideBar.defaultProps = {
  back: '',
  carePlans: [],
  excludedCarePlans: [],
  isOpen: false,
  isCurrentUser: false,
}

export default SideBar
