import _ from 'lodash'

import {
  ADD_MEMBER,
  DELETE_MEMBER,
  RESET_TEAM_INVITATIONS_MODAL_STATE,
  SET_TEAM_INVITATIONS_MODAL_ERROR,
} from '../actions/teamInvitationsModal'

const initialState = {
  members: [],
  error: false,
}

const teamInvitationsModal = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MEMBER: {
      const members = action.member.id
        ? state.members.map((member) => {
            return member.id === action.member.id ? action.member : member
          })
        : [...state.members, { ...action.member, id: _.uniqueId('member_') }]
      return {
        ...state,
        error: false,
        members,
      }
    }
    case RESET_TEAM_INVITATIONS_MODAL_STATE:
      return initialState
    case SET_TEAM_INVITATIONS_MODAL_ERROR:
      return {
        ...state,
        error: true,
      }
    case DELETE_MEMBER:
      return {
        ...state,
        error: false,
        members: state.members.filter((member) => member.id !== action.id),
      }
    default:
      return state
  }
}

export default teamInvitationsModal
