import React from 'react';
import PropTypes from 'prop-types';

import NestedAppointmentForm from 'Components/Tasks/NestedAppointmentForm';

const TaskAppointmentForm = ({ carePlanUuid, parentId, practitioner, skip, text, timeZone, url }) => (
  <div className="row">
    <div className="row__col">
      <NestedAppointmentForm
        carePlanUuid={carePlanUuid}
        skip={skip}
        practitioner={practitioner}
        text={text}
        parent_id={parentId}
        timeZone={timeZone}
        url={url}
      />
    </div>
  </div>
);

TaskAppointmentForm.propTypes = {
  carePlanUuid: PropTypes.string,
  parentId: PropTypes.number,
  practitioner: PropTypes.string,
  skip: PropTypes.func,
  text: PropTypes.string,
  timeZone: PropTypes.string,
  url: PropTypes.string,
};

TaskAppointmentForm.defaultProps = {
  carePlanUuid: null,
  parentId: null,
  practitioner: null,
  skip: null,
  text: null,
  timeZone: null,
  url: null,
};

export default TaskAppointmentForm;
