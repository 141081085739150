import React, { useState } from 'react';
import Edit from './Edit';
import Show from './Show';

const Recommendation = ({ recommendation, index }) => {
  const [viewState, setViewState] = useState('show');

  return viewState === 'edit' ? (
    <Edit recommendation={recommendation} setViewState={setViewState} index={index} />
  ) : (
    <Show recommendation={recommendation} setViewState={setViewState} index={index} />
  );
};

export default Recommendation;
