import React from 'react'
import PropTypes from 'prop-types'

import Button from 'Components/Base/Button'
import { Footer } from 'Components/Base/Card/'
import { t } from 'i18n'

const SectionFooter = ({ children, isSubmitting, onNext }) => {
  const i18nOpts = { scope: 'care_plan_creators.section_footer' }
  const nextButtonClass = isSubmitting ? 'btn btn--disabled' : 'btn'

  return (
    <Footer
      left={children}
      right={<Button text={t('next', i18nOpts)} className={nextButtonClass} onClick={onNext} />}
    />
  )
}

SectionFooter.propTypes = {
  children: PropTypes.any,
  isSubmitting: PropTypes.bool,
  onNext: PropTypes.func.isRequired,
}

export default SectionFooter
