import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis, Text, CartesianGrid } from 'recharts';
import XAxisTickFormatter from '../XAxisTickFormatter';
import RenderLegend from '../RenderLegend';
import RenderNoDataPoint from '../RenderNoDataPoint';

const i18nOpts = { scope: 'blocks.friend_interaction' };
const FORM_MAX_VALUE = 10;

const Chart = ({ data: { results, maxValue } }) => {
  return (
    <div className="chart friend-interaction">
      <ResponsiveContainer width="100%" height={150}>
        <BarChart height={40} data={results} barCategoryGap="12%" margin={{ left: 25, top: 5 }}>
          <CartesianGrid vertical={false} />
          {results.map(({ data }, index) => (
            <Bar
              key={index}
              data={data}
              dataKey="value"
              name="name"
              fill="fill"
              label={(args) => <RenderNoDataPoint {...args} data={data} y={50} />}
            />
          ))}

          <XAxis
            dataKey="day"
            type="category"
            allowDuplicatedCategory={false}
            tickLine={false}
            interval={0}
            tick={<XAxisTickFormatter />}
          />

          <YAxis
            tickMargin={25}
            axisLine={false}
            tickLine={false}
            domain={[0, FORM_MAX_VALUE]}
            tickCount={maxValue > 0 ? 5 : 2}
            tick={({ x, y, className, payload: { value } }) => {
              const getYAxisTickValue = (val) => {
                if (val === 0) return t('not_at_all', i18nOpts);
                if (val >= FORM_MAX_VALUE) return t('extremely', i18nOpts);
                if (val > 0 && val < FORM_MAX_VALUE) return '';
              };

              return (
                <g transform={`translate(${x - 55}, ${y})`}>
                  <Text className={`${className} text text-subtle`}>{getYAxisTickValue(value)}</Text>
                </g>
              );
            }}
          />
        </BarChart>
      </ResponsiveContainer>
      <RenderLegend legend={t('legend', i18nOpts)} />
    </div>
  );
};

Chart.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Chart;
