import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';
import Icon from 'Components/Base/Icon';

import { setSortBy } from 'Actions/placeUsersPatientsIndexPage';

const i18nOpts = { scope: 'patient.index.sort' };

const SortBy = ({ sortBy, setSortBy }) => {
  return (
    <div
      className="patients-filters__select select-container"
      data-balloon={t('sort_tooltip', i18nOpts)}
      data-balloon-pos="up"
      aria-label={t('sort_patients_aria', i18nOpts)}
    >
      <div className="select-container__icon text-grey-dark">
        <Icon name="sort" />
      </div>

      <select value={sortBy} onChange={(evt) => setSortBy(evt.target.value)}>
        <option value="createdAtDesc">{t('createdAtDesc', i18nOpts)}</option>
        <option value="createdAtAsc">{t('createdAtAsc', i18nOpts)}</option>
        <option value="nameAsc">{t('nameAsc', i18nOpts)}</option>
        <option value="nameDesc">{t('nameDesc', i18nOpts)}</option>
      </select>
    </div>
  );
};

const mapStateToProps = ({ placeUsersPatientsIndexPage }) => {
  const sortBy = placeUsersPatientsIndexPage.sortBy;

  return { sortBy };
};

const mapDispatchToProps = (dispatch) => ({
  setSortBy: (pageState) => dispatch(setSortBy(pageState)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SortBy);
