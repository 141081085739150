import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { t } from 'i18n'

const i18nOpts = { scope: 'team.team' }

const RoleChip = ({ role, className }) => {
  const chipClasses = classNames(
    {
      'chip--brand': role === 'primary_caregiver',
      'chip--blue-grey': role === 'caregiver',
      'chip--light-grey': role === 'support',
    },
    'chip',
    [className]
  )

  return <div className={chipClasses}>{t(`${role}.chip`, i18nOpts)}</div>
}

RoleChip.propTypes = {
  className: PropTypes.string,
  role: PropTypes.string.isRequired,
}

RoleChip.defaultProps = {
  className: '',
}

export default RoleChip
