import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

const ReferralStatus = ({ status }) => (
  <div
    className="row__col row__col--fixed text-accent text-medium"
    dangerouslySetInnerHTML={{ __html: t(status, { scope: 'care_plans.referral_section.referral.status' }) }}
  />
)

ReferralStatus.propTypes = {
  status: PropTypes.string.isRequired,
}

export default ReferralStatus
