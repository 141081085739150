import React from 'react';
import { t } from 'i18n';

import { Tooltip, Icon } from 'Components/Base';

const i18nOpts = { scope: 'team.support_team_member' };


const MostResponsibleProviderChip = () => (
  <Tooltip text={t('most_responsible_provider', i18nOpts)}>
    <Icon name="star" className="support-team-member__mrp-chip__star"/>
  </Tooltip>
)

export default MostResponsibleProviderChip;
