import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'
import _ from 'lodash'

import { Button, ModalFooter } from 'Components/Base'
import Document from 'Components/CarePlanCreators/Documents/Document'
import InputGroup from 'Components/Forms/InputGroup'
import Submit from 'Components/Forms/Submit'

import { MAX_FILE_SIZE, ACCEPTED_MEDICAL_DOCUMENT_TYPES } from 'Utilities/constants'

import { rekeyFormDataToBracketNotation } from 'Utilities/form-data'

const i18nOpts = { scope: 'forms.document_form' }

class DocumentForm extends Component {
  constructor(props) {
    super(props)

    this.fileUploadRef = null
    this.setFileUploadRef = (element) => {
      this.fileUploadRef = element
    }
    this.setFormRef = (element) => {
      this.formRef = element
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleFileChange = this.handleFileChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.resetForm = this.resetForm.bind(this)
    this.uploadFile = this.uploadFile.bind(this)

    const { documentFileName = '', name = '' } = props.activeDocument

    this.state = {
      fileName: null,
      medical_document: {
        name,
        document_file_name: documentFileName,
      },
      savingDisabled: !this.props.editing,
      fileSizeExceeded: false,
      fileFormatError: null,
    }
  }

  componentDidMount() {
    // open the file dialog as soon as the modal opens;
    // do this conditionally, only when no file is present
    // (e.g. in 'new document' mode only)
    if (!this.props.editing) {
      this.uploadFile()
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ fileFormatError: _.get(nextProps.errors, 'medical_document.document') })
  }

  get currentFile() {
    return this.fileUploadRef.files[0]
  }

  handleChange({ target: { name, value } }) {
    this.setState((prevState) => _.set(prevState, name, value))
  }

  handleFileChange() {
    const { name = null, size = null } = this.currentFile || {}
    const fileSizeExceeded = size > MAX_FILE_SIZE
    const savingDisabled = (!name && !this.props.editing) || fileSizeExceeded
    // disable saving when there is no filename and no record being edited
    // (e.g. a new document must have a file), or if file size has been exceeded

    if (name) {
      this.setState({
        medical_document: { ...this.state.medical_document, name, document_file_name: name },
        savingDisabled,
        fileSizeExceeded,
        fileFormatError: null,
      })
    } else {
      this.resetForm()
    }
  }

  handleSubmit(evt) {
    evt.preventDefault()

    let formData = new FormData(this.formRef)

    if (this.currentFile === undefined) {
      // if the form attribute is empty, drop the parameter
      // (e.g. when an update with no new document occurs)
      formData.delete(this.fileUploadRef.name)
    }

    formData = rekeyFormDataToBracketNotation(formData)

    if (this.props.activeDocument.id) {
      this.props.updateDocument(formData, this.props.activeDocument.id)
    } else {
      this.props.createDocument(formData)
    }
  }

  resetForm() {
    this.formRef.reset()
  }

  uploadFile(event) {
    if (this.fileUploadRef) {
      this.fileUploadRef.click()

      if (event) {
        // stop event propagation; otherwise, the form will lose focus
        event.stopPropagation()
        event.preventDefault()
      }
    }
  }

  render() {
    return (
      <form
        ref={this.setFormRef}
        className="form document-upload-form"
        onSubmit={this.handleSubmit}
        noValidate
      >
        <input
          ref={this.setFileUploadRef}
          onChange={this.handleFileChange}
          className="file"
          aria-required="true"
          type="file"
          name="medical_document.document"
          id="medical_document_document"
          style={{ visibility: 'hidden', width: '0px', height: '0px', padding: '0px', position: 'absolute' }}
          accept={ACCEPTED_MEDICAL_DOCUMENT_TYPES.join(', ')}
        />

        <div className="document-upload-form__body">
          <div className="form__row">
            <InputGroup
              className="input input--half"
              name="medical_document.name"
              component="input"
              label={t('document_name_label', i18nOpts)}
              onChange={this.handleChange}
              errors={this.props.errors}
              value={this.state.medical_document.name}
              required
            />
          </div>

          <div className="form__row">
            <Button
              className="btn btn--secondary document-upload-form__upload-btn"
              onClick={this.uploadFile}
              disabled={this.props.isSubmitting}
            >
              {t('change_file', i18nOpts)}
            </Button>
          </div>

          {this.state.medical_document.document_file_name && (
            <div className="form__row">
              <Document document={this.state.medical_document} displayFileName />
            </div>
          )}

          {this.state.fileSizeExceeded && (
            <div className="error text-red">{t('file_size_exceeded', i18nOpts)}</div>
          )}
          {this.state.fileFormatError && <div className="error text-red">{this.state.fileFormatError}</div>}
        </div>

        <ModalFooter
          renderRight={() => (
            <Fragment>
              <Button
                className="btn btn--secondary"
                onClick={this.props.onCancel}
                text={t('cancel', i18nOpts)}
              />
              <Submit
                value={t('save', i18nOpts)}
                disabled={this.props.isSubmitting || this.state.savingDisabled}
              />
            </Fragment>
          )}
        />
      </form>
    )
  }
}

DocumentForm.propTypes = {
  activeDocument: PropTypes.shape({
    documentFileName: PropTypes.string,
    name: PropTypes.string,
  }),
  createDocument: PropTypes.func,
  editing: PropTypes.bool,
  errors: PropTypes.object,
  isSubmitting: PropTypes.bool,
  onCancel: PropTypes.func,
  parentId: PropTypes.any.isRequired,
  updateDocument: PropTypes.func,
}

DocumentForm.defaultProps = {
  activeDocument: {},
}

export default DocumentForm
