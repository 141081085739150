import React from 'react'
import PropTypes from 'prop-types'

import { Activity, ActivityBody, CarePlanBadge, TaskCheckBox, TaskDetails } from '../'
import { ReferralProvider, ReferralStatus } from './'

const Referral = ({
  canComplete,
  carePlan,
  completedAt,
  isCompleting,
  isHealthCareView,
  onClick,
  patientName,
  provider,
  status,
  text,
}) => (
  <Activity>
    <TaskCheckBox
      canComplete={canComplete}
      completedAt={completedAt}
      isCompleting={isCompleting}
      onClick={onClick}
    />
    {carePlan.place && !isHealthCareView && <CarePlanBadge {...carePlan} />}
    <ActivityBody text={text}>
      <TaskDetails title={carePlan.title} patientName={patientName} />
      <div className="task__referral-details hidden--lg hidden--med">
        <ReferralProvider provider={provider} />
        <ReferralStatus status={status} />
      </div>
    </ActivityBody>

    <div className="task__referral-details hidden--sm">
      <ReferralProvider provider={provider} />
      <ReferralStatus status={status} />
    </div>
  </Activity>
)

Referral.propTypes = {
  canComplete: PropTypes.bool,
  carePlan: PropTypes.shape({
    title: PropTypes.string,
    place: PropTypes.shape({
      name: PropTypes.string,
      logo: PropTypes.string,
    }),
  }),
  completedAt: PropTypes.string,
  isCompleting: PropTypes.bool,
  isHealthCareView: PropTypes.bool,
  onClick: PropTypes.func,
  patientName: PropTypes.string,
  provider: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  text: PropTypes.string,
}

Referral.defaultProps = {
  canComplete: false,
  carePlan: {},
  completedAt: '',
  isCompleting: false,
  isHealthCareView: false,
  onClick: null,
  patientName: null,
  text: '',
}

export default Referral
