import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { ItemPill, ItemPillText, ItemPillActions, ItemPillLargeAction } from 'Components/Base/ItemPill'

const i18nOpts = { scope: 'team.modal.review' }

const MemberCard = ({ member, onEdit, onDelete }) => {
  return (
    <ItemPill key={member.id} stretch flat large>
      <ItemPillText text={member.email} subtext={t(member.relationship, { scope: 'relationship' })} />

      <ItemPillActions>
        <ItemPillLargeAction onClick={onEdit} iconName="pencil-edit_alt" label={t('edit', i18nOpts)} />

        <ItemPillLargeAction onClick={onDelete} iconName="minus-circle" label={t('remove', i18nOpts)} />
      </ItemPillActions>
    </ItemPill>
  )
}

MemberCard.propTypes = {
  member: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
}

MemberCard.defaultProps = {}

export default MemberCard
