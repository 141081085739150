import React from 'react';
import ReactModal from 'react-modal';

import Icon from './Icon';

const Dialog = ({ className, title, content, footer, isOpen, onClose }) => {
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      className={`dialog ${className}`}
      overlayClassName={'dialog__overlay'}
    >
      <div className="dialog__container">
        {title && (
          <div className="dialog__header">
            <h2 className="">{title}</h2>
            {onClose && (
              <a className="dialog__close_button" onClick={onClose} tabIndex={0}>
                <Icon name="close_v2" />
              </a>
            )}
          </div>
        )}
        <div className="dialog__text-content">{content}</div>
        {footer && <div className="dialog__footer">{footer}</div>}
      </div>
    </ReactModal>
  );
};

export default Dialog;
