import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';

import Icon from 'Components/Base/Icon';

const i18nOpts = {
  scope: 'care_plan_place_invitations.signups_container.target_signup_modal',
};

export const getResolvedBy = ({ status, resolvedBy, resolvedAt }) => {
  if (resolvedBy) {
    return t(`warning_${status}_by`, { ...i18nOpts, resolvedBy, resolvedAt });
  }
  return t(`warning_${status}`, { ...i18nOpts, resolvedAt });
};

const StatusWarning = ({ status, resolvedAt, resolvedBy }) => {
  if (status === 'pending') {
    return null;
  }

  return (
    <div
      className={`signup_modal__main_content__status_warning signup_modal__main_content__status_warning--${status}`}
    >
      <Icon
        className={`signup_modal__main_content__status_warning__icon signup_modal__main_content__status_warning__icon--${status}`}
        name={status === 'accepted' ? 'check_alt2' : 'warning'}
      />
      <span className="signup_modal__main_content__status_warning__copy">
        {getResolvedBy({ status, resolvedAt, resolvedBy })}
      </span>
    </div>
  );
};

StatusWarning.propTypes = {
  resolvedAt: PropTypes.string,
  resolvedBy: PropTypes.string,
  status: PropTypes.string.isRequired,
};

StatusWarning.defaultProps = {
  resolvedAt: '',
  resolvedBy: '',
};

export default StatusWarning;
