import moment from 'ct-moment';

const getDayDiff = (currentTime, days) => {
  return currentTime.clone().subtract(days, 'days');
};

const timeRangeToDate = (timeRange) => {
  const currentTime = moment();
  let fromTime = null;
  let toTime = null;

  switch (timeRange) {
    case '1day':
      fromTime = getDayDiff(currentTime, 1);
      return fromTime.format('MMMM D');

    case '7days':
      fromTime = getDayDiff(currentTime, 7);
      toTime = getDayDiff(currentTime, 1);
      return `${fromTime.format('MMM D')} to ${toTime.format('MMM D')}`;

    case '14days':
      fromTime = getDayDiff(currentTime, 14);
      toTime = getDayDiff(currentTime, 1);
      return `${fromTime.format('MMM D')} to ${toTime.format('MMM D')}`;

    case '30days':
      fromTime = getDayDiff(currentTime, 30);
      toTime = getDayDiff(currentTime, 1);
      return `${fromTime.format('MMM D')} to ${toTime.format('MMM D')}`;

    case '90days':
      fromTime = getDayDiff(currentTime, 90);
      toTime = getDayDiff(currentTime, 1);
      return `${fromTime.format('MMM D')} to ${toTime.format('MMM D')}`;

    default:
      return '';
  }
};

export default timeRangeToDate;
