import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  setActivityModalActivityProperties,
  setActivityModalAssignmentsDropdownIsOpen,
  setActivityModalAssignmentsDropdownSearchInput,
} from 'Actions/activityModal';

export const handleAssignmentSelection = (
  assignment,
  activityProperties,
  setActivityModalActivityProperties,
  setActivityModalAssignmentsDropdownIsOpen,
  setActivityModalAssignmentsDropdownSearchInput
) => {
  const assignments = activityProperties.assignments ? [...activityProperties.assignments] : [];
  assignments.push(assignment);

  setActivityModalActivityProperties({ ...activityProperties, assignments });
  setActivityModalAssignmentsDropdownIsOpen(false);
  setActivityModalAssignmentsDropdownSearchInput('');
};

const AssignmentItem = ({
  assignment,
  activityProperties,
  setActivityModalActivityProperties,
  setActivityModalAssignmentsDropdownIsOpen,
  setActivityModalAssignmentsDropdownSearchInput,
}) => {
  const onClick = () => {
    handleAssignmentSelection(
      assignment,
      activityProperties,
      setActivityModalActivityProperties,
      setActivityModalAssignmentsDropdownIsOpen,
      setActivityModalAssignmentsDropdownSearchInput
    );
  };

  const handleKeyDown = (evt) => {
    if (evt.key === ' ') {
      evt.preventDefault();
      onClick();
    }
    return;
  };

  return (
    <li
      key={`assignment-${assignment.value}`}
      className="assignments_dropdown__assignments_list__item"
      onClick={onClick}
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      {assignment.label}
    </li>
  );
};

const mapStateToProps = ({ activityModal }) => {
  const { activityProperties } = activityModal;

  return { activityProperties };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalActivityProperties = (dispatch) => {
    return (activityProperties) => {
      dispatch(setActivityModalActivityProperties(activityProperties));
    };
  };

  const dispatchSetActivityModalAssignmentsDropdownIsOpen = (dispatch) => {
    return (isOpen) => {
      dispatch(setActivityModalAssignmentsDropdownIsOpen(isOpen));
    };
  };

  const dispatchSetActivityModalAssignmentsDropdownSearchInput = (dispatch) => {
    return (searchInput) => {
      dispatch(setActivityModalAssignmentsDropdownSearchInput(searchInput));
    };
  };

  return {
    setActivityModalActivityProperties: dispatchSetActivityModalActivityProperties(dispatch),
    setActivityModalAssignmentsDropdownIsOpen: dispatchSetActivityModalAssignmentsDropdownIsOpen(dispatch),
    setActivityModalAssignmentsDropdownSearchInput:
      dispatchSetActivityModalAssignmentsDropdownSearchInput(dispatch),
  };
};

AssignmentItem.propTypes = {
  assignment: PropTypes.object,
  activityProperties: PropTypes.object,
  setActivityModalActivityProperties: PropTypes.func,
  setActivityModalAssignmentsDropdownIsOpen: PropTypes.func,
  setActivityModalAssignmentsDropdownSearchInput: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentItem);
