import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'i18n';

import {
  setActivityModalAssignmentsDropdownIsOpen,
  setActivityModalAssignmentsDropdownState,
  setActivityModalAssignmentsDropdownSearchInput,
  setActivityModalAssignmentsDropdownAssignments,
  setActivityModalAssignmentsDropdownAssignmentsToDisplay,
} from 'Actions/activityModal';
import handleAssignmentsSetup from './handleAssignmentsSetup';

import { Button, Icon } from 'Components/Base';

const i18nOpts = { scope: 'activity_modal.form_fields.who' };

export const OpenButton = ({
  carePlanId,
  activityType,
  assignmentsDropdown,
  setActivityModalAssignmentsDropdownIsOpen,
  setActivityModalAssignmentsDropdownState,
  setActivityModalAssignmentsDropdownSearchInput,
  setActivityModalAssignmentsDropdownAssignments,
  setActivityModalAssignmentsDropdownAssignmentsToDisplay,
}) => {
  const { isOpen } = assignmentsDropdown;

  const setAssignmentOptions = () => {
    const { assignments } = assignmentsDropdown;
    if (assignments) {
      setActivityModalAssignmentsDropdownAssignmentsToDisplay(assignments);
      setActivityModalAssignmentsDropdownState('DEFAULT');
    } else {
      handleAssignmentsSetup(
        setActivityModalAssignmentsDropdownState,
        setActivityModalAssignmentsDropdownAssignments,
        setActivityModalAssignmentsDropdownAssignmentsToDisplay,
        carePlanId,
        activityType
      );
    }
  };

  return (
    <Button
      className="btn--pill activity_modal__assignments__assignment_item"
      onClick={() => {
        if (isOpen) {
          setActivityModalAssignmentsDropdownIsOpen(false);
          setActivityModalAssignmentsDropdownSearchInput('');
        } else {
          setActivityModalAssignmentsDropdownIsOpen(true);
          setActivityModalAssignmentsDropdownSearchInput('');
          setAssignmentOptions();
        }
      }}
    >
      <Icon name="plus_1" className="activity_modal__resources__assignment_item__button_icon" />
      <span>{t('open_button', i18nOpts)}</span>
    </Button>
  );
};

const mapStateToProps = ({ activityModal }) => {
  const { carePlanId, activityType, assignmentsDropdown } = activityModal;

  return { carePlanId, activityType, assignmentsDropdown };
};

const mapDispatchToProps = (dispatch) => {

  const dispatchSetActivityModalAssignmentsDropdownIsOpen = (dispatch) => {
    return (isOpen) => {
      dispatch(setActivityModalAssignmentsDropdownIsOpen(isOpen));
    };
  };

  const dispatchSetActivityModalAssignmentsDropdownState = (dispatch) => {
    return (assignmentsDropdownState) => {
      dispatch(setActivityModalAssignmentsDropdownState(assignmentsDropdownState));
    };
  };

  const dispatchSetActivityModalAssignmentsDropdownSearchInput = (dispatch) => {
    return (searchInput) => {
      dispatch(setActivityModalAssignmentsDropdownSearchInput(searchInput));
    };
  };

  const dispatchSetActivityModalAssignmentsDropdownAssignments = (dispatch) => {
    return (assignments) => {
      dispatch(setActivityModalAssignmentsDropdownAssignments(assignments));
    };
  };

  const dispatchSetActivityModalAssignmentsDropdownAssignmentsToDisplay = (dispatch) => {
    return (assignmentsToDisplay) => {
      dispatch(setActivityModalAssignmentsDropdownAssignmentsToDisplay(assignmentsToDisplay));
    };
  };

  return {
    setActivityModalAssignmentsDropdownIsOpen: dispatchSetActivityModalAssignmentsDropdownIsOpen(dispatch),
    setActivityModalAssignmentsDropdownState: dispatchSetActivityModalAssignmentsDropdownState(dispatch),
    setActivityModalAssignmentsDropdownSearchInput:
      dispatchSetActivityModalAssignmentsDropdownSearchInput(dispatch),
    setActivityModalAssignmentsDropdownAssignments:
      dispatchSetActivityModalAssignmentsDropdownAssignments(dispatch),
    setActivityModalAssignmentsDropdownAssignmentsToDisplay:
      dispatchSetActivityModalAssignmentsDropdownAssignmentsToDisplay(dispatch),
  };
};

OpenButton.propTypes = {
  carePlanId: PropTypes.string,
  activityType: PropTypes.string,
  assignmentsDropdown: PropTypes.object,
  setActivityModalActivityProperties: PropTypes.func,
  setActivityModalAssignmentsDropdownIsOpen: PropTypes.func,
  setActivityModalAssignmentsDropdownState: PropTypes.func,
  setActivityModalAssignmentsDropdownSearchInput: PropTypes.func,
  setActivityModalAssignmentsDropdownAssignments: PropTypes.func,
  setActivityModalAssignmentsDropdownAssignmentsToDisplay: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenButton);
