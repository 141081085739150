import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import Activity from '../Activity'
import BlankSlate from 'Components/Base/BlankSlate'

import { descBy } from 'Utilities/sort'
import { t } from 'i18n'

import emptyTasksIcon from 'tasks_empty-state.svg';

const i18nOpts = (isCompleted) => ({ scope: `tasks.index.tasks.${isCompleted ? 'completed' : 'incomplete'}` })

const TaskList = ({ hasFilter, isCompleted, onClick, tasks, timeZone }) => (
  <Fragment>
    {tasks && tasks.length ? (
      tasks
        .sort(descBy('createdAt'))
        .map((task) => <Activity key={`${task.type}:${task.id}`} timeZone={timeZone} {...task} />)
    ) : (
      <BlankSlate
        flat
        icon={<img src={emptyTasksIcon} className='weekly-tasks__empty_tasks_icon' />}
        body={t(!hasFilter ? 'blankslate' : 'filtered_blankslate', i18nOpts(isCompleted))}
      />
    )}

    <a onClick={onClick}>{t('button', i18nOpts(isCompleted))}</a>
  </Fragment>
)

TaskList.propTypes = {
  hasFilter: PropTypes.bool,
  isCompleted: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  tasks: PropTypes.array,
  timeZone: PropTypes.string.isRequired,
}

TaskList.defaultProps = {
  hasFilter: false,
  isCompleted: false,
  tasks: [],
}

export default TaskList
