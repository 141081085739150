import React, { Fragment } from 'react';
import { DateTime } from 'luxon';
import { t } from 'i18n';
import QuillViewer from 'Components/Base/Quilljs/QuillViewer';

const Show = ({ emergencyInstructionFormatted, updatedAt, updatedBy }) => {
  const i18nOpts = { scope: 'emergency_instruction' };

  const updatedAtFormatted = updatedAt ? DateTime.fromSQL(updatedAt).toFormat('DD | t ZZZZ') : null;

  return (
    <div className="show">
      <h2 className="header">{t('header', i18nOpts)}</h2>
      <QuillViewer contentFormatted={emergencyInstructionFormatted} />
      {updatedAt && updatedBy && (
        <Fragment>
          <hr />
          <section className="card-footer">
            <p>
              {t('updated', i18nOpts)} {updatedAtFormatted}
            </p>
            <p>
              &nbsp;| {t('by', i18nOpts)} {updatedBy}
            </p>
          </section>
        </Fragment>
      )}
    </div>
  );
};

export default Show;
