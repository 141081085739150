import TeamMembershipApi from 'Api/TeamMembership';

export const SET_SUPPORT_TEAM_ERROR = 'SET_SUPPORT_TEAM_ERROR';
export const RECEIVE_SUPPORT_TEAM = 'RECEIVE_SUPPORT_TEAM';
export const RECEIVE_SUPPORT_TEAM_ENTRY = 'RECEIVE_SUPPORT_TEAM_ENTRY';

const fetchSupportTeam = (carePlanId) => {
  return (dispatch) => {
    const supportTeamApi = new TeamMembershipApi(carePlanId);

    return supportTeamApi
      .index()
      .then((res) => res.json())
      .then(({ supportTeam }) => {
        dispatch(receiveSupportTeam('primary_caregiver', { members: supportTeam.supportTeamContacts }));
        dispatch(receiveSupportTeam('caregiver', { members: supportTeam.supportTeamMembers }));
        dispatch(receiveSupportTeam('support', { members: supportTeam.extendedSupportTeam }));
        dispatch(receiveSupportTeam('patient', { members: supportTeam.patient }));
        dispatch(
          receiveSupportTeam('unaffiliated_health_care', { members: supportTeam.unaffiliatedHealthCare })
        );
      });
  };
};

const createSupportTeamEntry = (carePlanId, email, relationship, role, permission) => {
  return (dispatch) => {
    const teamMembershipApi = new TeamMembershipApi(carePlanId);

    return teamMembershipApi.create({ email, relationship, role, permission }).then((res) => {
      if (res.ok) {
        return res.json().then(({ teamMembership }) => dispatch(receiveSupportTeamEntry(teamMembership)));
      } else if (res.status === 422) {
        return res.json().then((errors) => dispatch(setSupportTeamError({ ...errors, role })));
      }
    });
  };
};

const setSupportTeamError = (errors) => ({
  type: SET_SUPPORT_TEAM_ERROR,
  role: errors.role,
  errors,
});

const receiveSupportTeam = (role, supportTeam) => ({
  type: RECEIVE_SUPPORT_TEAM,
  role,
  members: supportTeam.members,
});

const receiveSupportTeamEntry = (newSupportMember) => ({
  type: RECEIVE_SUPPORT_TEAM_ENTRY,
  role: newSupportMember.role,
  newSupportMember,
});

export {
  fetchSupportTeam,
  createSupportTeamEntry,
  setSupportTeamError,
  receiveSupportTeam,
  receiveSupportTeamEntry,
};
