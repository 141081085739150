import updateActionPlanStartAtApi from 'Apiv2/updateActionPlanStartAtApi';

export const setupStartAtUpdaterWrapper = (
  updateActionPlanStartAtApiProvider = updateActionPlanStartAtApi
) => {
  return (
    date,
    resourceId,
    setStartAt,
    setDateModalOpen,
    setDateModalStatus,
    setDateApiError,
    dateModalState
  ) => {
    return async () => {
      if (dateModalState === 'ready') {
        setDateModalOpen(false);
        return;
      }
      setDateApiError(null);
      setDateModalStatus('loading');
      try {
        const { status } = await updateActionPlanStartAtApiProvider(date, resourceId);

        if (status === 204) {
          setTimeout(() => {
            setDateModalStatus('ready');
            setDateModalOpen(false);
            setStartAt(date);
            window.location.reload();
          }, 750);
        } else {
          throw 'Something went wrong.';
        }
      } catch (error) {
        setDateModalStatus('apiError');

        if (typeof error === 'string') {
          setDateApiError(error);
        } else if (
          typeof error === 'object' &&
          error !== null && // null is an object in JS
          typeof error.error === 'string'
        ) {
          setDateApiError(error.error);
        }
      }
    };
  };
};

export default setupStartAtUpdaterWrapper(updateActionPlanStartAtApi);
