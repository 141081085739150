import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'
import { Card } from 'Components/Base/Card'
import { connect } from 'react-redux'

import { getUnaffiliatedMembers } from 'Selectors/supportTeam'
import UnaffiliatedContact from './UnaffiliatedContact'

const i18nOpts = { scope: 'team.unaffiliated_contact_team' }

const UnaffiliatedContactsContainer = ({ members }) => (
  <Card title={t('title', i18nOpts)} collapsable>
    {members.length ? (
      <ul className="item-pill-section__item-list">
        {members.map((member) => (
          <UnaffiliatedContact key={member.id} member={member} />
        ))}
      </ul>
    ) : (
      <p className="item-pill-section--item-list-empty">{t('no_members', i18nOpts)}</p>
    )}
  </Card>
)

UnaffiliatedContactsContainer.propTypes = {
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      photo: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
}

const mapStateToProps = (state) => {
  return {
    members: getUnaffiliatedMembers(state),
  }
}

export default connect(mapStateToProps, null)(UnaffiliatedContactsContainer)
