import React, { Component } from 'react'
import PropTypes from 'prop-types'

function modifyInvitationWrapper(WrappedComponent) {
  class ModifyInvitationWrapper extends Component {
    constructor(props) {
      super(props)

      this.handleOpen = this.handleOpen.bind(this)
      this.handleClose = this.handleClose.bind(this)
      this.handleRemoveInvite = this.handleRemoveInvite.bind(this)
      this.handleBack = this.handleBack.bind(this)
      this.handleEdit = this.handleEdit.bind(this)

      this.state = { modalIsOpen: false, formStep: '' }
    }

    handleOpen() {
      const {
        member: { id },
      } = this.props
      const formStep = id ? 'actionSelect' : 'new'

      this.setState({ modalIsOpen: true, formStep })
    }

    handleClose() {
      this.setState({ modalIsOpen: false, formStep: 'actionSelect' })
    }

    handleEdit() {
      this.setState({ formStep: 'edit' })
    }

    handleRemoveInvite() {
      this.setState({ formStep: 'remove' })
    }

    handleBack() {
      this.setState({ formStep: 'actionSelect' })
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          formStep={this.state.formStep}
          isOpen={this.state.modalIsOpen}
          member={this.props.member}
          onBack={this.handleBack}
          onClose={this.handleClose}
          onEdit={this.handleEdit}
          onOpen={this.handleOpen}
          onRemoveInvite={this.handleRemoveInvite}
        />
      )
    }
  }

  ModifyInvitationWrapper.propTypes = { member: PropTypes.object.isRequired }

  return ModifyInvitationWrapper
}

export default modifyInvitationWrapper
