import Api from './Api'

class RepeatedAppointmentOccurrence extends Api {
  constructor(carePlanId, repeatedAppointmentId, occurrenceTimestamp) {
    super()

    this.carePlanId = carePlanId
    this.repeatedAppointmentId = repeatedAppointmentId
    this.occurrenceTimestamp = occurrenceTimestamp

    this.update_assignment = this.update_assignment.bind(this)
    this.create_assignment = this.create_assignment.bind(this)
  }

  destroy(body) {
    return this.request(`${this.occurrenceUrl}`, 'DELETE', body)
  }

  update(body) {
    return this.request(`${this.occurrenceUrl}`, 'PATCH', body)
  }

  update_assignment({ teamMembershipId, status }) {
    return this.request(`${this.occurrenceUrl}/update_assignment`, 'PATCH', {
      assignment: {
        team_membership_id: teamMembershipId,
        status,
      },
    })
  }

  create_assignment({ teamMembershipId }) {
    return this.request(`${this.occurrenceUrl}/create_assignment`, 'PATCH', {
      assignment: {
        team_membership_id: teamMembershipId,
        status: 'accepted',
      },
    })
  }

  get occurrenceUrl() {
    const encodedOccurrenceTimestamp = encodeURIComponent(this.occurrenceTimestamp).replace(/\./g, '%20')
    return `/care_plans/${this.carePlanId}/repeated_appointments/${this.repeatedAppointmentId}/occurrences/${encodedOccurrenceTimestamp}`
  }
}

export default RepeatedAppointmentOccurrence
