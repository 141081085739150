import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import Loader from 'Components/Base/Loader';
import MultiSelectStyle from 'styles/MultiSelect';

import Label from './Label';

const Select = React.lazy(() => import('react-select'));

const i18nOpts = { scope: 'care_plan_creators.resources.form' };

const TagInput = ({ className, tags, suggestions, handleTagInputChange }) => {
  return (
    <div className={className}>
      <Label text={t('tag', i18nOpts)} inputName="tag" />
      <Suspense fallback={<Loader />}>
        <Select
          name="tag"
          value={tags}
          styles={MultiSelectStyle}
          className="multi-select"
          placeholder={t('placeholder.tag', i18nOpts)}
          isMulti
          options={suggestions}
          isClearable={true}
          isSearchable={true}
          onChange={handleTagInputChange}
          maxMenuHeight={160}
        />
      </Suspense>
    </div>
  );
};

TagInput.propTypes = {
  className: PropTypes.string,
  suggestions: PropTypes.array,
  tags: PropTypes.array,
};

TagInput.defaultProps = {
  className: '',
  suggestions: [],
  tags: [],
};

export default TagInput;
