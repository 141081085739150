import React from 'react'
import PropTypes from 'prop-types'
import linkifyHtml from 'linkifyjs/html'

const Announcement = ({ text }) => <div dangerouslySetInnerHTML={{ __html: linkifyHtml(text) }} />

Announcement.propTypes = {
  text: PropTypes.string.isRequired,
}

Announcement.defaultProps = {}

export default Announcement
