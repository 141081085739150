import getAllPatientsAndArchivedApi from './getAllPatientsAndArchivedApi';
import getMyPatientsListApi from './getMyPatientsListApi';

const handlePatientsSetupWrapper =
  (
    getAllPatientsAndArchivedApiProvider = getAllPatientsAndArchivedApi, 
    getMyPatientsListApiProvider = getMyPatientsListApi,
  ) =>
  async (setPageState, setPatients, setMyPatientIds, setArchivedPatientIds, setIsShowingMyPatients) => {
    try {
      const { responseType: allPatientsResType, patients, archivedPatientIds } = await getAllPatientsAndArchivedApiProvider();
      const { responseType: myPatientListResType, myPatientIds, isShowingMyPatients } = await getMyPatientsListApiProvider();

      if (allPatientsResType === 'DATA' && myPatientListResType === 'DATA') {
        setPatients(patients);
        setMyPatientIds(myPatientIds);
        setArchivedPatientIds(archivedPatientIds);
        setIsShowingMyPatients(isShowingMyPatients);
        setPageState('success');
      } else {
        throw 'Missing data';
      }
    } catch (err) {
      setPageState('error');
      console.error(err);
    }
  };

export default handlePatientsSetupWrapper(getAllPatientsAndArchivedApi);
