import React, { Component } from 'react';
import ProviderWrapper from 'Components/Base/ProviderWrapper';
import PropTypes from 'prop-types';
import _ from 'lodash';
import CarePlanDispatcherApi from 'Api/Dispatchers/CarePlanDispatcher';
import DetailsForm from './DetailsForm';
import DiagnosesList from './DiagnosesList';
import moment from 'ct-moment';

class DetailsSection extends Component {
  constructor(props) {
    super(props);

    this.addDiagnosis = this.addDiagnosis.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleErrors = this.handleErrors.bind(this);
    this.navigateToNextSection = this.navigateToNextSection.bind(this);
    this.handleHeaderHeightOffset = this.handleHeaderHeightOffset.bind(this);

    this.state = {
      carePlan: {
        title: props.title || '',
        diagnoses: props.diagnoses,
        startAt: moment(props.startAt).format('MM/DD/YYYY'),
      },
      isSubmitting: false,
      errors: {},
    };
  }

  componentDidMount() {
    window.addEventListener('hashchange', () => {
      this.handleHeaderHeightOffset();
    });
  }

  handleHeaderHeightOffset() {
    setTimeout(() => {
      window.scrollBy(0, -document.querySelector('.header-nav').offsetHeight);
    }, 1);
  }

  handleChange({ target: { name, value } }) {
    this.setState((prevState) => _.set(prevState, name, value));
  }

  handleErrors(errors) {
    this.setState({ errors });
  }

  handleSubmit(evt) {
    evt.preventDefault();
    this.setState({ isSubmitting: true });

    return new CarePlanDispatcherApi(this.props.resourceId, this.props.isTemplate)
      .updateDetails(this.state)
      .then((res) => {
        if (res.ok) {
          return res.json().then((response) => {
            this.setState((prevState) => ({
              isSubmitting: false,
              errors: {},
              carePlan: { ...prevState.carePlan, diagnoses: response.diagnoses },
            }));

            this.navigateToNextSection();
          });
        } else if (res.status === 422) {
          this.setState({ isSubmitting: false });
          return res.json().then(this.handleErrors);
        }
      });
  }

  navigateToNextSection() {
    window.location.hash = '#activities';
  }

  addDiagnosis(evt) {
    evt.preventDefault();

    this.setState((prevState) => ({
      carePlan: {
        ...prevState.carePlan,
        diagnoses: [...prevState.carePlan.diagnoses, { conditionCode: '' }],
      },
    }));
  }

  render() {
    const { carePlan, isSubmitting, errors } = this.state;
    const diagnosesView = this.props.isTemplate ? null : (
      <DiagnosesList
        onChange={this.handleChange}
        diagnoses={carePlan.diagnoses}
        addDiagnosis={this.addDiagnosis}
      />
    );
    const currentDate = moment(this.props.currentDate).format('MM/DD/YYYY');

    return (
      <ProviderWrapper>
        <DetailsForm
          currentDate={currentDate}
          isTemplate={this.props.isTemplate}
          resourceId={this.props.resourceId}
          onSubmit={this.handleSubmit}
          title={carePlan.title}
          startAt={carePlan.startAt}
          errors={errors}
          diagnosesView={diagnosesView}
          isSubmitting={isSubmitting}
          onChange={this.handleChange}
        />
      </ProviderWrapper>
    );
  }
}

DetailsSection.propTypes = {
  currentDate: PropTypes.string.isRequired,
  diagnoses: PropTypes.array,
  resourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string,
  startAt: PropTypes.string.isRequired,
};

DetailsSection.defaultProps = {
  title: '',
  diagnoses: [],
};

export default DetailsSection;
