import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import Icon from '../../Base/Icon'
import Button from '../../Base/Button'

import Avatar from 'PulseBackports/Avatar'

const i18nOpts = { scope: 'activities.assignments.my_assignment' }

const MyAssignment = ({ accept, decline, assignment }) => {
  const { status, statusText, teamMembership } = assignment
  return (
    <li className="assignment">
      <Avatar
        src={teamMembership.owner.photo}
        fullName={teamMembership.owner.name}
        className="assignment__avatar avatar avatar--thumb"
      />

      <ul className="assignment__status">
        <li>
          <h3 className="assignment__name">
            <a href={teamMembership.path}>
              {teamMembership.owner.name} ({t('me', i18nOpts)})
            </a>
            {status === 'accepted' && <Icon name="check_alt2" className="icon--green" />}
            {status === 'declined' && <Icon name="blocked" className="icon--red" />}
          </h3>
        </li>
        <li>
          <b>{statusText}</b>
        </li>
      </ul>

      {status === 'pending' && (
        <div className="assignment-actions">
          <div className="assignment-actions__label">{t('will_you_accept', i18nOpts)}</div>

          <Button onClick={accept} className="btn assignment-actions__action">
            {t('accept', i18nOpts)}
          </Button>
          <Button className="btn--flat btn--red assignment-actions__action" onClick={decline}>
            {t('decline', i18nOpts)}
          </Button>
        </div>
      )}

      {status === 'accepted' && (
        <div className="assignment-actions assignment-actions--mine">
          <div className="assignment-actions__label">{t('something_changed', i18nOpts)}</div>

          <Button className="btn--flat btn--red assignment-actions__action" onClick={decline}>
            {t('decline', i18nOpts)}
          </Button>
        </div>
      )}

      {status === 'declined' && (
        <div className="assignment-actions assignment-actions--mine">
          <div className="assignment-actions__label">{t('something_changed', i18nOpts)}</div>

          <Button className="btn assignment-actions__action" onClick={accept}>
            {t('claim', i18nOpts)}
          </Button>
        </div>
      )}
    </li>
  )
}

MyAssignment.propTypes = {
  accept: PropTypes.func.isRequired,
  assignment: PropTypes.object.isRequired,
  decline: PropTypes.func.isRequired,
}

MyAssignment.defaultProps = {}

export default MyAssignment
