import {
  SET_ACCESS_REQUESTS,
  SET_SELECTED_REQUEST,
  REMOVE_REQUEST,
  CHANGE_ROLE,
  SET_IS_PLACE_USER,
  TOGGLE_CONSENT,
  SET_MODAL_VIEW,
} from 'Actions/accessRequests'

const initialState = {
  accessRequests: [],
  selectedRequest: null,
  role: '',
  isSubmitting: false,
  isPlaceUser: false,
  consent: true,
  view: 'role_select',
}

const accessRequests = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCESS_REQUESTS:
      return { ...state, accessRequests: action.accessRequests }
    case SET_SELECTED_REQUEST: {
      const selectedRequest = state.accessRequests.find(({ id }) => id === action.id)
      return {
        ...state,
        role: selectedRequest.uhcpRequest ? 'unaffiliated_health_care' : '',
        selectedRequest,
        view: 'role_select',
        consent: true,
      }
    }
    case REMOVE_REQUEST:
      return {
        ...state,
        isSubmitting: false,
        accessRequests: state.accessRequests.filter(({ id }) => id !== action.id),
      }
    case CHANGE_ROLE:
      return {
        ...state,
        role: action.role,
      }
    case SET_IS_PLACE_USER:
      return {
        ...state,
        isPlaceUser: action.isPlaceUser,
      }
    case TOGGLE_CONSENT:
      return {
        ...state,
        consent: !state.consent,
      }
    case SET_MODAL_VIEW:
      return {
        ...state,
        view: action.view,
      }
    default:
      return state
  }
}

export default accessRequests
