import resendInvitationApi from './resendInvitationApi'

export const handleResendInvitationWrapper = (
  resendInvitationApiProvider = resendInvitationApi
) => {
  return async (
    patientId,
    setPageState = () => null,
  ) => {
    try {
      setPageState('LOADING');
      const { responseType } = await resendInvitationApiProvider(patientId);

      if (responseType === 'SUCCESS') {
        setPageState('INVITATION_SENT');
      } else {
        throw new Error;
      }
    } catch (error) {
      setPageState('ERROR');
    }
  }
};

export default handleResendInvitationWrapper(resendInvitationApi);
