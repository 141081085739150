import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n';

import Icon from 'Components/Base/Icon';
import Checkbox from 'Components/Forms/Checkbox';
import ActionButton from 'Components/Base/ActionButton';

const i18nOpts = { scope: 'patient.edit.index' };

class FooterWithCareHub extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { updateCareHub, submitError, isProcessing, onChange } = this.props

    return (
      <div className='carehub_footer'>
        <p className="submit_error">
          {(submitError) && t('submit_error', i18nOpts)}</p>
        <div className='carehub_submit'>
          <div className="carehub_checkbox_section">
            <Checkbox
              checked={updateCareHub}
              name="carehub-checkbox"
              onChange={() => {
                onChange({ target: { name: "updateCareHub", value: !updateCareHub } });
              }}
              text={t('update_carehub', i18nOpts)} />
            <Icon name="sync" className="sync-icon" />
          </div>
          <ActionButton content={t('submit', i18nOpts)} isLoading={isProcessing} />
        </div>
      </div >
    )
  }
}

FooterWithCareHub.propTypes = {
  updateCareHub: PropTypes.bool,
  submitError: PropTypes.bool,
  isProcessing: PropTypes.bool
}

FooterWithCareHub.defaultProps = {
  updateCareHub: true,
  submitError: false,
  isProcessing: false
}

export default FooterWithCareHub
