import React from 'react';
import { t } from 'i18n';
import { Tooltip } from 'Components/Base';

import Icon from 'Components/Base/Icon'

const i18nOpts = { scope: 'team.support_team_member' };

const SubstituteDecisionMakerChip = () => (
  <Tooltip text={t('substitute_decision_maker', i18nOpts)}>
      <Icon name="star" className="support-team-member__sdm-chip__star"/>
  </Tooltip>
)

export default SubstituteDecisionMakerChip;
