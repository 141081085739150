export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'

const openModal = (id) => ({
  type: OPEN_MODAL,
  id,
})

const closeModal = () => ({
  type: CLOSE_MODAL,
})

export { openModal, closeModal }
