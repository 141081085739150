import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { fetchAgenda, fetchIntegratedAgenda, fetchHealthCareAgenda } from 'Actions/agenda'

import {
  getVisibleEvents,
  hasVisibleEvents,
  getPreviousYear,
  getNextYear,
  getPreviousWeek,
  getNextWeek,
  isFetching,
  getFirstDay,
  getLastDay,
} from 'Selectors/agenda'

import { hasFilter } from 'Selectors/filter'

import EventList from './EventList'

class EventListContainer extends Component {
  constructor(props) {
    super(props)

    this.previous = this.previous.bind(this)
    this.current = this.current.bind(this)
    this.next = this.next.bind(this)
  }

  componentDidMount() {
    this.current()
  }

  previous() {
    this.props.loadAgenda(this.props.previousYear, this.props.previousWeek)
  }

  current() {
    this.props.loadAgenda()
  }

  next() {
    this.props.loadAgenda(this.props.nextYear, this.props.nextWeek)
  }

  render() {
    return <EventList previous={this.previous} current={this.current} next={this.next} {...this.props} />
  }
}

EventListContainer.propTypes = {
  carePlanId: PropTypes.string,
  loadAgenda: PropTypes.func.isRequired,
  nextWeek: PropTypes.number,
  nextYear: PropTypes.number,
  overview: PropTypes.bool,
  patientUuid: PropTypes.string,
  previousWeek: PropTypes.number,
  previousYear: PropTypes.number,
}

EventListContainer.defaultProps = {
  carePlanId: '',
  nextWeek: null,
  nextYear: null,
  overview: false,
  patientUuid: '',
  previousWeek: null,
  previousYear: null,
}

const mapStateToProps = (state, ownProps) => ({
  events: getVisibleEvents(state),
  hasEvents: hasVisibleEvents(state),
  hasFilter: hasFilter(state),
  previousYear: getPreviousYear(state),
  nextYear: getNextYear(state),
  previousWeek: getPreviousWeek(state),
  nextWeek: getNextWeek(state),
  isFetching: isFetching(state),
  firstDay: getFirstDay(state),
  lastDay: getLastDay(state),
  ...ownProps,
})

const mapDispatchToProps = (
  dispatch,
  { carePlanId, patientUuid, placeUserId, healthCareView, overview }
) => ({
  loadAgenda(year, week) {
    if (overview) {
      dispatch(fetchIntegratedAgenda(patientUuid, year, week))
    } else if (healthCareView) {
      dispatch(fetchHealthCareAgenda(placeUserId, year, week))
    } else {
      dispatch(fetchAgenda(carePlanId, year, week))
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(EventListContainer)
