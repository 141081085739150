export const setupFirstNameValidator = (formErrors, setFormErrors, t, i18nOpts) => {
  return (patientFirstName) => {
    if (!patientFirstName) {
      setFormErrors({ ...formErrors, patientFirstName: t('blank_first_name', i18nOpts) });
    } else {
      setFormErrors({ ...formErrors, patientFirstName: '' });
    }
  };
};
export const setupLastNameValidator = (formErrors, setFormErrors, t, i18nOpts) => {
  return (patientLastName) => {
    if (!patientLastName) {
      setFormErrors({ ...formErrors, patientLastName: t('blank_last_name', i18nOpts) });
    } else {
      setFormErrors({ ...formErrors, patientLastName: '' });
    }
  };
};
