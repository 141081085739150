import React from 'react';
import { Icon } from 'Components/Base';
import PropTypes from 'prop-types';

// Type prop: currently accepted types are 'warning' or 'success'
// Message prop: can accept either a string or a DOM element eg <span><a>Click Here</a> for details.</span>
// Icon prop: if present it will override the default icon for the type

const Highlight = ({ type, message, icon }) => {
  switch (type) {
    case 'warning':
      return (
        <div className="highlight highlight--warning">
          {icon
            ? <Icon className="icon highlight__icon highlight__icon--warning" name={icon} />
            : <Icon className="icon highlight__icon highlight__icon--warning" name="error-circle_alt" />}
          <p className="highlight__message">{message}</p>
        </div>
      );
    case 'success-alert':
      return (
        <div className="highlight highlight--success">
          {icon
            ? <Icon className="icon highlight__icon highlight__icon--success" name={icon} />
            : <Icon className="icon highlight__icon highlight__icon--success" name="error-circle_alt" />}
          <p className="highlight__message">{message}</p>
        </div>
      );
    default:
      return (
        <div className="highlight highlight--success">
          {icon
            ? <Icon className="icon highlight__icon highlight__icon--success" name={icon} />
            : <Icon className="icon highlight__icon highlight__icon--success" name="check_alt2" />}
          <p className="highlight__message">{message}</p>
        </div>
      );
  }
};

Highlight.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.oneOf(['warning', 'success']),
  icon: PropTypes.string,
};

Highlight.defaultProps = {
  message: '',
  type: 'success',
  icon: undefined,
};

export default Highlight;
