import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { t } from 'i18n'

import MessageInput from './MessageInput'
import { EmojiInput } from 'Components/Forms/EmojiTextInput'
import { Button, Icon, Tooltip } from 'Components/Base'

const i18nOpts = { scope: 'inbox.conversation.form' }

const Form = ({ body, onChange, onSubmit, isSubmitting }) => (
  <form className="form message-form" onSubmit={onSubmit}>
    <EmojiInput
      name="body"
      value={body}
      onChange={onChange}
      placeholder={t('body', i18nOpts)}
      component={MessageInput}
    />

    <Button
      onClick={onSubmit}
      className="message-form__submit"
      data-test="submit"
      disabled={isSubmitting || !_.words(body).length}
      tabIndex="0"
      title={t('submit', i18nOpts)}
      type="submit"
    >
      <Tooltip text={t('submit', i18nOpts)}>
        <Icon className="icon icon--medium message-form__submit-icon" name="send-message" />
      </Tooltip>
    </Button>
  </form>
)

Form.propTypes = {
  body: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default Form
