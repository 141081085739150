import { Resource } from './Api'

class LibraryDocumentCopies extends Resource {
  constructor(carePlanUuid) {
    super()

    this.carePlanUuid = carePlanUuid
  }

  update(selectedLibraryDocuments) {
    return super.update({ selected_library_documents: selectedLibraryDocuments })
  }

  get url() {
    return `/care_plans/${this.carePlanUuid}/library_document_copies`
  }
}

export default LibraryDocumentCopies
