import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import ProviderWrapper from 'Components/Base/ProviderWrapper'
import { ShowHealthTeamContacts, EditHealthTeamContacts, InvitationModal } from './'

class HealthTeamContactsContainer extends Component {
  constructor(props) {
    super(props)

    this.handleEdit = this.handleEdit.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleModalOpen = this.handleModalOpen.bind(this)
    this.handleModalClose = this.handleModalClose.bind(this)
    this.handleInvitationSuccess = this.handleInvitationSuccess.bind(this)
    this.handleHealthTeamSuccess = this.handleHealthTeamSuccess.bind(this)

    this.state = {
      display: 'show',
      invitablePlaces: this.props.invitablePlaces,
      pendingPlaceInvitations: this.props.pendingPlaceInvitations,
      members: this.props.members,
      modalIsOpen: false,
    }
  }

  get canInviteHealthTeam() {
    return !!this.state.invitablePlaces.length && this.props.canInviteHealthTeam
  }

  get unmodifiableMembers() {
    const invitableMemberIds = this.props.invitableMembers.map(({ ownerId }) => ownerId)
    return this.props.members.filter(({ ownerId }) => !invitableMemberIds.includes(ownerId))
  }

  handleEdit() {
    this.setState({ display: 'edit' })
  }

  handleCancel() {
    this.setState({ display: 'show' })
  }

  handleModalOpen() {
    this.setState({ modalIsOpen: true })
  }

  handleModalClose() {
    this.setState({ modalIsOpen: false })
  }

  handleInvitationSuccess(pendingPlaceInvitations) {
    const selectedPlaceIds = pendingPlaceInvitations.map((invitation) => invitation.placeId)

    this.setState((prevState) => ({
      modalIsOpen: false,
      invitablePlaces: prevState.invitablePlaces.filter((place) => !selectedPlaceIds.includes(place.id)),
      pendingPlaceInvitations,
    }))
  }

  handleHealthTeamSuccess(members) {
    this.setState({
      display: 'show',
      members: [...this.unmodifiableMembers, ...members],
    })
  }

  renderViewForContacts() {
    return this.state.display === 'show' ? (
      <ShowHealthTeamContacts
        canInviteHealthTeam={this.canInviteHealthTeam}
        canUpdateHealthContacts={this.props.canUpdateHealthContacts}
        members={this.state.members}
        pendingPlaceInvitations={this.state.pendingPlaceInvitations}
        onEdit={this.handleEdit}
        onModalOpen={this.handleModalOpen}
      />
    ) : (
      <EditHealthTeamContacts
        carePlanUuid={this.props.carePlanId}
        selectedMembers={this.state.members}
        invitableMembers={this.props.invitableMembers}
        onCancel={this.handleCancel}
        onSuccess={this.handleHealthTeamSuccess}
        placeName={this.props.placeName}
      />
    )
  }

  render() {
    return (
      <ProviderWrapper>
        <Fragment>
          {this.renderViewForContacts()}

          <InvitationModal
            onClose={this.handleModalClose}
            modalIsOpen={this.state.modalIsOpen}
            places={this.state.invitablePlaces}
            carePlanUuid={this.props.carePlanId}
            onSuccess={this.handleInvitationSuccess}
          />
        </Fragment>
      </ProviderWrapper>
    )
  }
}

HealthTeamContactsContainer.propTypes = {
  canInviteHealthTeam: PropTypes.bool,
  canUpdateHealthContacts: PropTypes.bool,
  carePlanId: PropTypes.string.isRequired,
  invitableMembers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      isCarePlanCreator: PropTypes.bool.isRequired,
      location: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      ownerId: PropTypes.number.isRequired,
      path: PropTypes.string.isRequired,
      phoneNumber: PropTypes.string,
      imageEntitlementToken: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  invitablePlaces: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      address: PropTypes.string,
      imageEntitlementToken: PropTypes.string,
      phoneNumber: PropTypes.string,
    })
  ),
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      isCarePlanCreator: PropTypes.bool.isRequired,
      location: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      ownerId: PropTypes.number.isRequired,
      path: PropTypes.string.isRequired,
      phoneNumber: PropTypes.string,
      imageEntitlementToken: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
  pendingPlaceInvitations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      address: PropTypes.string,
      name: PropTypes.string,
      imageEntitlementToken: PropTypes.string,
    })
  ),
  placeName: PropTypes.string,
}

HealthTeamContactsContainer.defaultProps = {
  canInviteHealthTeam: false,
  canUpdateHealthContacts: false,
  invitableMembers: [],
  invitablePlaces: [],
  members: [],
  pendingPlaceInvitations: [],
  placeName: '',
}

export default HealthTeamContactsContainer
