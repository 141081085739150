import React from 'react';

import ProviderWrapper from 'Components/Base/ProviderWrapper';
import ActivitiesContainer from './ActivitiesContainer';

const CarePlanActionPlanViewPage = () => {
  return (
    <ProviderWrapper>
      <ActivitiesContainer />
    </ProviderWrapper>
  );
};

export default CarePlanActionPlanViewPage;
