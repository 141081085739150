import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const ApiError = ({ pageState, apiError }) => {
  if (pageState === 'apiError') {
    if (apiError) {
      return <div className="signups-api-error">{apiError}</div>;
    } else {
      return <div className="signups-api-error">Something went wrong.</div>;
    }
  } else {
    return null;
  }
};

const mapStateToProps = ({ carePlanPlaceInvitationsPage }) => {
  const { pageState, apiError } = carePlanPlaceInvitationsPage;

  return {
    pageState,
    apiError,
  };
};

ApiError.propTypes = {
  apiError: PropTypes.string,
  pageState: PropTypes.string,
};

export default connect(mapStateToProps, null)(ApiError);
