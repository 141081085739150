import React from 'react';
import PropTypes from 'prop-types';

// props for tooltip https://kazzkiq.github.io/balloon.css/

const Tooltip = ({ text, position, children, length, className, tabIndex }) => {
  // Do not show label if the text is an empty string
  if (!text) text = undefined;
  return (
    <span
      data-balloon={text}
      data-balloon-length={length}
      data-balloon-pos={position}
      className={className}
      aria-label={text}
      tabIndex={tabIndex}
    >
      {children}
    </span>
  );
};

Tooltip.propTypes = {
  children: PropTypes.object.isRequired,
  className: PropTypes.string,
  length: PropTypes.string,
  position: PropTypes.oneOf([
    'up',
    'down',
    'left',
    'right',
    'up-left',
    'up-right',
    'down-left',
    'down-right',
  ]),
  tabIndex: PropTypes.string,
  text: PropTypes.string.isRequired,
};

Tooltip.defaultProps = {
  className: '',
  length: '',
  position: 'up',
  tabIndex: '-1',
};

export default Tooltip;
