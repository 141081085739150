import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';

import { Icon, Tooltip } from 'Components/Base';
import { CheckInModal, CheckInFormContainer, CheckInSubmittedModal } from './';

import { Activity, ActivityBody, AssignmentsWithDueAt, CarePlanBadge, TaskDetails } from '../';

import CheckInResponseApi from 'Api/CheckInResponse';
import RepeatedCheckInResponseApi from 'Api/RepeatedCheckInResponse';

const i18nOpts = { scope: 'tasks.index' };

class CheckInContainer extends Component {
  constructor(props) {
    super(props);

    this.renderCheckBox = this.renderCheckBox.bind(this);
    this.handleModalClick = this.handleModalClick.bind(this);
    this.handleResponseSubmit = this.handleResponseSubmit.bind(this);
    this.handleOptionSelect = this.handleOptionSelect.bind(this);
    this.checkInResponseParams = this.checkInResponseParams.bind(this);
    this.handleNextQuestion = this.handleNextQuestion.bind(this);
    this.handleQuestionSkip = this.handleQuestionSkip.bind(this);

    const checkInQuestions = props.checkInQuestions.filter(
      ({ id }) => !props.completedQuestionIds.includes(id)
    );

    this.state = {
      errors: {},
      modalIsOpen: false,
      nextQuestionPresent: false,
      checkInId: null,
      checkInQuestions,
      checkInQuestionIndex: 0,
      removeOptionFromList: false,
      responseText: null,
      modalSelectedOptionValue: null,
      userInput: '',
      userInputUnit: '',
    };
  }

  get api() {
    const { carePlanUuid, id, isRepeated } = this.props;

    if (isRepeated) {
      return new RepeatedCheckInResponseApi(carePlanUuid, id);
    } else {
      return new CheckInResponseApi(carePlanUuid, id);
    }
  }

  handleModalClick(evt) {
    if (evt.key && evt.key !== 'Enter') {
      return;
    }

    evt.stopPropagation();
    evt.preventDefault();

    if (this.props.canModify) {
      this.setState((prevState) => ({
        modalIsOpen: !prevState.modalIsOpen,
      }));
    }
  }

  handleOptionSelect(e) {
    const name = e.currentTarget.getAttribute('name');
    e.preventDefault();

    if (name === 'userInput') {
      const userInput = e.currentTarget.value;
      let option, userInputUnit;

      if (userInput.length) {
        option = this.state.checkInQuestions[this.state.checkInQuestionIndex].checkInQuestionOptions[0];
        userInputUnit = option.data.units;
      } else {
        option = {};
        userInputUnit = '';
      }

      this.setState({
        modalSelectedOptionValue: option.id,
        userInput,
        userInputUnit,
      });
    } else {
      this.setState({ modalSelectedOptionValue: e.currentTarget.getAttribute('value') });
    }
  }

  checkInResponseParams() {
    return {
      check_in_response: {
        check_in_question_id: this.state.checkInQuestions[this.state.checkInQuestionIndex].id,
        check_in_question_option_id: this.state.modalSelectedOptionValue,
        user_input: this.state.userInput,
        user_input_unit: this.state.userInputUnit,
      },
      check_in_id: this.state.checkInId,
      timestamp: this.props.dueAt,
      last: this.state.checkInQuestions.length == this.state.checkInQuestionIndex + 1,
    };
  }

  handleResponseSubmit(e) {
    e.preventDefault();

    return this.api.create(this.checkInResponseParams()).then((res) => {
      return res.json().then(({ checkInResponse, errors }) => {
        if (res.ok) {
          const checkInQuestionsLength = this.state.checkInQuestions.length;
          const nextIndex = this.state.checkInQuestionIndex + 1;

          this.setState({
            checkInId: checkInResponse.checkInId,
            responseText: checkInResponse.responseText,
            nextQuestionPresent: checkInQuestionsLength > nextIndex,
            removeOptionFromList: checkInQuestionsLength === nextIndex,
            checkInQuestionIndex: nextIndex,
            modalSelectedOptionValue: null,
            userInput: '',
            userInputUnit: '',
          });
        } else if (res.status === 422) {
          this.setState({ errors });
        }
      });
    });
  }

  handleNextQuestion() {
    this.setState(({ checkInId, nextQuestionPresent }) => ({
      nextQuestionPresent: !nextQuestionPresent,
      checkInId: nextQuestionPresent ? checkInId : null,
    }));
  }

  handleQuestionSkip(e) {
    this.setState(
      () => ({
        modalSelectedOptionValue: null,
        userInput: '',
        userInputUnit: '',
      }),
      () => this.handleResponseSubmit(e)
    );
  }

  renderModal() {
    const { userName, carePlanUuid, link, submittedData, completed, dueAt, pastDueAt } = this.props;

    const {
      checkInQuestions,
      nextQuestionPresent,
      checkInQuestionIndex,
      removeOptionFromList,
      modalIsOpen,
      responseText,
      errors,
      modalSelectedOptionValue,
      userInput,
    } = this.state;

    return (
      <CheckInModal isOpen={modalIsOpen} onClose={this.handleModalClick}>
        {nextQuestionPresent || removeOptionFromList || completed ? (
          <CheckInSubmittedModal
            patientName={userName}
            link={link}
            responseText={responseText || (submittedData ? submittedData.responseText : null)}
            nextQuestionPresent={nextQuestionPresent}
            onNextQuestion={this.handleNextQuestion}
          />
        ) : (
          <CheckInFormContainer
            checkInQuestion={checkInQuestions[checkInQuestionIndex]}
            patientName={userName}
            errors={errors}
            carePlanUuid={carePlanUuid}
            link={link}
            onResponseSubmit={this.handleResponseSubmit}
            onOptionSelect={this.handleOptionSelect}
            modalSelectedOptionValue={modalSelectedOptionValue}
            userInput={userInput}
            checkInComplete={checkInQuestions.length === checkInQuestionIndex + 1}
            onQuestionSkip={this.handleQuestionSkip}
            pastDueAt={pastDueAt}
            dueAt={dueAt}
          />
        )}
      </CheckInModal>
    );
  }

  renderCheckBox() {
    const checkInComplete =
      this.state.checkInQuestions.length === this.state.checkInQuestionIndex || this.props.completed;
    return (
      <div
        className="row__col row__col--fixed"
        data-test={checkInComplete ? 'checkbox-checked' : 'checkbox-unchecked'}
      >
        <Tooltip text={t('not_available', i18nOpts)} tabIndex="0">
          <Icon className="icon--medium" name={checkInComplete ? 'box-checked' : 'box-empty'} />
        </Tooltip>
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        {this.state.removeOptionFromList && this.props.dueAt === null ? null : (
          <Activity onClick={this.handleModalClick}>
            {this.props.canModify ? this.renderCheckBox() : <div className="row__spacer" />}

            {this.props.carePlan.place && !this.props.isHealthCareView && (
              <CarePlanBadge {...this.props.carePlan} />
            )}

            <ActivityBody text={this.props.text}>
              <TaskDetails title={this.props.carePlan.title} patientName={this.props.patientName} />
            </ActivityBody>
            <AssignmentsWithDueAt dueAt={this.props.dueAt} repeated={this.props.isRepeated} />
          </Activity>
        )}
        {this.renderModal()}
      </Fragment>
    );
  }
}

CheckInContainer.propTypes = {
  canModify: PropTypes.bool,
  carePlan: PropTypes.shape({
    title: PropTypes.string,
    place: PropTypes.shape({
      name: PropTypes.string,
      logo: PropTypes.string,
    }),
  }),
  carePlanUuid: PropTypes.string.isRequired,
  checkInQuestions: PropTypes.array.isRequired,
  completed: PropTypes.bool,
  completedQuestionIds: PropTypes.array,
  dueAt: PropTypes.string,
  pastDueAt: PropTypes.bool,
  id: PropTypes.number.isRequired,
  isHealthCareView: PropTypes.bool,
  isRepeated: PropTypes.bool,
  link: PropTypes.string,
  patientName: PropTypes.string,
  submittedData: PropTypes.array,
  text: PropTypes.string,
  userName: PropTypes.string,
};

CheckInContainer.defaultProps = {
  canModify: false,
  carePlan: {},
  checkInQuestions: [],
  completed: false,
  completedQuestionIds: [],
  dueAt: '',
  pastDueAt: false,
  isHealthCareView: false,
  isRepeated: false,
  link: '',
  patientName: null,
  submittedData: [],
  text: '',
  userName: '',
};

export default CheckInContainer;
