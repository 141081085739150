import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from 'Components/Base'
import {
  Activity,
  ActivityBody,
  AppointmentDetails,
  AssignmentsWithDueAt,
  AssignmentActions,
  ServiceLink,
  CarePlanBadge,
  TaskDetails,
} from '../'

import { redirectTo } from 'Utilities/linkHelpers'

const Appointment = ({
  allAssignments,
  assignments,
  carePlan,
  dueAt,
  isHealthCareView,
  link,
  myAssignment,
  practitioner,
  patientName,
  provider,
  services,
  text,
  updateStatus,
  url,
}) => (
  <Activity
    onClick={redirectTo.bind(null, link)}
    footer={
      isHealthCareView &&
      myAssignment.status === 'pending' && <AssignmentActions updateStatus={updateStatus} />
    }
  >
    <div className="row__col row__col--fixed">
      <Icon className="icon--large" name="calendar" />
    </div>

    {carePlan.place && !isHealthCareView && <CarePlanBadge {...carePlan} />}

    <ActivityBody text={text}>
      <AppointmentDetails practitioner={practitioner} url={url} />
      <TaskDetails title={carePlan.title} patientName={patientName} />
      {services.map((service) => (
        <ServiceLink key={service.id} service={service} />
      ))}
    </ActivityBody>

    <AssignmentsWithDueAt assignments={allAssignments || assignments} dueAt={dueAt} provider={provider} />
  </Activity>
)

Appointment.propTypes = {
  allAssignments: PropTypes.array,
  assignments: PropTypes.array,
  carePlan: PropTypes.shape({
    title: PropTypes.string,
    place: PropTypes.shape({
      name: PropTypes.string,
      logo: PropTypes.string,
    }),
  }),
  dueAt: PropTypes.string.isRequired,
  isHealthCareView: PropTypes.bool,
  link: PropTypes.string.isRequired,
  myAssignment: PropTypes.object,
  patientName: PropTypes.string,
  practitioner: PropTypes.string,
  provider: PropTypes.string,
  services: PropTypes.array,
  text: PropTypes.string.isRequired,
  updateStatus: PropTypes.func,
  url: PropTypes.string,
}

Appointment.defaultProps = {
  allAssignments: null,
  assignments: [],
  carePlan: {},
  isHealthCareView: false,
  myAssignment: null,
  patientName: null,
  practitioner: '',
  provider: null,
  services: [],
  updateStatus: null,
  url: '',
}

export default Appointment
