import React from 'react';
import { connect } from 'react-redux';

import { setActivityModalActivityProperties } from 'Actions/activityModal';

import Icon from 'Components/Base/Icon';

const AssignmentsList = ({ activityProperties, setActivityModalActivityProperties }) => {
  const { assignments } = activityProperties;

  if (!assignments || assignments.length <= 0) {
    return null;
  }

  return assignments.map((assignment) => {
    return (
      <button
        key={`assignment-${assignment.value}`}
        className="activity_modal__assignments__assignment_item btn--pill btn--pill--secondary"
        onClick={() => {
          // remove target assignment from the selected list
          setActivityModalActivityProperties({
            ...activityProperties,
            assignments: assignments.filter((assignmentToCheck) => {
              return assignmentToCheck.value !== assignment.value;
            }),
          });
        }}
      >
        <span className="assignment_description">{assignment.label}</span>
        <Icon className="assignment_close_icon" name="close_v2" />
      </button>
    );
  });
};

const mapStateToProps = ({ activityModal }) => {
  const { activityProperties } = activityModal;

  return { activityProperties };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalActivityProperties = (dispatch) => {
    return (activityProperties) => {
      dispatch(setActivityModalActivityProperties(activityProperties));
    };
  };

  return {
    setActivityModalActivityProperties: dispatchSetActivityModalActivityProperties(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentsList);
