import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Modal } from 'Components/Base/Modal'
import { ActionSelectPlace, RemoveInvitationPlace } from 'Components/Team/ModifyInvitation'

const ModifyPlaceInvitationModal = ({
  member,
  isOpen,
  formStep,
  onBack,
  onClose,
  onRemoveInvite,
  onResend,
  onRemove,
}) => (
  <Modal isOpen={isOpen} title={t('team.manage_invitation.header')} closeModal={onClose}>
    {(() => {
      switch (formStep) {
        case 'actionSelect':
          return (
            <ActionSelectPlace
              member={member}
              onClose={onClose}
              onResend={onResend}
              onRemoveInvite={onRemoveInvite}
            />
          )
        case 'remove':
          return <RemoveInvitationPlace member={member} onBack={onBack} onRemove={onRemove} />
      }
    })()}
  </Modal>
)

ModifyPlaceInvitationModal.propTypes = {
  formStep: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  member: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.string,
    imageEntitlementToken: PropTypes.string.isRequired,
  }).isRequired,
  onBack: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onRemoveInvite: PropTypes.func.isRequired,
  onResend: PropTypes.func.isRequired,
}

export default ModifyPlaceInvitationModal
