import React from 'react';
import { t } from 'i18n';
import Checkbox from 'Components/Forms/Checkbox';
const i18nOpts = { scope: 'terms_of_service' };

export const ErrorMessage = ({ error }) =>
  error ? <div className="tos-dialog__error-message">{error.message}</div> : null;

const Content = ({ hasAgreed, setHasAgreed, isProcessing, error }) => {
  return (
    <div className="tos-dialog">
      <h2>{t('welcome_message', i18nOpts)}</h2>
      <p>{t('confirmation_prompt', i18nOpts)}</p>
      <div
        className="tos-dialog__checkbox__wrapper"
        onClick={isProcessing ? null : () => setHasAgreed(!hasAgreed)}
      >
        <span className="tos-dialog__checkbox">
          <Checkbox
            name="tos-dialog__checkbox"
            checked={hasAgreed}
            onChange={() => setHasAgreed(!hasAgreed)}
          />
        </span>
        <div className="tos-dialog__checkbox__text">
          <label
            htmlFor="tos-checkbox"
            className="tos-dialog__checkbox__label"
            dangerouslySetInnerHTML={{
              __html: t('declaration', {
                ...i18nOpts,
                terms_of_service: t('terms_of_service', i18nOpts),
                privacy_policy: t('privacy_policy', i18nOpts),
              }),
            }}
          ></label>
          <ErrorMessage error={error} />
        </div>
      </div>
    </div>
  );
};

export default Content;
