import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';

import InvitationList from './InvitationList';

const i18nOpts = { scope: 'care_plan_place_invitations.index' };

const CarePlanPlaceInvitations = ({ pendingInvitations, pastInvitations }) => (
  <section>
    <InvitationList
      header={t('pending_header', i18nOpts)}
      subheader={t('subheader', i18nOpts)}
      invitations={pendingInvitations}
    />
    <InvitationList collapsed header={t('reviewed_header', i18nOpts)} invitations={pastInvitations} />
  </section>
);

CarePlanPlaceInvitations.propTypes = {
  pastInvitations: PropTypes.array.isRequired,
  pendingInvitations: PropTypes.array.isRequired,
};

CarePlanPlaceInvitations.defaultProps = {};

export default CarePlanPlaceInvitations;
