import Api from '../Api'

class Conversation extends Api {
  constructor(id) {
    super()

    this.id = id
  }

  create({ body, carePlanId, recipients, subject, type }) {
    return super.create({
      conversation: {
        body,
        carePlanId,
        recipients,
        subject,
        type,
      },
    })
  }

  reply({ body }) {
    return this.request(`${this.url}/${this.id}/reply`, 'POST', { body })
  }

  get url() {
    return '/inbox/conversations'
  }
}

export default Conversation
