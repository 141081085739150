import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import InputItemPill from 'Components/Forms/InputItemPill'
import { ItemPillIcon, ItemPillActionIcon } from 'Components/Base/ItemPill'

const i18nOpts = { scope: 'forms.library_document_select' }

const LibraryDocumentSelect = ({
  libraryDocuments,
  documentSelectionStates,
  toggleSelection,
  previewDocument,
}) => (
  <div className="item-pill-section__item-list" data-test="library-list">
    {libraryDocuments.length ? (
      libraryDocuments.map((document) => (
        <InputItemPill
          key={document.id}
          name={document.name}
          selected={documentSelectionStates[document.id]}
          toggleSelection={toggleSelection.bind(null, document.id)}
          renderImage={() => <ItemPillIcon iconName="file" />}
          renderActions={() => (
            <ItemPillActionIcon
              iconName="external-link"
              onClick={previewDocument.bind(null, document)}
              data-test="preview-link"
            />
          )}
        />
      ))
    ) : (
      <div className="item-pill-section--item-list-empty" data-test="library-list-empty">
        <span>{t('no_documents_available', i18nOpts)}</span>
      </div>
    )}
  </div>
)

LibraryDocumentSelect.propTypes = {
  documentSelectionStates: PropTypes.object,
  libraryDocuments: PropTypes.array,
  previewDocument: PropTypes.func.isRequired,
  toggleSelection: PropTypes.func.isRequired,
}

LibraryDocumentSelect.defaultProps = {
  documentSelectionStates: {},
  libraryDocuments: [],
}

export default LibraryDocumentSelect
