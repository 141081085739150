/*
  EmojiTextInput requires the emoji_support.js pack to be loaded on the page before
  the EmojiPicker component can be rendered. Ensure you include the following on
  any page this component is used:
    <%= javascript_pack_tag 'emoji_support', defer: true %>
*/

import React from 'react'
import PropTypes from 'prop-types'

import Icon from 'Components/Base/Icon'

const EmojiTextInput = ({
  name,
  onChange,
  onEmojiClick,
  openPicker,
  placeholder,
  required,
  setEmojiPickerMenuRef,
  setToggleRef,
  showEmojiPicker,
  value,
}) => (
  <div className="input-group">
    <span ref={setToggleRef} className="input-group__addon" onClick={openPicker}>
      <Icon name="emoji" />
    </span>

    <input
      onChange={onChange}
      placeholder={placeholder ? placeholder : ''}
      type="text"
      name={name}
      value={value}
      required={required ? 'required' : false}
      aria-required={required ? true : false}
      autoComplete="off"
      id={name}
    />

    {showEmojiPicker && (
      <span className="emoji-picker-menu" ref={setEmojiPickerMenuRef}>
        <EmojiPicker disableDiversityPicker onEmojiClick={onEmojiClick} />
      </span>
    )}
  </div>
)

EmojiTextInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onEmojiClick: PropTypes.func.isRequired,
  openPicker: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  setEmojiPickerMenuRef: PropTypes.func.isRequired,
  setToggleRef: PropTypes.func.isRequired,
  showEmojiPicker: PropTypes.bool.isRequired,
  value: PropTypes.string,
}

EmojiTextInput.defaultProps = {
  placeholder: '',
  required: false,
  value: '',
}

export default EmojiTextInput
