import Api from 'Api/Api'

class LibraryDocument extends Api {
  constructor(id) {
    super()

    this.id = id
    this.previewLink = this.previewLink.bind(this)
  }

  previewLink() {
    return this.request(`/library_documents/${this.id}/preview_link`)
  }
}

export default LibraryDocument
