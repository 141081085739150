import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';
import Icon from 'Components/Base/Icon';

import { setActivityModalActivityProperties, setActivityModalShowAddressFields } from 'Actions/activityModal';

import AddressForm from './AddressForm';

const i18nOpts = { scope: 'activity_modal.form_fields.where.address' };

import { mode as modeDefinitions } from '../../modalContentStateDefinitions.js';

export const Address = ({
  activityProperties,
  showAddressFields,
  mode,
  setActivityModalActivityProperties,
  setActivityModalShowAddressFields,
}) => {

  const { EDIT, CREATE } = modeDefinitions;
  /*
  Only show 'Remove address' button if address was not saved before
  (in a 'create' mode or 'edit' mode when address is not set)
  */
  const showRemoveButton = (
    mode === CREATE
    || mode === EDIT && !activityProperties.addressFields?.id
  );

  return (
    <>
      {!showAddressFields && (
        <button
          className="btn btn--secondary btn--add-address"
          onClick={() => {
            setActivityModalShowAddressFields(true);
            setActivityModalActivityProperties({
              ...activityProperties,
              addressFields: {
                address: '',
                extendedAddress: '',
                city: '',
                region: '',
                postalCode: '',
                countryCode: '',
              },
            });
          }}
        >
          {mode === EDIT && <Icon name="plus" className="add-address-icon" />}
          <span className="add-address-text">{t('add_address', i18nOpts)}</span>
        </button>
      )}

      {showAddressFields && (
        <>
          <div className="activity_modal__address_controller__input_field__container">
            <div className="activity_modal__address_controller__description__input_field__container">
              {t('description', i18nOpts)}
            </div>
            {showRemoveButton && <button
              className="btn btn--secondary btn--remove-address"
              onClick={() => {
                setActivityModalShowAddressFields(false);
                setActivityModalActivityProperties({
                  ...activityProperties,
                  addressFields: undefined,
                });
              }}
            >
              {t('remove_address', i18nOpts)}
            </button>}
          </div>

          <AddressForm />
        </>
      )}
    </>
  )
};

const mapStateToProps = ({ activityModal }) => {
  const { activityProperties, showAddressFields, formErrors, mode } = activityModal;

  return { activityProperties, showAddressFields, formErrors, mode };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalActivityProperties = (dispatch) => {
    return (activityProperties) => {
      dispatch(setActivityModalActivityProperties(activityProperties));
    };
  };

  const dispatchSetActivityModalShowAddressFields = (dispatch) => {
    return (showAddressFields) => {
      dispatch(setActivityModalShowAddressFields(showAddressFields));
    };
  };

  return {
    setActivityModalActivityProperties: dispatchSetActivityModalActivityProperties(dispatch),
    setActivityModalShowAddressFields: dispatchSetActivityModalShowAddressFields(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Address);
