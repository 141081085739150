import React, { Component } from 'react'
import { t } from 'i18n'
import PropTypes from 'prop-types'
import InputGroup from 'Components/Forms/InputGroup'

const i18nOpts = { scope: 'care_plan_creators.check_in_templates.questions' }

class NumericInputQuestionOption extends Component {
  constructor(props) {
    super(props)

    const { questionOption } = props

    this.handleChange = this.handleChange.bind(this)

    this.state = {
      id: questionOption.id,
      index: questionOption.id || questionOption.data.index,
      order: questionOption.data.order,
      units: questionOption.data.units,
      upperLimit: questionOption.data.upperLimit,
      lowerLimit: questionOption.data.lowerLimit,
    }
  }

  handleChange({ target: { name, value } }) {
    const currentState = this.state
    currentState[name] = value
    this.setState(currentState, () => {
      this.props.updateOptions(this.state)
    })
  }

  render() {
    const { units, upperLimit, lowerLimit } = this.state
    const error = this.props.errors['base']
    const { isActive } = this.props

    return (
      <div>
        <div className="input string required care_plan_title">
          <label className="string required careplan-wizard__details-title" htmlFor="units">
            <abbr title="required">*</abbr> {t('units', i18nOpts)}
          </label>
        </div>
        <InputGroup
          component="input"
          name="units"
          onChange={this.handleChange}
          placeholder={t('placeholders.numeric_input_units', i18nOpts)}
          value={units}
          errors={{ units: error }}
          type="text"
          required
          readonly={isActive}
        />
        <div className="input string required care_plan_title">
          <label className="string required careplan-wizard__details-title" htmlFor="upperLimit">
            {t('upper_limit', i18nOpts)}
            <p className="text-primary-lighter careplan-wizard__details-subtitle">
              {t('placeholders.numeric_input_upper_title', i18nOpts)}
            </p>
          </label>
        </div>
        <InputGroup
          component="input"
          name="upperLimit"
          onChange={this.handleChange}
          placeholder={t('placeholders.numeric_input_upper_limit', i18nOpts)}
          value={upperLimit}
          type="number"
          readonly={isActive}
        />
        <div className="input string required care_plan_title">
          <label className="string required careplan-wizard__details-title" htmlFor="lowerLimit">
            {t('lower_limit', i18nOpts)}
          </label>
        </div>
        <InputGroup
          component="input"
          name="lowerLimit"
          onChange={this.handleChange}
          placeholder={t('placeholders.numeric_input_lower_limit', i18nOpts)}
          value={lowerLimit}
          type="number"
          readonly={isActive}
        />
      </div>
    )
  }
}

NumericInputQuestionOption.propTypes = {
  errors: PropTypes.object,
  isActive: PropTypes.bool,
  questionOption: PropTypes.object,
  updateOptions: PropTypes.func,
}

NumericInputQuestionOption.defaultProps = {
  errors: {},
  isActive: false,
  questionOption: {},
  updateOptions: null,
}

export default NumericInputQuestionOption
