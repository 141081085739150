import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import { setIsShowingArchivedPatients } from 'Actions/placeUsersPatientsIndexPage';

import Checkbox from 'Components/Base/Checkbox';

const i18nOpts = { scope: 'patient.index' };

const ShowArchivedList = ({ isShowingArchivedPatients, setIsShowingArchivedPatients }) => {
  return (
    <Checkbox
      onClick={() => { setIsShowingArchivedPatients(!isShowingArchivedPatients); }}
      checked={isShowingArchivedPatients}
      name="filterPatientsCheckboxArchived"
      id="filterPatientsCheckboxArchived"
      label={t('filter_patients_archived', i18nOpts)}
    />
  );
};

const mapStateToProps = ({ placeUsersPatientsIndexPage }) => {
  const { isShowingArchivedPatients } = placeUsersPatientsIndexPage;

  return { isShowingArchivedPatients };
};

const mapDispatchToProps = (dispatch) => ({
  setIsShowingArchivedPatients: (isShowingArchivedPatients) => dispatch(setIsShowingArchivedPatients(isShowingArchivedPatients)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowArchivedList);
