import { t } from 'i18n';

const formatDataForDisplay = (doseValues, i18nOpts) => {
  const { longInsulinType, rapidInsulinType, ...values } = doseValues;

  // Each value is a number in the form of a string (eg. "5" or "5.1")
  // We want to format all these numbers to one decimal place (so "5" will be "5.0")
  for (let key in values) {
    if (isNaN(values[key]) || values[key] == '') {
      values[key] = '0.0';
    } else {
      // round to nearest 0.1 and display with *.0 if whole number
      values[key] = (Math.round(parseFloat(values[key]) * 10) / 10).toFixed(1);
    }
  }

  const {
    breakfastLong,
    breakfastICR,
    breakfastISF,
    lunchLong,
    lunchICR,
    lunchISF,
    dinnerLong,
    dinnerICR,
    dinnerISF,
    bedtimeLong,
    bedtimeICR,
    bedtimeISF,
  } = values;

  return {
    insulin: {
      label: t('insulin', i18nOpts),
      insulinTypes: [
        {
          property: t('rapid', i18nOpts),
          subproperty: rapidInsulinType,
        },
        {
          property: t('long', i18nOpts),
          subproperty: longInsulinType,
        },
      ],
    },
    dosing: {
      title: t('dose', i18nOpts),
      columns: ['', t('long', i18nOpts), t('ICR', i18nOpts), t('ISF', i18nOpts)],
      rows: [
        [t('breakfast', i18nOpts), breakfastLong, breakfastICR, breakfastISF],
        [t('lunch', i18nOpts), lunchLong, lunchICR, lunchISF],
        [t('dinner', i18nOpts), dinnerLong, dinnerICR, dinnerISF],
        [t('bedtime', i18nOpts), bedtimeLong, bedtimeICR, bedtimeISF],
      ],
    },
  };
};

export default formatDataForDisplay;
