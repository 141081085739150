import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'i18n';

import Header from './Header';
import Messages from './Messages';
import Form from './Form';
import Loader from 'Components/Base/Loader';

import { redirectToConversation } from 'Actions/inbox/conversation';
import { clearDraft, saveDraft } from 'Actions/inbox/messageDraft';
import { toggleSidebar } from 'Actions/inbox/sideNav/general';

import ConversationApi from 'Api/Inbox/Conversation';
import { getMessageDraftById } from 'Selectors/inbox';

class ConversationContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      body: props.body,
      isSubmitting: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  get isExisting() {
    return !!this.props.receipts.length;
  }

  get params() {
    return {
      body: this.state.body,
      recipients: this.props.recipients,
      type: this.props.type,
      subject: this.props.subject,
      carePlanId: this.props.carePlanId,
    };
  }

  get apiAction() {
    return this.isExisting ? 'reply' : 'create';
  }

  componentDidUpdate(prevProps) {
    const id = prevProps.id;
    const body = this.state.body;

    if (this.props.id && id && this.props.id !== id) {
      if (body) {
        this.props.saveDraft({ id, body });
      } else {
        this.props.clearDraft(id);
      }
      this.setState({ body: this.props.body });
    }
  }

  handleSuccess(res) {
    if (!this.isExisting) {
      return this.props.redirectToConversation(res, this.props.isPlaceUser);
    }
  }

  handleSubmit(evt) {
    const id = this.props.id;

    evt.preventDefault();
    this.setState({ isSubmitting: true });

    return new ConversationApi(id)[this.apiAction](this.params).then((res) => {
      if (res.ok) {
        return res
          .json()
          .then((res) => {
            return this.handleSuccess(res);
          })
          .then(() => this.setState({ body: '', isSubmitting: false }))
          .then(() => this.props.clearDraft(id));
      } else {
        window.flash_messages.addMessage(t('error', { scope: 'inbox.conversation.form' }));
        this.setState({ isSubmitting: false });
      }
    });
  }

  handleChange({ target: { value } }) {
    this.setState({ body: value });
  }

  render() {
    const {
      id,
      receipts,
      subheader,
      subject,
      disclaimer,
      details,
      isFetching,
      toggleSidebar,
      isPlaceUser,
      emergencyInstruction,
      conversationType,
      type,
    } = this.props;
    const { body, isSubmitting } = this.state;

    return (
      <main className="inbox">
        <Header
          onClick={toggleSidebar}
          link={details}
          subheader={subheader}
          emergencyInstruction={emergencyInstruction}
          isPlaceUser={isPlaceUser}
          conversationType={conversationType}
        />

        {isFetching ? (
          <div className="messages messages--loading">
            <Loader />
          </div>
        ) : (
          <Messages receipts={receipts} type={type} disclaimer={disclaimer} name={subject} noConversations={!id} />
        )}

        {!isFetching && (
          <Form
            body={body}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            isSubmitting={isSubmitting || !id}
          />
        )}
      </main>
    );
  }
}

ConversationContainer.propTypes = {
  body: PropTypes.string,
  carePlanId: PropTypes.string,
  clearDraft: PropTypes.func.isRequired,
  details: PropTypes.string,
  disclaimer: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isFetching: PropTypes.bool,
  receipts: PropTypes.array,
  recipients: PropTypes.array,
  redirectToConversation: PropTypes.func.isRequired,
  saveDraft: PropTypes.func.isRequired,
  subheader: PropTypes.string,
  subject: PropTypes.string,
  type: PropTypes.string,
};

ConversationContainer.defaultProps = {
  body: '',
  receipts: [],
  recipients: [],
  subheader: '',
  subject: '',
  disclaimer: '',
  details: '',
  type: '',
  isFetching: true,
};

const mapStateToProps = (state) => {
  return {
    body: getMessageDraftById(state),
    ...state.conversation,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveDraft: ({ id, body }) => dispatch(saveDraft({ id, body })),
  clearDraft: (id) => dispatch(clearDraft(id)),
  redirectToConversation: ({ conversation }, isPlaceUser) =>
    dispatch(redirectToConversation(conversation, isPlaceUser)),
  toggleSidebar: () => dispatch(toggleSidebar()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConversationContainer);
