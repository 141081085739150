import SearchIndex from './search_index'

class CarePlanSearchIndex extends SearchIndex {
  constructor(carePlans) {
    super(carePlans)
  }

  get fuseOptions() {
    return {
      threshold: 0.4,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 2,
      keys: ['title', 'patientName'],
    }
  }
}

export default CarePlanSearchIndex
