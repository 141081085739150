import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import { setActivityModalTemplatesDropdownIsOpen } from 'Actions/activityModal';

import { Button } from 'Components/Base';
import DotsLoader from 'Components/Base/DotsLoader';
import Backdrop from 'Components/Base/Backdrop';
import OpenButton from './OpenButton';
import FormField from '../FormField';
import TemplatesMenu from './TemplatesMenu';

const i18nOpts = { scope: 'activity_modal.form_fields.templates' };

export const Templates = ({ templatesDropdown, setActivityModalTemplatesDropdownIsOpen }) => {
  const { isOpen, templatesDropdownState } = templatesDropdown;

  let content;
  switch (templatesDropdownState) {
    case 'LOADING':
      content = (
        <div className="templates_dropdown">
          <DotsLoader />
        </div>
      );
      break;
    case 'ERROR':
      content = (
        <div className="templates_dropdown templates_dropdown--error">{t('api_error', i18nOpts)}</div>
      );
      break;
    case 'DEFAULT':
      content = <TemplatesMenu />;
      break;
    default:
      content = (
        <div className="templates_dropdown templates_dropdown--error">{t('api_error', i18nOpts)}</div>
      );
      break;
  }

  return (
    <div className="activity_modal__templates">
      <OpenButton />

      {isOpen && (
        <div className="activity_modal__templates__menu_container">
          {content}
          <Backdrop
            transparent
            onClick={() => {
              setActivityModalTemplatesDropdownIsOpen(false);
            }}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ activityModal }) => {
  const { templatesDropdown } = activityModal;

  return { templatesDropdown };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalTemplatesDropdownIsOpen = (dispatch) => {
    return (isOpen) => {
      dispatch(setActivityModalTemplatesDropdownIsOpen(isOpen));
    };
  };

  return {
    setActivityModalTemplatesDropdownIsOpen: dispatchSetActivityModalTemplatesDropdownIsOpen(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Templates);
