import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import FormField from '../../FormField'

import { setActivityModalActivityProperties } from 'Actions/activityModal';

const i18nOpts = { scope: 'forms.address_form' };

const ExtendedAddressByCountry = ({ activityProperties, formErrors, setActivityModalActivityProperties }) => {
  let regionLocale, postalCodeLocale;
  const address = activityProperties?.addressFields;

  switch (address?.countryCode) {
    case 'US':
      regionLocale = t('state', i18nOpts);
      postalCodeLocale = t('zip_code', i18nOpts);
      break;
    case 'GB':
      postalCodeLocale = t('postcode', i18nOpts);
      break;
    case 'CA':
    default:
      regionLocale = t('province', i18nOpts);
      postalCodeLocale = t('postal_code', i18nOpts);
      break;
  }

  return (
    <div className="activity_modal__extended_address__input_field__container">
      <FormField
        className="activity_modal__extended_address__input_field"
        label={t('city', i18nOpts)}
        placeholder={t('city', i18nOpts)}
        value={address?.city}
        name='city'
        onChange={(value) => {
          setActivityModalActivityProperties({
            ...activityProperties,
            addressFields: {
              ...activityProperties.addressFields,
              city: value,
            },
          });
        }}
        formErrors={formErrors}
      />
      {address.countryCode != 'GB' &&
        <FormField
          className="activity_modal__extended_address__input_field"
          label={regionLocale}
          placeholder={regionLocale}
          value={address?.region}
          name='region'
          onChange={(value) => {
            setActivityModalActivityProperties({
              ...activityProperties,
              addressFields: {
                ...activityProperties.addressFields,
                region: value,
              },
            });
          }}
          formErrors={formErrors}
        />
      }
      <FormField
        label={postalCodeLocale}
        placeholder={postalCodeLocale}
        className="activity_modal__extended_address__input_field"
        value={address?.postalCode}
        name='postalCode'
        onChange={(value) => {
          setActivityModalActivityProperties({
            ...activityProperties,
            addressFields: {
              ...activityProperties.addressFields,
              postalCode: value,
            },
          });
        }}
        formErrors={formErrors}
      />
    </div>
  )
};

const mapStateToProps = ({ activityModal }) => {
  const { activityProperties, formErrors } = activityModal;

  return { activityProperties, formErrors };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalActivityProperties = (dispatch) => {
    return (activityProperties) => {
      dispatch(setActivityModalActivityProperties(activityProperties));
    };
  };

  return {
    setActivityModalActivityProperties: dispatchSetActivityModalActivityProperties(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtendedAddressByCountry);

