import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'
import { connect } from 'react-redux'

import Contact from './Contact'
import Aside from './Aside'

import { showContacts } from 'Actions/inbox/sideNav/contacts'
import { showCarePlans } from 'Actions/inbox/sideNav/carePlans'
import { openConversation, startNewConversation } from 'Actions/inbox/conversation'

import { getNewContacts, getSideNavOpenState, getNewContactsFetchingState } from 'Selectors/inbox'

import { getCurrentUser } from 'Selectors/currentUser'

const i18nOpts = { scope: 'inbox.side_nav.new_contacts' }

class NewContactsContainer extends Component {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
    this.renderContacts = this.renderContacts.bind(this)
  }

  handleClick(id, conversation, contact) {
    if (conversation && conversation.id) {
      this.props.openConversation(conversation.id)
    } else {
      this.props.startConversation({ id, conversation, contact })
    }
  }

  renderContacts() {
    const { contacts } = this.props

    return (
      <Fragment>
        <p className="inbox-menu__info">{t('info', i18nOpts)}</p>

        <section className="inbox-menu__contacts">
          <div className="inbox-menu__contacts-scroll">
            {contacts.map(({ id, conversation, ...rest }) => (
              <Contact
                key={id}
                onClick={this.handleClick.bind(null, id, conversation, rest)}
                radio
                {...rest}
              />
            ))}
          </div>
        </section>
      </Fragment>
    )
  }

  render() {
    const { isOpen, isFetching, back, isPlaceUser } = this.props
    return (
      <Aside
        isOpen={isOpen}
        isFetching={isFetching}
        onClick={back.bind(null, isPlaceUser)}
        header={t(isPlaceUser ? 'desk_header' : 'header', i18nOpts)}
        render={this.renderContacts}
      />
    )
  }
}

NewContactsContainer.propTypes = {
  back: PropTypes.func.isRequired,
  contacts: PropTypes.array,
  isFetching: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isPlaceUser: PropTypes.bool,
  openConversation: PropTypes.func.isRequired,
  startConversation: PropTypes.func.isRequired,
}

NewContactsContainer.defaultProps = {
  contacts: [],
  isPlaceUser: false,
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  contacts: getNewContacts(state),
  isFetching: getNewContactsFetchingState(state),
  isOpen: getSideNavOpenState(state),
  isPlaceUser: getCurrentUser(state).isPlaceUser,
})

const mapDispatchToProps = (dispatch) => ({
  back: (isPlaceUser) => {
    if (isPlaceUser) {
      dispatch(showCarePlans())
    } else {
      dispatch(showContacts())
    }
  },
  openConversation: (id) => dispatch(openConversation(id)),
  startConversation: (conversation) => dispatch(startNewConversation(conversation)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewContactsContainer)
