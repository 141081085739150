import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import ExistingResourceForm from './ExistingResourceForm'
import NewResourceForm from 'Components/Forms/ResourceForm'

const i18nOpts = { scope: 'care_plan_creators.resources' }

const tabs = [
  { id: 0, text: t('from_library', i18nOpts) },
  { id: 1, text: t('create_new', i18nOpts) },
]

class ResourceFormContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedTab: { id: 0, text: t('from_library', i18nOpts) },
    }
  }

  handleSelectTab = (id) => {
    id = parseInt(id)
    this.setState({ selectedTab: tabs[id] })
  }

  render() {
    const selectedId = this.state.selectedTab.id

    return this.props.isSupportTeam ? (
      <NewResourceForm {...this.props} />
    ) : (
      <>
        <ul className="tabs__content--show_flex">
          {tabs.map(({ id, text }) => (
            <li
              key={id}
              className={selectedId === id ? 'tab-mobile tab-mobile--selected' : 'tab-mobile'}
              onClick={() => {
                this.handleSelectTab(id)
              }}
            >
              {text}
            </li>
          ))}
        </ul>
        {this.state.selectedTab.id === 0 ? (
          <ExistingResourceForm {...this.props} />
        ) : (
          <NewResourceForm {...this.props} />
        )}
      </>
    )
  }
}

ResourceFormContainer.propTypes = {
  isSupportTeam: PropTypes.bool,
}

ResourceFormContainer.defaultProps = {
  isSupportTeam: false,
}

export default ResourceFormContainer
