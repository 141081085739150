import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Button, IconButton, ModalFooter, DeferredImage } from 'Components/Base'

import { ItemPill, ItemPillText } from 'Components/Base/ItemPill'

import banner from 'modify_invitation_cancel.svg'
import location from 'location.png'

const i18nOpts = { scope: 'team.remove_invitation' }

const RemoveInvitationPlace = ({ member: { name, address, imageEntitlementToken }, onBack, onRemove }) => {
  return (
    <Fragment>
      <div className="ReactModal__ContentBanner ReactModal__ContentBanner--simple">
        <div className="ReactModal__ContentBanner-text">{t('remove', i18nOpts)}</div>

        <img src={banner} className="ReactModal__ContentBanner-image" />
      </div>

      <div className="ReactModal__ContentBody-text">
        <div className="text-medium">{t('place_confirmation', i18nOpts)}</div>
      </div>

      <ItemPill flat large stretch multiline isolated>
        <DeferredImage
          alt={name}
          className="new-contact__avatar avatar"
          imageEntitlementToken={imageEntitlementToken}
          fallbackImage={location}
        />
        <ItemPillText text={name} subtext={address} truncate={false} />
      </ItemPill>

      <ModalFooter
        renderRight={() => <Button onClick={onRemove} text={t('remove', i18nOpts)} />}
        renderLeft={() => (
          <IconButton text={t('back', i18nOpts)} isSecondary onClick={onBack} iconName="chevron-left" />
        )}
      />
    </Fragment>
  )
}

RemoveInvitationPlace.propTypes = {
  member: PropTypes.shape({
    id: PropTypes.number,
    address: PropTypes.string,
    name: PropTypes.string,
    imageEntitlementToken: PropTypes.string.isRequired,
  }).isRequired,
  onBack: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
}

export default RemoveInvitationPlace
