import fetchGlucoseDataForTimeRange from './fetchGlucoseDataForTimeRange';
import moment from 'ct-moment';

export const fetchAllGlucoseData = (
  getBloodGlucoseData = fetchGlucoseDataForTimeRange,
  timeProvider = moment
) => {
  return (carePlanUuid, setBloodGlucoseMonitorData, setBloodGlucoseMonitorStatus, setIsChartDataLoaded) => {
    return async () => {
      const TIME_RANGES = ['1day', '7days', '14days', '30days', '90days'];

      try {
        const currentUserTime = timeProvider().format(); // iso8601 format
        const fetches = TIME_RANGES.map((time_range) => {
          // fetch data once for each time range
          return getBloodGlucoseData(carePlanUuid, time_range, currentUserTime, setBloodGlucoseMonitorData);
        });

        let hasError = false;

        // Run all fetches in parallel. If any of the fetches are rejected, switch to error case
        await Promise.allSettled(fetches).catch((_) => {
          hasError = true;
        });

        setBloodGlucoseMonitorStatus(hasError ? 'ERROR' : 'SUCCESS');
        setIsChartDataLoaded(true);
      } catch (error) {
        setBloodGlucoseMonitorStatus('ERROR');
        setIsChartDataLoaded(true);
      }
    };
  };
};

export default fetchAllGlucoseData(fetchGlucoseDataForTimeRange, moment);
