import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { getDisplayName } from 'Utilities/hoc'
import Task from 'Api/Task'

function withTaskActions(WrappedComponent, forceRefresh = false) {
  class WithTaskActions extends Component {
    constructor(props) {
      super(props)

      this.handleClick = this.handleClick.bind(this)
      this.skip = this.skip.bind(this)

      const { messages, isComplete, completedAt, nestedActivities } = props

      this.state = {
        completedAt,
        nestedActivities,
        messages,
        isComplete,
        isCompleting: false,
      }
    }

    componentDidMount() {
      if (this.props.toggle && !this.state.isComplete && !this.state.completedAt) {
        this.props.toggle()
      }
    }

    handleClick(evt) {
      evt.stopPropagation()
      evt.preventDefault()

      this.setState({ isCompleting: true })

      const api = new Task(this.props.carePlanUuid, this.props.id)
      const call = this.state.completedAt ? 'revert' : 'complete'

      return api[call]().then((res) => {
        if (res.ok) {
          if(forceRefresh) {
            window.location.reload()
          } 
          return res.json().then(({ task }) => {
            this.props.toggle && this.props.toggle()
            this.setState({ ...task, isCompleting: false })
          })
        }
      })
    }

    skip(evt) {
      evt.preventDefault()
      this.setState({ isComplete: true })
    }

    render() {
      const wrappedProps = _.assign({}, this.props, this.state)
      return <WrappedComponent handleClick={this.handleClick} skip={this.skip} {...wrappedProps} />
    }
  }

  WithTaskActions.propTypes = {
    carePlanUuid: PropTypes.string.isRequired,
    completedAt: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    isComplete: PropTypes.bool,
    kind: PropTypes.string.isRequired,
    messages: PropTypes.object,
    nestedActivities: PropTypes.array,
    text: PropTypes.string,
    timeZone: PropTypes.string,
    toggle: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  }

  WithTaskActions.defaultProps = {
    completedAt: '',
    isComplete: false,
    messages: {},
    nestedActivities: [],
    text: '',
    timeZone: 'Etc/UTC',
    toggle: false,
  }

  WithTaskActions.displayName = `WithTaskActions(${getDisplayName(WrappedComponent)})`
  return WithTaskActions
}

export default withTaskActions
