import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'

import store from '../../../store'
import { setCurrentUser } from 'Actions/current-user'
import { setHealthCareView } from 'Actions/agenda'

import WeeklyList from './WeeklyList'

class WeeklyListModule extends Component {
  constructor(props) {
    super(props)
    store.dispatch(setHealthCareView())
    store.dispatch(setCurrentUser(this.props.currentUser))
  }

  render() {
    return (
      <Provider store={store}>
        <WeeklyList {...this.props} />
      </Provider>
    )
  }
}

WeeklyListModule.propTypes = {
  currentUser: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    timeZone: PropTypes.string.isRequired,
  }).isRequired,
  placeUserId: PropTypes.number.isRequired,
}

export default WeeklyListModule
