import { request } from 'Apiv2/handleRequest';

export const updateVisibilityApiWrapper = (requestProvider = request) => {
  return async (resourceId, visibleToAll) => {
    try {
      const requestUrl = `/check_in_templates/${resourceId}`;
      const body = { check_in_template: { visible_to_all: visibleToAll }};

      const response = await requestProvider(requestUrl, 'PATCH', body);

      if (response.status === 200) {
        return { responseType: 'DATA' };
      } else {
        throw new Error("Couldn't update visibility.");
      }
    } catch (error) {
      console.error(error);
      return { status: 422, responseType: 'ERROR' };
    }
  };
};

export default updateVisibilityApiWrapper(request);
