import Api from './Api'

class Task extends Api {
  constructor(carePlanId, id) {
    super()

    this.carePlanId = carePlanId
    this.id = id

    this.complete = this.complete.bind(this)
    this.revert = this.revert.bind(this)
  }

  weekly(year, week) {
    const path = year && week ? `weekly/${year}/${week}` : 'weekly'

    return this.request(`${this.url}/${path}`, 'GET')
  }

  completed() {
    return this.request(`${this.url}/completed`)
  }

  complete() {
    return this.request(`${this.url}/${this.id}/complete`, 'PATCH')
  }

  revert() {
    return this.request(`${this.url}/${this.id}/incomplete`, 'PATCH')
  }

  get url() {
    return `/care_plans/${this.carePlanId}/tasks`
  }
}

export default Task
