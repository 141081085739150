import React from 'react'
import PropTypes from 'prop-types'

import ScoreIcon from 'Components/Base/ScoreIcon'

const GenericScaleOption = ({ modalSelectedOptionValue, onClick, optionData }) => {
  const colorCode = optionData.data.colorCode || '#1085c6'

  return (
    <a onClick={onClick} value={optionData.id}>
      {String(optionData.id) === modalSelectedOptionValue ? (
        <div
          className={'ReactModal__CheckIn--QuestionOption-selected-generic'}
          style={{ background: colorCode }}
        >
          <ScoreIcon
            className="checkin-icon"
            score={optionData.data.score}
            background="#fff"
            color={colorCode}
          />
          <div className="ReactModal__CheckIn--QuestionOption-text">
            <span>
              <b>{optionData.data.text}</b>
            </span>
            <span> {optionData.data.description}</span>
          </div>
        </div>
      ) : (
        <div className="ReactModal__CheckIn--QuestionOption">
          <ScoreIcon
            className="checkin-icon"
            score={optionData.data.score}
            background={colorCode}
            color="#fff"
          />
          <div className="ReactModal__CheckIn--QuestionOption-text">
            <span>
              <b>{optionData.data.text}</b>
            </span>
            <span> {optionData.data.description}</span>
          </div>
        </div>
      )}
    </a>
  )
}

GenericScaleOption.propTypes = {
  modalSelectedOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func.isRequired,
  optionData: PropTypes.object.isRequired,
}

GenericScaleOption.defaultProps = {
  modalSelectedOptionValue: '',
}

export default GenericScaleOption
