import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';
import { setPatients, setPageState, setMyPatientIds, setArchivedPatientsAndIds, setIsShowingMyPatients } from 'Actions/placeUsersPatientsIndexPage';

import handlePatientsSetup from './handlePatientsSetup';

import FilterMenu from './FilterMenu';
import PatientsList from './PatientsList';
import Loader from 'Components/Base/Loader';

const i18nOpts = { scope: 'patient.index' };

const PatientsSetupWrapper = ({ pageState, setPageState, setPatients, setMyPatientIds, setArchivedPatientsAndIds, setIsShowingMyPatients }) => {
  useEffect(() => {
    handlePatientsSetup(setPageState, setPatients, setMyPatientIds, setArchivedPatientsAndIds, setIsShowingMyPatients);
  }, []);

  switch (pageState) {
    case 'loading':
      return <Loader />;
    case 'success':
      return (
        <>
          <FilterMenu />
          <PatientsList />
        </>
      );
    case 'error':
    default:
      return <div>{t('error_occurred', i18nOpts)}</div>;
  }
};

const mapStateToProps = ({ placeUsersPatientsIndexPage }) => {
  const pageState = placeUsersPatientsIndexPage.pageState;

  return { pageState };
};

const mapDispatchToProps = (dispatch) => ({
  setPageState: (pageState) => dispatch(setPageState(pageState)),
  setPatients: (patients) => dispatch(setPatients(patients)),
  setMyPatientIds: (myPatientIds) => dispatch(setMyPatientIds(myPatientIds)),
  setArchivedPatientsAndIds: (myPatientIds) => dispatch(setArchivedPatientsAndIds(myPatientIds)),
  setIsShowingMyPatients: (isShowingMyPatients) => dispatch(setIsShowingMyPatients(isShowingMyPatients)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientsSetupWrapper);
