import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';

import { onAssignmentChange } from 'Utilities/utilities';
import RepeatableFormFields from './RepeatableFormFields';
import InputGroup from 'Components/Forms/InputGroup';
import Submit from 'Components/Forms/Submit';
import { Modal } from 'Components/Base/Modal';
import { ModalFooter } from 'Components/Base';

import { RequestForm, RequestButton, RequestedHelp } from 'Components/HelpRequest';
import ResourcesSection from 'Components/Resources/ResourcesSection';
import LinkResources from 'Components/Resources/LinkResources';
import LinkResourceButton from 'Components/Tasks/Index/LinkResourceButton';

import Address from './FormFields/Address';

const TaskForm = ({
  adHocResources,
  allowRepeat,
  allTags,
  assignmentOptions,
  assignments,
  dueAt,
  editResource,
  end,
  ensureDatePickerVisibility,
  errors,
  frequency,
  onCloseResourcesModal,
  onCloseVolunteerModal,
  onOpenResourcesModal,
  onOpenVolunteerModal,
  onDeleteResource,
  onSelectResource,
  onHelpRequestSubmit,
  helpRequest,
  helpRequested,
  hideDateTime,
  interval,
  isSubmitting,
  kind,
  locale,
  modalResourcesIsOpen,
  modalVolunteerIsOpen,
  onCancel,
  onChange,
  onSubmit,
  showDateTime,
  resources,
  selectedResources,
  text,
  textIsReadonly,
  timeZone,
  count,
  until,
  volunteersEnabled,
  canEditResources,
  countryCode,
  region,
  city,
  postalCode,
  address,
  extendedAddress,
}) => {
  const existingResourcesPresent = selectedResources.map((r) => r.id).length > 0;

  const addressObj = {
    countryCode,
    region,
    city,
    postalCode,
    address,
    extendedAddress,
  };

  return (
    <form className="form" onSubmit={onSubmit} noValidate>
      <InputGroup
        name="text"
        component="textarea"
        readonly={textIsReadonly}
        value={text}
        label={t('text', { scope: 'tasks.form' })}
        onChange={onChange}
        errors={errors}
        required
      />

      {kind === 'medical' && (
        <Address address={addressObj} locale={locale} onChange={onChange} formErrors={errors} />
      )}

      <div className="form__row">
        <InputGroup
          component="multi-select"
          errors={errors}
          label={t('assignments', { scope: 'tasks.form' })}
          name="assignments"
          onChange={onAssignmentChange(onChange)}
          options={assignmentOptions}
          value={assignments}
          className={`input ${volunteersEnabled && 'input--volunteers-enabled'}`}
        />

        {volunteersEnabled && <RequestButton helpRequested={helpRequested} action={onOpenVolunteerModal} />}
      </div>

      <RequestedHelp helpRequested={helpRequested} action={onOpenVolunteerModal} />

      {kind === 'generic' && (
        <RepeatableFormFields
          allowRepeat={allowRepeat}
          isSubmitting={isSubmitting}
          count={count}
          dueAt={dueAt}
          end={end}
          ensureDatePickerVisibility={ensureDatePickerVisibility}
          errors={errors}
          frequency={frequency}
          interval={interval}
          onChange={onChange}
          timeZone={timeZone}
          until={until}
          showDateTime={showDateTime}
          hideDateTime={hideDateTime}
        />
      )}

      <Modal
        className="ReactModal__Content"
        isOpen={modalVolunteerIsOpen}
        closeModal={onCloseVolunteerModal}
        title={t('request_volunteer', { scope: 'care_plans.generic_order_section.generic_order' })}
      >
        <RequestForm
          helpRequest={helpRequest}
          onClose={onCloseVolunteerModal}
          onSubmit={onHelpRequestSubmit}
          timeZone={timeZone}
        />
      </Modal>

      <Modal
        isOpen={modalResourcesIsOpen}
        closeModal={onCloseResourcesModal}
        title={t('link_resources', { scope: 'care_plans.generic_order_section.generic_order' })}
      >
        {modalResourcesIsOpen && (
          <LinkResources
            allTags={allTags}
            adHocResources={adHocResources}
            resources={resources}
            selectedResources={selectedResources}
            onBack={onCloseResourcesModal}
            isLinking
            onSelectResources={onSelectResource}
          />
        )}
      </Modal>

      {existingResourcesPresent && (
        <ResourcesSection
          selectedResources={selectedResources}
          editResource={editResource}
          onDeleteResource={onDeleteResource}
        />
      )}
      <ModalFooter
        renderLeft={canEditResources ? () => <LinkResourceButton action={onOpenResourcesModal} /> : null}
        renderRight={() => (
          <Fragment>
            <a className="btn btn--secondary" onClick={onCancel}>
              {t('cancel', { scope: 'tasks.form' })}
            </a>
            <Submit value={t('submit', { scope: 'tasks.form' })} disabled={isSubmitting} />
          </Fragment>
        )}
      />
    </form>
  );
};

const resourceGroupShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  kind: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

TaskForm.propTypes = {
  adHocResources: PropTypes.arrayOf(resourceGroupShape),
  allTags: PropTypes.array,
  allowRepeat: PropTypes.bool,
  assignmentOptions: PropTypes.array,
  assignments: PropTypes.array,
  canEditResources: PropTypes.bool,
  count: PropTypes.string,
  dueAt: PropTypes.string,
  editResource: PropTypes.func,
  end: PropTypes.string,
  ensureDatePickerVisibility: PropTypes.bool,
  errors: PropTypes.object,
  frequency: PropTypes.string,
  helpRequest: PropTypes.object,
  helpRequested: PropTypes.bool,
  hideDateTime: PropTypes.func,
  interval: PropTypes.string,
  isSubmitting: PropTypes.bool,
  kind: PropTypes.string,
  modalResourcesIsOpen: PropTypes.bool,
  modalVolunteerIsOpen: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onCloseResourcesModal: PropTypes.func,
  onCloseVolunteerModal: PropTypes.func,
  onDeleteResource: PropTypes.func,
  onHelpRequestSubmit: PropTypes.func,
  onOpenResourcesModal: PropTypes.func,
  onOpenVolunteerModal: PropTypes.func,
  onSelectResource: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  resources: PropTypes.shape({
    information: PropTypes.arrayOf(resourceGroupShape),
    service: PropTypes.arrayOf(resourceGroupShape),
    practitioner: PropTypes.arrayOf(resourceGroupShape),
    people: PropTypes.arrayOf(resourceGroupShape),
    other: PropTypes.arrayOf(resourceGroupShape),
  }),
  selectedResources: PropTypes.array,
  showDateTime: PropTypes.func,
  text: PropTypes.string,
  textIsReadonly: PropTypes.bool,
  timeZone: PropTypes.string,
  until: PropTypes.string,
  volunteersEnabled: PropTypes.bool,
};

TaskForm.defaultProps = {
  adHocResources: [],
  allTags: [],
  allowRepeat: false,
  assignmentOptions: [],
  assignments: [],
  canEditResources: false,
  count: '',
  dueAt: '',
  editResource: null,
  end: '',
  ensureDatePickerVisibility: false,
  errors: {},
  frequency: '',
  helpRequest: {},
  helpRequested: false,
  hideDateTime: null,
  interval: '',
  isSubmitting: false,
  kind: '',
  modalResourcesIsOpen: false,
  modalVolunteerIsOpen: false,
  onCloseResourcesModal: null,
  onCloseVolunteerModal: null,
  onDeleteResource: null,
  onHelpRequestSubmit: null,
  onOpenResourcesModal: null,
  onOpenVolunteerModal: null,
  onSelectResource: null,
  resources: {
    information: [],
    service: [],
    practitioner: [],
    people: [],
    other: [],
  },
  selectedResources: [],
  showDateTime: null,
  text: '',
  textIsReadonly: true,
  timeZone: '',
  until: '',
  volunteersEnabled: false,
};

export default TaskForm;
