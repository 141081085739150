//External Libraries
import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import moment from 'ct-moment'
import 'moment-timezone'
import { t } from 'i18n'

// Components
import { Modal } from 'Components/Base/Modal'
import { Button, IconButton, ModalFooter } from 'Components/Base'

// Other utilities
import { sizeInMb } from 'Utilities/sizeInBytes'

const i18nOpts = { scope: 'care_plan_creators.documents' }

class DocumentPreviewModal extends PureComponent {
  constructor(props) {
    super(props)

    this.handleDownload = this.handleDownload.bind(this)
    this.formatTimeStamp = this.formatTimeStamp.bind(this)
  }

  handleDownload() {
    window.location = this.props.previewFile.downloadUrl
  }

  formatTimeStamp(timestamp) {
    const dateFormat = 'LLL'
    return moment.tz(timestamp, this.props.timeZone).format(dateFormat)
  }

  render() {
    const { previewIsOpen, previewFile, previewLink, closePreview } = this.props
    const { name, documentFileSize, createdAt, updatedAt } = previewFile
    return (
      <Modal
        title={t('document_preview', { ...i18nOpts, name })}
        isOpen={previewIsOpen}
        closeModal={closePreview}
        className="ReactModal__Content ReactModal__Content__documents"
      >
        <div className="preview-field-column">
          <div className="preview-field-row">
            <p className="preview-field-row__label text-bold">{t('file_name', i18nOpts)}</p>
            <p className="preview-field-row__value" data-test="file-name">
              {name}
            </p>
          </div>

          <div className="preview-field-row">
            <p className="preview-field-row__label text-bold">{t('file_size', i18nOpts)}</p>
            <p className="preview-field-row__value" data-test="file-size">
              {sizeInMb(documentFileSize)}
            </p>
          </div>

          <div className="preview-field-row">
            <p className="preview-field-row__label text-bold">{t('created', i18nOpts)}</p>
            <p className="preview-field-row__value" data-test="file-created-at">
              {this.formatTimeStamp(createdAt)}
            </p>
          </div>

          <div className="preview-field-row">
            <p className="preview-field-row__label text-bold">{t('updated', i18nOpts)}</p>
            <p className="preview-field-row__value" data-test="file-updated-at">
              {this.formatTimeStamp(updatedAt)}
            </p>
          </div>
        </div>

        <hr />

        <iframe className="document-preview" src={previewLink} />

        <ModalFooter
          renderRight={() => (
            <Fragment>
              <Button
                className="btn btn--secondary"
                onClick={closePreview}
                text={t('close', i18nOpts)}
                data-test="close-btn"
              />
              <IconButton
                onClick={this.handleDownload}
                iconName="file-download"
                text={t('download', i18nOpts)}
              />
            </Fragment>
          )}
        />
      </Modal>
    )
  }
}

DocumentPreviewModal.propTypes = {
  closePreview: PropTypes.func.isRequired,
  previewFile: PropTypes.object.isRequired,
  previewIsOpen: PropTypes.bool.isRequired,
  previewLink: PropTypes.string.isRequired,
  timeZone: PropTypes.string,
}

DocumentPreviewModal.defaultProps = {
  timeZone: 'Etc/UTC',
}

export default DocumentPreviewModal
