import React from 'react';
import { t } from 'i18n';
import RiverChart from './RiverChart';
import ScatterPlot from './ScatterPlot';
import ComparisonChart from './ComparisonChart';
import { UpperHighlight, LowerHighlight } from './StatsHighlight';
import Loader from 'Components/Base/Loader';
import transformData from './transformData';
import timeRangeToDate from './timeRangeToDate';

const i18nDeviceOpts = { scope: 'dashboard.device' };

const DataVisualization = ({
  bloodGlucoseMonitorStatus,
  bloodGlucoseMonitorData,
  statisticsData,
  statisticsStatus,
  isChartDataLoaded,
  timeRange,
  monitorType,
  error,
}) => {
  const hasDataForTimeRange = Object.keys(bloodGlucoseMonitorData).includes(timeRange);

  if (isChartDataLoaded && !hasDataForTimeRange) {
    return (
      <div className="stats-highlight__api-error">
        <span>
          {t('unknown_error', i18nDeviceOpts)}
          <a href='mailto:support@getcareteam.com'>{'support@getcareteam.com.'}</a>
        </span>
      </div>
    );
  }

  if (!isChartDataLoaded && !hasDataForTimeRange) {
    return <Loader />;
  }

  if (timeRange === 'compare') {
    return <ComparisonChart data={bloodGlucoseMonitorData['compare']} monitorType={monitorType} error={error} />;
  }

  const chartData = transformData(bloodGlucoseMonitorData[timeRange], monitorType, timeRange);
  const errorMessage = chartData?.length <= 0 && !error ? 'EMPTY_CHART_DATA' : error;

  if (['continuous_glucose_monitor', 'mdi_sensor', 'pump_sensor'].includes(monitorType)) {
    return (
      <>
        <UpperHighlight status={statisticsStatus} data={statisticsData} timeRange={timeRange} />
        <RiverChart data={chartData} timeRange={timeRange} error={errorMessage} />
        <LowerHighlight
          status={statisticsStatus}
          data={statisticsData}
          timeRange={timeRange}
          error={errorMessage}
        />
      </>
    );
  }

  return (
    <div className="stats-highlight__gluco">
      <div className="stats-highlight__gluco__date">{timeRangeToDate(timeRange)}</div>
      <ScatterPlot data={chartData} timeRange={timeRange} error={errorMessage} />
    </div>
  );
};

export default DataVisualization;
