import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import Icon from '../../Base/Icon'

const commentsInWords = (count) => t('comment', { scope: 'activity_feed.comments.comment_header', count })

const CommentsHeader = ({ commentCount, isCollapsed, onClick }) => (
  <div className="comments__header">
    <div className="comments__count">
      <div className="icon icon-container">
        <Icon name="comment_alt" />
      </div>
      {isCollapsed ? (
        commentCount ? (
          <a onClick={onClick}>{commentsInWords(commentCount)}</a>
        ) : (
          <span>{commentsInWords(commentCount)}</span>
        )
      ) : (
        <a onClick={onClick}>{t('hide', { scope: 'activity_feed.comments.comment_header' })}</a>
      )}
    </div>
  </div>
)

CommentsHeader.propTypes = {
  commentCount: PropTypes.number.isRequired,
  isCollapsed: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

CommentsHeader.defaultProps = {
  isCollapsed: true,
}

export default CommentsHeader
