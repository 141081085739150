import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import Icon from '../Base/Icon'

import banner from 'cpc_finish.svg'
import logo from 'logos/logo.png'

const i18nOpts = { scope: 'welcome_banner.uhcp_action_plan_show_banner' }

const UhcpActionPlanShowBanner = ({ close, userName, patientName }) => (
  <div className="banner banner--uhcp">
    <div className="card card--wave-header">
      <header className="card__image-header">
        <div className="card__image-header-content">
          <img src={logo} />
          <h2 className="text-bold">{t('hello', { ...i18nOpts, userName })}</h2>
          <h3 className="text-medium">{t('action_plan', { ...i18nOpts, patientName })}</h3>
        </div>

        <div className="card__image-header-image-center">
          <img src={banner} />
        </div>

        <div onClick={close} className="card__image-header-close">
          <Icon name="close" />
        </div>
      </header>
    </div>
  </div>
)

UhcpActionPlanShowBanner.propTypes = {
  close: PropTypes.func.isRequired,
  patientName: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
}

export default UhcpActionPlanShowBanner
