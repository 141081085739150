import { request } from 'Apiv2/handleRequest';

export const getSessionInfoApiWrapper = (requestProvider = request) => {
  return async (carePlanId) => {
    try {
      const response = await requestProvider(
        `/care_plans/${carePlanId}/care_plan_action_plan_view_page/session_info`,
        'GET'
      );

      const status = response.status;
      const { can_add, time_zone, patient_first_name, locale } = await response.json();

      if (status === 200 && can_add !== undefined) {
        return {
          responseType: 'DATA',
          canAdd: can_add,
          timeZone: time_zone,
          patientFirstName: patient_first_name,
          locale,
        };
      } else {
        throw 'Could not get session information.';
      }
    } catch (error) {
      console.error(error);
      return { responseType: 'API_ERROR' };
    }
  };
};

export default getSessionInfoApiWrapper(request);
