import { Resource } from 'Api/Api'

class ServiceTemplate extends Resource {
  constructor(carePlanTemplateId) {
    super()

    this.carePlanTemplateId = carePlanTemplateId
  }

  update(services) {
    return super.update({ carePlan: { services } })
  }

  get url() {
    return `/care_plan_templates/${this.carePlanTemplateId}/service_templates`
  }
}

export default ServiceTemplate
