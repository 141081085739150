import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import LocaleSelect from '../Forms/LocaleSelect'

import UserApi from '../../api/User'

class LocaleSelector extends PureComponent {
  constructor(props) {
    super(props)

    this.setLocale = this.setLocale.bind(this)

    this.state = { locale: props.locale }
  }

  setLocale(locale) {
    this.setState({ locale })
    new UserApi().updateLocale(locale).then((res) => {
      if (res.ok) {
        window.location.reload()
      }
    })
  }

  render() {
    return (
      <LocaleSelect
        value={this.state.locale}
        name={'locale-select'}
        onChange={this.setLocale}
        availableLocales={this.props.availableLocales}
      />
    )
  }
}

LocaleSelector.propTypes = {
  availableLocales: PropTypes.array,
  locale: PropTypes.string.isRequired,
}

LocaleSelector.defaultProps = {
  availableLocales: [],
}

export default LocaleSelector
