import React from 'react';
import { connect } from 'react-redux';
import Icon from 'Components/Base/Icon';
import Button from 'Components/Base/Button'
import { t } from 'i18n';

import {
  setCarePlanId,
  setSessionInfoState,
  setSessionInfoData,
} from 'actions/carePlanActionPlanViewPage';
import {
  setActivityModalActivityType,
  setActivityModalCarePlanId,
  setActivityModalTimeZone,
  setActivityModalActivityProperties,
  setActivityModalActivityId,
  setActivityModalShowAddressFields,
  setActivityModalCanDeleteActivity,
  setActivityModalCanEditFields,
  setActivityModalAssignmentsDropdownAssignments,
  setActivityModalResourcesDropdownResources,
  setActivityModalMode,
  setActivityModalIsOpen,
} from 'actions/activityModal';


const EditButtonModal = ({
  setCarePlanId,
  setSessionInfoState,
  setSessionInfoData,
  setActivityModalActivityId,
  setActivityModalActivityType,
  setActivityModalCarePlanId,
  setActivityModalActivityProperties,
  setActivityModalShowAddressFields,
  setActivityModalTimeZone,
  setActivityModalCanDeleteActivity,
  setActivityModalCanEditFields,
  setActivityModalAssignmentsDropdownAssignments,
  setActivityModalResourcesDropdownResources,
  setActivityModalMode,
  setActivityModalIsOpen,
  carePlanId,
  activityId,
  activityType,
  activityProperties,
  activityKind,
  assignmentOptions,
  timeZone,
  locale,
  canDeleteActivity,
  canEditFields,
  editBtnStyle,
  editBtnText
}) => {

  const handleEditClick = () => {
    const modalActivityType = (activityType === 'TASK' && activityKind === 'MEDICAL')
      ? 'APPOINTMENT'
      : activityType;
    setActivityModalIsOpen(true);
    setCarePlanId(carePlanId);
    setSessionInfoData({ timeZone, locale });
    setSessionInfoState('MAIN_CONTENT');
    setActivityModalCarePlanId(carePlanId);
    setActivityModalTimeZone(timeZone);
    setActivityModalActivityId(activityId);
    setActivityModalActivityType(modalActivityType);
    setActivityModalAssignmentsDropdownAssignments(assignmentOptions);
    setActivityModalShowAddressFields(!!activityProperties.addressFields);
    setActivityModalActivityProperties(activityProperties);
    setActivityModalCanDeleteActivity(canDeleteActivity);
    setActivityModalCanEditFields(canEditFields);
    setActivityModalMode('EDIT');
    setActivityModalResourcesDropdownResources(activityProperties.resources);
  };

  const handleKeyDown = ({ key }) => {
    if (key === 'Enter' || key === ' ') {
      handleEditClick();
    }
  };

  return (
    <Button
      className={editBtnStyle === 'SUBTLE' ? 'buttonV2 button-subtle activity__btn' : 'card-image-header__btn btn btn--circle'}
      onClick={handleEditClick}
      data-baloon={t('edit', { scope: 'activities.edit_modal' })}
      data-balloon-pos="up"
      aria-label={t('edit', { scope: 'activities.edit_modal' })}
      tabIndex="0"
      onKeyDown={handleKeyDown}
    >
      {editBtnText || <Icon name="pencil-edit" />}
    </Button>
  );
};

const mapDispatchToProps = (dispatch) => {

  const dispatchSetCarePlanId = (dispatch) => {
    return (carePlanId) => {
      dispatch(setCarePlanId(carePlanId));
    };
  };

  const dispatchSetSessionInfoState = (dispatch) => {
    return (sessionInfoState) => {
      dispatch(setSessionInfoState(sessionInfoState));
    };
  };

  const dispatchSetSessionInfoData = (dispatch) => {
    return (data) => {
      dispatch(setSessionInfoData(data));
    };
  };

  const dispatchSetActivityModalActivityType = (dispatch) => {
    return (activityType) => {
      dispatch(setActivityModalActivityType(activityType));
    };
  };

  const dispatchSetActivityModalCarePlanId = (dispatch) => {
    return (carePlanId) => {
      dispatch(setActivityModalCarePlanId(carePlanId));
    };
  };

  const dispatchSetActivityModalTimeZone = (dispatch) => {
    return (timeZone) => {
      dispatch(setActivityModalTimeZone(timeZone));
    };
  };

  const dispatchSetActivityModalActivityProperties = (dispatch) => {
    return (activityProperties) => {
      dispatch(setActivityModalActivityProperties(activityProperties));
    };
  };

  const dispatchSetActivityModalActivityId = (dispatch) => {
    return (activityId) => {
      dispatch(setActivityModalActivityId(activityId));
    };
  };

  const dispatchSetActivityModalShowAddressFields = (dispatch) => {
    return (showAddressFields) => {
      dispatch(setActivityModalShowAddressFields(showAddressFields));
    };
  };

  const dispatchSetActivityModalCanDeleteActivity = (dispatch) => {
    return (canDeleteActivity) => {
      dispatch(setActivityModalCanDeleteActivity(canDeleteActivity));
    };
  }

  const dispatchSetActivityModalCanEditFields = (dispatch) => {
    return (canEditFields) => {
      dispatch(setActivityModalCanEditFields(canEditFields));
    };
  }

  const dispatchSetActivityModalAssignmentsDropdownAssignments = (dispatch) => {
    return (assignmentOptions) => {
      dispatch(setActivityModalAssignmentsDropdownAssignments(assignmentOptions));
    };
  }

  const dispatchSetActivityModalResourcesDropdownResources = (dispatch) => {
    return (resources) => {
      dispatch(setActivityModalResourcesDropdownResources(resources));
    };
  }

  const dispatchSetActivityModalMode = (dispatch) => {
    return (mode) => {
      dispatch(setActivityModalMode(mode));
    };
  };

  const dispatchSetActivityModalIsOpen = (dispatch) => {
    return (isOpen) => {
      dispatch(setActivityModalIsOpen(isOpen));
    };
  };

  return {
    setCarePlanId: dispatchSetCarePlanId(dispatch),
    setSessionInfoState: dispatchSetSessionInfoState(dispatch),
    setSessionInfoData: dispatchSetSessionInfoData(dispatch),
    setActivityModalActivityType: dispatchSetActivityModalActivityType(dispatch),
    setActivityModalCarePlanId: dispatchSetActivityModalCarePlanId(dispatch),
    setActivityModalTimeZone: dispatchSetActivityModalTimeZone(dispatch),
    setActivityModalActivityProperties: dispatchSetActivityModalActivityProperties(dispatch),
    setActivityModalActivityId: dispatchSetActivityModalActivityId(dispatch),
    setActivityModalShowAddressFields: dispatchSetActivityModalShowAddressFields(dispatch),
    setActivityModalCanDeleteActivity: dispatchSetActivityModalCanDeleteActivity(dispatch),
    setActivityModalCanEditFields: dispatchSetActivityModalCanEditFields(dispatch),
    setActivityModalAssignmentsDropdownAssignments: dispatchSetActivityModalAssignmentsDropdownAssignments(dispatch),
    setActivityModalResourcesDropdownResources: dispatchSetActivityModalResourcesDropdownResources(dispatch),
    setActivityModalMode: dispatchSetActivityModalMode(dispatch),
    setActivityModalIsOpen: dispatchSetActivityModalIsOpen(dispatch),
  };
};

export default connect(null, mapDispatchToProps)(EditButtonModal);