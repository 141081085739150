import React from 'react';
import PropTypes from 'prop-types';

const ApiErrorMessage = ({ modalStatus, apiError }) => {
  if (modalStatus === 'apiError' && apiError) {
    return (
      <div className="signup_modal__main_content__api_error">
        {apiError}
      </div>
    );
  } else {
    return null;
  }
}

ApiErrorMessage.propTypes = {
  apiError: PropTypes.string,
  modalStatus: PropTypes.string,
}

export default ApiErrorMessage;
