import React from 'react';
import { comparisonChartStyles } from 'Components/Dashboard/BloodGlucoseCard/DataVisualization/constants';

export default function DemoLine({ day, isVisible }) {
  const dashPattern = comparisonChartStyles[day].dashPattern;
  const color = isVisible ? comparisonChartStyles[day].color : comparisonChartStyles.disabled.color;

  return (
    <div className="comparison-chart__day-selector__demo-line">
      <svg width="200" height="2" viewBox="0 0 200 2" xmlns="http://www.w3.org/2000/svg">
        <line x1="0" x2="200" y1="1" y2="1" stroke={color} strokeDasharray={dashPattern} strokeWidth="2" />
      </svg>
    </div>
  );
}
