import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';

import { Button } from 'Components/Base';
import MedicalOrder from './MedicalOrder';
import RepeatedActivity from '../RepeatedActivity';
import Form from './MedicalOrderForm';
import { Card, AddActionHeader, Footer } from 'Components/Base/Card/';

import orderCreator from '../orderCreator';

import LinkResources from 'Components/Resources/LinkResources';
import { Modal } from 'Components/Base/Modal';
import LinkResourceButton from 'Components/Tasks/Index/LinkResourceButton';

const i18nOpts = { scope: 'care_plans.medical_order_section.index' };

class MedicalOrderSection extends Component {
  constructor(props) {
    super(props);

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.state = {
      modalIsOpen: false,
    };
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  get cardProps() {
    let renderHeaderAction = null;

    if (this.props.canAdd && !this.props.showForm) {
      renderHeaderAction = (actionWrapper) => (
        <AddActionHeader onClick={() => actionWrapper(this.props.handleActionClick)} />
      );
    }

    return {
      title: this.props.showForm ? t('add_header', i18nOpts) : t('header', i18nOpts),
      collapsable: true,
      renderHeaderAction,
    };
  }

  renderModalForm() {
    const { adHocResources, resources, selectedResources, onSelectResources, allTags } = this.props;
    return (
      <Modal
        isOpen={this.state.modalIsOpen}
        closeModal={this.closeModal}
        title={t('link_resources', i18nOpts)}
      >
        {this.state.modalIsOpen && (
          <LinkResources
            allTags={allTags}
            adHocResources={adHocResources}
            resources={resources}
            selectedResources={selectedResources}
            onBack={this.closeModal}
            isLinking
            onSelectResources={onSelectResources}
          />
        )}
      </Modal>
    );
  }

  render() {
    const { showForm, orders, handleSubmit, handleActionClick, repeatedAppointments } = this.props;
    return (
      <Card {...this.cardProps}>
        <ul>
          {orders.map((order) => (
            <MedicalOrder key={order.id} {...order} />
          ))}

          {repeatedAppointments.map((repeatedAppointment) => (
            <RepeatedActivity
              key={repeatedAppointment.id}
              name={repeatedAppointment.text}
              {...repeatedAppointment}
            />
          ))}

          {orders.length + repeatedAppointments.length === 0 && (
            <li className="item-pill-section--item-list-empty">{t('empty', i18nOpts)}</li>
          )}
        </ul>
        <Modal
          className={'ReactModal__Content'}
          title={t('add_header', i18nOpts)}
          isOpen={showForm}
          closeModal={handleActionClick}
        >
          <Form
            className="form"
            {...this.props}
            renderSubmit={() => (
              <Footer
                left={<LinkResourceButton action={this.openModal} />}
                right={
                  <>
                    <Button
                      text={t('cancel', i18nOpts)}
                      className="btn btn--secondary"
                      onClick={handleActionClick}
                    />
                    <Button text={t('submit', i18nOpts)} className="btn" onClick={handleSubmit} />
                  </>
                }
              />
            )}
          />
        </Modal>
        {this.renderModalForm()}
      </Card>
    );
  }
}

const resourceGroupShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  kind: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

MedicalOrderSection.propTypes = {
  adHocResources: PropTypes.arrayOf(resourceGroupShape),
  allTags: PropTypes.array,
  canAdd: PropTypes.bool.isRequired,
  handleActionClick: PropTypes.func,
  handleSubmit: PropTypes.func,
  onSelectResources: PropTypes.func.isRequired,
  orders: PropTypes.array,
  repeatedAppointments: PropTypes.array,
  resources: PropTypes.shape({
    information: PropTypes.arrayOf(resourceGroupShape),
    service: PropTypes.arrayOf(resourceGroupShape),
    practitioner: PropTypes.arrayOf(resourceGroupShape),
    people: PropTypes.arrayOf(resourceGroupShape),
    other: PropTypes.arrayOf(resourceGroupShape),
  }).isRequired,
  selectedResources: PropTypes.array,
  showForm: PropTypes.bool.isRequired,
  timeZone: PropTypes.string,
};

MedicalOrderSection.defaultProps = {
  adHocResources: [],
  allTags: [],
  handleActionClick: null,
  handleSubmit: null,
  orders: [],
  repeatedAppointments: [],
  resources: {
    information: [],
    service: [],
    practitioner: [],
    people: [],
    other: [],
  },
  selectedResources: [],
  timeZone: '',
};

export default orderCreator(MedicalOrderSection);
