import React from 'react';
import PropTypes from 'prop-types';

import buildTabClassName from './buildTabClassName';
import NewSignupsBadge from './NewSignupsBadge';

const Tab = ({ onClick, hasNewSignups, tabIsSelected, descriptor }) => {
  return (
    <a className={buildTabClassName(tabIsSelected)} onClick={onClick}>
      <span className="signups-tabs__tab__descriptor">{descriptor}</span>
      {hasNewSignups && <NewSignupsBadge />}
    </a>
  );
};

Tab.propTypes = {
  hasNewSignups: PropTypes.bool,
  tabIsSelected: PropTypes.bool,
  onClick: PropTypes.func,
  descriptor: PropTypes.string,
};

export default Tab;
