import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

const i18nOpts = { scope: 'care_plans.document_section.edit.uploaded_documents.document_list' }

import Document from 'Components/Documents/Edit/Document'

const DocumentList = ({ documents, deleteDocument, editDocument, recordsProcessing }) => (
  <div className="item-pill-section__item-list">
    {documents.length ? (
      documents.map((document) => {
        return (
          <Document
            key={document.id}
            deleteDocument={deleteDocument.bind(null, document.id)}
            document={document}
            editDocument={editDocument.bind(null, document)}
            isProcessing={recordsProcessing[document.id]}
            previewUrl={document.url}
          />
        )
      })
    ) : (
      <div className="item-pill-section--item-list-empty">
        <span className="careplan__empty-list">{t('no_files_uploaded', i18nOpts)}</span>
      </div>
    )}
  </div>
)

DocumentList.propTypes = {
  deleteDocument: PropTypes.func.isRequired,
  documents: PropTypes.array,
  editDocument: PropTypes.func.isRequired,
  recordsProcessing: PropTypes.object.isRequired,
}

DocumentList.defaultProps = {
  documents: [],
}

export default DocumentList
