import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'
import _ from 'lodash'

import RelationshipForm from './RelationshipForm'

import TeamMembership from '../../../api/TeamMembership'

const i18nOpts = { scope: 'team.relationship.index' }

class Relationship extends Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.toggleEdit = this.toggleEdit.bind(this)

    this.state = {
      editing: false,
      relationship: props.relationship,
      newRelationship: props.relationship,
    }
  }

  handleChange({ target: { name, value } }) {
    this.setState((prevState) => _.set(prevState, name, value))
  }

  handleSubmit(evt) {
    evt.preventDefault()

    const { id, carePlanId } = this.props
    const relationship = this.state.newRelationship
    new TeamMembership(carePlanId).update({ id, relationship }).then((res) => {
      if (res.ok) {
        res.json().then(({ relationship }) => this.setState({ relationship, editing: false }))
      }
    })
  }

  toggleEdit() {
    this.setState({
      newRelationship: this.state.relationship,
      editing: !this.state.editing,
    })
  }

  render() {
    const { changeable } = this.props
    if (this.state.editing) {
      return (
        <RelationshipForm
          name={this.props.name}
          relationship={this.state.newRelationship}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          cancel={this.toggleEdit}
        />
      )
    } else {
      return (
        <div>
          <div className="user-profile__details-title">
            {t('label', { ...i18nOpts, name: this.props.name })}
          </div>
          <div data-test="relationship">
            {t(this.state.relationship, { scope: 'relationship' })}
            {changeable && (
              <span>
                {' '}
                - <a onClick={this.toggleEdit}>{t('change', i18nOpts)}</a>
              </span>
            )}
          </div>
        </div>
      )
    }
  }
}

Relationship.propTypes = {
  changeable: PropTypes.bool,
  name: PropTypes.string.isRequired,
  relationship: PropTypes.string.isRequired,
}

Relationship.defaultProps = {
  changeable: false,
}

export default Relationship
