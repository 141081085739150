import React from 'react';
import PropTypes from 'prop-types';

const Table = ({ data }) => {
  const { columns, rows } = data;

  return (
    <div className="pump-settings__settings-group">
      <table>
        <thead>
          <tr>
            {columns.map((columnHeader) => (
              <th key={columnHeader}>{columnHeader}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              {row.map((datum) => (
                <td key={datum}>{datum}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  data: PropTypes.shape({
    columns: PropTypes.array,
    rows: PropTypes.array,
  }),
};

Table.defaultProps = {
  data: {
    columns: [],
    rows: [],
  },
};

export default Table;
