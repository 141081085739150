import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import { setIsShowingMyPatients } from 'Actions/placeUsersPatientsIndexPage';

import Checkbox from 'Components/Base/Checkbox';

import updatePreferences from './updatePreferences';

const i18nOpts = { scope: 'patient.index' };

const ShowMyList = ({ isShowingMyPatients, setIsShowingMyPatients }) => {
  const toggleMyPatientsList = (event) => {
    const isChecked = event?.target?.checked || false;
    setIsShowingMyPatients(isChecked);

    updatePreferences({ payload: { show_my_patient_list: isChecked } });
  };

  return (
    <Checkbox
      className="patients-filters__checks__my-list"
      onClick={toggleMyPatientsList}
      checked={isShowingMyPatients}
      name="filterPatientsCheckboxMyList"
      id="filterPatientsCheckboxMyList"
      label={t('filter_patients_my_list', i18nOpts)}
    />
  );
};

const mapStateToProps = ({ placeUsersPatientsIndexPage }) => {
  const { isShowingMyPatients } = placeUsersPatientsIndexPage;

  return { isShowingMyPatients };
};

const mapDispatchToProps = (dispatch) => ({
  setIsShowingMyPatients: (isShowingMyPatients) => dispatch(setIsShowingMyPatients(isShowingMyPatients)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowMyList);
