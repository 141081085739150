import { combineReducers } from 'redux'

import { SET_SUPPORT_TEAM_ERROR, RECEIVE_SUPPORT_TEAM, RECEIVE_SUPPORT_TEAM_ENTRY } from 'Actions/supportTeam'

const initialState = {
  errors: {},
  members: [],
}

const createSupportTeamReducer = (reducerNamespace = '') => (state = initialState, action) => {
  if (action.role !== reducerNamespace) return state

  switch (action.type) {
    case RECEIVE_SUPPORT_TEAM: {
      return {
        ...state,
        members: action.members,
      }
    }

    case RECEIVE_SUPPORT_TEAM_ENTRY: {
      return {
        ...state,
        members: [...state.members, action.newSupportMember],
        errors: {},
      }
    }

    case SET_SUPPORT_TEAM_ERROR: {
      return {
        ...state,
        errors: action.errors,
      }
    }

    default:
      return state
  }
}

export default combineReducers({
  patient: createSupportTeamReducer('patient'),
  primaryCaregivers: createSupportTeamReducer('primary_caregiver'),
  caregivers: createSupportTeamReducer('caregiver'),
  support: createSupportTeamReducer('support'),
  unaffiliatedHealthCare: createSupportTeamReducer('unaffiliated_health_care'),
})
