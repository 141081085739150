import { createSelector } from 'reselect'

import { getFilter, getCarePlanFilter } from './filter'
import { getCurrentUser } from './currentUser'

export const getTasks = (state) => state.tasks.items
export const isFetchingTasks = (state) => state.tasks.isFetching
export const isShowingCompletedTasks = (state) => state.tasks.completed

import { applyFilters } from 'Selectors/agenda'

export const getVisibleTasks = createSelector(
  [getTasks, getFilter, getCurrentUser, getCarePlanFilter],
  applyFilters
)
