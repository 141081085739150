import { t } from 'i18n';
import React from 'react';
import ProviderWrapper from '../Base/ProviderWrapper';
import EditButtonModal from '../Activities/EditButtonModal';
import {
  activityType as activityTypeDefinitions,
} from 'Components/Activities/ActivityModal/modalContentStateDefinitions';
import ActivityModal from 'Components/Activities/ActivityModal';

const i18nOpts = { scope: 'appointments.actions_container' };

export const EditModalContainer = ({
  appointment,
  carePlanId,
  assignments,
  assignmentOptions,
  timeZone,
  locale,
  canDeleteActivity,
  canEditDescription,
  canEditUrl,
  canEditResources,
  canDeleteResources,
}) => {

  const activityProperties = {
    description: appointment.text,
    dueAt: appointment.dueAt,
    addressFields: appointment.address,
    virtualVisitLink: appointment.url,
    assignments,
    resources: appointment.services,
  };
  
  return (
    <ProviderWrapper>
      <>
        <EditButtonModal
          carePlanId={carePlanId}
          activityId={appointment.id}
          activityType={activityTypeDefinitions.APPOINTMENT}
          activityProperties={activityProperties}
          assignmentOptions={assignmentOptions}
          timeZone={timeZone}
          locale={locale}
          canDeleteActivity={canDeleteActivity}
          canEditFields={{
            canEditDescription,
            canEditUrl,
            canEditResources,
            canDeleteResources,
          }}
          editBtnStyle='SUBTLE'
          editBtnText={t('edit', i18nOpts)}
        />
        <ActivityModal
          onUpdate={() => {
            window.location.reload();
          }}
        />
      </>
    </ProviderWrapper>
  );
};

export default EditModalContainer;
