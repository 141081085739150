import Api from '../Api';

class ChannelSort extends Api {
  constructor({ sortBy = null }) {
    super();

    this.sortBy = sortBy || '';
  }

  sort() {
    return super.request(`${this.url}?sortBy=${this.sortBy}`, 'GET', null, false);
  }

  get url() {
    return '/inbox/sort';
  }
}

export default ChannelSort;
