import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import FormField from '../../FormField';
import CountrySelector from './CountrySelector';
import ExtendedAddressByCountry from './ExtendedAddressByCountry';

import { setActivityModalActivityProperties } from 'Actions/activityModal';

const i18nOpts = { scope: 'activity_modal.form_fields.where.address' }

const Address = ({ activityProperties, formErrors, setActivityModalActivityProperties }) => {
  const address = activityProperties?.addressFields;

  return (
    <div className="activity_modal__address_form__input_field__container">
      <CountrySelector />
      <FormField
        label={t('street_address', i18nOpts)}
        name='address.address'
        value={address?.address}
        placeholder={t('street_address', i18nOpts)}
        onChange={(value) => {
          setActivityModalActivityProperties({
            ...activityProperties,
            addressFields: {
              ...activityProperties.addressFields,
              address: value,
            }
          })
        }}
        formErrors={formErrors}
      />
      <FormField
        name='address.extendedAddress'
        label={t('extended_address', i18nOpts)}
        value={address?.extendedAddress}
        placeholder={t('extended_address', i18nOpts)}
        onChange={(value) => {
          setActivityModalActivityProperties({
            ...activityProperties,
            addressFields: {
              ...activityProperties.addressFields,
              extendedAddress: value,
            }
          })
        }}
        formErrors={formErrors}
      />
      <ExtendedAddressByCountry />
    </div>
  );
};

const mapStateToProps = ({ activityModal }) => {
  const { activityProperties, formErrors } = activityModal;

  return { activityProperties, formErrors };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalActivityProperties = (dispatch) => {
    return (activityProperties) => {
      dispatch(setActivityModalActivityProperties(activityProperties));
    };
  };

  return {
    setActivityModalActivityProperties: dispatchSetActivityModalActivityProperties(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Address);
