import { Resource } from '../Api'

class Careteam extends Resource {
  constructor(carePlanUuid) {
    super()

    this.carePlanUuid = carePlanUuid
  }

  update(carePlan) {
    return super.update({ carePlan })
  }

  get url() {
    return `/intake/${this.carePlanUuid}/careteam`
  }
}

export default Careteam
