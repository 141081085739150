import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';

import { Button, ModalFooter } from 'Components/Base';
import Submit from 'Components/Forms/Submit';
import InputGroup from 'Components/Forms/InputGroup';

import { frequencyTypes, frequencies, endRepeats, endRepeatsForTemplate } from 'Utilities/repeated_options';

const i18nOpts = { scope: 'care_plans.form_section.modal' };

const FormForm = ({
  count,
  daysOffset,
  dueAt,
  editing,
  endRepeat,
  errors,
  formTemplates,
  formTemplateId,
  frequencyType,
  frequency,
  interval,
  isTemplate,
  name,
  onClose,
  onChange,
  onSubmit,
  timeZone,
  timeOffset,
  until,
}) => (
  <form onSubmit={onSubmit} noValidate>
    <InputGroup
      component="select"
      name="formTemplateId"
      label={t('form_type', i18nOpts)}
      onChange={onChange}
      value={formTemplateId}
      errors={errors}
      required
    >
      <option value="">{t('blank', i18nOpts)}</option>
      {formTemplates.map((option) => (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      ))}
    </InputGroup>

    <div className="form__row row-justify-start">
      <InputGroup
        component="select"
        className="input"
        name="frequencyType"
        label={t('recurring', i18nOpts)}
        onChange={onChange}
        value={frequencyType}
        errors={errors}
        disabled={editing}
        required
      >
        {frequencyTypes.map((option, idx) => {
          return (
            <option key={idx} value={option.id}>
              {option.name}
            </option>
          );
        })}
      </InputGroup>
    </div>

    {isTemplate && frequencyType && frequencyType !== 'once' && (
      <div>
        <div className="input activity-days-offset">
          <InputGroup
            component="input"
            name="daysOffset"
            className="input input--one-third"
            label={t('days_offset', i18nOpts)}
            onChange={onChange}
            value={daysOffset}
            errors={errors}
            type="number"
            min="1"
            max="999"
            required
          />
          <div className="trail-text">
            {t('daily', { scope: 'tasks.form.interval', count: parseInt(daysOffset) })}{' '}
            {t('days_offset_text', i18nOpts)}
          </div>
        </div>
      </div>
    )}

    {!isTemplate && frequencyType && frequencyType !== 'once' && (
      <div className="input due-at">
        <div className="date-input">
          <div className="date-input__input">
            <InputGroup
              component="datetime"
              id="dueAt"
              name="dueAt"
              label={t('due_at', i18nOpts)}
              onChange={onChange}
              value={dueAt}
              timeZone={timeZone}
              withDate={true}
              errors={{ dueAt: errors['due_at'] }}
              required
            />
          </div>
        </div>
      </div>
    )}

    {isTemplate && frequencyType && frequencyType !== 'once' && (
      <div className="input due-at">
        <div className="date-input">
          <div className="date-input__input">
            <InputGroup
              component="datetime"
              id="timeOffset"
              name="timeOffset"
              label={t('time_offset', i18nOpts)}
              onChange={onChange}
              value={timeOffset}
              timeZone={timeZone}
              withDate={false}
              errors={{ timeOffset: errors['time_offset'] }}
              required
            />
          </div>
        </div>
      </div>
    )}

    {frequencyType === 'recurring' ? (
      <div>
        <div className="form__row row-justify-start">
          <InputGroup
            component="select"
            className="input input--half"
            name="frequency"
            onChange={onChange}
            value={frequency}
            errors={errors}
            label={t('repeats', i18nOpts)}
            required
          >
            {frequencies.map((option, idx) => (
              <option key={idx} value={option.id}>
                {option.name}
              </option>
            ))}
          </InputGroup>

          {frequency && (
            <div>
              <InputGroup
                component="input"
                className="input input--one-third interval"
                name="interval"
                label={t('every', i18nOpts)}
                onChange={onChange}
                value={interval}
                errors={errors}
                type="number"
                min="1"
                max="999"
                required
              />
              <div className="trail-text">
                {t(frequency, { scope: 'tasks.form.interval', count: parseInt(interval) })}
              </div>
            </div>
          )}
        </div>

        <div className="form__row row-justify-start">
          <InputGroup
            component="select"
            className="input input--half"
            name="endRepeat"
            onChange={onChange}
            errors={errors}
            value={endRepeat}
            label={t('end_repeat', i18nOpts)}
          >
            {(isTemplate ? endRepeatsForTemplate : endRepeats).map((option, idx) => {
              return (
                <option key={idx} value={option.id}>
                  {option.name}
                </option>
              );
            })}
          </InputGroup>

          {endRepeat === 'times' ? (
            <div>
              <InputGroup
                component="input"
                className="input input--one-third count"
                name="count"
                label={t('after', i18nOpts)}
                onChange={onChange}
                errors={errors}
                value={count}
                type="number"
                min="1"
                max="999"
                required
              />
              <div className="trail-text">{t('count.other', i18nOpts)}</div>
            </div>
          ) : null}
          {endRepeat === 'on_a_day' ? (
            <InputGroup
              className="input input--one-third until"
              label={t('end_date', i18nOpts)}
              name="until"
              onChange={onChange}
              value={until}
              timeZone={timeZone}
              component="datetime"
              errors={errors}
              ensureVisibility
              required
              withTime={false}
            />
          ) : null}
        </div>
      </div>
    ) : null}

    <ModalFooter
      renderRight={() => (
        <Fragment>
          <Button text={t('cancel', i18nOpts)} className="btn btn--secondary" onClick={onClose} />
          <Submit value={t('submit', i18nOpts)} />
        </Fragment>
      )}
    />
  </form>
);

FormForm.propTypes = {
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  daysOffset: PropTypes.string,
  dueAt: PropTypes.string,
  editing: PropTypes.bool,
  endRepeat: PropTypes.string,
  errors: PropTypes.object,
  formTemplateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  formTemplates: PropTypes.array,
  frequency: PropTypes.string,
  frequencyType: PropTypes.string,
  interval: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isTemplate: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  timeZone: PropTypes.string,
  timeOffset: PropTypes.string,
  until: PropTypes.string,
};

FormForm.defaultProps = {
  count: '',
  daysOffset: '',
  dueAt: '',
  editing: false,
  endRepeat: '',
  errors: {},
  formTemplateId: '',
  formTemplates: [],
  frequency: '',
  frequencyType: 'once',
  interval: '',
  isTemplate: false,
  name: '',
  timeZone: '',
  timeOffset: '',
  until: '',
};

export default FormForm;
