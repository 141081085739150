import React from 'react';
import { connect } from 'react-redux';

import Appointment from './Appointment';
import Task from './Task';
import { activityType as activityTypeDefinitions } from '../modalContentStateDefinitions';

export const Edit = ({ activityType }) => {
  const { APPOINTMENT, TASK } = activityTypeDefinitions;

  let mainContent;

  switch (activityType) {
    case APPOINTMENT:
      mainContent = <Appointment />;
      break;

    case TASK:
      mainContent = <Task />;
      break;

    default:
      mainContent = <Appointment />;
      break;
  }

  return mainContent;
};

const mapStateToProps = ({ activityModal }) => {
  const { activityType } = activityModal;

  return { activityType };
};

export default connect(mapStateToProps, null)(Edit);
