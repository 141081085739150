import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import { ProviderWrapper } from 'Components/Base'
import { AccessRequestsContainer, AcceptanceModalContainer } from './'

import store from '../../../store'
import { setAccessRequests, setIsPlaceUser } from 'Actions/accessRequests'

class AccessRequestsModule extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    store.dispatch(setIsPlaceUser(this.props.isPlaceUser))
    store.dispatch(setAccessRequests(this.props.accessRequests))
  }

  render() {
    return (
      <ProviderWrapper>
        <Fragment>
          <AccessRequestsContainer />
          <AcceptanceModalContainer />
        </Fragment>
      </ProviderWrapper>
    )
  }
}

AccessRequestsModule.propTypes = {
  accessRequests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      photo: PropTypes.string,
      preferredPhoneNumber: PropTypes.shape({
        number: PropTypes.string.isRequired,
      }),
      relationship: PropTypes.string.isRequired,
    })
  ),
  isPlaceUser: PropTypes.bool,
}

AccessRequestsModule.defaultProps = {
  accessRequests: [],
  isPlaceUser: false,
}

export default AccessRequestsModule
