const LEGEND_COLORS_MAP = {
  morning: '#99CCDF',
  afternoon: '#3399BE',
  evening: '#003B69',
  noData: '#FFF',
};

const calculateFill = (name, value) => {
  return value === '' || (name === 'morning' && value === 0)
    ? LEGEND_COLORS_MAP.noData
    : LEGEND_COLORS_MAP[name];
};

export { calculateFill as default, LEGEND_COLORS_MAP as legendMap };
