import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { t } from 'i18n';

import {
  setActivityModalIsOpen,
  setActivityModalCarePlanId,
  setActivityModalMode,
  setActivityModalActivityType,
  setActivityModalTimeZone,
} from 'Actions/activityModal';

import { Card, AddActionHeader } from 'Components/Base/Card/';
import Loader from 'Components/Base/Loader';
import Task from './Task';
import RepeatedTask from './RepeatedTask';

const i18nOpts = { scope: 'care_plan_action_plan_page.activities_container.tasks_card' };

const renderHeaderAction = (onClick) => {
  return (actionWrapper) => {
    return (
      <AddActionHeader
        onClick={() => {
          actionWrapper(onClick);
        }}
      />
    );
  };
};

const renderTasksList = (tasks, repeatedTasks) => {
  if (tasks?.length === 0 && repeatedTasks?.length === 0) {
    return <div className="item-pill-section--item-list-empty">{t('empty', i18nOpts)}</div>;
  }

  return (
    <ul>
      {tasks.map((task) => {
        return (
          <Task
            key={`task-${task.id}`}
            text={task.text}
            completedAt={task.completedAt}
            dueAtFormatted={task.dueAtFormatted}
          />
        );
      })}

      {repeatedTasks.map((repeatedTask) => {
        return (
          <RepeatedTask
            key={`repeated-task-${repeatedTask.id}`}
            text={repeatedTask.text}
            localizedFrequency={repeatedTask.localizedFrequency}
            until={repeatedTask.until}
            formattedTime={repeatedTask.formattedTime}
          />
        );
      })}
    </ul>
  );
};

const TasksCard = ({
  carePlanId,
  sessionInfo,
  tasksCard,
  setActivityModalIsOpen,
  setActivityModalCarePlanId,
  setActivityModalMode,
  setActivityModalActivityType,
  setActivityModalTimeZone,
}) => {
  const { tasksCardState, tasks, repeatedTasks } = tasksCard;

  let content;
  switch (tasksCardState) {
    case 'LOADING':
      content = <Loader />;
      break;
    case 'ERROR':
      content = <div className="activities-container__error">{t('api_error', i18nOpts)}</div>;
      break;
    case 'MAIN_CONTENT':
      content = renderTasksList(tasks, repeatedTasks);
      break;
    default:
      content = <div className="activities-container__error">{t('unexpected_error', i18nOpts)}</div>;
      break;
  }

  return (
    <Card
      id='generic'
      title={t('title', i18nOpts)}
      cardContainerClass='activities-card-container'
      collapsable={true}
      renderHeaderAction={
        sessionInfo.canAdd &&
        renderHeaderAction(() => {
          setActivityModalMode('CREATE');
          setActivityModalActivityType('TASK');
          setActivityModalCarePlanId(carePlanId);
          setActivityModalTimeZone(sessionInfo.timeZone);
          setActivityModalIsOpen(true);
        })
      }
    >
      {content}
    </Card>
  );
};

const mapStateToProps = ({ carePlanActionPlanViewPage }) => {
  const { carePlanId, sessionInfo, tasksCard } = carePlanActionPlanViewPage;

  return {
    carePlanId,
    sessionInfo,
    tasksCard,
  };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchSetActivityModalIsOpen = (dispatch) => {
    return (isOpen) => {
      dispatch(setActivityModalIsOpen(isOpen));
    };
  };

  const dispatchSetActivityModalMode = (dispatch) => {
    return (mode) => {
      dispatch(setActivityModalMode(mode));
    };
  };

  const dispatchSetActivityModalActivityType = (dispatch) => {
    return (activityType) => {
      dispatch(setActivityModalActivityType(activityType));
    };
  };

  const dispatchSetActivityModalCarePlanId = (dispatch) => {
    return (carePlanId) => {
      dispatch(setActivityModalCarePlanId(carePlanId));
    };
  };

  const dispatchSetActivityModalTimeZone = (dispatch) => {
    return (timeZone) => {
      dispatch(setActivityModalTimeZone(timeZone));
    };
  };

  return {
    setActivityModalIsOpen: dispatchSetActivityModalIsOpen(dispatch),
    setActivityModalCarePlanId: dispatchSetActivityModalCarePlanId(dispatch),
    setActivityModalMode: dispatchSetActivityModalMode(dispatch),
    setActivityModalActivityType: dispatchSetActivityModalActivityType(dispatch),
    setActivityModalTimeZone: dispatchSetActivityModalTimeZone(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksCard);
