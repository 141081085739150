import LibraryDocumentApi from 'Api/Place/LibraryDocument'

export const SET_DOCUMENTS = 'SET_DOCUMENTS'
export const REQUEST_LIBRARY_DOCUMENTS = 'REQUEST_LIBRARY_DOCUMENTS'
export const RECEIVE_LIBRARY_DOCUMENTS = 'RECEIVE_LIBRARY_DOCUMENTS'

export const ADD_DOCUMENT = 'ADD_DOCUMENT'
export const REPLACE_DOCUMENT = 'REPLACE_DOCUMENT'
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT'

export const SEARCH = 'SEARCH'
export const TOGGLE_EDITING = 'TOGGLE_EDITING'
export const TOGGLE_LIBRARY_DOCUMENT_SELECTION = 'TOGGLE_LIBRARY_DOCUMENT_SELECTION'
export const UPDATE_LIBRARY_DOCUMENT_COPIES = 'UPDATE_LIBRARY_DOCUMENT_COPIES'

const setDocuments = (documents) => ({
  type: SET_DOCUMENTS,
  documents,
})

const fetchLibraryDocuments = () => {
  return (dispatch) => {
    dispatch(requestLibraryDocuments())
    const libraryDocumentApi = new LibraryDocumentApi()

    return libraryDocumentApi
      .index()
      .then((res) => res.json())
      .then(({ libraryDocuments }) => dispatch(receiveLibraryDocuments(libraryDocuments)))
  }
}

const requestLibraryDocuments = () => ({
  type: REQUEST_LIBRARY_DOCUMENTS,
})

const receiveLibraryDocuments = (libraryDocuments) => ({
  type: RECEIVE_LIBRARY_DOCUMENTS,
  libraryDocuments,
})

const addDocument = (document) => ({
  type: ADD_DOCUMENT,
  document,
})

const replaceDocument = (currentDocumentId, newDocument) => ({
  type: REPLACE_DOCUMENT,
  currentDocumentId,
  newDocument,
})

const deleteDocument = (documentId) => ({
  type: DELETE_DOCUMENT,
  documentId,
})

const search = (searchQuery) => ({
  type: SEARCH,
  searchQuery,
})

const toggleEditing = () => ({
  type: TOGGLE_EDITING,
})

const toggleLibraryDocumentSelection = (libraryDocumentId) => ({
  type: TOGGLE_LIBRARY_DOCUMENT_SELECTION,
  libraryDocumentId,
})

const updateLibraryDocumentCopies = () => ({
  type: UPDATE_LIBRARY_DOCUMENT_COPIES,
})

export {
  setDocuments,
  fetchLibraryDocuments,
  requestLibraryDocuments,
  receiveLibraryDocuments,
  addDocument,
  replaceDocument,
  deleteDocument,
  search,
  toggleEditing,
  toggleLibraryDocumentSelection,
  updateLibraryDocumentCopies,
}
