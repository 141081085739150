import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Card } from 'Components/Base'
import { AccessRequest } from './'

const AccessRequests = ({ accessRequests, onClick }) =>
  accessRequests.length ? (
    <Card title={t('header', { scope: 'team.access_requests' })}>
      <ul>
        {accessRequests.map((request) => (
          <AccessRequest key={request.id} onClick={onClick.bind(null, request.id)} {...request} />
        ))}
      </ul>
    </Card>
  ) : null

AccessRequests.propTypes = {
  accessRequests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      relationship: PropTypes.string,
      user: PropTypes.object,
    })
  ),
  onClick: PropTypes.func.isRequired,
}
AccessRequests.defaultProps = {
  accessRequests: [],
}

export default AccessRequests
