import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import FormLine from './FormLine'

const i18nOpts = { scope: 'care_plan_creators.forms.index' }

const FormList = ({ forms, deleteForm, editForm }) =>
  !forms.length ? (
    <div className="careplan__empty-list" dangerouslySetInnerHTML={{ __html: t('empty_html', i18nOpts) }} />
  ) : (
    <div className="careplan-wizard__order-list">
      {forms.map((form) => {
        return (
          <FormLine
            key={form.id}
            deleteForm={deleteForm.bind(null, form.id, form.frequencyType)}
            editForm={editForm.bind(null, form)}
            form={form}
          />
        )
      })}
    </div>
  )

FormList.propTypes = {
  deleteForm: PropTypes.func.isRequired,
  editForm: PropTypes.func.isRequired,
  forms: PropTypes.array.isRequired,
}

FormList.defaultProps = {}

export default FormList
